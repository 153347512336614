/* eslint-disable no-nested-ternary */
import { useCallback, useMemo } from 'react';

export const useScoreWidget = (days?: { label: string }[]) => {
  const months = useMemo(() => Array.from(new Set(days?.map(({ label }) => new Date(label).toLocaleString('default', { month: 'short' })))), [days]);

  const interval = days && months.length > 2 ? Math.floor(days.length / (months.length - 1)) : 0;

  const customXTickFormatter = useCallback((_: unknown, index: number) => {
    if (!months.length || !days?.length) return '';

    if (months.length <= 2) {
      return index === 0 ? months[0] : index === days.length - 1 ? months[months.length - 1] : '';
    }

    const tickIndex = Math.floor(index / interval);
    return index % interval === 0 && tickIndex < months.length ? months[tickIndex] : '';
  }, [interval, months, days?.length]);

  return {
    customXTickFormatter,
  };
};
