import { useCallback, useMemo } from 'react';

import { AWS_INTEGRATION_VERSION, AWS_VERSION_ERROR_TOAST_ID, UPGRADE_PROWLER_DOCS } from '../constant';

import styles from './useHandleVersionError.module.scss';

import { ErrorDot } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import { IActiveToast } from 'context/ToastsContext/interfaces';
import { useToastsContext } from 'context/ToastsContext/ToastsContext';
import colors from 'themes/colors.module.scss';
import { ToastType } from 'types/enums';
import { IInstallation } from 'types/interfaces';

const isSemverLowerThan = (v1: string, v2: string) => {
  const parts1 = v1.split('.').map(Number);
  const parts2 = v2.split('.').map(Number);

  for (let i = 0; i < parts1.length || i < parts2.length; i += 1) {
    const part1 = parts1[i] || 0;
    const part2 = parts2[i] || 0;

    if (part1 < part2) return true;
  }
  return false;
};

export const useHandleAWSVersionError = (awsInstallation: IInstallation | undefined, expectedVersion: string = AWS_INTEGRATION_VERSION) => {
  const { showToast, activeToasts, hideToast } = useToastsContext();
  const isOldVersion = useMemo(() => awsInstallation
    && (!awsInstallation?.integration_version || isSemverLowerThan(awsInstallation.integration_version, expectedVersion)
    ), [awsInstallation, expectedVersion]);
  const activeVersionErrorToastIndex = useMemo(() => activeToasts.findIndex((toast) => toast.toastId === AWS_VERSION_ERROR_TOAST_ID), [activeToasts]);
  const shouldShow = useMemo(() => isOldVersion && activeVersionErrorToastIndex === -1, [activeVersionErrorToastIndex, isOldVersion]);
  const shouldHide = useMemo(() => !isOldVersion && activeVersionErrorToastIndex !== -1, [activeVersionErrorToastIndex, isOldVersion]);

  const integrationErrorToast: IActiveToast = useMemo(() => ({
    type: ToastType.SystemError,
    toastId: AWS_VERSION_ERROR_TOAST_ID,
    overrideProps: {
      backgroundColor: colors.white,
      content: (
        <div className={styles.ToastContentWrapper}>
          <JitIcon className={styles.ErrorIcon} icon={ErrorDot} size={25} />

          <div className={styles.ToastText}>
            <JitText
              color='black'
              components={{
                linkComponent: (
                  <JitLink
                    href={UPGRADE_PROWLER_DOCS}
                    rel='noreferrer'
                    sx={{
                      color: colors.cards,
                      textDecoration: 'none',
                      borderBottom: `1px solid ${colors.cards}`,
                    }}
                    target='_blank'
                  />
                ),
              }}
              text='toasts.awsIntegrationVersionError.text'
            />
          </div>
        </div>
      ),
    },
  }), []);

  const handleAWSVersionError = useCallback(() => {
    if (shouldShow) {
      showToast(integrationErrorToast);
    } else if (shouldHide) {
      hideToast(activeVersionErrorToastIndex);
    }
  }, [activeVersionErrorToastIndex, hideToast, integrationErrorToast, shouldHide, shouldShow, showToast]);

  return { handleAWSVersionError };
};
