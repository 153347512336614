import { FC } from 'react';

import styles from './SecurityImpactCard.module.scss';

import { GraphLoading } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/components';

export const SecurityImpactCardLoading: FC = () => (
  <div className={styles.loadingWrapper} data-testid='securityImpactCardLoading'>
    <GraphLoading />
  </div>
);
