import Collapse from '@mui/material/Collapse';
import { FC } from 'react';

import { Asset, AssetStyle, LogsLinkLocation } from '../Asset/Asset';

import { IAssetNew } from 'types/interfaces/Pipelines/IPipelineNew';

interface PipelineAssetsListProps {
  assets: IAssetNew
  jitEventId?: string;
  assetStyle?: AssetStyle;
  logsLinkLocation?: LogsLinkLocation;
  shouldShowShortAssetName?: boolean;
}

export const PipelineAssetsList: FC<PipelineAssetsListProps> = ({ assets, jitEventId, assetStyle, logsLinkLocation, shouldShowShortAssetName }) => (
  <Collapse
    in={false}
    timeout='auto'
  >
    {Object.entries(assets).map(([assetSlug, asset]) => (
      <div key={assetSlug}>
        <Asset asset={asset} assetStyle={assetStyle} jitEventId={jitEventId} logsLinkLocation={logsLinkLocation} shouldShowShortAssetName={shouldShowShortAssetName} />
      </div>
    ))}
  </Collapse>
);

