import { FC, useState } from 'react';

import { FindingsOverTimeCheckboxes } from './components/FindingsOverTimeCheckboxes/FindingsOverTimeCheckboxes';
import { FindingsOverTimeGraph } from './components/FindingsOverTimeGraph/FindingsOverTimeGraph';
import { FindingsOverTimeProgress } from './components/FindingsOverTimeProgress/FindingsOverTimeProgress';
import styles from './FindingsOverTime.module.scss';

import { GraphEmpty, GraphLoading } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/components';
import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { ITotalFindingsGraph } from 'types/interfaces/Metrics/SecurityImpactMetrics/TotalFindingsGraph';

export interface FindingsOverTimeProps {
  totalFindingsGraphMetric: MetricWithLoadingIndication<ITotalFindingsGraph>
}

export const FindingsOverTime: FC<FindingsOverTimeProps> = ({ totalFindingsGraphMetric }) => {
  const [displayDetectedLine, setDisplayDetectedLine] = useState(true);
  const [displayResolvedLine, setDisplayResolvedLine] = useState(true);

  const getContent = () => {
    if (totalFindingsGraphMetric.isLoading) {
      return <GraphLoading />;
    }
    const totalFindingsGraph = totalFindingsGraphMetric.metric;
    if (totalFindingsGraph) {
      const sortedDays = [...totalFindingsGraph.days].sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA.getTime() - dateB.getTime();
      });

      return (
        <div className={styles.content}>
          <FindingsOverTimeProgress
            currentDay={sortedDays[sortedDays.length - 1]}
          />

          <FindingsOverTimeCheckboxes
            displayDetectedLine={displayDetectedLine}
            displayResolvedLine={displayResolvedLine}
            setDisplayDetectedLine={setDisplayDetectedLine}
            setDisplayResolvedLine={setDisplayResolvedLine}
          />

          <FindingsOverTimeGraph displayDetectedLine={displayDetectedLine} displayResolvedLine={displayResolvedLine} totalFindingsGraph={totalFindingsGraph} />
        </div>
      );
    }

    return <GraphEmpty />;
  };

  const content = getContent();
  return (
    <div className={styles.wrapper}>
      {content}
    </div>
  );
};
