import { isEqual } from 'lodash';
import { useCallback } from 'react';

import { useConfigurationsContext } from 'context/ConfigurationsContext/ConfigurationsContext';
import { constants } from 'globalConstants';
import { AssetType } from 'types/enums';

export const useHasConfigurationsChanged = () => {
  const { configurations, fetchedConfigurations } = useConfigurationsContext();
  const hasConfigurationsChanged = useCallback((planItemSlug?: string): boolean => {
    if (!planItemSlug) {
      return !isEqual(configurations, fetchedConfigurations);
    }

    const { ZAP_WEB, ZAP_API, GITHUB_BRANCH_PROTECTION } = constants.PLAN_ITEMS_SLUG;

    if (planItemSlug === ZAP_WEB) {
      const webConfiguration = configurations.applications?.filter((application) => application.type === AssetType.WEB) || [];
      const fetchedWebConfiguration = fetchedConfigurations?.applications?.filter((application) => application.type === AssetType.WEB) || [];

      return !isEqual(webConfiguration, fetchedWebConfiguration);
    }

    if (planItemSlug === ZAP_API) {
      const apiConfiguration = configurations.applications?.filter((application) => application.type === AssetType.API) || [];
      const fetchedApiConfiguration = fetchedConfigurations?.applications?.filter((application) => application.type === AssetType.API) || [];

      return !isEqual(apiConfiguration, fetchedApiConfiguration);
    }

    if (planItemSlug === GITHUB_BRANCH_PROTECTION) {
      return !isEqual(configurations.github_branch_protection || {}, fetchedConfigurations?.github_branch_protection || {});
    }

    return false;
  }, [configurations, fetchedConfigurations]);
  return { hasConfigurationsChanged };
};
