import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { constants } from 'globalConstants';
import { logError } from 'services/logger/logger';
import { getDailyFindingsActivitiesMetric,
  getDetectionRateMetric,
  getExposureWindowMetric,
  getFindingsOverTimeMetric,
  getMTTRMetric,
  getPlanItemFindingsMetric,
  getResourceTypeFindingsMetric } from 'services/MetricService/MetricService';
import { useMetricService } from 'services/MetricService/useMetricService';
import { TimeAgo } from 'types/enums';
import { PerformanceMetricNames } from 'types/enums/MetricNames';
import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { IDailyFindingsActivityMetric } from 'types/interfaces/Metrics/PerformanceMetrics/DailyFindingsActivities';
import { IDetectionRateMetric } from 'types/interfaces/Metrics/PerformanceMetrics/DetectionRate';
import { IExposureWindowMetric } from 'types/interfaces/Metrics/PerformanceMetrics/ExposureWindow';
import { IFindingsOverTimeMetric } from 'types/interfaces/Metrics/PerformanceMetrics/FindingsOvertime';
import { IMTTRMetric } from 'types/interfaces/Metrics/PerformanceMetrics/MTTR';
import { IPlanItemFindingsMetric } from 'types/interfaces/Metrics/PerformanceMetrics/PlanItemFindings';
import { IResourceTypeFindingsMetric } from 'types/interfaces/Metrics/PerformanceMetrics/ResourceTypeFindings';
import { Snapshot } from 'types/interfaces/Metrics/Snapshots';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

type MetricsState = {
  [PerformanceMetricNames.DetectionRate]: MetricWithLoadingIndication<IDetectionRateMetric>;
  [PerformanceMetricNames.MTTR]: MetricWithLoadingIndication<IMTTRMetric>;
  [PerformanceMetricNames.FindingsOverTime]: MetricWithLoadingIndication<IFindingsOverTimeMetric>;
  [PerformanceMetricNames.ExposureWindow]: MetricWithLoadingIndication<IExposureWindowMetric>;
  [PerformanceMetricNames.DailyFindingsActivities]: MetricWithLoadingIndication<IDailyFindingsActivityMetric>;
  [PerformanceMetricNames.ResourceTypeFindings]: MetricWithLoadingIndication<IResourceTypeFindingsMetric>;
  [PerformanceMetricNames.PlanItemFindings]: MetricWithLoadingIndication<IPlanItemFindingsMetric>;
};

const initialMetricValue = { isLoading: false };

const initialMetricsState: MetricsState = {
  [PerformanceMetricNames.DetectionRate]: initialMetricValue,
  [PerformanceMetricNames.MTTR]: initialMetricValue,
  [PerformanceMetricNames.FindingsOverTime]: initialMetricValue,
  [PerformanceMetricNames.ExposureWindow]: initialMetricValue,
  [PerformanceMetricNames.DailyFindingsActivities]: initialMetricValue,
  [PerformanceMetricNames.ResourceTypeFindings]: initialMetricValue,
  [PerformanceMetricNames.PlanItemFindings]: initialMetricValue,
};

const metricCalls = [
  { metric: PerformanceMetricNames.DetectionRate,
    fetchMetric: getDetectionRateMetric },
  { metric: PerformanceMetricNames.MTTR,
    fetchMetric: getMTTRMetric },
  { metric: PerformanceMetricNames.FindingsOverTime,
    fetchMetric: getFindingsOverTimeMetric },
  { metric: PerformanceMetricNames.ExposureWindow,
    fetchMetric: getExposureWindowMetric },
  { metric: PerformanceMetricNames.DailyFindingsActivities,
    fetchMetric: getDailyFindingsActivitiesMetric },
  { metric: PerformanceMetricNames.ResourceTypeFindings,
    fetchMetric: getResourceTypeFindingsMetric },
  { metric: PerformanceMetricNames.PlanItemFindings,
    fetchMetric: getPlanItemFindingsMetric },
];

export const useFetchMetrics = (period: TimeAgo, snapshotId?: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [metrics, setMetrics] = useState(initialMetricsState);
  const [snapshot, setSnapshot] = useState<Snapshot>();
  const { getSnapshot, patchDevEfficiencyCalculation } = useMetricService();
  const navigate = useNavigate();
  const { insights: { BASE_ROUTE: BASE_INSIGHTS_ROUTE, PERFORMANCE, SNAPSHOT_NOT_FOUND } } = constants.routes;

  const setMetric = useCallback((
    metricName: PerformanceMetricNames,
    metric: MetricWithLoadingIndication<IDetectionRateMetric | IMTTRMetric | IFindingsOverTimeMetric | IExposureWindowMetric | IResourceTypeFindingsMetric | IPlanItemFindingsMetric | undefined>,
  ) => {
    setMetrics((prevState) => ({
      ...prevState,
      [metricName]: metric,
    }));
  }, []);

  const fetchMetrics = useCallback(async () => {
    metricCalls.forEach(({ metric, fetchMetric }) => {
      setMetric(metric, { isLoading: true });
      fetchMetric(period, snapshotId).then((result) => setMetric(metric, {
        metric: result,
        isLoading: false,
      }));
    });
  }, [period, setMetric, snapshotId]);

  const fetchSnapshot = useCallback((id: string) => {
    setIsLoading(true);
    getSnapshot(id).then((result) => {
      const data = camelizeSnakeCaseKeys(result?.data || {}) as Snapshot;
      setSnapshot(data);
      fetchMetrics();
      setIsLoading(false);
    }).catch((error) => {
      if (error.response.status === 404) {
        navigate(`/${BASE_INSIGHTS_ROUTE}/${PERFORMANCE}/${SNAPSHOT_NOT_FOUND}`);
      }
      logError(`Failed to fetch snapshot ${id}`, {
        status: error.response.status,
        error: error.response.data,
      });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BASE_INSIGHTS_ROUTE, PERFORMANCE, SNAPSHOT_NOT_FOUND, fetchMetrics, navigate]);

  useEffect(() => {
    if (snapshotId) {
      fetchSnapshot(snapshotId);
    } else {
      fetchMetrics();
    }
  }, [fetchMetrics, fetchSnapshot, period, snapshotId]);

  return { isLoading,
    metrics,
    snapshot,
    setSnapshot,
    patchDevEfficiencyCalculation };
};
