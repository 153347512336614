import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

import { CreateCentralizedRepo } from '../StepComponents/CreateCentralizedRepo/CreateCentralizedRepo';

import { IGithubIntegrationWizardStep, IGithubIntegrationWizardStepStatus } from 'components/GithubIntegrationWizard/GithubIntegrationWizard';
import { useAssetsContext } from 'context/AssetsContext/AssetsContext';
import { useTenantContext } from 'context/TenantContext';

interface Props {
  isPrevStepCompleted: boolean;
  closeWizard: () => void;
}

export const useGetCreateCentralizedRepoStep = ({
  isPrevStepCompleted,
  closeWizard,
}: Props): IGithubIntegrationWizardStep => {
  const { centralizedRepo } = useAssetsContext();
  const { initialOnboardingCompleted, SCMIntegrationCompleted } = useTenantContext();
  const { uiShowSecurityChampionQuickGuide } = useFlags();

  const status = useMemo(() => {
    if (isPrevStepCompleted) {
      const relevanChecksCompleted = uiShowSecurityChampionQuickGuide ? SCMIntegrationCompleted && initialOnboardingCompleted : true;
      if (centralizedRepo && relevanChecksCompleted) {
        return IGithubIntegrationWizardStepStatus.COMPLETE;
      }
      return IGithubIntegrationWizardStepStatus.ACTIVE;
    }
    return IGithubIntegrationWizardStepStatus.NOT_STARTED;
  }, [SCMIntegrationCompleted, centralizedRepo, initialOnboardingCompleted, isPrevStepCompleted, uiShowSecurityChampionQuickGuide]);

  return useMemo(() => ({
    title: 'GithubIntegrationWizard.createCentralizedRepo.tabText',
    component: () => (
      <CreateCentralizedRepo closeWizard={closeWizard} />
    ),
    status,
  }), [status, closeWizard]);
};
