import { useCallback } from 'react';

import { IPolicyRule, IPolicyTemplate } from 'pages/PoliciesPage/types';
import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

export const usePoliciesService = () => {
  const { client } = useClient();

  const getPoliciesTemplate = useCallback(async (): Promise<IPolicyTemplate[]> => {
    const url = getApiUrls.policiesService.getPoliciesTemplate();
    const params = { enabled_rules_count: true };

    const response = await client.get<IPolicyTemplate[]>({
      url,
      allowedStatuses: [200],
      requestConfig: {
        params,
      },
    });

    return camelizeSnakeCaseKeys(response?.data || []) as IPolicyTemplate[];
  }, [client]);

  type GetPolicyRulesResponse = {
    data: IPolicyRule[];
    metadata: object;
  };

  const getPolicyRules = useCallback(async (slug: string): Promise<IPolicyRule[]> => {
    const url = getApiUrls.policiesService.getPolicyRules(slug);

    const response = await client.get<GetPolicyRulesResponse>({
      url,
      allowedStatuses: [200],
    });

    const policyRules = response?.data?.data || [];

    return camelizeSnakeCaseKeys(policyRules) as IPolicyRule[];
  }, [client]);

  return {
    getPoliciesTemplate,
    getPolicyRules,
  };
};
