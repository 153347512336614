export const theLoopColorTheme = [
  {
    base: '#808080',
    text: '#ffffff',
  },
  {
    base: '#ffffff',
    text: '#000000',
  },
  {
    base: '#B8B8FF',
    text: '#000000',
  },
  {
    base: '#8585E8',
    text: '#000000',
  },
  {
    base: '#432F9C',
    text: '#ffffff',
  },
];
