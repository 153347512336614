import { FC } from 'react';

import { SecurityPlanTopRowCard } from '../SecurityPlanTopRowCard/SecurityPlanTopRowCard';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { Vendor } from 'types/enums';
import { iconsMap } from 'utils';

interface Props {
  vendor: Vendor
}

export const SecurityPlanTopRowVendorCard: FC<Props> = ({ vendor }) => (
  <SecurityPlanTopRowCard>
    <JitIcon icon={iconsMap[vendor].icon} size='45' />

  </SecurityPlanTopRowCard>
);
