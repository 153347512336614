import { useCallback, useState } from 'react';

import { createIgnoreRule, deleteIgnoreRule } from 'services/FindingsService/FindingsService';
import { IgnoreReason } from 'types/enums/IgnoreReason';
import { IgnoreRequestSource, IgnoreRuleType, OperatorTypes } from 'types/enums/IgnoreRules';
import { CreateIgnoreRuleRequest, IBaseIgnoreRule, IFinding } from 'types/interfaces';

export const useUpdateFindingsStatus = (reFetchCurrentPage: () => Promise<void>, findings: IFinding[], getTotalFindingsAmount: () => Promise<void>) => {
  const [findingsToIgnore, setFindingsToIgnore] = useState<IFinding[]>([]);
  const [isIgnoreLoading, setIsIgnoreLoading] = useState(false);

  const reloadPage = useCallback(async () => {
    await Promise.all([
      reFetchCurrentPage(),
      getTotalFindingsAmount(),
    ]);
  }, [reFetchCurrentPage, getTotalFindingsAmount]);

  const ignoreFindings = useCallback(async (ignoreRulesToCreate: CreateIgnoreRuleRequest[]): Promise<IBaseIgnoreRule[]> => {
    const responses: Promise<IBaseIgnoreRule | undefined>[] = ignoreRulesToCreate.map((ignoreRulePayload) => createIgnoreRule(ignoreRulePayload));
    const findingsResponses: (IBaseIgnoreRule | undefined)[] = await Promise.all(responses);
    return findingsResponses.filter((findingsResponse) => findingsResponse !== undefined) as IBaseIgnoreRule[];
  }, []);

  const undoIgnoreFindings = useCallback(async (findingsToUndoIgnore: IFinding[]) => {
    const ignoreRulesToUndo = findingsToUndoIgnore.map((finding) => finding.ignoreRulesIds).filter((item) => item !== undefined).flat() as string[];
    const responses: Promise<undefined>[] = ignoreRulesToUndo.map((ignoreRuleId) => deleteIgnoreRule(ignoreRuleId));
    await Promise.all(responses);
  }, []);

  const createIgnoreRuleByType = (finding: IFinding, reason: string, description: string) => ({
    type: IgnoreRuleType.IGNORE,
    reason,
    comment: description,
    source: IgnoreRequestSource.BACKLOG,
    fields: [
      {
        name: 'test_id',
        value: finding.testId,
        operator: OperatorTypes.EQUAL,
      },
    ],
  });

  const createIgnoreRuleByDetails = (finding: IFinding, reason: string, description: string) => ({
    type: IgnoreRuleType.IGNORE,
    reason,
    comment: description,
    source: IgnoreRequestSource.BACKLOG,
    fields: [
      {
        name: 'fingerprint',
        value: finding.fingerprint,
        operator: OperatorTypes.EQUAL,
      },
      {
        name: 'control_name',
        value: finding.controlName,
        operator: OperatorTypes.EQUAL,
      },
      {
        name: 'asset_id',
        value: finding.assetId,
        operator: OperatorTypes.EQUAL,
      },
    ],
  });

  const completeIgnoreFindings = useCallback(async (reason: IgnoreReason, description: string, ignoreByType?: boolean) => {
    setIsIgnoreLoading(true);
    const ignoredRulesToCreate: CreateIgnoreRuleRequest[] = findingsToIgnore.map((finding) => (
      ignoreByType
        ? createIgnoreRuleByType(finding, reason, description)
        : createIgnoreRuleByDetails(finding, reason, description)
    ));
    await ignoreFindings(ignoredRulesToCreate);
    setIsIgnoreLoading(false);
    setFindingsToIgnore([]);
    await reloadPage();
  }, [findingsToIgnore, ignoreFindings, reloadPage]);

  const updateMultipleFindingsStatus = useCallback(async (selectedFindingIds: string[], ignored: boolean) => {
    const findingsToUpdate = findings.filter((finding) => selectedFindingIds.includes(finding.id) && finding.ignored !== ignored);
    if (ignored) {
      setFindingsToIgnore(findingsToUpdate);
    }
    if (!ignored) {
      await undoIgnoreFindings(findingsToUpdate);
      await reloadPage();
    }
  }, [findings, undoIgnoreFindings, reloadPage]);

  const updateFindingIgnoredStatus = useCallback(async (findingToUpdate: IFinding, ignored: boolean) => {
    if (findingToUpdate.ignored !== ignored) {
      if (ignored) {
        setFindingsToIgnore([findingToUpdate]);
      }
      if (!ignored) {
        await undoIgnoreFindings([findingToUpdate]);
        await reloadPage();
      }
    }
    return findings;
  }, [findings, undoIgnoreFindings, reloadPage]);

  return {
    updateMultipleFindingsStatus,
    updateFindingStatus: updateFindingIgnoredStatus,
    findingsToIgnore,
    setFindingsToIgnore,
    completeIgnoreFindings,
    isIgnoreLoading,
  };
};
