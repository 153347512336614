import { IInstallation, IntegrationStatus } from 'types/interfaces';

export const getCurrentInstallationSortedByStatus = (vendor: string, installationsByVendor: { [key: string]: IInstallation[] }) => installationsByVendor[vendor]?.sort((a, b) => {
  if (a.status === IntegrationStatus.CONNECTED && b.status !== IntegrationStatus.CONNECTED) {
    return -1;
  }
  if (a.status !== IntegrationStatus.CONNECTED && b.status === IntegrationStatus.CONNECTED) {
    return 1;
  }
  return 0;
})[0];
