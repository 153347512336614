import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

export const useGetActivationDataComponent = (
  isActivated: boolean,
) => {
  const active = (
    <JitText color={colors.successGreen02} size='m' text='pages.teamsPortal.guidePage.activated' />

  );

  const inactive = (
    <JitText color={colors.lightGray} size='m' text='pages.teamsPortal.guidePage.inactive' />
  );

  const dataComponent = isActivated ? active : inactive;

  return ({ dataComponent });
};
