import { FC } from 'react';

import { DetailGrid } from '../DetailGrid/DetailGrid';

import { JitSidePanelItem } from 'components/JitSidePanel/JitSidePanelItem/JitSidePanelItem';
import { JitText } from 'components/JitText/JitText';
import { config } from 'config';
import colors from 'themes/colors.module.scss';
import { IFinding } from 'types/interfaces';

interface Props {
  finding: IFinding;
}

export const AboutThisIssue: FC<Props> = ({ finding }) => {
  if (!finding.codeAttributes?.lineRange && !finding.referencesUrls.length) {
    return null;
  }
  const calcLinkToLine = () => {
    if (finding.location) {
      return finding.location;
    }
    const { codeAttributes, organization, repository } = finding;
    const { filename = '', branch = '', lineRange = '' } = codeAttributes || {};
    const [lineStart, lineEnd] = lineRange.split('-');
    return config.getGithubFilePathLink({
      org: organization,
      repo: repository,
      branch,
      path: filename,
      lineStart,
      lineEnd,
    });
  };

  const referencesUrlsContent = (
    <div>
      {finding.referencesUrls.map((url, index) => (
        <a key={url} href={url} rel='noreferrer' target='_blank'>
          <JitText
            color={colors.blue02}
            text={`Link ${index + 1}`}
          />
        </a>
      ))}
    </div>
  );

  const locationContent = (
    <a data-testid='findingLocation' href={calcLinkToLine()} rel='noreferrer' target='_blank'>
      <JitText
        color={colors.blue02}
        text={finding.codeAttributes?.filename}
      />
    </a>
  );

  const content = [
    {
      title: 'pages.findings.findingDetails.aboutThisIssue.learnMore',
      body: referencesUrlsContent,
    },
    {
      title: 'pages.findings.findingDetails.aboutThisIssue.location',
      body: locationContent,
    },
  ];

  return (
    <JitSidePanelItem title='pages.findings.findingDetails.aboutThisIssue.title' variant='light'>
      <DetailGrid
        content={content}
        gridTemplateColumnsStyle='25% 75%'
      />
    </JitSidePanelItem>
  );
};
