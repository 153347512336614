import { uniq } from 'lodash';
import { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';

import styles from './AddTag.module.scss';
import { PopoverBody } from './PopoverBody';
import { PopoverFooter } from './PopoverFooter';
import { PopoverHeader } from './PopoverHeader';
import { getFormattedOptions, PREDEFINED_TAGS } from './utils';

import { JitDivider } from 'components/JitDivider/JitDivider';
import { useAssetsContext } from 'context/AssetsContext';
import { TAG_NAMES_TO_HIDE } from 'pages/Resources/ResourcePage/components/ResourceDetails/components/ResourceTags/utils';
import { useAssetService } from 'services/AssetsService/useAssetService';
import { Queries } from 'types/enums/Queries';
import { IAsset } from 'types/interfaces';
import { Tag } from 'types/interfaces/Tag';

interface Props {
  asset?: IAsset;
  onClose: () => void;
  tagToEdit?: Tag;
}

export const PopoverContent: FC<Props> = ({
  asset,
  onClose,
  tagToEdit,
}) => {
  const [currentKeyInput, setCurrentKeyInput] = useState('');
  const [selectedKey, setSelectedKey] = useState('');
  const [currentValueInput, setCurrentValueInput] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const {
    updateAsset,
    getAssetsFilterValues,
  } = useAssetService();

  const { updateAssetLocally } = useAssetsContext();

  const { data: assetFilterValues } = useQuery(
    [Queries.AssetFilterValues],
    getAssetsFilterValues,
  );

  const keyOptions = useMemo(() => {
    if (!assetFilterValues?.tags) return PREDEFINED_TAGS;
    const tagNamesOptions = assetFilterValues.tags.map((tag) => tag.name)
      ?.filter((name) => !TAG_NAMES_TO_HIDE.includes(name)) || [];
    return uniq([...tagNamesOptions, ...PREDEFINED_TAGS]);
  }, [assetFilterValues]);

  useEffect(() => {
    if (tagToEdit) {
      setCurrentKeyInput(tagToEdit.name);
      setSelectedKey(tagToEdit.name);
      setCurrentValueInput(tagToEdit.value);
      setSelectedValue(tagToEdit.value);
    }
  }, [tagToEdit]);

  const formattedKeyOptions = useMemo(() => getFormattedOptions(currentKeyInput, keyOptions), [currentKeyInput, keyOptions]);

  const formattedValueOptions = useMemo(() => {
    const tagValuesOptions = assetFilterValues?.tags?.filter((tag) => tag.name === selectedKey)
      .map((tag) => tag.value) || [];
    return getFormattedOptions(currentValueInput, tagValuesOptions);
  }, [assetFilterValues, currentValueInput, selectedKey]);

  const isDisabled = !selectedKey || !selectedValue || isLoading || !asset;

  const addAssetTag = useCallback(async () => {
    if (!asset) return;

    setIsLoading(true);

    let tags = [...asset.tags];
    if (tagToEdit) {
      tags = tags.filter((tag) => tag.name !== tagToEdit.name);
    }
    tags.push({
      name: selectedKey,
      value: selectedValue,
    });

    const response = await updateAsset({
      assetId: asset.asset_id,
      tags,
    });

    if (response?.status === 200) {
      updateAssetLocally(response?.data);
      onClose();
    }

    setIsLoading(false);
  }, [asset, selectedValue, onClose, selectedKey, tagToEdit, updateAsset, updateAssetLocally]);

  return (
    <div className={styles.popoverWrapper} data-testid='addTagPopoverContent'>
      <PopoverHeader />

      <JitDivider />

      <PopoverBody
        currentKeyInput={currentKeyInput}
        currentValueInput={currentValueInput}
        formattedKeyOptions={formattedKeyOptions}
        formattedValueOptions={formattedValueOptions}
        selectedKey={selectedKey}
        selectedValue={selectedValue}
        setCurrentKeyInput={setCurrentKeyInput}
        setCurrentValueInput={setCurrentValueInput}
        setSelectedKey={setSelectedKey}
        setSelectedValue={setSelectedValue}
      />

      <PopoverFooter
        addAssetTag={addAssetTag}
        isDisabled={isDisabled}
        isLoading={isLoading}
      />
    </div>
  );
};
