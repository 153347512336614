import { FC } from 'react';

import styles from './DevProductivityDescription.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { formatHoursToDisplayText } from 'utils';

interface Props {
  developersCountDisplay: string;
  preProdResolvedFindings: number;
  hoursSaved: number;
}

export const DevProductivityDescription: FC<Props> = ({ developersCountDisplay, preProdResolvedFindings, hoursSaved }) => (
  <div className={styles.wrapper} data-testid='dev-productivity-description'>
    <JitText
      color={colors.lightGray}
      components={{
        developersCount: <JitText
          bold
          display='inline'
          params={{
            developersCount: developersCountDisplay,
          }}
          size='xxl'
          text='pages.securityImpact.devProductivity.developersCount'
        />,
        time: <JitText
          bold
          color={colors.performanceGreen}
          display='inline'
          size='xxl'
          text={formatHoursToDisplayText({
            hours: hoursSaved,
            fullWord: true,
          })}
        />,
      }}
      params={{
        preProdResolvedFindings,
      }}
      size='xxl'
      text='pages.securityImpact.devProductivity.description'
    />
  </div>
);
