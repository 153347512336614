import { FC } from 'react';

import styles from './GraphEmpty.module.scss';

import { Graph } from 'assets';
import { JitEmpty } from 'components/JitEmpty/JitEmpty';
import colors from 'themes/colors.module.scss';

export const GraphEmpty: FC = () => (
  <div className={styles.contentWrapper} data-testid='graphEmpty'>
    <JitEmpty
      description='pages.performance.graphs.emptyGraph.title'
      descriptionColor={colors.gray}
      icon={Graph}
      title='pages.performance.graphs.emptyGraph.description'
      titleColor={colors.lightGray}
    />
  </div>
);

