import { FC } from 'react';

import { ConfirmDialog } from 'components/JitDialogs/ConfirmDialog';
import { i18n } from 'locale/i18n';
import { ActionButtonVariant } from 'types/interfaces';
import { Snapshot } from 'types/interfaces/Metrics/Snapshots';

interface Props {
  snapshot: Snapshot;
  setSelectedSnapshotForDelete: (snapshot: Snapshot | undefined) => void;
  handleDeleteSnapshot: (id: string) => void;
  isLoadingDelete: boolean;
}
export const DeleteSnapshotConfirmDialog: FC<Props> = ({ snapshot, handleDeleteSnapshot, setSelectedSnapshotForDelete, isLoadingDelete }) => {
  const confirmDeleteDialogProps = {
    title: i18n.t('pages.performance.shareSnapshot.history.delete.title'),
    message: i18n.t('pages.performance.shareSnapshot.history.delete.confirmationMessage', { snapshotName: snapshot.name }),
    subMessage: i18n.t('pages.performance.shareSnapshot.history.delete.explanationMessage'),
    actionButtons: [{
      label: i18n.t('pages.performance.shareSnapshot.history.delete.cancelButtonText'),
      variant: ActionButtonVariant.SECONDARY,
      handleClick: () => setSelectedSnapshotForDelete(undefined),
    }, {
      label: `${i18n.t('pages.performance.shareSnapshot.history.delete.deleteButtonText')}`,
      variant: ActionButtonVariant.DANGER,
      isLoading: isLoadingDelete,
      handleClick: () => {
        handleDeleteSnapshot(snapshot.id);
      },
    }],
    onClose: () => setSelectedSnapshotForDelete(undefined),
    isOpen: true,
  };
  return (
    <ConfirmDialog {...confirmDeleteDialogProps} />
  );
};
