import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useGetCreateCentralizedRepoStep } from './useGetCreateCentralizedRepoStep';

import { useGetInstallJitAppStep } from '.';

import { IGithubIntegrationWizardStep, IGithubIntegrationWizardStepStatus } from 'components/GithubIntegrationWizard/GithubIntegrationWizard';
import { constants } from 'globalConstants';

export const useWizardSteps = (incrementStepIndex: () => void, decrementStep: () => void, closeWizard: () => void, handleInstallClick?: () => void) => {
  const { pathname } = useLocation();
  const planSlug = sessionStorage.getItem(constants.GOAL_PLAN_KEY) || undefined;

  const isRedirectedBackFromGHInstallation = useMemo(() => pathname === constants.GITHUB_INSTALLATION_REDIRECT_PATH, [pathname]);
  const isStepCompleted = useCallback((step: IGithubIntegrationWizardStep) => step.status === IGithubIntegrationWizardStepStatus.COMPLETE, []);

  const installJitAppStep = useGetInstallJitAppStep({
    incrementStepIndex,
    isRedirectedBackFromGHInstallation,
    planSlug,
    handleInstallClick,
  });
  const createCentralizedRepoStep = useGetCreateCentralizedRepoStep({
    isPrevStepCompleted: isStepCompleted(installJitAppStep),
    closeWizard,
  });

  return useMemo(() => [installJitAppStep, createCentralizedRepoStep], [installJitAppStep, createCentralizedRepoStep]);
};
