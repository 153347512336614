import { t } from 'i18next';
import { FC, useMemo, useState } from 'react';

import styles from './InviteSlackUsersContent.module.scss';

import { SlackIconOneColor } from 'assets';
import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitAutoCompleteForm } from 'components/JitAutoCompleteForm/components/JitAutocompleteForm';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { useGetSlackUsers } from 'context/SlackContext/hooks/useGetSlackUsers';
import { useToastsContext } from 'context/ToastsContext/ToastsContext';
import { useNotificationService } from 'services/NotificationService/useNotificationService';
import colors from 'themes/colors.module.scss';
import { ToastType } from 'types/enums';
import { ActionButton, ActionButtonVariant } from 'types/interfaces';
import { ISlackUserResponse } from 'types/interfaces/Slack';

interface Props {
  role?: string;
}

export const InviteSlackUsersContent: FC<Props> = ({ role }) => {
  const [selectedUsers, setSelectedUsers] = useState<ISlackUserResponse[]>([]);
  const { slackUsers } = useGetSlackUsers();
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const { showToast } = useToastsContext();

  const { sendInvitation } = useNotificationService();

  const isButtonLoading = useMemo(() => isLoadingRequest, [isLoadingRequest]);

  const inviteActionButton: ActionButton = useMemo(() => ({
    label: 'pages.teams.invite.dialog.sendInvite',
    handleClick: async () => {
      setIsLoadingRequest(true);
      const response = await sendInvitation({
        role,
        recipientsIds: selectedUsers.map((user) => user.id),
      });
      if (response?.status === 200) {
        showToast({
          type: ToastType.Success,
          overrideProps: {
            title: 'pages.teams.invite.dialog.invitationSent',
            secondsToClose: 3,
          },
        });
      }
      setIsLoadingRequest(false);
    },
    variant: ActionButtonVariant.PRIMARY,
    disabled: selectedUsers.length === 0 || isButtonLoading,
    isLoading: isButtonLoading,
    textProps: {
      noWrap: true,
    },
  }), [isButtonLoading, role, selectedUsers, sendInvitation, showToast]);

  const content = useMemo(() => (
    <div className={styles.wrapper} data-testid='InviteSlackUsers'>
      <div className={styles.header}>
        <JitIcon color='lightGray' icon={SlackIconOneColor} />

        <JitText color='lightGray' size='l' text='pages.teams.invite.dialog.inviteViaSlack' />
      </div>

      <div className={styles.autoComplete}>
        <JitAutoCompleteForm
          endAdornment={(
            <div className={styles.endAdornment}>
              <JitText color={colors.gray} noWrap size='m' text='pages.teams.invite.dialog.role' />

              <JitText color={colors.white} noWrap size='m' text='pages.teams.invite.dialog.engineeringManager' />

            </div>
          )}
          freeSolo
          getOptionLabel={(option) => option?.real_name || option.name}
          options={slackUsers || []}
          placeHolder={t('pages.teams.invite.dialog.slackUser')}
          selectedValue={selectedUsers}
          setSelectedValue={(selected) => { setSelectedUsers(selected); }}
        />

        <JitActionButton actionButton={inviteActionButton} />

      </div>
    </div>
  ), [inviteActionButton, selectedUsers, slackUsers]);

  return (
    content
  );
};
