import { FC } from 'react';

import { hoursToShortenedDaysOrHoursString } from '../../utils';
import { MttrInfoIcon } from '../MttrInfoIcon/MetricInfoIcon/MttrInfoIcon';

import styles from './MTTROverview.module.scss';

import { JitText } from 'components/JitText/JitText';
import { RankBadge } from 'pages/InsightsPage/SubPages/PerformancePage/components/GraphHeaderComponents';
import colors from 'themes/colors.module.scss';
import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { IDevProductivity } from 'types/interfaces/Metrics/SecurityImpactMetrics/DevProductivity';
import { ISecurityImpactMTTR } from 'types/interfaces/Metrics/SecurityImpactMetrics/SecurityImpactMTTR';

interface Props {
  securityImpactMTTRMetric: MetricWithLoadingIndication<ISecurityImpactMTTR>
  devProductivityMetric: MetricWithLoadingIndication<IDevProductivity>;
  isExpanded: boolean;
}

export const MTTROverview: FC<Props> = ({
  devProductivityMetric,
  securityImpactMTTRMetric,
  isExpanded,
}) => {
  if ((devProductivityMetric.isLoading || securityImpactMTTRMetric.isLoading) || (!devProductivityMetric.metric || !securityImpactMTTRMetric.metric)) {
    return <div />;
  }
  const devProductivity = devProductivityMetric.metric;
  const currentMttrInHours = securityImpactMTTRMetric.metric.currentPeriodMttr;
  const previousPeriodMttInHours = securityImpactMTTRMetric.metric.previousPeriodMttr;
  const { initialMttr } = securityImpactMTTRMetric.metric;
  const { industryStandard } = securityImpactMTTRMetric.metric;
  const { totalResolvedFindings } = devProductivity;

  const currentMttrFormatted = hoursToShortenedDaysOrHoursString(currentMttrInHours);
  const previousMttrFormatted = hoursToShortenedDaysOrHoursString(previousPeriodMttInHours);
  const initialMttrFormatted = hoursToShortenedDaysOrHoursString(initialMttr);
  const industryStandardFormatted = hoursToShortenedDaysOrHoursString(industryStandard);

  return (
    <div className={styles.wrapper}>
      <JitText className={styles.subtitle} size='l' text='pages.securityImpact.securityPosture.mttrOverview.last90Days' />

      <div className={styles.mainItem}>
        <div className={styles.parameter}>
          <JitText bold size='huge' text={currentMttrFormatted} />
        </div>

        <RankBadge rank={securityImpactMTTRMetric.metric.mttrRank} />

      </div>

      <div className={styles.previousPeriod}>
        <div className={styles.parameter}>
          <JitText bold color={colors.lightGray} size='m' text={previousMttrFormatted} />
        </div>

        <JitText className={styles.descriptor} color={colors.gray} size='m' text='pages.securityImpact.securityPosture.mttrOverview.previous90Days' />
      </div>

      <div className={styles.subItem}>
        <div className={styles.parameter}>
          <JitText bold color={colors.performanceGreen} size='m' text={totalResolvedFindings} />
        </div>

        <JitText className={styles.descriptor} color={colors.gray} size='m' text='pages.securityImpact.securityPosture.mttrOverview.totalResolvedFindings' />
      </div>

      {isExpanded && (
      <>
        <div className={`${styles.subItem} ${styles.gap}`}>
          <div className={styles.parameter}>
            <JitText bold color={colors.lightGray} size='m' text={initialMttrFormatted} />
          </div>

          <JitText className={styles.descriptor} color={colors.gray} size='m' text='pages.securityImpact.securityPosture.mttrOverview.initialMttr' />

          <div className={styles.infoIcon}>
            <MttrInfoIcon
              popperDescription='pages.securityImpact.securityPosture.mttrOverview.initialMttrPopoverContent'
              shouldDisablePopper={false}
            />
          </div>
        </div>

        <div className={styles.subItem}>
          <div className={styles.parameter}>
            <JitText bold color={colors.lightGray} size='m' text={industryStandardFormatted} />
          </div>

          <JitText className={styles.descriptor} color={colors.gray} size='m' text='pages.securityImpact.securityPosture.mttrOverview.industryStandard' />

          <div className={styles.infoIcon}>
            <MttrInfoIcon
              popperDescription='pages.securityImpact.securityPosture.mttrOverview.industryStandardPopoverContent'
              shouldDisablePopper={false}
            />
          </div>
        </div>
      </>
      )}
    </div>
  );
};
