import { camelCase } from 'lodash';
import { FC } from 'react';

import { ISvg } from 'types/interfaces';
import { iconsMap } from 'utils/constants';

export const getLayerIcon = (layer: string): FC<ISvg> | undefined => {
  const layerCamelCase = camelCase(layer || 'code');
  return iconsMap[layerCamelCase]?.icon;
};
