import { FC } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { PieChartCountList } from '../components/PieChartCountList/PieChartCountList';

import { constants } from 'globalConstants';
import { IgnoreFilterKeys } from 'pages/FindingsPage/constants';
import colors from 'themes/colors.module.scss';
import { FindingStatus } from 'types/enums';
import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { IPlanItemFindingsMetric } from 'types/interfaces/Metrics/PerformanceMetrics/PlanItemFindings';
import { FindingCountListItem } from 'types/interfaces/Metrics/PieChartCountList/PieChartCountList';

interface Props {
  planItemFindingsMetric: MetricWithLoadingIndication<IPlanItemFindingsMetric>;
}

export const PlanItemFindings: FC<Props> = ({ planItemFindingsMetric }) => {
  const navigate = useNavigate();
  const { routes: { FINDINGS } } = constants;

  const filteredItems = planItemFindingsMetric.metric?.planItemFindingCounts?.filter((item) => item.count > 0) || [];

  const items: FindingCountListItem[] = filteredItems
    .sort((a, b) => b.count - a.count)
    .map((item, index) => ({
      key: item.key,
      displayName: item.displayName,
      count: item.count,
      color: colors[`pieChartColor${index % 20}`],
    }));

  const onItemClick = (searchValue: string) => {
    navigate({
      pathname: `/${FINDINGS}`,
      search: createSearchParams({
        plan_item: searchValue,
        resolution: FindingStatus.OPEN,
        ignored: IgnoreFilterKeys.NOT_IGNORED,
      }).toString(),
    });
  };

  return (
    <PieChartCountList
      data-testid='planItemFindingsGraph'
      description='pages.performance.graphs.planItemFindings.description'
      isLoading={planItemFindingsMetric.isLoading}
      items={items}
      onItemClick={onItemClick}
      title='pages.performance.graphs.planItemFindings.title'
    />
  );
};

