import Markdown from 'markdown-to-jsx';
import { FC } from 'react';

import { VulnerabilityClassBadge } from '../VulnerabilityClassBadge/VulnerabilityClassBadge';

import styles from './FindingInfo.module.scss';

import { CreateTicketButton } from 'components/CreateTicketButton';
import { useFindingTableContext } from 'components/FindingsTable/FindingsTableContext/FindingsTableContext';
import { JitCopyToClipboardBox } from 'components/JitCopyToClipboardBox/JitCopyToClipboardBox';
import { JitSidePanelItem } from 'components/JitSidePanel/JitSidePanelItem/JitSidePanelItem';
import { JitText } from 'components/JitText/JitText';
import { SeverityBox } from 'components/SeverityBox/SeverityBox';
import { StatusBox } from 'pages/FindingsPage/components/FindingStatusCell/StatusBox';
import { HandleFixSuggestionButton } from 'pages/FindingsPage/components/HandleFixSuggestionButton/HandleFixSuggestionButton';
import { ToggleIgnoreFinding } from 'pages/FindingsPage/components/ToggleIgnoreFinding/ToggleIgnoreFinding';
import { generateMarkdown } from 'pages/FindingsPage/utils/generateMarkdown';
import { IFinding, TicketFinding } from 'types/interfaces';
import { markdownOptionsProps } from 'utils/constants/markdownOptionsProps';
import { parseMarkdown } from 'utils/functions/parseMarkdown';

interface Props {
  finding: IFinding;
}

export const FindingInfo: FC<Props> = ({ finding }) => {
  const {
    setTicketUrlOnFindings,
    onSetIgnoredToSingleRow,
  } = useFindingTableContext();
  const handleCreateTicket = (ticket?: TicketFinding) => {
    if (ticket) {
      setTicketUrlOnFindings([finding], ticket);
    }
  };

  return (
    <JitSidePanelItem
      title='pages.findings.findingDetails.findingInfo.title'
      variant='light'
      wrapperStyleOverrides={{ borderRadius: '8px 8px 0 0' }}
    >
      <div className={styles.textsAndLabels}>
        <div className={styles.textsWrapper}>
          <div className={styles.identifiersWrapper}>
            <VulnerabilityClassBadge finding={finding} />

            <JitText bold size='l' text={finding.name} />
          </div>

          <Markdown className={styles.issueText} {...markdownOptionsProps}>
            {parseMarkdown(finding.issueText)}
          </Markdown>

        </div>

        <div className={styles.labelsWrapper}>

          <StatusBox finding={finding} resolution={finding.resolution} />

          <SeverityBox severity={finding.severity} />
        </div>

      </div>

      <div className={styles.buttonsWrapper}>
        <CreateTicketButton findings={[finding]} onClose={handleCreateTicket} />

        <ToggleIgnoreFinding
          isIgnored={finding.ignored}
          onSetIsIgnored={(ignored) => onSetIgnoredToSingleRow(finding, ignored)}
        />

        <HandleFixSuggestionButton finding={finding} />

        <JitCopyToClipboardBox displayText='' iconSize={14} text={generateMarkdown([finding])} variant='outlined' />

      </div>

    </JitSidePanelItem>
  );
};
