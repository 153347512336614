import { FC } from 'react';

import { StatusIndicationBox } from './StatusIndicationBox';
import styles from './StatusIndicationCellNew.module.scss';

import { JitText } from 'components/JitText/JitText';
import { i18n } from 'locale/i18n';
import {
  getRelevantFindingsIdsForPipelineWorkflows,
} from 'pages/PipelinesPage/PipelineDetailsPage/utils/pipelineFindings';
import colors from 'themes/colors.module.scss';
import { PipelineStatus } from 'types/enums';
import { IDictionary, IMockPipelineNew, ITableCell } from 'types/interfaces';

type CellData = {
  pipeline: IMockPipelineNew
};

export const StatusIndicationCellNew: FC<ITableCell> = ({ cell: { value } }) => {
  const { pipeline } = value as CellData;
  const { status } = pipeline;
  let statusText = '';
  const FindingsStatus: IDictionary<string> = i18n.t('pages.pipelines.statuses.findings', { returnObjects: true });

  const findingsCount = getRelevantFindingsIdsForPipelineWorkflows(pipeline).length;

  if (findingsCount > 0) {
    statusText = findingsCount > 1 ? FindingsStatus.plural : FindingsStatus.singular;
  } else {
    statusText = FindingsStatus.noNewFindings;
  }

  return (
    <div className={styles.statusCellContainer}>
      <div className={styles.statusCellContainerVertical}>
        <StatusIndicationBox status={status} />

        {(status === PipelineStatus.SUCCESS || status === PipelineStatus.FAILED) && (
          <div className={styles.statusExtrasContainer}>
            <JitText
              color={colors.lightGray}
              data-testid='findings-count'
              params={{ count: findingsCount }}
              size='s'
              text={statusText}
            />
          </div>
        )}

        {status === PipelineStatus.ERROR && (
          <div className={styles.statusExtrasContainer}>
            <JitText
              className={styles.statusErrorMsg}
              color={colors.lightGray}
              data-testid='error-message'
              size='s'
              text='pages.pipelines.statuses.errorMessage'
            />
          </div>
        )}
      </div>
    </div>
  );
};
