import { FC, useState } from 'react';

import { AssetsManagementDialog } from 'components/JitDialogs/AssetsManagementDialog/AssetsManagementDialog';
import { TABS } from 'components/JitDialogs/AssetsManagementDialog/constants';
import { AwsConnectDialog } from 'components/JitDialogs/AwsConnectDialog/AwsConnectDialog';
import { useDialogsContext } from 'context/DialogsContext/DialogsContext';
import { Dialogs, Vendor } from 'types/enums';

interface Props {
  integration: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  vendor: string;
}

export const IntegrationDialog: FC<Props> = ({ integration, vendor }) => {
  const [isAssetManagementOpen, setIsAssetManagementOpen] = useState(false);

  const { hideDialog } = useDialogsContext();

  const handleClose = () => hideDialog(Dialogs.Integrations);

  return (
    isAssetManagementOpen ? (
      <AssetsManagementDialog
        defaultTab={TABS.AWS}
        extraDetails={{
          showCreateNewIntegrationButton: false,
        }}
        onClose={() => {
          setIsAssetManagementOpen(false);
          hideDialog(Dialogs.Integrations);
        }}
        tagAllAsCheckedForVendors={[Vendor.AWS]}
      />
    ) : (
      <AwsConnectDialog
        integration={integration}
        onClose={handleClose}
        vendor={vendor}
      />
    )
  );
};
