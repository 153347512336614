import { DataType, IPolicyRule, IRuleTemplate } from '../../types';
import { getConditionSentence } from '../../utils';

type Props = {
  template: IRuleTemplate;
  settings: IPolicyRule['settings'];
  isEditMode: boolean;
  onFieldChange: (field: string, value: DataType, index?: number) => void;
};

export const useRuleBuilder = ({ template, settings, isEditMode, onFieldChange }: Props) => {
  const { baseCondition, filterConditions, inputs: inputTemplates } = template;

  const baseConditionSentence = getConditionSentence({
    conditionSentence: baseCondition,
    inputTemplates,
    data: settings,
    isEditMode,
    onFieldChange,
  });

  const filterConditionSentence = settings?.conditions.map((condition, index) => getConditionSentence({
    conditionSentence: filterConditions,
    inputTemplates,
    data: condition,
    isEditMode,
    onFieldChange,
    index,
  }));

  return {
    baseConditionSentence,
    filterConditionSentence,
  };
};
