export interface NotificationPreference {
  enabled?: boolean;
  channel?: string;
}

export interface SpecificTeamsNotificationPreference {
  score_decrease: NotificationPreference;
}

export interface TenantNotificationsPreferences {
  ignore_findings?: NotificationPreference;
  deployment_with_vulnerabilities?: NotificationPreference;
  findings_on_saved_views?: NotificationPreference;
  new_action_created?: NotificationPreference;
  high_severity_findings?: NotificationPreference;
  period_report?: NotificationPreference;
  security_plan_failures?: NotificationPreference;
}

export interface TeamsNotificationPreference {
  teams_notifications?: Record<string, SpecificTeamsNotificationPreference>; // The string is the team name
}
export type NotificationPreferences = TenantNotificationsPreferences & TeamsNotificationPreference;

export interface DisplayPreferences {
  show_set_as_goal_modal: boolean;
}

export enum TeamsSyncType {
  MANUAL = 'manual',
  AUTO = 'auto',
}

export interface TeamsSyncPreferences {
  sync_type: TeamsSyncType
  provider?: string;
}

export interface IPreferences {
  notifications: NotificationPreferences
  display?: DisplayPreferences;
  teams_sync?: TeamsSyncPreferences;
}
