import { useMemo, useState } from 'react';
import { HeaderGroup, Row, TableBodyPropGetter, TableBodyProps, TablePropGetter, TableProps, usePagination, useTable } from 'react-table';

import { ITableRow } from 'types/interfaces';
import { TableColumn } from 'types/types';

export interface ITableInstance {
  getTableProps: (propGetter?: TablePropGetter<object> | undefined) => TableProps;
  getTableBodyProps: (propGetter?: TableBodyPropGetter<object> | undefined) => TableBodyProps;
  headerGroups: HeaderGroup<object>[];
  prepareRow: (row: Row<object>) => void;
  page: Row<ITableRow>[];
  canPreviousPage: boolean;
  canNextPage: boolean;
  getNextPage: () => void;
  getPreviousPage: () => void;
  pageIndex: number;
  setPageIndex: (updater: number | ((pageIndex: number) => number)) => void;
  paginationKeys: string[];
  setPaginationKeys: (updater: string[] | ((paginationKeys: string[]) => string[])) => void;
}

export const useGetTableInstance = (columns: TableColumn<object>[], data: object[]): ITableInstance => {
  const [paginationKeys, setPaginationKeys] = useState<string[]>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
    },
    usePagination,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
  } = tableInstance;

  const canNextPage = useMemo(() => !!paginationKeys[paginationKeys.length - 1], [paginationKeys]);
  const canPreviousPage = useMemo(() => pageIndex > 0, [pageIndex]);

  const getNextPage = () => {
    if (canNextPage) {
      setPageIndex(pageIndex + 1);
    }
  };

  const getPreviousPage = () => {
    if (canPreviousPage) {
      setPageIndex(pageIndex - 1);
    }
  };

  return {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page: page as Row<ITableRow>[],
    canPreviousPage,
    canNextPage,
    pageIndex,
    setPageIndex,
    getNextPage,
    getPreviousPage,
    paginationKeys,
    setPaginationKeys,
  };
};
