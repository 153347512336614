import { Dispatch, SetStateAction, useCallback } from 'react';

import { IInstallation, IWebsocketNotification } from 'types/interfaces';

interface Props {
  setInstallations: Dispatch<SetStateAction<IInstallation[]>>,
}

export const useHandleInstallationsWebSocketNotification = ({ setInstallations }: Props) => {
  const getInstallationsAfterUpdated = (newInstallation: IInstallation[], updated: IInstallation[]) => newInstallation.map((installation) => {
    const foundInstallation = updated.find((updatedInstallation) => updatedInstallation.installation_id === installation.installation_id);
    if (foundInstallation) {
      return foundInstallation;
    }
    return installation;
  });

  const getInstallationsAfterDeleted = (newInstallations: IInstallation[], deleted: IInstallation[]) => (
    newInstallations.filter((installation) => !deleted.find((deletedInstallation) => deletedInstallation.installation_id === installation.installation_id))
  );

  const handleInstallationsWebSocketNotification = useCallback((notification: IWebsocketNotification<IInstallation>) => {
    const { message: { created, updated, deleted } } = notification;

    setInstallations((curInstallations) => {
      let newInstallations = [...curInstallations];
      newInstallations = created ? [...newInstallations, ...created] : newInstallations;
      newInstallations = updated ? getInstallationsAfterUpdated(newInstallations, updated) : newInstallations;
      newInstallations = deleted ? getInstallationsAfterDeleted(newInstallations, deleted) : newInstallations;
      return newInstallations;
    });
  }, [setInstallations]);

  return { handleInstallationsWebSocketNotification };
};
