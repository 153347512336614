import { FC } from 'react';

import { SecurityPlanTopRowCard } from '../SecurityPlanTopRowCard/SecurityPlanTopRowCard';

import styles from './SecurityPlanTopRowScoreCard.module.scss';

import { CircleInfo } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { i18n } from 'locale/i18n';
import { ScoreGauge } from 'pages/SecurityPlansPage/components/ScoreGauge/ScoreGauge';
import colors from 'themes/colors.module.scss';

interface Props {
  score: number,
  lastUpdated?: string,
}

export const SecurityPlanTopRowScoreCard: FC<Props> = ({ score, lastUpdated }) => {
  const tooltipText = i18n.t('pages.securityPlans.planDetails.score.tooltip.description')
    + (lastUpdated ? i18n.t('pages.securityPlans.planDetails.score.tooltip.lastUpdated', { lastUpdated }) : '');

  return (
    <SecurityPlanTopRowCard width='140px'>
      <div className={styles.wrapper}>
        <div className={styles.score}>
          <ScoreGauge score={score} variant='small' />
        </div>

        <div className={styles.header}>

          <JitText muted size='s' text='pages.securityPlans.planDetails.score.title' />

          <JitTooltip data-testid='SecurityPlanScoreCard-tooltip' followCursor={false} placement='top' title={tooltipText}>
            <JitIcon color={colors.white} icon={CircleInfo} size={15} />
          </JitTooltip>
        </div>

      </div>
    </SecurityPlanTopRowCard>
  );
};
