import { useTenantsState } from '@frontegg/react';
import Avatar from '@mui/material/Avatar';
import { FC, useEffect, useState } from 'react';

import { SelectTenantPopper } from '../SelectTenantPopper/SelectTenantPopper';

import { didUserPickDefaultTenantStorageKey, localStorageTrueValue } from './constants';
import styles from './UserAvatar.module.scss';

import { Logout } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitPopper } from 'components/JitPopper/JitPopper';
import { JitText } from 'components/JitText/JitText';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import colors from 'themes/colors.module.scss';

interface Props {
  isCollapsed: boolean,
  setIsHoverSwitchTenantPopper: (isHover: boolean) => void;
  size?: number;
  showBackgroundHover?: boolean;
  padding?: number;
  margin?: number;
  placement?: 'bottom' | 'right';
  shouldShowArrow?: boolean;
  shouldShowLogout?: boolean;
  isTeamPortalView?: boolean;
}

export const UserAvatar: FC<Props> = ({
  isCollapsed, setIsHoverSwitchTenantPopper, size = 32, showBackgroundHover = true, padding, margin, placement = 'right',
  shouldShowArrow, shouldShowLogout = false, isTeamPortalView,
}) => {
  const { frontEggUser, handleLogout } = useAuthContext();
  const { tenants } = useTenantsState();
  const [isHovered, setIsHovered] = useState(false);
  const [isHoverLogoutSection, setIsHoverLogoutSection] = useState(false);
  const [isMultiTenantPopperOpen, setIsMultiTenantPopperOpen] = useState(false);

  const didUserOpenTenantPicker = window.localStorage.getItem(didUserPickDefaultTenantStorageKey) === localStorageTrueValue;
  const setUserOpenTenantPicker = () => window.localStorage.setItem(didUserPickDefaultTenantStorageKey, localStorageTrueValue);

  const shouldAllowMultiTenant = tenants.length > 1;
  const shouldShowMultiTenantHint = !didUserOpenTenantPicker && shouldAllowMultiTenant;

  useEffect(() => {
    if (isCollapsed) {
      setIsMultiTenantPopperOpen(false);
    }
  }, [isCollapsed]);

  const onPopperClose = () => {
    setIsMultiTenantPopperOpen(false);
    setIsHoverSwitchTenantPopper(false);
  };

  const tenantName = tenants.find((tenant) => tenant.tenantId === frontEggUser.tenantId)?.name || '';
  let wrapperClassName = styles.navbarFooter;
  if (isHovered && showBackgroundHover) {
    wrapperClassName += ` ${styles.backgroundShadow}`;
  }
  const logoutSectionColor = isHoverLogoutSection ? colors.iris : colors.white;
  let avatarWrapperProps = {};
  if (shouldAllowMultiTenant) {
    avatarWrapperProps = {
      onClick: () => {
        setIsMultiTenantPopperOpen(true);
        setUserOpenTenantPicker();
      },
      role: 'button',
    };
  }
  let avatarClassName = '';
  if (shouldAllowMultiTenant) {
    if (shouldShowMultiTenantHint) {
      avatarClassName = styles.multiTenantHint;
    } else {
      avatarClassName = styles.avatarWhenMultiTenantEnabled;
    }
  }

  const content = (
    <div
      className={wrapperClassName}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        backgroundColor: isHovered && showBackgroundHover ? colors.navbarFooterHovered : 'transparent',
        ...(padding && { padding }),
        ...(margin && { margin }),
      }}
    >

      <div className={styles.navbarFooterLeftSide}>
        <div {...avatarWrapperProps} data-testid='avatarWrapper'>
          <Avatar
            className={avatarClassName}
            src={frontEggUser?.profilePictureUrl || ''}
            style={{
              cursor: isTeamPortalView ? 'pointer' : 'default',
            }}
            sx={{
              width: size,
              height: size,
            }}
          />
        </div>

        {!isCollapsed && (
          <div className={styles.textsWrapper}>
            <JitText noWrap overflowHiddenEllipsis size='s' text={tenantName} />

            <JitText muted noWrap overflowHiddenEllipsis size='s' text={frontEggUser.name} />
          </div>
        )}
      </div>

      {!isCollapsed && (
        <div
          className={styles.navbarFooterRightSide}
          onClick={handleLogout}
          onMouseEnter={() => setIsHoverLogoutSection(true)}
          onMouseLeave={() => setIsHoverLogoutSection(false)}
          role='button'
          tabIndex={0}
        >

          {isHovered && (<JitText color={logoutSectionColor} horizontalAlign='center' lineHeight={1} text='Logout' />)}

          <JitIcon color={logoutSectionColor} icon={Logout} size={16} />
        </div>
      )}
    </div>
  );
  return (
    !isTeamPortalView ? content
      : (
        <JitPopper
          content={<SelectTenantPopper handleLogout={handleLogout} shouldShowArrow={shouldShowArrow} shouldShowLogout={shouldShowLogout} />}
          isWithArrow={false}
          onClose={onPopperClose}
          onMouseEnter={() => setIsHoverSwitchTenantPopper(true)}
          onMouseLeave={() => setIsHoverSwitchTenantPopper(false)}
          open={isMultiTenantPopperOpen}
          placement={placement}
          shadowVariant='none'
          transition={false}
        >
          {content}
        </JitPopper>
      )
  );
};
