import { isEmpty } from 'lodash';
import { FC } from 'react';

import { PipelineAssetsList } from '../PipelineAssets/AssetsList/AssetsList';

import { WorkflowNew } from './WorkflowNew';
import styles from './WorkflowsCellNew.module.scss';

import { JitText } from 'components/JitText/JitText';
import { IWorkflowNew } from 'types/interfaces/Pipelines/IPipelineNew';

type Props = {
  workflows: IWorkflowNew;
  prLink: string;
  jobRunner: string;
  jitEventId?: string;
  expected?: number
};

export const WorkflowsNew: FC<Props> = ({ workflows, prLink, jobRunner, jitEventId, expected }) => {
  const emptyWorkflowsElement = (expected === 0) ? (
    <JitText
      bold
      data-testid='no-workflows-text'
      size='m'
      text='pages.pipelines.pipelinesTable.noWorkflows'
    />
  ) : (
    <div>
      <div className={styles.loadingParent}>
        <div className={styles.workflowCellLoader} />

        <div className={styles.loadingText}>
          <JitText
            bold
            data-testid='loading-text'
            size='m'
            text={`pages.pipelines.pipelinesTable.${prLink ? 'collectWorkflowsLoading' : 'createPipelinesLoading'}`}
          />
        </div>
      </div>
    </div>
  );

  const workflowsElement = (
    Object.entries(workflows).map(([workflowSlug, workflow]) => (
      <WorkflowNew
        key={workflowSlug}
        jobRunner={jobRunner}
        prLink={prLink}
        shouldDisableLink
        workflow={workflow}
        workflowSlug={workflowSlug}
      >
        <PipelineAssetsList
          assets={workflow.assets}
          jitEventId={jitEventId}
        />
      </WorkflowNew>
    ))
  );

  return (
    <div className={styles.workflowCell}>
      {isEmpty(workflows) ? emptyWorkflowsElement : workflowsElement}
    </div>
  );
};
