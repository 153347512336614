import { Dispatch, SetStateAction, useMemo } from 'react';

import actionsStyles from '../actionComponents.module.scss';

import { SlackIconIris } from 'assets';
import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { useTenantContext } from 'context/TenantContext';
import { isVendorInstalled } from 'pages/IntegrationsPage/utils';
import { Vendor } from 'types/enums';
import { ActionButtonVariant } from 'types/interfaces';
import { ITeamCheckDetails, TaskKeyEnum } from 'types/interfaces/Teams/ITeam';

export const useGetConfigureSlackAction = (
  check: ITeamCheckDetails | undefined,
  setOpenedDialogKey: Dispatch<SetStateAction<string | null>>,
) => {
  const { installations } = useTenantContext();
  const isSlackConnected = useMemo(() => isVendorInstalled(Vendor.SLACK, installations), [installations]);
  const toolTipText = useMemo(() => check?.info || '', [check?.info]);

  const disabled = useMemo(() => !isSlackConnected, [isSlackConnected]);

  const gradientButton = useMemo(() => (
    isSlackConnected ? (
      <JitGradientButton
        data-testid={`action-${TaskKeyEnum.configureSlack}`}
        onClick={() => setOpenedDialogKey(TaskKeyEnum.configureSlack)}
      >
        <div className={actionsStyles.gradientButton}>
          <JitText size='s' text={check?.options?.button?.label} />
        </div>
      </JitGradientButton>
    ) : (
      <JitTooltip
        key={check?.checkSlug}
        componentsProps={{
          tooltip: {
            sx: {
              maxWidth: 'none',
            },
          },
        }}
        data-testid='InfoTooltip'
        followCursor={false}
        placement='top-start'
        title={toolTipText}
      >

        <JitGradientButton
          data-testid={`action-${TaskKeyEnum.configureSlack}`}
          disabled
          onClick={() => setOpenedDialogKey(TaskKeyEnum.configureSlack)}
        >
          <div className={actionsStyles.gradientButton}>
            <JitText size='s' text={check?.options?.button?.label} />
          </div>
        </JitGradientButton>
      </JitTooltip>
    )
  ), [check?.checkSlug, check?.options?.button?.label, isSlackConnected, setOpenedDialogKey, toolTipText]);

  const button = useMemo(() => (
    <JitActionButton actionButton={{
      disabled,
      label: check?.value || '',
      handleClick: () => setOpenedDialogKey(TaskKeyEnum.configureSlack),
      icon: SlackIconIris,
      variant: ActionButtonVariant.SECONDARY,
    }}
    />
  ), [check?.value, disabled, setOpenedDialogKey]);

  const actionButton = useMemo(
    () => (check?.isCompleted && isSlackConnected ? button : gradientButton),
    [button, check?.isCompleted, gradientButton, isSlackConnected],
  );

  return ({ actionButton });
};
