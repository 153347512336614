import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { app, common } from './en';
import './RequestTypePostProcessor';

const resources = {
  en: {
    app,
    common,
  },
};

i18next.use(initReactI18next).use(LanguageDetector).init({
  resources,
  ns: ['app', 'common'],
  defaultNS: 'app',
  fallbackNS: 'common',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  postProcess: ['requestTypeProcessor'],
  nsSeparator: false,
  react: {
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b'],
    useSuspense: true,
  },
});

export const i18n = i18next;
