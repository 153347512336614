
export interface IGetSbomReportStatusResponse {
  status: SbomReportStatus;
}

export enum SbomReportStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  FAILED = 'FAILED',
  NO_DATA = 'NO_DATA',
  SCANNING = 'SCANNING',
}
