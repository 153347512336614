import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

import colors from 'themes/colors.module.scss';

interface StyledLinearProgressProps extends LinearProgressProps {
  isProgressLine?: boolean;
}

export const StyledLinearProgress = styled(LinearProgress)<StyledLinearProgressProps>(
  ({ value = 0, isProgressLine = false }) => ({
    '.MuiLinearProgress-bar': {
      borderRadius: 8,
      background: `linear-gradient(90deg, ${colors.blue} ${100 - value}%,  ${colors.statusRed} 100%)`,
      transition: 'background 2s ease-out',
    },
    backgroundColor: isProgressLine ? colors.darkGray : `${colors.blue} ${100 - value}%`,
  }),
);
