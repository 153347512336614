import { isEmpty } from 'lodash';

import { TimeAgo, SortDirections } from 'types/enums';
import { IFindingFilter, ITableQueryParams, TIME_AGO_FILTER_KEY, ISortColumn, ITableFiltersQueryParam } from 'types/interfaces';
import { getTimeAgoRange } from 'utils';

const filterToKeyValuePair = (filter: IFindingFilter): object => {
  if (filter.key === TIME_AGO_FILTER_KEY) {
    const { startDate, endDate } = getTimeAgoRange(filter.selectedValue as TimeAgo);
    return {
      created_at_start: startDate.toISOString().split('T')[0],
      created_at_end: endDate.toISOString().split('T')[0],
    };
  }
  const filterValue = Array.isArray(filter.selectedValue) ? filter.selectedValue : [filter.selectedValue];
  return { [filter.key]: filterValue };
};

export const getActiveFiltersKeyValuePair = (filters: IFindingFilter[]) => {
  const relevantFilters = filters.filter((filter) => !isEmpty(filter.selectedValue) && filter.isVisible);
  const formattedFilters = relevantFilters.reduce((currentFormattedFilters: ITableFiltersQueryParam, currentFilter: IFindingFilter) => ({
    ...currentFormattedFilters,
    ...filterToKeyValuePair(currentFilter),
  }), {});

  return formattedFilters;
};

interface ConstructRequestParamsArgs {
  currentActiveColumn: ISortColumn;
  formattedFilters: ITableFiltersQueryParam
  nextPageKey?: string;
}

export const constructRequestParams = ({
  currentActiveColumn,
  nextPageKey,
  formattedFilters,
}: ConstructRequestParamsArgs): ITableQueryParams => ({
  sort: [currentActiveColumn.sortKey || 'created_at'],
  start_key: nextPageKey,
  filters: formattedFilters,
  sort_desc: currentActiveColumn.direction === SortDirections.DESC,
});

type Args = { currentActiveColumn: ISortColumn, filters?: IFindingFilter[], nextPageKey?: string };
export const buildGetFindingsRequestParams = ({ currentActiveColumn, filters = [], nextPageKey }: Args): ITableQueryParams => {
  const relevantFilters = filters.filter((filter) => !isEmpty(filter.selectedValue) && filter.isVisible);
  const formattedFilters = getActiveFiltersKeyValuePair(relevantFilters);

  return constructRequestParams({
    currentActiveColumn,
    nextPageKey,
    formattedFilters,
  });
};
