import { AssetType, Vendor } from 'types/enums';

export const getVendorForAssetType = (assetType: AssetType): Vendor | undefined => {
  switch (assetType) {
    case AssetType.AWS_ACCOUNT:
      return Vendor.AWS;
    case AssetType.GCP_ACCOUNT:
      return Vendor.GCP;
    case AssetType.AZURE_ACCOUNT:
      return Vendor.AZURE;
    case AssetType.AWS_ORG:
      return Vendor.AWS;
    case AssetType.REPO:
      return Vendor.Github;
    case AssetType.ORG:
      return Vendor.Github;
    case AssetType.APPLICATION:
      return Vendor.Domain;
    case AssetType.API:
      return Vendor.ZAP_API;
    case AssetType.WEB:
      return Vendor.ZAP_WEB;
    default:
      return undefined;
  }
};

export const isVendorCloudProvider = (vendor: Vendor | undefined): boolean => ([Vendor.AWS.valueOf(), Vendor.GCP.valueOf(), Vendor.AZURE.valueOf()].includes(vendor || ''));
