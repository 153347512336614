import { FC, useCallback, useMemo, useState } from 'react';

import { GraphLegend } from '../components/GraphLegend/GraphLegend';
import { TooltipFormatter } from '../components/TooltipFormatter/TooltipFormatter';

import { Bar } from './Bar';
import styles from './JitStatusBarChart.module.scss';

import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { hexColorToRGBA } from 'utils';

interface Props {
  completed: number;
  total: number;
  name?: string;
}

const TOTAL = 'total';
const COMPLETED = 'completed';

const legendPayload = [{
  color: colors.successMain,
  value: i18n.t('components.graphs.statusBarChart.completed'),
}, {
  color: hexColorToRGBA(colors.info, 0.5),
  value: i18n.t('components.graphs.statusBarChart.total'),
}];

export const JitStatusBarChart: FC<Props> = ({ completed, total, name }) => {
  const [hoveredKey, setHoveredKey] = useState('');

  const completedPercentage = total > 0 ? Math.floor((completed / total) * 100) : 0;

  const clearTooltipLabel = useCallback(() => {
    setHoveredKey('');
  }, []);

  const tooltip = useMemo(() => (
    hoveredKey
    && (
    <TooltipFormatter
      label={`${i18n.t(`components.graphs.statusBarChart.${hoveredKey}`)}${name ? ` ${name}` : ''}`}
      value={hoveredKey === COMPLETED ? completed : total}
    />
    )
  ), [completed, hoveredKey, name, total]);

  return (
    <div className={styles.wrapper} data-testid='jit-completed-bar-chart'>
      <JitTooltip childrenWrapperClassName={styles.tooltip} placement='top' title={tooltip}>
        <div className={styles.graph}>
          <Bar
            className={styles.completed}
            data-testid='completed-bar'
            label={i18n.t('components.graphs.statusBarChart.completed')}
            onEnter={() => setHoveredKey(COMPLETED)}
            onLeave={clearTooltipLabel}
            width={`${completedPercentage}%`}
          />

          <Bar
            className={styles.total}
            data-testid='total-bar'
            label={i18n.t('components.graphs.statusBarChart.total')}
            onEnter={() => setHoveredKey(TOTAL)}
            onLeave={clearTooltipLabel}
            width={`${100 - completedPercentage}%`}
          />
        </div>
      </JitTooltip>

      <GraphLegend payload={legendPayload} />
    </div>
  );
};
