import { DEPLOYMENT_TRIGGER } from '../constants';

import { ApplicationTypes, IConfigurations } from 'types/interfaces';

export const getPlanItemTriggerConfigurationName = (configurations: IConfigurations, planItemSlug: string, applicationType: ApplicationTypes) => {
  const configuredPlanItem = configurations?.plan_items?.[planItemSlug];
  const configuredEnvironment = configurations?.deployment?.environments?.at(0);
  const configuredApplication = configurations?.applications?.find((application) => application.type === applicationType);
  const isEnvironmentAndApplicationConfigured = configuredPlanItem && configuredEnvironment && configuredApplication;
  if (!isEnvironmentAndApplicationConfigured) return null;
  return Object.keys(configuredPlanItem).find((key) => configuredPlanItem[key].trigger === DEPLOYMENT_TRIGGER);
};
