import { useMemo } from 'react';

import { ResourcePlanItemsStatusesTableData } from '../../ResourcePlanItemsStatusesPage';

import { ResultsCell } from './Cells/ResultsCell/ResultsCell';
import { SecurityItemsCell } from './Cells/SecurityItemsCell/SecurityItemsCell';

import { i18n } from 'locale/i18n';
import { TableColumn } from 'types/types';

export const useGetColumns = (assetName: string, planItemsAmount?: number): TableColumn<object>[] => {
  const { t } = i18n;

  // @ts-ignore - the accessor attribute expects a string, but we need to pass an object
  const columns: TableColumn<object>[] = useMemo(() => [
    {
      Header: t('pages.teams.resourcePlanItemsStatuses.planItemsTable.columns.planItems', { planItemsAmount: planItemsAmount !== undefined ? `(${planItemsAmount})` : '' }),
      accessor: (data: ResourcePlanItemsStatusesTableData) => ({
        name: data.name,
      }),
      Cell: SecurityItemsCell,
      width: '60%',
    }, {
      Header: t('pages.teams.resourcePlanItemsStatuses.planItemsTable.columns.results'),
      accessor: (data: ResourcePlanItemsStatusesTableData) => ({
        passed: data.passed,
        findingsCount: data.findingsCount,
        assetName,
        planItemSlug: data.slug,
      }),
      Cell: ResultsCell,
      width: '40%',
    },
  ], [assetName, planItemsAmount, t]);

  return columns;
};
