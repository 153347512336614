import { FC } from 'react';

import { PageTitles } from 'components/PageTitles/PageTitles';
import { ExportSbomReport } from 'pages/SbomPage/components/ExportSbomReport/ExportSbomReport';
import { SbomTable } from 'pages/SbomPage/components/SbomTable/SbomTable';
import styles from 'pages/SbomPage/SbomPage.module.scss';

export const SbomPage: FC = () => (
  <div className={styles.wrapper}>
    <div className={styles.titleWrapper}>
      <PageTitles subtitle='pages.sbom.subtitle' title='pages.sbom.title' />

      <ExportSbomReport />
    </div>

    <SbomTable />
  </div>
);
