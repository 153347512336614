import { useEffect, useRef, useState } from 'react';

export const useSubscribeToSvgHover = () => {
  const transparentCircleRef = useRef<HTMLElement>(null); // The span element that wraps the svg element
  const [hoveredValue, setHoveredValue] = useState<number | null>(null);

  const handleMouseMove: EventListenerOrEventListenerObject = (e) => {
    if (transparentCircleRef.current) {
      // @ts-ignore
      const { clientX, clientY } = e;
      const { left, top } = transparentCircleRef.current.getBoundingClientRect();
      const elementCenterX = left + transparentCircleRef.current.offsetWidth / 2;
      const elementCenterY = top + transparentCircleRef.current.offsetHeight / 2;

      const angle = Math.atan2(clientY - elementCenterY, clientX - elementCenterX);
      // eslint-disable-next-line no-mixed-operators
      const hoveredPercentage = (angle + Math.PI * 4.5) % (2 * Math.PI) / (2 * Math.PI) * 100;
      // A value between 0 and 100, starting from middle top and going clockwise
      setHoveredValue(hoveredPercentage);
    } else {
      setHoveredValue(null);
    }
  };
  const handleMouseLeave = () => {
    setHoveredValue(null);
  };

  useEffect(() => {
    const actualValueSvg = transparentCircleRef.current?.firstChild?.firstChild;
    if (actualValueSvg) {
      actualValueSvg.addEventListener('mousemove', handleMouseMove);
      actualValueSvg.addEventListener('mouseleave', handleMouseLeave);
    }
    return () => {
      actualValueSvg?.removeEventListener('mousemove', handleMouseMove);
      actualValueSvg?.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  return {
    hoveredValue,
    transparentCircleRef,
  };
};
