import Input from '@mui/material/Input';
import { ChangeEvent, FC, useState } from 'react';

import styles from './ConfigurationsGithubBranchProtectionRequireApproval.module.scss';
import { ConfigurationsGithubBranchProtectionRequiredCheckTile } from './ConfigurationsGithubBranchProtectionRequiredCheckTile';

import { JitButton } from 'components/JitButton/JitButton';
import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import { JitText } from 'components/JitText/JitText';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { stopPropagation } from 'utils';

interface Props {
  requiredChecks: string[];
  onChange: (requireStatusChecks: boolean) => void;
  handleChangeRequiredChecks: (newValue: string[]) => void;
}

export const ConfigurationsGithubBranchProtectionRequireStatusChecks: FC<Props> = ({ requiredChecks, onChange, handleChangeRequiredChecks }) => {
const { fields: BranchProtectionConfigurationFields }: any = i18n.t('configurations.githubBranchProtection', { returnObjects: true }); // eslint-disable-line
  // @typescript-eslint/no-explicit-any
  const [requireStatusChecks, setRequireStatusChecks] = useState<boolean>(!!requiredChecks?.length);
  const [addStatusCheckState, setAddStatusCheckState] = useState<string>('');

  const handleCheckboxChecked = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onChange(checked);
    setRequireStatusChecks(checked);
    handleChangeRequiredChecks([]);
  };

  const handleAddStatusCheckInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setAddStatusCheckState(event.target.value);
  };

  const handleCheckAdded = () => {
    if (addStatusCheckState && !requiredChecks.includes(addStatusCheckState)) {
      handleChangeRequiredChecks([...requiredChecks, addStatusCheckState]);
    }
    setAddStatusCheckState('');
  };

  const shouldAddDisabled = !addStatusCheckState || requiredChecks.some((requiredCheck) => requiredCheck === addStatusCheckState);

  return (
    <div className={styles.branchProtectionSameLineContainer}>
      <JitCheckbox
        checked={requireStatusChecks}
        data-testid='ConfigurationsGithubBranchProtectionRequireStatusChecks-checkbox'
        onChange={handleCheckboxChecked}
        onClick={stopPropagation}
        style={{ marginRight: '0.5rem' }}
      />

      <div className={styles.branchProtectionBody}>
        <JitText bold text={BranchProtectionConfigurationFields.requireStatusChecks.label} />

        <JitText text={BranchProtectionConfigurationFields.requireStatusChecks.description} />

        {requireStatusChecks && (
          <>
            <div className={styles.branchProtectionSameLineContainer} style={{ marginTop: 10 }}>
              <Input
                autoFocus
                data-testid='ConfigurationsGithubBranchProtectionRequireStatusChecks-input'
                disableUnderline
                fullWidth
                name='addChecks'
                onChange={handleAddStatusCheckInputChanged}
                placeholder={BranchProtectionConfigurationFields.requireStatusChecks.textboxHint}
                sx={{
                  color: 'white',
                  border: `1px solid ${colors.darkGray}`,
                  padding: '0 0.5rem',
                  fontSize: 14,
                  borderRadius: '4px',
                  '&.Mui-focused': {
                    border: `1px solid ${colors.iris}`,
                  },
                }}
                value={addStatusCheckState}
              />

              <JitButton
                data-testid='addButton'
                disabled={shouldAddDisabled}
                onClick={handleCheckAdded}
                style={{
                  marginRight: '1.5rem',
                  marginLeft: '1.5rem',
                }}
                variant='contained'
              >
                <JitText text={BranchProtectionConfigurationFields.requireStatusChecks.add} />
              </JitButton>
            </div>

            {!!requiredChecks?.length && (
            <>
              <JitText style={{ marginTop: 10 }} text={BranchProtectionConfigurationFields.requireStatusChecks.listOfChecksThatMustPass} />

              <div style={{
                marginTop: 4,
                display: 'flex',
                flexWrap: 'wrap',
                gap: 10,
              }}
              >
                {
                      requiredChecks.map((check) => (
                        <div key={check} style={{ display: 'inline-block' }}>
                          <ConfigurationsGithubBranchProtectionRequiredCheckTile
                            checkName={check}
                            removeCheckHandler={() => {
                              handleChangeRequiredChecks(requiredChecks.filter((requiredCheck) => requiredCheck !== check));
                            }}
                          />
                        </div>
                      ))
                    }
              </div>
            </>

            )}
          </>
        )}
      </div>
    </div>
  );
};
