import { FC, useCallback } from 'react';

import { IPolicyRule, IPolicyTemplate } from '../../types';
import { AddNewRule } from '../AddNewRule/AddNewRule';
import { PolicyRule } from '../PolicyRule/PolicyRule';

import styles from './Policy.module.scss';

import { JitChip } from 'components/JitChip/JitChip';
import { i18n } from 'locale/i18n';

interface Props {
  policyTemplate: IPolicyTemplate;
  rulesData: IPolicyRule[];
}

export const Policy: FC<Props> = ({ policyTemplate, rulesData }) => {
  const handleAddRule = useCallback(() => {
    // TODO: Implement
  }, []);

  return (
    <div className={styles.wrapper} data-testId='policyWrapper'>
      {rulesData.map((rule, index) => (
        <div key={rule.id} className={styles.ruleWrapper}>
          <div className={styles.ruleCard}>
            <PolicyRule index={index} ruleData={rule} ruleTemplate={policyTemplate.ruleTemplates} />
          </div>

          {index !== 0 && (
            <div className={styles.chipWrapper}>
              <JitChip label={i18n.t('pages.policies.rule.divider.or')} />
            </div>
          )}
        </div>
      ))}

      <AddNewRule onClick={handleAddRule} policyName={policyTemplate.display.displayName} />
    </div>
  );
};
