import { t } from 'i18next';
import { FC } from 'react';
import { useQuery } from 'react-query';

import styles from './ResolvedIssuesWidget.module.scss';

import { JitStatusBarChart } from 'components/graphs/StatusBarChart/JitStatusBarChart';
import { JitText } from 'components/JitText/JitText';
import { GraphEmpty, GraphLoading } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/components';
import { useFindingsService } from 'services/FindingsService/useFindingService';
import { FindingStatus } from 'types/enums';
import { Queries } from 'types/enums/Queries';
import { ITableQueryParams } from 'types/interfaces';
import { IGroupByCount } from 'types/interfaces/Counts/ICounts';

type Props = {
  queryParams: ITableQueryParams;
};

export const name = t('pages.securityImpactV2.sections.securityProgress.widgets.resolvedIssues.type');

export const ResolvedIssuesWidget: FC<Props> = ({ queryParams }) => {
  const { getFindingsCount } = useFindingsService();
  const { data, isLoading, isError } = useQuery([Queries.FindingsCount, queryParams], () => getFindingsCount(queryParams));

  if (isLoading) return <GraphLoading />;

  const findingsCount = (data?.data || []) as IGroupByCount[];

  const openCount = findingsCount.find(({ key }) => key === FindingStatus.OPEN)?.count || 0;
  const fixedCount = findingsCount.find(({ key }) => key === FindingStatus.FIXED)?.count || 0;
  const ignoredCount = findingsCount.find(({ key }) => key === FindingStatus.IGNORED)?.count || 0;
  const totalResolved = fixedCount + ignoredCount;
  const totalDetected = openCount + totalResolved;

  const resolvedPercentage = `${totalDetected > 0 ? Math.floor((totalResolved / totalDetected) * 100) : 0}%`;

  if (isError || !totalDetected) return <GraphEmpty />;

  return (
    <div className={styles.wrapper} data-testid='solvedIssuesWidget'>
      <div className={styles.header}>
        <JitText bold size='xhuge' text={`${resolvedPercentage}`} />

        <JitText muted size='s' text='pages.securityImpactV2.sections.securityProgress.widgets.resolvedIssues.label' />
      </div>

      <div className={styles.chart}>
        <JitStatusBarChart
          completed={totalResolved}
          name={name}
          total={totalDetected}
        />
      </div>
    </div>
  );
};
