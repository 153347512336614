import { useNavigate } from 'react-router-dom';

import { constants } from 'globalConstants';

export const useNavigatePipelinesDemo = () => {
  const navigate = useNavigate();
  const navigatePipelinesDemo = () => {
    navigate(`/${constants.routes.PIPELINES}`);
  };

  return { navigatePipelinesDemo };
};
