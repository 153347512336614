import { FC } from 'react';

import { Background } from '../Background/Background';

import { WelcomePageContent } from './components/WelcomePageContent/WelcomePageContent';
import styles from './WelcomePage.module.scss';

export const WelcomePage: FC = () => (
  <div className={styles.root} data-testid='teams-portal-welcome-page'>
    <Background />

    <div className={styles.content}>
      <WelcomePageContent />
    </div>

  </div>
);
