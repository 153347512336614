import { constants } from 'globalConstants';

export const dateStringToNumber = (date: string): number => new Date(date).getTime();
export const getGraphXAxisDomain = (ticks: number[]): [number, number] => [ticks[0], ticks[ticks.length - 1]];
export const getXAxisTicks = (startDate: Date, endDate: Date): number[] => {
  const daysBetween = Math.ceil((endDate.getTime() - startDate.getTime()) / constants.time.DAY);
  const AVAILABLE_TICKS_AMOUNT = 10; // Desired amount of ticks on the x-axis (to fit the graph width)
  const daysJump = Math.ceil(daysBetween / AVAILABLE_TICKS_AMOUNT);
  let currentDay = startDate.getTime();

  const xAxisTicks = [startDate.getTime()];

  while (currentDay + constants.time.DAY * daysJump <= endDate.getTime()) {
    currentDay += constants.time.DAY * daysJump;
    xAxisTicks.push(currentDay);
  }

  return xAxisTicks;
};

