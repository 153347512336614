import { useMemo } from 'react';

import { ResultsCell } from './Cells/ResultsCell/ResultsCell';
import { SecurityItemsCell } from './Cells/SecurityItemsCell/SecurityItemsCell';

import { i18n } from 'locale/i18n';
import { ResourcePlanItemsStatusesTableData } from 'pages/TeamsPage/components/ResourcePlanItemsStatusesPage/ResourcePlanItemsStatusesPage';
import { PlanItemTitleWithTooltip } from 'pages/TeamsPortal/components/PlanItemTitleWithTooltip/PlanItemTitleWithTooltip';
import { TableColumn } from 'types/types';

export const useGetColumns = (assetName: string, planItemsAmount: number, findingsCounts: Record<string, number>): TableColumn<object>[] => {
  const { t } = i18n;

  // @ts-ignore - the accessor attribute expects a string, but we need to pass an object
  const columns: TableColumn<object>[] = useMemo(() => [
    {
      Header: () => (
        <PlanItemTitleWithTooltip
          columnName={t('pages.teamsPortal.resourcePage.columns.planItems', { planItemsAmount: planItemsAmount !== undefined ? `(${planItemsAmount})` : '' })}
        />
      ),
      id: 'plan-items',
      accessor: (data: ResourcePlanItemsStatusesTableData) => ({
        name: data.name,
      }),
      Cell: SecurityItemsCell,
      width: '60%',
    }, {
      Header: t('pages.teamsPortal.resourcePage.columns.results'),
      accessor: (data: ResourcePlanItemsStatusesTableData) => ({
        passed: data.passed,
        findingsCount: findingsCounts[data.slug] || 0,
        assetName,
        planItemSlug: data.slug,
      }),
      Cell: ResultsCell,
      width: '40%',
    },
  ], [assetName, findingsCounts, planItemsAmount, t]);
  return columns;
};
