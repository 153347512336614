import { FC } from 'react';

import styles from './ShapesBackground.module.scss';

import { QuestionMark, TeamSymbol1, TeamSymbol2, TeamSymbol3 } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';

interface Props {
  fullSize?: boolean;
}

export const ShapesBackground: FC<Props> = ({ fullSize = true }) => {
  const size = fullSize ? 300 : 200;

  const backgroundClasses = fullSize ? `${styles.root} ${styles.background}` : styles.root;
  return (
    <div
      className={backgroundClasses}
      data-testid='shapes-background'
      style={{ height: size,
        width: size }}
    >
      {fullSize && <JitIcon data-testid='question-mark-icon' icon={QuestionMark} size={60} />}

      <img alt='flower' className={styles.flower} data-testid='flower' src={TeamSymbol3} />

      {fullSize && <img alt='circle' className={styles.circle} data-testid='circle' src={TeamSymbol1} />}

      <img alt='square' className={styles.square} data-testid='square' src={TeamSymbol2} />
    </div>
  );
};
