import { useCallback, useEffect, useState } from 'react';

import { useTenantService } from 'services/TenantService/useTenantService';
import { Vendor } from 'types/enums';
import { IUserVendorServerRequest } from 'types/interfaces/IUserVendor';

export const useGetSlackUserVendor = () => {
  const [slackUsersVendors, setSlackUsersVendors] = useState<IUserVendorServerRequest[] | undefined>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { getUserVendorByVendor } = useTenantService();
  const initSlackUserVendors = useCallback(async () => {
    setIsLoading(true);
    const userVendors = await getUserVendorByVendor(Vendor.SLACK);
    setSlackUsersVendors(userVendors?.data);
    setIsLoading(false);
  }, [getUserVendorByVendor]);

  useEffect(() => {
    initSlackUserVendors();
  }, [initSlackUserVendors]);

  return {
    setSlackUsersVendors,
    slackUsersVendors,
    isLoading,
  };
};
