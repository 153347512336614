import { FC } from 'react';

import { GraphEmpty, GraphLoading } from '../components';
import styles from '../Graphs.module.scss';

import { GraphBody } from './GraphBody/GraphBody';
import { GraphHeader } from './GraphHeader/GraphHeader';

import { TimeAgo } from 'types/enums';
import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { IExposureWindowMetric } from 'types/interfaces/Metrics/PerformanceMetrics/ExposureWindow';

interface Props {
  exposureWindowMetric: MetricWithLoadingIndication<IExposureWindowMetric>;
  xAxisTicks: number[];
  period: TimeAgo;
}

export const ExposureWindowGraph: FC<Props> = ({ exposureWindowMetric, xAxisTicks, period }) => {
  const days = exposureWindowMetric.metric?.days ?? [];
  return (
    <div className={styles.metricWrapper} data-testid='exposureWindowGraph'>
      <GraphHeader
        description='pages.performance.graphs.exposureWindow.description'
        isLoading={exposureWindowMetric.isLoading}
        popperDescription='pages.performance.graphs.exposureWindow.widget.description'
        popperTitle='pages.performance.graphs.exposureWindow.widget.title'
        title='pages.performance.graphs.exposureWindow.title'
      />

      {exposureWindowMetric.isLoading && <GraphLoading />}

      {!exposureWindowMetric.isLoading && (days.length > 0 ? (
        <GraphBody
          days={days}
          exposureWindowMetric={exposureWindowMetric}
          period={period}
          xAxisTicks={xAxisTicks}
        />
      ) : (
        <GraphEmpty />
      ))}
    </div>
  );
};
