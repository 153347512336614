import { useCallback } from 'react';

import { usePlansContext } from '../PlansContext';

import { IPlanItemDetails } from 'types/interfaces';

export const useUpdatePlanItem = () => {
  const { setPlans } = usePlansContext();

  const updatePlanItem = useCallback((planSlug: string, planItem: IPlanItemDetails) => {
    setPlans((prevPlans) => ({
      ...prevPlans,
      [planSlug]: {
        ...prevPlans[planSlug],
        items: {
          ...prevPlans[planSlug].items,
          [planItem.slug]: planItem,
        },
      },
    }
    ));
  }, [setPlans]);

  return {
    updatePlanItem,
  };
};
