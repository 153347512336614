import { FC } from 'react';

import styles from './SecurityPlanTopRowDetails.module.scss';
import { SecurityPlanTopRowScoreCard } from './SecurityPlanTopRowScoreCard/SecurityPlanTopRowScoreCard';
import { SecurityPlanTopRowTextCard } from './SecurityPlanTopRowTextCard/SecurityPlanTopRowTextCard';
import { SecurityPlanTopRowVendorCard } from './SecurityPlanTopRowVendorCard/SecurityPlanTopRowVendorCard';

import { getScore } from 'context/PlansContext/utils/getPlanCoverage';
import colors from 'themes/colors.module.scss';
import { Vendor } from 'types/enums';
import { IPlanDetails } from 'types/interfaces';

interface Props {
  plan: IPlanDetails
}

export const SecurityPlanTopRowDetails: FC<Props> = ({ plan }) => {
  const score = getScore(plan);

  const allPlanItems = plan.allItemSlugs.size;
  const activePlanItems = plan.activeItemSlugs.size;
  const failedActivePlanItems = plan.failedItemSlugs.size;
  const passedActivePlanItems = plan.passedItemSlugs.size;

  const lastScoreUpdate = Object.values(plan.items || []).map((item) => item.last_evaluated).sort().reverse()[0];

  const planVendor = plan.author && plan.author in Vendor ? Vendor[plan.author as keyof typeof Vendor] : Vendor.JIT;

  return (
    <div className={styles.wrapper} data-testid='security-plan-top-row-details'>
      <SecurityPlanTopRowScoreCard lastUpdated={lastScoreUpdate} score={score} />

      <SecurityPlanTopRowTextCard subtitle='pages.securityPlans.planDetails.securityControls' title={allPlanItems.toString()} width='140px' />

      <SecurityPlanTopRowTextCard subtitle='pages.securityPlans.planDetails.activated' title={activePlanItems.toString()} />

      <SecurityPlanTopRowTextCard subtitle='pages.securityPlans.planDetails.passed' title={passedActivePlanItems.toString()} titleColor={colors.performanceGreen} />

      <SecurityPlanTopRowTextCard subtitle='pages.securityPlans.planDetails.failed' title={failedActivePlanItems.toString()} titleColor={colors.failRed} />

      <SecurityPlanTopRowVendorCard vendor={planVendor} />

    </div>
  );
};
