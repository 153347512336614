/* eslint-disable react/no-multi-comp */
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isNumber } from 'lodash';
import { FC, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import styles from './ResourceDetails.module.scss';

import { EditPen } from 'assets';
import { GradientLock } from 'components/GradientLock/GradientLock';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitPopper } from 'components/JitPopper/JitPopper';
import { JitText } from 'components/JitText/JitText';
import { AddRemovePopover } from 'pages/FindingsPage/components/FindingDetails/components/Priority/children/AddRemoveFactor/AddRemovePopover';
import { PriorityBox } from 'pages/FindingsPage/components/PriorityBox/PriorityBox';
import { PriorityBreakdown } from 'pages/Resources/components/PriorityBreakdown/PriorityBreakdown';
import { PriorityFactorsList } from 'pages/Resources/components/PriorityFactorsList/PriorityFactorsList';
import { ResourceTags } from 'pages/Resources/ResourcePage/components/ResourceDetails/components/ResourceTags/ResourceTags';
import { ResourceTeamsList } from 'pages/Resources/ResourcePage/components/ResourceDetails/components/ResourceTeamsList/ResourceTeamsList';
import { aggregateFindingsCount } from 'pages/Resources/ResourcesPage/component/FindingsCountCard/utils';
import { fetchFindingsCount } from 'services/FindingsService';
import colors from 'themes/colors.module.scss';
import { GraphEntityType } from 'types/enums/ContextGraphEntityType';
import { Queries } from 'types/enums/Queries';
import { TagNames } from 'types/enums/Tags';
import { IAsset, ServerFindingKeys } from 'types/interfaces';
import { IPriorityEntity, IPriorityFields } from 'types/interfaces/Graph/IPriorityFactor';

interface Props {
  asset?: IAsset;
}

interface DetailItemProps {
  title: string;
  content: JSX.Element;
}

const DetailItem: FC<DetailItemProps> = ({
  title,
  content,
}) => (
  <div className={styles.item} data-testid={`resourceDetailsItem-${title}`}>
    <JitText muted noWrap size='s' text={title} />

    {content}
  </div>
);

export const ResourceDetails: FC<Props> = ({ asset }) => {
  const { useKnowledgeGraph } = useFlags();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const params = useMemo(() => ({
    filters: {
      resolution: 'OPEN',
      ignored: false,
      asset_name: [asset?.asset_name || ''],
    },
    group_by: ServerFindingKeys.priority_score,
  }), [asset]);
  const { data: findingsCount } = useQuery(
    [Queries.FindingsCount, params],
    () => fetchFindingsCount(params),
    {
      keepPreviousData: true,
      enabled: !!asset,
    },
  );

  const handleClick = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const onClose = () => {
    setIsDialogOpen(false);
  };

  const findingsPriorityBreakdown = useMemo(() => aggregateFindingsCount(findingsCount), [findingsCount]);

  const priorityFields: IPriorityFields = useMemo(() => ({
    id: asset?.asset_id ?? '',
    name: asset?.asset_name ?? '',
    priorityFactors: asset?.priority_factors ?? [],
    priorityScore: asset?.priority_score ?? 0,
    manualFactors: asset?.manual_factors ?? {
      added: [],
      removed: [],
    },
    type: GraphEntityType.ASSET,
  }), [asset]);

  const priorityScore = isNumber(asset?.priority_score) ? (
    <JitPopper
      content={<AddRemovePopover entity={asset as IPriorityEntity} onClose={onClose} priorityFields={priorityFields} />}
      isWithArrow={false}
      onClose={onClose}
      open={isDialogOpen}
      placement='auto'
      shadowVariant='none'
      withBackdrop
    >

      <div
        className={styles.manageClickableItem}
        onClick={handleClick}
        role='button'
        tabIndex={0}
      >

        <PriorityBox priorityScore={asset!.priority_score} />

        <JitIcon
          color={colors.iris}
          icon={EditPen}
          size={14}
          style={{ alignSelf: 'center' }}
        />

        <JitText color={colors.blue02} text='Manage' />
      </div>
    </JitPopper>

  ) : <JitText text='na' />;

  const hasTeams = useMemo(() => asset?.tags.some((tag) => tag.name === TagNames.Teams), [asset]);
  return (
    <div className={styles.wrapper} data-testid='resourceDetails'>
      {hasTeams && (
        <DetailItem
          content={<ResourceTeamsList asset={asset} />}
          title='pages.risks.resources.resourcePage.resourceDetails.team'
        />
      )}

      <DetailItem
        content={useKnowledgeGraph ? priorityScore : <GradientLock />}
        title='pages.risks.resources.resourcePage.resourceDetails.riskScore'
      />

      <DetailItem
        content={useKnowledgeGraph ? <PriorityFactorsList factors={asset?.priority_factors || []} maxWidth='25vw' /> : <GradientLock />}
        title='pages.risks.resources.resourcePage.resourceDetails.priorityFactors'
      />

      <DetailItem
        content={useKnowledgeGraph ? <PriorityBreakdown {...findingsPriorityBreakdown} textSize='m' /> : <GradientLock />}
        title='pages.risks.resources.resourcePage.resourceDetails.findingsByCriticality'
      />

      <DetailItem
        content={<ResourceTags asset={asset} maxWidth='35vw' />}
        title='pages.risks.resources.resourcePage.resourceDetails.tags'
      />

    </div>
  );
};
