import { FC } from 'react';

import styles from './AddRemovePopover.module.scss';

import { CircleQuestion } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { PriorityFactorsBox } from 'components/PriorityFactorsBox/PriorityFactorsBox';
import { PriorityBox } from 'pages/FindingsPage/components/PriorityBox/PriorityBox';
import colors from 'themes/colors.module.scss';
import { IPriorityFactor } from 'types/interfaces/Graph/IPriorityFactor';

interface Props {
  factors: IPriorityFactor[];
  selectedFactor?: string;
  toggleFactor?: (factorName: string) => void;
  showQuestionDescription?: boolean;
  editable?: boolean;
  viewMode?: boolean;
}

export const FactorsRow: FC<Props> = ({ factors, selectedFactor, toggleFactor, showQuestionDescription, editable, viewMode }) => {
  const sourceFactor = factors.find((factor) => selectedFactor === factor.key) || factors[0];
  const onFactorClick = (factorName: string) => {
    if (!toggleFactor) return;
    const isMultiFactors = factors.length > 1;
    if (isMultiFactors && selectedFactor && selectedFactor !== factorName) {
      toggleFactor(selectedFactor); // Disable the other activated factor
    }

    toggleFactor(factorName);
  };

  return (
    <div className={styles.factor}>
      <PriorityFactorsBox
        action={{
          onClick: onFactorClick,
          selectedFactor,
        }}
        editable={editable}
        factors={factors}
        viewMode={viewMode}
      />

      <div className={styles.factorRightSide}>

        <PriorityBox
          disabled={!selectedFactor}
          dynamicColor={false}
          priorityScore={sourceFactor.defaultWeight}
          withBorder={false}
        />

        <JitTooltip
          followCursor={false}
          offset={[0, 8]}
          placement='top'
          title={showQuestionDescription ? sourceFactor.descriptionQuestion : sourceFactor.description}
        >
          <JitIcon
            color={colors.gray}
            icon={CircleQuestion}
            size={14}
          />
        </JitTooltip>
      </div>
    </div>
  );
};
