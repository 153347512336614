import { FC, PropsWithChildren } from 'react';

import styles from './LabelAndTooltip.module.scss';

import { CircleInfo } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import colors from 'themes/colors.module.scss';

export type TooltipPlacement = 'top' | 'bottom' | 'left' | 'right';

interface Props {
  label: string;
  subLabel?: string;
  tooltipText?: string;
  withSpaceBetween?: boolean;
  tooltipPlacement?: TooltipPlacement;
}

export const LabelAndTooltip: FC<PropsWithChildren<Props>> = ({ label, tooltipText, withSpaceBetween = true, subLabel, tooltipPlacement = 'left', children }) => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: withSpaceBetween ? 'space-between' : 'flex-start',
      paddingBottom: '0.25rem',
      flexDirection: 'row',
    }}
  >
    <div className={styles.textsWrapper}>
      <JitText color={colors.white} text={label} />

      {subLabel && <JitText color={colors.lightGray} text={subLabel} />}

      {children}
    </div>

    {tooltipText && (
      <JitTooltip followCursor={false} placement={tooltipPlacement} title={tooltipText}>
        <div>
          <JitIcon
            icon={CircleInfo}
            size={15}
            style={{
              margin: '0 0.5rem',
            }}
          />
        </div>
      </JitTooltip>
    )}
  </div>
);
