import { FC } from 'react';

import { IWorkflowNew } from '../../../../types/interfaces/Pipelines/IPipelineNew';
import { getEnvironment } from '../../utils/utilsNew';

import styles from './ResourceNameAndTypeCellV3.module.scss';
import { ResourceNameAndTypeHeaderV3 } from './ResourceNameAndTypeHeaderV3';

import { CalenderNotebook, Clock } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import colors from 'themes/colors.module.scss';
import { ISvg, ITableCell } from 'types/interfaces';

interface IPipelineNewResourceCellParam {
  id: string,
  assetName: string,
  assetType: string,
  vendor: string,
  owner: string,
  start?: string,
  startTime?: string,
  duration?: string,
  userVendorId?: string,
  userVendorName?: string,
  eventType: string,
  workflows: IWorkflowNew,
  environment?: string
}

export const ResourceNameAndTypeCellV3: FC<ITableCell> = ({ cell: { value } }) => {
  const { workflows, eventType, assetName, assetType, vendor, owner, start, startTime, duration, id, userVendorId, userVendorName, environment } = value as IPipelineNewResourceCellParam;
  const getResourceAdditionalData = (tooltipTitle: string, text: string, testId: string, startIcon?: FC<ISvg>, prefixText?: string, optionalStyling?: string, endIcon?: FC<ISvg>) => (
    <JitTooltip followCursor={false} placement='top' title={tooltipTitle}>
      <div className={`${styles.additionalDataElement} ${optionalStyling}`}>

        {startIcon && <JitIcon icon={startIcon} size={16} /> }

        {prefixText && (
          <JitText
            color={colors.lightGray}
            size='m'
            text={prefixText}
          />
        )}

        <JitText
          color={colors.lightGray}
          data-testid={testId}
          size='m'
          text={text}
        />

        {endIcon && <JitIcon icon={endIcon} size={16} />}
      </div>
    </JitTooltip>
  );

  const getInitiatorData = () => {
    const vendorImage = userVendorId && `https://avatars.githubusercontent.com/u/${userVendorId}`;
    return (
      <div className={styles.additionalDataElement} data-testid='resource-initiator'>
        {vendorImage && <img alt='' className={styles.initiatorImg} src={vendorImage} />}

        {userVendorName
              && (
              <TooltipOnlyOnOverflow tooltipText={userVendorName}>
                <JitText color={colors.lightGray} noWrap overflowHiddenEllipsis size='m' text={userVendorName} />
              </TooltipOnlyOnOverflow>
              )}
      </div>
    );
  };
  return (
    <div className={styles.cellWrapper}>
      <ResourceNameAndTypeHeaderV3
        assetName={assetName}
        assetType={assetType}
        disableLink
        environment={environment || getEnvironment(workflows) || ''}
        eventType={eventType}
        id={id}
        owner={owner}
        vendor={vendor}
        workflows={workflows}
      />

      <div className={styles.additionalDataWrapper} data-testid='resource-additional-data'>
        {start && startTime && (
          getResourceAdditionalData(startTime, start, 'resource-start', CalenderNotebook, '', styles.width80))}

        {start && duration && (
          getResourceAdditionalData('pages.pipelines.pipelinesTable.resourceDetailsTooltip.duration', duration, 'resource-duration', Clock, '', styles.width60)
        )}

        {userVendorId && getInitiatorData()}
      </div>
    </div>
  );
};
