import { FC } from 'react';

import { TeamsProvider } from 'context/TeamsContext/TeamsProvider';
import { TeamDetailsPage } from 'pages/TeamsPage/SubPages/TeamDetailsPage/TeamDetailsPage';

export const TeamDetailsPageWrapper: FC = () => (
  <TeamsProvider>
    <TeamDetailsPage />
  </TeamsProvider>
);
