import { FC, useMemo } from 'react';

import { GithubIcon } from 'assets';
import { JitText } from 'components/JitText/JitText';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { PageTitles } from 'components/PageTitles/PageTitles';
import { i18n } from 'locale/i18n';
import { InsightBody } from 'pages/InsightsPage/components';
import { ANALYTICS_CLICKED_LINK_TYPES } from 'pages/InsightsPage/components/InsightListCardItem/constants';
import { DeploymentsStatistics, NoDeploymentConfiguration } from 'pages/InsightsPage/SubPages/DeploymentsPage/components';
import styles from 'pages/InsightsPage/SubPages/DeploymentsPage/DeploymentsPage.module.scss';
import { getDeploymentsInsightCategories } from 'pages/InsightsPage/SubPages/DeploymentsPage/utils/getDeploymentsCategories';
import { getPeriodDate } from 'pages/InsightsPage/utils/getPeriodDates';
import colors from 'themes/colors.module.scss';
import { TimeAgo } from 'types/enums';
import { IPipelineNew } from 'types/interfaces';

interface DeploymentsPageProps {
  environment: string
  isDeploymentConfigured: boolean
  deployments?: IPipelineNew[]
  isLoading: boolean
}

export const DeploymentsPage: FC<DeploymentsPageProps> = ({ isDeploymentConfigured, environment, isLoading, deployments }) => {
  const { formattedStartDate, formattedEndDate } = getPeriodDate(TimeAgo.TwoWeeks);

  const deploymentsCategories = useMemo(() => getDeploymentsInsightCategories(deployments), [deployments]);

  const description = i18n.t('pages.deployments.dataPeriod', {
    periodStartDate: formattedStartDate,
    periodEndDate: formattedEndDate,
  });

  const getDeploymentsPageBody = () => (
    <div className={styles.content} data-testid='deploymentsStatisticsAndListCards'>
      {isDeploymentConfigured
        ? (

          <InsightBody
            analyticsLinkType={ANALYTICS_CLICKED_LINK_TYPES.DEPLOYMENT}
            categories={deploymentsCategories}
            emptyDescription='pages.deployments.emptyList.description'
            emptyTitle='pages.deployments.emptyList.title'
            listItemIcon={GithubIcon}
          />

        ) : <NoDeploymentConfiguration />}
    </div>
  );

  return (
    <div className={styles.wrapper} data-testid='deploymentsPage'>
      <div className={styles.header}>

        <PageTitles description={description} subtitle='pages.deployments.subtitle' title='pages.deployments.title' />

        <DeploymentsStatistics deployments={deployments} environment={environment || 'Unconfigured'} />

      </div>

      <div className={styles.contentTitle}>
        <JitText bold size='l' text='pages.deployments.contentTitle' />

        <JitText color={colors.lightGray} size='l' text='pages.deployments.contentSubtitle' />
      </div>

      {isLoading
        ? <LoadingBar data-testid='deploymentsLoadingBar' />
        : getDeploymentsPageBody()}
    </div>

  );
};
