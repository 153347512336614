import { AxiosResponse } from 'axios';
import { useCallback } from 'react';

import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import { DownloadFileResponse } from 'types/interfaces/DownloadFileResponse';
import { IPaginatedResponse } from 'types/interfaces/IPaginatedResponse/IPaginatedResponse';
import { ISbomComponentGroup } from 'types/interfaces/Sbom/SbomComponentGroup';
import { IGetSbomReportStatusResponse } from 'types/interfaces/Sbom/SbomReportStatus';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';
import { deleteFalseValuesFromObject } from 'utils/functions/deleteFalseValuesFromObject';

export const useSbomService = () => {
  const { client } = useClient();

  const getSbomReportDownload = useCallback(async (): Promise<AxiosResponse<DownloadFileResponse> | undefined> => {
    const url = getApiUrls.sbomService.getSbomReportDownload();

    const response = await client.get<DownloadFileResponse>({
      url,
      allowedStatuses: [200],
    });

    if (response?.status === 200) {
      response.data = camelizeSnakeCaseKeys(response.data) as DownloadFileResponse;
    }
    return response;
  }, [client]);

  const getSbomComponentGroups = useCallback(async (after: string | undefined, limit: number, searchValue: string): Promise<AxiosResponse<IPaginatedResponse<ISbomComponentGroup>> | undefined> => {
    const url = getApiUrls.sbomService.getSbomComponents();
    const params = deleteFalseValuesFromObject({
      after,
      limit,
      search_value: searchValue,
    });
    const response = await client.get<IPaginatedResponse<ISbomComponentGroup>>({
      url,
      allowedStatuses: [200],
      requestConfig: {
        params,
      },
    });

    if (response?.status === 200) {
      response.data = camelizeSnakeCaseKeys(response.data) as IPaginatedResponse<ISbomComponentGroup>;
    }
    return response;
  }, [client]);

  const getSbomReportStatus = useCallback(async (): Promise<AxiosResponse<IGetSbomReportStatusResponse> | undefined> => {
    const url = getApiUrls.sbomService.getSbomReportStatus();

    const response = await client.get<IGetSbomReportStatusResponse>({
      url,
      allowedStatuses: [200],
    });
    if (response?.status === 200) {
      response.data = camelizeSnakeCaseKeys(response.data) as IGetSbomReportStatusResponse;
    }
    return response;
  }, [client]);

  return {
    getSbomReportDownload,
    getSbomComponentGroups,
    getSbomReportStatus,
  };
};
