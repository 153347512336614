import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { RisksBreadcrumbs } from '../components/RisksBreadcrumbs/RisksBreadcrumbs';
import { getAssetDisplayName } from '../utils/getAssetDisplayName';

import { FindingsTableWrapper } from './components/FindingsTableWrapper/FindingsTableWrapper';
import styles from './ResourcePage.module.scss';

import { JitText } from 'components/JitText/JitText';
import { PageTitles } from 'components/PageTitles/PageTitles';
import { MockPriorityFactorsGraph } from 'components/PriorityFactorsGraph/MockPriorityFactorsGraph';
import { PriorityFactorsGraph } from 'components/PriorityFactorsGraph/PriorityFactorsGraph';
import { useAssetsContext } from 'context/AssetsContext';
import { GraphProvider } from 'context/GraphContext/GraphProvider';
import { constants } from 'globalConstants';
import { ResourceDetails } from 'pages/Resources/ResourcePage/components/ResourceDetails/ResourceDetails';

export const ResourcePage: FC = () => {
  const { assets } = useAssetsContext();
  const { useKnowledgeGraph } = useFlags();
  const assetId = useParams()[constants.routes.pathParameters.RESOURCE_ID];

  const asset = useMemo(
    () => assets.find((a) => a.asset_id === assetId),
    [assets, assetId],
  );

  const pageTitle = asset ? getAssetDisplayName(asset) : '';

  const graph = useMemo(() => (
    useKnowledgeGraph ? (
      <div className={styles.graphWrapper}>
        <JitText size='m' text='pages.risks.resources.resourcePage.graphTitle' />

        <PriorityFactorsGraph entity={asset} />
      </div>
    ) : <MockPriorityFactorsGraph />
  ), [asset, useKnowledgeGraph]);

  return (
    <GraphProvider>
      <div className={styles.wrapper}>
        <RisksBreadcrumbs asset={asset} />

        <PageTitles title={pageTitle} />

        <ResourceDetails asset={asset} />

        <div className={styles.bodyWrapper}>
          {graph}

          <FindingsTableWrapper asset={asset} />
        </div>
      </div>
    </GraphProvider>
  );
};
