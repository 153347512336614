import { FC, useCallback, useEffect, useState } from 'react';

import { secondsToShortenedDaysAndHoursString } from '../../../SecurityPosture/utils';

import styles from './ChangeCalculationDialogContent.module.scss';
import { DevEfficiencyCalculationWidget } from './components/DevEfficiencyCalculationWidget';

import { JitButton } from 'components/JitButton/JitButton';
import { JitSlider } from 'components/JitSlider/JitSlider';
import { JitText } from 'components/JitText/JitText';
import { GraphLoading } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/components';
import { getDevEfficiencyCalculation } from 'services/MetricService/MetricService';
import { useMetricService } from 'services/MetricService/useMetricService';
import colors from 'themes/colors.module.scss';
import { DevProductivityCalculationGetRequest } from 'types/interfaces/Metrics/DevEfficiencyCalculation';
import { IBaseDevProductivity } from 'types/interfaces/Metrics/SecurityImpactMetrics/DevProductivity';

interface ChangeCalculationDialogProps {
  devProductivityMetric: IBaseDevProductivity;
  setIsCalcDialogOpen: (isOpen: boolean) => void;
  silentFetchDevProductivity: () => void;
}

export const ChangeCalculationDialogContent: FC<ChangeCalculationDialogProps> = ({
  devProductivityMetric,
  setIsCalcDialogOpen,
  silentFetchDevProductivity,
}) => {
  const { patchDevEfficiencyCalculation } = useMetricService();
  const [devProductivityCalculation, setDevProductivityCalculation] = useState<DevProductivityCalculationGetRequest>({ isLoading: false });

  const fetchDevCalculation = useCallback(async () => {
    setDevProductivityCalculation({ isLoading: true });
    const devCalculation = await getDevEfficiencyCalculation();
    setDevProductivityCalculation({ isLoading: false,
      devProductivityCalculation: devCalculation });
  }, [setDevProductivityCalculation]);

  useEffect(() => {
    fetchDevCalculation();
  }, [fetchDevCalculation]);

  const [isApplyLoading, setIsApplyLoading] = useState(false);
  const devEfficiencyCalculationData = devProductivityCalculation.devProductivityCalculation;

  const avgTimeMinHours = devEfficiencyCalculationData?.averageTimeSavedMinHours || 0;
  const avgTimeMaxHours = devEfficiencyCalculationData?.averageTimeSavedMaxHours || 0;
  const avgHourlySalaryMin = devEfficiencyCalculationData?.averageHourlySalaryMin || 0;
  const avgHourlySalaryMax = devEfficiencyCalculationData?.averageHourlySalaryMax || 0;

  const [avgTimeSliderValue, setAvgTimeSliderValue] = useState(0);
  const [avgSalarySliderValue, setAvgSalarySliderValue] = useState(0);

  useEffect(() => {
    setAvgTimeSliderValue(devEfficiencyCalculationData?.averageHoursSaved || 0);
    setAvgSalarySliderValue(devEfficiencyCalculationData?.averageHourlySalary || 0);
  }, [devEfficiencyCalculationData]);

  const timeSavedSeconds = avgTimeSliderValue * devProductivityMetric.preProdResolvedFindings * 3600;
  const moneySaved = avgSalarySliderValue * avgTimeSliderValue * devProductivityMetric.preProdResolvedFindings;

  const handleTimeSliderChange = (event: Event, value: number | number[]) => {
    setAvgTimeSliderValue(value as number);
  };

  const handleSalarySliderChange = (event: Event, value: number | number[]) => {
    setAvgSalarySliderValue(value as number);
  };

  const handleApplyClick = async () => {
    setIsApplyLoading(true);
    await patchDevEfficiencyCalculation({
      average_hours_saved: avgTimeSliderValue,
      average_hourly_salary: avgSalarySliderValue,
    });
    await silentFetchDevProductivity();
    setIsApplyLoading(false);
    setIsCalcDialogOpen(false);
  };

  const content = (
    <div className={styles.wrapper} data-testid='ChangeCalculationDialogContent-wrapper'>
      <DevEfficiencyCalculationWidget
        maxMoneySaved={avgHourlySalaryMax * avgTimeMaxHours * devProductivityMetric.preProdResolvedFindings}
        moneySaved={moneySaved}
        timeSaved={secondsToShortenedDaysAndHoursString(timeSavedSeconds)}
      />

      <div className={styles.card}>
        <div className={styles.cardContent}>
          <JitText bold size='m' text='pages.securityImpact.calculator.findingsResolvedPreProd' />

          <JitText bold size='m' text={devProductivityMetric.preProdResolvedFindings} />
        </div>
      </div>

      <div className={styles.card}>
        <div className={styles.cardContent}>
          <JitText bold size='m' text='pages.securityImpact.calculator.averageTimeSaved' />

          <JitText bold size='m' text={`${avgTimeSliderValue}h`} />
        </div>

        <JitText
          color={colors.gray}
          size='m'
          text='pages.securityImpact.calculator.productionAdditionalMttrIncludes'
        />

        <div className={styles.slider}>
          <JitSlider
            height='medium'
            max={avgTimeMaxHours}
            min={avgTimeMinHours}
            name='avgTimeSlider'
            onChange={handleTimeSliderChange}
            value={avgTimeSliderValue}
          />
        </div>
      </div>

      <div className={styles.card}>
        <div className={styles.cardContent}>
          <JitText bold size='m' text='pages.securityImpact.calculator.avgHourlySalary' />

          <JitText bold size='m' text={`$${avgSalarySliderValue}`} />
        </div>

        <div className={styles.slider}>
          <JitSlider
            height='medium'
            max={avgHourlySalaryMax}
            min={avgHourlySalaryMin}
            name='avgSalarySlider'
            onChange={handleSalarySliderChange}
            value={avgSalarySliderValue}
          />
        </div>
      </div>

      <div className={styles.applyButtonWrapper}>
        <JitButton
          data-testid='apply-button'
          isLoading={isApplyLoading}
          onClick={handleApplyClick}
          showChildrenWhileLoading
          variant='contained'
        >
          <JitText text='pages.securityImpact.calculator.apply' />
        </JitButton>
      </div>
    </div>
  );

  const loadingState = (
    <div className={styles.loadingState}>
      <GraphLoading />
    </div>
  );
  return (
    devProductivityCalculation.isLoading ? loadingState : content
  );
};
