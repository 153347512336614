
import { isEmpty } from 'lodash';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DataOverviewItem, SecurityImpactCard } from '../SecurityImpactCard/SecurityImpactCard';
import { SecurityImpactCardEmpty } from '../SecurityImpactCard/SecurityImpactCardEmpty';
import { SecurityImpactCardLoading } from '../SecurityImpactCard/SecurityImpactCardLoading';

import { CoverageDescription } from './components/CoverageDescription/CoverageDescription';
import { CoverageExpandedContent } from './components/CoverageExpandedContent/CoverageExpandedContent';
import { getActivePlanItemsText, getResourcesCoverageText } from './utils';

import { PercentageLineProgress } from 'components/PercentageLineProgress/PercentageLineProgress';
import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { IPlanStatus } from 'types/interfaces/Metrics/SecurityImpactMetrics/LayersCoverage';
import { IResourcesCoverage } from 'types/interfaces/Metrics/SecurityImpactMetrics/ResourcesCoverage';

interface Props {
  resourcesCoverageMetric: MetricWithLoadingIndication<IResourcesCoverage>
  planStatusMetric: MetricWithLoadingIndication<IPlanStatus>
}

export const Coverage: FC<Props> = ({ resourcesCoverageMetric, planStatusMetric }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();

  if (resourcesCoverageMetric.isLoading) {
    return <SecurityImpactCardLoading />;
  }

  // Checks if the 'metric' property of either 'resourcesCoverageMetric' or 'planStatusMetric' is empty or falsy.
  // If at least one of these conditions is true, the component returns 'null'.
  if ((isEmpty(resourcesCoverageMetric.metric) || !resourcesCoverageMetric.metric)
      || (isEmpty(planStatusMetric.metric) || !planStatusMetric.metric)) return <SecurityImpactCardEmpty />;

  const { metric: { coveragePercentage } } = resourcesCoverageMetric;
  const { metric: { activeItemsPercentage } } = planStatusMetric;

  const activeItemsDescriptionText = getActivePlanItemsText(planStatusMetric.metric);
  const coveredResourcesDescriptionText = getResourcesCoverageText(resourcesCoverageMetric.metric);
  const planDescriptionText = t('pages.securityImpact.coverage.securityControls.description');
  const resourcesDescriptionText = t('pages.securityImpact.coverage.planCoverage.description');

  const dataOverviewItems: DataOverviewItem[] = [{
    content: <PercentageLineProgress gap={13} percentage={activeItemsPercentage} />,
    description: `${activeItemsDescriptionText} ${planDescriptionText}.`,
    descriptionTestID: 'security-controls-description',
    title: 'pages.securityImpact.coverage.securityControls.title',
    titleTestID: 'security-controls-title',
  }, {
    content: <PercentageLineProgress gap={13} percentage={coveragePercentage} />,
    description: `${coveredResourcesDescriptionText} ${resourcesDescriptionText}.`,
    descriptionTestID: 'resources-description',
    title: 'pages.securityImpact.coverage.planCoverage.title',
    titleTestID: 'resources-title',
  }];

  return (
    <SecurityImpactCard
      dataOverviewItems={dataOverviewItems}
      description={(
        <CoverageDescription activeItemsPercentage={activeItemsPercentage} coveragePercentage={coveragePercentage} />
      )}
      expandedContent={<CoverageExpandedContent planStatusMetric={planStatusMetric} resourcesCoverageMetric={resourcesCoverageMetric} />}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      title='pages.securityImpact.coverage.title'
    />
  );
};
