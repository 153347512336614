import { FC } from 'react';

import { SecurityPlanTopRowCard } from '../SecurityPlanTopRowCard/SecurityPlanTopRowCard';
import styles from '../SecurityPlanTopRowCard/SecurityPlanTopRowCard.module.scss';

import { JitText } from 'components/JitText/JitText';

interface Props {
  title: string;
  titleColor?: string;
  subtitle: string;
  width?: string;
}

export const SecurityPlanTopRowTextCard: FC<Props> = ({ title, titleColor, subtitle, width }) => (
  <SecurityPlanTopRowCard width={width}>
    <div />

    <JitText bold color={titleColor} size='xhuge' text={title} />

    <JitText className={styles.subtitle} muted size='s' text={subtitle} />
  </SecurityPlanTopRowCard>
);
