import { FC, useEffect, useState } from 'react';

import { InsightListCard } from '../InsightListCard';

import styles from './InsightBody.module.scss';

import { JitText } from 'components/JitText/JitText';
import { PercentageChip } from 'components/PercentageChip/PercentageChip';
import colors from 'themes/colors.module.scss';
import { ISvg } from 'types/interfaces';
import { IInsightCategory } from 'types/interfaces/Insights';

interface InsightBodyProps {
  categories: IInsightCategory[]
  analyticsLinkType: string;
  emptyTitle: string;
  emptyDescription: string;
  listItemIcon: FC<ISvg>;
}

export const InsightBody: FC<InsightBodyProps> = ({ categories, analyticsLinkType, emptyTitle, emptyDescription, listItemIcon }) => {
  const [selectedCategoryKey, setSelectedCategoryKey] = useState<string>();
  const [hoveredCategoryKey, setHoveredCategoryKey] = useState<string>();

  useEffect(() => {
    if (!categories) return;

    if (!selectedCategoryKey) {
      const category = categories.find((item) => item.items?.length);
      if (category) setSelectedCategoryKey(category.key);
      else setSelectedCategoryKey(categories[0].key);
    }
  }, [selectedCategoryKey, categories, setSelectedCategoryKey]);

  const selectedCategory = categories.find((item) => item.key === selectedCategoryKey);

  return (
    <div className={styles.wrapper} data-testid='InsightBody'>
      <div className={styles.insightCategoryList}>
        {categories.map((category) => {
          const isSelected = category === selectedCategory;
          const isHovered = category.key === hoveredCategoryKey;
          return (
            <div
              key={category.key}
              className={styles.cardItem}
              data-testid={`insightCategoryListItem-${category.key}`}
              onClick={() => setSelectedCategoryKey(category.key)}
              onMouseEnter={() => setHoveredCategoryKey(category.key)}
              onMouseLeave={() => setHoveredCategoryKey(undefined)}
              role='button'
              style={{
                boxShadow: (isSelected || isHovered) ? `4px 0 0 ${colors.lightBlue}` : 'none',
              }}
              tabIndex={0}
            >
              <div className={styles.cardItemTitles}>
                <JitText
                  bold={isSelected || isHovered}
                  color={(isSelected || isHovered) ? colors.lightBlue : colors.lightGray}
                  size='l'
                  text={category.title}
                />

                {isSelected && <JitText color={colors.lightGray} size='m' text={category.subTitle} />}
              </div>

              <JitText
                bold
                color={(isSelected || isHovered) ? colors.lightBlue : colors.lightGray}
                size='xl'
                text={category.items?.length}
              />

              {category.changePercentage !== undefined // value can ben undefined but if the value is 0 we want to show it
              && <PercentageChip percentage={category.changePercentage} positiveTrendIndicatorDirection={category.oppositeColor ? 'down' : 'up'} />}
            </div>
          );
        })}

        <div className={styles.lastCardItem} />
      </div>

      {selectedCategory && (
        <InsightListCard
          analyticsLinkType={analyticsLinkType}
          boldFindingStatus={selectedCategory.boldFindingStatus}
          emptyDescription={emptyDescription}
          emptyTitle={emptyTitle}
          items={selectedCategory.items}
          listItemIcon={listItemIcon}
          shouldDisplayStaleIndication={!!selectedCategory.shouldDisplayStaleIndication}
        />
      )}
    </div>
  );
};
