import { IConfigurations, IPlanItemDeployment, ApplicationTypes } from 'types/interfaces';

export const generatePlanItemTriggerConfiguration = (
  configurations: IConfigurations,
  defaultApplicationName: string,
  applicationType: ApplicationTypes,
): IPlanItemDeployment | null => {
  const configuredEnvironment = configurations?.deployment?.environments?.at(0);
  const configuredApplication = configurations?.applications?.find((application) => application.type === applicationType);

  const applicationName = configuredApplication?.application_name || defaultApplicationName;

  if (!configuredEnvironment) return null;

  const planItemDeployment: IPlanItemDeployment = {
    tags: [configuredEnvironment],
    configuration: [applicationName],
    trigger: 'deployment',
  };

  return (planItemDeployment);
};
