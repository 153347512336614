import { FC } from 'react';

import styles from './PlanItemsDetailsSummaryAwsMisconfigured.module.scss';

import { CircleFailure, TriangleWarning } from 'assets';
import { TABS } from 'components/JitDialogs/AssetsManagementDialog/constants';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import { useDialogsContext } from 'context/DialogsContext/DialogsContext';
import colors from 'themes/colors.module.scss';
import { Dialogs } from 'types/enums';
import { IMouseEvent } from 'types/interfaces';
import { stopPropagation } from 'utils';

interface Props {
  allAssetsAreMisconfigured: boolean;
}

export const PlanItemsDetailsSummaryAwsMisconfigured: FC<Props> = ({ allAssetsAreMisconfigured }) => {
  const { showDialog } = useDialogsContext();

  const showAssetsManagementDialog = (tab?: TABS) => {
    showDialog({
      type: Dialogs.AssetsManagement,
      props: { defaultTab: tab },
    });
  };

  const showAWSDialog = (e: IMouseEvent) => {
    stopPropagation(e);
    showAssetsManagementDialog(TABS.AWS);
  };

  if (allAssetsAreMisconfigured) {
    return (
      <div
        className={styles.AwsMisconfigured}
        data-testid='all-assets-misconfig'
      >
        <JitIcon color={colors.failRed} icon={CircleFailure} size={10} />

        <JitText
          color={colors.lightGray}
          size='s'
          text='pages.plan.planItem.detailsSummary.awsMisConfiguration.brokenIntegration'
        />

        <JitLink onClick={showAWSDialog}>
          <JitText
            color={colors.iris}
            size='s'
            text='pages.plan.planItem.detailsSummary.awsMisConfiguration.configure'
          />
        </JitLink>
      </div>
    );
  }

  return (
    <div
      className={styles.AwsMisconfigured}
      data-testid='some-assets-misconfig'
    >
      <JitIcon icon={TriangleWarning} size={10} />

      <JitText
        color={colors.alert}
        size='s'
        text='pages.plan.planItem.detailsSummary.awsMisConfiguration.warning'
      />

      <JitText
        color={colors.lightGray}
        size='s'
        text='pages.plan.planItem.detailsSummary.awsMisConfiguration.partialMisconfig'
      />

      <JitLink onClick={showAWSDialog}>
        <JitText
          color={colors.iris}
          size='s'
          text='pages.plan.planItem.detailsSummary.awsMisConfiguration.configure'
        />
      </JitLink>
    </div>
  );
};
