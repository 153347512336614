import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';

import { PriorityBreakdown } from 'pages/Resources/components/PriorityBreakdown/PriorityBreakdown';
import { BaseCard } from 'pages/Resources/ResourcesPage/component/BaseCard/BaseCard';
import { removeEmptyValues, aggregateFindingsCount, buildBacklogPageUrl } from 'pages/Resources/ResourcesPage/component/FindingsCountCard/utils';
import { filtersToRequestParams } from 'services/AssetsService/useAssetService';
import { fetchFindingsCount } from 'services/FindingsService';
import { Queries } from 'types/enums/Queries';
import { IFilter, ServerFindingKeys } from 'types/interfaces';

interface Props {
  filters: IFilter[];
}

export const FindingsCountCard: FC<Props> = ({ filters }) => {
  const { data: findingsCount } = useQuery(
    [Queries.FindingsCount, filters],
    () => fetchFindingsCount({
      filters: removeEmptyValues({
        ...filtersToRequestParams(filters, false),
        resolution: 'OPEN',
        ignored: false,
      }),
      group_by: ServerFindingKeys.priority_score,
    }),
    { keepPreviousData: true },
  );

  const findingsPriorityBreakdown = useMemo(() => aggregateFindingsCount(findingsCount), [findingsCount]);
  const backlogPageUrl = useMemo(() => buildBacklogPageUrl(filters), [filters]);
  return (
    <BaseCard link={backlogPageUrl} title='pages.risks.resources.resourcesPage.cards.findingsCount.description'>
      <PriorityBreakdown
        critical={findingsPriorityBreakdown.critical}
        high={findingsPriorityBreakdown.high}
        medium={findingsPriorityBreakdown.medium}
        textSize='s'
      />
    </BaseCard>
  );
};
