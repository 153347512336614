import { ToastProps } from 'components/Toast/Toast';
import colors from 'themes/colors.module.scss';

export const useSystemErrorToastProps = (): ToastProps => ({
  backgroundColor: colors.findingStatusOpenText,
  textColor: colors.white,
  borderRadius: '0',
  width: '100%',
  margin: '0',
  display: 'flex',
  justifyContent: 'center',
  enableClose: false,
});
