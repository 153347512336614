import { i18n } from 'locale/i18n';
import { SortDirections } from 'types/enums';
import { ServerFindingKeys, ISortColumns } from 'types/interfaces';

export const defaultSortColumns: ISortColumns = {
  columns: [{
    sortKey: ServerFindingKeys.priority_score as string,
    columnName: i18n.t('pages.findings.findingsTable.columnHeaders.priorityScore'),
    direction: SortDirections.DESC,
  }, {
    sortKey: ServerFindingKeys.created_at as string,
    columnName: i18n.t('pages.findings.findingsTable.columnHeaders.firstDetected'),
    direction: SortDirections.DESC,
  }],
  activeColumnKey: ServerFindingKeys.created_at,
};
