import { FC, useEffect } from 'react';

import { ReviewCard, Carousel } from './components';
import { loginTitle, reviews } from './constants';
import styles from './LoginPage.module.scss';

import { JitFavicon, LoginJitTeams, LoginLogos, LoginBg } from 'assets';
import { JitText } from 'components/JitText/JitText';
import { useNotAuthenticatedAnalyticsClient } from 'context/AnalyticsContext/hooks/useNotAuthenticatedAnalyticsClient';
import colors from 'themes/colors.module.scss';
import { Interval } from 'types/types';
import { setIntervalImmediately } from 'utils';

let hideElementsInterval: Interval;

const getFronteggContainer = () => {
  const fronteggContainer: Element | undefined = document.querySelector('#frontegg-login-box-container-default') || undefined;
  const fronteggContainerShadowRoot: ShadowRoot | undefined = fronteggContainer?.shadowRoot || undefined;
  return fronteggContainerShadowRoot;
};

const hidePoweredByFronteggAndSignUp = () => {
  hideElementsInterval = setIntervalImmediately(() => {
    const container = getFronteggContainer();
    const poweredByFrontegg: HTMLElement | undefined = container?.querySelector('[data-test-id="powered-by-logo"]') || undefined;
    const signUpElement: HTMLElement | undefined = container?.querySelector('h6') || undefined;

    if (poweredByFrontegg && signUpElement) {
      signUpElement.style.display = 'none';
      poweredByFrontegg.style.display = 'none';
      clearInterval(hideElementsInterval);
    }
  }, 10, 100);
};

export const LoginPage: FC = () => {
  const { sendPageViewedEventNotAuthenticated } = useNotAuthenticatedAnalyticsClient();
  hidePoweredByFronteggAndSignUp();

  useEffect(() => {
    sendPageViewedEventNotAuthenticated('login', '/login');
  }, [sendPageViewedEventNotAuthenticated]);

  return (
    <div className={styles.container} style={{ backgroundImage: `url(${LoginBg})` }}>
      <div className={styles.wrapper}>
        <div className={styles.favicon}>
          <JitFavicon
            fill={colors.jitPink}
          />
        </div>

        <JitText bold className='ReviewCard_Header' size='1vw' text={loginTitle} />

        <div className={styles.content}>
          <div className={styles.carouselContainer}>
            <Carousel
              ChildComponent={ReviewCard}
              options={reviews}
            />
          </div>

          <img
            alt='JitTeams Icon'
            className={styles.icon}
            src={LoginJitTeams}
          />
        </div>

      </div>

      <img alt='Logos Icon' src={LoginLogos} />
    </div>
  );
};
