import colors from 'themes/colors.module.scss';

export const paperStyle = {
  backgroundColor: colors.cards,
  boxShadow: 'none',
  border: `1px solid ${colors.darkGray}`,
  borderRadius: 8,
  width: 550,
  backgroundImage: 'none',
};

export const inputDefaultProps = {
  disableUnderline: true,
  fullWidth: true,
  required: true,
  sx: {
    color: 'gray',
    border: `2px solid ${colors.darkGray}`,
    marginRight: '0.5rem',
    marginLeft: '0.5rem',
    fontSize: '14px',
    fontWeight: '0.5rem',
  },
};
