import { FC } from 'react';

import { JitText } from 'components/JitText/JitText';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { ITableCell } from 'types/interfaces';

export const TextCell: FC<ITableCell> = ({ cell: { value } }) => (
  <TooltipOnlyOnOverflow tooltipText={value}>
    <JitText noWrap overflowHiddenEllipsis text={value} />
  </TooltipOnlyOnOverflow>
);
