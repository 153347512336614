import { useEffect, useState } from 'react';

import { useHandleInstallationsWebSocketNotification } from 'context/TenantContext/tenantHooks/useHandleInstallationsWebSocketNotification';
import { useToastsContext } from 'context/ToastsContext/ToastsContext';
import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { AWS_ERROR_LEARN_MORE_LINK } from 'pages/IntegrationsPage/components/integrationCards/AWSIntegrationCard/constants';
import { useTenantService } from 'services/TenantService/useTenantService';
import { ToastType, Vendor, WebSocketNotificationTopics } from 'types/enums';
import { IInstallation, IntegrationStatus } from 'types/interfaces';

export const useListenToInstallationsWebsocket = () => {
  const { showToast } = useToastsContext();
  const [installations, setInstallations] = useState<IInstallation[]>([]);
  const [isLoadingInstallation, setIsLoadingInstallation] = useState<boolean>(false);
  const [hasTriedFetchInstallations, setHasTriedFetchInstallations] = useState(false);
  const { websocketSubscribe } = useWebsocketSubscribe();
  const { handleInstallationsWebSocketNotification } = useHandleInstallationsWebSocketNotification({ setInstallations });
  const { getAllInstallations } = useTenantService();

  const handleSetInstallation = (fetched: IInstallation[] | undefined) => {
    setInstallations(fetched || []);
    setIsLoadingInstallation(false);
    return fetched;
  };

  const handleShouldDisplayBanner = (fetched: IInstallation[] | undefined) => { // TODO: Move to different hook - ask Michael
    const hasFaultyAwsIntegration = fetched?.some(((item) => item.vendor === Vendor.AWS && item.status === IntegrationStatus.ERROR));
    if (hasFaultyAwsIntegration) {
      const openAwsErrorDocsInNewTab = () => {
        window.open(AWS_ERROR_LEARN_MORE_LINK, '_blank', 'noreferrer');
      };
      showToast({
        type: ToastType.SystemError,
        overrideProps: {
          title: 'toasts.awsConfigurationError.title',
          subtitle: 'toasts.awsConfigurationError.subtitle',
          actions: [{
            text: 'toasts.actions.learnMore',
            onClick: openAwsErrorDocsInNewTab,
          }],
        },
      });
    }
  };
  const fetchAllInstallations = async () => {
    setIsLoadingInstallation(true);
    const fetchedInstallationsRes = await getAllInstallations();
    setIsLoadingInstallation(false);
    setHasTriedFetchInstallations(true);
    if (fetchedInstallationsRes?.status === 200) {
      handleSetInstallation(fetchedInstallationsRes.data);
      handleShouldDisplayBanner(fetchedInstallationsRes.data);
    }
  };

  useEffect(() => {
    fetchAllInstallations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.Installation, handleInstallationsWebSocketNotification);
  }, [handleInstallationsWebSocketNotification, websocketSubscribe]);

  return {
    installations,
    isLoadingInstallation,
    hasTriedFetchInstallations,
  };
};
