import { useMemo } from 'react';

import { getPriorityFields } from 'components/PriorityFactorsGraph/utils/graphDataUtils';
import { useAssetsContext } from 'context/AssetsContext';
import { useAddRemoveFactors } from 'context/GraphContext/hooks/useAddRemoveFactors';
import { IPriorityFactor } from 'types/interfaces/Graph/IPriorityFactor';

export const useGetAssetPriorityFactorHandler = (assetId?: string) => {
  const { assets } = useAssetsContext();
  const asset = assets.find((currAsset) => currAsset.asset_id === assetId);
  const priorityFields = useMemo(() => (asset && getPriorityFields(asset)), [asset]);
  const { addFactors, removeFactors } = useAddRemoveFactors();

  if (!assetId || !priorityFields || !asset) {
    return undefined;
  }

  const addAssetFactors = async (factors: IPriorityFactor[]) => {
    const assetAddedFactors = factors.filter((factor) => !priorityFields.priorityFactors?.includes(factor.key));
    if (assetAddedFactors.length > 0) {
      await addFactors(priorityFields, asset, assetAddedFactors);
    }
  };

  const removeAssetFactors = async (factors: IPriorityFactor[]) => {
    const assetRemovedFactors = factors.filter((factor) => priorityFields.priorityFactors?.includes(factor.key));
    if (assetRemovedFactors.length > 0) {
      await removeFactors(priorityFields, asset, assetRemovedFactors);
    }
  };

  return {
    addAssetFactors,
    removeAssetFactors,
  };
};
