import { t } from 'i18next';
import { FC } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { PieChartCountList } from '../components/PieChartCountList/PieChartCountList';

import { constants } from 'globalConstants';
import { IgnoreFilterKeys } from 'pages/FindingsPage/constants';
import colors from 'themes/colors.module.scss';
import { FindingStatus } from 'types/enums';
import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { IResourceTypeFindingsMetric } from 'types/interfaces/Metrics/PerformanceMetrics/ResourceTypeFindings';
import { FindingCountListItem } from 'types/interfaces/Metrics/PieChartCountList/PieChartCountList';
import { useAssetTypeToVendor } from 'utils/hooks/useAssetTypeToVendor';

interface Props {
  resourceTypeFindingsMetric: MetricWithLoadingIndication<IResourceTypeFindingsMetric>;
}

export const ResourceTypeFindings: FC<Props> = ({ resourceTypeFindingsMetric }) => {
  const navigate = useNavigate();
  const { routes: { FINDINGS } } = constants;

  const { getIconByAssetType } = useAssetTypeToVendor();

  const filteredItems = resourceTypeFindingsMetric.metric?.resourceTypesFindingCounts?.filter((item) => item.count > 0) || [];

  const items: FindingCountListItem[] = filteredItems
    .sort((a, b) => b.count - a.count)
    .map((item, index) => ({
      key: item.key,
      displayName: t(`assetTypes.${item.key}`),
      count: item.count,
      color: colors[`pieChartColor${index % 20}`],
      icon: getIconByAssetType(item.key),
    }));

  const onItemClick = (searchValue: string) => {
    navigate({
      pathname: `/${FINDINGS}`,
      search: createSearchParams({
        asset_type: searchValue,
        resolution: FindingStatus.OPEN,
        ignored: IgnoreFilterKeys.NOT_IGNORED,
      }).toString(),
    });
  };

  return (
    <PieChartCountList
      data-testid='resourceTypeFindingsGraph'
      description='pages.performance.graphs.resourceTypeFindings.description'
      isLoading={resourceTypeFindingsMetric.isLoading}
      items={items}
      onItemClick={onItemClick}
      title='pages.performance.graphs.resourceTypeFindings.title'
    />
  );
};
