import { FC, ReactNode } from 'react';

import styles from './SecurityImpactWrapper.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  mainTitle: string;
  actionItemText?: string;
  actionItemOnClick?: () => void;
  subtitle?: string;
  content: ReactNode;
  increasedGap?: boolean;
}

export const SecurityImpactMetricCard: FC<Props> = ({ mainTitle, actionItemText, actionItemOnClick, content, subtitle, increasedGap }) => {
  const gapBetweenTitleAndContent = increasedGap ? 15 : 7;
  return (
    <div className={styles.wrapper} data-testid={`SecurityImpactMetricCard-${mainTitle}`} style={{ gap: gapBetweenTitleAndContent }}>
      <div className={styles.header}>
        <JitText bold size='l' text={mainTitle} />

        {actionItemText && (
        <div
          className={styles.actionItem}
          data-testid={`action-item-${actionItemText}`}
          onClick={actionItemOnClick}
          role='button'
          tabIndex={0}
        >
          <JitText className={styles.actionItemText} color={colors.iris} size='m' text={actionItemText} />
        </div>
        )}
      </div>

      { subtitle && (
      <div className={styles.subtitle}>
        <JitText color={colors.lightGray} size='m' text={subtitle} />
      </div>
      )}

      {content}
    </div>
  );
};
