import { FC } from 'react';

import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { JitSidePanelItem } from 'components/JitSidePanel/JitSidePanelItem/JitSidePanelItem';

interface Props {
  url: string;
}

export const FixSuggestion: FC<Props> = ({ url }) => (
  <JitSidePanelItem testId='fix-suggestion-panel-item' title='pages.findings.findingDetails.fixSuggestion.title' variant='light'>
    <JitExternalLink href={url} showIcon={false} testId='fix-suggestion-link' text='handleFixSuggestion.openFixPR.viewFixPR' withUnderline />
  </JitSidePanelItem>
);

