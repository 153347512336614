import { FC } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, TooltipProps } from 'recharts';
import { CategoricalChartFunc } from 'recharts/types/chart/generateCategoricalChart';

import { commonGridProps, commonLineChartProps, commonLineProps, commonTooltipProps, commonXAxisProps, commonYAxisProps, xAxisDxToPeriod } from '../../../constants';
import { DETECTED_FINDINGS_GRAPH_KEY, DETECTED_FINDINGS_GRAPH_NAME, RESOLVED_FINDINGS_GRAPH_KEY, RESOLVED_FINDINGS_GRAPH_NAME } from '../../constants';

import { getGraphXAxisDomain } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/utils';
import colors from 'themes/colors.module.scss';
import { TimeAgo } from 'types/enums';
import { IFindingsOverTimeDay } from 'types/interfaces/Metrics/PerformanceMetrics/FindingsOvertime';

interface Props {
  days: IFindingsOverTimeDay[];
  onMouseMove: CategoricalChartFunc;
  displayDetectedLine: boolean;
  displayResolvedLine: boolean;
  closestLineName: string | null;
  yAxisTicksFormatter: (value: number) => string;
  xAxisTicks: number[];
  getTooltipFormatter: (props: TooltipProps<string, string>) => JSX.Element;
  period: TimeAgo;
}

export const GraphBody: FC<Props> = ({ days, onMouseMove, displayDetectedLine, displayResolvedLine, closestLineName, xAxisTicks, yAxisTicksFormatter, getTooltipFormatter, period }) => {
  const allValues = days.flatMap((day) => [day[DETECTED_FINDINGS_GRAPH_KEY], day[RESOLVED_FINDINGS_GRAPH_KEY]]);
  const maxValue = Math.max(...allValues);
  const formattedMaxValue = yAxisTicksFormatter(maxValue);
  return (
    <div data-testid='graphBody'>
      <LineChart {...commonLineChartProps} data={days} height={285} onMouseMove={onMouseMove}>
        <CartesianGrid {...commonGridProps} />

        {displayDetectedLine
          && (
            <Line
              {...commonLineProps}
              dataKey={DETECTED_FINDINGS_GRAPH_KEY}
              isAnimationActive={false}
              name={DETECTED_FINDINGS_GRAPH_NAME}
              stroke={colors.findingsOverTimeDetectedLineColor}
              {...(closestLineName === DETECTED_FINDINGS_GRAPH_NAME ? {} : { activeDot: false })}
            />
          )}

        {displayResolvedLine
          && (
            <Line
              {...commonLineProps}
              data-testid='findingsResolvedLine'
              dataKey={RESOLVED_FINDINGS_GRAPH_KEY}
              isAnimationActive={false}
              name={RESOLVED_FINDINGS_GRAPH_NAME}
              stroke={colors.findingsOverTimeResolvedLineColor}
              {...(closestLineName === RESOLVED_FINDINGS_GRAPH_NAME ? {} : { activeDot: false })}
            />
          )}

        <YAxis {...commonYAxisProps(formattedMaxValue.length)} allowDecimals={false} tickFormatter={yAxisTicksFormatter} />

        <XAxis {...commonXAxisProps} domain={getGraphXAxisDomain(xAxisTicks)} dx={xAxisDxToPeriod[period]} ticks={xAxisTicks} />

        <Tooltip {...commonTooltipProps} content={getTooltipFormatter} />
      </LineChart>
    </div>
  );
};
