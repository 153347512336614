import { FC, useCallback, useMemo } from 'react';

import { PlanItemsSummaryLabel } from './components';
import {
  PlanItemsDetailsSummaryAwsMisconfigured,
} from './components/PlanItemsDetailsSummaryLabelMisconfigured/PlanItemsDetailsSummaryAwsMisconfigured';
import styles from './PlanItemsDetailsSummary.module.scss';

import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { useAssetsContext } from 'context/AssetsContext/AssetsContext';
import { useGetPlanItemConfigurationsStatus, useHasConfigurationsChanged } from 'context/ConfigurationsContext/hooks';
import { usePlanItem } from 'context/PlansContext/hooks/usePlanItem';
import { getFormattedTimeSince } from 'pages/PipelinesPage/utils';
import {
  PlanItemsDetailsSummaryLabelApplyChangesFirst,
} from 'pages/PlanPage/components/PlanCategoryCardItem/components/PlanItemDetailsSummary/components/PlanItemsDetailsSummaryLabelApplyChangesFirst/PlanItemsDetailsSummaryLabelApplyChangesFirst';
import { AssetStatus, AssetType, Vendor } from 'types/enums';
import { IMouseEvent, IPlanItemDetails } from 'types/interfaces';
import { formatDate, stopPropagation } from 'utils';
import { useAssetTypeToVendor } from 'utils/hooks/useAssetTypeToVendor';

interface Props {
  planItem: IPlanItemDetails
  setDetailedPlanItem: (planItem: IPlanItemDetails | undefined) => void;
  handleConfigure: (planItem: IPlanItemDetails) => void;
}

export const PlanItemDetailsSummary: FC<Props> = ({
  planItem,
  setDetailedPlanItem,
  handleConfigure,
}) => {
  const { getSecurityTools } = usePlanItem();
  const { hasConfigurationsChanged } = useHasConfigurationsChanged();
  const { awsAccountAssets } = useAssetsContext();
  const { isConfigured } = useGetPlanItemConfigurationsStatus();
  const { getVendorByAssetType } = useAssetTypeToVendor();

  const isItemConfigured = useMemo(() => isConfigured(planItem), [isConfigured, planItem]);

  const controls = useMemo(() => (planItem?.controls ? planItem.controls : []), [planItem]);

  const securityTools = useMemo(() => getSecurityTools(controls), [getSecurityTools, controls]);
  const statusTimestamp = planItem?.status_properties?.modified_at;

  const handlePlanItemClick = useCallback((e: IMouseEvent) => {
    setDetailedPlanItem(planItem);
    handleConfigure(planItem);
    stopPropagation(e);
  }, [planItem, setDetailedPlanItem, handleConfigure]);

  // const divider = <div className={styles.divider} />;
  const divider = (key?: string) => <div key={key ? `divider-${key}` : 'divider'} className={styles.divider} />;

  let assetType: AssetType | undefined;
  if (planItem.asset_types && planItem.asset_types.length > 0) {
    [assetType] = planItem.asset_types; // Each plan item has only one asset type, we extract the first here
  }

  // eslint-disable-next-line max-statements
  const detailsComponents = useMemo(() => {
    const result = [];

    if (assetType) {
      const vendor = getVendorByAssetType(assetType);
      const awsMisconfiguredAssets = awsAccountAssets.filter((asset) => (asset.status
        !== null
        && asset.status
        !== AssetStatus.CONNECTED
        && asset.is_covered));
      const allAssetsAreMisconfigured = (awsMisconfiguredAssets.length === awsAccountAssets.length);

      if (vendor === Vendor.AWS && awsMisconfiguredAssets.length > 0) {
        result.push(<PlanItemsDetailsSummaryAwsMisconfigured
          key='PlanItemsDetailsSummaryAwsMisconfigured'
          allAssetsAreMisconfigured={allAssetsAreMisconfigured}
        />);
      }
    }

    if (isItemConfigured && hasConfigurationsChanged(planItem.slug)) {
      result.push(<PlanItemsDetailsSummaryLabelApplyChangesFirst
        key='PlanItemsDetailsSummaryLabelApplyChangesFirst'
        handlePlanItemClick={handlePlanItemClick}
      />);
    }

    if (securityTools.length) {
      const securityToolKey = securityTools.length > 1 ? 'securityTools' : 'securityTool';
      const securityToolLabel = `pages.plan.planItem.detailsSummary.${securityToolKey}`;
      result.push(<PlanItemsSummaryLabel
        key='PlanItemsSummaryControlLabelSecurityTools'
        label={securityToolLabel}
        text={securityTools.join(', ')}
      />);
    }

    if (statusTimestamp) {
      const lastUpdateTime = formatDate(statusTimestamp) || '';
      result.push(
        <JitTooltip
          childrenWrapperClassName={styles.timeTooltip}
          data-testid='status-time-tooltip'
          followCursor={false}
          offset={[0, 5]}
          placement='top'
          title={lastUpdateTime}
        >
          <PlanItemsSummaryLabel
            key='lastEvaluated'
            label='pages.plan.planItem.detailsSummary.lastEvaluated'
            text={getFormattedTimeSince(statusTimestamp)}
          />
        </JitTooltip>,
      );
    }
    return result;
  }, [isItemConfigured, assetType, hasConfigurationsChanged, planItem, securityTools,
    statusTimestamp, handlePlanItemClick, getVendorByAssetType, awsAccountAssets]);

  const dividedDetailsComponents = detailsComponents.flatMap((component, index) => [
    index > 0 && divider(index.toString()),
    component,
  ]);

  return (
    <div className={styles.summaryContainer}>
      <JitText bold size='m' text={planItem.name} />

      <div className={styles.detailsWrapper}>
        {dividedDetailsComponents}
      </div>

    </div>
  );
};
