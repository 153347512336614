import { FC } from 'react';

import styles from './DisabledElement.module.scss';

interface Props {
  isDisabled: boolean;
  children: React.ReactNode;
}

export const DisabledElement: FC<Props> = ({ isDisabled, children }) => {
  const classes = `${styles.root} ${isDisabled ? styles.disabled : ''}`;

  return (
    <div className={classes}>
      {children}
    </div>
  );
};
