import { Dispatch, FC, ReactElement, SetStateAction, useCallback, useMemo } from 'react';

import { CustomDialog, DialogHeightOptions, DialogWidthOptions } from '../CustomDialog';

import styles from './StepsDialog.module.scss';

import { Check } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { IMouseEvent } from 'types/interfaces';
import { ActionButton } from 'types/interfaces/ActionButton/ActionButton';

export interface DialogStep {
  stepName: string;
  content: ReactElement;
  isSelected?: boolean; // TODO: The component should get the 'stepIndex' value and calc it inside
  isVerified?: boolean;
  // determines if we'll show the step number and checked icon
  isIntro?: boolean;
}

interface Props {
  steps: DialogStep[];
  setStep: Dispatch<SetStateAction<number>>;
  isOpen: boolean;
  isHidden?: boolean;
  onClick?: (e: IMouseEvent) => void;
  onClose?: (event: object, reason?: string) => void;
  title?: string;
  withDivider?: boolean;
  height?: DialogHeightOptions;
  width?: DialogWidthOptions;
  actionButtons?: ActionButton[];
}

export const StepsDialog: FC<Props> = ({ steps, setStep, isOpen, isHidden = false, onClick, onClose, title, withDivider, width = 's', actionButtons, height }) => {
  const setClickedStep = (clickedStep: number) => setStep(clickedStep);

  const selectedContent = useMemo(() => steps.find((step) => step.isSelected)?.content, [steps]);

  const lastVerifiedStepIndex = useMemo(() => steps.map(((step) => step.isVerified)).lastIndexOf(true), [steps]);
  const isVerifiedStepExist = useMemo(() => lastVerifiedStepIndex !== -1, [lastVerifiedStepIndex]);
  const isVerifiedOrFirstUnverifiedStep = useCallback((index: number) => index <= lastVerifiedStepIndex + 1, [lastVerifiedStepIndex]);
  const shouldBeClickable = useCallback((index: number) => isVerifiedStepExist && isVerifiedOrFirstUnverifiedStep(index), [isVerifiedStepExist, isVerifiedOrFirstUnverifiedStep]);

  const dialogContent = (
    <div className={styles.stepsDialogWrapper}>
      <div className={styles.stepsWrapper}>
        {steps.map((step, index) => (
          <div key={step.stepName} className={step.isSelected ? styles.selectedStep : styles.step} data-testid='step-stepName'>

            <TooltipOnlyOnOverflow tooltipText={step.stepName}>
              {shouldBeClickable(index)
                ? <JitText bold noWrap onClick={() => setClickedStep(index)} overflowHiddenEllipsis size='s' text={step.stepName} />
                : <JitText bold noWrap overflowHiddenEllipsis size='s' text={step.stepName} />}
            </TooltipOnlyOnOverflow>

            {!step.isIntro && !!step.isVerified && (
              <div className={styles.checkedIconWrapper}>
                <JitIcon centered={false} icon={Check} size={7} />
              </div>
            )}

          </div>

        ))}
      </div>

      <div className={styles.contentWrapper}>
        {selectedContent}
      </div>
    </div>
  );

  return (
    <CustomDialog
      actionButtons={actionButtons}
      content={dialogContent}
      height={height}
      isHidden={isHidden}
      isOpen={isOpen}
      onClick={onClick}
      onClose={onClose}
      title={title}
      width={width}
      withDivider={withDivider}
    />
  );
};
