import colors from 'themes/colors.module.scss';

const scores = [
  { threshold: 97,
    grade: 'A+',
    color: colors.gradeA },
  { threshold: 93,
    grade: 'A',
    color: colors.gradeA },
  { threshold: 90,
    grade: 'A-',
    color: colors.gradeA },
  { threshold: 87,
    grade: 'B+',
    color: colors.gradeB },
  { threshold: 83,
    grade: 'B',
    color: colors.gradeB },
  { threshold: 80,
    grade: 'B-',
    color: colors.gradeB },
  { threshold: 77,
    grade: 'C+',
    color: colors.gradeC },
  { threshold: 73,
    grade: 'C',
    color: colors.gradeC },
  { threshold: 70,
    grade: 'C-',
    color: colors.gradeC },
  { threshold: 67,
    grade: 'D+',
    color: colors.gradeD },
  { threshold: 63,
    grade: 'D',
    color: colors.gradeD },
  { threshold: 0,
    grade: 'D-',
    color: colors.gradeD },
];

export const getGradeByScore = (score: number): string => scores.find((g) => score >= g.threshold)!.grade;

export const getColorByGrade = (grade: string): string => {
  const score = scores.find((item) => item.grade === grade);
  if (!score) return scores[scores.length - 1].color;

  return score.color;
};

export const getColorByScore = (score: number): string => getColorByGrade(getGradeByScore(score));
