import { TextField, Button } from '@mui/material';
import React from 'react';

import styles from './ChatFooter.module.scss';

import { Enter } from 'assets';
import { JitText } from 'components/JitText/JitText';
import { i18n } from 'locale/i18n';

interface ChatFooterProps {
  questionInput: string;
  isPolling: boolean;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInputKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onSend: () => void;
}

export const ChatFooter: React.FC<ChatFooterProps> = ({ questionInput, isPolling, onInputChange, onInputKeyDown, onSend }) => (
  <footer className={styles.footer}>
    <TextField
      autoFocus
      className={styles.inputField}
      fullWidth
      InputProps={{ disableUnderline: true }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{ maxLength: 512 }}
      multiline
      onChange={onInputChange}
      onKeyDown={onInputKeyDown}
      placeholder={i18n.t('chat.placeholder')}
      value={questionInput}
      variant='standard'
    />

    <Button className={styles.sendButton} disabled={isPolling} onClick={onSend} startIcon={<Enter />} variant='text'>
      <JitText color='inherit' text='chat.sendBtn' />
    </Button>
  </footer>
);
