import InputAdornment from '@mui/material/InputAdornment';
import { t } from 'i18next';
import { FC, useEffect, useMemo, useState } from 'react';

import styles from './SlackChannelNotificationsItem.module.scss';

import { CircleInfo } from 'assets';
import { CircularProgressBar } from 'components/CircularProgressBar/CircularProgressBar';
import { JitAutoCompleteForm } from 'components/JitAutoCompleteForm/components/JitAutocompleteForm';
import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { useSlackContext } from 'context/SlackContext';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { Vendor } from 'types/enums';
import { TenantNotificationsPreferences, NotificationPreference, SpecificTeamsNotificationPreference } from 'types/interfaces';
import { ISlackServiceChannelResponse } from 'types/interfaces/Slack';

const SKIDDING_OFFSET = 0;
const DISTANCE_OFFSET = 6;

const tooltipPopperProps = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [SKIDDING_OFFSET, DISTANCE_OFFSET],
      },
    },
  ],
};

interface Props {
  preferenceKey: keyof TenantNotificationsPreferences | keyof SpecificTeamsNotificationPreference;
  slackChannels: ISlackServiceChannelResponse[],
  updateNotificationPreferences: (preferenceKey: string, enabled: boolean, channel?: string) => void
  selected?: NotificationPreference
}

export const SlackChannelNotificationsItem: FC<Props> = ({
  preferenceKey,
  slackChannels,
  updateNotificationPreferences,
  selected,
}) => {
  const { isPreferencesLoading, installations } = useTenantContext();
  const { isSlackChannelsLoading } = useSlackContext();
  const [isNotificationEnabled, setIsNotificationEnabled] = useState(selected?.enabled);
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const [selectedChannel, setSelectedChannel] = useState<string>(selected?.channel || '');

  const isLoading = isPreferencesLoading
    || isSlackChannelsLoading
    || installations.filter((installation) => installation.vendor === Vendor.SLACK).length === 0;

  const isDisabled = isLoading || !slackChannels?.length;

  useEffect(() => {
    setIsNotificationEnabled(selected?.enabled);
  }, [selected?.enabled]);

  useEffect(() => {
    setSelectedChannel(selected?.channel || '');
  }, [selected?.channel]);

  const slackChannelsOptions: string[] = useMemo(
    () => slackChannels.map((channel) => channel.name).sort((a, b) => a.localeCompare(b)),
    [slackChannels],
  );

  const onChannelSelected = (channel: string | undefined) => {
    setSelectedChannel(channel || '');
    if (channel) {
      updateNotificationPreferences(preferenceKey, true, channel);
      sendAnalyticsEvent({ action: 'slack-channel-selected' });
    }
  };

  const onCheckBoxClick = () => {
    const newState = !isNotificationEnabled;
    setIsNotificationEnabled(newState);
    updateNotificationPreferences(preferenceKey, newState, selected?.channel);
    const analyticsEvent = newState ? 'slack-channel-enabled' : 'slack-channel-disabled';
    sendAnalyticsEvent({
      action: analyticsEvent,
      params: { notification_type: preferenceKey.toString() },
    });
  };
  const tooltipText = i18n.t(`dialogs.integration.slack.channels.preferencesTooltips.${preferenceKey}`, { defaultValue: '' });

  return (
    <div className={styles.inLine} data-testid='slackChannelNotifications'>
      <JitCheckbox
        key={isNotificationEnabled?.toString()} // had to do this hack in because the check icon wasn't refreshing
        checked={isNotificationEnabled}
        className={styles.notificationCheckBox}
        data-testid='isEnabledCheckBox'
        disabled={isDisabled}
        onClick={onCheckBoxClick}
      />

      <JitText text={`dialogs.integration.slack.channels.preferencesDescriptions.${preferenceKey}`} />

      {tooltipText && (
        <JitTooltip
          className={styles.infoTooltip}
          followCursor={false}
          placement='top'
          PopperProps={tooltipPopperProps}
          title={tooltipText}
          withHighlightShadow
        >
          <JitIcon
            data-testid='tooltipCircleIcon'
            icon={CircleInfo}
            iconClassName={styles.infoTooltipIcon}
            size={15}
          />
        </JitTooltip>
      )}

      <div className={`${styles.inLine} ${styles.stickToTheRight}`}>
        <div className={styles.hashTagSign}>
          <JitText color={isNotificationEnabled ? colors.white : colors.darkGray} size='l' text='#' />
        </div>

        <div className={styles.autoComplete}>
          <JitAutoCompleteForm
            disabled={!isNotificationEnabled || isLoading}
            endAdornment={isLoading ? (
              <InputAdornment position='end'>
                <CircularProgressBar className={styles.loadingProgress} size='18px' />
              </InputAdornment>
            ) : undefined}
            freeSolo
            isSingleValue
            options={slackChannelsOptions || []}
            placeHolder={t('dialogs.integration.slack.channels.defaultDropDown')}
            selectedValue={selectedChannel}
            setSelectedValue={onChannelSelected}
          />
        </div>
      </div>

      <div />
    </div>
  );
};
