import { startCase } from 'lodash';
import { Row } from 'react-table';

import { GroupedRow } from 'components/JitTable/components/GroupedTableRows/GroupedTableRows';
import { IDictionary, ITableRow } from 'types/interfaces';
import { IAssetFieldsNew, IWorkflowNew } from 'types/interfaces/Pipelines/IPipelineNew';
import { getLayerIcon } from 'utils';

const groupWorkflows = (workflows: IWorkflowNew) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const workflowsMap = {} as any;

  Object.entries(workflows).forEach(([name, workflow]) => Object.values(workflow.assets).forEach((asset: IAssetFieldsNew) => Object.values(asset.jobs).forEach((job) => {
    if (workflowsMap[name]) {
      workflowsMap[name].push(...job.existingFindings);
      workflowsMap[name].push(...job.newFindings);
    } else {
      workflowsMap[name] = [...job.existingFindings, ...job.newFindings];
    }
  })));

  return workflowsMap;
};

interface WorkflowsMap {
  [key: string]: string[];
}

const groupFindingsByWorkflows = (page: Row<ITableRow>[], workflowsMap: WorkflowsMap): IDictionary<Row<ITableRow>[]> => {
  const groupedFindingsByWorkflows: Record<string, Row<ITableRow>[]> = {};

  page.forEach((row: Row<ITableRow>) => {
    const workflow = Object.entries(workflowsMap).find(([, findingIds]) => findingIds.includes(row.original.id));
    const workflowKey = (workflow && workflow[0]) || '';
    if (groupedFindingsByWorkflows[workflowKey]) {
      groupedFindingsByWorkflows[workflowKey].push(row);
    } else {
      groupedFindingsByWorkflows[workflowKey] = [row];
    }
  });
  return groupedFindingsByWorkflows;
};

export const getGroupedFindings = (workflows: IWorkflowNew, rows: Row<ITableRow>[]): GroupedRow[] => {
  const groupedWorkflows = groupWorkflows(workflows);
  const groupedFindingsByWorkflows = groupFindingsByWorkflows(rows, groupedWorkflows);

  return Object.entries(groupedFindingsByWorkflows).map(([workflowKey, group]: [string, Row<ITableRow>[]]) => {
    const vulnerabilityType = group[0].original.vulnerability_type ? ` (${startCase(group[0].original.vulnerability_type)})` : '';
    return ({
      groupText: `<b>${workflows[workflowKey]?.name || ''}</b>${vulnerabilityType}`,
      groupIcon: getLayerIcon(group[0].original.plan_layer),
      rows: group,
    });
  });
};
