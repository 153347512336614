import { t } from 'i18next';
import { FC } from 'react';

import { WidgetContent } from './components/WidgetContent/WidgetContent';

import { InsightsCard } from 'pages/OverviewPage/components/Insights/baseComponents';
import { ScoreEntityType } from 'types/interfaces/Metrics/TeamsPortalMetrics/ScoreTrending';

interface Props {
  entityId: string;
  entityType: ScoreEntityType;
  currentScore: number;
}

export const ScoreTrendingWidget: FC<Props> = ({ entityId, entityType = ScoreEntityType.Team, currentScore }) => (
  <InsightsCard
    content={<WidgetContent currentScore={currentScore} entityId={entityId} />}
    header={{
      infoTooltip: t(`components.scoreTrendingWidget.${entityType}.tooltip`, {
        timesCount: 4,
      }),
      title: `components.scoreTrendingWidget.${entityType}.title`,
    }}
    size='s'
    variant='light'
  />
);
