import { FC, useMemo } from 'react';

import styles from './AssetManagementTable.module.scss';
import { AssetTableRow, AssetsTableHeaders } from './components';

import { useAssetsContext } from 'context/AssetsContext/AssetsContext';
import { IAsset } from 'types/interfaces';

export interface Header {
  label: string;
  key: string;
}

interface Props {
  headers: Header[];
  assets: IAsset[];
  checkAsset: (assetId: string, checked: boolean) => void;
}

export const AssetManagementTable: FC<Props> = ({ headers = [], assets = [], checkAsset }) => {
  const { getCentralizedRepoForSCMVendor } = useAssetsContext();

  // show `failed` assets first, so the user doesn't have to scroll through thousands of assets to find broken ones
  const sortedAssets = useMemo(() => [...assets].sort((a, b) => (b.status ?? '').localeCompare(a.status ?? '')), [assets]);

  return (
    <div className={styles.root}>
      <AssetsTableHeaders headers={headers} />

      {sortedAssets.map((asset) => (
        <AssetTableRow
          key={asset.asset_id}
          asset={asset}
          checkAsset={checkAsset}
          headers={headers}
          isDisabled={asset.asset_id === getCentralizedRepoForSCMVendor(asset.vendor)?.asset_id}
        />
      ))}
    </div>
  );
};
