import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useTenantContext } from 'context/TenantContext/TenantContext';
import { isVendorInstalled } from 'pages/IntegrationsPage/utils';
import { POST_INSTALL_QUERY_PARAM } from 'pages/TeamsPage/constants';
import { Vendor } from 'types/enums';

export const useSlackIntegrationStatus = () => {
  const { getIntegrations, installations, hasTriedFetchInstallations, isLoadingInstallation } = useTenantContext();
  const [isPostInstallation, setIsPostInstallation] = useState(false);
  const { search } = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    setIsPostInstallation(queryParams.get(POST_INSTALL_QUERY_PARAM) === Vendor.SLACK);
  }, [search, setIsPostInstallation]);

  const integration = useMemo(
    () => getIntegrations().find((currIntegration) => currIntegration.vendor === Vendor.SLACK),
    [getIntegrations],
  );

  const isConnected = useMemo(
    () => isVendorInstalled(Vendor.SLACK, installations),
    [installations],
  );

  const isLoading = useMemo(
    () => !hasTriedFetchInstallations || isLoadingInstallation || !integration || (isPostInstallation && !isConnected),
    [hasTriedFetchInstallations, isLoadingInstallation, integration, isPostInstallation, isConnected],
  );

  return {
    isConnected,
    isLoading,
    isPostInstallation,
    setIsPostInstallation,
    installationPath: integration?.installation_path,
  };
};
