import { client } from 'services/client';

interface LoginValidationResponse {
  message: string;
  validationKey: string;
}

interface LoginValidationDetails {
  validationResult: {
    screenshot: string;
    hasLoggedIn: boolean;
    message: string;
    textLog: string[];
  };
}

const serviceName = 'validation';

export const validateLoginParams = async (
  loginPageUrl: string,
  userName: string,
  password: string,
  apiDomain: string,
  usernameCssSelector?: string,
  passwordCssSelector?: string,
): Promise<LoginValidationResponse | undefined> => {
  const url = `${serviceName}/website/validate/login`;
  return client.post<LoginValidationResponse>(url, {
    url: loginPageUrl,
    username: userName,
    password,
    usernameCssSelector,
    passwordCssSelector,
    apiDomain,
  });
};

export const fetchLoginValidationDetails = async (validationKey: string): Promise<LoginValidationDetails | undefined> => {
  const url = `${serviceName}/validation/result?validation_key=${encodeURIComponent(validationKey)}`;

  const response = await client.get<LoginValidationDetails>(url);
  return response;
};

interface ApiFileValidationResponse {
  message: string;
}

export const validateApiFile = async (apiFile: string): Promise<ApiFileValidationResponse | undefined> => {
  const url = `${serviceName}/api/file`;
  return client.post<ApiFileValidationResponse>(url, {
    apiFile,
  });
};

interface UrlExistenceValidationResponse {
  message: string;
}

export const validateUrlExistence = async (targetUrl: string): Promise<UrlExistenceValidationResponse | undefined> => {
  const endpointUrl = `${serviceName}/website/existence`;
  return client.post<UrlExistenceValidationResponse>(endpointUrl, {
    url: targetUrl,
  });
};
