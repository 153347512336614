import { FC } from 'react';

import styles from './TooltipFormatter.module.scss';

import { JitText } from 'components/JitText/JitText';

type Props = {
  label?: string;
  value?: number;
  isPercentage?: boolean;
};

export const TooltipFormatter: FC<Props> = ({ label, value, isPercentage }: Props) => (
  <div className={styles.tooltipWrapper}>
    <JitText muted size='xs' text={label} />

    <JitText text={`${value}${isPercentage ? '%' : ''}`} />
  </div>
);

