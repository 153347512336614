
import { FC } from 'react';

import styles from './MetadataItem.module.scss';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import colors from 'themes/colors.module.scss';
import { ISvg } from 'types/interfaces';
import { IPipelineDetailsMetadataItem } from 'types/interfaces/Pipelines';

interface Props {
  item: IPipelineDetailsMetadataItem;
}

export const MetadataItem: FC<Props> = ({ item }) => {
  const { header, text, textStyle, link, startIcon, endIcon, startImgSrc, endImgSrc, iconSize, tooltip, dataTestId } = item;

  const wrapComponentWithTooltip = (component: JSX.Element) => (
    <JitTooltip
      componentsProps={{ tooltip: { style: { marginLeft: 5 } } }}
      data-testid={`${dataTestId}Tooltip`}
      followCursor={false}
      placement='right'
      title={tooltip || false}
    >
      {component}
    </JitTooltip>
  );

  const renderTextComponent = (isLink: boolean) => (
    <TooltipOnlyOnOverflow placement='right' tooltipText={text}>
      <JitText
        className={`${styles.overflowEllipsis} ${isLink && styles.underline}`}
        color={isLink ? colors.iris : colors.white}
        data-testid={`${dataTestId}Text`}
        noWrap
        overflowHiddenEllipsis
        style={textStyle}
        text={text}
      />
    </TooltipOnlyOnOverflow>
  );

  const renderIconOrImg = (icon?: FC<ISvg>, imageSrc?: string) => {
    let component = null;
    if (imageSrc) {
      component = <img alt='' className={styles.metadataItemImg} src={imageSrc} />;
    } else if (icon) {
      component = <JitIcon color={colors.white} data-testid={`${dataTestId}Icon`} icon={icon} size={iconSize || '20px'} />;
    }

    return component;
  };

  const linkComponent = (
    <div className={styles.overflowEllipsis}>
      <div className={styles.metadataItemLink}>
        <JitLink data-testid={`${dataTestId}Link`} href={link} rel='noreferrer' style={{ textDecoration: 'none' }} target='_blank'>
          {renderTextComponent(true)}
        </JitLink>
      </div>
    </div>
  );

  const dataComponent = (
    <div className={styles.metadataItemTextWrapper} data-testid={`${dataTestId}TextWrapper`}>
      {(startIcon || startImgSrc) && renderIconOrImg(startIcon, startImgSrc) }

      {link ? linkComponent : renderTextComponent(false) }

      {(endIcon || endImgSrc) && renderIconOrImg(endIcon, endImgSrc) }
    </div>
  );

  return (
    <div className={styles.metadataItemContainer} data-testid={dataTestId || 'PipelineMetadataItem'}>
      <JitText color={colors.gray} data-testid={`${dataTestId}Header`} text={header} />

      <div className={styles.metadataItemTextWrapper}>
        {tooltip ? wrapComponentWithTooltip(dataComponent) : dataComponent}
      </div>

    </div>
  );
};

