import { FC, ReactNode, CSSProperties } from 'react';

import styles from './JitSidePanelItem.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  title: string;
  children: ReactNode;
  actionComponent?: ReactNode;
  wrapperStyleOverrides?: CSSProperties;
  testId?: string;
  variant?: 'light' | 'dark';
}

const variantToStyle = {
  light: styles.light,
  dark: styles.dark,
};

export const JitSidePanelItem: FC<Props> = ({ title, children, actionComponent, wrapperStyleOverrides, testId = 'JitSidePanelItem', variant = 'dark' }) => {
  const classes = `${styles.wrapper} ${variantToStyle[variant]}`;

  return (
    <div className={classes} data-testid={testId} style={wrapperStyleOverrides}>
      <div className={styles.childrenWrapper}>
        <JitText bold color={colors.gray} size='s' text={title} />

        {children}
      </div>

      <div className={styles.centralized}>
        <div className={styles.actionsWrapper}>
          {actionComponent}
        </div>
      </div>
    </div>
  );
};
