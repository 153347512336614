import { FC, useMemo } from 'react';

import styles from './WidgetContent.module.scss';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { JitText } from 'components/JitText/JitText';
import { ScoreTrendingGraph } from 'components/ScoreTrendingGraph/ScoreTrendingGraph';
import { useGetScoreTrendingByWeek } from 'components/ScoreTrendingWidget/hooks/useGetScoreTrendingByWeek';
import colors from 'themes/colors.module.scss';
import { getGradeByScore } from 'utils/functions/grades';

const DEFAULT_THRESHOLD = 80;

interface Props {
  currentScore: number;
  entityId: string;
}

export const WidgetContent: FC<Props> = ({ currentScore, entityId }) => {
  const { scoreTrending, isLoading } = useGetScoreTrendingByWeek([entityId]);

  const content = useMemo(() => {
    if (isLoading) {
      return <CircularLoadingSpinner />;
    }

    if (!scoreTrending || !scoreTrending.length) {
      return null;
    }

    return <ScoreTrendingGraph scoreTrendingEntity={scoreTrending[0]} />;
  }, [isLoading, scoreTrending]);

  return (
    <div className={styles.wrapper} data-testid='WidgetContent'>
      <div className={styles.scoreSection}>
        <JitText
          data-testid='scoreText'
          params={{ score: currentScore,
            grade: getGradeByScore(currentScore) }}
          size='l'
          text='components.scoreTrendingWidget.score'
        />

        <JitText
          color={colors.iconBlue}
          data-testid='thresholdText'
          params={{
            score: DEFAULT_THRESHOLD,
            grade: getGradeByScore(DEFAULT_THRESHOLD),
          }}
          text='components.scoreTrendingWidget.threshold'
        />

      </div>

      <div className={styles.graphSection} data-testid='graphSection'>
        {content}
      </div>
    </div>
  );
};
