import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';

import { ConfigurationsCheckboxField } from './ConfigurationsCheckboxField';
import { ConfigurationsZapField } from './ConfigurationsZapField';
import { ConfigurationsZapWebPassword } from './ConfigurationsZapWebPassword';
import { AUTH_KEY, AUTH_VALUE, PASSWORD } from './constants';
import styles from './FormBasedAuthConfigurations.module.scss';

import { ZapUrlValidator } from 'context/ConfigurationsContext/validators/ZapValidators';
import { ZapApplicationFormBasedAuth, ZapAuthenticationConfigType } from 'types/interfaces';

interface Props {
  configurations: ZapApplicationFormBasedAuth
  handleChangeInput: (event: ChangeEvent<HTMLInputElement>) => void;
  type: ZapAuthenticationConfigType
  handleChange: (key: string, value: string) => void
}

export const FormBasedAuthConfigurations: FC<Props> = ({ configurations, handleChangeInput, type, handleChange }) => {
  const {
    login_page_url: loginPageUrl,
    username,
    authentication_key: authenticationKey,
    authentication_value: authenticationValue,
    username_css_selector: usernameCssSelector,
    password_css_selector: passwordCssSelector,
  } = configurations;

  const [isSelectorVisible, setIsSelectorVisible] = useState<boolean>(false);

  useEffect(() => {
    if (authenticationKey !== PASSWORD) handleChange(AUTH_KEY, PASSWORD);
  }, [authenticationKey, handleChange]);

  const clearPassword = useCallback(() => {
    handleChange(PASSWORD, '');
    handleChange(AUTH_KEY, '');
    handleChange(AUTH_VALUE, '');
  }, [handleChange]);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleChangeInput(event);
    if (![PASSWORD, AUTH_VALUE].includes(event.target.name)) clearPassword();
  };

  const handlePasswordInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleChange(AUTH_VALUE, event.target.value);
    onInputChange(event);
  };

  const handlePasswordChange = (key: string, value: string) => {
    handleChange(AUTH_VALUE, value);
    handleChange(key, value);
  };

  return (
    <>
      <ConfigurationsZapField handleChangeInput={onInputChange} inputField='login_page_url' inputValue={loginPageUrl} isValid={ZapUrlValidator(false, loginPageUrl)} type={type} />

      <div className={styles.credentialsWrapper}>
        <ConfigurationsZapField handleChangeInput={onInputChange} inputField='username' inputValue={username} type={type} />

        <ConfigurationsZapWebPassword
          clearPassword={clearPassword}
          handleChange={handlePasswordChange}
          handleChangeInput={handlePasswordInputChange}
          inputField='password'
          password={authenticationValue}
          type={type}
        />

      </div>

      <ConfigurationsCheckboxField
        isChecked={isSelectorVisible}
        label='configurations.zap.enableSelectorsConfiguration.label'
        onChange={() => setIsSelectorVisible(!isSelectorVisible)}
        tooltipText='configurations.zap.enableSelectorsConfiguration.info'
      />

      {isSelectorVisible && (
      <div className={styles.credentialsWrapper}>

        <ConfigurationsZapField
          dataTestid='username_css_selector'
          handleChangeInput={onInputChange}
          inputField='username_css_selector'
          inputValue={usernameCssSelector}
          type={type}
        />

        <ConfigurationsZapField
          dataTestid='password_css_selector'
          handleChangeInput={onInputChange}
          inputField='password_css_selector'
          inputValue={passwordCssSelector}
          type={type}
        />

      </div>
      )}
    </>
  );
};
