import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { deleteSavedFilter, fetchSavedFilters, postSavedFilter, putSavedFilter } from 'services/FindingsService';
import { IFindingFilter } from 'types/interfaces';
import { ISavedFilter } from 'types/interfaces/Findings/ISavedFilter';

export const useSavedFilters = (filters: IFindingFilter[], setFilters: Dispatch<SetStateAction<IFindingFilter[]>>) => {
  const [savedFilters, setSavedFilters] = useState<ISavedFilter[]>([]);
  const [selectedSavedFilter, setSelectedSavedFilter] = useState<ISavedFilter | undefined>();

  const getDefaultSavedFilter = useCallback((savedFiltersList: ISavedFilter[]) => savedFiltersList.find((savedFilter) => savedFilter.isDefault), []);

  const setFiltersFromSavedFilter = useCallback((newSavedFilter: ISavedFilter) => {
    setFilters((currentFilters: IFindingFilter[]) => newSavedFilter.filters.map((savedFilter: IFindingFilter) => {
      const relatedFilter = currentFilters.find((filter) => filter.key === savedFilter.key);
      return {
        ...relatedFilter,
        isVisible: savedFilter.isVisible,
        selectedValue: savedFilter.selectedValue,
      } as IFindingFilter;
    }));
  }, [setFilters]);

  const getSavedFilters = useCallback(async (shouldApplyDefault: boolean = false) => {
    const currentSavedFilters = await fetchSavedFilters();
    currentSavedFilters.sort((a, b) => Number(b.isDefault) - Number(a.isDefault));
    setSavedFilters(currentSavedFilters);
    if (shouldApplyDefault) {
      const defaultSavedFilter = getDefaultSavedFilter(currentSavedFilters);
      if (defaultSavedFilter) {
        setSelectedSavedFilter(defaultSavedFilter);
        setFiltersFromSavedFilter(defaultSavedFilter);
      }
    }
  }, [getDefaultSavedFilter, setFiltersFromSavedFilter]);

  const editSavedFilter = useCallback(async (editedSavedFilter: ISavedFilter) => {
    const currentSavedFilter = savedFilters.find((savedFilter) => savedFilter.id === editedSavedFilter.id);
    if (currentSavedFilter !== editedSavedFilter) {
      await putSavedFilter(editedSavedFilter);
      getSavedFilters();
    }
  }, [getSavedFilters, savedFilters]);

  const removeSavedFilterInUI = useCallback(async (savedFilter: ISavedFilter) => {
    const currentFilters: ISavedFilter[] = [...savedFilters];
    const savedFilterIndex = currentFilters.findIndex((filter) => filter.id === savedFilter.id);
    currentFilters.splice(savedFilterIndex, 1);
    setSavedFilters(currentFilters);
    if (selectedSavedFilter?.id === savedFilter.id) {
      setSelectedSavedFilter(undefined);
    }
  }, [savedFilters, selectedSavedFilter]);

  const removeSavedFilter = useCallback(async (savedFilter: ISavedFilter) => {
    removeSavedFilterInUI(savedFilter);
    if (savedFilter.id) {
      // A filter will have an ID only if it was already on the server.
      await deleteSavedFilter(savedFilter.id);
    }
    getSavedFilters();
  }, [getSavedFilters, removeSavedFilterInUI]);

  const setSavedFilterAsDefaultInUI = useCallback(async (savedFilter: ISavedFilter) => {
    const currentFilters: ISavedFilter[] = [...savedFilters];
    const savedFilterIndex = currentFilters.findIndex((filter) => filter.id === savedFilter.id);
    currentFilters[savedFilterIndex].isDefault = true;
    const currentDefaultSavedFilter = getDefaultSavedFilter(currentFilters);
    if (currentDefaultSavedFilter) {
      const defaultSavedFilterIndex = currentFilters.findIndex((filter) => filter.id === currentDefaultSavedFilter.id);
      currentFilters[defaultSavedFilterIndex].isDefault = false;
    }
    currentFilters.sort((a, b) => Number(b.isDefault) - Number(a.isDefault));
    setSavedFilters(currentFilters);
  }, [getDefaultSavedFilter, savedFilters]);

  const setSavedFilterAsDefault = useCallback(async (savedFilter: ISavedFilter) => {
    setSavedFilterAsDefaultInUI(savedFilter);
    await putSavedFilter({
      ...savedFilter,
      isDefault: true,
    });
    getSavedFilters();
  }, [getSavedFilters, setSavedFilterAsDefaultInUI]);

  const selectSavedFilter = useCallback((savedFilter: ISavedFilter) => {
    setFiltersFromSavedFilter(savedFilter);
    setSelectedSavedFilter(savedFilter);
  }, [setFiltersFromSavedFilter]);

  const createSavedFilter = useCallback(async (savedFilter: ISavedFilter) => {
    setSavedFilters([...savedFilters, savedFilter]);
    selectSavedFilter(savedFilter);
    await postSavedFilter(savedFilter);
    await getSavedFilters();
  }, [getSavedFilters, savedFilters, selectSavedFilter]);

  return {
    editSavedFilter,
    removeSavedFilter,
    setSavedFilterAsDefault,
    createSavedFilter,
    getSavedFilters,
    savedFilters,
    selectSavedFilter,
    selectedSavedFilter,
  };
};
