import { DocNode } from '@atlaskit/adf-schema';
import { AxiosResponse } from 'axios';
import { useCallback } from 'react';

import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import { Ticket } from 'types/interfaces/TicketManager/Ticket';

export const useJiraService = () => {
  const { client } = useClient();

  const createTicket = useCallback(async (integrationId: string, title: string, description: DocNode, findingIds: string[]): Promise<AxiosResponse<Ticket> | undefined> => {
    const url = getApiUrls.jiraService.createTicket();

    return client.post<Ticket>({
      url,
      allowedStatuses: [201],
      requestConfig: {
        data: {
          integration_id: integrationId,
          title,
          description,
          finding_ids: findingIds,
        },
      } });
  }, [client]);

  return {
    createTicket,
  };
};
