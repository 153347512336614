import { Avatar } from '@mui/material';
import React from 'react';

import styles from './UserMessage.module.scss';

import { JitText } from 'components/JitText/JitText';

interface UserMessageProps {
  message: string;
  profilePictureUrl: string;
}

export const UserMessage: React.FC<UserMessageProps> = ({ message, profilePictureUrl }) => (
  <div className={`${styles.chatItem} ${styles.userMessage}`}>
    <Avatar className={styles.avatar} data-testid='userIcon' src={profilePictureUrl} />

    <JitText size='l' text={message} />
  </div>
);
