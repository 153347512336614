import { FC, PropsWithChildren } from 'react';

import styles from './BlockMobileWrapper.module.scss';

import { JitFavicon, JittyWithMvsPlan } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

export const BlockMobileWrapper: FC<PropsWithChildren> = ({ children }) => {
  const isMobile = window.matchMedia('only screen and (max-width: 760px)').matches;

  if (isMobile) {
    return (
      <div className={styles.wrapper}>

        <JitIcon
          className={styles.jittyIcon}
          icon={JittyWithMvsPlan}
          size={218}
        />

        <JitIcon
          className={styles.jitFaviconIcon}
          color={colors.jitPink}
          icon={JitFavicon}
          size={30}
        />

        <div className={styles.texts}>
          <JitText align='center' bold className={styles.text} data-testid='mobileNotSupportedTitle' size='l' text='mobileNotSupported.title' />

          <JitText
            align='center'
            className={styles.text}
            data-testid='mobileNotSupportedDescription'
            size='m'
            style={{ marginTop: 6 }}
            text='mobileNotSupported.description'
          />
        </div>
      </div>
    );
  }
  return children as React.ReactElement;
};
