import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useCallback } from 'react';

import { AboutThisIssue } from './components/AboutThisIssue/AboutThisIssue';
import { FindingContext } from './components/FindingContext/FindingContext';
import { FindingInfo } from './components/FindingInfo/FindingInfo';
import { FixSuggestion } from './components/FixSuggestion/FixSuggestion';
import { Priority } from './components/Priority/Priority';
import { TicketManagement } from './components/TicketManagement/TicketManagement';

import { useFindingTableContext } from 'components/FindingsTable/FindingsTableContext/FindingsTableContext';
import { JitSidePanel } from 'components/JitSidePanel/JitSidePanel';
import { useJitSlidingPanelContext } from 'components/JitSlidingPanel/JitSlidingPanel';
import { GraphProvider } from 'context/GraphContext/GraphProvider';

type Props = {
  onClosePanel: () => void;
};

export const FindingDetails: FC<Props> = ({ onClosePanel }) => {
  const { closePanel } = useJitSlidingPanelContext();
  const { selectedRow } = useFindingTableContext();

  const { useKnowledgeGraph } = useFlags();

  const handleClosePanel = useCallback(() => {
    closePanel();
    onClosePanel();
  }, [closePanel, onClosePanel]);

  if (!selectedRow) return null;
  return (
    <JitSidePanel closePanel={handleClosePanel} title='pages.findings.findingDetails.header.title'>

      <FindingInfo finding={selectedRow} />

      <FindingContext finding={selectedRow} />

      {useKnowledgeGraph && (
        <GraphProvider>
          <Priority finding={selectedRow} />
        </GraphProvider>

      )}

      <AboutThisIssue finding={selectedRow} />

      <TicketManagement finding={selectedRow} />

      {selectedRow.fixPRUrl && <FixSuggestion url={selectedRow.fixPRUrl} />}

    </JitSidePanel>

  );
};
