import { Dispatch, SetStateAction, useCallback } from 'react';

import { usePRService } from 'services/PrService/usePrService';
import { IPullRequestsAnalysis } from 'types/interfaces/PullRequests';

interface Props {
  setPrAnalysis: Dispatch<SetStateAction<IPullRequestsAnalysis | undefined>>,
}

export const useHandlePrUpdatesWebSocketNotification = ({ setPrAnalysis }: Props) => {
  const { fetchPullRequestsAnalysis } = usePRService();
  const handlePrUpdatesWebSocketNotification = useCallback(async () => {
    const updatedAnalysis = await fetchPullRequestsAnalysis();
    if (!updatedAnalysis) {
      return;
    }
    setPrAnalysis(updatedAnalysis);
  }, [fetchPullRequestsAnalysis, setPrAnalysis]);

  return { handlePrUpdatesWebSocketNotification };
};
