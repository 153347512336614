import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

import colors from 'themes/colors.module.scss';

interface Props extends Omit<CheckboxProps, 'size'> {
  size?: number;
}

export const JitCheckbox = styled(Checkbox)<Props>(({ size = 20, disabled }) => ({
  height: size,
  width: size,
  borderRadius: 8,
  color: `${disabled ? colors.darkGray : colors.blue02} !important`,
  svg: {
    height: size,
  },
}));
