import { FC } from 'react';

import styles from './FindingsStatisticsTextAndBullet.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  text: string | number;
  onClick?: () => void;
  width?: number;
  addUnderlineOnHover?: boolean;
}

export const FindingsStatisticsTextAndBullet: FC<Props> = ({ text, onClick, width, addUnderlineOnHover = true }) => (
  <div className={styles.wrapper}>

    <JitText addUnderlineOnHover={addUnderlineOnHover} className={styles.text} color={colors.lightGray} onClick={onClick} size='s' style={{ width: width || 'initial' }} text={text} />

  </div>
);
