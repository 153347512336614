import { ChangeEvent, FC } from 'react';

import { ConfigurationsZapField } from './ConfigurationsZapField';
import { ConfigurationsZapWebPassword } from './ConfigurationsZapWebPassword';
import { AUTH_KEY, AUTH_VALUE } from './constants';
import styles from './FormBasedAuthConfigurations.module.scss';

import { ZapApplicationCustomCookie, ZapAuthenticationConfigType } from 'types/interfaces/Configurations/IApplicationConfiguration';

interface Props {
  configurations: ZapApplicationCustomCookie;
  handleChange: (key: string, value: string) => void;
  type: ZapAuthenticationConfigType;
}

export const CustomCookieConfiguration: FC<Props> = ({
  configurations: {
    authentication_key: authenticationKey,
    authentication_value: authenticationValue,
  },
  handleChange,
  type,
}) => (
  <div className={styles.credentialsWrapper}>

    <ConfigurationsZapField
      handleChangeInput={(event) => handleChange(AUTH_KEY, event.target.value)}
      inputField='customCookieKey'
      inputValue={authenticationKey}
      type={type}
    />

    <ConfigurationsZapWebPassword
      handleChange={(_, value) => handleChange(AUTH_VALUE, value)}
      handleChangeInput={(event: ChangeEvent<HTMLInputElement>) => handleChange(AUTH_VALUE, event.target.value)}
      inputField='customCookieValue'
      password={authenticationValue}
      type={type}
    />
  </div>
);
