import { FC, useState, ReactElement, useContext, createContext, useCallback, useMemo, useEffect, CSSProperties } from 'react';

import styles from './JitSlidingPanel.module.scss';

interface Props {
  isOpen: boolean;
  children?: ReactElement;
  closePanel: () => void;
  width?: string;
}

interface ContextProps {
  closePanel: () => void;
  isOpening: boolean;
}

const JitSlidingPanelContext = createContext<ContextProps>({
  closePanel: () => {},
  isOpening: true,
});

const SLIDING_PANEL_ANIMATION_DURATION = 400;

export const JitSlidingPanel: FC<Props> = ({ isOpen, children, closePanel, width = '70%' }) => {
  const [isClosing, setIsClosing] = useState(false);
  const [isOpening, setIsOpening] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsOpening(true);
      setTimeout(() => {
        setIsOpening(false);
      }, SLIDING_PANEL_ANIMATION_DURATION);
    }
  }, [isOpen]);

  const closeWithDelay = useCallback(() => {
    if (isOpening) return;
    setIsClosing(true);
    setTimeout(() => {
      closePanel();
      setIsClosing(false);
    }, SLIDING_PANEL_ANIMATION_DURATION);
  }, [closePanel, isOpening]);

  const contextValue = useMemo(() => ({
    closePanel: closeWithDelay,
    isOpening,
  }), [closeWithDelay, isOpening]);

  const fadeDivClassNames = useMemo(
    () => `${styles.fadeDiv} ${isOpen && styles.openFadeDivAnimation} ${isClosing && styles.closeFadeDivAnimation}`,
    [isOpen, isClosing],
  );
  if (!isOpen) return null;

  return (
    <JitSlidingPanelContext.Provider value={contextValue}>
      <div className={styles.wrapper} data-testId='JitSlidingPanel' onClick={closeWithDelay} role='presentation'>
        <div className={fadeDivClassNames} />

        <div className={`${styles.card} ${isOpen && styles.openCardAnimation} ${isClosing && styles.closeCardAnimation}`} style={{ '--width': width } as CSSProperties}>
          {children}
        </div>
      </div>
    </JitSlidingPanelContext.Provider>
  );
};

export const useJitSlidingPanelContext = () => useContext(JitSlidingPanelContext);

