import { FC } from 'react';
import { TooltipProps } from 'recharts';

import { graphNameWithData } from '../../utils';

import styles from './TooltipFormatter.module.scss';

import { JitText } from 'components/JitText/JitText';
import { Environment, MTTRDay } from 'types/interfaces/Metrics/PerformanceMetrics/MTTR';
import { formatDate } from 'utils';

interface Props extends TooltipProps<string, string> {
  closestLineName: string | null;
}

export const TooltipFormatter: FC<Props> = ({ closestLineName, payload }) => {
  if (!closestLineName) {
    return null;
  }
  const hoveredPoint = payload?.find((point) => point.name === closestLineName)?.payload as MTTRDay | undefined;
  if (!hoveredPoint) return null;

  const environment: Environment = closestLineName === graphNameWithData('production') ? 'production' : 'preProduction';
  return (
    <div className={styles.tooltipWrapper}>
      <JitText muted size='xs' text={formatDate(hoveredPoint.date, false)} />

      <JitText bold size='xxl' text={hoveredPoint[environment]?.mttr?.displayValue || 0} />

      <div className={styles.tooltipFindingsAmount}>

        <JitText size='s' text='pages.performance.graphs.mttr.tooltip.fixedFindings' />

        <JitText bold size='s' text={hoveredPoint[environment]?.fixedFindingsCount || 0} />

      </div>
    </div>
  );
};
