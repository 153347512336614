import { FC } from 'react';

import { FeedbackButton } from '../Feedback/FeedbackButton/FeedbackButton';

import styles from './Header.module.scss';

import { CircleQuestion } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { AddRemoveButton } from 'pages/FindingsPage/components/FindingDetails/components/Priority/children/AddRemoveFactor/AddRemoveButton';
import { FullScreenButton } from 'pages/FindingsPage/components/FindingDetails/components/Priority/children/FullScreenButton/FullScreenButton';
import { PriorityBox } from 'pages/FindingsPage/components/PriorityBox/PriorityBox';
import colors from 'themes/colors.module.scss';
import { IFinding } from 'types/interfaces';

interface Props {
  finding: IFinding;
  setIsFullScreen: (isFullScreen: boolean) => void;
}

export const Header: FC<Props> = ({ finding, setIsFullScreen }) => (
  <div>
    <div className={styles.leftSide}>
      <div className={styles.title}>
        <JitText bold color={colors.gray} size='s' text='pages.findings.findingDetails.priority.title' />

        <JitTooltip title='pages.findings.findingDetails.priority.tooltip'>
          <JitIcon
            color={colors.gray}
            icon={CircleQuestion}
            size={14}
          />
        </JitTooltip>
      </div>

      <PriorityBox priorityScore={finding.priorityScore || 0} />

      <AddRemoveButton finding={finding} />

    </div>

    <div className={styles.buttons}>
      <FullScreenButton setIsFullScreen={setIsFullScreen} />

      <FeedbackButton finding={finding} />
    </div>
  </div>
);
