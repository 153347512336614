import { IPlan } from '../interfaces/IPlan';

// The Security plans that are listed in the offering is from a hard coded list at the moment,
// on future PR's the structure will be extended with the relevant stages on the loop and the security items that attached to each of them.
// Once the structure will be stable, we can consider extending the existing plan templates for that purpose.
export const securityPlansInit: IPlan[] = [
  {
    key: 'plan-minimal-viable-security',
    name: 'Minimal Viable Security',
    color: '#F6597F',
    isSelected: false,
    stagesActivities: {
      build: [
        'SAST',
        'SCA',
        'Secret Detection',
        'IaC Scanning',
      ],
      code: [
        'SAST',
        'SCA',
        'Secret Detection',
        'IaC Scanning',
      ],
      monitor: [
        'Vulnerability Management',
      ],
    },
  },
  {
    key: 'plan-mvs-for-cloud-app',
    name: 'Jit Max',
    color: '#B8287F',
    isSelected: true,
    stagesActivities: {
      build: [
        'SAST',
        'SCA',
        'Secret Detection',
        'IaC Scanning',
        'SBOM',
        'Dockerfile Scanning',
      ],
      code: [
        'SAST',
        'SCA',
        'Secret Detection',
        'IaC Scanning',
        'Dockerfile Scanning',
      ],
      test: [
        'DAST',
        'CSPM',
      ],
      deploy: [
        'DAST',
      ],
      monitor: [
        'Vulnerability Management',
      ],
      operate: [
        'DAST',
        'CSPM',
        'GitHub Security',
      ],
    },
  },
  {
    key: 'plan-application-security',
    name: 'Basic Application Security',
    color: '#F6B359',
    isSelected: false,
    stagesActivities: {
      build: [
        'SAST',
        'SCA',
        'Secret Detection',
        'SBOM',
      ],
      code: [
        'SAST',
        'SCA',
        'Secret Detection',
      ],
    },
  },
  {
    key: 'plan-github-security',
    name: 'CI/CD Security',
    color: '#FF9DF5',
    isSelected: false,
    stagesActivities: {
      operate: [
        'GitHub Security',
      ],
    },
  },
  {
    key: 'plan-web-app',
    name: 'Dynamic Application Security Testing',
    color: '#F6597F',
    isSelected: false,
    stagesActivities: {
      test: [
        'DAST',
      ],
      deploy: [
        'DAST',
      ],
      operate: [
        'DAST',
      ],
      monitor: [
        'Vulnerability Management',
      ],
    },
  },
  {
    key: 'plan-aws-ftr',
    name: 'AWS FTR',
    color: '#FCB3B3',
    isSelected: false,
    stagesActivities: {
      build: [
        'Secret Detection',
        'IaC Scanning',
      ],
      code: [
        'Secret Detection',
        'IaC Scanning',
      ],
      test: [
        'CSPM',
      ],
      operate: [
        'CSPM',
        'Backup',
      ],
      monitor: [
        'Vulnerability Management',
        'Compliance Process',
      ],
    },
  },
  {
    key: 'plan-owasp-asvs',
    name: 'OWASP ASVS',
    color: '#6ADFDE',
    isSelected: false,
    stagesActivities: {
      test: [
        'DAST',
      ],
      deploy: [
        'DAST',
      ],
      operate: [
        'DAST',
      ],
      monitor: [
        'Vulnerability Management',
      ],
    },
  },
  {
    key: 'plan-aws-security',
    name: 'AWS Security',
    color: '#F6B359',
    isSelected: false,
    stagesActivities: {
      test: [
        'CSPM',
      ],
      operate: [
        'CSPM',
      ],
      monitor: [
        'Vulnerability Management',
      ],
    },
  },
  {
    key: 'plan-owasp-top-10',
    name: 'OWASP Top 10',
    color: '#FF9DF5',
    isSelected: false,
    stagesActivities: {
      test: [
        'DAST',
      ],
      deploy: [
        'DAST',
      ],
      operate: [
        'DAST',
      ],
      monitor: [
        'Vulnerability Management',
      ],
    },
  },
];
