import { FC } from 'react';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  description: string;
}

export const Description: FC<Props> = ({ description }) => <JitText bold color={colors.gray} data-testid='description' text={description} />;
