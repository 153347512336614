import { FC, useMemo } from 'react';

import styles from './IconWithStatus.module.scss';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { ISvg } from 'types/interfaces';

interface IconWithStatusProps {
  text: string;
  icon?: FC<ISvg>;
  hideIcon?: boolean;
}

export const IconWithStatus: FC<IconWithStatusProps> = ({
  text,
  icon,
  hideIcon,
}) => {
  const color = useMemo(() => (hideIcon ? colors.lightGray : colors.successGreen02), [hideIcon]);

  return (
    <div className={styles.wrapper}>
      { !hideIcon && icon && <JitIcon color={colors.successGreen} icon={icon} size={36} />}

      <JitText
        bold
        color={color}
        size='m'
        text={text}
      />
    </div>
  );
};
