import { AssetType, Vendor } from 'types/enums';
import { IAggregatedAssetStatistics, IAsset, ManualFactors } from 'types/interfaces';

const emptyManualFactors: ManualFactors = {
  added: [],
  removed: [],
};
export const AssetMock: IAsset = {
  asset_id: 'something',
  tenant_id: 'something',
  asset_type: AssetType.REPO,
  vendor: Vendor.Github,
  owner: 'something',
  asset_name: 'something',
  is_active: true,
  is_covered: true,
  created_at: new Date().toDateString(),
  modified_at: new Date().toDateString(),
  score: 1,
  tags: [],
  priority_score: 50,
  priority_factors: ['Production'],
  manual_factors: emptyManualFactors,

};

export const AwsOrgAssetMock: IAsset = {
  asset_id: 'AwsOrgAssetMock',
  tenant_id: 'AwsOrgAssetMock',
  asset_type: AssetType.AWS_ORG,
  vendor: 'aws',
  owner: 'AwsOrgAssetMock',
  asset_name: 'AwsOrgAssetMock',
  aws_account_id: '123456789',
  is_active: true,
  is_covered: true,
  created_at: new Date().toDateString(),
  modified_at: new Date().toDateString(),
  tags: [],
  priority_score: 50,
  priority_factors: ['Production'],
  manual_factors: emptyManualFactors,

};

export const AwsAccAssetMock: IAsset = {
  asset_id: 'AwsAccAssetMock',
  tenant_id: 'AwsAccAssetMock',
  asset_type: AssetType.AWS_ACCOUNT,
  vendor: 'aws',
  owner: 'AwsAccAssetMock',
  asset_name: 'AwsAccAssetMock',
  aws_account_id: '123456789',
  is_active: true,
  is_covered: true,
  created_at: new Date().toDateString(),
  modified_at: new Date().toDateString(),
  tags: [{
    name: 'team',
    value: 'The Best Team',
  }, {
    name: 'team',
    value: 'Context',
  }],
  priority_score: 1,
  priority_factors: ['Production', 'Externally Accessible'],
  manual_factors: emptyManualFactors,
};

export const buildAssetMock = (assetFields?: Partial<IAsset>) => ({
  ...AssetMock,
  ...assetFields,
});

export const buildAssetsMock = (length: number = 5, assetFields: Partial<IAsset> = {}) => [...Array(length)].map((item, index) => {
  const defaultAssetFields = {
    asset_id: `${Math.random() * 100000}`,
    asset_name: `Asset - ${index}`,
  };
  return buildAssetMock({
    ...defaultAssetFields,
    ...assetFields,
  });
});

export const buildAssetsMockDifferentOwners = (length: number = 5, assetFields: Partial<IAsset> = {}) => [...Array(length)].map((item, index) => {
  const defaultAssetFields = {
    asset_id: `${Math.random() * 100000}`,
    asset_name: `Asset - ${index}`,
    owner: `owner${index}`,
  };
  return buildAssetMock({
    ...defaultAssetFields,
    ...assetFields,
  });
});

export const buildAssetsMockIndividualAccounts = (length: number = 5, assetFields: Partial<IAsset> = {}) => [...Array(length)].map((item, index) => {
  const defaultAssetFields = {
    asset_id: `${Math.random() * 100000}`,
    asset_name: `${index}`,
    owner: `${index}`,
  };
  return buildAssetMock({
    ...defaultAssetFields,
    ...assetFields,
  });
});

export const buildAggregatedAssetStatistics = (): IAggregatedAssetStatistics[] => [{
  aggregationKey: 'Production',
  description: 'These are the production assets',
  count: 75,
  averagePriorityScore: 90,
  buckets: {
    critical: 10,
    high: 20,
    medium: 30,
    low: 15,
  },
},
{
  aggregationKey: 'Database Integration',
  description: 'These are the database integration assets',
  count: 50,
  averagePriorityScore: 80,
  buckets: {
    critical: 10,
    high: 20,
    medium: 30,
    low: 15,
  },
},
{
  aggregationKey: 'Externally Accessible',
  description: 'These are the externally accessible assets',
  count: 25,
  averagePriorityScore: 70,
  buckets: {
    critical: 10,
    high: 20,
    medium: 30,
    low: 15,
  },
},
];

export const MOCK_CENTRALIZED_REPO_ASSET_NAME = 'not-dot-jit';
export const MOCK_CENTRALIZED_REPO_ASSET_ID = 'not-dot-jit-id';

export const MOCK_CENTRALIZED_REPO_ASSET: IAsset = buildAssetMock({
  asset_name: MOCK_CENTRALIZED_REPO_ASSET_NAME,
  asset_id: MOCK_CENTRALIZED_REPO_ASSET_ID,
  asset_type: AssetType.REPO,
  vendor: Vendor.Github,
});
