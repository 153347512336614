import { GraphEntity } from '../types';

import { Vendor, AssetType } from 'types/enums';
import { GraphEntityType } from 'types/enums/ContextGraphEntityType';
import { IFinding, IAsset } from 'types/interfaces';
import { IEntityGraph, IEntityGraphNode, IEntityGraphEdge } from 'types/interfaces/Graph/IEntityGraph';
import { IPriorityFields } from 'types/interfaces/Graph/IPriorityFactor';

const buildFactorsNodesAndEdges = (sourceNodeId: string, factors: string[]): IEntityGraph => {
  const nodes = factors.map((factor) => ({
    id: factor,
    type: factor,
    factors: [factor],
  }));

  const edges = nodes.map((node) => ({
    id: `${sourceNodeId}_${node.id}`,
    source: sourceNodeId,
    target: node.id,
  }));
  return {
    graph: {
      nodes,
      edges,
    },
  };
};

export const buildDefaultFindingGraph = (finding: IFinding): IEntityGraph => {
  const nodes: IEntityGraphNode[] = [{
    type: 'Finding',
    name: finding.name,
    id: finding.id,
  }];
  const edges = [];

  if (finding.priorityFactors) {
    const factorsGraph = buildFactorsNodesAndEdges(finding.id, finding.priorityFactors);
    nodes.push(...factorsGraph.graph.nodes);
    edges.push(...factorsGraph.graph.edges);
  }

  let relatedResourceNode = null;
  if (finding.codeAttributes) {
    const type = finding.vendor === Vendor.Github ? 'GitHub Repository' : 'GitLab Project';
    relatedResourceNode = {
      type,
      name: finding.locationText,
      id: `${type}#${finding.locationText}`,
    };
  } else if (finding.vendor === Vendor.AWS) {
    relatedResourceNode = {
      type: 'AWS Account',
      name: finding?.cloudAttributes?.accountId || '',
      id: `AWS Account#${finding?.cloudAttributes?.accountId}`,
    };
  }

  if (relatedResourceNode) {
    nodes.push(relatedResourceNode);
    edges.push({
      id: `${finding.id}-${relatedResourceNode.id}`,
      source: finding.id,
      target: relatedResourceNode.id,
    });
  }

  return {
    graph: {
      nodes,
      edges,
    },
  };
};

export const buildDefaultAssetGraph = (asset: IAsset): IEntityGraph => {
  if (asset.asset_type !== AssetType.REPO) {
    // Currently we don't support graph for non-repo assets
    return {
      graph: {
        nodes: [] as IEntityGraphNode[],
        edges: [] as IEntityGraphEdge[],
      },
    };
  }
  const type = asset.vendor === Vendor.Github ? 'GitHub Repository' : 'GitLab Project';
  const assetNode = {
    type,
    name: asset.asset_name,
    id: `${type}#${asset.asset_name}`,
  };
  const nodes: IEntityGraphNode[] = [assetNode];
  const edges = [];
  if (asset.priority_factors) {
    const factorsGraph = buildFactorsNodesAndEdges(asset.asset_id, asset.priority_factors);
    nodes.push(...factorsGraph.graph.nodes);
    edges.push(...factorsGraph.graph.edges);
  }
  return {
    graph: {
      nodes,
      edges,
    },
  };
};

export const getEntityType = (entity: GraphEntity): GraphEntityType => {
  // Identify the entity type by the presence of expected fields
  if ('asset_id' in entity) return GraphEntityType.ASSET;
  if ('fingerprint' in entity) return GraphEntityType.FINDING;
  throw new Error('Invalid entity type');
};

export const findMainNode = (name: string, graph: IEntityGraph): IEntityGraphNode | null => {
  const mainNode = graph.graph.nodes.find((node) => node.name === name || node.name?.split('/')[1] === name);
  return mainNode || null;
};

export const getPriorityFields = (entity: GraphEntity): IPriorityFields => {
  switch (getEntityType(entity)) {
    case GraphEntityType.ASSET:
      return {
        id: (entity as IAsset).asset_id,
        name: (entity as IAsset).asset_name,
        type: GraphEntityType.ASSET,
        manualFactors: (entity as IAsset).manual_factors,
        priorityFactors: (entity as IAsset).priority_factors,
        priorityScore: (entity as IAsset).priority_score,
        assetId: (entity as IAsset).asset_id,
      };
    case GraphEntityType.FINDING:
      return {
        id: (entity as IFinding).id,
        name: (entity as IFinding).name,
        type: GraphEntityType.FINDING,
        priorityFactors: (entity as IFinding).priorityFactors || [],
        priorityScore: (entity as IFinding).priorityScore || 0,
        manualFactors: (entity as IFinding).manualFactors,
        assetId: (entity as IFinding).assetId,
      };
    default:
      throw new Error('Invalid entity type');
  }
};
