import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';

import styles from './BranchProtectionConfiguration.module.scss';
import { ConfigurationsGithubBranchProtectionRequireApproval } from './components/ConfigurationsGithubBranchProtectionRequireApproval';
import { ConfigurationsGithubBranchProtectionRequireStatusChecks } from './components/ConfigurationsGithubBranchProtectionRequireStatusChecks';
import { useConfigurationStateManager } from './hooks/useConfigurationStateManager';

const branchProtectionConfigInitialState = {
  organization: {
    amount_of_required_reviews: 0,
    required_status_checks: [],
  },
};

interface Props {
  setIsDoneStep: Dispatch<SetStateAction<boolean>>;
}

export const BranchProtectionConfiguration: FC<Props> = ({ setIsDoneStep }) => {
  const { currentConfiguration, handleChange } = useConfigurationStateManager(branchProtectionConfigInitialState);
  const [isRequireStatusChecksSelected, setIsRequireStatusChecksSelected] = useState<boolean>(false);

  const handleChangeNumOfApprovals = (newValue: number) => {
    handleChange('amount_of_required_reviews', newValue);
  };

  const handleChangeRequiredChecks = (newValue: string[]) => {
    handleChange('required_status_checks', newValue);
  };

  const isRequierdConfigurationDone = useMemo(
    () => !!currentConfiguration.organization
    // Check required status off when amount_of_required_reviews apply, if RequireStatusChecksSelected it will return true anyway
  && ((currentConfiguration.organization.amount_of_required_reviews > 0 && !isRequireStatusChecksSelected)
  || currentConfiguration.organization.required_status_checks.length > 0),
    [currentConfiguration.organization, isRequireStatusChecksSelected],
  );

  useEffect(() => {
    setIsDoneStep(isRequierdConfigurationDone);
  }, [isRequierdConfigurationDone, setIsDoneStep]);

  return (
    <div className={styles.branchProtectionConfigurationWrapper}>
      <ConfigurationsGithubBranchProtectionRequireApproval
        handleChangeNumOfApprovals={handleChangeNumOfApprovals}
        numOfRequiredApprovals={currentConfiguration.organization?.amount_of_required_reviews
          || branchProtectionConfigInitialState.organization.amount_of_required_reviews}
      />

      <ConfigurationsGithubBranchProtectionRequireStatusChecks
        handleChangeRequiredChecks={handleChangeRequiredChecks}
        onChange={setIsRequireStatusChecksSelected}
        requiredChecks={currentConfiguration.organization?.required_status_checks
          || branchProtectionConfigInitialState.organization.required_status_checks}
      />
    </div>
  );
};
