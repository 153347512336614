import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';
import { ReferenceLineProps } from 'recharts';

import { useScoreWidget } from '../hooks/useScoreWidget';
import styles from '../ScoreChart.module.scss';
import { getIntervalAverage } from '../utils';

import { TooltipFormatter } from 'components/graphs/components/TooltipFormatter/TooltipFormatter';
import { JitLinearGradientChart } from 'components/graphs/LinearGradientChart/JitLinearGradientChart';
import { i18n } from 'locale/i18n';
import { GraphEmpty, GraphLoading } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/components';
import { fetchPrsMetric } from 'services/MetricService/MetricService';
import colors from 'themes/colors.module.scss';
import { TimeAgo } from 'types/enums';
import { Queries } from 'types/enums/Queries';

interface Props {
  period: TimeAgo;
}

const referenceLinePosition = 1.7;

const referenceLineProps: ReferenceLineProps = {
  label: {
    value: i18n.t('pages.securityImpactV2.sections.performance.widgets.secureDevsWorking.referenceLineLabel') as string,
    position: 'left',
    fill: colors.white,
    fontSize: 10,
    dx: 120,
    dy: -10,
  },
  stroke: colors.white,
  strokeDasharray: '5 5',
  y: referenceLinePosition,
};

export const SecureDevsWorkingWidget: FC<Props> = ({ period }) => {
  const { data, isLoading, isError } = useQuery([Queries.PrsStats, period], async () => fetchPrsMetric(period));

  const processedData = useMemo(
    () => data?.days.map(({ date, totalPrs, prsWithFindings }) => {
      const secureScore = totalPrs > 0 ? Math.round((prsWithFindings / totalPrs) * 100) : 0;

      return ({
        date,
        value: secureScore,
      });
    }) || [],
    [data?.days],
  );

  const weeklyData = useMemo(() => getIntervalAverage(processedData, 7), [processedData]);

  const { customXTickFormatter } = useScoreWidget(weeklyData);

  if (isLoading) return <GraphLoading />;
  if (isError || !weeklyData.length) return <GraphEmpty />;

  return (
    <div className={styles.gradientChartWrapper}>
      <JitLinearGradientChart
        CustomTooltip={TooltipFormatter}
        customXTickFormatter={customXTickFormatter}
        data={weeklyData}
        isValuePercentage
        referenceLineProps={referenceLineProps}
        singleColor={colors.errorLight}
        yAxisDomain={null}
      />
    </div>
  );
};
