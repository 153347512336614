import { useQuery } from 'react-query';

import { fetchFindingsCount } from 'services/FindingsService';
import { FindingStatus } from 'types/enums';
import { IgnoreRequestSource } from 'types/enums/IgnoreRules';
import { Queries } from 'types/enums/Queries';
import { IGroupByCount } from 'types/interfaces/Counts/ICounts';

export const useGetFindingsCount = (teamName?: string) => {
  const { data: findingsCount, isLoading } = useQuery(
    [Queries.FindingsCount, teamName],
    () => fetchFindingsCount({ filters: { resolution: [FindingStatus.FIXED],
      // @ts-ignore
      team: teamName,
    },
    group_by: IgnoreRequestSource.BACKLOG,
    }),
    { enabled: !!teamName },
  );

  return {
    findings: findingsCount as IGroupByCount[],
    isLoading,
  };
};
