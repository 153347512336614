
const detectCloudFormationLanguage = (code: string): string => {
  try {
    JSON.parse(code);
    return 'json';
  } catch (e) {
    return 'yaml';
  }
};

type LanguageMapType = {
  [key: string]: string;
};

export const getLanguageFromKey = (key: string, code: string): string => {
  const languageMap: LanguageMapType = {
    aws_cli: 'shell',
    awscli: 'shell',
    terraform: 'hcl', // HCL = hashicorp configuration language, the name of Terraform's syntax.
    pulumi: 'python',
    cloud_formation: detectCloudFormationLanguage(code), // CloudFormation can be both JSON and YAML
    cloudformation: detectCloudFormationLanguage(code), // CloudFormation can be both JSON and YAML
  };

  return languageMap[key.toLowerCase()] || 'plaintext';
};
