import { FixedIssuesWidget } from './components/widgets/FixedIssues/FixedIssuesWidget';
import { IgnoredIssues } from './components/widgets/IgnoredIssues/IgnoredIssues';
import { MostDetectedWidget } from './components/widgets/MostDetectedWidget/MostDetectedWidget';
import { MTTR } from './components/widgets/MTTR/MTTR';
import { OrgScoreWidget } from './components/widgets/OrgScore/OrgScoreWidget';
import { ResolvedIssuesWidget } from './components/widgets/ResolvedIssues/ResolvedIssuesWidget';
import { ResourcesCoverage } from './components/widgets/ResourcesCoverage/ResourcesCoverage';
import { SecureDevsWorkingWidget } from './components/widgets/SecureDevsWorking/SecureDevsWorkingWidget';
import { SecurityAwareDevelopersWidget } from './components/widgets/SecurityAwareDevelopers/SecurityAwareDevelopersWidget';
import { Section } from './types';

import { JitDropdownNew } from 'components/JitDropdownNew/JitDropdownNew';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { TimeAgo } from 'types/enums';
import { ServerFindingKeys } from 'types/interfaces';
import { formatDateToRequestFormat, getTimeAgoRange } from 'utils';

const ResolvedIssuesQueryParams = {
  group_by: 'resolution',
};

const widgetsPeriod = TimeAgo.ThreeMonths;
const { startDate, endDate } = getTimeAgoRange(widgetsPeriod);

const mostDetectedQueryParams = {
  group_by: ServerFindingKeys.test_name,
  filters: {
    created_at_start: formatDateToRequestFormat(startDate),
    created_at_end: formatDateToRequestFormat(endDate),
  },
};

const fixedFindingsBreakdown = 'environment';
const ignoreFindingsBreakdown = 'ignored_reasons';

export const getInsightsSections = (period: TimeAgo = TimeAgo.ThreeMonths): Section[] => [
  {
    title: 'pages.securityImpactV2.sections.securityProgress.title',
    rightTitleElement: <JitText muted size='s' text='pages.securityImpactV2.sections.securityProgress.rightTitleElement' />,
    content: [
      {
        title: 'pages.securityImpactV2.sections.securityProgress.widgets.orgScore.title',
        infoTooltip: 'pages.securityImpactV2.sections.securityProgress.widgets.orgScore.tooltip',
        component: <OrgScoreWidget />,
      },
      {
        title: 'pages.securityImpactV2.sections.securityProgress.widgets.resolvedIssues.title',
        infoTooltip: 'pages.securityImpactV2.sections.securityProgress.widgets.resolvedIssues.tooltip',
        component: <ResolvedIssuesWidget queryParams={ResolvedIssuesQueryParams} />,
      },
      {
        title: 'pages.securityImpactV2.sections.securityProgress.widgets.coveredResources.title',
        component: <ResourcesCoverage filters={{ isCovered: true }} />,
      },
    ],
  },
  {
    title: 'pages.securityImpactV2.sections.performance.title',
    itemWidth: 600,
    rightTitleElement: (
      <JitTooltip followCursor={false} placement='top' title='pages.securityImpactV2.sections.performance.rightTitleElement.tooltip' withHighlightShadow>
        <JitDropdownNew
          displayText='pages.securityImpactV2.sections.performance.rightTitleElement.periodsOptions.last90Days'
          isEnabled={false}
          menuItems={[]}
          width={180}
        />
      </JitTooltip>
    ),
    content: [
      {
        title: 'pages.securityImpactV2.sections.performance.widgets.mttr.title',
        infoTooltip: 'pages.securityImpactV2.sections.performance.widgets.mttr.tooltip',
        component: <MTTR period={period} />,
      },
      {
        title: 'pages.securityImpactV2.sections.performance.widgets.fixedIssues.title',
        infoTooltip: 'pages.securityImpactV2.sections.performance.widgets.fixedIssues.tooltip',
        component: <FixedIssuesWidget breakdown={fixedFindingsBreakdown} period={widgetsPeriod} />,
      },
      {
        title: 'pages.securityImpactV2.sections.performance.widgets.ignoredIssues.title',
        subTitle: 'pages.securityImpactV2.sections.performance.rightTitleElement.periodsOptions.last90Days',
        component: <IgnoredIssues breakdown={ignoreFindingsBreakdown} period={period} />,
      },
      {
        title: 'pages.securityImpactV2.sections.performance.widgets.mostDetectedFindings.title',
        subTitle: 'pages.securityImpactV2.sections.performance.rightTitleElement.periodsOptions.last90Days',
        infoTooltip: 'pages.securityImpactV2.sections.performance.widgets.mostDetectedFindings.tooltip',
        component: <MostDetectedWidget period={widgetsPeriod} queryParams={mostDetectedQueryParams} />,
      },
      {
        title: 'pages.securityImpactV2.sections.performance.widgets.securityAwareDevelopers.title',
        subTitle: 'pages.securityImpactV2.sections.performance.widgets.securityAwareDevelopers.subTitle',
        component: <SecurityAwareDevelopersWidget breakdown={fixedFindingsBreakdown} period={widgetsPeriod} />,
      },
      {
        title: 'pages.securityImpactV2.sections.performance.widgets.secureDevsWorking.title',
        subTitle: 'pages.securityImpactV2.sections.performance.widgets.secureDevsWorking.subTitle',
        component: <SecureDevsWorkingWidget period={widgetsPeriod} />,
      },
    ],
  },
];
