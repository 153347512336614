import { useMemo } from 'react';

import { SeverityCell } from '../../../components/JitTable/templates';
import { FindingStatusCell } from '../../FindingsPage/components';
import { FindingNameCell } from '../components/FindingNameCell/FindingNameCell';
import { FirstDetectedCell } from '../components/FirstDetectedCell/FirstDetectedCell';

import { i18n } from 'locale/i18n';
import { IFindingServer, IPipelineDetailsFindingsColumns, ServerFindingKeys } from 'types/interfaces';
import { TableColumns } from 'types/types/TableColumns';

export const useGetFindingsTableColumns = () => {
  const { t } = i18n;
  const columnHeaders: IPipelineDetailsFindingsColumns = useMemo(() => t('pages.pipelineDetails.findingsTable.columnHeaders', { returnObjects: true }), [t]);

  const getColumnsData = () => {
    const columns = [
      {
        Header: columnHeaders.name,
        accessor: (finding: IFindingServer) => ({
          name: finding[ServerFindingKeys.test_name],
        }),
        Cell: FindingNameCell,
        width: '70%',
      },
      {
        Header: columnHeaders.firstDetected,
        accessor: (finding: IFindingServer) => ({
          firstDetected: finding[ServerFindingKeys.created_at],
        }),
        Cell: FirstDetectedCell,
        width: 180,
      },
      {
        Header: columnHeaders.severity,
        accessor: (finding: IFindingServer) => ({
          severity: finding[ServerFindingKeys.issue_severity],
          fullWord: true,
        }),
        Cell: SeverityCell,
        width: 100,
      },
      {
        Header: columnHeaders.resolution,
        accessor: 'pipeline_resolution',
        Cell: FindingStatusCell,
        width: 100,
      },
    ];

    return { columns };
  };

  const columnsData = useMemo(getColumnsData, [columnHeaders]);

  return { columnsData: columnsData as TableColumns<object> };
};
