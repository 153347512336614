import { FC } from 'react';

import { FilterBox } from '../FilterBox/FilterBox';

import styles from './FiltersBoxes.module.scss';

import { IFilter } from 'types/interfaces/IFilter';

interface Props {
  filters: IFilter[];
  shouldCloseOnItemClick?: boolean;
  updateFilter: (updatedFilter: IFilter) => void;
}

export const FiltersBoxes: FC<Props> = ({ filters, updateFilter, shouldCloseOnItemClick }) => (
  <div className={styles.boxesContainer}>
    {filters.map((filter) => (
      <div
        key={filter.displayText}
      >
        <FilterBox
          key={filter.displayText}
          filter={filter}
          shouldCloseOnItemClick={shouldCloseOnItemClick}
          updateFilter={updateFilter}
        />
      </div>
    ))}
  </div>
);
