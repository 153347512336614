import { useCallback } from 'react';

import { ConvertPipelineResponseIntoIPipelineNew } from 'pages/PipelinesPage/utils';
import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import { IPipelineServiceResponseNew, IResponsedPipelineNew, ISinglePipelineResponse } from 'types/interfaces';

type GetNewPipelinesAttributes = {
  startKey?: string,
  status?: string,
  assetId?: string,
  jitEventName?: string,
};
export const usePipelineService = () => {
  const { client } = useClient();

  const getNewPipelines = useCallback(async (params: GetNewPipelinesAttributes) => {
    const url = getApiUrls.pipelineService.getNewPipelines();
    const convertedParams = {
      start_key: params.startKey || undefined,
      status: params.status || undefined,
      asset_id: params.assetId || undefined,
      jit_event_name: params.jitEventName || undefined,
    };
    return client.get<IPipelineServiceResponseNew>({ url,
      requestConfig: { params: convertedParams },
      allowedStatuses: [200] });
  }, [client]);

  const getPipelineById = useCallback(async (pipelineId: string) => {
    const url = getApiUrls.pipelineService.getPipelineById(pipelineId);
    return client.get<ISinglePipelineResponse>({ url,
      allowedStatuses: [200, 404] });
  }, [client]);

  const getDeploymentsFromCreatedAfter = useCallback(async (environment: string) => {
    const url = getApiUrls.pipelineService.getDeploymentsFromCreatedAfter(environment);
    const deployments = await client.get<IResponsedPipelineNew[]>({ url,
      allowedStatuses: [200] });
    const parsedDeployments = ConvertPipelineResponseIntoIPipelineNew(deployments?.data || []);
    return parsedDeployments;
  }, [client]);

  return {
    getNewPipelines,
    getPipelineById,
    getDeploymentsFromCreatedAfter,
  };
};
