import { isEmpty } from 'lodash';

import { config } from 'config';
import { EVENTS_TYPES, STATUSES } from 'pages/PipelinesPage/constants';
import { AssetType, PipelineScope, Vendor } from 'types/enums';
import { IFilter, IFilterOption, IFilterType } from 'types/interfaces';
import { IAssetFieldsNew, IJobFieldsNew, IPipelineNew, IWorkflowNew } from 'types/interfaces/Pipelines/IPipelineNew';

export const getStatusFromJobs = (jobs: IJobFieldsNew[]) => {
  const hasStatus = (status: string) => jobs.some((job: IJobFieldsNew) => job.calculatedStatus === status);

  if (hasStatus(STATUSES.RUNNING)) {
    return STATUSES.RUNNING;
  }
  if (hasStatus(STATUSES.ERROR)) {
    return STATUSES.ERROR;
  }
  const isFailed = jobs.some((job: IJobFieldsNew) => job.calculatedStatus === STATUSES.FAILED);
  if (isFailed) {
    return STATUSES.FAILED;
  }
  const isCompleted = jobs.every((job: IJobFieldsNew) => job.calculatedStatus === STATUSES.COMPLETED);
  if (isCompleted) {
    return STATUSES.COMPLETED;
  }

  return STATUSES.PENDING;
};

export const getStatusFromAssets = (assets: IAssetFieldsNew[]) => {
  const hasStatus = (status: string) => assets.some((asset) => getStatusFromJobs(Object.values(asset.jobs)) === status);

  if (hasStatus(STATUSES.RUNNING)) {
    return STATUSES.RUNNING;
  }
  if (hasStatus(STATUSES.ERROR)) {
    return STATUSES.ERROR;
  }
  if (hasStatus(STATUSES.FAILED)) {
    return STATUSES.FAILED;
  }
  const isCompleted = assets.every((asset) => getStatusFromJobs(Object.values(asset.jobs)) === STATUSES.COMPLETED);
  if (isCompleted) {
    return STATUSES.COMPLETED;
  }

  return STATUSES.PENDING;
};

export const getJobRunner = (workflows: IWorkflowNew) => {
  const SpecificWorkflow = Object.values(workflows)[0];
  const asset = SpecificWorkflow && SpecificWorkflow.assets && Object.values(SpecificWorkflow.assets)[0];
  return asset && Object.values(asset.jobs)[0].runner;
};

export const getPrLink = (pipeline: IPipelineNew) => {
  if (pipeline.sourceAssetVendor === Vendor.Github && pipeline.additionalAttributes?.pullRequestNumber) {
    return config.githubPrLink(
      pipeline.sourceAssetOwner || '',
      pipeline.sourceAssetName || '',
      pipeline.additionalAttributes?.pullRequestNumber || '',
    );
  }
  return '';
};

export const getTextFromPipelineParams = (vendor: string | undefined, owner: string | undefined, assetName: string | undefined, assetType: string | undefined, eventType: string | undefined) => {
  if (vendor && vendor === Vendor.Github && owner) {
    return assetType === AssetType.ORG ? owner : `${owner}/${assetName}`;
  }
  if (!assetName && (eventType === EVENTS_TYPES.SCHEDULED || eventType === EVENTS_TYPES.ITEM_ACTIVATED)) {
    return 'pages.pipelines.pipelinesTable.TriggerEventDisplayNames.monitoredResources';
  }
  return assetName || '';
};

export const getAssetLinkFromPipelineParams = (vendor: string | undefined, owner: string | undefined, assetName: string | undefined, assetType: string | undefined) => {
  if (vendor === Vendor.Github && owner) {
    if (assetType === AssetType.ORG) {
      return config.getGithubOrgLink(owner);
    }
    if (assetName) {
      return config.getGithubRepoLink({ org: owner,
        repo: assetName });
    }
  }
  return '';
};

export const getEnvironment = (workflows: IWorkflowNew) => {
  const SpecificWorkflow = Object.values(workflows)[0];
  const asset = SpecificWorkflow && SpecificWorkflow.assets && Object.values(SpecificWorkflow.assets)[0];
  return asset && asset.environment;
};

const getPipelineStringValue = (pipeline: IPipelineNew, value: string) => (pipeline[value as keyof typeof pipeline] as string);

const isFilterMatch = (filter: IFilter, pipeline: IPipelineNew) => {
  const isPipelineValueSelected = filter.type === IFilterType.MULTI_SELECT
    ? (filter.selectedValue as IFilterOption[]).map((value) => value.value).includes(getPipelineStringValue(pipeline, filter.entityKey))
    : (filter.selectedValue as IFilterOption)?.value === getPipelineStringValue(pipeline, filter.entityKey);

  const isFilterEmpty = filter.type === IFilterType.SINGLE_SELECT ? isEmpty((filter.selectedValue as IFilterOption)?.value) : isEmpty(filter.selectedValue);

  return isFilterEmpty || isPipelineValueSelected;
};

export const shouldShowPipeline = (filters: IFilter[], pipeline: IPipelineNew) => filters.every((filter) => isFilterMatch(filter, pipeline));

export const getScope = (pipeline: IPipelineNew) => pipeline.scope || PipelineScope.ALL;

