import { FC } from 'react';
import { TooltipProps } from 'recharts';

import styles from './TooltipFormatter.module.scss';

import { JitGrade } from 'components/JitGrade/JitGrade';
import { JitText } from 'components/JitText/JitText';

export const TooltipFormatter: FC<TooltipProps<string, string>> = ({ payload }) => {
  if (!payload?.length) return null;

  const payloadData = payload[0].payload;

  if (payloadData.weeklyAvg === null) {
    return (
      <div className={styles.emptyScoreWrapper}>
        <JitText size='m' text='components.scoreTrendingGraph.noDataTooltip' />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <JitText size='m' text='components.scoreTrendingGraph.tooltip' />

      <JitGrade score={payloadData.weeklyAvg} size='s' />
    </div>
  );
};
