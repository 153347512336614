import { FC } from 'react';

import styles from './JitEmpty.module.scss';

import { JittyEmpty } from 'assets';
import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { ActionButton, ISvg } from 'types/interfaces';

interface Props {
  'data-testid'?: string;
  title: string,
  description?: string
  showJitty?: boolean
  icon?: FC<ISvg>
  titleColor?: string
  descriptionColor?: string
  actionButtons?: ActionButton[];

}

export const JitEmpty: FC<Props> = ({ title, description, actionButtons, showJitty = true, icon, titleColor = colors.white, descriptionColor = colors.white, ...props }) => (
  <div className={styles.pageWrapper} data-testid={props['data-testid'] || 'jitEmpty'}>
    {showJitty && (
      <JitIcon
        className={styles.jittyIconWrapper}
        color='rgba(0, 0, 0, 0)'
        data-testid='jittyEmptyIcon'
        icon={icon || JittyEmpty}
        iconClassName={styles.jittyEmptyIcon}
      />
    )}

    <div className={styles.textWrapper}>
      <JitText align='center' bold color={titleColor} data-testid='jitEmptyTitle' size='l' text={title} />

      {!!description
    && (
      <JitText
        align='center'
        color={descriptionColor}
        data-testid='jitEmptyTitleDescription'
        marginTop='4px'
        size='m'
        text={description}
      />
    )}
    </div>

    <div className={styles.buttons}>
      {actionButtons?.map((actionButton: ActionButton) => <JitActionButton key={`actionButton-${actionButton.label}`} actionButton={actionButton} />)}
    </div>
  </div>
);

