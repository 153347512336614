import { useMemo } from 'react';

import { useAuthContext } from 'context/AuthContext/AuthContext';
import { IMember } from 'types/interfaces/Teams/ITeam';
import { getUserVendorIdFromProfilePicture } from 'utils/functions/getUserVendorIdFromProfilePicture';

export const useIsUserMemberInTeam = (members: IMember[]) => {
  const { frontEggUser } = useAuthContext();
  const userVendorId = useMemo(() => getUserVendorIdFromProfilePicture(frontEggUser.profilePictureUrl || ''), [frontEggUser.profilePictureUrl]);
  const isMemberInTeam = members.some((member: IMember) => member.vendorId === userVendorId);

  return { isMemberInTeam };
};
