import { useCallback, useState } from 'react';

import { useTenantContext } from 'context/TenantContext/TenantContext';
import { useTenantService } from 'services/TenantService/useTenantService';
import { CentralizedAssetValidationStatus } from 'types/enums/CentralizedAssetValidationStatus';
import { IAsset } from 'types/interfaces';

export const useSetCentralizedRepo = () => {
  const { githubInstallation } = useTenantContext();
  const [centralizedRepoResponseStatus, setCentralizedRepoResponseStatus] = useState<CentralizedAssetValidationStatus | undefined>(undefined);
  const { setCentralizedRepo } = useTenantService();

  const handleSetCentralizedRepo = useCallback(async (centralizedRepoAsset: IAsset) => {
    let status: CentralizedAssetValidationStatus | undefined;
    if (githubInstallation) {
      status = CentralizedAssetValidationStatus.PENDING;
      setCentralizedRepoResponseStatus(status);
      try {
        await setCentralizedRepo(githubInstallation, centralizedRepoAsset);
        status = CentralizedAssetValidationStatus.VALID;
        setCentralizedRepoResponseStatus(status);
      } catch (error) {
        status = CentralizedAssetValidationStatus.INVALID;
        setCentralizedRepoResponseStatus(status);
      }
    }
    return status;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [githubInstallation]);

  return {
    handleSetCentralizedRepo,
    centralizedRepoResponseStatus,
  };
};
