import { FC, useEffect, useMemo } from 'react';

import { CustomDialog } from '../CustomDialog';

import styles from './SlackIntegrationDialog.module.scss';

import { SlackIcon } from 'assets';
import { SlackUsersSection } from 'components/JitDialogs/SlackIntegrationDialog/components/SlackUsersSection/SlackUsersSection';
import { TeamSlackNotifications } from 'components/JitDialogs/SlackIntegrationDialog/components/TeamSlackNotifications/TeamSlackNotifications';
import { TenantSlackNotifications } from 'components/JitDialogs/SlackIntegrationDialog/components/TenantSlackNotifications/TenantSlackNotifications';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import colors from 'themes/colors.module.scss';
import { ActionButton, ActionButtonVariant } from 'types/interfaces/ActionButton/ActionButton';

export enum SlackIntegrationDialogStage {
  TEAM = 'team',
  TENANT = 'tenant',
}

export interface ITeamStageProps {
  type: SlackIntegrationDialogStage.TEAM,
  teamName: string,
  teamId: string,
}

export interface ITenantStageProps {
  type: SlackIntegrationDialogStage.TENANT,
}

interface Props {
  isOpen: boolean,
  onClose: () => void,
  stage: ITeamStageProps | ITenantStageProps,
}

export const SlackIntegrationDialog: FC<Props> = ({ isOpen, onClose, stage }) => {
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const { initPreferences } = useTenantContext();

  const buttons: ActionButton[] = [
    {
      label: 'Done',
      handleClick: onClose,
      variant: ActionButtonVariant.PRIMARY,
    },
  ];

  useEffect(() => {
    initPreferences();
  }, [initPreferences]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    sendAnalyticsEvent({ action: 'slack-config-opened' });
  }, [sendAnalyticsEvent, isOpen]);

  const stageContentMap = useMemo(() => ({
    [SlackIntegrationDialogStage.TEAM]: {
      title: (stage as ITeamStageProps).teamName,
      description: 'dialogs.integration.slack.channels.teamNotifications.description',
      content: isOpen ? (
        <TeamSlackNotifications teamId={(stage as ITeamStageProps).teamId} teamName={(stage as ITeamStageProps).teamName} />
      ) : null,
    },
    [SlackIntegrationDialogStage.TENANT]: {
      title: 'dialogs.integration.slack.channels.tenantNotifications.title',
      description: 'dialogs.integration.slack.channels.tenantNotifications.description',
      content: isOpen ? (
        <>
          <div className={styles.channelsSectionWrapper}>
            <TenantSlackNotifications />
          </div>

          <SlackUsersSection onClose={onClose} />
        </>
      ) : null,
    },
  }), [isOpen, onClose, stage]);

  const { title, description, content } = stageContentMap[stage.type];

  return (
    <CustomDialog
      actionButtons={buttons}
      content={(
        <div className={styles.dialogWrapper}>
          <JitIcon icon={SlackIcon} size={44} style={{ marginBottom: '20px' }} />

          <JitText bold className={styles.title} size='m' text={title} />

          <JitText className={styles.description} color={colors.lightGray} size='m' text={description} />

          {content}

        </div>
      )}
      dataTestId='slackIntegrationDialog'
      isOpen={isOpen}
      onClose={onClose}
      title='dialogs.integration.slack.title'
      width='xl'
      withDivider
    />
  );
};
