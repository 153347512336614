import { FC } from 'react';
import { PieChart, Pie, Cell, Label } from 'recharts';

import styles from './CompletionGauge.module.scss';

import colors from 'themes/colors.module.scss';
import { calculateGradientColor } from 'utils/functions/calculateGradientColor';

interface Props {
  completed: number;
  total: number;
}

const styleProps = {
  height: 72,
  width: 72,
  innerRadius: 32,
  outerRadius: 32,
  strokeWidth: 5,
  background: colors.white,
  textSize: 14,
  verticalAlign: '50%',
  paddingRadius: 4.25,
};

export const CompletionGauge: FC<Props> = ({ completed, total }) => {
  const score = completed / total;
  const data = [
    { isData: true,
      value: score },
    { isData: false,
      value: 1 - score },
  ];

  const endAngle = -270;
  const startAngle = 90;

  const { innerRadius, outerRadius, width, strokeWidth, height, background, textSize, verticalAlign, paddingRadius } = styleProps;

  const intermediateColor = calculateGradientColor(colors.jitPink, colors.progressGradientStart, 0.6);
  const gradientId = `completion-gauge-gradient-${score}}`;

  return (
    <div className={styles.wrapper}>
      <div className={styles.background} />

      <PieChart className={styles.pieWrapper} height={height} width={width}>

        <defs>
          <linearGradient id={gradientId} x1='0' x2='1' y1='0' y2='0'>
            <stop offset='0%' stopColor={colors.progressGradientStart} />

            <stop offset='100%' stopColor={intermediateColor} />
          </linearGradient>
        </defs>

        <Pie
          cy={verticalAlign}
          data={data}
          dataKey='value'
          endAngle={endAngle}
          fill='green'
          innerRadius={innerRadius}
          isAnimationActive
          outerRadius={outerRadius}
          paddingAngle={score === 0 ? 0 : paddingRadius}
          startAngle={startAngle}
          stroke='green'
          style={{ fill: 'green' }}
        >
          {data.map(({ isData }) => (
            <Cell
              key={`cell-${isData}`}
              stroke={isData ? `url(#${gradientId})` : background}
              strokeLinejoin='round'
              strokeWidth={isData ? strokeWidth : 0}
              style={{ background: 'green' }}
            />
          ))}

          <Label
            position='center'
            style={{ fontSize: textSize,
              fontWeight: 'bold',
              fill: colors.white }}
            value={`${completed} / ${total}`}
          />
        </Pie>
      </PieChart>
    </div>
  );
};
