import { FC } from 'react';

import { FindingsPage } from './FindingsPage';

import { FindingsProvider } from 'context/FindingsContext/FindingsProvider';
import { useNewBacklogPageRoute } from 'pages/FindingsPage/hooks/useNewBacklogPageRoute';

export const FindingsPageWrapper: FC = () => {
  useNewBacklogPageRoute();

  return (
    <FindingsProvider>
      <FindingsPage />
    </FindingsProvider>
  );
};
