import Collapse from '@mui/material/Collapse';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useState } from 'react';

import { AssetManagementTable } from '../AssetManagementTable/AssetManagementTable';
import { HEADERS_LIST } from '../SelectionList/constants';

import styles from './OwnerAccordionCustomAws.module.scss';

import { ChevronClosed, ChevronOpened, Reload } from 'assets';
import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { usePlanService } from 'services/PlanService/usePlanService';
import colors from 'themes/colors.module.scss';
import { IAsset, IChangeEvent, IMouseEvent } from 'types/interfaces';
import { stopPropagation } from 'utils';

interface Props {
  owner: string;
  assets: IAsset[];
  checkAsset: (assetId: string, checked: boolean) => void;
  checkMultipleAssetsByOwner: (assetId: string, checked: boolean) => void;
  ownerDisplayNames: { [key: string]: string };
}

export const OwnerAccordionCustomAws: FC<Props> = ({
  owner,
  assets,
  checkAsset,
  checkMultipleAssetsByOwner,
  ownerDisplayNames,
}) => {
  const { hasOldAwsIntegration } = useFlags();

  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [isReloading, setIsReloading] = useState<boolean>(false);
  const [isReloadHovered, setIsReloadHovered] = useState<boolean>(false);
  const isAllAssetsOfOwnerSelected = assets.every((asset) => asset.is_covered);
  const { reloadAwsAccounts } = usePlanService();

  const toggleExpanded = () => setIsExpanded((currentExpanded) => !currentExpanded);

  const reloadAssets = (e: IMouseEvent) => {
    setIsReloading(true);
    e.stopPropagation();
    reloadAwsAccounts(owner).then(() => setIsReloading(false));
  };

  const handleSelectAllAssetsOfOwner = (e: IChangeEvent, checked: boolean) => {
    checkMultipleAssetsByOwner(owner, checked);
  };

  // In the case of AWS, the ownerName is the organization title
  const ownerName = ownerDisplayNames[owner];

  return (
    <div data-testid='owner-heading'>
      <div
        className={styles.orgContainer}
        onClick={toggleExpanded}
        role='button'
        tabIndex={0}
      >
        <JitCheckbox
          checked={isAllAssetsOfOwnerSelected}
          className={styles.ownerCheckBox}
          data-testid='select-all-assets-of-owner'
          id={owner}
          onChange={handleSelectAllAssetsOfOwner}
          onClick={stopPropagation}
        />

        <JitText className={styles.ownerText} params={{ orgTitle: ownerName }} text='dialogs.assetsManagement.awsOrganizationTopRow' />

        <div className={styles.iconContainer}>
          <JitIcon
            color={colors.gray}
            icon={isExpanded ? ChevronOpened : ChevronClosed}
            iconClassName={styles.icon}
          />
        </div>

        {hasOldAwsIntegration && (
        <div className={styles.iconContainer} data-testid='awsReloadAccountsButtonContainer'>
          <div
            onMouseEnter={() => setIsReloadHovered(true)}
            onMouseLeave={() => setIsReloadHovered(false)}
          >
            <JitIcon
              color={isReloadHovered ? colors.white : colors.gray}
              icon={Reload}
              iconClassName={isReloading ? styles.iconSpinner : undefined}
              onClick={isReloading ? undefined : reloadAssets}
            />
          </div>

        </div>
        )}

      </div>

      <Collapse
        data-testid='owner-details'
        in={isExpanded}
        style={{
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >

        <div className={styles.contentContainer}>
          <AssetManagementTable assets={assets} checkAsset={checkAsset} headers={HEADERS_LIST} />
        </div>
      </Collapse>
    </div>
  );
};
