import { FC } from 'react';
import { TooltipProps } from 'recharts';

import styles from './OpenFindingsGraph.module.scss';

import { JitText } from 'components/JitText/JitText';
import { formatDate } from 'utils';

interface OpenFindingsTooltipProps extends TooltipProps<string, string> {}

export const OpenFindingsTooltip: FC<OpenFindingsTooltipProps> = ({ payload, label }) => {
  const dataRecord = payload?.[0]?.payload;

  if (!dataRecord) return null;

  const tooltipContentItems = [
    {
      text: 'pages.securityImpact.securityPosture.detectedFindings.openFindings',
      value: dataRecord.openFindings,
    },
  ];

  return (
    <div className={styles.tooltipWrapper}>
      <JitText muted size='xs' text={formatDate(label, false)} />

      {tooltipContentItems.map((item) => (
        <div key={item.text}>
          <JitText size='s' text={item.text} />

          <JitText bold size='s' text={item.value} />
        </div>
      ))}
    </div>
  );
};
