import { IAsset } from './IAsset';

export enum IntegrationStatus {
  CONNECTED = 'connected',
  CONNECTING = 'connecting',
  DISCONNECTED = 'disconnected',
  DISCONNECTING = 'disconnecting',
  WARNING = 'warning',
  ERROR = 'error',
  PENDING = 'pending',
}

export enum RepositorySelection {
  ALL = 'all',
  SELECTED = 'selected',
}

export interface IInstallationModelVendorResponse {
  integration_url: string;
  regions: string[];
}

export interface IInstallationModelVendorAttributes {
  repository_selection: RepositorySelection;
  html_url: string;
}

interface IOauthContext {
  [key: string]: string;
}

export interface IInstallation {
  tenant_id: string;
  app_id: string;
  owner: string;
  installation_id: string;
  is_active: boolean; // TODO: this may be redundant - we should get only active installations
  creator: string;
  vendor: string;
  name: string;
  created_at: string;
  modified_at: string;
  status: IntegrationStatus;
  integration_version?: string;
  vendor_response?: IInstallationModelVendorResponse;
  vendor_attributes?: IInstallationModelVendorAttributes;
  centralized_repo_asset_id?: string;
  centralized_repo_asset?: IAsset;
  oauth_context?: IOauthContext;
}
