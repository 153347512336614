import { useState } from 'react';
import { useMutation } from 'react-query';

import { logError } from 'services/logger/logger';
import { validateLoginParams, fetchLoginValidationDetails } from 'services/ValidationService/ValidationService';
import { ZapApplicationFormBasedAuth } from 'types/interfaces';

export const POLLING_INTERVAL = 2000;
export const POLLING_TIMEOUT = 60000;
export const POLLING_DELAY = 10000;

interface PollValidationResult {
  success: boolean;
  message: string;
  screenshot?: string;
}

export const usePollValidationDetails = (
  timeoutMs = POLLING_TIMEOUT,
): {
    isLoading: boolean;
    error: string | null;
    result: PollValidationResult | null;
    startValidation: (params: ZapApplicationFormBasedAuth) => Promise<void>;
  } => {
  const [error, setError] = useState<string | null>(null);
  const [result, setResult] = useState<PollValidationResult | null>(null);
  const [isPolling, setIsPolling] = useState<boolean>(false);

  const pollValidationDetails = async (validationKey: string, startTime: number): Promise<void> => {
    const poll = async (): Promise<void> => {
      try {
        const data = await fetchLoginValidationDetails(validationKey);
        if (data?.validationResult || Date.now() - startTime >= timeoutMs) {
          setIsPolling(false); // Stop polling state
          setResult({
            success: data?.validationResult?.hasLoggedIn || false,
            screenshot: data?.validationResult?.screenshot,
            message: data?.validationResult?.hasLoggedIn
              ? 'configurations.zap.validation.loginValidationSuccess'
              : 'configurations.zap.validation.loginValidationFailed',
          });
          return;
        }

        setTimeout(poll, POLLING_INTERVAL); // Continue polling
      } catch (err) {
        // In case of an error, stop polling and set error state
        setIsPolling(false);
        logError('Error during polling validation details:', { err });
        setError('Error during polling validation details.');
      }
    };

    await poll();
  };

  const { mutateAsync: startValidation, isLoading: isStartingValidation } = useMutation(
    async (validationParams: ZapApplicationFormBasedAuth) => {
      setError(null);
      setResult(null);

      if (!validationParams.login_page_url || !validationParams.username || !validationParams.authentication_value) {
        setResult({
          success: true,
          message: 'configurations.zap.validation.missingFields',
        });
        return;
      }
      setIsPolling(true); // Start polling state
      try {
        const response = await validateLoginParams(
          validationParams.login_page_url,
          validationParams.username,
          validationParams.authentication_value,
          validationParams.api_domain,
          validationParams.username_css_selector,
          validationParams.password_css_selector,
        );

        if (response?.validationKey) {
          // Introduce a  delay before starting the polling
          setTimeout(async () => {
            await pollValidationDetails(response.validationKey, Date.now());
          }, POLLING_DELAY); // Delay before starting the polling - validation flow is not instant
        } else {
          setResult({
            success: true,
            message: 'configurations.zap.validation.loginValidationFailed',
          });
        }
      } catch (err) {
        logError('Error fetching validation key:', { err });
        setError('Error fetching validation key.');
        setResult({
          success: true,
          message: 'configurations.zap.validation.loginValidationFailed',
        });
      }
    },
  );

  return {
    isLoading: isStartingValidation || isPolling, // Reflect both starting and polling states
    error,
    result,
    startValidation,
  };
};
