import Avatar from '@mui/material/Avatar';
import { FC, UIEvent } from 'react';

import styles from './MembersList.module.scss';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { calcShouldFetchMore } from 'components/JitTable/utils';
import { JitText } from 'components/JitText/JitText';
import { useTeamsContext } from 'context/TeamsContext/TeamsContext';
import { TEAM_ROW_APPROXIMATE_HEIGHT as MEMBER_ROW_APPROXIMATE_HEIGHT } from 'pages/TeamsPage/constants';
import { IPaginatedState } from 'types/interfaces/IPaginatedState';
import { IMember } from 'types/interfaces/Teams/ITeam';

interface Props {
  members: IPaginatedState<IMember>;
}

export const MembersList: FC<Props> = ({ members }) => {
  const { getNextMembers } = useTeamsContext();
  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    if (members.isLoading || members.hasReachedEnd) return;

    const shouldFetchMore = calcShouldFetchMore(event, MEMBER_ROW_APPROXIMATE_HEIGHT);
    if (shouldFetchMore) {
      getNextMembers();
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <JitText data-testid='membersListTitle' muted noWrap params={{ membersCount: members.data.length }} size='s' text='pages.teams.teamDetails.membersList.title' />

        {!members.data.length && <JitText data-testid='noMembersDescription' muted text='pages.teams.teamDetails.membersList.noMembers' />}
      </div>

      <div className={styles.listWrapper} onScroll={handleScroll}>

        {members.data.map((member) => (
          <div key={member.id} className={styles.memberRow} style={{ opacity: members.isLoading ? 0.5 : 1 }}>
            <Avatar
              src={member.avatarUrl}
              sx={{
                width: 24,
                height: 24,
              }}
            />

            <JitText text={member.name} />
          </div>
        ))}
      </div>

      <div className={styles.loadingSpinnerWrapper}>
        {members.isLoading && <CircularLoadingSpinner size='large' />}
      </div>
    </div>
  );
};
