import { FC, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { TeamsBreadcrumbs } from '../TeamsBreadcrumbs/TeamsBreadcrumbs';

import { ResourcePlanItemsStatusesTable } from './components/ResourcePlanItemsStatuses/ResourcePlanItemsStatusesTable';
import { StatsCards } from './components/StatsCards/StatsCards';
import styles from './ResourcePlanItemsStatusesPage.module.scss';

import { JittyTheExplorer, Link } from 'assets';
import { GeneralErrorPage } from 'components/GeneralErrorPage/GeneralErrorPage';
import { JitDivider } from 'components/JitDivider/JitDivider';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { PageTitles } from 'components/PageTitles/PageTitles';
import { useTeamsContext } from 'context/TeamsContext/TeamsContext';
import { i18n } from 'locale/i18n';
import { buildResourceURL } from 'pages/TeamsPage/components/ResourcePlanItemsStatusesPage/utils/buildResourceURL';
import colors from 'themes/colors.module.scss';
import { IAsset, ServerFindingKeys } from 'types/interfaces';
import { useGetTableData } from 'utils/hooks/useGetResourcePlanStatusTableData';

export interface ResourcePlanItemsStatusesTableData {
  name: string;
  passed: boolean;
  findingsCount: number;
  slug: string;
}

interface Props {
  resource: IAsset;
}

export const ResourcePlanItemsStatusesPage: FC<Props> = ({ resource }) => {
  const {
    getSpecificTeamById,
    specificTeam,
  } = useTeamsContext();
  const { teamId } = useParams();

  useEffect(() => {
    if (teamId) {
      getSpecificTeamById(teamId);
    }
  }, [teamId]); // eslint-disable-line react-hooks/exhaustive-deps -- We want to run this only once

  const {
    isLoading,
    tableData,
    totalFindingsCount,
  } = useGetTableData(resource.asset_id, resource.asset_name, specificTeam.team?.name);

  const resourceURL = buildResourceURL(resource.asset_name, resource.owner, resource.vendor);

  const vendorDisplayName = i18n.t(`vendor.${resource.vendor}`);
  const linkTitle = i18n.t('pages.teams.resourcePlanItemsStatuses.resourceLink', { vendor: vendorDisplayName });

  const content = useMemo(() => (
    <>
      <div className={styles.header}>
        <div>

          <PageTitles title={resource.asset_name} />

          {resourceURL && (

            <JitLink
              className={styles.githubLink}
              data-testid='asset-link'
              href={resourceURL}
              rel='noreferrer'
              target='_blank'
              title={linkTitle}
              underline='none'
            >
              <JitText color={colors.blue02} text={linkTitle} />

              <JitIcon color={colors.blue02} data-testid='link-icon' icon={Link} size={17} />

            </JitLink>
          )}
        </div>

        <StatsCards
          filterName={ServerFindingKeys.asset_name}
          filterValue={resource.asset_name}
          score={resource.score || 0}
          totalFindingsCount={isLoading ? undefined : totalFindingsCount}
        />
      </div>

      <JitDivider />

      <ResourcePlanItemsStatusesTable
        assetName={resource.asset_name}
        isLoading={isLoading}
        tableData={tableData}
        teamName={specificTeam.team?.name}
      />
    </>
  ), [isLoading, linkTitle, resource.asset_name, resource.score, resourceURL, specificTeam.team?.name, tableData, totalFindingsCount]);

  const shouldShowLoadingBar = specificTeam.isLoadingSpecificTeam || isLoading;

  return (
    (shouldShowLoadingBar ? <LoadingBar />
      : (
        <div className={styles.wrapper}>
          <TeamsBreadcrumbs assetName={resource.asset_name} teamId={specificTeam?.team?.id} teamName={specificTeam?.team?.name} />

          {specificTeam.team ? (
            content
          ) : (
            <GeneralErrorPage
              backgroundColor='transparent'
              description='pages.teams.teamDetails.notFound.description'
              icon={JittyTheExplorer}
              isFullPage={false}
              title='pages.teams.teamDetails.notFound.title'
            />
          )}
        </div>
      )
    )
  );
};
