import groupBy from 'lodash/groupBy';
import { FC } from 'react';

import { OwnerAccordion } from '..';

import { IAsset } from 'types/interfaces';

interface Props {
  assets: IAsset[];
  checkAsset: (assetId: string, checked: boolean) => void;
  checkMultipleAssetsByOwner: (owner: string, checked: boolean) => void;
  accordionOwnerTypeTitle: string;
  accordionTableAssetColumnHeader: string;
}

export const SelectionListAccordion: FC<Props> = ({
  assets,
  checkAsset,
  checkMultipleAssetsByOwner,
  accordionOwnerTypeTitle,
  accordionTableAssetColumnHeader,
}) => {
  const assetsGroupedByOwner: { [owner: string]: IAsset[] } = groupBy(assets, 'owner');
  return (
    <>
      {Object.entries(assetsGroupedByOwner).map(([owner, assetsByOwner]) => (
        <OwnerAccordion
          key={owner}
          accordionOwnerTypeTitle={accordionOwnerTypeTitle}
          accordionTableAssetColumnHeader={accordionTableAssetColumnHeader}
          assets={assetsByOwner}
          checkAsset={checkAsset}
          checkMultipleAssetsByOwner={checkMultipleAssetsByOwner}
          ownerName={owner}
        />
      ))}
    </>
  );
};
