import { FC } from 'react';

import { TeamsProvider } from 'context/TeamsContext/TeamsProvider';
import { TeamsPage } from 'pages/TeamsPage/SubPages/TeamsPage/TeamsPage';

export const TeamsPageWrapper: FC = () => (
  <TeamsProvider>
    <TeamsPage />
  </TeamsProvider>
);
