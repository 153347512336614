import {
  AWS_GENERAL_PERMISSION_LINK,
  AZURE_GENERAL_PERMISSION_LINK,
  GCP_GENERAL_PERMISSION_LINK, GITHUB_GENERAL_PERMISSION_LINK,
} from 'pages/IntegrationsPage/components/integrationCards/AWSIntegrationCard/constants';
import { Vendor } from 'types/enums';

export const getPermissionLinkForVendor = (vendor: Vendor): string | undefined => {
  switch (vendor) {
    case Vendor.AWS:
      return AWS_GENERAL_PERMISSION_LINK;
    case Vendor.GCP:
      return GCP_GENERAL_PERMISSION_LINK;
    case Vendor.AZURE:
      return AZURE_GENERAL_PERMISSION_LINK;
    case Vendor.Github:
      return GITHUB_GENERAL_PERMISSION_LINK;
    default:
      return undefined;
  }
};
