import { useFindingTableContext } from 'components/FindingsTable/FindingsTableContext/FindingsTableContext';
import { useGraphContext } from 'context/GraphContext/GraphContext';
import { useGraphService } from 'services/GraphService/useGraphService';
import { GraphEntityType } from 'types/enums/ContextGraphEntityType';
import { ManualFactors, IFinding, IPriorityContext, IAsset } from 'types/interfaces';
import { IPriorityEntity, IPriorityFactor, IPriorityFields } from 'types/interfaces/Graph/IPriorityFactor';

export const useAddRemoveFactors = () => {
  const { updateFindingPriorityContext, updateAssetPriorityContext } = useGraphService();
  const { allPriorityFactors } = useGraphContext();
  const { setFindings } = useFindingTableContext();

  const getFactor = (factor: string): IPriorityFactor | undefined => allPriorityFactors.find((priorityFactor) => priorityFactor.key === factor);

  const calcIsFactorManuallyRemoved = (manualFactors: ManualFactors, selectedFactor: IPriorityFactor) => manualFactors.removed.some((factor) => factor.factor
    === selectedFactor.key);

  const calcIsFactorManuallyAdded = (manualFactors: ManualFactors, selectedFactor: IPriorityFactor) => manualFactors.added.some((factor) => factor.factor
    === selectedFactor.key);

  const calcUpdatedScore = (priorityContext: IPriorityContext) => Object.values(priorityContext).reduce((acc, currentFactor) => acc
    + (currentFactor.weight || 0), 0);

  const calcFindingPriorityContext = (finding: IFinding, manualFactors: ManualFactors): IPriorityContext => {
    const newContext = {
      ...finding.originalPriorityContext,
      ...manualFactors.added.reduce((acc, { factor, userExplanation }) => ({
        ...acc,
        [factor]: {
          static_description: factor,
          weight: getFactor(factor)?.defaultWeight || 0,
          description: userExplanation,
        },
      }), {}),
    };
    manualFactors.removed.forEach(({ factor }) => {
      // @ts-ignore
      delete newContext[factor];
    });

    return newContext;
  };

  const updateFindingState = (finding: IFinding, updatedManualFactors: ManualFactors) => {
    // Update the state locally
    setFindings((prevFindings) => prevFindings.map((prevFinding) => {
      if (prevFinding.id !== finding.id) return prevFinding;

      const newPriorityContext = calcFindingPriorityContext(prevFinding, updatedManualFactors);
      return {
        ...prevFinding,
        priorityFactors: Object.keys(newPriorityContext),
        priorityContext: newPriorityContext,
        priorityScore: calcUpdatedScore(newPriorityContext),
        manualFactors: updatedManualFactors,
      };
    }));
  };

  const handleSaveEntity = async (priorityFields: IPriorityFields, entity: IPriorityEntity, manualFactors: ManualFactors) => {
    if (priorityFields.type === GraphEntityType.FINDING) {
      const finding = entity as IFinding;
      await updateFindingPriorityContext(finding.id, manualFactors);
      updateFindingState(finding, manualFactors);
    }
    if (priorityFields.type === GraphEntityType.ASSET) {
      const asset = entity as IAsset;
      await updateAssetPriorityContext(asset.asset_id, manualFactors);
    }
  };

  const addFactors = async (priorityFields: IPriorityFields, priorityEntity: IPriorityEntity, factors: IPriorityFactor[]) => {
    const updatedManualFactors = { ...priorityFields.manualFactors };

    factors.forEach((factor) => {
      const isFactorManuallyRemoved = calcIsFactorManuallyRemoved(priorityFields.manualFactors, factor);
      if (isFactorManuallyRemoved) {
        updatedManualFactors.removed = updatedManualFactors.removed.filter((removedFactor) => removedFactor.factor !== factor.key);
      } else {
        updatedManualFactors.added.push({
          factor: factor.key,
          source: priorityFields.type,
        });
      }
    });
    await handleSaveEntity(priorityFields, priorityEntity, updatedManualFactors);
  };

  const removeFactors = async (priorityFields: IPriorityFields, entity: IPriorityEntity, factors: IPriorityFactor[]) => {
    const entityManualFactors = priorityFields.manualFactors;
    const updatedManualFactors = { ...entityManualFactors };

    factors.forEach((factor) => {
      const isFactorManuallyAdded = calcIsFactorManuallyAdded(entityManualFactors, factor);
      if (isFactorManuallyAdded) {
        updatedManualFactors.added = updatedManualFactors.added.filter((addedFactor) => addedFactor.factor !== factor.key);
      } else {
        updatedManualFactors.removed.push({
          factor: factor.key,
          source: priorityFields.type,
        });
      }
    });
    await handleSaveEntity(priorityFields, entity, updatedManualFactors);
  };

  return {
    addFactors,
    removeFactors,
    calcUpdatedScore,
  };
};
