import { FC, useState } from 'react';

import styles from './FindingsCountBanner.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface FindingsCountBannerProps {
  onClick: () => void;
  findingsCount?: number;
  navigateToFindings?: boolean;
}

export const FindingsCountBanner: FC<FindingsCountBannerProps> = ({
  onClick,
  findingsCount,
  navigateToFindings,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const findingsCountText = findingsCount ?? '---';
  const actionProps = !navigateToFindings ? {} : {
    onClick,
    onMouseEnter: () => setIsHovered(true),
    onMouseLeave: () => setIsHovered(false),
    role: 'button',
    tabIndex: 0,
  };
  return (
    <div
      className={styles.wrapper}
      {...actionProps}
    >
      <JitText muted text='pages.teams.teamDetails.securityFindings' />

      <JitText color={colors.blue02} data-testid='findings-count-banner-count' text={isHovered ? 'pages.teams.teamDetails.seeDetails' : findingsCountText} />
    </div>
  );
};
