import { AdminPortal } from '@frontegg/react';
import Collapse from '@mui/material/Collapse';
import { FC, useCallback, useState } from 'react';

import styles from './SlackUsersSection.module.scss';

import { ArrowRight } from 'assets';
import { SlackUserNotifications } from 'components/JitDialogs/SlackIntegrationDialog/components/SlackUserNotifications/SlackUserNotifications';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { useGetSlackUsers } from 'context/SlackContext/hooks/useGetSlackUsers';
import { useGetSlackUserVendor } from 'context/TenantContext/tenantHooks/useGetSlackUserVendor';
import { useGetTenantUsers } from 'context/TenantContext/tenantHooks/useGetTenantUsers';
import colors from 'themes/colors.module.scss';
import { ISlackUserResponse } from 'types/interfaces/Slack';

const ICON_SIZE = 25;

const SLACK_BOT_ID = 'USLACKBOT';

interface Props {
  onClose: () => void;
}

export const SlackUsersSection: FC<Props> = ({ onClose }) => {
  const { tenantUsers } = useGetTenantUsers();
  const { slackUsersVendors, setSlackUsersVendors } = useGetSlackUserVendor();
  const { slackUsers } = useGetSlackUsers();
  const [showSlackUsers, setShowSlackUsers] = useState(false);

  const handleInviteLinkClick = () => {
    onClose();
    AdminPortal.show();
  };

  const isSlackUserValid = (slackUser: ISlackUserResponse) => (!slackUser.deleted && !slackUser.is_invited_user
      && !slackUser.is_restricted && !slackUser.is_ultra_restricted && !slackUser.is_app_user && !slackUser.is_bot
      && slackUser.id !== SLACK_BOT_ID);

  const getSlackUsersVendorsVendorIds = useCallback(() => slackUsersVendors?.map((tenantUser) => tenantUser.user_vendor_id), [slackUsersVendors]);

  return (
    <div data-testid='slackUserSection'>
      <div style={{ display: 'flex' }}>

        <JitIcon
          className={styles.arrowWrapper}
          color={colors.iris}
          icon={ArrowRight}
          onClick={() => setShowSlackUsers(!showSlackUsers)}
          rotateDegrees={showSlackUsers ? 90 : 0}
          size={ICON_SIZE}
        />

        <JitText
          bold
          size='m'
          text='dialogs.integration.slack.users.title'
        />
      </div>

      <JitText
        bold
        className={styles.description}
        color={colors.lightGray}
        size='m'
        text='dialogs.integration.slack.users.description'
      />

      <Collapse
        data-testid='standalone-accounts-details'
        in={showSlackUsers}
      >

        <div>
          {tenantUsers?.length ? (
            tenantUsers?.filter((tenantUser) => tenantUser.email).sort((a, b) => a.name.localeCompare(b.name)).map((tenantUser) => {
              const selected = slackUsersVendors?.find((u) => u.user_id === tenantUser.id);
              const availableSlackUsers = slackUsers?.filter((u) => isSlackUserValid(u) && !getSlackUsersVendorsVendorIds()?.includes(u.id));
              return (
                <SlackUserNotifications
                  key={`${tenantUser.id}-${selected}`}
                  isLoadingSlackUsers={(slackUsers || []).length === 0}
                  selected={selected}
                  setSlackUsersVendors={setSlackUsersVendors}
                  slackUsers={selected ? [...availableSlackUsers || [], {
                    id: selected.user_vendor_id,
                    name: selected.username || selected.user_vendor_id,
                    real_name: selected.username || selected.user_vendor_id,
                  } as ISlackUserResponse] : availableSlackUsers || []}
                  slackUsersVendors={slackUsersVendors || []}
                  tenantUser={tenantUser}
                />
              );
            })) : (
              <JitText className={styles.loadingUsers} data-testid='loadingTenantUsers' text='dialogs.integration.slack.users.loadingText' />)}

          <div className={styles.inviteLink}>
            <JitText
              color={colors.iris}
              data-testid='inviteLink'
              onClick={handleInviteLinkClick}
              text='dialogs.integration.slack.inviteLink'
            />
          </div>

        </div>

      </Collapse>
    </div>
  );
};
