import { FC } from 'react';

import styles from './PanelLayout.module.scss';

type RowComponent = {
  component: FC;
  style?: React.CSSProperties;
};

interface Props {
  rowComponents: {
    left?: RowComponent;
    center?: RowComponent;
    right?: RowComponent;
  }[];
}

export const PanelLayout: FC<Props> = ({ rowComponents }) => (
  <div className={styles.wrapper} data-testid='panel-layout'>
    {rowComponents.map(({ left, center, right }, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={index} className={styles.row} data-testid='panel-layout-row'>
        {left && (
          <div className={styles.left} data-testid='panel-layout-left' style={left.style}>
            <left.component />
          </div>
        )}

        {center && (
          <div className={styles.center} data-testid='panel-layout-center' style={center.style}>
            <center.component />
          </div>
        )}

        {right && (
          <div className={styles.right} data-testid='panel-layout-right' style={right.style}>
            <right.component />
          </div>
        )}
      </div>
    ))}
  </div>
);
