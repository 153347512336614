import { FC } from 'react';

import styles from './GradientLock.module.scss';

import { PremiumLock } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';

export const GradientLock: FC = () => (
  <div className={styles.gradientBorder}>
    <JitIcon color='#FFFFFFB2' icon={PremiumLock} size={14} />
  </div>
);
