import { FC } from 'react';

import { AssetType } from 'components/AssetType/AssetType';
import { IFinding, ITableCell } from 'types/interfaces';

const getDisplayTextOfFindingLocation = (finding: IFinding) => {
  let location = finding.locationText;
  if (finding?.location?.includes('arn:aws:')) {
    location = `${finding?.location?.split('arn:aws:').pop()} - ${location}`;
  }
  return location;
};

export const LocationCell: FC<ITableCell<IFinding>> = ({ cell }) => {
  const { row: { original }, value } = cell;
  const text = getDisplayTextOfFindingLocation(original) || value as string;

  return (
    <AssetType assetName={text} vendor={original.vendor} />
  );
};
