import { FC } from 'react';

import { Check } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import styles
  from 'pages/FindingsPage/components/FindingDetails/components/Priority/children/Feedback/FeedbackSubmittedText/FeedbackSubmittedText.module.scss';
import colors from 'themes/colors.module.scss';

export const FeedbackSubmittedText: FC = () => (
  <div className={styles.feedbackSubmittedText}>
    <JitIcon color={colors.successGreen02} icon={Check} size={12} />

    <JitText
      color={colors.successGreen02}
      data-testid='feedbackSubmittedText'
      display='flex'
      size='s'
      text='pages.findings.findingDetails.priority.feedbackSubmitted'
    />
  </div>
);
