import { useMemo } from 'react';

import { IFilter, IFilterOption, IPlanFilterEntity, IPlanItemDetails, PlanItemState } from 'types/interfaces';

export const useGetFilteredPlanItemsDetails = (planItems: IPlanItemDetails[], filters: IFilter[]): { filteredPlanItemsDetails: IPlanItemDetails[] } => {
  const filteredPlanItemsDetails: IPlanItemDetails[] = useMemo(
    () => {
      let filtered = planItems;

      const statusFilter = filters.find((filter) => filter.entityKey === IPlanFilterEntity.STATUS);
      const stateFilter = filters.find((filter) => filter.entityKey === IPlanFilterEntity.STATE);

      const statusFilterValue = (statusFilter?.selectedValue as IFilterOption)?.value;
      const stateFilterValue = (stateFilter?.selectedValue as IFilterOption)?.value;

      if (statusFilterValue) {
        filtered = filtered.filter((planItem) => planItem?.status_properties?.status === statusFilterValue);
      }

      if (stateFilterValue) {
        filtered = filtered.filter((planItem) => (stateFilterValue === PlanItemState.ACTIVATED ? planItem.is_active : !planItem.is_active));
      }

      return filtered;
    },
    [planItems, filters],
  );

  return {
    filteredPlanItemsDetails,
  };
};
