import { IPlanStatus } from 'types/interfaces/Metrics/SecurityImpactMetrics/LayersCoverage';
import { IResourcesCoverage } from 'types/interfaces/Metrics/SecurityImpactMetrics/ResourcesCoverage';

export const getActivePlanItemsText = (planStatus: IPlanStatus): string => {
  let activeItems = 0;
  let totalItems = 0;
  Object.keys(planStatus.planItems).forEach((layer) => {
    activeItems += planStatus.planItems[layer].activeItems.length;
    totalItems += planStatus.planItems[layer].activeItems.length + planStatus.planItems[layer].inactiveItems.length;
  });
  return `<b>${activeItems}</b> / ${totalItems}`;
};

export const getResourcesCoverageText = (resourcesCoverageMetric: IResourcesCoverage): string => {
  let coveredItems = 0;
  let totalItems = 0;

  resourcesCoverageMetric.resourcesCoverage.forEach((assetTypeCoverage) => {
    coveredItems += assetTypeCoverage.covered;
    totalItems += assetTypeCoverage.covered + assetTypeCoverage.notCovered;
  });

  return `<b>${coveredItems}</b> / ${totalItems}`;
};
