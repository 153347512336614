import { useCallback, useMemo } from 'react';

import integrationGroups from './integrationGroups.json';
import integrationTemplates from './integrations.json';
import { InnerOptions, IntegrationTemplate, IntegrationTemplatesGroup, IntegrationType, IntegrationVendorType } from './interfaces';

import { getIconByVendor } from 'components/AssetType/utils/getIconByVendor';
import { Vendor } from 'types/enums';
import { IIntegration, IIntegrationThirdParty, IntegrationProvider } from 'types/interfaces/Integrations/IIntegration';

const secretIntegrationDefaultOptions: InnerOptions = {
  connect: {
    label: 'Connect',
  },
  fields: {
    name: {
      label: 'Name',
      value: 'default-value',
      placeholder: 'Enter the key name',
    },
    secret: {
      label: 'Token',
      placeholder: 'Enter the token',
    },
  },
};

const getIntegrationOptions = (integrationTemplate: IntegrationTemplate): InnerOptions | undefined => {
  if (integrationTemplate.integrationType !== IntegrationType.secret) return undefined;
  const options = integrationTemplate.options as InnerOptions;
  return {
    ...secretIntegrationDefaultOptions,
    ...options,
    fields: {
      name: {
        ...secretIntegrationDefaultOptions.fields.name,
        ...options.fields?.name,
      },
      secret: {
        ...secretIntegrationDefaultOptions.fields.secret,
        ...options.fields?.secret,
      },
      ...(options.fields?.secretLink && {
        secretLink: {
          ...secretIntegrationDefaultOptions.fields.secretLink,
          ...options.fields.secretLink,
        },
      }
      ),
    },
  };
};
const VendorMapping = {
  [Vendor.GCP]: Vendor.PARAGON_GCP,
};

const parseIntegrationToTemplate = (integration: IIntegrationThirdParty): IntegrationTemplate => ({
  key: integration.vendor,
  vendor: integration.vendor,
  label: integration.display.name,
  vendorType: integration.display.categoryKey,
  provider: integration.provider,
  icon: integration.display.icon,
  integrationType: integration.provider,
  description: integration.display.description,
  shortDescription: integration.display.description,
});

export const useIntegrationsTemplate = (integrations?: IIntegration[]) => {
  const thirdPartyIntegrations = useMemo(() => integrations?.filter((integration) => integration.provider === IntegrationProvider.THIRD_PARTY), [integrations]);

  const shouldKeepTemplate = useCallback(
    (template: IntegrationTemplate) => {
      const paragonVendor = VendorMapping[template.key as keyof typeof VendorMapping];
      if (paragonVendor) {
        return !thirdPartyIntegrations?.some((integration) => integration.vendor === paragonVendor);
      }
      return true;
    },
    [thirdPartyIntegrations],
  );

  const updateExistingTemplates = useCallback(
    (template: IntegrationTemplate) => {
      const matchingIntegration = thirdPartyIntegrations?.find(
        (integration) => integration.vendor === template.key,
      );
      return matchingIntegration
        ? parseIntegrationToTemplate(matchingIntegration as IIntegrationThirdParty)
        : template;
    },
    [thirdPartyIntegrations],
  );

  const mergeTemplatesWithIntegrations = useCallback((templates: IntegrationTemplate[]): IntegrationTemplate[] => {
    const updatedTemplates = templates
      .map(updateExistingTemplates)
      .filter(shouldKeepTemplate);

    const updatedIntegrations = (thirdPartyIntegrations || [])?.filter(
      (integration) => !updatedTemplates.some((template) => template.key === integration.vendor),
    ).map((integration) => parseIntegrationToTemplate(integration as IIntegrationThirdParty));

    return [...updatedTemplates, ...updatedIntegrations];
  }, [shouldKeepTemplate, thirdPartyIntegrations, updateExistingTemplates]);

  const templates: IntegrationTemplate[] = useMemo(() => {
    let currentTemplates: IntegrationTemplate[] = integrationTemplates.items.map((item) => ({
      ...item,
      vendorType: IntegrationVendorType[item.vendorType as keyof typeof IntegrationVendorType],
      options: getIntegrationOptions(item as IntegrationTemplate),
      icon: getIconByVendor(item.vendor, undefined, true),
      provider: IntegrationProvider.JIT,
    }));

    currentTemplates = mergeTemplatesWithIntegrations(currentTemplates);
    return currentTemplates;
  }, [mergeTemplatesWithIntegrations]);

  const getTemplateByVendorKey = (vendorKey: string): IntegrationTemplate | undefined => templates.find((template) => template.key === vendorKey);

  const groupedTemplates = useMemo(
    () => {
      const groups: IntegrationTemplatesGroup[] = [];

      integrationGroups.groups.forEach((group) => {
        const groupTemplates = templates.filter((template) => group.templates.includes(template.key));

        if (groupTemplates.length) {
          groups.push({
            key: group.key,
            title: group.title,
            templates: groupTemplates,
          });
        }
      });

      return groups;
    },
    [templates],
  );

  return {
    templates,
    groupedTemplates,
    getTemplateByVendorKey,
  };
};
