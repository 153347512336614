import { FC } from 'react';

import styles from './LogDialogContent.module.scss';

import { CircleWarning } from 'assets';
import { StyledLinearProgress } from 'components/AppLoadingBar/AppLoadingBar.styles';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { ExecutionLogDetails, LogDialogLogLine, LogDialogError } from 'pages/PipelinesPage/components/LogDialog';
import colors from 'themes/colors.module.scss';

type Props = {
  executionLogDetails?: ExecutionLogDetails;
  isLoading: boolean;
  hasError: boolean;
};

export const LogDialogContent: FC<Props> = ({
  executionLogDetails,
  isLoading,
  hasError,
}) => {
  if (isLoading) {
    return (
      <div className={styles.contentWrapper}>
        <StyledLinearProgress
          data-testid='log-dialog-loading'
          style={{ width: '20%' }} // className doesn't work here
          value={50}
        />
      </div>
    );
  }

  if (hasError || !executionLogDetails) {
    return (<LogDialogError />);
  }

  return (
    // We use text.trim() to remove empty lines at the start or end of the log
    <div className={styles.dialogContentWrapper}>
      {executionLogDetails.text.trim().split('\n').map((txt: string, index) => (
        <LogDialogLogLine
          key={`${txt.slice(-10)} ${index + 1}`}
          rowLineNumber={index + 1}
          text={txt}
        />
      ))}

      {executionLogDetails.isTruncated && executionLogDetails.downloadLink && (
        <div
          key='refer-to-download'
          className={styles.referToDownloadWrapper}
        >
          <JitText
            align='center'
            className={styles.tripleDot}
            color={colors.gray}
            data-testid='triple-dot'
            text='dialogs.executionLog.truncatedLogFakeLineNumber'
          />

          <JitIcon
            className={styles.warningIcon}
            icon={CircleWarning}
            size={25}
          />

          <JitText
            data-testid='refer-to-download'
            style={{
              marginBottom: 0, // className doesn't work here
            }}
            text='dialogs.executionLog.truncatedLogText'
          />

          <a
            className={styles.downloadLogLink}
            href={executionLogDetails.downloadLink}
          >
            <JitText
              color={colors.iris}
              data-testid='download-link'
              paragraph
              style={{ // className doesn't work here
                marginBottom: 0,
                marginLeft: 5,
              }}
              text='dialogs.executionLog.downloadLog'
            />
          </a>
        </div>
      )}
    </div>
  );
};
