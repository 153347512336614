import i18next, { PostProcessorModule } from 'i18next';

import { constants } from 'globalConstants';

let globalScmVendor = constants.GITHUB;

export const setGlobalScmVendor = (vendor: string) => {
  globalScmVendor = vendor;
};

const getTextByScmVendor = (vendor: string) => {
  switch (vendor) {
    case constants.GITLAB:
      return {
        scmRequestType: 'Merge',
        scmRequestTypeShort: 'MR',
        scmRepo: 'Project',
        scmOrg: 'Group',
      };
    default:
      return {
        scmRequestType: 'Pull',
        scmRequestTypeShort: 'PR',
        scmRepo: 'Repository',
        scmOrg: 'Organization',
      };
  }
};

const RequestTypePostProcessor: PostProcessorModule = {
  type: 'postProcessor',
  name: 'requestTypeProcessor',

  process: (value) => {
    const { scmRequestType, scmRequestTypeShort } = getTextByScmVendor(globalScmVendor);

    return value
      .replace(/{{scmRequestType}}/g, scmRequestType)
      .replace(/{{scmRequestTypeLower}}/g, scmRequestType.toLowerCase())
      .replace(/{{scmRequestTypeShort}}/g, scmRequestTypeShort)
      .replace(/{{scmRepo}}/g, getTextByScmVendor(globalScmVendor).scmRepo)
      .replace(/{{scmOrg}}/g, getTextByScmVendor(globalScmVendor).scmOrg);
  }
  ,
};

i18next.use(RequestTypePostProcessor);
