import { FC } from 'react';

import styles from './Asset.module.scss';

import { Empty } from 'assets';
import { getIconByVendor } from 'components/AssetType/utils/getIconByVendor';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { JobsListNew } from 'pages/PipelinesPage/PipelineDetailsPage/components/PipelineWorkflows/Jobs/JobsList/JobsListNew';
import { getTextFromPipelineParams } from 'pages/PipelinesPage/utils/utilsNew';
import { JitEventName } from 'types/enums/index';
import { IAssetFieldsNew } from 'types/interfaces/Pipelines/IPipelineNew';

interface AssetProps {
  asset: IAssetFieldsNew
  assetSlug: string
  jitEventId: string;
  jitEventName: JitEventName
}

export const Asset: FC<AssetProps> = ({ asset, assetSlug, jitEventId, jitEventName }) => {
  const assetText = getTextFromPipelineParams(asset.vendor, asset.owner, asset.assetName, asset.assetType, jitEventName);

  return (
    <div data-testid={`asset_${assetSlug}`}>
      <div className={styles.asset}>
        <JitIcon
          icon={getIconByVendor(asset.vendor, asset.assetType) || Empty}
          size={18}
        />

        <TooltipOnlyOnOverflow placement='right' tooltipText={assetText}>
          <JitText
            className={`${styles.notClickable}`}
            data-testid={`assetName_${assetSlug}`}
            noWrap
            overflowHiddenEllipsis
            size='s'
            text={assetText}
          />
        </TooltipOnlyOnOverflow>
      </div>

      <JobsListNew jitEventId={jitEventId} jobs={asset.jobs} owner={asset.owner} />
    </div>
  );
};

