import { useCallback, useMemo } from 'react';

import { useConfigurationsContext } from 'context/ConfigurationsContext/ConfigurationsContext';
import { Deployment } from 'types/interfaces';

export const useDeploymentConfigurationStateManager = (initialState: Deployment) => {
  const { setConfigurations, configurations } = useConfigurationsContext();

  const currentConfiguration = useMemo(() => configurations.deployment || initialState, [configurations.deployment, initialState]);

  const handleOverrideChange = useCallback((key: any, value: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    setConfigurations((prevConfigurations) => ({
      ...prevConfigurations,
      deployment: {
        ...currentConfiguration,
        [key]: value,
      },
    }));
  }, [currentConfiguration, setConfigurations]);

  return {
    configurations,
    setConfigurations,
    currentConfiguration,
    handleOverrideChange,
  };
};
