import { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { JitSlidingPanel } from 'components/JitSlidingPanel/JitSlidingPanel';
import { mockWorkflow } from 'mocks/Workflows/mockWorkflow';
import { StepDetails } from 'pages/WorkflowsPage/EditWorkflowPage/components/StepDetails/StepDetails';
import { WorkflowGraph } from 'pages/WorkflowsPage/EditWorkflowPage/components/WorkflowGraph/WorkflowGraph';
import { EditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext';
import { buildInitialWorkflow } from 'pages/WorkflowsPage/EditWorkflowPage/utils/buildInitialWorkflow';
import { IWorkflow } from 'types/interfaces/Workflows/IWorkflow';

const NEW_WORKFLOW_PATH_PARAM = 'new';
export const EditWorkflowPage = () => {
  const [workflow, setWorkflow] = useState<IWorkflow>(mockWorkflow);
  const [selectedStepId, setSelectedStepId] = useState<string | undefined>();

  const { workflowId } = useParams();

  useEffect(() => {
    if (workflowId === NEW_WORKFLOW_PATH_PARAM) {
      const newWorkflow = buildInitialWorkflow();
      setWorkflow(newWorkflow);
    } else {
      setWorkflow(mockWorkflow);
    }
  }, [workflowId]);

  const onStepClick = useCallback((stepId: string) => {
    setSelectedStepId(stepId);
  }, []);
  const handleClosePanel = () => {
    setSelectedStepId(undefined);
  };

  const contextValue = useMemo(() => ({
    workflow,
    setWorkflow,
    onStepClick,
  }), [
    workflow,
    setWorkflow,
    onStepClick,
  ]);

  return (
    <EditWorkflowContext.Provider value={contextValue}>
      <WorkflowGraph workflow={workflow} />

      <JitSlidingPanel closePanel={handleClosePanel} isOpen={!!selectedStepId} width='50%'>
        {selectedStepId ? <StepDetails closePanel={handleClosePanel} selectedStepId={selectedStepId} setSelectedStepId={setSelectedStepId} /> : undefined}
      </JitSlidingPanel>
    </EditWorkflowContext.Provider>
  );
};
