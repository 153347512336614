import colors from 'themes/colors.module.scss';
import { ActionStatus } from 'types/interfaces';

export const actionStatusToColors = (status: ActionStatus): { backgroundColor: string, textColor: string } => {
  switch (status) {
    case 'NEW':
      return {
        backgroundColor: colors.actionNewStatusBG,
        textColor: colors.actionNewStatusText,
      };
    case 'IN_PROGRESS':
      return {
        backgroundColor: colors.actionInProgressStatusBG,
        textColor: colors.actionInProgressStatusText,
      };
    default:
      return {
        backgroundColor: '',
        textColor: '',
      };
  }
};
