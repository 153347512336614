import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { PlanItemDetailsBody } from './components';

import { JitSlidingPanel } from 'components/JitSlidingPanel/JitSlidingPanel';
import { ItemSlugOptions } from 'pages/PlanPage/components/PlanItemIntroDialog';
import { IPlanItemDetails } from 'types/interfaces';

interface Props {
  planSlug: string;
  planItem: IPlanItemDetails;
  handleBack: () => void;
  handleConfigure: (planItem: IPlanItemDetails) => void;
  setIntroItem: Dispatch<SetStateAction<ItemSlugOptions | null>>;
}

export const PlanItemDetails: FC<Props> = ({ planSlug, planItem, handleBack, handleConfigure, setIntroItem }) => {
  const planItemName = planItem.name;
  const [currentPlanItemName, setCurrentPlanItemName] = useState(planItemName);

  useEffect(() => {
    if (planItem.name !== currentPlanItemName) {
      setCurrentPlanItemName(planItem.name);
    }
  }, [planItem.name, currentPlanItemName]);

  return (
    <JitSlidingPanel closePanel={handleBack} isOpen>
      <PlanItemDetailsBody
        handleConfigure={handleConfigure}
        planItem={planItem}
        planSlug={planSlug}
        setIntroItem={setIntroItem}
      />
    </JitSlidingPanel>
  );
};
