import { constants } from 'globalConstants';

export interface InsightOption {
  key: string;
  displayText?: string;
  shouldHide?: boolean;
  isMenuOption: boolean;
}

const { insights: { DEPLOYMENTS, PULL_REQUESTS, PERFORMANCE, SNAPSHOT_NOT_FOUND, SECURITY_IMPACT, SBOM } } = constants.routes;

export const getInsightOptions = (): InsightOption[] => [
  {
    key: SECURITY_IMPACT,
    displayText: 'pages.securityImpact.title',
    isMenuOption: true,
  },
  {
    key: PERFORMANCE,
    displayText: 'pages.performance.pageName',
    isMenuOption: true,
  },
  {
    key: SBOM,
    displayText: 'pages.sbom.title',
    isMenuOption: true,
  },
  {
    key: PULL_REQUESTS,
    displayText: 'pages.pullRequests.title',
    isMenuOption: true,
  },
  {
    key: DEPLOYMENTS,
    displayText: 'pages.deployments.title',
    isMenuOption: true,
  },
  {
    key: SNAPSHOT_NOT_FOUND,
    isMenuOption: false,
  }];

