import { FC } from 'react';

import { Search } from 'assets';
import { JitTextInput } from 'components/JitTextInput/JitTextInput';

interface TeamSearchProps {
  searchValue: string;
  onChange: (searchText: string) => void;
}

export const TeamsSearch: FC<TeamSearchProps> = ({ searchValue, onChange }) => (
  <JitTextInput defaultValue={searchValue} fullWidth={false} icon={Search} onChange={onChange} placeholder='Search Team' />
);
