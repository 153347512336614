import { logError } from 'services/logger/logger';

export const jsonLoad = <T = undefined>(content: string): T | undefined => {
  try {
    return JSON.parse(content);
  } catch (e: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
    logError('Error Dumping JSON', { error: e.toString() });
    return undefined;
  }
};

export const jsonDump = (obj: object): string => {
  try {
    return JSON.stringify(obj);
  } catch (e: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
    logError('Error Dumping JSON', { error: e.toString() });
    return '';
  }
};
