import { Chip, ChipProps, styled } from '@mui/material';
import { FC } from 'react';

import colors from 'themes/colors.module.scss';

interface Props {
  label: string;
  params?: ChipProps;
}

const StyledChip = styled(Chip)(() => ({
  borderRadius: 4,
  height: 26,
  fontSize: 12,
  border: `2px solid ${colors.appBgColor}`,
  backgroundColor: colors.cards,
  width: 'fit-content',
}));

export const JitChip: FC<Props> = ({ label, params }) => (
  <StyledChip data-testid='chip' label={label} {...params} />
);
