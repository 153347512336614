import Box from '@mui/material/Box';
import { FC } from 'react';

import styles from 'components/JitDialogs/IntegrationDialog/components/IntegrationOption/IntegrationOption.module.scss';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { ISvg } from 'types/interfaces';

interface Props {
  option: string,
  integrationTypeChosen?: string,
  handleClick: () => void,
  icon: FC<ISvg>,
  iconSize: number,
}

export const IntegrationOption: FC<Props> = ({ option, integrationTypeChosen, handleClick, icon, iconSize }) => (
  integrationTypeChosen === option ? (
    <div className={styles.ActiveIntegrationTypeContainer}>
      <div style={{ padding: '10px' }}><JitIcon color='transparent' icon={icon} size={iconSize} /></div>

      <div>
        <JitText text={`dialogs.integration.aws.${option}`} />
      </div>
    </div>
  ) : (
    <Box className={styles.IntegrationTypeContainer} onClick={handleClick}>
      <div style={{ padding: '10px' }}><JitIcon color='transparent' icon={icon} size={iconSize} /></div>

      <div>
        <JitText text={`dialogs.integration.aws.${option}`} />
      </div>
    </Box>
  )
);
