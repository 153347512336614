import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { InsightsCard, DaysCountBadgeCard } from '../../baseComponents';
import { PERFORMANCE_ROUTE } from '../../constants';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { IMTTRMetric } from 'types/interfaces/Metrics/PerformanceMetrics/MTTR';
import { formatHoursToDisplayText } from 'utils/functions/dateUtils';

interface Props {
  mttrMetric: IMTTRMetric;
}

export const MTTRComponent: FC<Props> = ({ mttrMetric }) => {
  const { average, rank, performanceRateOptions } = mttrMetric;
  const navigate = useNavigate();
  const formattedMTTR = average === null ? null : formatHoursToDisplayText({ hours: average });
  return (
    <InsightsCard
      content={<DaysCountBadgeCard mttrAvg={formattedMTTR} performanceRateOptions={performanceRateOptions} rank={rank} />}
      footer={(
        <JitText
          color={colors.iris}
          onClick={() => {
            navigate(PERFORMANCE_ROUTE);
          }}
          text='pages.overview.insights.viewPerformance'
        />
)}
      header={{
        title: 'pages.overview.insights.mttr.title',
      }}
      size='m'
      variant='bottom'
    />
  );
};
