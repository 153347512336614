import { pickBy } from 'lodash';
import groupBy from 'lodash/groupBy';
import { FC } from 'react';

import { OwnerAccordionCustomAws } from '..';

import { CustomAwsStandaloneAccounts } from './CustomAwsStandaloneAccounts';

import { IAsset } from 'types/interfaces';

interface Props {
  assets: IAsset[];
  checkAsset: (assetId: string, checked: boolean) => void;
  checkMultipleAssetsByOwner: (owner: string, checked: boolean) => void;
  ownerDisplayNames: { [key: string]: string }
}

export const SelectionListCustomAws: FC<Props> = ({
  assets,
  checkAsset,
  checkMultipleAssetsByOwner,
  ownerDisplayNames,
}) => {
  const assetsGroupedByOwner: { [owner: string]: IAsset[] } = groupBy(assets, 'owner');

  // Single account has to have 1 item and be its own owner, in every different case it's an organization.
  const isSingleAccount = (assetList: IAsset[]) => assetList.length === 1 && assetList[0].owner === (assetList[0].aws_account_id);
  const singleAccounts = Object.values(assetsGroupedByOwner)
    .filter((assetList: IAsset[]) => isSingleAccount(assetList))
    .map((assetList: IAsset[]) => assetList[0]);
  const organizations = pickBy(assetsGroupedByOwner, (assetList: IAsset[]) => !isSingleAccount(assetList));
  const shouldRenderSingleAccounts = singleAccounts.length > 0;

  return (
    <>
      {Object.entries(organizations).map(([owner, assetsByOwner]) => (
        <OwnerAccordionCustomAws
          key={owner}
          assets={assetsByOwner}
          checkAsset={checkAsset}
          checkMultipleAssetsByOwner={checkMultipleAssetsByOwner}
          owner={owner}
          ownerDisplayNames={ownerDisplayNames}
        />
      ))}

      {shouldRenderSingleAccounts && (
        <CustomAwsStandaloneAccounts
          assets={singleAccounts}
          checkAsset={checkAsset}
          checkMultipleAssetsByOwner={checkMultipleAssetsByOwner}
        />
      )}
    </>
  );
};
