export const COOKIE_HEADER = 'Cookie';
export const AUTHORIZATION_HEADER = 'Authorization';
export const BEARER = 'Bearer';

export const COOKIE_HEADER_INPUT_FIELD = 'auth_cookie_header_value';
export const BEARER_AUTH_INPUT_FIELD = 'auth_bearer_token_header_value';

export const DEFAULT_AUTH_INPUT_FIELD = 'auth_header_value';

export enum ZapAnalyticsEvents {
  ApiFileUploaded = 'api-file-uploaded',
  ApiFileInvalidType = 'api-file-invalid-type',
  ApiFileInvalidContent = 'api-file-invalid-content',
}

export const ANALYTICS_EVENT_TITLE = 'api';

export const PASSWORD = 'password';
export const AUTH_KEY = 'authentication_key';
export const AUTH_VALUE = 'authentication_value';
