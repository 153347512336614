import { FC } from 'react';

import styles from './IntegrationWidget.module.scss';

import { JittyCloudOk, Cloud, GithubIcon, GitlabIcon } from 'assets';
import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitText } from 'components/JitText/JitText';
import { ActionButtonVariant } from 'types/interfaces';

interface Props {
  handleIntegrateGithub: () => void;
  handleIntegrateGitlab: () => void;
}

export const IntegrationWidget: FC<Props> = ({ handleIntegrateGithub, handleIntegrateGitlab }) => (
  <div className={styles.wrapper} data-testid='integration-widget-wrapper'>
    <img alt='Jitty img' className={styles.jitty} src={JittyCloudOk} />

    <div className={styles.content}>

      <div className={styles.header} data-testid='integration-widget-header'>
        <JitText bold text='pages.plan.integrationWidget.title' />

        <JitText text='pages.plan.integrationWidget.info' />
      </div>

      <div className={styles.body} data-testid='integration-widget-body'>
        <JitActionButton
          actionButton={{
            icon: GithubIcon,
            variant: ActionButtonVariant.OUTLINED,
            label: 'GitHub',
            handleClick: handleIntegrateGithub,
            iconColor: 'white',
          }}
        />

        <JitActionButton
          actionButton={{
            icon: GitlabIcon,
            variant: ActionButtonVariant.OUTLINED,
            label: 'GitLab',
            handleClick: handleIntegrateGitlab,
          }}
        />
      </div>
    </div>

    <div className={styles.cloud}>
      <img alt='Cloud img' src={Cloud} />
    </div>
  </div>
);
