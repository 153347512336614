import { FC, ReactElement, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { SelectPageDropdown } from './components/SelectPageDropdown/SelectPageDropdown';
import styles from './InsightsPage.module.scss';
import { getInsightOptions } from './utils/getInsightOptions';

interface Props {
  pageKey: string
  page: ReactElement
}

export const InsightsPage: FC<Props> = ({ pageKey, page }) => {
  const navigate = useNavigate();

  const insightsOptions = getInsightOptions();

  const selectedInsight = useMemo(
    () => insightsOptions.find((insight) => insight.key === pageKey),
    [insightsOptions, pageKey],
  );

  useEffect(() => {
    if (!selectedInsight) {
      navigate('/');
    }
  }, [selectedInsight, navigate, insightsOptions, pageKey]);

  if (!selectedInsight) return null;

  return (
    <div className={styles.wrapper}>
      {!!insightsOptions.length && (
        <SelectPageDropdown insightsOptions={insightsOptions} selectedInsightPage={pageKey} />
      )}

      {page}
    </div>
  );
};
