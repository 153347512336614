import { useFindingsContext } from 'context/FindingsContext/FindingsContext';
import { IFindingFilter, IFindingFilterType } from 'types/interfaces';

export const useClearFilters = () => {
  const { setFilters, filters } = useFindingsContext();
  return () => {
    const updatedFilters = [...filters].map((filter: IFindingFilter) => {
      const updatedFilter: IFindingFilter = {
        ...filter,
        selectedValue: filter.type === IFindingFilterType.MULTI_SELECT ? [] : '',
        isVisible: false,
      };
      return updatedFilter;
    });

    setFilters(updatedFilters);
  };
};
