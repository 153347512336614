import DialogContent from '@mui/material/DialogContent';
import { FC } from 'react';

import styles from './IntegrationTypeChooser.module.scss';

import { OrganizationIcon, SingleAccount } from 'assets';
import { IntegrationOption } from 'components/JitDialogs/IntegrationDialog/components/IntegrationOption/IntegrationOption';
import { AwsIntegrationType } from 'types/enums';

interface Props {
  setIntegrationTypeChosen: (type?: AwsIntegrationType) => void;
  integrationTypeChosen?: string;
}

export const IntegrationTypeChooser: FC<Props> = ({ integrationTypeChosen, setIntegrationTypeChosen }) => {
  const handleIntegrationTypeAccount = () => {
    setIntegrationTypeChosen(AwsIntegrationType.Account);
  };

  const handleIntegrationTypeOrganization = () => {
    setIntegrationTypeChosen(AwsIntegrationType.Organization);
  };

  return (
    <DialogContent sx={{
      p: '0.4rem 0',
      overflow: 'auto',
    }}
    >
      <div className={styles.wrapper}>
        <div className={styles.SideBySide}>
          <IntegrationOption
            handleClick={handleIntegrationTypeAccount}
            icon={SingleAccount}
            iconSize={24}
            integrationTypeChosen={integrationTypeChosen}
            option='account'
          />

          <IntegrationOption
            handleClick={handleIntegrationTypeOrganization}
            icon={OrganizationIcon}
            iconSize={28}
            integrationTypeChosen={integrationTypeChosen}
            option='org'
          />
        </div>
      </div>

    </DialogContent>
  );
};
