import { FC, ReactElement } from 'react';

import { JitTooltip } from '../JitTooltip';

interface Props {
  children: ReactElement;
}

export const JitReadOnlyTooltip: FC<Props> = ({ children }) => (
  <JitTooltip data-testid='readOnlyTooltip' followCursor={false} placement='top' title='readOnly.tooltip'>
    {children}
  </JitTooltip>
);
