import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';

import { DetectionRateGraph } from './DetectionRate/DetectionRateGraph';
import { ExposureWindowGraph } from './ExposureWindow/ExposureWindowGraph';
import { DailyFindingsActivities } from './FindingsActivityGraphs/DailyFindingsActivity/DailyFindingsActivities';
import { FindingsOverTimeGraph } from './FindingsActivityGraphs/FindingsOverTime/FindingsOverTimeGraph';
import styles from './Graphs.module.scss';
import { PlanItemFindings } from './PlanItemFindings/PlanItemFindings';
import { ResourceTypeFindings } from './ResourceTypeFindings/ResourceTypeFindings';

import { MTTRGraph } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/MTTR/MTTRGraph';
import { getXAxisTicks } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/utils';
import { TimeAgo } from 'types/enums';
import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { IDailyFindingsActivityMetric } from 'types/interfaces/Metrics/PerformanceMetrics/DailyFindingsActivities';
import { IDetectionRateMetric } from 'types/interfaces/Metrics/PerformanceMetrics/DetectionRate';
import { IExposureWindowMetric } from 'types/interfaces/Metrics/PerformanceMetrics/ExposureWindow';
import { IFindingsOverTimeMetric } from 'types/interfaces/Metrics/PerformanceMetrics/FindingsOvertime';
import { IMTTRMetric } from 'types/interfaces/Metrics/PerformanceMetrics/MTTR';
import { IPlanItemFindingsMetric } from 'types/interfaces/Metrics/PerformanceMetrics/PlanItemFindings';
import { IResourceTypeFindingsMetric } from 'types/interfaces/Metrics/PerformanceMetrics/ResourceTypeFindings';

interface Props {
  detectionRateMetric: MetricWithLoadingIndication<IDetectionRateMetric>;
  mttrMetric: MetricWithLoadingIndication<IMTTRMetric>;
  findingsOverTimeMetric: MetricWithLoadingIndication<IFindingsOverTimeMetric>;
  exposureWindowMetric: MetricWithLoadingIndication<IExposureWindowMetric>;
  dailyFindingsActivitiesMetric: MetricWithLoadingIndication<IDailyFindingsActivityMetric>;
  resourceTypeFindingsMetric: MetricWithLoadingIndication<IResourceTypeFindingsMetric>;
  planItemFindingsMetric: MetricWithLoadingIndication<IPlanItemFindingsMetric>;
  startDate: Date;
  endDate: Date;
  period: TimeAgo;
}

export const Graphs: FC<Props> = ({
  detectionRateMetric, mttrMetric, findingsOverTimeMetric, exposureWindowMetric, startDate, endDate, period, dailyFindingsActivitiesMetric,
  resourceTypeFindingsMetric, planItemFindingsMetric,
}) => {
  const { uiShowFindingsOvertimeInPerformancePage } = useFlags();
  const xAxisTicks = getXAxisTicks(startDate, endDate);

  const graphsList = [
    <ResourceTypeFindings key='ResourceTypeFindings' resourceTypeFindingsMetric={resourceTypeFindingsMetric} />,
    <PlanItemFindings key='PlanItemFindings' planItemFindingsMetric={planItemFindingsMetric} />,
    <DetectionRateGraph key='DetectionRateGraph' detectionRateMetric={detectionRateMetric} period={period} xAxisTicks={xAxisTicks} />,
    <MTTRGraph key='MTTRGraph' mttrMetric={mttrMetric} period={period} xAxisTicks={xAxisTicks} />,
    <DailyFindingsActivities key='DailyFindingsActivities' dailyFindingsActivitiesMetric={dailyFindingsActivitiesMetric} period={period} xAxisTicks={xAxisTicks} />,
    <ExposureWindowGraph key='ExposureWindowGraph' exposureWindowMetric={exposureWindowMetric} period={period} xAxisTicks={xAxisTicks} />,
  ];

  if (uiShowFindingsOvertimeInPerformancePage) {
    graphsList.push(
      <FindingsOverTimeGraph key='FindingsOverTimeGraph' findingsOverTimeMetric={findingsOverTimeMetric} period={period} xAxisTicks={xAxisTicks} />,
    );
  }

  return (
    <div className={styles.wrapper}>
      {graphsList.map((graph, index) => {
        if (graph) {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`Graphs-${index}`} className={styles.graphContainer}>
              {graph}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};
