import { FC } from 'react';

import styles from './SecurityItemsCell.module.scss';

import { JitText } from 'components/JitText/JitText';
import { ITableCell } from 'types/interfaces';

export const SecurityItemsCell: FC<ITableCell> = ({ cell: { value } }) => (
  <div className={styles.wrapper}>
    <JitText
      bold
      text={value.name}
    />
  </div>
);
