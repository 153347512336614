import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Results.module.scss';

import { JitStatusChip } from 'components/JitStatusChip/JitStatusChip';
import { JitText } from 'components/JitText/JitText';
import { constants } from 'globalConstants';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { ITableCell } from 'types/interfaces';

export const ResultsCell: FC<ITableCell> = ({ cell: { value } }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <JitStatusChip
        success={value.passed}
        text={value.passed
          ? 'pages.teams.resourcePlanItemsStatuses.planItemsTable.cells.results.passed'
          : 'pages.teams.resourcePlanItemsStatuses.planItemsTable.cells.results.failed'}
        variant='filled'
      />

      {!value.passed && value.findingsCount > 0 && (
      <JitText
        addUnderlineOnHover
        bold
        color={colors.iris}
        onClick={() => {
          navigate(`/${constants.routes.FINDINGS}?asset_name=${value.assetName}&plan_item=${value.planItemSlug}&resolution=OPEN&ignored=false`);
        }}
        text={`${i18n.t('pages.teams.resourcePlanItemsStatuses.planItemsTable.cells.results.securityFindings', { findingsCount: value.findingsCount })}`}
      />
      )}
    </div>
  );
};
