import { paragon } from '@useparagon/connect';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { IGitlabIntegrationWizardStep, IGitlabIntegrationWizardStepStatus } from 'components/GitlabIntegrationWizard/GitlabIntegrationWizard';
import { IntegrateGitlab } from 'components/GitlabIntegrationWizard/StepComponents';
import { useIntegrationsContext } from 'context/IntegrationsContext';
import { constants } from 'globalConstants';
import { IntegrationStatus } from 'types/interfaces/Integrations/IIntegration';

interface Props {
  incrementStepIndex: () => void;
}

const onIntegrateClick = () => paragon.installIntegration(constants.INTEGRATIONS.GITLAB, {});

export const useIntegrateGitlabStep = ({
  incrementStepIndex,
}: Props): IGitlabIntegrationWizardStep => {
  const [isStepCompleted, setIsStepCompleted] = useState<boolean>(false);
  const [isGitlabIntegrated, setIsGitlabIntegrated] = useState<boolean>(false);

  const { integrations } = useIntegrationsContext();

  useEffect(() => {
    if (integrations) {
      const gitlabIntegration = integrations.find((integration) => integration.vendor === constants.INTEGRATIONS.GITLAB);

      if (gitlabIntegration?.status === IntegrationStatus.SUCCESS) {
        setIsGitlabIntegrated(true);
        setIsStepCompleted(true);
      }
    }
  }, [integrations]);

  const status = useMemo(() => {
    if (isStepCompleted) return IGitlabIntegrationWizardStepStatus.COMPLETE;

    return IGitlabIntegrationWizardStepStatus.ACTIVE;
  }, [isStepCompleted]);

  const onContinueButtonClick = useCallback(() => {
    setIsStepCompleted(true);
    incrementStepIndex();
  }, [incrementStepIndex]);

  const integrateGitlabStep = useMemo(() => ({
    title: 'GitlabIntegrationWizard.integrateGitlab.tabText',
    component: () => (
      <IntegrateGitlab
        isGitlabIntegrated={isGitlabIntegrated}
        onContinueButtonClick={onContinueButtonClick}
        onIntegrateClick={onIntegrateClick}
      />
    ),
    status,
  }), [isGitlabIntegrated, onContinueButtonClick, status]);

  return integrateGitlabStep;
};
