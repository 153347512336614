import Slider, { SliderProps } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

import colors from 'themes/colors.module.scss';

interface Props extends SliderProps {
  height?: 'small' | 'medium' | undefined;
  width?: 'small' | 'medium' | '100%' | 'fit-content' | undefined;
}

export const JitSliderStyles = styled(Slider)<Props>(({ height, width }) => {
  const heightMap = {
    small: {
      height: 2,
      thumbSize: 12,
      muiSliderRailHeight: 4,
      muiSliderTrackHeight: 4,
    },
    medium: {
      height: 4,
      thumbSize: 16,
      muiSliderRailHeight: 6,
      muiSliderTrackHeight: 6,
    },
  };

  const widthMap = {
    small: 200,
    medium: 300,
    'fit-content': 'fit-content',
    '100%': '100%',
  };

  const selectedColor = colors.blue;
  const selectedHeight = heightMap[height || 'medium'];

  return {
    padding: 0,
    width: widthMap[width || '100%'],
    color: selectedColor,
    height: selectedHeight.height,
    '& .MuiSlider-thumb': {
      height: selectedHeight.thumbSize,
      width: selectedHeight.thumbSize,
      backgroundColor: selectedColor,
      '&:hover': {
        backgroundColor: selectedColor,
      },
      '&:hover, &:active': {
        boxShadow: 'none',
      },
      '&.Mui-focusVisible': {
        boxShadow: `0 0 0 8px ${selectedColor}33`,
      },
    },
    '& .MuiSlider-thumb.Mui-active': {
      boxShadow: 'none',
    },
    '& .MuiSlider-track': {
      height: selectedHeight.muiSliderRailHeight,
      backgroundColor: selectedColor,
    },
    '& .MuiSlider-rail': {
      height: selectedHeight.muiSliderRailHeight,
      backgroundColor: colors.lightGray,
    },
  };
});
