import { IPlanItemTemplate, IPlanItemTemplateServer } from 'types/interfaces';
import { yamlLoad } from 'utils/functions/yaml';

export const getParsedPlanItemTemplates = (items?: IPlanItemTemplateServer[]) => items?.map((item) => {
  const content = yamlLoad(item.content) as IPlanItemTemplate;
  return ({
    ...item,
    ...content,
    layer: content?.layer || content?.tags?.layer || '',
  });
});
