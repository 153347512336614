import { FC } from 'react';

import { DetailGrid, Content } from '../DetailGrid/DetailGrid';

import styles from './FindingContext.module.scss';

import { AssetType } from 'components/AssetType/AssetType';
import { JitCollapsibleText } from 'components/JitCollapsibleText/JitCollapsibleText';
import { JitSidePanelItem } from 'components/JitSidePanel/JitSidePanelItem/JitSidePanelItem';
import { i18n } from 'locale/i18n';
import { IFinding, IDictionary } from 'types/interfaces';
import { getTagsDisplayText, getCweIdText, getSolutionDisplayText, getDescriptionDisplayText } from 'utils/functions/findingsUtils';
import { useGetFormatControlName } from 'utils/hooks/useGetFormatControlName';

interface Props {
  finding: IFinding;
}

export const FindingContext: FC<Props> = ({ finding }) => {
  const { getFormattedControlName } = useGetFormatControlName();
  const hasFindingDetails = finding.cloudAttributes || finding.codeAttributes || finding.appAttributes;
  if (!hasFindingDetails) {
    return null;
  }

  const isCloudFinding = finding.cloudAttributes;
  const isCodeFinding = finding.codeAttributes;
  const isAppFinding = finding.appAttributes;

  let content: Content[] = [];
  let fullDisplayContent: Content[] = [];
  let gridTemplateColumnsStyle: string = '';
  let gridTemplateColumnsStyleFullWidth: string = '';

  if (isCloudFinding) {
    const cloudStrings: IDictionary<string> = i18n.t('pages.findings.findingDetails.findingContext.cloud', { returnObjects: true });
    const { accountId, accountName, region, serviceName } = cloudStrings;
    content = [
      {
        title: accountId,
        body: finding.cloudAttributes?.accountId,
      },
      {
        title: accountName,
        body: finding.cloudAttributes?.accountName,
      },
      {
        title: region,
        body: finding.cloudAttributes?.region,
      },
      {
        title: serviceName,
        body: finding.cloudAttributes?.cloudServiceName,
      },
    ];
    gridTemplateColumnsStyle = '25% 25% 25% 25%';
  } else if (isCodeFinding) {
    const codeStrings: IDictionary<string> = i18n.t('pages.findings.findingDetails.findingContext.code', { returnObjects: true });
    const { organization, repository, branch } = codeStrings;
    content = [
      {
        title: organization,
        body: finding.organization,
      },
      {
        title: repository,
        body: finding.repository,
      },
      {
        title: branch,
        body: finding.codeAttributes?.branch,
      },
    ];
    gridTemplateColumnsStyle = '25% 25% 25% 25%';
  } else if (isAppFinding) {
    gridTemplateColumnsStyle = '25% 25% 50%';
    gridTemplateColumnsStyleFullWidth = '95%';
    const appStrings: IDictionary<string> = i18n.t('pages.findings.findingDetails.findingContext.app', { returnObjects: true });
    const { appName, targetUrl, requestHeader, responseHeader, param, path, method, solution, cweid, description, tags } = appStrings;

    // Transform for display
    const tagsDisplay = getTagsDisplayText(finding.appAttributes?.tags);
    const cweidDisplay = getCweIdText(finding.appAttributes?.cweid);
    const solutionDisplay = getSolutionDisplayText(finding.appAttributes?.solution);
    const descriptionDisplay = getDescriptionDisplayText(finding.issueText);
    // Create collapsible sections
    const descriptionWithCollapse = <JitCollapsibleText key={`${finding.id}-description-collapse`} text={descriptionDisplay} />;
    const solutionWithCollapse = <JitCollapsibleText key={`${finding.id}-solution-collapse`} text={solutionDisplay} />;
    const requestHeaderWithCollapse = (
      <JitCollapsibleText
        key={`${finding.id}-request-header-collapse`}
        text={finding.appAttributes?.requestHeader || ''}
      />
    );
    const responseHeaderWithCollapse = (
      <JitCollapsibleText
        key={`${finding.id}-response-header-collapse`}
        text={finding.appAttributes?.responseHeader || ''}
      />
    );
    content = [
      {
        title: appName,
        body: finding.assetName,
      },
      {
        title: param,
        body: finding.appAttributes?.param,
      },
      {
        title: targetUrl,
        body: finding.appAttributes?.targetUrl,
      },
      {
        title: path,
        body: finding.appAttributes?.path,
      },
      {
        title: method,
        body: finding.appAttributes?.method,
      },
      {
        title: cweid,
        body: cweidDisplay,
      },
      {
        title: tags,
        body: tagsDisplay,
      },
    ];

    fullDisplayContent = [
      {
        title: requestHeader,
        body: requestHeaderWithCollapse,
      },
      {
        title: responseHeader,
        body: responseHeaderWithCollapse,
      },
      {
        title: solution,
        body: solutionWithCollapse,
      },
      {
        title: description,
        body: descriptionWithCollapse,
      },
    ];
  }
  const sharedContent = [
    {
      title: 'pages.findings.findingDetails.planContext.controlName',
      body: getFormattedControlName(finding.controlName || ''),
    },
    {
      title: 'pages.findings.findingDetails.planContext.vulnerabilityType',
      body: finding.vulnerabilityType || '',
    },
    {
      title: 'pages.findings.findingDetails.planContext.assetType',
      body: <AssetType key={finding.assetType} assetName={finding.assetType} vendor={finding.vendor} />,
    }];
  const sharedGridTemplateColumnsStyle = '25% 25% 25%';

  return (
    <div className={styles.wrapper}>
      <JitSidePanelItem
        title='pages.findings.findingDetails.findingContext.title'
        variant='light'
        wrapperStyleOverrides={{ borderRadius: '0 0 8px 8px' }}
      >
        <div className={styles.gridsWrapper}>
          <DetailGrid content={sharedContent} gridTemplateColumnsStyle={sharedGridTemplateColumnsStyle} />

          <DetailGrid content={content} gridTemplateColumnsStyle={gridTemplateColumnsStyle} />
        </div>

        {isAppFinding
          && (
            <DetailGrid
              content={fullDisplayContent}
              gridTemplateColumnsStyle={gridTemplateColumnsStyleFullWidth}
            />
          )}
      </JitSidePanelItem>
    </div>
  );
};
