/* eslint-disable react/no-multi-comp */
import { useMemo } from 'react';

import { SortableHeader } from './Headers/SortableHeader';

import { TextCell } from 'components/JitTable/templates';
import { MembersCell } from 'components/JitTable/templates/MembersCell/MembersCell';
import { JitText } from 'components/JitText/JitText';
import { useTeamsContext } from 'context/TeamsContext/TeamsContext';
import { i18n } from 'locale/i18n';
import { NameCell } from 'pages/TeamsPage/SubPages/TeamsPage/components/TeamsTable/Cells/NameCell/NameCell';
import { GradeCell } from 'pages/TeamsPortal/components/GradeCell/GradeCell';
import colors from 'themes/colors.module.scss';
import { ITableCell } from 'types/interfaces';
import { IGetTeamResponseItem } from 'types/interfaces/Teams/ITeam';
import { TeamSortBy } from 'types/interfaces/Teams/TeamSorting';
import { TableColumn } from 'types/types';

interface TableColumnsNames {
  name: string;
  members: string;
  resources: string;
  score: string;
  isActivated: {
    header: string;
    true: string;
    false: string;
  };
}

export const useGetColumns = (shouldDisplayColumns: boolean): TableColumn<object>[] => {
  const { t } = i18n;
  const { sortedBy, setSortedBy, sortOrder, setSortOrder } = useTeamsContext();
  const columnTexts: TableColumnsNames = useMemo(() => t('pages.teams.teamsTable.columns', { returnObjects: true }), [t]);

  // @ts-ignore The whole tables related logic uses object as type, this should be fixed
  const columns: TableColumn<object>[] = useMemo(
    () => (shouldDisplayColumns ? [
      {
        id: columnTexts.name,
        Header: () => (
          <SortableHeader
            columnName={columnTexts.name}
            setSortedBy={setSortedBy}
            setSortOrder={setSortOrder}
            sortedBy={sortedBy}
            sortOrder={sortOrder}
            sortParameter={TeamSortBy.NAME}
          />
        ),
        accessor: (team: IGetTeamResponseItem) => ({
          name: team.name,
          description: team.description,
        }),
        Cell: NameCell,
        width: '30%',
      },
      {
        id: columnTexts.score,
        Header: () => (
          <SortableHeader
            columnName={columnTexts.score}
            setSortedBy={setSortedBy}
            setSortOrder={setSortOrder}
            sortedBy={sortedBy}
            sortOrder={sortOrder}
            sortParameter={TeamSortBy.SCORE}
          />
        ),
        accessor: (team: IGetTeamResponseItem) => ({
          score: team.score,
          isActivated: true, // TODO: This setting will use team.isActivated once we add the "Activated" column in the next PR.
        }),
        width: '10%',
        Cell: GradeCell,
      },
      {
        Header: columnTexts.members,
        accessor: (team: IGetTeamResponseItem) => ({
          members: team.members,
          count: team.membersCount,
        }),
        Cell: MembersCell,
        width: '30%',
      },
      {
        Header: columnTexts.resources,
        accessor: (team: { assetsCount: number }) => team.assetsCount,
        Cell: TextCell,
        width: '15%',
      },
      {
        Header: columnTexts.isActivated.header,
        accessor: 'isActivated',
        Cell: ({ cell: { value } }: ITableCell<{ isActivated: boolean }>) => (
          <JitText
            color={value ? colors.successGreen : colors.lightGray}
            text={value ? columnTexts.isActivated.true : columnTexts.isActivated.false}
          />
        ),
        width: '15%',
      },
    ] : []),
    [columnTexts, setSortOrder, setSortedBy, shouldDisplayColumns, sortOrder, sortedBy],
  );

  return columns;
};
