import { Vendor } from 'types/enums';

const VENDORS_URLS = new Map<string, string>([
  [Vendor.Github, 'https://github.com'],
]);

export const buildResourceURL = (assetName: string, owner: string, vendor: string): string | undefined => {
  const vendorURL = VENDORS_URLS.get(vendor);
  if (!vendorURL) {
    return undefined;
  }
  return `${vendorURL}/${owner}/${assetName}`;
};
