import { FC, useState } from 'react';

import styles from './AddNewRule.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  onClick(): void;
  policyName?: string;
}

export const AddNewRule: FC<Props> = ({ onClick, policyName = '' }) => {
  const [addButtonHovered, setAddButtonHovered] = useState(false);

  const addButtonColor = addButtonHovered ? colors.iris : colors.lightGray;

  return (
    <div
      className={styles.wrapper}
      onClick={onClick}
      onMouseEnter={() => setAddButtonHovered(true)}
      onMouseLeave={() => setAddButtonHovered(false)}
      role='button'
      tabIndex={0}
    >
      <JitText color={addButtonColor} text='+' />

      <JitText color={addButtonColor} params={{ policyName }} text='pages.policies.rule.buttons.add.label' />
    </div>
  );
};
