import { startCase } from 'lodash';
import { FC, useCallback } from 'react';

import styles from './ActionCardDetailsSection.module.scss';

import { CalenderNotebook } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { getSeverityIcon } from 'components/JitTable/templates/SeverityCell/utils/getSeverityIcon';
import { JitText } from 'components/JitText/JitText';
import { getLayerIcon } from 'context/PlanInstanceContext/planUtils';
import colors from 'themes/colors.module.scss';
import { Severity } from 'types/enums';
import { IActionFinding, ISvg } from 'types/interfaces';
import { formatDate } from 'utils';

interface ActionCardDetailsSectionProps {
  finding: IActionFinding
  updatedAt: string
  vulnerabilityType: string
}

export const ActionCardDetailsSection: FC<ActionCardDetailsSectionProps> = ({ finding, updatedAt, vulnerabilityType }) => {
  const severity = finding?.issue_severity || Severity.High;
  const severityIcon = getSeverityIcon(severity, true);
  const lastDetected = formatDate(updatedAt);
  const startCaseVulnerabilityType = startCase(vulnerabilityType);
  const planLayerIcon = getLayerIcon(finding?.plan_layer);

  interface RowProps { keyText: string, valueText: string, icon: FC<ISvg> | undefined, iconTestId: string, valueTestId: string }

  const Row = useCallback(({ keyText, valueText, icon, iconTestId, valueTestId }: RowProps) => (
    <div className={styles.detailsSectionRow}>
      <JitIcon data-testid={iconTestId} icon={icon} size={12} />

      <div className={styles.detailsSectionRowTextWrapper}>
        <JitText color={colors.lightGray} size='m' text={keyText} />

        <JitText bold color={colors.lightGray} data-testid={valueTestId} size='m' text={valueText} />
      </div>

    </div>
  ), []);

  return (
    <div className={styles.secondSection} data-testid='ActionCardDetailsSection'>
      <Row
        icon={severityIcon}
        iconTestId='actionCardSeverityIcon'
        keyText='pages.actions.actionCard.severity'
        valueTestId='actionCardSeverityText'
        valueText={`severity.${severity.toLowerCase()}`}
      />

      <Row
        icon={CalenderNotebook}
        iconTestId='CalenderNotebookIcon'
        keyText='pages.actions.actionCard.lastDetected'
        valueTestId='actionCardLastDetected'
        valueText={lastDetected}
      />

      <Row
        icon={planLayerIcon}
        iconTestId='actionCardTypeIcon'
        keyText='pages.actions.actionCard.vulnerabilityType'
        valueTestId='actionCardVulnerabilityType'
        valueText={startCaseVulnerabilityType}
      />
    </div>
  );
};
