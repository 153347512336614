const validateJson = (json: unknown): boolean => {
  try {
    JSON.stringify(json);
    return true;
  } catch (e) {
    return false;
  }
};

export const downloadFileFromJson = <T>(data: T, filename: string): void => {
  if (!validateJson(data)) {
    return;
  }

  const beautifiedJson = JSON.stringify(data, null, 2);
  const blob = new Blob([beautifiedJson], { type: 'application/json' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};
