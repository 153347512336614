import MenuItem from '@mui/material/MenuItem';
import { FC } from 'react';

import { SetSavedFilterAsDefaultIcon } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { ISavedFilter } from 'types/interfaces/Findings/ISavedFilter';

interface Props {
  savedFilter: ISavedFilter;
  onClose: () => void;
  setSavedFilterAsDefault: (savedFilter: ISavedFilter) => void;
}

export const SetFilterAsDefault: FC<Props> = ({
  savedFilter,
  onClose,
  setSavedFilterAsDefault,
}) => {
  const handleClick = () => {
    setSavedFilterAsDefault(savedFilter);
    onClose();
  };

  return (
    <div>
      <MenuItem disabled={savedFilter.isDefault} onClick={handleClick}>
        <div style={{ display: 'flex' }}>
          <JitIcon icon={SetSavedFilterAsDefaultIcon} />

          <JitText style={{ marginLeft: 7 }} text='dialogs.planItemsCatalog.filter.savedFilters.setAsDefault' />
        </div>
      </MenuItem>
    </div>
  );
};
