import { FC, useMemo } from 'react';

import { PlanItemDetailsResultsStatusBreakdown } from '../PlanItemDetailsResultsStatusBreakdown/PlanItemDetailsResultsStatusBreakdown';
import { useGetRerunButton } from '../RerunButton/useGetRerunButton';

import styles from './PlanItemDetailsResults.module.scss';

import { CalenderNotebook, TriangleWarningOutline } from 'assets';
import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitSidePanelItem } from 'components/JitSidePanel/JitSidePanelItem/JitSidePanelItem';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { getFormattedTimeSince } from 'pages/PipelinesPage/utils';
import { PlanItemStatusBadge } from 'pages/PlanPage/components/PlanCategoryCardItem/components/PlanItemStatusBadge/PlanItemStatusBadge';
import colors from 'themes/colors.module.scss';
import { IStatusProperties, PlanItemAggregatedStatus } from 'types/interfaces';
import { formatDate } from 'utils';

interface Props {
  itemSlug: string;
  resetStatus: () => void;
  statusProperties?: IStatusProperties;
}

export const PlanItemDetailsResults: FC<Props> = ({ itemSlug, resetStatus, statusProperties }) => {
  const rerunButton = useGetRerunButton({
    itemSlug,
    onTriggerSend: resetStatus,
  });

  const status = statusProperties?.status;
  const timestamp = statusProperties?.modified_at;
  const hasError = !!statusProperties?.error_count?.count;

  const enableRerun = useMemo(
    () => hasError && status !== PlanItemAggregatedStatus.PENDING,
    [hasError, status],
  );

  const errorMessage = (
    <div className={styles.errorIndicationContainer}>
      <JitIcon
        centered
        color={colors.failRed}
        data-testid='error-icon'
        icon={TriangleWarningOutline}
        size={14}
      />

      <JitText bold color={colors.failRed} data-testid='status-error-text-info' size='s' text='pages.plan.planItem.hasErrors' />
    </div>
  );

  const rerunButtonComponent = <JitActionButton actionButton={rerunButton} />;

  return (
    <JitSidePanelItem actionComponent={enableRerun ? rerunButtonComponent : undefined} title='pages.plan.itemDetails.results.title'>

      <div className={styles.content}>
        <div className={styles.text}>
          <div className={styles.statusWrapper}>
            <PlanItemStatusBadge size='m' status={status || PlanItemAggregatedStatus.PENDING} />

            {statusProperties?.has_error && errorMessage}
          </div>

          {timestamp && (
            <div className={styles.timestampWrapper}>
              <JitTooltip data-testid='timestamp-tooltip' followCursor={false} placement='top' title={formatDate(timestamp)}>
                <div className={styles.timestampData}>
                  <JitIcon icon={CalenderNotebook} size={16} />

                  <JitText
                    color={colors.lightGray}
                    size='m'
                    text='pages.plan.itemDetails.results.timestamp'
                  />

                  <JitText
                    color={colors.lightGray}
                    size='m'
                    text={getFormattedTimeSince(timestamp)}
                  />
                </div>
              </JitTooltip>
            </div>
          )}
        </div>

        {statusProperties && <PlanItemDetailsResultsStatusBreakdown statusProperties={statusProperties} />}
      </div>

    </JitSidePanelItem>
  );
};
