import colors from 'themes/colors.module.scss';
import { getColorByScore } from 'utils/functions/grades';

export const getTotalScore = (scores?: (number | null)[]) => {
  if (!scores?.length) return 0;

  let total = 0;
  let nullCount = 0;
  scores.forEach((score) => {
    total += score || 0;
    if (score === null) {
      nullCount += 1;
    }
  });

  return Math.round(total / (scores.length - nullCount));
};

const stopColors = [
  { offset: 10,
    color: colors.gradeA },
  { offset: 20,
    color: colors.gradeB },
  { offset: 30,
    color: colors.gradeC },
  { offset: 40,
    color: colors.gradeD },
];

export const calculateGradientStops = (scores: number[]) => {
  const maxScore = Math.max(...scores);
  const minScore = Math.min(...scores);

  const gradientValues: { color: string; offset: string }[] = [];
  const currentGap = maxScore - minScore;
  if (currentGap === 0) {
    return [{ offset: '0%',
      color: getColorByScore(maxScore) }];
  }

  stopColors.forEach((stopColor) => {
    const adjustedPercentage = stopColor.offset / (100 / currentGap);
    const newColor = {
      color: stopColor.color,
      offset: `${adjustedPercentage}%`,
    };
    gradientValues.push(newColor);
  });

  return gradientValues;
};

export const sliceGradientArray = (scores: number[], gradientValues: { color: string; offset: string }[]) => {
  const maxScore = Math.max(...scores);
  const minScore = Math.min(...scores);
  const maxColor = getColorByScore(maxScore);
  const minColor = getColorByScore(minScore);

  const maxColorIndex = gradientValues.findIndex((color) => color.color === maxColor);
  const minColorIndex = gradientValues.findIndex((color) => color.color === minColor);

  return gradientValues.slice(maxColorIndex, minColorIndex + 1);
};
