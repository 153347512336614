import { Dispatch, FC, SetStateAction, useCallback } from 'react';

import styles from './ConfigurationsZapInputFields.module.scss';

import { FieldsTextInterface } from 'components/Configurations/Zap/components/ConfigurationsZapField';
import { LabelAndTooltip } from 'components/Configurations/Zap/components/LabelAndTooltip';
import { JitDropdownNew } from 'components/JitDropdownNew/JitDropdownNew';
import { MenuItemType } from 'components/JitMenu/JitMenu';
import colors from 'themes/colors.module.scss';
import { MenuItemKeyType } from 'types/interfaces';

interface Props {
  fieldsText: FieldsTextInterface;
  handleChange: () => void;
  menuItems: Partial<MenuItemType>[];
  selectedMenuItem: MenuItemKeyType;
  setSelectedMenuItem: Dispatch<SetStateAction<MenuItemKeyType>>;
}

export const ConfigurationsDropdownField: FC<Props> = ({
  fieldsText, handleChange, menuItems, selectedMenuItem, setSelectedMenuItem,
}) => {
  const onMenuItemChange = useCallback((menuItem: MenuItemKeyType) => {
    if (selectedMenuItem && menuItem === selectedMenuItem) return;

    setSelectedMenuItem(menuItem);
    handleChange();
  }, [selectedMenuItem, setSelectedMenuItem, handleChange]);

  const itemContent = menuItems.find((item) => item.itemKey === selectedMenuItem)?.itemContent;

  return (
    <div className={styles.inputFieldContainer} data-testid='configurations-dropdown-field'>
      <LabelAndTooltip label={fieldsText.label} tooltipText={fieldsText.info}>
        <JitDropdownNew
          bold
          displayText={fieldsText.header}
          enabledColor={colors.iris}
          menuItems={menuItems}
          menuItemsWrapperStyle={{
            backgroundColor: colors.grey11,
          }}
          noBackground
          noBorder
          noGap
          onMenuItemClick={({ itemKey }) => onMenuItemChange(itemKey)}
          width='justify-content'
        />
      </LabelAndTooltip>

      {itemContent}
    </div>

  );
};
