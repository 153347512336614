import { FC } from 'react';

import { StatusBox } from 'pages/FindingsPage/components/FindingStatusCell/StatusBox';
import { FindingStatus } from 'types/enums';
import { IFinding, ITableCell } from 'types/interfaces';

interface Props extends ITableCell<IFinding> {
  updateFindingStatus?: (findingToUpdate: IFinding, selectedStatus: FindingStatus) => void,
}

export const FindingStatusCell: FC<Props> = ({ cell: { value, row }, updateFindingStatus }) => (
  <StatusBox
    finding={row.original}
    resolution={value}
    updateFindingStatus={updateFindingStatus}
  />
);
