import { FC } from 'react';

import { ActionsProvider } from 'context/ActionsContext/ActionsProvider';
import { ActionsPage } from 'pages/ActionsPage/ActionsPage';

export const ActionsPageWrapper: FC = () => (
  <ActionsProvider>
    <ActionsPage />
  </ActionsProvider>
);
