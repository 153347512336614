import { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { EmptyState } from '../EmptyState/EmptyState';
import { TeamCarousel } from '../TeamCarousel/TeamCarousel';
import { WelcomePageHeader } from '../WelcomePageHeader/WelcomePageHeader';

import styles from './WelcomePageContent.module.scss';

import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { constants } from 'globalConstants';
import { useCurrentTeam } from 'pages/TeamsPortal/hooks/useCurrentTeam/useCurrentTeam';
import { useTenantService } from 'services/TenantService/useTenantService';

export const WelcomePageContent: FC = () => {
  const { updateUser } = useTenantService();
  const { frontEggUser, setViewedWelcomeTeamsPage } = useAuthContext();
  const { currentTeam, handleCurrentTeamChange, isInitLoading, teams } = useCurrentTeam();
  const {
    teamsPortal: { BASE_ROUTE: BASE_TEAMS_PORTAL_ROUTE, GUIDE },
  } = constants.routes;
  const navigate = useNavigate();

  const handleSelectTeam = useCallback(() => {
    setViewedWelcomeTeamsPage(true);
    navigate(`/${BASE_TEAMS_PORTAL_ROUTE}/${GUIDE}`);
  }, [BASE_TEAMS_PORTAL_ROUTE, GUIDE, navigate, setViewedWelcomeTeamsPage]);

  const handleUserViewedWelcomeTeamsPage = useCallback(async () => {
    await updateUser({
      user_id: frontEggUser.id,
      metadata: {
        viewed_welcome_teams_page: {
          ...JSON.parse(frontEggUser?.metadata ?? '{}')?.viewed_welcome_teams_page,
          [frontEggUser.tenantId]: true,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (teams.data.length > 0) {
      handleUserViewedWelcomeTeamsPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams.data.length]);

  if (isInitLoading) {
    return (
      <div className={styles.loadingSpinnerWrapper}>
        <LoadingBar />
      </div>
    );
  }

  if (teams.data.length === 0) {
    return <EmptyState />;
  }

  return (
    <>
      <WelcomePageHeader />

      <TeamCarousel currentTeam={currentTeam} handleSelectTeam={handleSelectTeam} setCurrentTeam={handleCurrentTeamChange} teams={teams} />
    </>
  );
};
