import { useCallback, useState } from 'react';
import { useQueryParam } from 'use-query-params';

import { useActionsContext } from 'context/ActionsContext/ActionsContext';
import { useActionService } from 'services/ActionService/useActionService';
import { logError } from 'services/logger/logger';
import { IAction, IActionFinding, IConcealedAction, IFilter, IFilterOption, IFilterType } from 'types/interfaces';
import { ActionFiltersKeys, IActionFilters } from 'types/interfaces/Actions/ActionFilters';
import { initialPaginatedState, IPaginatedState } from 'types/interfaces/IPaginatedState';
import { buildStringArrayQueryParam } from 'utils/functions/buildStringArrayQueryParam';

export const useFetchActions = () => {
  const { fetchActions } = useActionService();
  const actionInitialState = {
    ...initialPaginatedState,
    isLoading: true,
  };

  const [actions, setActions] = useState<IPaginatedState<(IAction | IConcealedAction)>>(actionInitialState);

  const getActions = useCallback(async (filters?: IFilter[], cleanState?: boolean) => {
    setActions((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const after = cleanState ? undefined : actions.after;
    const response = await fetchActions(after, filters);

    if (response) {
      let afterResponse = response?.metadata?.after;
      if (afterResponse === null) {
        afterResponse = undefined; // We don't want to send null to the API
      }
      let data: (IAction | IConcealedAction)[] = response?.data || [];
      if (!cleanState) {
        data = [...actions.data, ...(response?.data || [])];
      }
      setActions((prevState: IPaginatedState<IAction | IConcealedAction>) => ({
        ...prevState,
        isLoading: false,
        after: afterResponse,
        data: data || [],
        hasReachedEnd: !response?.metadata?.after,
      }));
    } else {
      logError('Error while getting Actions');
    }
    setActions((prevState) => ({
      ...prevState,
      isLoading: false,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions.after, actions.data]);

  return {
    getActions,
    actions,
    isLoading: actions.isLoading,
    setActions,
  };
};

export const useFetchActionFilters = () => {
  const { fetchActionsFiltersDistinctValues } = useActionService();
  const [isFetchActionFiltersLoading, setIsFetchActionFiltersLoading] = useState<boolean>(false);
  const [actionFilters, setActionFilters] = useState<IFilter[]>([]);

  const stringArrayParam = buildStringArrayQueryParam(
    () => true,
  );

  const [assetNamesQueryParam, setAssetNamesQueryParam] = useQueryParam(ActionFiltersKeys.ASSET_NAMES, stringArrayParam);
  const [planItemsQueryParam, setPlanItemsQueryParam] = useQueryParam(ActionFiltersKeys.PLAN_ITEMS, stringArrayParam);
  const [teamsQueryParam, setTeamsQueryParam] = useQueryParam(ActionFiltersKeys.TEAMS, stringArrayParam);

  const applyFilterToQueryParam = (filter: IFilter) => {
    const setQueryParamMap = {
      [ActionFiltersKeys.ASSET_NAMES]: setAssetNamesQueryParam,
      [ActionFiltersKeys.PLAN_ITEMS]: setPlanItemsQueryParam,
      [ActionFiltersKeys.TEAMS]: setTeamsQueryParam,
    };

    const setQueryParam = setQueryParamMap[filter.entityKey as ActionFiltersKeys];
    const selectedValues = Array.isArray(filter.selectedValue)
      ? filter.selectedValue.map((option: IFilterOption) => option.value)
      : undefined;

    if (selectedValues && selectedValues.length > 0) {
      setQueryParam(selectedValues);
    } else {
      setQueryParam(null);
    }
  };

  const applyQueryParamsFilters = useCallback((currentFilters: IFilter[]): IFilter[] => {
    const queryParamMap = {
      [ActionFiltersKeys.ASSET_NAMES]: assetNamesQueryParam,
      [ActionFiltersKeys.PLAN_ITEMS]: planItemsQueryParam,
      [ActionFiltersKeys.TEAMS]: teamsQueryParam,
    };

    return currentFilters.map((filter) => {
      const queryParamValues = queryParamMap[filter.entityKey as ActionFiltersKeys];
      const selectedValue: IFilterOption[] = queryParamValues?.map((value) => ({
        value,
        displayText: value,
      } as IFilterOption)) || [];

      return {
        ...filter,
        selectedValue,
      };
    });
  }, [assetNamesQueryParam, planItemsQueryParam, teamsQueryParam]);

  const initializeFilters = useCallback((actionFilterValues: IActionFilters): IFilter[] => {
    let filters: IFilter[] = [];
    filters.push({
      entityKey: ActionFiltersKeys.ASSET_NAMES,
      displayText: 'Asset Names',
      type: IFilterType.MULTI_SELECT,
      selectedValue: [],
      valueOptions: actionFilterValues[ActionFiltersKeys.ASSET_NAMES].map((name) => ({
        value: name,
        displayText: name,
      })),
    });

    filters.push({
      entityKey: ActionFiltersKeys.PLAN_ITEMS,
      displayText: 'Plan Items',
      type: IFilterType.MULTI_SELECT,
      selectedValue: [],
      valueOptions: actionFilterValues[ActionFiltersKeys.PLAN_ITEMS].map((item) => ({
        value: item,
        displayText: item,
      })),
    });

    filters.push({
      entityKey: ActionFiltersKeys.TEAMS,
      displayText: 'Teams',
      type: IFilterType.MULTI_SELECT,
      selectedValue: [],
      valueOptions: actionFilterValues[ActionFiltersKeys.TEAMS].map((team) => ({
        value: team,
        displayText: team,
      })),
    });
    filters = applyQueryParamsFilters(filters);
    return filters;
  }, [applyQueryParamsFilters]);

  const getActionDistinctFilters = useCallback(async () => {
    setIsFetchActionFiltersLoading(true);
    const filtersDistinctValues = await fetchActionsFiltersDistinctValues();
    if (filtersDistinctValues) {
      setActionFilters(initializeFilters(filtersDistinctValues));
    }
    setIsFetchActionFiltersLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializeFilters]);

  return {
    getActionDistinctFilters,
    actionFilters,
    setActionFilters,
    initializeFilters,
    applyFilterToQueryParam,
    isFetchActionFiltersLoading,
  };
};

export const useFetchActionFindings = () => {
  const { fetchActionFindings } = useActionService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { actionFilters } = useActionsContext();
  const [actionFindings, setActionFindings] = useState<IPaginatedState<IActionFinding>>(initialPaginatedState);

  const getActionFindings = useCallback(async (actionId: string) => {
    setIsLoading(true);
    const response = await fetchActionFindings(actionId, actionFindings.after, actionFilters);

    if (response) {
      let afterResponse = response?.metadata?.after;
      if (afterResponse === null) {
        afterResponse = undefined; // We don't want to send null to the API
      }
      const data = [...actionFindings.data, ...(response?.data || [])];
      setActionFindings((prevState: IPaginatedState<IActionFinding>) => ({
        ...prevState,
        isLoading: false,
        after: afterResponse,
        data: data || [],
        hasReachedEnd: !response?.metadata?.after,
      }));
    } else {
      logError('Error while getting Action Findings');
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionFindings.after, actionFindings.data]);

  return {
    getActionFindings,
    actionFindings,
    isLoading,
    setActionFindings,
  };
};

export const useFetchActionsCount = () => {
  const { fetchActionsCount } = useActionService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [actionsCount, setActionsCount] = useState<number>();

  const getActionsCount = useCallback(async (filters?: IFilter[]) => {
    setIsLoading(true);
    const response = await fetchActionsCount(filters);

    if (response) {
      setActionsCount(response?.count);
    } else {
      logError('Error while getting Actions Count');
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    getActionsCount,
    actionsCount,
    isLoading,
  };
};
