import { FC, useCallback, useMemo } from 'react';

import styles from './ConfigurableRow.module.scss';

import { CalenderNotebook, EditPen, Trash } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { getFormattedTimeSince } from 'pages/PipelinesPage/utils';
import colors from 'themes/colors.module.scss';
import { IConfigurableItem } from 'types/interfaces/Configurations/IConfigurableItem';

interface Props {
  configurableItem: IConfigurableItem;
  onEdit: (configurableItem: IConfigurableItem) => void;
  onDelete: (configurableItem: IConfigurableItem) => void;
  disableDelete?: boolean;
  disableDeleteTooltip?: string;
}

export const ConfigurableRow: FC<Props> = ({ configurableItem, onEdit, onDelete, disableDelete, disableDeleteTooltip }) => {
  const formattedTimeSince = useMemo(
    () => getFormattedTimeSince(configurableItem.modifiedAt?.split('.')[0]),
    [configurableItem.modifiedAt],
  );

  const deleteButtonColor = useMemo(
    () => (disableDelete ? colors.lightGray : colors.red),
    [disableDelete],
  );

  const deleteTooltipTitle = useMemo(
    () => disableDeleteTooltip || 'dialogs.secretsManagement.tooltips.delete',
    [disableDeleteTooltip],
  );

  const handleEdit = useCallback(() => onEdit(configurableItem), [onEdit, configurableItem]);
  const handleDelete = useCallback(() => onDelete(configurableItem), [onDelete, configurableItem]);

  return (
    <div className={styles.row} data-testid={`configurableRow-${configurableItem.name}`}>
      <div className={styles.leftSide}>
        <TooltipOnlyOnOverflow bold text={configurableItem.name} />
      </div>

      <div className={styles.rightSide}>
        {configurableItem.modifiedAt && (
          <div className={styles.modifiedAt} data-testid='modifiedAt'>
            <JitIcon color={colors.lightGray} icon={CalenderNotebook} size={15} />

            <JitText muted text={formattedTimeSince} />
          </div>
        )}

        <JitButton data-testid='editButton' noHover onClick={handleEdit}>
          <JitTooltip followCursor={false} placement='top' title='dialogs.secretsManagement.tooltips.edit'>
            <JitIcon color={colors.iris} icon={EditPen} size={15} />
          </JitTooltip>
        </JitButton>

        <JitTooltip followCursor={false} placement='top' title={deleteTooltipTitle}>
          <JitButton
            data-testid='deleteButton'
            disabled={disableDelete}
            noHover
            onClick={handleDelete}
          >
            <JitIcon icon={Trash} size={15} strokeColor={deleteButtonColor} />
          </JitButton>
        </JitTooltip>
      </div>
    </div>
  );
};
