import { CSSProperties, FC } from 'react';

import { LINE_PROGRESS_GAP, LINE_PROGRESS_NO_GAP } from './constants';
import styles from './LineProgress.module.scss';

import colors from 'themes/colors.module.scss';

interface Props {
  percentage: number;
  color: string;
  emptySectionColor?: string;
  width?: CSSProperties['width'];
}

export const LineProgress: FC<Props> = ({ percentage, color, emptySectionColor, width = 200 }) => {
  const coloredLineClasses = [styles.coloredLine];
  const emptySectionClasses = [styles.emptyLine];

  const noGap = percentage === 100 || percentage === 0;
  // If the percentage is 100% or 0%, no need to add the gap between the colored and empty lines

  if (percentage === 100) {
    coloredLineClasses.push(styles.fullColored);
  } else if (percentage === 0) {
    emptySectionClasses.push(styles.fullEmpty);
  }

  const emptyLineColor = emptySectionColor || colors.darkGray;
  // If the percentage is 100% or 0%, the colored line should be full width and the empty line should be 0 width, and vice versa

  return (
    <div className={styles.container} style={{ width }}>
      <div
        className={styles.wrapper}
        style={{
          gap: noGap ? LINE_PROGRESS_NO_GAP : LINE_PROGRESS_GAP,
        }}
      >
        <div
          className={coloredLineClasses.join(' ')}
          style={{
            width: `${percentage}%`,
            backgroundColor: color,
          }}
        />

        <div
          className={emptySectionClasses.join(' ')}
          style={{
            width: `${100 - percentage}%`,
            backgroundColor: emptyLineColor,
          }}

        />
      </div>
    </div>
  );
};
