import { FC } from 'react';

import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import { IAsset, IChangeEvent } from 'types/interfaces';
import { stopPropagation } from 'utils';

interface Props {
  asset: IAsset;
  isDisabled: boolean;
  checkAsset: (assetId: string, checked: boolean) => void;
}

export const AssetsTableRowCheckBox: FC<Props> = ({
  asset,
  isDisabled,
  checkAsset,
}) => {
  const handleAssetSelection = (e: IChangeEvent, checked: boolean) => {
    const assetId = e.target.id;
    checkAsset(assetId, checked);
  };

  return (
    <JitCheckbox
      checked={asset.is_covered}
      data-testid='asset-checkbox'
      disabled={isDisabled}
      id={asset.asset_id}
      onChange={handleAssetSelection}
      onClick={stopPropagation}
    />
  );
};

