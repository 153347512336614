import { AxiosResponse } from 'axios';
import { t } from 'i18next';
import React, { FC, useCallback } from 'react';
import { useMutation } from 'react-query';

import { AddAssetsDialogContent } from '../AddAssetsDialogContent/AddAssetsDialogContent';

import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import { useToastsContext } from 'context/ToastsContext/ToastsContext';
import { useAssetService } from 'services/AssetsService/useAssetService';
import { ToastType, Vendor } from 'types/enums';
import { TagNames } from 'types/enums/Tags';
import { IAsset, IMouseEvent } from 'types/interfaces';
import { stopPropagation } from 'utils';

interface Props {
  onClose: (e: IMouseEvent) => void;
  isOpen: boolean;
  teamName: string;
  vendor: Vendor;
}

interface MutationArgs {
  assets: IAsset[];
  event: IMouseEvent;
}

export const AddAssetsDialog: FC<Props> = ({ onClose, isOpen, teamName, vendor }) => {
  const { updateMultipleAssets } = useAssetService();

  const { showToast } = useToastsContext();

  const onSuccess = useCallback((response: AxiosResponse<IAsset[]> | undefined, variables: MutationArgs) => {
    const expected = variables?.assets.length;
    const affected = response?.data?.length;
    let title;

    if (expected !== affected) {
      title = t(`pages.teamsPortal.teamPage.table.add.${vendor}.success.partial`, {
        affected,
        expected,
      });
    } else if (affected === 1) {
      title = t(`pages.teamsPortal.teamPage.table.add.${vendor}.success.single`);
    } else {
      title = t(`pages.teamsPortal.teamPage.table.add.${vendor}.success.multiple`, { affected });
    }

    showToast({
      type: ToastType.Success,
      overrideProps: {
        title,
        secondsToClose: 3,
      },
    });
  }, [showToast, vendor]);

  const { mutate: addAssets, isLoading } = useMutation(
    async ({ assets }: MutationArgs) => updateMultipleAssets(assets),
    {
      onSuccess,
      onSettled: (data, error, variables) => {
        if (variables && variables.event) {
          onClose(variables.event);
        }
      },
    },
  );

  const onConfirm = async (event: React.MouseEvent<HTMLButtonElement>, assets: IAsset[]) => {
    stopPropagation(event);
    const assetsToUpdate: IAsset[] = assets.map((asset) => ({
      ...asset,
      tags: [...asset.tags, {
        name: TagNames.Teams,
        value: teamName,
      }],
    }));
    addAssets({
      assets: assetsToUpdate,
      event,
    });
  };

  return (
    <CustomDialog
      content={<AddAssetsDialogContent isLoading={isLoading} onConfirm={onConfirm} teamName={teamName} vendor={vendor} />}
      dataTestId='AddAssetsDialog'
      isOpen={isOpen}
      onClose={(e) => onClose(e as IMouseEvent)}
      title={`pages.teamsPortal.teamPage.table.add.${vendor}.header`}
      width='l'
      withDivider
    />
  );
};
