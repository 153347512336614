import { useState, useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { GithubIntegrationWizard } from 'components/GithubIntegrationWizard/GithubIntegrationWizard';
import { GitlabIntegrationWizard } from 'components/GitlabIntegrationWizard/GitlabIntegrationWizard';
import { useTenantContext } from 'context/TenantContext';
import { constants } from 'globalConstants';
import { SCMVendors, Vendor } from 'types/enums/Vendor';

export type SCMWizardProps = {
  handleClose: () => void;
};

const SCM_WIZARD_MAP: Record<SCMVendors, React.FC<SCMWizardProps>> = {
  [Vendor.Github]: GithubIntegrationWizard,
  [Vendor.GITLAB]: GitlabIntegrationWizard,
};

export const useScmIntegration = () => {
  const { initialOnboardingCompleted } = useTenantContext();
  const { pathname } = useLocation();

  const isRedirectedBackFromGHInstallation = pathname === constants.GITHUB_INSTALLATION_REDIRECT_PATH;
  const [activeSCMWizard, setActiveSCMWizard] = useState<keyof typeof SCM_WIZARD_MAP>();

  useEffect(() => {
    if (isRedirectedBackFromGHInstallation && !initialOnboardingCompleted) {
      setActiveSCMWizard(Vendor.Github);
    }
  }, [initialOnboardingCompleted, isRedirectedBackFromGHInstallation]);

  const handleClose = useCallback(() => {
    setActiveSCMWizard(undefined);
  }, []);

  const ActiveSCMWizardComponent = useMemo(() => (activeSCMWizard ? SCM_WIZARD_MAP[activeSCMWizard] : undefined), [activeSCMWizard]);

  return {
    handleClose,
    ActiveSCMWizardComponent,
    setActiveSCMWizard,
  };
};
