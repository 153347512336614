import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ProductionOpenFindingsComponent,
  DevelopersEfficiencyComponent,
  MTTRComponent,
  ResolvedPreProductionComponent,
  ResolvedFindingsComponent,
  PullRequestsWithFindingsComponent,
} from './components';
import { SECURITY_IMPACT_ROUTE } from './constants';
import styles from './Insights.module.scss';

import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import { JitText } from 'components/JitText/JitText';
import { GraphLoading } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/components';
import {
  ChangeCalculationDialogContent,
} from 'pages/InsightsPage/SubPages/SecurityImpact/components/DevProductivity/components/ChangeCalculationDialogContent/ChangeCalculationDialogContent';
import { ActionButtonVariant } from 'types/interfaces';
import { IMTTRMetric } from 'types/interfaces/Metrics/PerformanceMetrics/MTTR';
import { ITrendingDevProductivity } from 'types/interfaces/Metrics/SecurityImpactMetrics/DevProductivity';
import { IPullRequestsAnalysis } from 'types/interfaces/PullRequests';

interface Props {
  devProductivityMetric?: ITrendingDevProductivity
  mttrMetric?: IMTTRMetric;
  silentFetchDevProductivity: () => void;
  prAnalysis?: IPullRequestsAnalysis;
  isLoading: boolean;
}

export const Insights: FC<Props> = ({ devProductivityMetric, mttrMetric, silentFetchDevProductivity, prAnalysis, isLoading }) => {
  const [isCalcDialogOpen, setIsCalcDialogOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  if (devProductivityMetric === undefined || mttrMetric === undefined || prAnalysis === undefined) {
    return <GraphLoading />;
  }

  const insightTopComponents = [
    <ProductionOpenFindingsComponent key='insightTopComponents-DetectedFindingsComponent' devProductivityMetric={devProductivityMetric} />,
    <ResolvedFindingsComponent key='insightTopComponents-ResolvedFindingsComponent' devProductivityMetric={devProductivityMetric} />,
    <ResolvedPreProductionComponent key='insightTopComponents-ResolvedPreProductionComponent' devProductivityMetric={devProductivityMetric} />,
  ];

  const insightBottomComponents = [
    <PullRequestsWithFindingsComponent key='insightBottomComponents-PullRequestsWithFindingsComponent' prAnalysis={prAnalysis} />,
    <MTTRComponent key='insightBottomComponents-MTTRComponent' mttrMetric={mttrMetric} />,
    <DevelopersEfficiencyComponent
      key='insightBottomComponents-DevelopersEfficiencyComponent'
      devProductivityMetric={devProductivityMetric}
      setIsCalcDialogOpen={setIsCalcDialogOpen}
    />,
  ];

  return (
    <div className={styles.wrapper} data-testid='Insights-wrapper'>
      <div className={styles.header} data-testid='Insights-header'>
        <JitText text='pages.overview.insights.title' />

        <div className={styles.buttonWrapper}>
          <JitActionButton actionButton={{
            label: 'pages.overview.insights.viewSecurityImpact',
            handleClick: () => { navigate(SECURITY_IMPACT_ROUTE); },
            variant: ActionButtonVariant.OUTLINED,
          }}
          />
        </div>
      </div>

      <div className={styles.dataOverviewItemsWrapper}>
        {isLoading ? <GraphLoading />
          : (
            <>
              <div className={styles.dataOverviewItemsList} data-testid='dataOverviewItemsList-top'>
                {insightTopComponents}
              </div>

              <div className={styles.dataOverviewItemsList} data-testid='dataOverviewItemsList-bottom'>
                {insightBottomComponents}
              </div>
            </>
          )}
      </div>

      <CustomDialog
        content={(
          <ChangeCalculationDialogContent
            devProductivityMetric={devProductivityMetric}
            setIsCalcDialogOpen={setIsCalcDialogOpen}
            silentFetchDevProductivity={silentFetchDevProductivity}
          />
        )}
        dataTestId='share-snapshot-dialog'
        isOpen={isCalcDialogOpen}
        onClose={() => { setIsCalcDialogOpen(false); }}
        title='pages.securityImpact.calculator.title'
        width='m'
        withDivider
      />

    </div>
  );
};
