import { FC } from 'react';

import { PriorityFactorsList } from 'pages/Resources/components/PriorityFactorsList/PriorityFactorsList';
import { ITableCell, IAsset } from 'types/interfaces';

export const PriorityFactorsCell: FC<ITableCell<IAsset>> = ({ cell: { value } }) => {
  const factors = ((value || []) as string[]);
  return <PriorityFactorsList factors={factors} />;
};

