import { PROGRESS_COLOR_HIGH_PERCENTAGE } from './constants';

import colors from 'themes/colors.module.scss';

export const getPercentageColor = (percentage: number): string => {
  let progressColor: string;
  if (percentage === 0) progressColor = colors.failRed;
  else if (percentage < PROGRESS_COLOR_HIGH_PERCENTAGE) progressColor = colors.alert;
  else progressColor = colors.eliteRank;
  return progressColor;
};
