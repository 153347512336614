import { FC } from 'react';

import styles from './JobsListNew.module.scss';

import { CollapseList } from 'pages/PipelinesPage/PipelineDetailsPage/components/PipelineWorkflows/CollapseList';
import { JobNew } from 'pages/PipelinesPage/PipelineDetailsPage/components/PipelineWorkflows/Jobs/Job/JobNew';
import { IJobNew } from 'types/interfaces/Pipelines/IPipelineNew';

interface JobsListNewProps {
  jobs: IJobNew
  owner: string
  jitEventId: string
}

export const JobsListNew: FC<JobsListNewProps> = ({ jobs, owner, jitEventId }) => {
  const jobsCount = Object.keys(jobs).length;

  const jobsElements = (
    Object.entries(jobs).map(([jobSlug, job]) => (
      <div key={jobSlug} className={styles.wrapper}>
        <JobNew jitEventId={jitEventId} job={job} jobSlug={jobSlug} owner={owner} />
      </div>
    ))
  );

  return (
    <div data-testid='jobs'>
      <CollapseList headerParams={{ jobsCount }} headerText='pages.pipelineDetails.workflows.jobs' shouldCollapsedThreshold={2}>
        {jobsElements}
      </CollapseList>
    </div>
  );
};

