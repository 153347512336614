import { FC, useState } from 'react';

import { JitIcon } from '../JitIcon/JitIcon';
import { JitText } from '../JitText/JitText';

import styles from './SmilyRateBox.module.scss';

import colors from 'themes/colors.module.scss';
import { ISvg } from 'types/interfaces';

interface Props {
  onClick: () => void;
  isSelected: boolean;
  icon: FC<ISvg>;
  text: string;
  color: string;
  wrapperClassName?: string;
}

export const SmilyRateBox: FC<Props> = (
  { onClick, isSelected = false, icon, text, color, wrapperClassName = '' },
) => {
  const [isHovered, setIsHovered] = useState(false);
  const isSmilyActive = isSelected || isHovered;
  const activeColor = isSmilyActive ? color : colors.lightGray;

  const borderStyle = isSmilyActive ? {
    border: `2px solid ${color}`,
  } : {};
  return (
    <div
      className={`${styles.iconBox} ${wrapperClassName}`}
      data-testid={`smily-rate-box-${text}`}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      role='button'
      style={{
        cursor: 'pointer',
        ...borderStyle,
      }}
      tabIndex={0}
    >
      <JitIcon color={activeColor} icon={icon} size={20} />

      <JitText color={colors.lightGray} size='xxs' text={text} />
    </div>
  );
};
