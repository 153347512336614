import { FC } from 'react';

import { FindingsFiltersBox } from '..';

import styles from './FindingsFiltersBoxes.module.scss';

import { IFindingFilter, MenuItemKeyType } from 'types/interfaces';

interface Props {
  allowExtendFilterView: boolean;
  isFilterViewExtended: boolean;
  initiallyOpenedFilterOption?: MenuItemKeyType
  visibleFilters: IFindingFilter[];
  height: number | string;
  onMenuItemClick: () => void;
  itemsRef: React.MutableRefObject<HTMLDivElement[]>;
  calculatedWidthToFitFilterBoxes: number;
}

export const FindingsFiltersBoxes: FC<Props> = ({
  allowExtendFilterView, isFilterViewExtended, initiallyOpenedFilterOption,
  visibleFilters, height, onMenuItemClick, calculatedWidthToFitFilterBoxes,
  itemsRef,
}) => {
  const setElementToItemsRef = (element: HTMLDivElement | null, index: number) => {
    if (element) {
      // eslint-disable-next-line no-param-reassign
      itemsRef.current[index] = element;
    }
  };

  return (
    <div
      className={styles.boxesContainer}
      style={{
        height,
        overflow: isFilterViewExtended ? 'inherit' : 'hidden',
        width: calculatedWidthToFitFilterBoxes || 'auto',
        paddingTop: allowExtendFilterView ? 3 : 0,
      }}
    >
      {visibleFilters.map((filter, index) => (
        <div
          key={filter.key}
          ref={(el) => setElementToItemsRef(el, index)}
        >
          <FindingsFiltersBox
            key={filter.key}
            filter={filter}
            onMenuItemClickAction={onMenuItemClick}
            shouldInitiallyBeOpen={initiallyOpenedFilterOption ? filter.key === initiallyOpenedFilterOption : false}
          />
        </div>
      ))}
    </div>
  );
};
