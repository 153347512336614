import { useMemo } from 'react';

import { LibraryNameCell } from './Cells/LibraryNameCell/LibraryNameCell';

import { i18n } from 'locale/i18n';
import { LicensesCell } from 'pages/SbomPage/components/SbomTable/Cells/LicensesCell/LicensesCell';
import { ResourcesCell } from 'pages/SbomPage/components/SbomTable/Cells/ResourcesCell/ResourcesCell';
import { ExpendableComponentGroup } from 'types/interfaces/Sbom/SbomComponentGroup';
import { TableColumn } from 'types/types';

interface TableColumnsNames {
  libraryName: string;
  license: string;
  resources: string;
}

export const useGetColumns = (): TableColumn<object>[] => {
  const { t } = i18n;
  const columnHeaders: TableColumnsNames = useMemo(() => t('pages.sbom.table.columns', { returnObjects: true }), [t]);

  // @ts-ignore The whole tables related logic uses object as type, this should be fixed
  const columns: TableColumn<object>[] = useMemo(() => [
    {
      Header: columnHeaders.libraryName,
      accessor: (componentsGroup: ExpendableComponentGroup) => ({
        assets: componentsGroup.assets,
        name: componentsGroup.name,
        version: componentsGroup.version,
        expanded: !!componentsGroup.expanded,
        groupId: componentsGroup.id,
      }),
      Cell: LibraryNameCell,
      width: '45%',
    },
    {
      Header: columnHeaders.license,
      accessor: (componentsGroup: ExpendableComponentGroup) => ({
        licenses: componentsGroup.licenses,
      }),
      Cell: LicensesCell,
      width: '20%',
    },
    {
      Header: columnHeaders.resources,
      accessor: (componentsGroup: ExpendableComponentGroup) => ({
        assets: componentsGroup.assets,
        expanded: componentsGroup.expanded,
      }),
      Cell: ResourcesCell,
      width: '35%',
    },
  ], [columnHeaders]);

  return columns;
};
