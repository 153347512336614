import { JitButton } from 'components/JitButton/JitButton';
import { JitText } from 'components/JitText/JitText';

export const CreateWorkflowButton = () => {
  const handleClick = async () => {
    // not implemented yet
    // eslint-disable-next-line no-console
    console.log('Create workflow button clicked');
  };

  return (
    <JitButton
      data-testid='workflows-button-create'
      onClick={handleClick}
      variant='outlined'
    >

      <JitText horizontalAlign='center' text='pages.workflows.workflowsListPage.buttons.create' />
    </JitButton>
  );
};
