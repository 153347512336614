import { FC } from 'react';

import { CalendarIcon } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { getFormattedTimeSince } from 'pages/PipelinesPage/utils';
import colors from 'themes/colors.module.scss';

interface Props {
  creationDate: string;
}
export const SnapshotDuration: FC<Props> = ({ creationDate }) => {
  const timeSince = getFormattedTimeSince(creationDate);
  return (
    <div style={{
      display: 'flex',
      gap: 6,
    }}
    >
      <JitIcon color={colors.iris} icon={CalendarIcon} size={10} />

      <JitText color={colors.lightGray} data-testid='SnapshotDuration' size='m' text={timeSince} />
    </div>
  );
};
