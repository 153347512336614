import { useEffect, useState } from 'react';

import { useTenantService } from 'services/TenantService/useTenantService';
import { IIntegration } from 'types/interfaces';

export const useFetchIntegrations = () => {
  const [integrations, setIntegrations] = useState<IIntegration[]>([]);
  const { fetchIntegrations } = useTenantService();

  useEffect(() => {
    const fetch = async () => {
      const fetched = await fetchIntegrations();
      setIntegrations(fetched?.data || []);
    };
    fetch();
  }, [fetchIntegrations]);

  return { integrations };
};
