import { range } from 'lodash';
import { Dispatch, FC, SetStateAction, useState } from 'react';

import { PlanCategoryListTitle } from './components';
import styles from './PlanCategoryList.module.scss';

import { LoadingSkeleton } from 'components/LoadingSkeleton/LoadingSkeleton';
import { PaperTitle } from 'components/PaperTitle/PaperTitle';
import { useConfigurationsContext } from 'context/ConfigurationsContext/ConfigurationsContext';
import { usePlanInstanceContext } from 'context/PlanInstanceContext/PlanInstanceContext';
import { useGetFilteredPlanItemsDetails } from 'context/PlansContext/hooks/useGetFilteredPlanItemsDetails';
import { usePlanItemsGroupedByCategory } from 'context/PlansContext/hooks/usePlanItemsGroupedByCategory';
import { usePlansContext } from 'context/PlansContext/PlansContext';
import { usePlanTemplatesContext } from 'context/PlanTemplatesContext/PlanTemplatesContext';
import { ItemSlugOptions, PlanCategoryCard } from 'pages/PlanPage/components';
import { IFilter, IPlanItemDetails } from 'types/interfaces';

interface Props {
  setDetailedPlanItem: (planItem: IPlanItemDetails | undefined) => void;
  detailedPlanItem: IPlanItemDetails | undefined;
  handleConfigure: (planItem: IPlanItemDetails) => void;
  setIntroItem: Dispatch<SetStateAction<ItemSlugOptions | null>>;
  planSlug: string;
}

export const PlanCategoryList: FC<Props> = ({ setDetailedPlanItem, detailedPlanItem, handleConfigure, setIntroItem, planSlug }) => {
  const { planItemsFilters } = usePlanTemplatesContext();
  const { isLoadingPlan } = usePlanInstanceContext();
  const { isLoadingConfigurations } = useConfigurationsContext();
  const isLoading = isLoadingPlan || isLoadingConfigurations;
  const { hasFetchedPlans } = usePlansContext();
  const [filters, setFilters] = useState<IFilter[]>(planItemsFilters);
  const { plans } = usePlansContext();

  const plan = plans[planSlug];
  const planItemsDetails = Object.values(plans[planSlug].items || {});

  const { filteredPlanItemsDetails } = useGetFilteredPlanItemsDetails(planItemsDetails, filters);

  const { planItemTemplatesGroupedByCategory } = usePlanItemsGroupedByCategory(filteredPlanItemsDetails, plan);

  const updateFilters = (updatedFilters: IFilter[]) => {
    setFilters(updatedFilters);
  };

  const resetFilters = () => {
    setFilters(planItemsFilters);
  };

  return (
    <PaperTitle
      className={styles.paperTitle}
      sticky
      template={(
        <PlanCategoryListTitle
          filters={filters}
          planItems={filteredPlanItemsDetails}
          resetFilters={resetFilters}
          updateFilters={updateFilters}
        />
      )}
    >
      {(!hasFetchedPlans || !plan.hasFetchedItems) && range(10).map((i) => (
        <div key={i} className={styles.loadingSkeletonWrapper}>
          <LoadingSkeleton className={styles.loadingSkeleton} />
        </div>
      ))}

      {!isLoading && hasFetchedPlans && planItemTemplatesGroupedByCategory?.map(([layer, planItems]) => (
        <PlanCategoryCard
          key={layer}
          handleConfigure={handleConfigure}
          layerKey={layer}
          layerTitle={plan?.layers?.find((l) => l.key === layer)?.name || `layer.${layer}`}
          planItems={planItems}
          planSlug={planSlug}
          selectedPlanItem={detailedPlanItem}
          setDetailedPlanItem={setDetailedPlanItem}
          setIntroItem={setIntroItem}
        />
      ))}
    </PaperTitle>
  );
};
