import { FC, useState } from 'react';
import { TooltipProps } from 'recharts';

import { GraphEmpty, GraphLoading } from '../../components';
import styles from '../../Graphs.module.scss';
import { GraphBody } from '../components/GraphBody/GraphBody';
import { GraphHeader } from '../components/GraphHeader/GraphHeader';
import { TooltipFormatter } from '../components/TooltipFormatter/TooltipFormatter';
import { Y_TICK_INCREASE_CONST } from '../constants';

import { useClosestLineName } from 'pages/InsightsPage/hooks/useClosestLineName';
import { getMaxFindingAmount, getYAvailableTicks } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/FindingsActivityGraphs/utils';
import { TimeAgo } from 'types/enums';
import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { IDailyFindingsActivityMetric } from 'types/interfaces/Metrics/PerformanceMetrics/DailyFindingsActivities';

interface Props {
  dailyFindingsActivitiesMetric: MetricWithLoadingIndication<IDailyFindingsActivityMetric>;
  xAxisTicks: number[];
  period: TimeAgo
}

export const DailyFindingsActivities: FC<Props> = ({ dailyFindingsActivitiesMetric, xAxisTicks, period }) => {
  const [displayDetectedLine, setDisplayDetectedLine] = useState(true);
  const [displayResolvedLine, setDisplayResolvedLine] = useState(true);

  const days = dailyFindingsActivitiesMetric.metric?.days ?? [];
  const maxFindingsAmount = getMaxFindingAmount(days);
  const yAxisAvailableTicks = getYAvailableTicks(maxFindingsAmount);
  const yAxisTicks = yAxisAvailableTicks.filter((value) => value <= maxFindingsAmount + Y_TICK_INCREASE_CONST);
  const yAxisTicksFormatter = (value: number) => value.toLocaleString();
  const { closestLineName, onMouseMove } = useClosestLineName(yAxisTicks);

  const getTooltipFormatter = (props: TooltipProps<string, string>) => (
    <TooltipFormatter
      {...props}
      closestLineName={closestLineName}
    />
  );

  return (
    <div className={styles.metricWrapper} data-testid='dailyFindingsActivitiesGraph'>
      <GraphHeader
        description='pages.performance.graphs.dailyFindingsActivities.description'
        displayDetectedLine={displayDetectedLine}
        displayResolvedLine={displayResolvedLine}
        emptyDays={days.length === 0}
        isLoading={dailyFindingsActivitiesMetric.isLoading}
        popperDescription='pages.performance.graphs.dailyFindingsActivities.widget.description'
        popperTitle='pages.performance.graphs.dailyFindingsActivities.widget.title'
        setDisplayDetectedLine={setDisplayDetectedLine}
        setDisplayResolvedLine={setDisplayResolvedLine}
        title='pages.performance.graphs.dailyFindingsActivities.title'
      />

      {dailyFindingsActivitiesMetric.isLoading && <GraphLoading />}

      {!dailyFindingsActivitiesMetric.isLoading && (days.length > 0 ? (
        <GraphBody
          closestLineName={closestLineName}
          days={days}
          displayDetectedLine={displayDetectedLine}
          displayResolvedLine={displayResolvedLine}
          getTooltipFormatter={getTooltipFormatter}
          onMouseMove={onMouseMove}
          period={period}
          xAxisTicks={xAxisTicks}
          yAxisTicksFormatter={yAxisTicksFormatter}
        />
      ) : (
        <GraphEmpty />
      ))}
    </div>
  );
};
