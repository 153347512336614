import Box from '@mui/material/Box';
import { FC } from 'react';

import { JitText } from 'components/JitText/JitText';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { findingSeverityToColor } from 'pages/FindingsPage/constants';
import { Severity } from 'types/enums';

interface Props {
  severity: Severity;
}

export const SeverityBox: FC<Props> = ({ severity }) => {
  const { palette } = useThemeContext();

  return (
    <Box
      sx={{
        backgroundColor: findingSeverityToColor[severity],
        marginRight: '0.25rem',
        marginLeft: '0.25rem',
        borderRadius: '0.5rem',
      }}
    >
      <JitText
        color={palette.common.white}
        style={{
          margin: '0.25rem 0.5rem',
        }}
        text={`severity.${severity?.toLowerCase()}`}
      />
    </Box>
  );
};
