import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getPagesNames, getPlanName } from '../utils/pageUtils';

import { ActionType } from './constants';
import { useSendAnalyticsEvent } from './useSendAnalyticsEvent';

import { IDictionary } from 'types/interfaces';

export const queryParamsToGet: Array<string> = [
  'referral',
];

export const usePageViewedEvent = () => {
  const { pathname, search } = useLocation();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const params: IDictionary<string> = {};

    queryParamsToGet.forEach((key) => {
      const value = queryParams.get(key);
      if (value !== null) {
        params[key] = value;
      }
    });

    params.page = getPagesNames(pathname) || 'Unknown';

    // Special case for Security Plans page
    // get the plan name from the URL
    const planName = getPlanName(pathname);
    if (planName) {
      params.plan = planName;
    }

    sendAnalyticsEvent({
      action: ActionType.PageViewed,
      params,
    });
  }, [pathname, search, sendAnalyticsEvent]);
};
