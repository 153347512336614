import { FC, useEffect, useState } from 'react';

import { HistoryContent } from './components/HistoryConent/HistoryContent';
import styles from './SnapshotHistory.module.scss';

import { ArrowRight } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { useMetricService } from 'services/MetricService/useMetricService';
import colors from 'themes/colors.module.scss';
import { IMouseEvent } from 'types/interfaces';
import { Snapshot } from 'types/interfaces/Metrics/Snapshots';

const ICON_SIZE = 25;

export const SnapshotHistory: FC = () => {
  const { getSnapshots } = useMetricService();
  const [isSnapshotHistoryOpen, setIsSnapshotHistoryOpen] = useState<boolean>(false);
  const [snapshots, setSnapshots] = useState<Snapshot[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isSnapshotHistoryOpen) {
      setIsLoading(true);
      getSnapshots().then((res) => {
        setSnapshots(res);
        setIsLoading(false);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSnapshotHistoryOpen]);
  const handleOpenCloseHistory = (event: IMouseEvent) => {
    event.stopPropagation();
    setIsSnapshotHistoryOpen((prev: boolean) => !prev);
  };
  return (
    <div>
      <div style={{ display: 'flex' }}>
        <JitIcon
          className={styles.arrowWrapper}
          color={colors.iris}
          icon={ArrowRight}
          onClick={handleOpenCloseHistory}
          rotateDegrees={isSnapshotHistoryOpen ? 90 : 0}
          size={ICON_SIZE}
        />

        <JitText
          bold
          data-testid='ShareSnapshotHistory'
          size='m'
          text='pages.performance.shareSnapshot.history.title'
        />
      </div>

      {isSnapshotHistoryOpen && (
      <JitText
        color={colors.lightGray}
        data-testid='ShareSnapshotHistoryDescription'
        size='m'
        text='pages.performance.shareSnapshot.history.description'
      />
      )}

      {isLoading && (
      <div className={styles.loadingSnapshots}>
        <JitText color={colors.lightGray} data-testid='LoadingIndicator' size='m' text='pages.performance.shareSnapshot.history.loading' />

        <JitText className={styles.dotFlashing} color={colors.lightGray} size='m' text='  ' />
      </div>
      )}

      {isSnapshotHistoryOpen && !isLoading && (
      <HistoryContent data-testid='HistoryContent' setSnapshots={setSnapshots} snapshots={snapshots} />)}
    </div>
  );
};
