import { Dispatch, SetStateAction, useCallback } from 'react';

import { IWebsocketNotification, IPlanDetailsMap, ISecurityPlanInstance } from 'types/interfaces';

interface Props {
  setPlans: Dispatch<SetStateAction<IPlanDetailsMap>>,
}

export const useHandlePlansWebSocketNotification = ({ setPlans }: Props) => {
  const handlePlansWebSocketNotification = useCallback((notification: IWebsocketNotification<ISecurityPlanInstance>) => {
    const { message: { updated, created } } = notification;

    const messages = [...(updated || []), ...(created || [])];

    setPlans((curPlans) => ({
      ...curPlans,
      ...messages.reduce((accumulator, message) => ({
        ...accumulator,
        [message.slug]: {
          ...curPlans?.[message.slug],
          ...message,
        },
      }), {}),
    }));
  }, [setPlans]);

  return { handlePlansWebSocketNotification };
};
