import { StiggProvider } from '@stigg/react-sdk';
import { CustomizedTheme } from '@stigg/react-sdk/dist/theme/Theme';
import { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { customCss } from './PricingPlanTheme';
import { StiggExtendedContext } from './StiggExtendedContext';

import { useAuthContext } from 'context/AuthContext/AuthContext';
import { useEntitlementService } from 'services/EntitlementService/useEntitlementService';
import { logError } from 'services/logger/logger';
import { IStiggFeatures } from 'types/interfaces/Entitlements/IStiggFeatures';

interface Props {
  children: ReactElement;
  apiKey: string;
}

export const StiggExtendedProvider: FC<Props> = ({ children, apiKey }) => {
  const [showStiggPayWall, setShowStiggPayWall] = useState(false);
  const [stiggFeatures, setStiggFeatures] = useState<IStiggFeatures[]>([]);
  const { frontEggUser } = useAuthContext();
  const { getFeatures } = useEntitlementService();

  const fetchStiggFeatures = useCallback(async () => {
    const response = await getFeatures();
    if (response?.status === 200) {
      setStiggFeatures(response.data);
    } else {
      logError('Failed to fetch Stigg features', {
        status: response?.status,
        data: response?.data,
      });
    }
  }, [getFeatures]);

  useEffect(() => {
    fetchStiggFeatures();
  }, [fetchStiggFeatures]);

  const value = useMemo(() => ({
    showStiggPayWall,
    setShowStiggPayWall,
    stiggFeatures,
  }), [showStiggPayWall, stiggFeatures]);

  const theme: CustomizedTheme = useMemo(() => ({
    customCss,
  }), []);

  return (
    <StiggProvider apiKey={apiKey} customerId={frontEggUser.tenantId} theme={theme}>
      <StiggExtendedContext.Provider value={value}>
        {children}
      </StiggExtendedContext.Provider>
    </StiggProvider>
  );
};
