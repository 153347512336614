import {
  fetchFindingPriorityFactorsOptions,
  fetchAssetPriorityFactorsOptions,
  fetchFindingTypesOptions,
  fetchAssetTypesOptions,
  fetchFindingsTypesNamesOptions,
  fetchAssetNamesOptions,
  fetchFindingsCwesOptions,
} from './queries';
import { InputOption } from './types';

export const policyRuleOptionsCallbackMap: Record<string, () => InputOption[]> = {
  fetchFindingPriorityFactorsOptions,
  fetchAssetPriorityFactorsOptions,
  fetchFindingTypesOptions,
  fetchAssetTypesOptions,
  fetchFindingsTypesNamesOptions,
  fetchAssetNamesOptions,
  fetchFindingsCwesOptions,
};
