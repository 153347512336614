export enum SnapshotStatus {
  inProgress = 'IN_PROGRESS',
  failed = 'FAILED',
  completed = 'COMPLETED',
}

export interface Snapshot {
  tenantId: string
  name: string
  id: string
  startDate: string
  endDate: string
  createdAt: string
  isPublic: boolean
  expirationDate: string
  metricNames: string[]
  status: SnapshotStatus
  link?: string
  username: string
}
