/* eslint-disable react/prop-types */
import { FC, memo } from 'react';

import { FindingsTable } from 'components/FindingsTable/FindingsTable';
import { JitBreadcrumbs } from 'components/JitBreadcrumbs/JitBreadcrumbs';
import styles from 'pages/TeamsPortal/FindingsPage/components/FindingsPageContent/FindingsPageContent.module.scss';
import { useFindingsContent } from 'pages/TeamsPortal/FindingsPage/hooks/useFindingsContent';
import { FindingServiceViewMode } from 'types/enums/FindingServiceViewMode';
import { IAsset } from 'types/interfaces';
import { ITeam } from 'types/interfaces/Teams/ITeam';

interface PlanItemPageContentProps {
  currentTeam: ITeam;
  relevantAsset?: IAsset;
  planItemSlug?: string;
  mode: FindingServiceViewMode;
}

export const FindingsPageContent: FC<PlanItemPageContentProps> = memo(({
  currentTeam,
  relevantAsset,
  planItemSlug,
  mode,
}) => {
  const {
    findingsFilters,
    breadcrumbsItems,
    findingsLimit,
  } = useFindingsContent({
    currentTeam,
    relevantAsset,
    planItemSlug,
    mode,
  });

  return (
    <div className={styles.contentWrapper} data-testid='teams-page-content-findings-page'>
      <div className={styles.header}>
        <JitBreadcrumbs
          items={breadcrumbsItems}
        />
      </div>

      <div className={styles.tableWrapper}>
        <FindingsTable filters={findingsFilters} limit={findingsLimit} />
      </div>
    </div>
  );
});
