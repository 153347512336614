import { isEqual, pick } from 'lodash';
import { useState, useCallback, useEffect } from 'react';

import { authModeToExtraFieldsInitialState } from '../Zap/constants';

import { logInfo } from 'services/logger/logger';
import { usePollValidationDetails } from 'services/ValidationService/useValidationService';
import { validateUrlExistence } from 'services/ValidationService/ValidationService';
import { AssetType } from 'types/enums';
import {
  IConfigurations,
  ZapApplication,
  ZapApplicationFormBasedAuth,
  ZapAuthMode,
  ZapAuthenticationConfigType,
} from 'types/interfaces';

interface ValidationResult {
  isValid: boolean;
  reason: string;
  screenshot?: string;
}

export const useZapValidator = () => {
  const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { isLoading: pollingLoading, error: pollingError, result: pollingResult, startValidation } = usePollValidationDetails();

  const validateZap = useCallback(async (
    zapType: ZapAuthenticationConfigType,
    selectedApplication: ZapApplication,
    configurations: IConfigurations,
  ) => {
    setLoading(true);
    setError(null);

    try {
      const isFormBasedAuth = (tested?: ZapApplication): tested is ZapApplicationFormBasedAuth => tested?.authentication_mode === ZapAuthMode.FormBased;

      const isFormBasedAuthExtraFieldChanged = (tested?: ZapApplication): tested is ZapApplicationFormBasedAuth => {
        const AuthExtraFields = Object.keys(authModeToExtraFieldsInitialState[ZapAuthMode.FormBased]);
        const fetchedApplication = configurations?.applications?.find(
          (app) => app.application_name === selectedApplication.application_name,
        ) as ZapApplication | undefined;

        const applicationAuthExtraFields = pick(tested, AuthExtraFields);
        const fetchedApplicationAuthExtraFields = pick(fetchedApplication, AuthExtraFields);
        return !isEqual(applicationAuthExtraFields, fetchedApplicationAuthExtraFields);
      };

      const isWebsiteValidated = selectedApplication.target_url && zapType === AssetType.WEB
        ? await validateUrlExistence(selectedApplication.target_url)
        : false;

      if (!isFormBasedAuth(selectedApplication) || !isFormBasedAuthExtraFieldChanged(selectedApplication)) {
        setValidationResult({
          isValid: !!isWebsiteValidated || zapType === AssetType.API,
          reason: isWebsiteValidated
            ? 'configurations.zap.validation.noValidationRequired'
            : 'configurations.zap.validation.websiteDoesNotExist',
        });
        return;
      }

      await startValidation(selectedApplication);
    } catch (err) {
      setError((err as Error).message || 'Unknown error occurred');
    } finally {
      setLoading(false);
    }
  }, [startValidation]);

  useEffect(() => {
    if (pollingResult) {
      setValidationResult({
        isValid: pollingResult.success,
        reason: pollingResult.message,
        screenshot: pollingResult.screenshot,
      });

      logInfo('Zap configurations validated', { pollingResult });
    } else if (pollingError) {
      setError(pollingError);
    }
  }, [pollingResult, pollingError]);

  return {
    validationResult,
    validateZap,
    isValidating: loading || pollingLoading,
    error,
  };
};
