import { FC, useCallback, useMemo, useState } from 'react';

import styles from './JitCopyToClipboardBox.module.scss';

import { CopyIcon } from 'assets';
import { IconButton } from 'components/IconButton/IconButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText, TextSize } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';

interface Props {
  text: string;
  variant?: 'contained' | 'compact' | 'minimal' | 'outlined';
  size?: TextSize;
  textColor?: string;
  iconSize?: number;
  displayText?: string
  fullSize?: boolean;
}

const copyToClipboard = async (text: string) => navigator.clipboard.writeText(text);

export const JitCopyToClipboardBox: FC<Props> = ({ text, variant = 'contained', size, displayText = text, textColor = 'white', iconSize = 22, fullSize = true }) => {
  const [isHoverCopyIcon, setIsHoverCopyIcon] = useState(false);
  const [isCopiedTooltipOpen, setIsCopiedTooltipOpen] = useState(false);
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const colorStyle = ['minimal', 'outlined'].includes(variant) ? { color: colors.iris } : { strikeColor: colors.iris };

  const displayedText = i18n.t(text);

  const onClick = () => {
    sendAnalyticsEvent({
      action: 'copy-to-clipboard',
      params: { 'copy-to-clipboard-text': displayedText },
    });
    copyToClipboard(displayedText).then(() => {
      setIsHoverCopyIcon(false);
      setIsCopiedTooltipOpen(true);
      setTimeout(() => {
        setIsHoverCopyIcon(false);
        setIsCopiedTooltipOpen(false);
      }, 2000);
    });
  };

  const isTooltipEnabled = isHoverCopyIcon || isCopiedTooltipOpen;

  const tooltipText = useMemo(() => {
    if (isTooltipEnabled || !fullSize) {
      return isCopiedTooltipOpen ? 'components.copyToClipboard.copied' : 'components.copyToClipboard.copy';
    }
    return '';
  }, [isCopiedTooltipOpen, isTooltipEnabled, fullSize]);

  const onMouseEnter = useCallback(() => setIsHoverCopyIcon(true), []);
  const onMouseLeave = useCallback(() => setIsHoverCopyIcon(false), []);

  if (fullSize) {
    return (
      <div
        className={styles[`copyToClipboardContainer-${variant}`]}
        data-testid='copyToClipboard'
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        role='button'
        tabIndex={0}
      >
        <JitText color={textColor} size={size} text={displayText} />

        <JitTooltip
          followCursor={false}
          open={isTooltipEnabled}
          placement='top'
          title={tooltipText}
        >
          <JitIcon icon={CopyIcon} size={iconSize} {...colorStyle} />
        </JitTooltip>

      </div>
    );
  }

  return (
    <IconButton color={colors.iris} icon={CopyIcon} isDisabled={false} onClick={onClick} testId='copy-to-clipboard-icon-button' tooltipText={tooltipText} />
  );
};
