import { useCallback } from 'react';

import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import { IGetIntegrationsResponse } from 'types/interfaces/Integrations';
import { IntegrationLifecycleEventState } from 'types/interfaces/Integrations/IIntegration';
import { IParagonAuthDetails } from 'types/interfaces/Integrations/Paragon/IParagonAuthDetails';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

export const useIntegrationsService = () => {
  const { client } = useClient();

  const getAllIntegrations = useCallback(async () => {
    const url = getApiUrls.integrationService.getIntegrations();
    const response = await client.get<IGetIntegrationsResponse>({
      url,
      allowedStatuses: [200, 404],
    });

    if (response?.status === 200) {
      response.data = camelizeSnakeCaseKeys(response.data) as IGetIntegrationsResponse;
    }
    return response;
  }, [client]);

  const getParagonEnv = useCallback(async () => {
    const url = getApiUrls.integrationService.getParagonEnv();
    const response = await client.get<IParagonAuthDetails>({
      url,
      allowedStatuses: [200],
    });

    if (response?.status === 200) {
      response.data = camelizeSnakeCaseKeys(response.data) as IParagonAuthDetails;
    }
    return response;
  }, [client]);

  const publishLifecycleNotification = useCallback(
    async (vendor: string, state: IntegrationLifecycleEventState) => {
      const url = getApiUrls.integrationService.publishLifecycleNotification();
      const response = await client.post({
        url,
        allowedStatuses: [200],
        requestConfig: {
          data: {
            vendor,
            event: state,
          },
        },
      });
      if (response?.status === 200) {
        return response.data;
      }
      return response;
    },
    [client],
  );

  return {
    getAllIntegrations,
    getParagonEnv,
    publishLifecycleNotification,
  };
};
