/* eslint-disable react/no-array-index-key */
import { FC } from 'react';

import { RuleSettings, IRuleTemplate, DataType } from '../../types';

import styles from './RuleBuilder.module.scss';
import { useRuleBuilder } from './useRuleBuilder';

import { JitChip } from 'components/JitChip/JitChip';
import { i18n } from 'locale/i18n';

interface Props {
  settings: RuleSettings;
  ruleTemplate: IRuleTemplate[];
  isEditMode: boolean;
  onFieldChange: (field: string, value: DataType, index?: number) => void;
}

export const RuleBuilder: FC<Props> = ({ settings, ruleTemplate, isEditMode, onFieldChange }) => {
  const { baseConditionSentence, filterConditionSentence } = useRuleBuilder({
    template: ruleTemplate[0],
    settings,
    isEditMode,
    onFieldChange,
  });

  return (
    <div className={styles.ruleInputs}>
      <div className={styles.conditionsWrapper}>
        <div className={styles.condition}>
          {baseConditionSentence.map((rule) => {
            const Component = rule?.type;

            return (Component && <Component key={rule.key} {...rule.props} />);
          })}
        </div>

        {filterConditionSentence?.length
        && (
        <div className={styles.filterConditions}>
          {
            filterConditionSentence.map((condition, index) => (
              <div key={index}>
                <div className={styles.condition}>
                  {
                    condition?.map((rule) => {
                      const Component = rule?.type;

                      return (Component && <Component key={`${rule.key}_${index}`} {...rule.props} />);
                    })
                  }
                </div>

                {index !== filterConditionSentence.length - 1 && <JitChip label={i18n.t('pages.policies.rule.divider.and')} />}
              </div>
            ))
          }
        </div>
        )}
      </div>

    </div>

  );
};
