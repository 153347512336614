import { FC } from 'react';

import { constants } from 'globalConstants';
import { Layer } from 'types/enums';
import { IDictionary, IPlanInstanceItem, ISvg, ItemInput } from 'types/interfaces';
import { iconsMap } from 'utils/constants/iconsMap';

export const getPlanTemplateItemId = (planTemplate: string, tags: IDictionary<string>) => {
  // get plan template name
  const planTemplatePrefix = 'plan-';
  // remove plan from planTemplate
  const planTemplateName = planTemplate.replace(planTemplatePrefix, '');

  // get compliance name from tag key if exists
  const complianceTagKey = `compliance:${planTemplateName}`;

  // get compliance name from tags if exists
  const complianceTagValue = tags[complianceTagKey];

  return complianceTagValue;
};

export const buildPlanItemRecord = (name: string, layer: string, slug: string, input?: ItemInput): IPlanInstanceItem => ({
  name,
  uses: `${constants.PLAN_ITEM_REF_PREFIX}/${layer}/${slug}${constants.PLAN_ITEM_REF_SUFFIX}`,
  ...(input && { input }),
});

export const getLayerIcon = (layer: Layer | string): FC<ISvg> => {
  const layerLowerCase = layer?.toLowerCase();

  switch (layerLowerCase) {
    case Layer.Code:
      return iconsMap.code.icon;
    case Layer.Data:
      return iconsMap.data.icon;
    case Layer.Infrastructure:
      return iconsMap.infrastructure.icon;
    case Layer.Application:
      return iconsMap.application.icon;
    case Layer.Operations:
      return iconsMap.pipeline.icon;
    case Layer.ThirdPartyApp:
      return iconsMap.pipeline.icon;
    default:
      return iconsMap.code.icon;
  }
};
