import React from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import styles from './FindingsOverTimeGraph.module.scss';
import { FindingsOverTimeTooltip } from './FindingsOverTimeTooltip';

import { useClosestLineName } from 'pages/InsightsPage/hooks/useClosestLineName';
import {
  commonGridProps,
  commonLineProps,
  commonTooltipProps,
  commonYAxisProps,
} from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/constants';
import colors from 'themes/colors.module.scss';
import { ITotalFindingsDay, ITotalFindingsGraph } from 'types/interfaces/Metrics/SecurityImpactMetrics/TotalFindingsGraph';

interface FindingsOverTimeProps {
  totalFindingsGraph: ITotalFindingsGraph;
  displayDetectedLine: boolean;
  displayResolvedLine: boolean;
}

const getMaxValue = (days: ITotalFindingsDay[]): number => {
  let max = 0;
  days.forEach((day) => {
    max = Math.max(max, day.detectedFindings, day.resolvedFindings);
  });
  return max;
};

export const FindingsOverTimeGraph: React.FC<FindingsOverTimeProps> = ({
  totalFindingsGraph,
  displayDetectedLine,
  displayResolvedLine,
}) => {
  const { days } = totalFindingsGraph;
  const maxFindingsAmount = getMaxValue(days);
  const yAxisTicks = [0, Math.ceil(maxFindingsAmount / 10) * 10];
  const YAxisTickFormatter = (value: number) => value.toLocaleString();

  // yAxisTicks represents the y-axis ticks for the graph. The first value is always 0, and the second value is the max
  // value of the findings amount rounded up to the nearest 10. For example, if the max value is 23, the second value
  // will be 30. If the max value is 100, the second value will be 110.
  const { closestLineName, onMouseMove } = useClosestLineName(yAxisTicks);

  const monthTickFormatter = (dateString: string) => {
    const date = new Date(dateString);
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
    return month;
  };

  const sortedDays = [...totalFindingsGraph.days].sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA.getTime() - dateB.getTime();
  });

  const chartData = sortedDays.map((item) => ({
    date: item.date,
    detectedFindings: item.detectedFindings,
    resolvedFindings: item.resolvedFindings,
  }));

  const formattedMaxValue = YAxisTickFormatter(maxFindingsAmount);

  return (
    <div className={styles.graph}>
      <ResponsiveContainer height={220} width='100%'>
        <LineChart
          data={chartData}
          height={220}
          margin={{
            left: -32,
            right: 0,
            top: 10,
          }}
          onMouseMove={onMouseMove}
        >

          <CartesianGrid {...commonGridProps} stroke={colors.darkGray} />

          <XAxis dataKey='date' tickFormatter={monthTickFormatter} tickLine={false} tickMargin={10} />

          <YAxis {...commonYAxisProps(formattedMaxValue.length)} allowDecimals={false} tickFormatter={YAxisTickFormatter} />

          {displayDetectedLine && (
            <Line
              {...commonLineProps}
              activeDot={<circle fill={colors.failRed} r={4} stroke={colors.appBgColor} strokeWidth={2} />}
              dataKey='detectedFindings'
              stroke={colors.failRed}
              strokeWidth={3}
              {...(closestLineName === 'detectedFindings' ? {} : { activeDot: false })}

            />
          )}

          {displayResolvedLine && (
            <Line
              {...commonLineProps}
              activeDot={<circle fill={colors.performanceGreen} r={4} stroke={colors.appBgColor} strokeWidth={2} />}
              dataKey='resolvedFindings'
              stroke={colors.performanceGreen}
              strokeWidth={3}
              {...(closestLineName === 'resolvedFindings' ? {} : { activeDot: false })}
            />
          )}

          <Tooltip {...commonTooltipProps} content={<FindingsOverTimeTooltip closestLineName={closestLineName} />} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

