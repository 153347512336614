import { AssetType } from 'types/enums';
import { IAsset } from 'types/interfaces';

export const getAssetDisplayName = (asset: IAsset) => {
  const namesMap = {
    [AssetType.REPO]: `${asset.owner}/${asset.asset_name}`,
    [AssetType.ORG]: asset.asset_name,
    [AssetType.AWS_ACCOUNT]: asset.asset_name,
    [AssetType.AWS_ORG]: asset.asset_name,
    [AssetType.GCP_ACCOUNT]: asset.asset_name,
    [AssetType.AZURE_ACCOUNT]: asset.asset_name,
    [AssetType.API]: asset.asset_name,
    [AssetType.WEB]: asset.asset_name,
    [AssetType.APPLICATION]: asset.asset_name,
  };

  return namesMap[asset.asset_type] || asset.asset_name;
};
