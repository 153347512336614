import { styled } from '@mui/material/styles';

import colors from 'themes/colors.module.scss';

interface Props {
  backgroundColor?: string;
}

export const HoverBox = styled('div')<Props>(({ backgroundColor, color }) => ({
  '&:hover': {
    backgroundColor: backgroundColor || 'inherit',
    color: color || colors.blue02,
    '*': { color: color || colors.blue02 },
    svg: { fill: `${color || colors.blue02}!important` },
  },
  cursor: 'pointer',
}));
