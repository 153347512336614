import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';

import { useScoreWidget } from '../hooks/useScoreWidget';
import styles from '../ScoreChart.module.scss';
import { getIntervalAverage } from '../utils';

import { TooltipFormatter } from 'components/graphs/components/TooltipFormatter/TooltipFormatter';
import { JitLinearGradientChart } from 'components/graphs/LinearGradientChart/JitLinearGradientChart';
import { GraphEmpty, GraphLoading } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/components';
import { fetchFindingsMetric } from 'services/MetricService/MetricService';
import colors from 'themes/colors.module.scss';
import { TimeAgo } from 'types/enums';
import { Queries } from 'types/enums/Queries';
import { FixedIssuesMetric } from 'types/interfaces/Metrics/FindingsMetrics/FindingsMetrics';

type Props = {
  period: TimeAgo;
  breakdown: string;
};

export const SecurityAwareDevelopersWidget: FC<Props> = ({ period, breakdown }) => {
  const { data, isLoading, isError } = useQuery([Queries.FindingsCount, period, breakdown], () => fetchFindingsMetric<FixedIssuesMetric>({
    period,
    params: { breakdown },
  }));

  const processedData = useMemo(() => data?.data.days.map(({ date, fixed: { production, preProduction } }) => {
    const total = production + preProduction;
    const score = total ? Math.round((preProduction / (total)) * 100) : total;

    return ({
      date,
      value: score,
    });
  }) || [], [data?.data.days]);

  const weeklyData = useMemo(() => getIntervalAverage(processedData, 7), [processedData]);

  const { customXTickFormatter } = useScoreWidget(weeklyData);

  if (isLoading) return <GraphLoading />;
  if (isError || !weeklyData.length) return <GraphEmpty />;

  return (
    <div className={styles.gradientChartWrapper}>
      <JitLinearGradientChart
        CustomTooltip={TooltipFormatter}
        customXTickFormatter={customXTickFormatter}
        data={weeklyData}
        isValuePercentage
        singleColor={colors.successMain}
      />
    </div>
  );
};
