import { useCallback, useEffect } from 'react';

import { useConfigurationsContext } from '../ConfigurationsContext';

import { useAssetsContext } from 'context/AssetsContext/AssetsContext';
import { logError, logInfo } from 'services/logger/logger';
import { usePlanService } from 'services/PlanService/usePlanService';

export const useFetchConfigurations = () => {
  const { isCentralizedRepoAvailable } = useAssetsContext();
  const {
    setConfigurations, setConfigurationsCommit, setFetchedConfigurations, setIsLoadingConfigurations, fetchedConfigurations,
  } = useConfigurationsContext();
  const { getConfigurations } = usePlanService();

  const setDefaultConfigurations = useCallback((defaultConfigurations: { content: {}; sha: string; }) => {
    setConfigurations(defaultConfigurations.content);
    setFetchedConfigurations(defaultConfigurations.content);
    setConfigurationsCommit(defaultConfigurations.sha);
  }, [setConfigurations, setFetchedConfigurations, setConfigurationsCommit]);

  // eslint-disable-next-line max-statements
  const fetchConfigurations = useCallback(async () => {
    if (!isCentralizedRepoAvailable || fetchedConfigurations) return;

    setIsLoadingConfigurations(true);
    const defaultConfigurations = {
      content: {},
      sha: '',
    };

    try {
      const response = await getConfigurations();
      logInfo('Configuration file fetched');
      if (response?.status && response.status <= 299) {
        setConfigurations(response?.data?.content || defaultConfigurations.content);
        setFetchedConfigurations(response?.data?.content || defaultConfigurations.content);
        setConfigurationsCommit(response?.data?.sha || defaultConfigurations.sha);
      } else if (response?.status && response.status === 400) {
        logInfo(`Could not fetch configuration file: ${response.data}`);
        setDefaultConfigurations(defaultConfigurations);
      } else if (response?.status && response.status === 404) {
        logInfo('No configuration file found');
        setDefaultConfigurations(defaultConfigurations);
      } else if (response?.status && response.status === 422) {
        logInfo('Configuration file is malformed');
        setDefaultConfigurations(defaultConfigurations);
      } else {
        logError('Fetched configuration with invalid status code');
        setDefaultConfigurations(defaultConfigurations);
      }
    } catch (error) {
      logError(`Error while fetching configuration file ${error}`);
    } finally {
      setIsLoadingConfigurations(false);
    }
  }, [isCentralizedRepoAvailable, fetchedConfigurations, setIsLoadingConfigurations, getConfigurations, setConfigurations,
    setFetchedConfigurations, setConfigurationsCommit, setDefaultConfigurations]);

  useEffect(() => {
    fetchConfigurations();
  }, [fetchConfigurations]);
};
