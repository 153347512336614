import { FC, ReactElement } from 'react';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  itemContent: string | ReactElement;
}

export const ItemContent: FC<Props> = ({ itemContent }) => {
  if (typeof itemContent === 'string') return <JitText color={colors.white} size='s' style={{ alignSelf: 'center' }} text={itemContent as string} />;
  return itemContent;
};
