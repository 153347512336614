export const formatMoneyToDisplayText = (money: number) => {
  if (money >= 1000000) {
    const millions = Math.round(money / 1000000);
    return `${millions}M`;
  }
  if (money >= 1000) {
    const thousands = Math.round(money / 1000);
    return `${thousands}K`;
  }
  return money.toString();
};
