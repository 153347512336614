import { FC, useState } from 'react';

import { ImportFileButton } from '../../../ImportFileButton/ImportFileButton';

import styles from './TeamsSyncGradient.module.scss';

import { JitTeams } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { TableGradient } from 'components/JitTable/components/TableGradient/TableGradient';
import { JitText } from 'components/JitText/JitText';
import { useTenantContext } from 'context/TenantContext';
import { useTenantService } from 'services/TenantService/useTenantService';
import { PreferencesScope } from 'types/enums/PreferencesScope';
import { TeamsSyncPreferences, TeamsSyncType } from 'types/interfaces';

interface Props {
  teamsSource: string;
}

export const TeamsSyncGradient: FC<Props> = ({ teamsSource }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { preferences, setPreferences } = useTenantContext();
  const { patchPreferences } = useTenantService();

  const onExternalSyncButtonClick = async () => {
    setIsLoading(true);
    const response = await patchPreferences<TeamsSyncPreferences>(
      'teams_sync',
      {
        sync_type: TeamsSyncType.AUTO,
        provider: teamsSource,
      },
      PreferencesScope.TENANT,
    );

    if (response && preferences) {
      setPreferences({
        ...preferences,
        teams_sync: response.data,
      });
    }

    setIsLoading(false);
  };

  return (
    <TableGradient>
      <div className={styles.content}>

        <div className={styles.title}>
          <JitText bold size='l' text='pages.teams.teamsTable.syncGradient.title' />

          <JitIcon data-testid='jit-teams-icon' icon={JitTeams} width={70} />
        </div>

        <JitText
          align='center'
          text='pages.teams.teamsTable.syncGradient.description'
        />

        <div className={styles.buttons}>
          <JitButton
            className={styles.button}
            data-testid='github-teams-button'
            isLoading={isLoading}
            onClick={onExternalSyncButtonClick}
            showChildrenWhileLoading
            variant='contained'
          >
            <JitText text='pages.teams.teamsTable.syncGradient.externalSyncButton' />
          </JitButton>

          <ImportFileButton />
        </div>
      </div>
    </TableGradient>
  );
};
