import { isEmpty } from 'lodash';
import { FC, useState } from 'react';

import { useGetFindingsTableColumns } from '../../../hooks/useGetFindingsTableColumns';
import { getGroupedFindings } from '../../utils/groupFindingsUtils';
import { PipelineFindingDetails } from '../PipelineFindingsDetails/PipelineFindingDetails';

import styles from './PipelineFindingsTable.module.scss';

import { useGetTableInstance } from 'components/JitTable/hooks/useGetTableInstance';
import { JitTable } from 'components/JitTable/JitTable';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { IFindingServer } from 'types/interfaces';
import { IPipelineNew } from 'types/interfaces/Pipelines/IPipelineNew';

interface Props {
  findings: IFindingServer[];
  selectedPipeline: IPipelineNew;
  isFetchingFindings: boolean;
}

export const PipelineFindingsTable: FC<Props> = ({ findings, selectedPipeline, isFetchingFindings }) => {
  const { columnsData } = useGetFindingsTableColumns();
  const tableInstance = useGetTableInstance(columnsData.columns, findings);
  const [activeFinding, setActiveFinding] = useState<IFindingServer>();

  const numOfFindings = !isFetchingFindings && `(${findings.length})`;

  const onSelectItem = (item: IFindingServer | undefined) => {
    if (item && item !== activeFinding) {
      setActiveFinding(item);
    } else if (item && item === activeFinding) {
      setActiveFinding(undefined);
    }
  };

  const handleBack = () => {
    setActiveFinding(undefined);
  };

  return (
    <>
      <JitText
        bold
        className={styles.numOfFindingsText}
        color={colors.lightGray}
        data-testid='pipelinesFindingsHeader'
        size='m'
        text={`Findings ${numOfFindings || ''}`}
      />

      <div className={styles.displayFlex}>
        <div className={`${styles.withTransition} ${activeFinding ? (styles.sidePanelIsExpanded) : (styles.sidePanelIsNotExpanded)}`}>
          <JitTable
            cellsNumber={columnsData.columns.length}
            cellVerticalAlign='center'
            className={`${styles.tablePaddingBottom} ${activeFinding ? (styles.straightRightBorders) : ('')}`}
            emptyTableSubtext='pages.pipelineDetails.findingsTable.noData.subText'
            emptyTableText='pages.pipelineDetails.findingsTable.noData.mainText'
            entityName='findings'
            groupRowsBy={(page) => (!isEmpty(selectedPipeline) ? getGroupedFindings(selectedPipeline.workflows, page) : undefined)}
            isLoading={isFetchingFindings}
            jittyIcon={null}
            onSelectRow={onSelectItem}
            selectedRow={activeFinding}
            showPagination={false}
            tableInstance={tableInstance}
          />
        </div>

        <div className={`${styles.withTransition} ${activeFinding ? (styles.tableIsNotExpanded) : (styles.tableIsExpanded)}`}>
          <div className={styles.findingDetailsHeader}>
            {activeFinding && (
              <PipelineFindingDetails finding={activeFinding} handleBack={handleBack} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
