import { AxiosResponse } from 'axios';
import { useCallback } from 'react';

import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import { IStiggFeatures } from 'types/interfaces/Entitlements/IStiggFeatures';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

export const useEntitlementService = () => {
  const { client } = useClient();

  const getFeatures = useCallback(async (): Promise<AxiosResponse | undefined> => {
    const url = getApiUrls.entitlementService.getFeatures();

    const response = await client.get<IStiggFeatures>({
      url,
      allowedStatuses: [200],
    });

    if (response?.status === 200) {
      response.data = camelizeSnakeCaseKeys(response.data) as IStiggFeatures;
    }

    return response;
  }, [client]);

  return {
    getFeatures,
  };
};
