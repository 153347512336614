import { FC } from 'react';

import styles from './DeploymentsStatistics.module.scss';

import { JitText } from 'components/JitText/JitText';
import { i18n } from 'locale/i18n';
import { StatisticsWidget } from 'pages/InsightsPage/components/StatisticsWidget/StatisticsWidget';
import { getAllFindingsTypesOfDeployment } from 'pages/InsightsPage/SubPages/DeploymentsPage/utils/getAllFindingsTypesOfDeployment';
import { IPipelineNew } from 'types/interfaces';

interface DeploymentsStatisticsProps {
  environment: string
  deployments?: IPipelineNew[]
}

export const DeploymentsStatistics: FC<DeploymentsStatisticsProps> = ({ environment, deployments }) => {
  const failedDeployments = deployments?.filter((deployment) => (
    getAllFindingsTypesOfDeployment(deployment).newFindings > 0
  ));

  const passedDeployments = deployments?.filter((deployment) => (
    getAllFindingsTypesOfDeployment(deployment).newFindings === 0
  ));

  const failedTooltipTitle = i18n.t('pages.deployments.statistics.tooltips.failedDeploymentsTooltip', { failedDeploymentsAmount: failedDeployments?.length });
  const passedTooltipTitle = i18n.t('pages.deployments.statistics.tooltips.passedDeploymentsTooltip', { passedDeploymentsAmount: passedDeployments?.length });
  const totalTooltipTitle = i18n.t('pages.deployments.statistics.tooltips.totalReviewedDeploymentsTooltip', { totalAmount: deployments?.length });

  const getHeader = () => (
    <div className={styles.headerWrapper}>
      <JitText data-testid='title' size='s' text='pages.deployments.statistics.header.title' />

      <div className={styles.environmentTitle}>
        <JitText data-testid='environmentTitle' text='pages.deployments.statistics.header.environmentTitle' />

        <JitText bold data-testid='environmentName' style={{ marginLeft: 8 }} text={environment} />
      </div>
    </div>
  );

  return (
    <StatisticsWidget
      failedAmount={failedDeployments?.length}
      failedTitle='pages.deployments.statisticsWidget.failedPrs'
      failedTooltipTitle={failedTooltipTitle}
      header={getHeader()}
      passedAmount={passedDeployments?.length}
      passedTitle='pages.deployments.statisticsWidget.passedPrs'
      passedTooltipTitle={passedTooltipTitle}
      totalAmount={deployments?.length}
      totalTooltipTitle={totalTooltipTitle}
    />
  );
};

