import { isEmpty } from 'lodash';
import { Dispatch, SetStateAction, useCallback } from 'react';

import { ConvertPipelineResponseIntoIPipelineNew } from 'pages/PipelinesPage/utils';
import { shouldShowPipeline } from 'pages/PipelinesPage/utils/utilsNew';
import { IFilter, IWebsocketNotification } from 'types/interfaces';
import { IPipelineNew, IPipelineStateNew, IResponsedPipelineNew } from 'types/interfaces/Pipelines/IPipelineNew';

interface Props {
  setPipelinesState: Dispatch<SetStateAction<IPipelineStateNew>>,
  filters: IFilter[],
  selectedPipeline?: IPipelineNew | null,
  setSelectedPipeline?: Dispatch<SetStateAction<IPipelineNew | null>>,
}

export const useHandlePipelinesWebSocketNotificationNew = ({ setPipelinesState, filters, selectedPipeline = null, setSelectedPipeline }: Props) => {
  const getFilteredPipelines = useCallback((pipelines: IPipelineNew[]) => pipelines.filter((pipeline) => shouldShowPipeline(filters, pipeline)), [filters]);

  const updateExistPipelines = (existingPipelines: IPipelineNew[], updated: IPipelineNew[]) => existingPipelines.map((pipeline) => {
    const foundPipeline = updated.find((updatedPipeline) => updatedPipeline.pipelineId === pipeline.pipelineId);
    if (foundPipeline) {
      return foundPipeline;
    }
    return pipeline;
  });

  const handleUpdateNonExistPipeline = (existingPipelines: IPipelineNew[], updatedPipeline: IPipelineNew) => {
    const isUpdatedPipelineCreatedTimeInList = existingPipelines.length > 0
      && existingPipelines[existingPipelines.length - 1].createdAt
      < updatedPipeline.createdAt;

    const shouldUpdatedPipelineBeInList = !isEmpty(existingPipelines) && isUpdatedPipelineCreatedTimeInList;

    if (shouldUpdatedPipelineBeInList) {
      const indexToPush = existingPipelines.findIndex((pipeline) => pipeline.createdAt < updatedPipeline.createdAt);
      existingPipelines.splice(indexToPush, 0, updatedPipeline);
    }
  };

  const updateNonExistPipelines = useCallback((existingPipelines: IPipelineNew[], updated: IPipelineNew[]) => {
    if (isEmpty(existingPipelines)) {
      return updated;
    }

    updated.forEach((updatedPipeline: IPipelineNew) => {
      const foundPipeline = existingPipelines.find((pipeline) => pipeline.pipelineId === updatedPipeline.pipelineId);
      if (!foundPipeline) {
        handleUpdateNonExistPipeline(existingPipelines, updatedPipeline);
      }
    });

    return existingPipelines;
  }, []);

  const getPipelinesAfterUpdated = useCallback((existingPipelines: IPipelineNew[], updated: IPipelineNew[]) => {
    const updatedExistPipelines = updateExistPipelines(existingPipelines, updated);

    const updatedPipelines = updateNonExistPipelines(updatedExistPipelines, updated);

    return getFilteredPipelines(updatedPipelines);
  }, [getFilteredPipelines, updateNonExistPipelines]);

  const getPipelinesAfterDeleted = (newPipelines: IPipelineNew[], deleted: IPipelineNew[]) => (
    newPipelines.filter((pipeline) => !deleted.find((deletedPipeline) => deletedPipeline.pipelineId === pipeline.pipelineId))
  );

  const handlePipelinesWebSocketNotification = useCallback((notification: IWebsocketNotification<IResponsedPipelineNew>) => {
    const { message: { created, updated, deleted } } = notification;

    const createdPipelines = created ? getFilteredPipelines(ConvertPipelineResponseIntoIPipelineNew(created)) : [];
    const updatedPipelines = updated ? ConvertPipelineResponseIntoIPipelineNew(updated) : [];
    const deletedPipelines = deleted ? ConvertPipelineResponseIntoIPipelineNew(deleted) : [];

    setPipelinesState((currPipelinesState: IPipelineStateNew) => {
      let newPipelines = [...(currPipelinesState.pipelines || [])];
      newPipelines = createdPipelines.length ? [...createdPipelines, ...newPipelines] : newPipelines;
      newPipelines = updatedPipelines.length ? getPipelinesAfterUpdated(newPipelines, updatedPipelines) : newPipelines;
      newPipelines = deletedPipelines.length ? getPipelinesAfterDeleted(newPipelines, deletedPipelines) : newPipelines;
      return {
        ...currPipelinesState,
        pipelines: newPipelines,
      };
    });

    const updatedSelectedPipeline = selectedPipeline
      && updatedPipelines.find((deletedPipeline) => deletedPipeline.pipelineId === selectedPipeline.pipelineId);
    if (updatedSelectedPipeline && setSelectedPipeline) {
      setSelectedPipeline(updatedSelectedPipeline);
    }
  }, [getFilteredPipelines, getPipelinesAfterUpdated, setPipelinesState, selectedPipeline, setSelectedPipeline]);

  return { handlePipelinesWebSocketNotification };
};
