import { FC } from 'react';

import { isoFormatToDateFormat } from '../../utils';

import { JitText } from 'components/JitText/JitText';
import { ITableCell } from 'types/interfaces';

export const FirstDetectedCell: FC<ITableCell> = ({ cell: { value } }) => {
  const format = isoFormatToDateFormat(value.firstDetected);
  return <JitText size='m' text={format} />;
};
