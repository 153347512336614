import { Dispatch, SetStateAction, useMemo } from 'react';

import { useGetActivateJitAction } from '../useGetActivateJitAction/useGetActivateJitAction';
import { useGetExploreButton } from '../useGetExploreButton/useGetExploreButton';
import { useGetNavigateButton } from '../useGetNavigateButton/useGetNavigateButton';

import { useGetConfigureSlackAction } from 'pages/TeamsPortal/GuidePage/components/actionComponents/useGetConfigureSlackButton/useGetConfigureSlackAction';
import { ITeam, ITeamCheckDetails, TaskKeyEnum } from 'types/interfaces/Teams/ITeam';

export const useGetActionComponents = (
  team: ITeam | undefined,
  checksMap: { [key: string]: ITeamCheckDetails } | undefined,
  setOpenedDialogKey: Dispatch<SetStateAction<string | null>>,
  updateCheck: (check: ITeamCheckDetails, value?: string) => void,
) => {
  const { actionButton: activateTeam } = useGetActivateJitAction(
    setOpenedDialogKey,
    checksMap?.[TaskKeyEnum.prCheckEnablement],
  );
  const { actionButton: configureSlack } = useGetConfigureSlackAction(
    checksMap?.[TaskKeyEnum.configureSlack],
    setOpenedDialogKey,
  );

  const { actionButton: navigate } = useGetNavigateButton(
    team?.id || '',
    checksMap?.[TaskKeyEnum.resources]?.options && checksMap?.[TaskKeyEnum.resources]?.options?.button,
    updateCheck,
  );
  const { actionButton: explore } = useGetExploreButton(
    checksMap?.[TaskKeyEnum.exploreDeveloper],
    setOpenedDialogKey,
  );
  const actionComponentMap = useMemo(() => ({
    activateTeam,
    configureSlack,
    navigate,
    explore,
  }), [activateTeam, configureSlack, explore, navigate]);
  return (actionComponentMap);
};
