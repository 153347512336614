import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC } from 'react';

import styles from './PlanItemDetailsSupportedLanguagesHeader.module.scss';

import { JitText } from 'components/JitText/JitText';

interface Props {
  hasSupportedLanguages: boolean;
}

export const PlanItemDetailsSupportedLanguagesHeader: FC<Props> = ({ hasSupportedLanguages }) => (
  <TableHead className={styles.head}>
    <TableRow>

      <TableCell>
        <JitText size='s' text='pages.plan.itemDetails.securityTool' />
      </TableCell>

      {hasSupportedLanguages && (
        <TableCell>
          <JitText size='s' text='pages.plan.itemDetails.language' />
        </TableCell>
      )}

      <TableCell />
    </TableRow>
  </TableHead>
);

