import { FC } from 'react';

import styles from './SnapshotNotFound.module.scss';

import { JittyNotFound } from 'assets';
import { JitEmpty } from 'components/JitEmpty/JitEmpty';

export const SnapshotNotFound: FC = () => (
  <div className={styles.root}>
    <JitEmpty
      description='pages.performance.shareSnapshot.notFound.description'
      icon={JittyNotFound}
      title='pages.performance.shareSnapshot.notFound.title'
    />
  </div>
);
