/* eslint-disable no-nested-ternary */
import { useAuthActions, useAuthUser } from '@frontegg/react';
import { configureScope } from '@sentry/react';
import isEmpty from 'lodash/isEmpty';
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useHandleRedirectIfNeeded } from './HandleRedirect/useHandleRedirectIfNeeded';

import { AppLoadingBar } from 'components/AppLoadingBar/AppLoadingBar';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { AuthContext } from 'context/AuthContext/AuthContext';
import { constants } from 'globalConstants';
import { setClientsHeaders } from 'services/client';
import { signUp } from 'services/TenantService';
import { IFronteggUser } from 'types/interfaces';
import { useOnboardingState } from 'utils/hooks/useOnboardingState';
import { PERMISSIONS } from 'wrappers/RBAC/constants';

export const POLLING_GRACE_PERIOD = 1000 * 15; // 15 seconds

interface AuthProviderProps {
  children: ReactNode;
  isFrontEggLoading: boolean;
}

export const AuthProvider: FC<AuthProviderProps> = ({
  children,
  isFrontEggLoading,
}) => {
  const [isWaitingForSwitchTenant, setIsWaitingForSwitchTenant] = useState(true);
  const [isWaitingForRoleUpdate, setIsWaitingForRoleUpdate] = useState(true);

  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const frontEggUser: IFronteggUser = useAuthUser();
  const { switchTenant } = useAuthActions();
  const navigate = useNavigate();
  const { setOnboardingState } = useOnboardingState();
  const queryClient = useQueryClient();
  const fronteggUserMetadata = useMemo(() => JSON.parse(frontEggUser?.metadata || '{}'), [frontEggUser.metadata]);
  const [viewedWelcomeTeamsPage, setViewedWelcomeTeamsPage] = useState<boolean>(fronteggUserMetadata?.viewed_welcome_teams_page?.[frontEggUser.tenantId]);

  useEffect(() => () => {
    sessionStorage.removeItem(constants.IS_SIGNUP_KEY);
  }, []);

  useEffect(() => {
    configureScope((scope) => {
      scope.setUser({
        userId: frontEggUser.id,
        email: frontEggUser.email,
        tenantId: frontEggUser.tenantId,
      });
    });
  }, [frontEggUser]);

  // permissions might be undefined even though it is always defined in the interface (Frontegg package issue)
  const fronteggUserPermissions = useMemo(() => {
    if (!frontEggUser.permissions) {
      return [];
    }

    return frontEggUser.permissions;
  }, [frontEggUser.permissions]);

  const userPermissions = useMemo(() => fronteggUserPermissions.map(({ key }) => key), [fronteggUserPermissions]);
  const userHasAccessToManagementPortal = useMemo(() => userPermissions.includes(PERMISSIONS.MANAGEMENT_PLATFORM), [userPermissions]);
  const isAuthorized = useMemo(() => !isEmpty(frontEggUser.roles), [frontEggUser.roles]);

  const shouldSignup = useMemo(() => sessionStorage.getItem(constants.SHOULD_SIGNUP_KEY), []);
  const isSignup = useMemo(() => sessionStorage.getItem(constants.IS_SIGNUP_KEY), []);

  const shouldShowLoader = useMemo(
    () => !isAuthorized && (isWaitingForRoleUpdate || isWaitingForSwitchTenant),
    [isAuthorized, isWaitingForRoleUpdate, isWaitingForSwitchTenant],
  );

  setClientsHeaders({ authorization: `Bearer ${frontEggUser.accessToken}` });

  const handleLogout = useCallback(() => {
    localStorage.clear();
    queryClient.removeQueries();
    navigate('/logout');
    sendAnalyticsEvent({ action: 'logout' });
  }, [navigate, queryClient, sendAnalyticsEvent]);

  useHandleRedirectIfNeeded({
    ...frontEggUser,
    isAuthorized,
  });

  const hasPermission = useCallback(
    (permissionKey: string) => frontEggUser.permissions.some(({ key }) => key === permissionKey),
    [frontEggUser.permissions],
  );

  const handleSignUp = useCallback(async () => {
    const invitationToken = sessionStorage.getItem(constants.INVITATION_TOKEN_KEY);

    if (invitationToken) {
      const stateToken = sessionStorage.getItem(constants.STATE_TOKEN_KEY) || undefined;

      sessionStorage.removeItem(constants.INVITATION_TOKEN_KEY);
      sessionStorage.removeItem(constants.STATE_TOKEN_KEY);

      return signUp({
        user: frontEggUser,
        invitationToken,
        state: stateToken,
      });
    }

    return signUp({ user: frontEggUser });
  }, [frontEggUser]);

  const signUpWithSessionStorageCleanup = useCallback(async () => {
    sessionStorage.removeItem(constants.SHOULD_SIGNUP_KEY);

    const result = await handleSignUp();
    if (result) {
      const {
        onboarding_status: status,
        onboarding_details: details,
        tenant_id: tenantId,
        role,
      } = result;
      setOnboardingState({
        status,
        details,
      });
      const isDifferentTenant = tenantId !== frontEggUser.tenantId;
      const isDifferentRoles = !!role && !frontEggUser.roles.some(({ key }) => key === role);
      const shouldSwitchTenant = isDifferentTenant || isDifferentRoles;

      if (shouldSwitchTenant) {
        switchTenant({ tenantId });
      }
    }
    setIsWaitingForSwitchTenant(false);
    setIsWaitingForRoleUpdate(false);
  }, [frontEggUser.roles, frontEggUser.tenantId, handleSignUp, setOnboardingState, switchTenant]);

  useEffect(() => {
    if (shouldSignup) {
      signUpWithSessionStorageCleanup();
    } else if (!isSignup) {
      setIsWaitingForSwitchTenant(false);
    }
  }, [isSignup, shouldSignup, setIsWaitingForSwitchTenant, signUpWithSessionStorageCleanup]);

  useEffect(() => {
    if (!isAuthorized) {
      setTimeout(() => {
        setIsWaitingForRoleUpdate(false);
      }, POLLING_GRACE_PERIOD);
    }
  }, [isAuthorized, shouldSignup, setIsWaitingForRoleUpdate]);

  const value = useMemo(() => ({
    frontEggUser,
    handleLogout,
    hasPermission,
    viewedWelcomeTeamsPage,
    setViewedWelcomeTeamsPage,
    userPermissions,
    userHasAccessToManagementPortal,
  }), [frontEggUser, handleLogout, hasPermission, viewedWelcomeTeamsPage, userPermissions, userHasAccessToManagementPortal]);

  if (isFrontEggLoading) {
    return <AppLoadingBar />;
  }

  return (
    <AuthContext.Provider value={value}>
      {isEmpty(frontEggUser) ? null
        : shouldShowLoader ? <AppLoadingBar />
          : children}
    </AuthContext.Provider>
  );
};
