import { logInfo, logWarning } from 'services/logger/logger';
import { validateApiFile } from 'services/ValidationService/ValidationService';

export const validateZapApiFile = async (apiFile: string): Promise<{ success: boolean, message: string }> => {
  if (!apiFile) {
    return {
      success: true,
      message: 'configurations.zap.validation.missingFields',
    };
  }

  const result = await validateApiFile(apiFile);
  if (!result) {
    logWarning('API File Validation Failed');
    return {
      success: false,
      message: 'configurations.zap.validation.apiFileValidationFailed',
    };
  }
  logInfo('API File validation succeeded', { result });
  return {
    success: true,
    message: result.message,
  };
};
