import { createContext, Dispatch, SetStateAction, useContext } from 'react';

import { defaultSortColumns } from 'context/FindingsContext/constants';
import {
  IFinding,
  IFindingFilter,
  IFindingServer,
  IFindingsStatistics,
  ISortColumns,
  ISortColumn,
} from 'types/interfaces';
import { ISavedFilter } from 'types/interfaces/Findings/ISavedFilter';

interface IFindingsContext {
  findings: IFinding[];
  getFindings: (paginationKey: string | undefined, activeColumn: ISortColumn) => Promise<string | undefined>;
  setFindings: Dispatch<SetStateAction<IFinding[]>>;
  isFetchingCSV: boolean;
  findingsStatistics?: IFindingsStatistics;
  isLoadingStatistics: boolean;
  getFindingsStatistics: (isSilentFetch: boolean, requestFilters: IFindingFilter[]) => Promise<void>;
  getCsvFile: (requestedFilters: IFindingFilter[]) => Promise<string | undefined>;
  fetchedFindings: (findingIds: string[]) => Promise<IFindingServer[] | undefined>;
  savedFilters: ISavedFilter[];
  editSavedFilter: (savedFilter: ISavedFilter) => void;
  createSavedFilter: (savedFilter: ISavedFilter) => void;
  removeSavedFilter: (savedFilter: ISavedFilter) => void;
  filters: IFindingFilter[];
  setFilters: Dispatch<SetStateAction<IFindingFilter[]>>;
  setSavedFilterAsDefault: (savedFilter: ISavedFilter) => void;
  selectSavedFilter: (savedFilter: ISavedFilter) => void;
  selectedFilter: ISavedFilter | undefined;
  filtersVisibleInFirstLine: IFindingFilter[];
  setFiltersVisibleInFirstLine: (filters: IFindingFilter[]) => void;
  getSavedFilters: (shouldApplyDefault: boolean) => void;
  sortColumns: ISortColumns;
  setSortColumns: Dispatch<SetStateAction<ISortColumns>>;
  totalFindingsAmount: number | undefined;
  getTotalFindingsAmount: () => Promise<void>;
  setTotalFindingsAmount: Dispatch<SetStateAction<number | undefined>>;
  getColumn: (columnKey: string) => ISortColumn | undefined;
  currentActiveColumn: ISortColumn;
  toggleColumn: (columnKey: string) => void;
  hasFetchedFilters: boolean;
  setHasFetchedFilters: (hasFetchedFindings: boolean) => void;
}

export const FindingsContext = createContext<IFindingsContext>({
  setTotalFindingsAmount: () => undefined,
  sortColumns: defaultSortColumns,
  setSortColumns: () => undefined,
  getFindings: () => Promise.resolve(undefined),
  setFindings: () => undefined,
  findings: [],
  isFetchingCSV: false,
  isLoadingStatistics: false,
  getFindingsStatistics: () => Promise.resolve(),
  getCsvFile: async () => undefined,
  fetchedFindings: async () => undefined,
  savedFilters: [],
  editSavedFilter: () => undefined,
  createSavedFilter: () => undefined,
  removeSavedFilter: () => undefined,
  setSavedFilterAsDefault: () => undefined,
  selectSavedFilter: () => undefined,
  selectedFilter: undefined,
  filters: [],
  setFilters: () => undefined,
  filtersVisibleInFirstLine: [],
  setFiltersVisibleInFirstLine: () => undefined,
  getSavedFilters: () => undefined,
  getTotalFindingsAmount: () => Promise.resolve(),
  totalFindingsAmount: undefined,
  getColumn: () => undefined,
  currentActiveColumn: defaultSortColumns.columns[0],
  toggleColumn: () => undefined,
  hasFetchedFilters: false,
  setHasFetchedFilters: () => undefined,
});

export const useFindingsContext = () => useContext(FindingsContext);
