import { FC } from 'react';

import styles from './LogDialogError.module.scss';

import { CircleError } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';

export const LogDialogError: FC = () => (
  <div
    className={styles.errorContainerWrapper}
    data-testid='log-dialog-error'
  >
    <div className={styles.errorBoxWrapper}>
      <div className={styles.errorHeaderWrapper}>
        <JitIcon data-testid='circle-error-icon' icon={CircleError} size={25} />

        <JitText
          bold
          size='l'
          text='dialogs.executionLog.errorTitle'
        />
      </div>

      <div className={styles.errorBodyWrapper}>
        <JitText text='dialogs.executionLog.errorText' />
      </div>
    </div>
  </div>
);
