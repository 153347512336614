import { FC } from 'react';

import { JitGraph } from 'components/JitGraph/JitGraph';
import { edgeTypes, getLayoutedElements, nodeTypes } from 'pages/WorkflowsPage/EditWorkflowPage/components/WorkflowGraph/visualUtils';
import { translateWorkflowToGraph } from 'pages/WorkflowsPage/EditWorkflowPage/utils/translateWorkflowToGraph';
import colors from 'themes/colors.module.scss';
import { IWorkflow } from 'types/interfaces/Workflows/IWorkflow';

interface Props {
  workflow: IWorkflow;
}

export const WorkflowGraph: FC<Props> = ({ workflow }) => {
  const { nodes, edges } = translateWorkflowToGraph(workflow);
  return (
    <JitGraph backgroundColor={colors.appBgColor} edges={edges} edgeTypes={edgeTypes} getLayoutedElements={getLayoutedElements} isLoading={false} nodes={nodes} nodeTypes={nodeTypes} />
  );
};
