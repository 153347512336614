import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { usePageViewedEvent } from 'context/AnalyticsContext/hooks/usePageViewedEvent';
import { constants } from 'globalConstants';
import { ThirdPartyAuthentication } from 'pages/ThirdPartyAuthentication/ThirdPartyAuthentication';
import { useRoutesOfUser } from 'wrappers/RBAC/hooks/useRoutesOfUser';

export const Router: FC = () => {
  usePageViewedEvent();
  const { routesToRender } = useRoutesOfUser();
  const { THIRD_PARTY_AUTHENTICATION } = constants.routes;

  return (
    <Routes>
      {/* The ThirdPartyAuthentication is not in the Layout scope since the navbar is not wanted here */}

      <Route key={THIRD_PARTY_AUTHENTICATION} element={<ThirdPartyAuthentication />} path={`/${THIRD_PARTY_AUTHENTICATION}`} />

      {routesToRender}

    </Routes>
  );
};
