import DialogActions from '@mui/material/DialogActions';
import { FC } from 'react';

import styles from './AssetsManagementButtons.module.scss';

import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import { ActionButtonVariant } from 'types/interfaces';

interface Props {
  handleClose: () => void;
  handleSave: () => void;

  CustomLowerBarLeftComponent?: FC

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  extraDetails?: {
    [key: string]: any
  }
}

export const AssetsManagementButtons: FC<Props> = ({
  handleSave,
  handleClose,
  extraDetails,
  CustomLowerBarLeftComponent,
}) => {
  const { isAnyScmVendorIntegrated } = useTenantContext();
  return (
    <DialogActions className={styles.actions}>
      {
        !!CustomLowerBarLeftComponent
        && (
          <div
            className={styles.customLeftComponent}
          >
            <CustomLowerBarLeftComponent
              {...extraDetails}
            />
          </div>
        )
      }

      <JitActionButton
        actionButton={{
          variant: ActionButtonVariant.OUTLINED,
          label: 'dialogs.assetsManagement.cancel',
          handleClick: handleClose,
          analytics: { action: 'clicked-closed-manage-repos' },
        }}
      />

      <JitActionButton
        actionButton={{
          variant: ActionButtonVariant.PRIMARY,
          label: 'dialogs.assetsManagement.update',
          handleClick: handleSave,
          analytics: { action: 'clicked-save-manage-repos' },
          disabled: !isAnyScmVendorIntegrated,
        }}
      />

    </DialogActions>
  );
};
