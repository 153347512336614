import { isEmpty } from 'lodash';

import { IFilter, IFilterOption, IFilterType } from 'types/interfaces';

export const isFiltersApplied = (filters: IFilter[]) => filters.some((filter) => {
  if (filter.type === IFilterType.MULTI_SELECT) {
    return !isEmpty(filter.selectedValue);
  }

  const selectedValue = filter?.selectedValue;

  return (selectedValue && !isEmpty((filter.selectedValue as IFilterOption).value));
});
