import { FC } from 'react';

import { formatToWrittenDate } from '../../utils';

import { JitText } from 'components/JitText/JitText';
import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { IJitUsageFirstDate } from 'types/interfaces/Metrics/SecurityImpactMetrics/JitUsageFirstDate';

interface Props {
  firstDateJitUsage: MetricWithLoadingIndication<IJitUsageFirstDate>
}

export const SecurityImpactSubtitle: FC<Props> = ({ firstDateJitUsage }) => {
  if (firstDateJitUsage.isLoading || !firstDateJitUsage.metric) {
    return null;
  }

  const formattedFirstDate = formatToWrittenDate(new Date(firstDateJitUsage.metric.date));
  const formattedCurrentDate = formatToWrittenDate(new Date());

  return (
    <JitText
      components={{
        dates: (
          <JitText bold display='inline' size='l' text={`${formattedFirstDate} - ${formattedCurrentDate}`} />
        ),
      }}
      data-testid='SecurityImpactSubtitle'
      size='l'
      text='pages.securityImpact.subTitle'
    />
  );
};
