import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { CardStatusToBorderColor, StatusIndicationIconProps } from '../AWSIntegrationCard/constants';

import { JitCard } from 'components/JitCard/JitCard';
import { SlackDisconnectDialog } from 'components/JitDialogs/SlackDisconnectDialog/SlackDisconnectDialog';
import { SlackIntegrationDialog, SlackIntegrationDialogStage } from 'components/JitDialogs/SlackIntegrationDialog/SlackIntegrationDialog';
import { CustomIntegrationCardDefaultProps } from 'context/IntegrationsContext/templates/interfaces';
import { useSlackContext } from 'context/SlackContext';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import { CONFIGURE_QUERY_PARAM } from 'pages/IntegrationsPage/constants';
import { useFetchIntegrations } from 'pages/IntegrationsPage/hooks/useFetchIntegrations';
import {
  getCurrentInstallationSortedByStatus,
  getSlackRedirectUri,
  SlackRedirectParams,
  groupInstallationsByVendor,
  isVendorInstalled,
} from 'pages/IntegrationsPage/utils';
import { Vendor } from 'types/enums';
import { IntegrationStatus } from 'types/interfaces';
import { ActionButton, ActionButtonVariant } from 'types/interfaces/ActionButton/ActionButton';

// eslint-disable-next-line max-statements
export const SlackIntegrationCard: FC<CustomIntegrationCardDefaultProps> = ({ title, icon, tags }) => {
  const { installations, isAnyScmVendorIntegrated } = useTenantContext();
  const { integrations } = useFetchIntegrations();
  const { isBeingUninstalled } = useSlackContext();
  const [showSlackDisconnectDialog, setShowSlackDisconnectDialog] = useState(false);
  const [showSlackConfigureDialog, setShowSlackConfigureDialog] = useState(false);
  const { search } = useLocation();

  const installationsByVendor = groupInstallationsByVendor(installations);
  const isSlackConnected = isVendorInstalled(Vendor.SLACK, installations);
  const slackInstallation = getCurrentInstallationSortedByStatus(Vendor.SLACK, installationsByVendor);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    setShowSlackConfigureDialog(queryParams.get(CONFIGURE_QUERY_PARAM) === Vendor.SLACK && isSlackConnected);
  }, [isSlackConnected, search]);

  const validateSlackNewScopes = () => {
    // temporary solution to distinguish the users that have the new scopes
    const newScopesDate = new Date('2022-09-01'); // the date of the time that new scopes added to slack
    if (!slackInstallation) {
      return false;
    }
    const createdAt = new Date(slackInstallation.created_at);
    return createdAt >= newScopesDate;
  };
  const isSlackConfigurable = isSlackConnected && !isBeingUninstalled && validateSlackNewScopes();
  const slackIntegration = integrations.find((integration) => integration.vendor === Vendor.SLACK);

  const slackInstallRedirectUri = useMemo(() => {
    const currentHrefWithoutQueryParams = window.location.href.split('?')[0];

    const slackRedirectParams: SlackRedirectParams = {
      httpRedirect: `${currentHrefWithoutQueryParams}?${CONFIGURE_QUERY_PARAM}=${Vendor.SLACK}`,
    };
    return getSlackRedirectUri(slackIntegration?.installation_path, slackRedirectParams);
  }, [slackIntegration?.installation_path]);

  const actionButtonTooltip: string | undefined = useMemo(() => (!isAnyScmVendorIntegrated
    ? 'cards.tooltip.requiresSCMIntegration'
    : undefined), [isAnyScmVendorIntegrated]);

  const connectButton: ActionButton = {
    label: 'cards.buttons.connect',
    handleClick: () => {
      window.open(slackInstallRedirectUri, '_self');
    },
    disabled: !isAnyScmVendorIntegrated || isBeingUninstalled,
    tooltip: actionButtonTooltip,
  };

  const disconnectingButton: ActionButton = {
    label: 'cards.buttons.disconnecting',
    handleClick: () => {},
    isLoading: true,
    disabled: true,
    shouldShowChildrenWhileLoading: true,
  };

  const connectingButton: ActionButton = {
    label: 'cards.buttons.connecting',
    handleClick: () => {},
    isLoading: true,
    disabled: true,
    shouldShowChildrenWhileLoading: true,
  };

  const ConfigureButton: ActionButton = {
    label: 'cards.buttons.configure',
    handleClick: () => {
      if (isSlackConfigurable) {
        setShowSlackConfigureDialog(true);
      }
    },
    disabled: !isAnyScmVendorIntegrated,
    tooltip: actionButtonTooltip,
    variant: ActionButtonVariant.OUTLINED,
  };

  const DisconnectButton: ActionButton = {
    label: 'cards.buttons.disconnect',
    handleClick: () => {
      setShowSlackDisconnectDialog(true);
    },
    disabled: !isAnyScmVendorIntegrated,
    tooltip: actionButtonTooltip,
    variant: ActionButtonVariant.SECONDARY_DANGER,
  };

  const statusToActionButtons = {
    [IntegrationStatus.DISCONNECTED]: [connectButton],
    [IntegrationStatus.DISCONNECTING]: [disconnectingButton],
    [IntegrationStatus.CONNECTING]: [connectingButton],
    [IntegrationStatus.CONNECTED]: [ConfigureButton, DisconnectButton],
    [IntegrationStatus.ERROR]: [ConfigureButton, DisconnectButton],
    [IntegrationStatus.WARNING]: [ConfigureButton, DisconnectButton],
    [IntegrationStatus.PENDING]: [ConfigureButton],
  };

  const statusIcon = StatusIndicationIconProps?.[slackInstallation?.status as keyof typeof StatusIndicationIconProps];
  const actionButtons: ActionButton[] = statusToActionButtons[slackInstallation?.status as keyof typeof StatusIndicationIconProps] || [connectButton];
  const borderColor = CardStatusToBorderColor?.[slackInstallation?.status as keyof typeof CardStatusToBorderColor];
  const headerIcons = { icons: statusIcon ? [{ icon }, { icon: statusIcon }] : [{ icon }] };

  return (
    <>
      <SlackIntegrationDialog
        isOpen={showSlackConfigureDialog}
        onClose={() => setShowSlackConfigureDialog(false)}
        stage={{ type: SlackIntegrationDialogStage.TENANT }}
      />

      <SlackDisconnectDialog
        data-testid='slackDisconnectDialog'
        isOpen={showSlackDisconnectDialog}
        onClose={() => setShowSlackDisconnectDialog(false)}
      />

      <JitCard
        actionButtons={actionButtons}
        borderColor={borderColor}
        description='cards.slack.descriptions.primary'
        headerIcons={headerIcons}
        tags={tags}
        testid='slack'
        title={title || 'vendor.slack'}
      />
    </>
  );
};

