import { FC } from 'react';

import styles from './DetailGrid.module.scss';

import { JitText } from 'components/JitText/JitText';

export type Content = {
  title: string | undefined;
  body: string | JSX.Element | undefined
};

interface Props {
  content: Content[]
  gridTemplateColumnsStyle: string;
}

export const DetailGrid: FC<Props> = ({ content, gridTemplateColumnsStyle }) => (
  <div className={styles.grid} style={{ gridTemplateColumns: gridTemplateColumnsStyle }}>
    {content.map(({ title, body = 'N/A' }) => (
      <div key={title}>
        <JitText muted size='s' text={title} />

        <div className={styles.itemWrapper}>
          {typeof body === 'string' ? <JitText text={body} /> : body}
        </div>
      </div>
    ))}
  </div>
);
