import { t } from 'i18next';
import { FC } from 'react';

import { ResolvedFindingsWidgetContent } from './ResolvedFindingsWidgetContent';

import { JitText } from 'components/JitText/JitText';
import { InsightsCard } from 'pages/OverviewPage/components/Insights/baseComponents/index';
import { useGetFindingsCount } from 'pages/TeamsPortal/TeamPage/hooks/useGetFindingsCount';
import colors from 'themes/colors.module.scss';
import { BacklogValue } from 'types/enums/IgnoreRules';

interface Props {
  teamName: string;
}

export const ResolvedFindingsWidget: FC<Props> = ({ teamName }) => {
  const { findings, isLoading: isLoadingFindings } = useGetFindingsCount(teamName);

  const preProdFindings = findings?.find((item) => item.key === BacklogValue.FALSE)?.count || 0;
  const totalFindings = findings?.reduce((acc, item) => acc + item.count, 0) || 0;

  return (
    <InsightsCard
      content={<ResolvedFindingsWidgetContent isLoading={isLoadingFindings} preProdCount={preProdFindings} totalCount={totalFindings} />}
      footer={<JitText color={colors.lightGray} size='s' text='pages.teamsPortal.teamPage.findingsMetric.footer' />}
      header={{
        infoTooltip: t('pages.teamsPortal.teamPage.findingsMetric.tooltip', {
          total: totalFindings,
          resolved: preProdFindings,
        }),
        title: 'pages.teamsPortal.teamPage.findingsMetric.title',
      }}
      size='s'
      variant='light'
    />
  );
};
