/**
 * Calculates the maximum allowed width based on the `maxWidth` prop and an HTML element.
 * Supports units: 'px', '%', 'vw'.
 *
 * @param maxWidth - The maximum width as a number or string with units.
 * @param element - The HTML element to calculate relative widths.
 * @returns The calculated maximum allowed width in pixels.
 */
export const calculateElementRelativeWidth = (
  maxWidth: number | string,
  element: HTMLElement,
) => {
  let maxAllowedWidth = 0;

  if (typeof maxWidth === 'string') {
    if (maxWidth.endsWith('%')) {
      // Handle percentage units
      const percentage = parseFloat(maxWidth);
      const parentWidth = element.parentElement
        ? element.parentElement.clientWidth
        : window.innerWidth;
      maxAllowedWidth = (parentWidth * percentage) / 100;
    } else if (maxWidth.endsWith('px')) {
      // Handle pixel units
      maxAllowedWidth = parseFloat(maxWidth);
    } else if (maxWidth.endsWith('vw')) {
      // Handle viewport width units
      const vw = parseFloat(maxWidth);
      maxAllowedWidth = (window.innerWidth * vw) / 100;
    } else {
      // Fallback to element's width if unit is not recognized
      maxAllowedWidth = element.clientWidth;
    }
  } else if (typeof maxWidth === 'number') {
    maxAllowedWidth = maxWidth;
  } else {
    // Default to the element's width
    maxAllowedWidth = element.clientWidth;
  }

  return maxAllowedWidth;
};
