import { FC, useEffect, useState } from 'react';

import { StatusIndicationBox } from '../../../components/StatusIndicationCell/StatusIndicationBox';
import { getEnvironment } from '../../../utils/utilsNew';

import { DownloadButton } from './DownloadButton';
import styles from './PipelineHeader.module.scss';

import {
  ResourceNameAndTypeHeaderV3,
} from 'pages/PipelinesPage/components/ResourceNameAndTypeCell/ResourceNameAndTypeHeaderV3';
import { useExecutionsService } from 'services/ExecutionsService/useExecutionsService';
import { IPipelineNew } from 'types/interfaces/Pipelines/IPipelineNew';

interface Props {
  pipeline: IPipelineNew;
}

const getFirstExecutionId = (pipeline: IPipelineNew) => Object.values(pipeline.workflows)
  .flatMap((workflow) => Object.values(workflow.assets)
    .flatMap((asset) => Object.values(asset.jobs)))?.[0]?.executionId;

export const PipelineHeader: FC<Props> = ({ pipeline }) => {
  const environment = pipeline.additionalAttributes?.environment || getEnvironment(pipeline.workflows) || '';
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  const { getExecutionArtifacts } = useExecutionsService();

  useEffect(() => {
    const executionId = getFirstExecutionId(pipeline);
    if (executionId) {
      getExecutionArtifacts(pipeline.jitEventId, executionId).then(
        (res) => {
          if (res?.status === 200) {
            setDownloadUrl(res.data?.download_url);
          }
        },
      ).catch((reason) => {
        console.error(reason);
      });
    }
  }, [getExecutionArtifacts, pipeline]);

  return (
    <div className={styles.headerContainer} data-testid='PipelineHeader'>
      <div className={styles.headerContainerLeft} data-testid='PipelineHeaderLeft'>
        <ResourceNameAndTypeHeaderV3
          assetName={pipeline.sourceAssetName}
          assetType={pipeline.sourceAssetType}
          environment={environment}
          eventType={pipeline.jitEventName}
          owner={pipeline.sourceAssetOwner}
          vendor={pipeline.sourceAssetVendor}
          workflows={pipeline.workflows}
        />

        <div className={styles.statusContainer} data-testid='status'>
          <StatusIndicationBox status={pipeline.status} />
        </div>
      </div>

      {downloadUrl && <DownloadButton downloadUrl={downloadUrl} /> }
    </div>
  );
};
