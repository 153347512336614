import { useCallback } from 'react';

import { parseExecutions } from './utils/parseExecutions';

import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import {
  IDownloadFileResponse,
  IExecution,
  IGetExecutionLogResponse,
  ITableQueryParams,
  ITableServerResponse,
} from 'types/interfaces';

export const useExecutionsService = () => {
  const { client } = useClient();

  const getExecutionArtifacts = useCallback(async (jitEventId: string, executionId: string) => {
    const url = getApiUrls.executionsService.getExecutionArtifacts(jitEventId, executionId);
    return client.get<IDownloadFileResponse>({
      url,
      allowedStatuses: [200, 404],
    });
  }, [client]);

  const fetchExecutions = useCallback(async (params: ITableQueryParams) => {
    const url = getApiUrls.executionsService.fetchExecutions();
    const res = await client.get<ITableServerResponse<IExecution>>({
      url,
      allowedStatuses: [200],
      requestConfig: {
        params,
      },
    });

    if (!res?.data?.data) return undefined;
    const { data, metadata } = res.data;
    return {
      data: parseExecutions(data),
      metadata: {
        count: metadata.count,
        nextKey: metadata.last_key,
      },
    };
  }, [client]);

  const getExecutionLog = useCallback(async (jitEventId: string, executionId: string) => {
    const url = getApiUrls.executionsService.getExecutionLog(jitEventId, executionId);
    return client.get<IGetExecutionLogResponse>({
      url,
      allowedStatuses: [200, 400, 404],
    });
  }, [client]);

  return {
    getExecutionArtifacts,
    fetchExecutions,
    getExecutionLog,
  };
};
