import { useMemo } from 'react';

import colors from 'themes/colors.module.scss';

export const useNavButtonStyles = (isDisabled?: boolean, isSelected?: boolean, isHovered?: boolean) => {
  const backgroundColor = useMemo(() => {
    if (isDisabled) return 'transparent';
    if (isSelected) return colors.navbarSelected;
    if (isHovered) return colors.navbarHovered;

    return 'transparent';
  }, [isHovered, isSelected, isDisabled]);

  const textAndIconColor = useMemo(() => {
    if (isDisabled) return colors.gray;
    if (isSelected) return colors.white;

    return colors.navbarButton;
  }, [isSelected, isDisabled]);

  return {
    backgroundColor,
    textAndIconColor,
  };
};
