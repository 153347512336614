import { FC } from 'react';

import styles from './ControlsSummary.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  passed: number;
  active: number;
  total: number;
}

export const ControlsSummary: FC<Props> = ({ passed, active, total }) => (
  <div className={styles.textWrapper}>
    <JitText
      bold
      color={colors.gray}
      size='s'
      text='pages.securityPlans.planCard.controlsSummary.controls'
    />

    <div className={styles.statisticsWrapper}>
      <JitText
        color={colors.gray}
        data-testid='active-controls'
        params={{
          active,
          total,
        }}
        size='s'
        text='pages.securityPlans.planCard.controlsSummary.activatedControls'
      />

      {!!active && (
      <JitText
        color={colors.gray}
        data-testid='passed-controls'
        params={{
          passed,
          active,
        }}
        size='s'
        text='pages.securityPlans.planCard.controlsSummary.passedControls'
      />
      )}
    </div>
  </div>
);
