import { useMemo } from 'react';

import { ChooseGitlabProjects } from '../StepComponents/ChooseGitlabProjects/ChooseGitlabProjects';

import { IGitlabIntegrationWizardStep, IGitlabIntegrationWizardStepStatus } from 'components/GitlabIntegrationWizard/GitlabIntegrationWizard';
import { IGroup } from 'services/GitlabService/useGitlabService';

interface Props {
  selectedGroup?: IGroup;
  handleClose: () => void;
}

export const useChooseGitlabProjectsStep = ({ selectedGroup, handleClose }: Props): IGitlabIntegrationWizardStep => {
  const chooseGitlabProjectsStep = useMemo(() => ({
    title: 'GitlabIntegrationWizard.chooseGitlabProjects.tabText',
    component: () => (
      <ChooseGitlabProjects
        handleClose={handleClose}
        selectedGroup={selectedGroup}
      />
    ),
    status: IGitlabIntegrationWizardStepStatus.ACTIVE,
  }), [handleClose, selectedGroup]);

  return chooseGitlabProjectsStep;
};
