import { FC } from 'react';

import styles from './PremiumActionLabel.module.scss';

import { PremiumLock } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';

export const PremiumActionLabel: FC = () => (
  <div className={styles.labelContainer}>
    <JitIcon icon={PremiumLock} size={18} />

    <JitText
      bold
      size='s'
      text='pages.actions.actionCard.premium.premiumAction'
    />
  </div>
);
