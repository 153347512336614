import { Day } from 'types/interfaces/Metrics/FindingsMetrics/FindingsMetrics';

type Data = {
  name: string;
  preProduction: number;
  production: number;
};

export const getMonthlyData = (days: Day[]) => {
  const monthlyData: Record<number, Data> = {};
  const monthOrder: number[] = [];

  days.forEach((item) => {
    const date = new Date(item.date);
    const monthIndex = date.getMonth();
    const month = date.toLocaleString('default', { month: 'short' });

    if (!monthlyData[monthIndex]) {
      monthlyData[monthIndex] = {
        name: month,
        preProduction: 0,
        production: 0,
      };
      monthOrder.push(monthIndex);
    }

    monthlyData[monthIndex].preProduction += item.fixed.preProduction;
    monthlyData[monthIndex].production += item.fixed.production;
  });

  return monthOrder
    .sort((a, b) => a - b)
    .map((monthIndex) => monthlyData[monthIndex]);
};
