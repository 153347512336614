import { createContext, Dispatch, SetStateAction, useContext } from 'react';

import { initialPromise, initialEmptyObject } from 'context/constants';
import { ISpecificTeamState } from 'context/TeamsContext/TeamsProvider';
import { MemberSearch } from 'services/TeamsService/useTeamsService';
import { initialPaginatedState, IPaginatedState } from 'types/interfaces/IPaginatedState';
import { IGetTeamResponseItem } from 'types/interfaces/Teams/ITeam';
import { TeamSortBy, TeamSortOrder } from 'types/interfaces/Teams/TeamSorting';

export type GetTeamsOptions = {
  shouldResetState: boolean;
  searchValue?: string;
  searchKey?: string;
  memberSearch?: MemberSearch;
  sortBy?: TeamSortBy;
  displayImage?: boolean;
  displayMembers?: boolean;
  userId?: string;
  limit?: number;
  page?: number;
};

interface ITeamsContext {
  teams: IPaginatedState<IGetTeamResponseItem>;
  specificTeam: ISpecificTeamState;
  isLoadingSpecificTeam: boolean;
  getTeams: (options: GetTeamsOptions) => Promise<void>;
  getSpecificTeamById: (teamId: string, includeChecks?: boolean, includePosition?: boolean) => Promise<void>;
  getNextChildTeams: () => Promise<void>;
  getNextMembers: () => Promise<void>;
  sortedBy: TeamSortBy;
  setSortedBy: (sortBy: TeamSortBy) => void;
  sortOrder: TeamSortOrder;
  setSortOrder: (sortOrder: TeamSortOrder) => void;
  setTeams: Dispatch<SetStateAction<IPaginatedState<IGetTeamResponseItem>>>
  isUploadingTeams: boolean;
  setIsUploadingTeams: Dispatch<SetStateAction<boolean>>;
}

export const TeamsContext = createContext<ITeamsContext>({
  getNextMembers: initialPromise,
  getNextChildTeams: initialPromise,
  getTeams: initialPromise,
  getSpecificTeamById: initialPromise,
  isLoadingSpecificTeam: false,
  teams: initialPaginatedState,
  sortedBy: TeamSortBy.SCORE,
  setSortedBy: initialEmptyObject,
  sortOrder: TeamSortOrder.DESC,
  setSortOrder: initialEmptyObject,
  specificTeam: {
    team: undefined,
    isLoadingSpecificTeam: false,
    members: initialPaginatedState,
    childTeams: initialPaginatedState,
    resources: initialPaginatedState,
  },
  setTeams: initialEmptyObject,
  isUploadingTeams: false,
  setIsUploadingTeams: initialEmptyObject,
});

export const useTeamsContext = () => useContext(TeamsContext);
