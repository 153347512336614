import { useMemo } from 'react';

import { IGetTeamResponseItem } from 'types/interfaces/Teams/ITeam';

export const useGetTeamsSymbolImage = (teams: IGetTeamResponseItem[]) => {
  const symbolImages = useMemo(() => {
    const images = teams.map((team) => {
      const image = new Image();
      image.src = team.imageDetails?.backgroundImageUrl || '';
      return image;
    });
    return images;
  }, [teams]);

  return {
    symbolImages,
  };
};
