import { FC } from 'react';

import { EmptySecurityPlansCard } from './components/EmptySecurityPlansCard/EmptySecurityPlansCard';
import { SecurityPlansCard } from './components/SecurityPlansCard/SecurityPlansCard';

import { IPlanDetails } from 'types/interfaces';

interface Props {
  goals: IPlanDetails[];
}

export const SecurityPlans: FC<Props> = ({ goals }) => (
  <div data-testid='securityPlans-collapse-section'>
    {goals.length ? <SecurityPlansCard goals={goals} /> : <EmptySecurityPlansCard />}
  </div>
);
