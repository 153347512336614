import { FC, useMemo } from 'react';

import { QuestionMarkBold } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitRank } from 'components/JitRank/JitRank';
import { ITableCell } from 'types/interfaces';

const IMAGE_SIZE = 30;

export const RankCell: FC<ITableCell> = ({ cell: { value: { imageDetails, isActivated, rank } } }) => {
  const { backgroundImageUrl } = imageDetails ?? {};
  const logo = useMemo(() => {
    if (!isActivated) {
      return <JitIcon icon={QuestionMarkBold} size={13} />;
    }

    return <img alt='symbol' src={backgroundImageUrl} width={IMAGE_SIZE} />;
  }, [backgroundImageUrl, isActivated]);

  return (
    <JitRank borderStyle={isActivated ? 'solid' : 'dashed'} icon={logo} rank={rank} />
  );
};
