import { FC, useCallback, useMemo, useState } from 'react';

import styles from './IconButton.module.scss';

import { WhiteDotCheck } from 'assets';
import { DisabledElement } from 'components/DisabledElement/DisabledElement';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import colors from 'themes/colors.module.scss';
import { IMouseEvent, ISvg } from 'types/interfaces';
import { stopPropagation } from 'utils';

interface Props {
  icon: FC<ISvg>;
  onClick: (e: IMouseEvent) => void;
  isDisabled?: boolean;
  tooltipText: string;
  completed?: boolean;
  testId?: string;
  color?: string;
  size?: number;
}

const DEFAULT_COLOR = 'transparent';

export const IconButton: FC<Props> = ({ size = 16, icon, onClick, isDisabled, tooltipText, completed, testId = 'icon-button', color = colors.white }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = useCallback(() => {
    if (isDisabled) return;
    setIsHovered(true);
  }, [isDisabled]);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const handleClick = useCallback((e: IMouseEvent) => {
    if (isDisabled) return;

    stopPropagation(e);
    onClick(e);
  }, [isDisabled, onClick]);

  const backSquareProps = useMemo(() => ({
    backgroundColor: isHovered ? colors.deepPurple : DEFAULT_COLOR,
    borderRadius: 50,
    borderColor: DEFAULT_COLOR,
  }), [isHovered]);

  return (
    <DisabledElement isDisabled={!!isDisabled}>
      <div className={styles.iconButtonWrapper}>
        <JitTooltip data-testid='icon-button-tooltip' followCursor={false} placement='top' title={tooltipText}>
          <JitIcon
            color={color}
            data-testid={testId}
            icon={icon}
            onClick={isDisabled ? undefined : handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            size={`${size}px`}
            withBackSquare={backSquareProps}
          />
        </JitTooltip>

        {completed && (
          <JitIcon
            className={styles.completedIcon}
            data-testid='completed-icon'
            icon={WhiteDotCheck}
            size='15px'
          />
        )}
      </div>
    </DisabledElement>
  );
};
