import Chip from '@mui/material/Chip';
import { FC } from 'react';

interface Props {
  checkName: string,
  removeCheckHandler: () => void,
}

export const ConfigurationsGithubBranchProtectionRequiredCheckTile: FC<Props> = ({ checkName, removeCheckHandler }) => (
  <Chip
    label={checkName}
    onDelete={removeCheckHandler}
    sx={{ borderRadius: 2 }}
    variant='outlined'
  />
);
