import { Y_TICK_INCREASE_CONST } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/FindingsActivityGraphs/constants';
import { IFindingsOverTimeDay } from 'types/interfaces/Metrics/PerformanceMetrics/FindingsOvertime';

export const getMaxFindingAmount = (days: IFindingsOverTimeDay[]): number => {
  let maxFindingsAmount = 0;
  days.forEach((day) => {
    maxFindingsAmount = Math.max(day.detectedFindings, day.resolvedFindings, maxFindingsAmount);
  });
  return maxFindingsAmount;
};

export const getYAvailableTicks = (maxFindingsAmount: number): number[] => {
  const yAxisAvailableTicks = [];
  for (let i = 0; i <= (maxFindingsAmount + Y_TICK_INCREASE_CONST); i += Y_TICK_INCREASE_CONST) {
    yAxisAvailableTicks.push(i);
  }
  return yAxisAvailableTicks;
};
