import Dialog from '@mui/material/Dialog';
import Lottie from 'lottie-react';
import { FC } from 'react';

import animationData from '../../assets/loading.json';

import colors from 'themes/colors.module.scss';

export const AppLoadingBar: FC = () => (
  <Dialog
    BackdropProps={{ style: { backgroundColor: 'transparent' } }}
    open
    PaperProps={{
      style: {
        width: 300,
        background: 'transparent',
        boxShadow: 'none',
      },
    }}
    style={{ backgroundColor: colors.appBgColor }}
    transitionDuration={0}
  >

    <Lottie
      animationData={animationData}
      data-testid='appLoadingBar'
      style={{ width: '300px' }}
    />
  </Dialog>
);
