import Divider from '@mui/material/Divider';
import { FC } from 'react';

import colors from 'themes/colors.module.scss';

interface Props {
  color?: string;
}

export const JitDivider: FC<Props> = ({ color }) => <Divider style={{ borderColor: color || colors.cardsDivider }} />;
