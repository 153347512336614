import { uniqBy } from 'lodash';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';

import styles from './ResourcesTable.module.scss';
import { useGetColumns } from './useGetColumns';

import { GroupedRow } from 'components/JitTable/components/GroupedTableRows/GroupedTableRows';
import { useGetTableInstance } from 'components/JitTable/hooks/useGetTableInstance';
import { JitTable } from 'components/JitTable/JitTable';
import { JitText } from 'components/JitText/JitText';
import { constants } from 'globalConstants';
import { IAsset, ITableRow } from 'types/interfaces';
import { iconsMap } from 'utils';

interface Props {
  teamId: string;
  teamAssets: IAsset[];
}

export const ResourcesTable: FC<Props> = ({ teamId, teamAssets }) => {
  const navigate = useNavigate();

  const activeTeamAssets = teamAssets.filter((asset) => asset.is_active && asset.is_covered && !asset.is_archived);

  const columns = useGetColumns();
  const tableInstance = useGetTableInstance(columns, activeTeamAssets);
  const groupAssetsByVendor = (assetRows: Row<ITableRow>[]): GroupedRow[] => {
    const vendors: string[] = uniqBy(assetRows, 'original.vendor').map((row) => row.original.vendor);
    return vendors.map((vendor) => ({
      groupText: vendor,
      groupIcon: iconsMap[vendor]?.icon,
      rows: assetRows.filter((row) => row.original.vendor === vendor),
    }));
  };

  const onSelectRow = (row: ITableRow) => {
    navigate(`/${constants.routes.TEAMS}/${teamId}/${constants.routes.RESOURCE}/${row.asset_id}`);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <JitText bold params={{ resourcesCount: activeTeamAssets.length }} text='pages.teams.teamDetails.resourceTable.title' />

      </div>

      <div className={styles.tableWrapper}>
        <JitTable
          cellsNumber={columns.length}
          emptyTableSubtext='pages.teams.teamDetails.resourceTable.noResources.description'
          emptyTableText='pages.teams.teamDetails.resourceTable.noResources.title'
          groupRowsBy={groupAssetsByVendor}
          isLoading={false}
          jittyIcon={null}
          onSelectRow={onSelectRow}
          showPagination={false}
          tableInstance={tableInstance}
        />
      </div>
    </div>
  );
};
