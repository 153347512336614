import { LeaderboardGoldMedal, LeaderboardSilverMedal, LeaderboardBronzeMedal } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { ITeam } from 'types/interfaces/Teams/ITeam';

export const getMedalForScore = (teams: ITeam[], index: number) => {
  /*
      * Determines the type of medal a team should receive based on its ranking and handling ties as in real competitions.

      * For example, if two teams are tied for first place, both receive gold medals, and the next team receives a bronze medal (no silver medal is awarded).
      * If three teams are tied for first place, all three receive gold medals, and no other medals are awarded.
      * The function only assigns medals for the top three scores (or fewer if there are less than three teams).
    */
  const medals = [LeaderboardGoldMedal, LeaderboardSilverMedal, LeaderboardBronzeMedal];
  const topScores = teams.slice(0, 3).map((t) => t.score);
  const currentScore = teams[index].score;
  const countOfTeamsWithTheSameScore = topScores.filter((score) => score === currentScore).length;

  if (index < 3) {
    if (countOfTeamsWithTheSameScore > 1) {
      const medalIndex = topScores.indexOf(currentScore);
      return <JitIcon data-testid={`medal-${medalIndex}`} icon={medals[medalIndex]} />;
    }
    return <JitIcon data-testid={`medal-${index}`} icon={medals[index]} />;
  }
  return undefined;
};
