import { FC } from 'react';

import styles from './JobNew.module.scss';

import { ErrorDot, DotClockRunning, DotQueued, Empty, FailedDot, GreenDotCheck } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { config } from 'config';
import { useAssetsContext } from 'context/AssetsContext/AssetsContext';
import { LogsLink } from 'pages/PipelinesPage/components/LogsLink/LogsLink';
import { STATUSES } from 'pages/PipelinesPage/constants';
import { getStatusFromJobs } from 'pages/PipelinesPage/utils/utilsNew';
import { isCiRunner } from 'types/enums/Runner';
import { IJobFieldsNew, IMouseEvent } from 'types/interfaces';
import { stopPropagation } from 'utils';

interface JobNewProps {
  owner: string
  job: IJobFieldsNew
  jobSlug: string
  jitEventId: string
}

const statusToIconProps = {
  [STATUSES.COMPLETED]: {
    icon: GreenDotCheck || Empty,
    className: styles.successWrapper || '',
  },
  [STATUSES.FAILED]: {
    icon: FailedDot || Empty,
    className: styles.failedWrapper || '',
  },
  [STATUSES.RUNNING]: {
    icon: DotClockRunning || Empty,
    className: styles.runningWrapper || '',
  },
  [STATUSES.PENDING]: {
    icon: DotQueued || Empty,
    className: styles.queuedWrapper || '',
  },
  [STATUSES.ERROR]: {
    icon: ErrorDot || Empty,
    className: styles.failedWrapper || '',
  },
};

export const JobNew: FC<JobNewProps> = ({ owner, job, jobSlug, jitEventId }) => {
  const status = getStatusFromJobs([job]);
  const jobStatusProps = statusToIconProps[status];
  const shouldShowLogs = [STATUSES.COMPLETED, STATUSES.FAILED, STATUSES.ERROR].includes(status);
  const { centralizedRepo } = useAssetsContext();
  const shouldBeClickable = !!(isCiRunner(job.runner) && job.centralizedRepoExternalJobId);

  const handleClick = (e: IMouseEvent) => {
    if (shouldBeClickable && centralizedRepo) {
      window.open(config.githubExternalJobId(owner, centralizedRepo.asset_name, job.centralizedRepoExternalJobId));
    }
    stopPropagation(e);
  };

  return (
    <div className={styles.wrapper} data-testid='JobNew'>
      <div className={`${jobStatusProps?.className} ${styles.jobWrapper}`}>
        <JitIcon
          className={styles.icon}
          icon={jobStatusProps?.icon}
          size={12}
        />

        <TooltipOnlyOnOverflow placement='right' tooltipText={jobSlug}>
          <JitText
            addUnderlineOnHover={shouldBeClickable}
            className={`${styles.overflowEllipsis} ${!shouldBeClickable && styles.notClickable}`}
            data-testid={jobSlug}
            noWrap
            onClick={(e: IMouseEvent) => handleClick(e)}
            overflowHiddenEllipsis
            size='s'
            text={jobSlug}
          />
        </TooltipOnlyOnOverflow>

        {shouldShowLogs && <LogsLink jitEventId={jitEventId} job={job} />}
      </div>
    </div>
  );
};
