import { FC } from 'react';

import {
  AwsGreyIcon,
  GcpIcon,
  AzureIcon,
  GithubGreyIcon,
  ZapAPIIcon,
  ZapWebIcon,
  WebsiteIcon,
  SemgrepIcon,
  LegitifyIcon,
  GitlabIcon,
  BitbucketIcon,
  AzureReposIcon,
  GosecIcon,
  BanditIcon,
  CheckmarxIcon,
  GitleaksIcon,
  NpmAuditIcon,
  OsvScannerIcon,
  NancyIcon,
  KicsIcon,
  KubescapeIcon,
  TrivyIcon,
  PrismaIcon,
  Prowler,
  DependabotIcon,
  ChainBenchIcon,
  ZapIcon,
  AwsIcon,
  JitFavicon,
  JiraIcon,
  ShortcutIcon,
  LinearIcon,
  SlackIcon,
  NpmIcon,
  DrataIcon,
} from 'assets';
import { AssetType, Vendor } from 'types/enums';
import { ISvg } from 'types/interfaces';

const VENDORS_ICONS = new Map<string, FC<ISvg>>([
  [Vendor.Github, GithubGreyIcon],
  [Vendor.AWS, AwsGreyIcon],
  [Vendor.GCP, GcpIcon],
  [Vendor.PARAGON_GCP, GcpIcon],
  [Vendor.AZURE, AzureIcon],
  [Vendor.Domain, WebsiteIcon],
  [Vendor.SEMGREP, SemgrepIcon],
  [Vendor.GHSECURITY, LegitifyIcon],
  [Vendor.GITLAB, GitlabIcon],
  [Vendor.BITBUCKET, BitbucketIcon],
  [Vendor.AZURE_REPOS, AzureReposIcon],
  [Vendor.GOSEC, GosecIcon],
  [Vendor.BANDIT, BanditIcon],
  [Vendor.CHECKMARX, CheckmarxIcon],
  [Vendor.GITLEAKS, GitleaksIcon],
  [Vendor.NPM_AUDIT, NpmAuditIcon],
  [Vendor.OSV_SCANNER, OsvScannerIcon],
  [Vendor.NANCY, NancyIcon],
  [Vendor.KICS, KicsIcon],
  [Vendor.KUBESCAPE, KubescapeIcon],
  [Vendor.TRIVY, TrivyIcon],
  [Vendor.PRISMA, PrismaIcon],
  [Vendor.PROWLER, Prowler],
  [Vendor.DEPENDABOT, DependabotIcon],
  [Vendor.CHAIN_BENCH, ChainBenchIcon],
  [Vendor.ZAP, ZapIcon],
  [Vendor.NPM, NpmIcon],
]);

const COLORED_ICONS = new Map<string, FC<ISvg>>([
  [Vendor.AWS, AwsIcon],
  [Vendor.JIT, JitFavicon],
  [Vendor.Jira, JiraIcon],
  [Vendor.Drata, DrataIcon],
  [Vendor.Shortcut, ShortcutIcon],
  [Vendor.LINEAR, LinearIcon],
  [Vendor.SLACK, SlackIcon],
]);

export const getIconByVendor = (vendorName: string | undefined, assetType?: string | undefined, colored: boolean = false) => {
  const vendor = vendorName?.toLowerCase();
  if (!vendor) {
    return undefined;
  }

  if (vendor === Vendor.Domain && assetType) {
    if (assetType === AssetType.API) return ZapAPIIcon;
    if (assetType === AssetType.WEB) return ZapWebIcon;
  }

  if (colored && COLORED_ICONS.has(vendor)) {
    return COLORED_ICONS.get(vendor);
  }

  return VENDORS_ICONS.get(vendor);
};
