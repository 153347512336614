import { t } from 'i18next';
import { useMemo } from 'react';

import { NameCell } from './components/NameCell/NameCell';

import { GradeCell } from 'pages/TeamsPortal/components/GradeCell/GradeCell';
import { PlanItemTitleWithTooltip } from 'pages/TeamsPortal/components/PlanItemTitleWithTooltip/PlanItemTitleWithTooltip';
import { IAsset } from 'types/interfaces';
import { TableColumn } from 'types/types';

interface TableColumnsNames {
  name: string;
  score: string;
  planItems: string;
}

export const useGetAssetColumns = (): TableColumn<object>[] => {
  const columnHeaders: TableColumnsNames = useMemo(() => t('pages.teamsPortal.teamPage.table.columns', { returnObjects: true }), []);
  return useMemo(
    () => [
      {
        id: columnHeaders.name,
        Header: columnHeaders.name,
        accessor: (asset: IAsset) => ({
          name: asset.asset_name,
          isActivated: asset.is_active || false,
        }),
        Cell: NameCell,
      },
      {
        id: columnHeaders.score,
        Header: columnHeaders.score,
        accessor: (asset: IAsset) => ({
          score: asset.score || 0,
          isActivated: asset.is_active || false,
        }),
        Cell: GradeCell,
      },
      {
        id: columnHeaders.planItems,
        Header: () => (
          <PlanItemTitleWithTooltip
            columnName={columnHeaders.planItems}
          />
        ),
        accessor: (asset: IAsset) => (asset.plan_items ? asset.plan_items.length : 0),
      },
    ] as TableColumn<object>[],
    [columnHeaders.name, columnHeaders.planItems, columnHeaders.score],
  );
};
