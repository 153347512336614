import { IGetTeamResponseItem } from 'types/interfaces/Teams/ITeam';

export const assignRanksToTeams = (teams: IGetTeamResponseItem[]) => {
  /*
    * Assigns a rank to each team based on their score.
    * If two teams have the same score, they will have the same rank.
    * The rank is determined by the order of the teams in the array.
    * If several teams have the same score, they will all have the same rank, and the next team will have a rank equal to the number of teams with the same score plus one.
  */
  if (teams.length === 0) return [];

  let rank = 1;
  let previousScore = teams[0].score;

  return teams.map((team, index) => {
    if (team.score !== previousScore) {
      rank = index + 1;
      previousScore = team.score;
    }

    return { ...team,
      rank };
  });
};

export const addCurrentTeamIfNotInTop10 = (teams: IGetTeamResponseItem[], currentTeam?: IGetTeamResponseItem): IGetTeamResponseItem[] => {
  /*
    * If the current team is in the top 10, it will be added to the leaderboard if it has the same score as the last team in the leaderboard.
  */
  if (teams.length === 0 || !currentTeam) {
    return teams;
  }
  const isCurrentTeamInLeaderboard = teams.some((team) => team.id === currentTeam.id);
  if (!isCurrentTeamInLeaderboard && teams[teams.length - 1].score === currentTeam.score) {
    const updatedCurrentTeam: IGetTeamResponseItem = {
      ...currentTeam,
      rank: teams[teams.length - 1].rank,
    };
    teams.pop();
    teams.push(updatedCurrentTeam);
  }

  return teams;
};
