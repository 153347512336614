import { FC } from 'react';

import styles from './AddTag.module.scss';

import { JitText } from 'components/JitText/JitText';

export const PopoverHeader: FC = () => (
  <div className={styles.popoverContent}>
    <div className={styles.popOverContentColumn}>
      <JitText text='pages.risks.resources.resourcePage.resourceDetails.addTagPopover.key' />
    </div>

    <div className={styles.popOverContentColumn}>
      <JitText text='pages.risks.resources.resourcePage.resourceDetails.addTagPopover.value' />
    </div>
  </div>
);
