import { FC } from 'react';

import { RankBadgeWithPopover } from '../../../GraphHeaderComponents/RankBadgeWithPopover/RankBadgeWithPopover';

import styles from './GraphHeader.module.scss';

import { AvgValueAndChangeRate, Description, TitleWithIcon } from 'pages/InsightsPage/SubPages/PerformancePage/components/GraphHeaderComponents';
import { IPerformanceRateOption, PerformanceRateRank } from 'types/interfaces/Metrics/PerformanceRateOption';

interface Props {
  title: string;
  popperTitle: string;
  avgValue?: string | null;
  changeRate?: number | null;
  positiveTrendIndicatorDirection?: 'up' | 'down';
  performanceRateOptions?: IPerformanceRateOption[];
  rank?: PerformanceRateRank | null;
  isLoading: boolean;
  description: string;
  popperDescription: string;
}

export const GraphHeader: FC<Props> = ({ title, popperTitle, avgValue, changeRate, positiveTrendIndicatorDirection, performanceRateOptions, rank, isLoading, description, popperDescription }) => (
  <div data-testid='DetectionRateGraphHeader'>
    <div className={styles.headerWrapper}>
      <TitleWithIcon
        avgValue={avgValue}
        displayRankInfoIcon
        isLoading={isLoading}
        performanceRateOptions={performanceRateOptions}
        popperDescription={popperDescription}
        popperTitle={popperTitle}
        rank={rank}
        title={title}
      />

      <RankBadgeWithPopover avgValue={avgValue} isLoading={isLoading} performanceRateOptions={performanceRateOptions} popperDescription={popperDescription} popperTitle={popperTitle} rank={rank!} />
    </div>

    <Description description={description} />

    <AvgValueAndChangeRate avgValue={avgValue} changeRate={changeRate} positiveTrendIndicatorDirection={positiveTrendIndicatorDirection} />
  </div>
);
