import { FC } from 'react';

import { CounterCard, InsightsCard } from '../../baseComponents/index';
import styles from '../../baseComponents/InsightsCard/InsightsCard.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { ITrendingDevProductivity } from 'types/interfaces/Metrics/SecurityImpactMetrics/DevProductivity';

interface Props {
  devProductivityMetric: ITrendingDevProductivity;
}

export const ResolvedFindingsComponent: FC<Props> = ({ devProductivityMetric }) => {
  const { totalResolvedFindings, totalResolvedFindingsPositiveTrendIndicatorDirection, totalResolvedFindingsChangeRate, previousPeriodTotalResolvedFindings } = devProductivityMetric;

  return (
    <InsightsCard
      content={(
        <CounterCard
          count={{
            count: totalResolvedFindings,
            color: totalResolvedFindings === 0 ? colors.white : colors.performanceGreen,
          }}
          percentageChip={{ percentage: totalResolvedFindingsChangeRate,
            bgColor: colors.cards,
            positiveTrendIndicatorDirection: totalResolvedFindingsPositiveTrendIndicatorDirection }}
        />
    )}
      footer={(
        <JitText
          className={styles.footerText}
          color={colors.gray}
          components={{ days: <JitText bold color={colors.lightGray} text={previousPeriodTotalResolvedFindings} /> }}
          text='pages.overview.insights.previousPeriod'
        />
    )}
      header={{
        title: 'pages.securityImpact.securityPosture.resolvedFindingsTitle',
      }}
      size='m'
      variant='top'
    />
  );
};
