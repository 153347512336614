import { FC } from 'react';

import { CustomNodeType, CustomEdgeType } from './types';

import { JitGraph } from 'components/JitGraph/JitGraph';
import { getLayoutedElements, edgeTypes, nodeTypes } from 'components/PriorityFactorsGraph/utils/visualGraphUtils';

interface Props {
  nodes: CustomNodeType[];
  edges: CustomEdgeType[];
  isLoading?: boolean;

}

export const ContextGraph: FC<Props> = ({
  nodes,
  edges,
  isLoading,
}) => (
  <JitGraph
    edges={edges}
    edgeTypes={edgeTypes}
    getLayoutedElements={getLayoutedElements}
    isLoading={isLoading}
    nodes={nodes}
    nodeTypes={nodeTypes}
  />
);
