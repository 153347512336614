import { FC } from 'react';

import styles from './JitRank.module.scss';

import { JitText } from 'components/JitText/JitText';

interface Props {
  rank: number
  icon?: React.ReactNode
  borderStyle?: 'solid' | 'dashed'
}

export const JitRank: FC<Props> = ({ rank, icon = '🏆', borderStyle = 'solid' }) => (
  <div
    className={styles.root}
    data-testid='jit-rank'
    style={{ borderStyle }}
  >
    <JitText bold text={rank} />

    {icon}
  </div>
);

