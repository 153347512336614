import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQuickstartGuide } from '../../hooks/useQuickstartGuide';
import styles from '../ButtonMainComponent.module.scss';

import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';
import { CONFIGURE_CLOUD_INTEGRATION_CHECK_SLUG } from 'context/TenantContext/constant';
import { constants } from 'globalConstants';
import colors from 'themes/colors.module.scss';

export const GoToIntegrationsButton: React.FC = () => {
  const navigate = useNavigate();
  const { checksMap } = useQuickstartGuide();

  const isCloudIntegrationComplete = useMemo(() => {
    const cloudIntegrationCheck = checksMap[CONFIGURE_CLOUD_INTEGRATION_CHECK_SLUG];
    return cloudIntegrationCheck?.isCompleted ?? false;
  }, [checksMap]);

  const handleClick = useCallback(() => navigate(`/${constants.routes.INTEGRATIONS}`), [navigate]);

  return (
    isCloudIntegrationComplete ? (
      <JitText
        color={colors.iris}
        onClick={handleClick}
        text='pages.quickStart.checks.configureIntegrations.viewAllIntegrationsButton'
      />
    ) : (
      <JitGradientButton onClick={handleClick}>
        <div className={styles.buttonContent}>
          <JitText bold fontFamily='Inter, serif' size='s' text='pages.quickStart.checks.configureIntegrations.goToIntegrationsButton' />
        </div>
      </JitGradientButton>
    )
  );
};
