/* eslint-disable react/jsx-no-literals */
import { FC } from 'react';

import styles from './CoverageScale.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

export interface ICoverageScaleProps {
  inputColors: string[];
}

export const CoverageScale: FC<ICoverageScaleProps> = ({ inputColors }) => (
  <div
    className={styles.container}
    data-testid='coverage-scale'
  >
    <div className={styles.boxes}>
      {
        inputColors.map((color) => (
          <div
            key={color}
            className={styles.box}
            data-testid='color-box'
            style={{
              backgroundColor: color,
            }}
          />
        ))
      }
    </div>

    <div className={styles.labels}>
      <JitText
        bold
        className={styles.labelEdited}
        color={colors.lightGray}
        size='xxs'
        text='pages.securityMapping.coverageScale.labels.noCoverage'
      />

      <JitText
        bold
        className={styles.labelEdited}
        color={colors.lightGray}
        size='xxs'
        text='pages.securityMapping.coverageScale.labels.greatCoverage'
      />
    </div>
  </div>
);
