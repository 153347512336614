import { createContext, useContext } from 'react';

import { IFronteggUser } from 'types/interfaces';

interface IAuthContext {
  frontEggUser: IFronteggUser;
  handleLogout: () => void;
  hasPermission: (permissionKey: string) => boolean;
  viewedWelcomeTeamsPage: boolean;
  setViewedWelcomeTeamsPage: (viewedWelcomeTeamsPage: boolean) => void;
  userPermissions: string[];
  userHasAccessToManagementPortal: boolean;
}

export const AuthContext = createContext<IAuthContext>({
  handleLogout: () => { },
  frontEggUser: {} as IFronteggUser,
  hasPermission: () => false,
  viewedWelcomeTeamsPage: false,
  setViewedWelcomeTeamsPage: () => { },
  userPermissions: [],
  userHasAccessToManagementPortal: false,
});

export const useAuthContext = () => useContext(AuthContext);
