
import { useInfiniteQuery } from 'react-query';

import { CreateWorkflowButton } from './components/CreateWorkflowButton/CreateWorkflowButton';
import { WorkflowsTable } from './components/WorkflowsTable/WorkflowsTable';
import styles from './WorkflowsPage.module.scss';

import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { PageTitles } from 'components/PageTitles/PageTitles';
import { useWorkflowsService } from 'services/WorkflowsService/useWorkflowsService';
import { Queries } from 'types/enums/Queries';

export const WorkflowsPage = () => {
  const { getWorkflows } = useWorkflowsService();

  const {
    data,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(
    [Queries.Workflows],
    ({ pageParam }) => getWorkflows({ after: pageParam }),
    {
      getNextPageParam: (lastPage) => lastPage?.metadata?.after || undefined,
    },
  );

  const allWorkflows = data?.pages.flatMap((page) => page?.data || []) || [];

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <PageTitles subtitle='pages.workflows.workflowsListPage.subtitle' title='pages.workflows.workflowsListPage.title' />

        <CreateWorkflowButton />
      </div>

      <div className={styles.content}>
        { isLoading
          ? <LoadingBar />
          : (
            <WorkflowsTable
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
              workflows={allWorkflows}
            />
          ) }
      </div>
    </div>
  );
};
