/* eslint-disable max-statements */
import { difference } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import { useGetAssetPriorityFactorHandler } from './useGetAssetPriorityFactorHandler';

import { useGraphContext } from 'context/GraphContext/GraphContext';
import { useAddRemoveFactors } from 'context/GraphContext/hooks/useAddRemoveFactors';
import { GraphEntityType } from 'types/enums/ContextGraphEntityType';
import { IPriorityEntity, IPriorityFactor, IPriorityFields } from 'types/interfaces/Graph/IPriorityFactor';

export const usePriorityFactorsState = (priorityFields: IPriorityFields, entity: IPriorityEntity) => {
  const { allPriorityFactors, isLoadingPriorityFactors } = useGraphContext();
  const [updatedPriorityFactors, setUpdatedPriorityFactors] = useState<string[]>(priorityFields.priorityFactors || []);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isApplyClicked, setIsApplyClicked] = useState(false);
  const { addFactors, removeFactors } = useAddRemoveFactors();
  const [isInEditMode, setIsInEditMode] = useState(false);
  const assetPriorityFactorsHandler = useGetAssetPriorityFactorHandler(priorityFields.assetId);

  const enrichedOriginalFactors = useMemo(
    () => updatedPriorityFactors
      .map((factorName) => allPriorityFactors.find((factor) => factor.key === factorName))
      .filter((factor) => factor) as IPriorityFactor[],
    [updatedPriorityFactors, allPriorityFactors],
  );

  const priorityScoreToDisplay = useMemo(
    () => (isInEditMode
      ? (allPriorityFactors.reduce((acc, factor) => acc + (updatedPriorityFactors.includes(factor.key) ? factor!.defaultWeight : 0), 0)
        || priorityFields.priorityScore || 0)
      : (priorityFields.priorityScore || 0)),
    [isInEditMode, allPriorityFactors, updatedPriorityFactors, priorityFields],
  );

  const toggleFactor = (factor: string) => {
    if (updatedPriorityFactors.includes(factor)) {
      setUpdatedPriorityFactors((prevState) => prevState.filter((f) => f !== factor));
    } else {
      setUpdatedPriorityFactors((prevState) => [...prevState, factor]);
    }
  };

  const addedFactors = enrichedOriginalFactors.filter((factor) => !priorityFields.priorityFactors?.includes(factor.key));
  const removedFactors = difference(priorityFields.priorityFactors || [], updatedPriorityFactors)
    .map((factor) => allPriorityFactors.find((f) => f.key === factor)!);

  const hasFactorsChanged = addedFactors.length > 0 || removedFactors.length > 0;

  const submitFactors = useCallback(async () => {
    setIsSubmitting(true);
    if (addedFactors.length > 0) {
      await addFactors(priorityFields, entity, addedFactors);
    }
    if (removedFactors.length > 0) {
      await removeFactors(priorityFields, entity, removedFactors);
    }
    setIsSubmitting(false);
  }, [addedFactors, removedFactors, addFactors, priorityFields, entity, removeFactors]);

  const handleApplyClicked = async () => {
    if (priorityFields.type === GraphEntityType.FINDING) {
      setIsApplyClicked(true);
    }
    if (priorityFields.type === GraphEntityType.ASSET) {
      await submitFactors();
    }
  };

  const handleSubmitAssetFactors = useCallback(async () => {
    if (!assetPriorityFactorsHandler) {
      return;
    }
    setIsSubmitting(true);
    await Promise.all([
      assetPriorityFactorsHandler.addAssetFactors(addedFactors),
      assetPriorityFactorsHandler.removeAssetFactors(removedFactors),
    ]);
    await submitFactors();
    setIsApplyClicked(false);
    setIsInEditMode(false);
  }, [addedFactors, assetPriorityFactorsHandler, removedFactors, submitFactors]);

  return {
    isInEditMode,
    setIsInEditMode,
    priorityScoreToDisplay,
    enrichedOriginalFactors,
    toggleFactor,
    submitFactors,
    handleApplyClicked,
    isApplyClicked,
    hasFactorsChanged,
    updatedPriorityFactors,
    isLoadingPriorityFactors,
    isSubmitting,
    handleSubmitAssetFactors,
  };
};
