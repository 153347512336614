import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';

import {
  getDevEfficiencyCalculation,
  getDevProductivityMetric, getJitUsageFirstDateMetric, getLayersCoverageMetric,
  getOpenFindingsCountMetric,
  getResolvedFindingsMetric, getResourceCoverageMetric, getSecurityImpactMTTRMetric,
} from 'services/MetricService/MetricService';
import { useMetricService } from 'services/MetricService/useMetricService';
import { SecurityImpactMetricNames } from 'types/enums/MetricNames';
import { DevProductivityCalculationGetRequest } from 'types/interfaces/Metrics/DevEfficiencyCalculation';
import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { IDevProductivity } from 'types/interfaces/Metrics/SecurityImpactMetrics/DevProductivity';
import { IJitUsageFirstDate } from 'types/interfaces/Metrics/SecurityImpactMetrics/JitUsageFirstDate';
import { IPlanStatus } from 'types/interfaces/Metrics/SecurityImpactMetrics/LayersCoverage';
import { IOpenFindingsCount } from 'types/interfaces/Metrics/SecurityImpactMetrics/OpenFindingsCount';
import { IResourcesCoverage as IResourceCoverage } from 'types/interfaces/Metrics/SecurityImpactMetrics/ResourcesCoverage';
import { ISecurityImpactMTTR } from 'types/interfaces/Metrics/SecurityImpactMetrics/SecurityImpactMTTR';
import { ITotalFindingsGraph } from 'types/interfaces/Metrics/SecurityImpactMetrics/TotalFindingsGraph';

type MetricsState = {
  [SecurityImpactMetricNames.LayersCoverage]: MetricWithLoadingIndication<IPlanStatus>;
  [SecurityImpactMetricNames.DevProductivity]: MetricWithLoadingIndication<IDevProductivity>;
  [SecurityImpactMetricNames.JitUsageFirstDate]: MetricWithLoadingIndication<IJitUsageFirstDate>;
  [SecurityImpactMetricNames.TotalFindingsGraph]: MetricWithLoadingIndication<ITotalFindingsGraph>;
  [SecurityImpactMetricNames.ResourcesCoverage]: MetricWithLoadingIndication<IResourceCoverage>;
  [SecurityImpactMetricNames.SecurityImpactMTTR]: MetricWithLoadingIndication<ISecurityImpactMTTR>;
  [SecurityImpactMetricNames.OpenFindingsCount]: MetricWithLoadingIndication<IOpenFindingsCount>;
};

const initialMetricValue = { isLoading: false };

const initialMetricsState: MetricsState = {
  [SecurityImpactMetricNames.LayersCoverage]: initialMetricValue,
  [SecurityImpactMetricNames.DevProductivity]: initialMetricValue,
  [SecurityImpactMetricNames.JitUsageFirstDate]: initialMetricValue,
  [SecurityImpactMetricNames.TotalFindingsGraph]: initialMetricValue,
  [SecurityImpactMetricNames.ResourcesCoverage]: initialMetricValue,
  [SecurityImpactMetricNames.SecurityImpactMTTR]: initialMetricValue,
  [SecurityImpactMetricNames.OpenFindingsCount]: initialMetricValue,
};

const initialDevCalculationState: DevProductivityCalculationGetRequest = { isLoading: false };

const metricCalls = [
  {
    metric: SecurityImpactMetricNames.DevProductivity,
    fetchMetric: getDevProductivityMetric,
  },
  {
    metric: SecurityImpactMetricNames.LayersCoverage,
    fetchMetric: getLayersCoverageMetric,
  },
  {
    metric: SecurityImpactMetricNames.JitUsageFirstDate,
    fetchMetric: getJitUsageFirstDateMetric,
  },
  {
    metric: SecurityImpactMetricNames.TotalFindingsGraph,
    fetchMetric: getResolvedFindingsMetric,
  },
  {
    metric: SecurityImpactMetricNames.ResourcesCoverage,
    fetchMetric: getResourceCoverageMetric,
  },
  {
    metric: SecurityImpactMetricNames.SecurityImpactMTTR,
    fetchMetric: getSecurityImpactMTTRMetric,
  },
  {
    metric: SecurityImpactMetricNames.OpenFindingsCount,
    fetchMetric: getOpenFindingsCountMetric,
  },
];

export const useFetchMetrics = () => {
  const { patchDevEfficiencyCalculation } = useMetricService();
  const [metrics, setMetrics] = useState(initialMetricsState);
  const [devProductivityCalculation, setDevProductivityCalculation] = useState<DevProductivityCalculationGetRequest>(initialDevCalculationState);

  const setMetric = useCallback((
    metricName: SecurityImpactMetricNames,
    metric: MetricWithLoadingIndication<
    IDevProductivity | IDevProductivity | IPlanStatus | IJitUsageFirstDate |
    ITotalFindingsGraph | IResourceCoverage | ISecurityImpactMTTR | IOpenFindingsCount | undefined
    >,
  ) => {
    setMetrics((prevState) => ({
      ...prevState,
      [metricName]: metric,
    }));
  }, []);

  const fetchDevCalculation = useCallback(async (isSilent?: boolean) => {
    if (!isSilent) {
      setDevProductivityCalculation({ isLoading: true });
    }
    const devCalculation = await getDevEfficiencyCalculation();
    setDevProductivityCalculation({ isLoading: false,
      devProductivityCalculation: devCalculation });
  }, [setDevProductivityCalculation]);

  const fetchMetrics = useCallback(async () => {
    metricCalls.forEach(({ metric, fetchMetric }) => {
      setMetric(metric, { isLoading: true });
      fetchMetric().then((result) => {
        const isResultEmpty = result && isEmpty(result);
        // We make sure that the result returned valid information and not an empty object.
        setMetric(metric, {
          metric: isResultEmpty ? undefined : result,
          isLoading: false,
        });
      });
    });
  }, [setMetric]);

  const silentFetchDevProductivity = useCallback(async () => {
    const devProductivityMetric = await getDevProductivityMetric();
    setMetric(SecurityImpactMetricNames.DevProductivity, { isLoading: false,
      metric: devProductivityMetric });
  }, [setMetric]);

  return { fetchMetrics,
    metrics,
    fetchDevCalculation,
    devProductivityCalculation,
    patchDevEfficiencyCalculation,
    silentFetchDevProductivity };
};
