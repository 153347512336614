import { AxiosResponse } from 'axios';
import { t } from 'i18next';
import { kebabCase } from 'lodash';
import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react';

import styles from './ImportFileDialog.module.scss';

import { ErrorIcon, Link } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import { JitFileInput } from 'components/JitFileInput/JitFileInput';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitInfoBanner } from 'components/JitInfoBanner/JitInfoBanner';
import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { ActionButtonVariant } from 'types/interfaces';
import { IUploadFileResponse } from 'types/interfaces/Files/IUploadFile';

interface Props {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  title: string
  fileInputTitle: string
  downloadTemplate?: () => void
  alertMessage?: string
  isFileContentValid: (value: string) => Promise<boolean>;
  accept?: string;
  handleUpload: (file: File) => Promise<AxiosResponse<IUploadFileResponse> | undefined>;
  isLoadingDownload?: boolean;
  docsLink?: string;
}

export const ImportFileDialog: FC<Props> = ({ isOpen, setIsOpen, title, fileInputTitle, downloadTemplate, alertMessage, isFileContentValid, accept, handleUpload, isLoadingDownload, docsLink }) => {
  const [inputValue, setInputValue] = useState('');

  const downloadTemplateButton = (
    <div className={styles.downloadTemplateButton}>
      <JitText color={colors.lightGray} text='dialogs.importFile.downloadTemplate' />

      <JitButton isLoading={isLoadingDownload} onClick={downloadTemplate} showChildrenWhileLoading variant='text'>
        <JitText color={colors.iris} text='dialogs.importFile.downloadTemplateButton' />
      </JitButton>
    </div>
  );

  const handleChange = useCallback((value: string) => setInputValue(value), []);

  const docsLinkComponent = useMemo(() => (
    <JitLink
      className={styles.docsLink}
      data-testid='docsLink'
      href={docsLink}
      rel='noreferrer'
      target='_blank'
      underline='none'
    >
      <JitText color={colors.iris} text='dialogs.importFile.docsLink' />

      <JitIcon color={colors.iris} icon={Link} size={16} />
    </JitLink>
  ), [docsLink]);
  const analyticsTitle = kebabCase(i18n.t(fileInputTitle));

  const content = (
    <div className={styles.content}>
      <div className={styles.wrapper}>
        <JitText bold size='l' text={title} />

        {downloadTemplate && downloadTemplateButton}
      </div>

      <div className={styles.fileInputWrapper}>
        <JitText color={colors.lightGray} text={fileInputTitle} />

        <JitFileInput
          accept={accept}
          analyticsTitle={analyticsTitle}
          handleChange={handleChange}
          handleUpload={handleUpload}
          inputValue={inputValue}
          isFileContentValid={isFileContentValid}
        />
      </div>

      {alertMessage && (
        <JitInfoBanner bgColor={colors.failRedBackground02} data-testid='alert-message' icon={ErrorIcon} iconColor={colors.white}>
          <JitText params={{ message: t(alertMessage) as string }} text='dialogs.importFile.alertMessage' />
        </JitInfoBanner>
      )}

      {docsLinkComponent}
    </div>
  );

  return (
    <CustomDialog
      actionButtons={[
        {
          label: 'dialogs.importFile.doneButton',
          disabled: !inputValue,
          handleClick: () => setIsOpen(false),
          variant: ActionButtonVariant.PRIMARY,
        },
      ]}
      content={content}
      dataTestId='import-file-dialog'
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title='dialogs.importFile.title'
      width='m'
      withDivider
    />
  );
};
