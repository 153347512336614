import { Interval } from 'types/types';
import { setIntervalImmediately } from 'utils';

let interval: Interval;

export const hideApiTokensManagement = () => {
  interval = setIntervalImmediately(() => {
    const fronteggContainer = document.querySelector('#frontegg-admin-portal-container-default');
    const fronteggContainerShadowRoot = fronteggContainer?.shadowRoot;
    const fronteggApiTokensButton = fronteggContainerShadowRoot?.querySelector<HTMLElement>('[data-test-id="apiTokens"]');
    const fronteggPersonalTokensButton = fronteggContainerShadowRoot?.querySelector<HTMLElement>('[data-test-id="personalApiTokens"]');

    if (fronteggApiTokensButton) {
      fronteggApiTokensButton.remove();
    }

    if (fronteggPersonalTokensButton) {
      fronteggPersonalTokensButton.remove();
    }

    if (fronteggApiTokensButton || fronteggPersonalTokensButton) {
      clearInterval(interval);
    }
  }, 10, 100);
};
