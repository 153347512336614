import { FC, forwardRef, RefCallback, RefObject } from 'react';

import { JitSliderStyles } from './JitSlider.styles';

interface Props {
  height?: 'small' | 'medium' | undefined;
  width?: 'small' | 'medium' | 'fit-content' | '100%' | undefined;
  max: number;
  min: number;
  value: number;
  onChange: (event: Event, value: number | number[]) => void;
  name: string;
}

export const JitSlider: FC<Props> = forwardRef(
  (
    {
      height = 'medium',
      width = '100%',
      ...props
    }: Props,
    ref?: RefCallback<HTMLSpanElement> | RefObject<HTMLSpanElement> | null,
  ) => (
    <JitSliderStyles
      ref={ref}
      aria-label={props.name}
      height={height}
      max={props.max}
      min={props.min}
      name={props.name}
      onChange={props.onChange}
      value={props.value}
      width={width}
    />
  ),
);
