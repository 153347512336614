import { FC } from 'react';

import styles from './EmptyActionCard.module.scss';

import { JittyTheExplorerGray } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

export const EmptyActionCard: FC = () => (
  <div
    className={styles.wrapper}
    data-testid='empty-action-card'
  >
    <JitIcon icon={JittyTheExplorerGray} size={60} />

    <div className={styles.cardContentWrapper}>
      <JitText bold color={colors.lightGray} text='actions.card.emptyTitle' />

      <JitText color={colors.gray} text='actions.card.emptyDescription' />
    </div>
  </div>

);
