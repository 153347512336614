import isEmpty from 'lodash/isEmpty';

import { IFindingFilter, ServerFindingKeys } from 'types/interfaces';

const getDefaultIgnoredFilter = (filter: IFindingFilter) => ({
  ...filter,
  selectedValue: [false],
  isVisible: true,
});

export const addIgnoredFilterIfNeeded = (updatedFilter: IFindingFilter, updatedFilters: IFindingFilter[]) => {
  if (updatedFilter.key === ServerFindingKeys.status && !isEmpty(updatedFilter.selectedValue) && updatedFilter.isVisible) {
    return updatedFilters.map((currentFilter) => (currentFilter.key === ServerFindingKeys.ignored ? getDefaultIgnoredFilter(currentFilter) : currentFilter));
  }
  return updatedFilters;
};
