import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styles from './TooltipOnlyOnOverflow.module.scss';

import { JitText, JitTextProps } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';

interface Props extends JitTextProps {
  tooltipText?: string | string[]
  placement?: 'top' | 'right' | 'bottom' | 'left' | 'bottom-end' | 'bottom-start' | 'left-end' | 'left-start' | 'right-end' | 'right-start' | 'top-end' | 'top-start'
}

export const TooltipOnlyOnOverflow: FC<Props> = ({ text = '', tooltipText, children, placement, ...jitTextProps }) => {
  const textElementRef = useRef<HTMLSpanElement | null>(null);
  const { clientWidth = 0, clientHeight = 0 } = (textElementRef?.current?.children?.[0] || {});
  const { scrollWidth = 0, scrollHeight = 0 } = (textElementRef?.current?.children?.[0] || {});
  const [isOverflowed, setIsOverflowed] = useState<boolean>(false);

  const isOverflowedCalculate = useMemo(() => scrollWidth > clientWidth || scrollHeight > clientHeight, [clientHeight, clientWidth, scrollHeight, scrollWidth]);

  const checkOverflow = useCallback(() => {
    setIsOverflowed(isOverflowedCalculate);
  }, [isOverflowedCalculate]);

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [checkOverflow, text, textElementRef]);

  return (
    <JitTooltip
      childrenWrapperClassName={styles.tooltipChildren}
      disableHoverListener={!isOverflowed}
      followCursor={false}
      placement={placement || 'top'}
      title={tooltipText || text}
    >
      <span
        ref={textElementRef}
        className={styles.tooltipChildren}
      >
        {children || (
          <JitText
            className={styles.tooltipChildren}
            data-testid={jitTextProps['data-testid'] || 'TooltipOnlyOnOverflow-text'}
            noWrap
            overflowHiddenEllipsis
            text={text}
            {...jitTextProps}
          />
        )}
      </span>
    </JitTooltip>
  );
};
