import { CSSProperties, FC } from 'react';

import styles from './JitMenu.module.scss';

import { MenuItemCategories } from 'components/JitMenu/JitMenu';
import { MenuItems, SelectedDesignVariants } from 'components/JitMenu/MenuItems';
import { IMouseEvent, MenuItemKeyType } from 'types/interfaces';

interface Props {
  categories: MenuItemCategories[];
  onMenuItemClick?: ({ e, categoryKey, itemKey }: { e: IMouseEvent, categoryKey?: string, itemKey: MenuItemKeyType }) => void;
  menuItemsClassName?: string;
  menuItemsWrapperStyle?: CSSProperties;
  selectedDesign?: SelectedDesignVariants;
  menuItemStyle?: CSSProperties;
}

export const CategoriesAndItems: FC<Props> = ({ categories, onMenuItemClick, menuItemsClassName = '', menuItemsWrapperStyle, menuItemStyle = {}, selectedDesign = 'radioButtons' }) => {
  const renderCategory = (category: MenuItemCategories) => {
    const { categoryKey, categoryContent, items, withCheckbox, withRadioButtons } = category;
    return (
      <div key={categoryKey} className={menuItemsClassName}>
        <div className={styles.category}>
          {categoryContent}
        </div>

        <MenuItems
          categoryKey={categoryKey}
          className={menuItemsClassName}
          items={items}
          menuItemStyle={menuItemStyle}
          onMenuItemClick={onMenuItemClick}
          selectedDesign={selectedDesign}
          withCheckbox={withCheckbox}
          withRadioButtons={withRadioButtons}
        />

      </div>
    );
  };

  return (
    <div className={menuItemsClassName} style={menuItemsWrapperStyle}>
      {categories.map((category) => renderCategory(category))}
    </div>
  );
};
