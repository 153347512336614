import { FC } from 'react';

import styles from './AddRemovePopover.module.scss';

import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitText } from 'components/JitText/JitText';
import { FactorsRow } from 'pages/FindingsPage/components/FindingDetails/components/Priority/children/AddRemoveFactor/FactorsRow';
import { ActionButtonVariant } from 'types/interfaces';
import { IPriorityFactor } from 'types/interfaces/Graph/IPriorityFactor';

interface Props {
  selectedFactors: IPriorityFactor[];
  setIsInEditMode: (isInEditMode: boolean) => void;
}

export const PopoverViewMode: FC<Props> = ({ selectedFactors, setIsInEditMode }) => (
  <div className={styles.factorsList}>

    {!selectedFactors.length ? (
      <div className={styles.emptyStateContainer}>
        <JitText muted size='m' text='pages.findings.findingDetails.priority.addRemoveFactors.emptyState.title' />

        <JitActionButton
          actionButton={{
            variant: ActionButtonVariant.PRIMARY,
            label: 'pages.findings.findingDetails.priority.addRemoveFactors.emptyState.buttonText',
            handleClick: () => setIsInEditMode(true),
          }}
        />
      </div>
    ) : (
      <>
        <JitText muted size='s' text='pages.findings.findingDetails.priority.addRemoveFactors.factorsListTitle' />

        {selectedFactors.map((factor) => (
          <FactorsRow
            key={factor.key}
            factors={[factor]}
            selectedFactor={factor.key}
            showQuestionDescription={false}
            viewMode
          />
        ))}
      </>
    )}
  </div>
);
