import { FC } from 'react';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  columnKey: string;
}

export const DisabledHeader: FC<Props> = ({ columnKey }) => (
  <JitText color={colors.gray} size='m' text={columnKey} />
);
