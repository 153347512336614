import { FC, Fragment, useState } from 'react';

import { DeleteSnapshotConfirmDialog } from '../DeleteSnapshotConfirmDialog';

import { SnapshotCopyLink } from './components/SnapshotCopyLink';
import { SnapshotDuration } from './components/SnapshotDuration';
import { SnapshotOwner } from './components/SnapshotOwner';
import { SnapshotType } from './components/SnapshotType';
import { SnapshotName } from './components/SnashotName';
import styles from './HistoryContent.module.scss';

import { Trash } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { useMetricService } from 'services/MetricService/useMetricService';
import commonStyles from 'themes/common.module.scss';
import { Snapshot } from 'types/interfaces/Metrics/Snapshots';

interface Props {
  snapshots: Snapshot[];
  setSnapshots: (snapshots: Snapshot[]) => void;
  'data-testid'?: string
}

export const HistoryContent: FC<Props> = ({ snapshots, setSnapshots, ...props }) => {
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const { deleteSnapshot } = useMetricService();
  const [selectedSnapshotForDelete, setSelectedSnapshotForDelete] = useState<Snapshot>();
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);

  const handleDeleteSnapshot = (id: string) => {
    setIsLoadingDelete(true);

    sendAnalyticsEvent({
      action: 'delete-snapshot',
      params: { 'snapshot-id': id },
    });
    deleteSnapshot(id).then((snapshot) => {
      if (snapshot) {
        setSnapshots(snapshots.filter((s) => s.id !== snapshot.id));
        setIsLoadingDelete(false);
      }
      setSelectedSnapshotForDelete(undefined);
    });
  };
  return (
    <div className={styles.historyContentWrapper} data-testid={props['data-testid'] || 'HistoryContent'}>

      <div className={`${styles.gridContainer} ${commonStyles.scrollInnerShadow}`}>
        {snapshots.map((snapshot) => (

          <Fragment key={snapshot.id}>
            <SnapshotName name={snapshot.name} />

            <SnapshotType isPublic={snapshot.isPublic} />

            <SnapshotDuration creationDate={snapshot.createdAt} />

            <SnapshotOwner owner={snapshot.username} />

            <SnapshotCopyLink link={snapshot.link || ''} />

            <JitTooltip followCursor={false} placement='top' title='pages.performance.shareSnapshot.history.delete.deleteButtonText'>
              <JitIcon data-testid={`Trash-${snapshot.id}`} icon={Trash} onClick={() => setSelectedSnapshotForDelete(snapshot)} size={12} />
            </JitTooltip>

          </Fragment>

        ))}
      </div>

      {!!selectedSnapshotForDelete && (
        <DeleteSnapshotConfirmDialog
          handleDeleteSnapshot={handleDeleteSnapshot}
          isLoadingDelete={isLoadingDelete}
          setSelectedSnapshotForDelete={setSelectedSnapshotForDelete}
          snapshot={selectedSnapshotForDelete}
        />
      )}
    </div>
  );
};
