import { FC } from 'react';

import { IconWithStatus } from '../../../../components/IconWithStatus/IconWithStatus';

import { SlackIcon } from 'assets';
import { useTenantContext } from 'context/TenantContext';
import { isVendorInstalled } from 'pages/IntegrationsPage/utils';
import { Vendor } from 'types/enums';

export const SlackStatus: FC = () => {
  const { installations } = useTenantContext();

  const isSlackConnected = isVendorInstalled(Vendor.SLACK, installations);
  const text = isSlackConnected ? 'pages.quickStart.checks.statuses.integrated' : 'pages.quickStart.checks.statuses.notIntegrated';

  return (
    <IconWithStatus
      hideIcon={!isSlackConnected}
      icon={SlackIcon}
      text={text}
    />
  );
};
