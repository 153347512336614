import { useCallback } from 'react';

import { usePlanService } from 'services/PlanService/usePlanService';

export const useGetPlanReport = () => {
  const { generatePlanReport } = usePlanService();

  const getPlanReport = useCallback(async (slug: string): Promise<string | undefined> => {
    try {
      const response = await generatePlanReport(slug);
      return response;
    } catch (error) {
      console.error('Error while getting plan report', error);
      return undefined;
    }
  }, [generatePlanReport]);

  return {
    getPlanReport,
  };
};
