import { usePipelineContextNew } from 'context/PipelinesContext/PipelinesContextNew';
import { IFilter, IFilterType } from 'types/interfaces/IFilter';

export const useResetFiltersNew = () => {
  const { setFilters, filters } = usePipelineContextNew();

  const resetFilters = () => {
    const resetedFilters = filters.map((filter: IFilter) => (filter.type === IFilterType.SINGLE_SELECT
      ? {
        ...filter,
        selectedValue: {
          value: '',
          displayText: '',
        },
      }
      : {
        ...filter,
        selectedValue: [],
      }
    ));
    setFilters(resetedFilters);
  };
  return { resetFilters };
};
