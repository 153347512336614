export const fetchFindingPriorityFactorsOptions = () => [{ value: 'test',
  label: 'Test' }];
export const fetchAssetPriorityFactorsOptions = () => [{ value: 'test',
  label: 'Test' }];
export const fetchFindingTypesOptions = () => [{ value: 'test',
  label: 'Test' }];
export const fetchAssetTypesOptions = () => [{ value: 'test',
  label: 'Test' }];
export const fetchFindingsTypesNamesOptions = () => [{ value: 'test',
  label: 'Test' }];
export const fetchAssetNamesOptions = () => [{ value: 'test',
  label: 'Test' }];
export const fetchFindingsCwesOptions = () => [{ value: 'test',
  label: 'Test' }];
