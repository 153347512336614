
import {
  getLayersCoverageMetric, getPlanItemFindingsMetric, getResourceCoverageMetric, getTrendingDevProductivityMetric, getMTTRMetric,
} from 'services/MetricService/MetricService';
import { TimeAgo } from 'types/enums';
import { OverviewMetricNames } from 'types/enums/MetricNames';
import { DevProductivityCalculationGetRequest } from 'types/interfaces/Metrics/DevEfficiencyCalculation';
import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { IMTTRMetric } from 'types/interfaces/Metrics/PerformanceMetrics/MTTR';
import { IPlanItemFindingsMetric } from 'types/interfaces/Metrics/PerformanceMetrics/PlanItemFindings';
import { ITrendingDevProductivity } from 'types/interfaces/Metrics/SecurityImpactMetrics/DevProductivity';
import { IPlanStatus } from 'types/interfaces/Metrics/SecurityImpactMetrics/LayersCoverage';
import { IResourcesCoverage } from 'types/interfaces/Metrics/SecurityImpactMetrics/ResourcesCoverage';

type MetricsState = {
  [OverviewMetricNames.TrendingDevProductivity]: MetricWithLoadingIndication<ITrendingDevProductivity>;
  [OverviewMetricNames.MTTR]: MetricWithLoadingIndication<IMTTRMetric>;
  [OverviewMetricNames.ResourcesCoverage]: MetricWithLoadingIndication<IResourcesCoverage>;
  [OverviewMetricNames.LayersCoverage]: MetricWithLoadingIndication<IPlanStatus>;
  [OverviewMetricNames.PlanItemFindings]: MetricWithLoadingIndication<IPlanItemFindingsMetric>;
};

const initialMetricValue = { isLoading: false };

export const initialMetricsState: MetricsState = {
  [OverviewMetricNames.TrendingDevProductivity]: initialMetricValue,
  [OverviewMetricNames.MTTR]: initialMetricValue,
  [OverviewMetricNames.ResourcesCoverage]: initialMetricValue,
  [OverviewMetricNames.LayersCoverage]: initialMetricValue,
  [OverviewMetricNames.PlanItemFindings]: initialMetricValue,
};

export const initialDevCalculationState: DevProductivityCalculationGetRequest = { isLoading: false };

export const metricCalls = [
  {
    metric: OverviewMetricNames.TrendingDevProductivity,
    fetchMetric: () => getTrendingDevProductivityMetric(TimeAgo.TwoWeeks),
  },
  {
    metric: OverviewMetricNames.MTTR,
    fetchMetric: () => getMTTRMetric(TimeAgo.TwoWeeks),
  },
  {
    metric: OverviewMetricNames.ResourcesCoverage,
    fetchMetric: getResourceCoverageMetric,
  },
  {
    metric: OverviewMetricNames.LayersCoverage,
    fetchMetric: getLayersCoverageMetric,
  },
  {
    metric: OverviewMetricNames.PlanItemFindings,
    fetchMetric: getPlanItemFindingsMetric,
  },
];
