export interface DailyData {
  date: string;
  value: number;
}

export const getIntervalAverage = (
  data: DailyData[],
  interval: number,
): Array<{ label: string; value: number }> => {
  if (!data.length) return [];

  return Array.from(
    { length: Math.ceil(data.length / interval) },
    (_, i) => {
      const intervalData = data.slice(i * interval, (i + 1) * interval);
      const avgValue = intervalData.reduce((sum, item) => sum + item.value, 0) / intervalData.length;

      return {
        label: new Date(intervalData[0].date).toLocaleDateString('en-US', {
          month: 'short',
          day: '2-digit',
          year: 'numeric',
        }),
        value: Math.round(avgValue),
      };
    },
  );
};

