/* eslint-disable no-nested-ternary */
import { FC, useMemo } from 'react';

import { IconWithStatus } from '../../../../components/IconWithStatus/IconWithStatus';

import { getIconByVendor } from 'components/AssetType/utils/getIconByVendor';
import { useIntegrationsContext } from 'context/IntegrationsContext';
import { useTenantContext } from 'context/TenantContext';
import { getIntegrationTemplateVendor } from 'pages/IntegrationsPage/utils/getIntegrationTemplateVendor';
import { Vendor } from 'types/enums';
import { IntegrationStatus } from 'types/interfaces/Integrations';
import { isVendorCloudProvider } from 'utils/functions/matchVendorToAssetType';

const getTemplateVendor = (vendor: Vendor) => getIntegrationTemplateVendor(vendor) as Vendor;

const getVendorFromString = (vendorString: string): Vendor | undefined => Object.values(Vendor).find((v) => v === vendorString) as Vendor | undefined;

export const CloudIntegrationStatus: FC = () => {
  const { integrations } = useIntegrationsContext();
  const { installations } = useTenantContext();

  const successfulCloudIntegration = useMemo(
    () => integrations?.find((integration) => isVendorCloudProvider(getTemplateVendor(integration.vendor)) && integration.status === IntegrationStatus.SUCCESS),
    [integrations],
  );
  const cloudProviderInstallation = useMemo(
    () => installations?.find((installation) => isVendorCloudProvider(getVendorFromString(installation.vendor))),
    [installations],
  );
  const hasCloudIntegration = useMemo(() => !!successfulCloudIntegration || !!cloudProviderInstallation, [successfulCloudIntegration, cloudProviderInstallation]);
  const text = useMemo(() => (hasCloudIntegration ? 'pages.quickStart.checks.statuses.integrated' : 'pages.quickStart.checks.statuses.notIntegrated'), [hasCloudIntegration]);

  const cloudIcon = useMemo(
    () => (
      hasCloudIntegration
        ? successfulCloudIntegration
          ? getIconByVendor(getTemplateVendor(successfulCloudIntegration.vendor))
          : getIconByVendor(cloudProviderInstallation?.vendor)
        : undefined
    ),
    [successfulCloudIntegration, cloudProviderInstallation, hasCloudIntegration],
  );

  return (
    <IconWithStatus
      hideIcon={!hasCloudIntegration}
      icon={cloudIcon}
      text={text}
    />
  );
};
