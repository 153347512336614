import CircularProgress from '@mui/material/CircularProgress';
import { FC, ReactElement, useMemo } from 'react';

import styles from './CircularPercentage.module.scss';
import { useSubscribeToSvgHover } from './useSubscribeToSvgMouseEvent';

import { JitPopper } from 'components/JitPopper/JitPopper';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  value: number;
  size: number;
  content?: ReactElement;
  color?: string;
  valuePartTooltipText?: string;
  backgroundPartTooltipText?: string;
  filledSectionThickness?: number;
}

export const CircularPercentage: FC<Props> = ({ value, size, content, color, valuePartTooltipText, backgroundPartTooltipText, filledSectionThickness = 4 }) => {
  const {
    hoveredValue,
    transparentCircleRef,
  } = useSubscribeToSvgHover();
  const circleToHighlight = useMemo(() => {
    if (hoveredValue === null) {
      return null;
    }
    if (hoveredValue > value) {
      return 'background';
    }
    return 'value';
  }, [hoveredValue, value]);

  const shouldHighlightValue = circleToHighlight === 'value' && !!valuePartTooltipText;
  const shouldHighlightBackground = circleToHighlight === 'background' && !!backgroundPartTooltipText;

  const popperText = shouldHighlightValue ? valuePartTooltipText : backgroundPartTooltipText;
  const popperContent = (
    <div className={styles.popperWrapper}>
      <JitText size='s' text={popperText} />
    </div>
  );
  return (
    <JitPopper
      content={popperContent}
      isWithArrow={false}
      open={shouldHighlightValue || shouldHighlightBackground}
      placement='top'
      transition={false}
    >
      <div
        style={{
          position: 'relative',
          display: 'inline-flex',
          height: size,
          width: size,
        }}
      >

        <CircularProgress
          size={size - 2}
          sx={{
            color: colors.statusGray,
            opacity: shouldHighlightValue ? 0.3 : 1,
          }}
          thickness={2}
          value={100}
          variant='determinate'
        />

        <CircularProgress
          size={size}
          sx={{
            left: -1,
            top: -1,
            position: 'absolute',
            color: color || colors.statusLightBlue,
            strokeLinecap: 'round',
            opacity: shouldHighlightBackground ? 0.3 : 1,
          }}
          thickness={filledSectionThickness}
          value={value}
          variant='determinate'
        />

        <CircularProgress
          ref={transparentCircleRef}
          size={size}
          sx={{
            position: 'absolute',
            opacity: 0,
            zIndex: 1,
          }}
          thickness={6}
          value={100}
          variant='determinate'
        />

        <div
          style={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{ zIndex: 2 }}>
            {content}
          </div>

        </div>
      </div>
    </JitPopper>
  );
};
