import { AnalyticsBrowser } from '@segment/analytics-next';
import { useCallback, useState, useEffect } from 'react';

import { config } from 'config';

export const useNotAuthenticatedAnalyticsClient = () => {
  const WRITE_KEY: string = config.segmentKey!;
  const ANALYTICS_CDN: string = config.analyticsCdn!;

  const ANONYMOUS_COOKIE_NAME = 'ajs_anonymous_id';

  const [analyticsBrowserInstance, setAnalyticsBrowserInstance] = useState<AnalyticsBrowser | null>(null);

  const getCookie = async (): Promise<string | undefined> => {
    // @ts-ignore
    const cookies = await window?.cookieStore?.getAll(ANONYMOUS_COOKIE_NAME) || [];
    const anonymousCookie = cookies.find((cookie: { name: string, value: string }) => cookie.name === ANONYMOUS_COOKIE_NAME);
    return anonymousCookie?.value;
  };

  const identifyNotAuthenticatedUser = useCallback(async (analyticsBrowser: AnalyticsBrowser) => {
    const anonymousId = await getCookie();
    if (anonymousId) {
      analyticsBrowser?.identify(anonymousId, {
        anonymousId,
      });
    }
  }, []);

  useEffect(() => {
    const loadAndIdentify = async () => {
      const localAnalyticsBrowser = AnalyticsBrowser.load({
        cdnURL: ANALYTICS_CDN,
        writeKey: WRITE_KEY,
      });
      await identifyNotAuthenticatedUser(localAnalyticsBrowser);
      setAnalyticsBrowserInstance(localAnalyticsBrowser);
    };

    loadAndIdentify();
  }, [ANALYTICS_CDN, WRITE_KEY, identifyNotAuthenticatedUser]);

  const sendPageViewedEventNotAuthenticated = useCallback((pageName: string, pathname: string) => {
    analyticsBrowserInstance?.page(pageName, {
      name: pageName,
      path: pathname,
    });
  }, [analyticsBrowserInstance]);

  return { analyticsBrowser: analyticsBrowserInstance,
    sendPageViewedEventNotAuthenticated };
};
