import { useAuthActions } from '@frontegg/react';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

export const useSwitchTenant = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { switchTenant } = useAuthActions();

  const handleSwitchTenant = useCallback((tenantId: string) => {
    navigate('/');
    queryClient.removeQueries();
    localStorage.clear();
    switchTenant({ tenantId });
  }, [navigate, queryClient, switchTenant]);

  return {
    handleSwitchTenant,
  };
};
