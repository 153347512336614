import { FC } from 'react';
import { BaseEdge, EdgeProps, getBezierPath } from 'reactflow';

import colors from 'themes/colors.module.scss';

export const CustomEdge: FC<EdgeProps> = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
}) => {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge path={edgePath} style={style} />

      <svg>
        <circle
          cx={sourceX}
          cy={sourceY}
          fill={colors.appBgColor}
          r={style.strokeWidth}
          style={style}
        />

        <circle
          cx={targetX}
          cy={targetY}
          fill={colors.appBgColor}
          r={style.strokeWidth}
          style={style}
        />
      </svg>
    </>
  );
};
