export enum TABS {
  GITHUB = 'github',
  GITLAB = 'gitlab',
  AWS = 'aws',
  GCP = 'gcp',
  AZURE = 'azure',
  WEB = 'WEB',
  API = 'API',
}

export const TABS_ARRAY = [
  TABS.AWS,
  TABS.GCP,
  TABS.AZURE,
  TABS.WEB,
  TABS.API,
];
