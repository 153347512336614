
import { getPlanItemTriggerConfigurationName } from './getPlanItemTriggerConfigurationName';

import { ApplicationTypes, IConfigurations } from 'types/interfaces';

export const getPlanItemTriggerConfiguration = (configurations: IConfigurations, planItemSlug: string, applicationType: ApplicationTypes) => {
  const itemKeyName = getPlanItemTriggerConfigurationName(configurations, planItemSlug, applicationType);
  if (!itemKeyName) return undefined;
  return configurations?.plan_items?.[planItemSlug]?.[itemKeyName];
};
