import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

import { MenuVariant } from './JitMenu';

import colors from 'themes/colors.module.scss';

const variantsStyles: Record<MenuVariant, object> = {
  default: {
    backgroundColor: colors.cardContent,
    '&:hover': {
      backgroundColor: colors.cardContent,
    },
  },
  bright: {
    backgroundColor: colors.white,
    '&:hover': {
      backgroundColor: colors.jitPink02,
    },
  },
  light: {
    backgroundColor: colors.cardContent,
    '&:hover': {
      backgroundColor: colors.cardContent,
    },
  },
};

export const StyledMenuItem = styled(MenuItem)(({ style = {}, variant = 'default' }: { style?: object, variant: MenuVariant }) => ({
  padding: 0,
  ...variantsStyles[variant],
  ...style,
}));
