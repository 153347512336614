import { t } from 'i18next';
import { FC } from 'react';

import { useCurrentTeam } from '../hooks/useCurrentTeam/useCurrentTeam';

import { AssetsTable } from './components/AssetsTable/AssetsTable';
import { Insights } from './components/Insights/Insights';
import styles from './TeamPage.module.scss'; // Reuse the existing styles

import { AvatarGroupMember, JitAvatarGroup } from 'components/JitAvatarGroup/JitAvatarGroup';
import { JitGrade } from 'components/JitGrade/JitGrade';
import { JitText } from 'components/JitText/JitText';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { IMember } from 'types/interfaces/Teams/ITeam';

export const TeamPage: FC = () => {
  const { specificTeam } = useCurrentTeam();
  const members: AvatarGroupMember[] = specificTeam.members.data.map((member: IMember) => ({
    avatarUrl: member.avatarUrl,
    id: member.id,
    name: member.name,
  })) || [];

  if (specificTeam.isLoadingSpecificTeam) {
    return <LoadingBar />;
  }

  return (
    <div className={styles.pageWrapper} data-testid='team-page-content'>
      <div className={styles.header}>
        <div className={styles.grade}>
          <JitGrade score={specificTeam.team?.score || 0} size='l' tooltip='pages.teamsPortal.teamPage.gradeTooltip' />
        </div>

        <div className={styles.avatars}>
          <JitText bold size='xxxxl' text={specificTeam.team?.name} />

          <JitAvatarGroup maxMembersToDisplay={5} members={members} mode='individual' />
        </div>
      </div>

      <div className={styles.bodyWrapper} data-testid='team-page-content-body'>

        <div className={styles.highlights}>
          <JitText bold text='pages.teamsPortal.teamPage.highlights' />

          <div className={styles.insights}>
            <Insights resources={specificTeam.resources} team={specificTeam.team} />
          </div>
        </div>

        <div className={styles.resources}>
          <div className={styles.resourcesTitle}>
            <JitText text={`<b>${t('pages.teamsPortal.teamPage.resources')}</b> (${specificTeam.resources.data.length})`} />
          </div>

          <AssetsTable isLoading={specificTeam.resources.isLoading} resources={specificTeam.resources.data} />
        </div>
      </div>

    </div>
  );
};
