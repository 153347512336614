import { constants } from 'globalConstants';
import { IDictionary } from 'types/interfaces';

export const config = {
  platformBaseUrl: process.env.VITE_PLATFORM_URL,
  apiBaseUrl: process.env.VITE_API_BASE_URL,
  webSocketBaseUrl: process.env.VITE_API_BASE_URL?.replace('https://api', 'wss://ws'),
  githubApiUrl: 'https://api.github.com',
  docsUrl: 'https://docs.jit.io/docs', // TODO: Move to the constants / utils file everything that is not comes from 'process.env'
  contactUsUrl: 'https://www.jit.io/contact',
  docsTermsOfServiceLink: 'https://www.jit.io/legal/terms',
  docsPrivacyPolicyLink: 'https://www.jit.io/legal/privacy-policy',
  docsGithubInstallation: 'https://docs.jit.io/docs/integrating-with-github',
  docsWhyJitNeedsPermissionLink: 'https://docs.jit.io/docs/integrating-with-github#requirements',
  docsTeamsSync: 'https://docs.jit.io/docs/jit-teams-sync',
  docsContextEngine: 'https://docs.jit.io/docs/viewing-findings#understanding-the-findings-priority-and-the-context-engine',
  frontEggBaseUrl: process.env.VITE_FRONTEGG_URL || '',
  jitGithubAppUrl: process.env.VITE_GITHUB_JIT_APP_URL,
  docsQaUrl: process.env.VITE_DOCS_QA_URL,
  docsQaEnabled: !!process.env.VITE_DOCS_QA_URL,
  getInstallGithubUrl: (state: string) => `${config.jitGithubAppUrl}?state=${state}`,
  createNewGithubRepo: `${constants.GITHUB_URL}new`,
  githubPrLink: (owner: string, repo: string, prNumber?: string) => `${constants.GITHUB_URL}${owner}/${repo}/pull/${prNumber}`,
  githubExternalJobId: (owner: string, centralizedRepoName: string, jobId: string | undefined) => `${constants.GITHUB_URL}${owner}/${centralizedRepoName}/actions/runs/${jobId}`,
  githubJitPlan: (org: string, centralizedRepoName: string) => `${constants.GITHUB_URL}${org}/${centralizedRepoName}`,
  getGithubFilePathLink: ({ org, repo, branch, path, lineStart, lineEnd }: IDictionary<string>) => `${constants.GITHUB_URL}${org}/${repo}/blob/${branch}/${path}#L${lineStart}-L${lineEnd}`,
  getGithubRepoLink: ({ org, repo }: IDictionary<string>) => `${constants.GITHUB_URL}${org}/${repo}`,
  getRepoLink: (org: string, repo: string, scmVendor: string) => `${constants.SCM_URL_FORMAT.replace('{scmVendor}', scmVendor)}${org}/${repo}`,
  getGithubOrgLink: (org: string) => `${constants.GITHUB_URL}${org}`,
  getGithubPRLink: ({ org, repo, prNumber }: IDictionary<string>) => `${constants.GITHUB_URL}${org}/${repo}/pull/${prNumber}`,
  getGithubAppPermissionsUrl: (organization: string, installationId: string) => `${constants.GITHUB_URL}organizations/${organization}/settings/installations/${installationId}`,
  getGithubRefFileLink: (org: string, repo: string, ref: string, path: string) => `${constants.GITHUB_URL}${org}/${repo}/blob/${ref}/${path}`,
  hotJarSiteId: process.env.VITE_HOTJAR_SITE_ID || '',
  isProduction: process.env.NODE_ENV === 'production' && process.env.VITE_ENVIRONMENT === 'prod',
  isStaging: process.env.VITE_ENVIRONMENT === 'staging',
  isTest: process.env.NODE_ENV === 'test',
  isDev: process.env.NODE_ENV === 'development',
  environmentName: process.env.VITE_ENVIRONMENT,
  // https://docs.sentry.io/product/sentry-basics/dsn-explainer/#dsn-utilization
  sentryDsn: 'https://0724cd9e7f444a21a1f84f615c659c62@o1121770.ingest.sentry.io/6158708',
  launchDarklyClientId: process.env.VITE_LAUNCH_DARKLY,
  segmentKey: process.env.VITE_SEGMENT_KEY,
  analyticsCdn: process.env.VITE_SEGMENT_CDN,
  stiggKey: process.env.VITE_STIGG_KEY || '',
};
