import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCurrentTeam } from '../hooks/useCurrentTeam/useCurrentTeam';

import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { constants } from 'globalConstants';
import { FindingsPageContent } from 'pages/TeamsPortal/FindingsPage/components/FindingsPageContent/FindingsPageContent';
import styles from 'pages/TeamsPortal/FindingsPage/FindingsPage.module.scss';
import { FindingServiceViewMode } from 'types/enums/FindingServiceViewMode';

export const FindingsPage: FC = () => {
  const { teamsPortal: { BASE_ROUTE: BASE_TEAMS_PORTAL_ROUTE, GUIDE } } = constants.routes;

  const navigate = useNavigate();
  const {
    resourceId,
    planItemSlug,
  } = useParams();
  const { specificTeam } = useCurrentTeam();

  const relevantAsset = specificTeam?.resources.data.find((asset) => asset.asset_id === resourceId);
  const mode = (planItemSlug && resourceId ? FindingServiceViewMode.PlanItemFindings : FindingServiceViewMode.TopFindings);

  if (specificTeam.isLoadingSpecificTeam || specificTeam?.resources.isLoading) {
    return <LoadingBar />;
  }

  if (!specificTeam.team) {
    navigate(`/${BASE_TEAMS_PORTAL_ROUTE}/${GUIDE}`);
    return null;
  }

  return (
    <div className={styles.pageWrapper} data-testid='teams-page-findings-page'>
      <FindingsPageContent
        currentTeam={specificTeam.team!}
        mode={mode}
        planItemSlug={planItemSlug}
        relevantAsset={relevantAsset}
      />
    </div>
  );
};
