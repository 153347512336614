import { FC } from 'react';

import { PrivateIcon, PublicIcon } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  isPublic: boolean;
}
export const SnapshotType: FC<Props> = ({ isPublic }) => {
  const icon = isPublic ? PublicIcon : PrivateIcon;
  const text = isPublic ? 'pages.performance.shareSnapshot.history.public' : 'pages.performance.shareSnapshot.history.orgOnly';
  return (
    <div style={{
      display: 'flex',
      gap: 6,
    }}
    >
      <JitIcon color={colors.iris} icon={icon} size={10} />

      <JitText color={colors.lightGray} data-testid={isPublic ? 'PublicSnapshotText' : 'OrgOnlySnapshotText'} size='m' text={text} />
    </div>
  );
};
