import { FC, useCallback, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useFetchMetrics } from './hooks/useFetchMetrics';
import { PerformancePage } from './PerformancePage';

import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { constants } from 'globalConstants';
import { TimeAgo } from 'types/enums';
import { PerformanceMetricNames } from 'types/enums/MetricNames';

export const PerformancePageWrapper: FC = () => {
  const { insights: { BASE_ROUTE: BASE_INSIGHTS_ROUTE, PERFORMANCE } } = constants.routes;
  const { snapshotId } = useParams();
  const [period, setPeriod] = useState(TimeAgo.TwoWeeks);
  const navigate = useNavigate();

  const { metrics, isLoading, snapshot, setSnapshot } = useFetchMetrics(period, snapshotId);

  const handlePeriodChange = useCallback((newPeriod: TimeAgo) => {
    if (snapshotId) {
      setSnapshot(undefined);
      navigate(`/${BASE_INSIGHTS_ROUTE}/${PERFORMANCE}`);
    }
    setPeriod(newPeriod);
  }, [BASE_INSIGHTS_ROUTE, PERFORMANCE, navigate, setSnapshot, snapshotId]);

  return (
    (!isLoading ? (

      <PerformancePage
        dailyFindingsActivitiesMetric={metrics[PerformanceMetricNames.DailyFindingsActivities]}
        detectionRateMetric={metrics[PerformanceMetricNames.DetectionRate]}
        exposureWindowMetric={metrics[PerformanceMetricNames.ExposureWindow]}
        findingsOverTimeMetric={metrics[PerformanceMetricNames.FindingsOverTime]}
        handlePeriodChange={handlePeriodChange}
        mttrMetric={metrics[PerformanceMetricNames.MTTR]}
        period={period}
        planItemFindingsMetric={metrics[PerformanceMetricNames.PlanItemFindings]}
        resourceTypeFindingsMetric={metrics[PerformanceMetricNames.ResourceTypeFindings]}
        snapshot={snapshot}
      />
    )
      : <LoadingBar />
    )
  );
};
