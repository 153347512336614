import styles from './TeamsLeaderboardEmptyState.module.scss';

import { JittyNotFoundDoubleShadow } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';

export const TeamsLeaderboardEmptyState: React.FC = () => (
  <div className={styles.emptyStateWrapper}>

    <div className={styles.emptyState}>
      <JitIcon data-testid='noTeamsFoundIcon' icon={JittyNotFoundDoubleShadow} size={170} />

      <JitText bold size='l' text='pages.overview.teamsLeaderboard.emptyStateTitle' />

      <JitText size='m' text='pages.overview.teamsLeaderboard.emptyStateDescription' textAlign='center' />
    </div>
  </div>
);

