import { Tab, TabItemTypes } from './types';

const removeHiddenNestedTabs = (tabs: Tab[]) => {
  const tabsWithoutHiddenNestedTabs: Tab[] = tabs.map((tab) => {
    if (tab.type === TabItemTypes.group) {
      return {
        ...tab,
        items: tab.items.filter((item) => !item.shouldHide),
      };
    }

    return tab;
  });

  return tabsWithoutHiddenNestedTabs;
};

export const getNotHiddenTabs = (tabs: Tab[]) => {
  let filteredTabs = tabs.filter((tab) => !tab.shouldHide);
  filteredTabs = removeHiddenNestedTabs(filteredTabs);

  return filteredTabs;
};
