import { FC, UIEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './ChildTeamsList.module.scss';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { JitLink } from 'components/JitLink/JitLink';
import { calcShouldFetchMore } from 'components/JitTable/utils';
import { JitText } from 'components/JitText/JitText';
import { useTeamsContext } from 'context/TeamsContext/TeamsContext';
import { constants } from 'globalConstants';
import { TEAM_ROW_APPROXIMATE_HEIGHT as CHILD_TEAM_ROW_APPROXIMATE_HEIGHT } from 'pages/TeamsPage/constants';
import colors from 'themes/colors.module.scss';
import { IPaginatedState } from 'types/interfaces/IPaginatedState';
import { ITeam } from 'types/interfaces/Teams/ITeam';

interface Props {
  childTeams: IPaginatedState<ITeam>;
}

export const ChildTeamsList: FC<Props> = ({ childTeams }) => {
  const navigate = useNavigate();
  const { getNextChildTeams } = useTeamsContext();
  const { TEAMS } = constants.routes;
  const navigateToTeam = (id: string) => {
    navigate(`/${TEAMS}/${id}`);
  };

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    if (childTeams.isLoading || childTeams.hasReachedEnd) return;

    const shouldFetchMore = calcShouldFetchMore(event, CHILD_TEAM_ROW_APPROXIMATE_HEIGHT);
    if (shouldFetchMore) {
      getNextChildTeams();
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <JitText data-testid='childTeamsListTitle' muted noWrap params={{ childTeamsCount: childTeams.data.length }} size='s' text='pages.teams.teamDetails.childTeamsList.title' />

        {!childTeams.data.length && <JitText data-testid='noChildTeams' muted text='pages.teams.teamDetails.childTeamsList.noChildTeams' />}
      </div>

      <div className={styles.listWrapper} onScroll={handleScroll} style={{ opacity: childTeams.isLoading ? 0.5 : 1 }}>
        {childTeams.data.map((childTeam) => (
          <div key={childTeam.id} className={styles.memberRow}>
            <JitLink
              color={colors.blue02}
              onClick={() => navigateToTeam(childTeam.id)}
              sx={{ cursor: 'pointer' }}
              underline='none'

            >
              <JitText addUnderlineOnHover color={colors.blue02} text={childTeam.name} />
            </JitLink>
          </div>
        ))}
      </div>

      <div className={styles.loadingSpinnerWrapper}>
        {childTeams.isLoading && <CircularLoadingSpinner size='large' />}
      </div>
    </div>
  );
};
