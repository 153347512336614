import { getAllPlanItemTemplates, getAllTemplateWorkflows } from 'services/PlanService/PlanService';
import { IControlOption, IDictionary, IPlanItemTemplate } from 'types/interfaces';
import { IWorkFlowJob } from 'types/interfaces/PlanTemplate/IWorkflowTemplate';

export const workFlowTemplateJobToIControlOption = (job: IWorkFlowJob, planItemTemplate: IPlanItemTemplate, isScheduled: boolean = false): IControlOption[] => {
  const controlOptions: IControlOption[] = [];
  job.steps.forEach((step) => {
    if (!step.tags?.is_hidden) {
      const controlOption: IControlOption = {
        code_owner: 'jitsecurity',
        control_name: step.name,
        default_command_args: step.with?.args || '',
        description: planItemTemplate.description,
        docker_image_path: step.uses,
        github_action_version: '',
        id: planItemTemplate.slug,
        is_public: true,
        links: step.tags?.links || {},
        name: step.name,
        security_tool: step.tags?.security_tool || step.name,
        supported_languages: job.tags?.languages || [],
        item_name: planItemTemplate.name,
        item_slug: planItemTemplate.slug,
        is_scheduled: isScheduled,
      };
      controlOptions.push(controlOption);
    }
  });
  return controlOptions;
};

export const getIControlOptionsFromWorkflowTemplates = async () => {
  const controlOptions: IControlOption[] = [];
  const allWorkflowTemplatesRes = await getAllTemplateWorkflows() || [];
  const allPlanItemTemplatesRes = await getAllPlanItemTemplates() || [];
  allWorkflowTemplatesRes.forEach((workflowTemplate) => {
    Object.values(workflowTemplate.parsedContent.jobs).forEach((job) => {
      const planItemsFilteredByWorkflow = allPlanItemTemplatesRes.filter((planItem) => planItem.workflows?.find((workflow: IDictionary<string>) => workflow.uses.includes(workflowTemplate.slug)));

      planItemsFilteredByWorkflow.forEach((planItem) => {
        controlOptions.push(...workFlowTemplateJobToIControlOption(job, planItem, workflowTemplate.isScheduled));
      });
    });
  });
  return controlOptions;
};
