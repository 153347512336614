import { useCallback } from 'react';

import { useTenantService } from 'services/TenantService/useTenantService';
import { IRole } from 'types/interfaces/TenantService/IRole';

export const useGetInvitationLink = () => {
  const { generateInvitationLink } = useTenantService();

  const getInvitationLink = useCallback(async (role: IRole) => {
    const invitationTokenResponse = await generateInvitationLink({
      role,
    });
    if (invitationTokenResponse?.data) {
      const { invitation_link: invitationLink } = invitationTokenResponse.data;
      return invitationLink;
    }
    return null;
  }, [generateInvitationLink]);

  return { getInvitationLink };
};
