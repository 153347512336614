import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';

import { DEPLOYMENT_TRIGGER } from '../ConfigurationTrigger/constants';
import { getPlanItemTriggerConfiguration } from '../ConfigurationTrigger/utils';

import { SlackIcon } from 'assets';
import { DialogCard } from 'components/JitDialogs/DialogCard/DialogCard';
import { SlackIntegrationDialog, SlackIntegrationDialogStage } from 'components/JitDialogs/SlackIntegrationDialog/SlackIntegrationDialog';
import { useConfigurationsContext } from 'context/ConfigurationsContext/ConfigurationsContext';
import { useCommitConfigurations } from 'context/ConfigurationsContext/hooks';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import { getCurrentInstallationSortedByStatus } from 'pages/IntegrationsPage/utils/getCurrentInstallationSortedByStatus';
import { getSlackRedirectUri, SlackRedirectParams } from 'pages/IntegrationsPage/utils/getSlackRedirectUri';
import { groupInstallationsByVendor } from 'pages/IntegrationsPage/utils/groupInstallationsByVendor';
import { Vendor } from 'types/enums';
import { ApplicationTypes } from 'types/interfaces';

interface Props {
  applicationType: ApplicationTypes;
  dialogCardWidth?: string;
  dialogCardMinHeight?: string;
  dialogCardMaxHeight?: string;
  setIsDoneStep: Dispatch<SetStateAction<boolean>>;
  planItemSlug: string;
  stepIndex: number;
}

export const SlackNotifications: FC<Props> = ({
  applicationType, dialogCardWidth, dialogCardMinHeight, dialogCardMaxHeight, setIsDoneStep, planItemSlug, stepIndex,
}) => {
  const { isCommittingConfigurations, configurations } = useConfigurationsContext();
  const { installations, isLoadingInstallation, hasTriedFetchInstallations, getIntegrations } = useTenantContext();
  const installationsByVendor = groupInstallationsByVendor(installations);
  const installation = getCurrentInstallationSortedByStatus(Vendor.SLACK, installationsByVendor);

  const integrations = getIntegrations();
  const slackIntegration = integrations.find((integration) => integration.vendor === Vendor.SLACK);
  const [showSlackConfigureDialog, setShowSlackConfigureDialog] = useState(false);
  const commitConfigurations = useCommitConfigurations();
  const currentHrefWithoutQueryParams = window.location.href.split('?')[0];

  const slackRedirectParams: SlackRedirectParams = {
    httpRedirect: currentHrefWithoutQueryParams,
    planItemSlug,
    stepIndex: stepIndex.toString(),
  };

  const isDeploymentTrigger = useMemo(() => {
    const triggerConfiguration = getPlanItemTriggerConfiguration(configurations, planItemSlug, applicationType);
    return !!triggerConfiguration && triggerConfiguration.trigger === DEPLOYMENT_TRIGGER;
  }, [configurations, planItemSlug, applicationType]);

  const slackRedirectUri = getSlackRedirectUri(slackIntegration?.installation_path, slackRedirectParams);
  useEffect(() => {
    setIsDoneStep(!isDeploymentTrigger || !!installation);
  }, [installation, setIsDoneStep, isDeploymentTrigger]);

  const isLoading = useMemo(() => !hasTriedFetchInstallations || isLoadingInstallation
    || isCommittingConfigurations || !slackIntegration, [hasTriedFetchInstallations,
    isLoadingInstallation,
    isCommittingConfigurations,
    slackIntegration]);
  return (
    <div data-testid='SlackNotifications'>
      <SlackIntegrationDialog
        isOpen={showSlackConfigureDialog}
        onClose={() => setShowSlackConfigureDialog(false)}
        stage={{ type: SlackIntegrationDialogStage.TENANT }}
      />

      <DialogCard
        configureText='configurations.integrations.configure'
        description='configurations.integrations.slack.description'
        icon={SlackIcon}
        installation={installation}
        isLoading={isLoading}
        maxHeight={dialogCardMaxHeight}
        minHeight={dialogCardMinHeight}
        onConfigure={() => { setShowSlackConfigureDialog(true); }}
        onConnect={async () => {
          await commitConfigurations();
          window.open(slackRedirectUri, '_self');
        }}
        vendor='slack'
        width={dialogCardWidth}
      />
    </div>
  );
};
