import { startCase } from 'lodash';
import { ReactElement } from 'react';

import { FILE_ERROR_TOAST_ID } from '../constants';

import styles from './getFileErrorToast.module.scss';

import { JitText, JitTextProps } from 'components/JitText/JitText';
import { config } from 'config';
import { IActiveToast } from 'context/ToastsContext/interfaces';
import colors from 'themes/colors.module.scss';
import { ToastType } from 'types/enums';
import { FileStatus } from 'types/enums/FileStatus';
import { IFileMetadata } from 'types/interfaces/Files/IFileMetadata';
import { capitalizeFirstLetter } from 'utils';

export const getToastContent = (fileMetadata: IFileMetadata): ReactElement => {
  const fileLink = (fileMetadata.owner && fileMetadata.repo && fileMetadata.filePath && fileMetadata.branch)
    ? config.getGithubRefFileLink(fileMetadata.owner, fileMetadata.repo, fileMetadata.branch, fileMetadata.filePath) : undefined;
  const fileRefLink = (fileMetadata.owner && fileMetadata.repo && fileMetadata.filePath && fileMetadata.lastValidCommitRef)
    ? config.getGithubRefFileLink(fileMetadata.owner, fileMetadata.repo, fileMetadata.lastValidCommitRef, fileMetadata.filePath) : undefined;

  const fileTextComponentProps: Partial<JitTextProps> = { color: colors.white,
    noWrap: true,
    withUnderline: !!fileLink,
    text: fileMetadata.filePath };
  const reasonStatuses = [FileStatus.DUPLICATED_KEYS, FileStatus.INVALID_SCHEMA];
  const reasonComponent = reasonStatuses.includes(fileMetadata.status) ? `because of: ${startCase(fileMetadata.status)}` : <p />;

  return (
    <>
      <JitText
        bold
        className={styles.textWrapper}
        components={{ IntegrationName: capitalizeFirstLetter(fileMetadata.type.split('_')[0]) }}
        data-testid='file-error-toast-title'
        noWrap
        text='toasts.integrationFileError.title'
      />

      <JitText
        className={styles.textWrapper}
        components={{ FileLink: fileLink ? (
          <JitText
            className={styles.integrationFileLink}
            {...fileTextComponentProps}
            data-testid='file-error-toast-subtitle'
            onClick={() => window.open(fileLink)}
          />
        ) : <JitText {...fileTextComponentProps} />,
        Reason: reasonComponent }}
        noWrap
        text='toasts.integrationFileError.subtitle'
      />

      {fileRefLink && (
      <JitText
        className={styles.textWrapper}
        components={{ CommitLink: <JitText
          className={styles.lastValidCommitLink}
          color={colors.white}
          data-testid='file-error-toast-valid-commit'
          onClick={() => window.open(fileRefLink)}
          text='toasts.integrationFileError.clickValidCommit'
          withUnderline
        /> }}
        noWrap
        text='toasts.integrationFileError.validCommit'
      />

      )}
    </>
  );
};
export const getFileErrorToasts = (filesMetadata?: IFileMetadata[]): IActiveToast[] | undefined => filesMetadata?.filter(
  (fileMetadata) => ![FileStatus.VALID, FileStatus.EMPTY].includes(fileMetadata.status),
).map((fileMetadata) => ({
  type: ToastType.SystemError,
  overrideProps: {
    content: getToastContent(fileMetadata),
  },
  toastId: FILE_ERROR_TOAST_ID,
}));
