import { useCallback, useEffect } from 'react';

import { useSlackContext } from '../SlackContext';

import { useSlackService } from 'services/SlackService/useSlackService';

export const useGetSlackChannels = () => {
  const { slackChannels, setSlackChannels, setIsSlackChannelsLoading, isSlackChannelsLoading } = useSlackContext();
  const { getSlackChannels } = useSlackService();

  const initSlackChannels = useCallback(async () => {
    setIsSlackChannelsLoading(true);
    const slackChannelsResponse = await getSlackChannels();
    setSlackChannels(slackChannelsResponse?.data);
    setIsSlackChannelsLoading(false);
  }, [setIsSlackChannelsLoading, setSlackChannels]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!slackChannels?.length) {
      initSlackChannels();
    }
  }, [initSlackChannels, slackChannels?.length]);

  return {
    slackChannels,
    isSlackChannelsLoading,
  };
};
