import { FC } from 'react';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  label: string,
  text: string,
}

export const PlanItemsSummaryLabel: FC<Props> = ({ label, text }) => (
  <>
    <JitText
      color={colors.gray}
      size='s'
      text={label}
    />

    <JitText
      color={colors.lightGray}
      size='s'
      text={text}
    />
  </>
);
