import { FC } from 'react';

import styles from './ShareSnapshotButton.module.scss';

import { Users } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';

interface Props {
  onClick: () => void;
  isSnapshotMode: boolean;
}

export const ShareSnapshotButton: FC<Props> = ({ onClick, isSnapshotMode }) => (
  <JitTooltip data-testid='ShareSnapshotButton-tooltip' placement='top' title={isSnapshotMode && 'pages.performance.shareSnapshot.tooltipText'}>
    <JitButton data-testid='ShareSnapshotButton' disabled={isSnapshotMode} disableRipple height={34} onClick={onClick} variant='contained'>
      <div className={styles.buttonContent}>
        <JitIcon icon={Users} />

        <JitText noWrap size='l' text='pages.performance.shareSnapshot.openDialogButtonText' />
      </div>
    </JitButton>
  </JitTooltip>
);
