import { FC } from 'react';
import { TooltipProps } from 'recharts';

import styles from './DetectionRate.module.scss';

import { JitText } from 'components/JitText/JitText';
import {
  DAYS_WITH_DATA_GRAPH_NAME,
  DAYS_WITHOUT_DATA_GRAPH_NAME,
} from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/DetectionRate/constants';
import { IDetectionRateDay, IEmptyDetectionRateDay } from 'types/interfaces/Metrics/PerformanceMetrics/DetectionRate';
import { formatDate } from 'utils';

export const TooltipFormatter: FC<TooltipProps<string, string>> = ({ payload }) => {
  const hoveredPoints = payload || [];
  let dataRecord = hoveredPoints.find((point) => point.name === DAYS_WITH_DATA_GRAPH_NAME)?.payload as IDetectionRateDay | IEmptyDetectionRateDay | undefined;
  if (!dataRecord) dataRecord = hoveredPoints.find((point) => point.name === DAYS_WITHOUT_DATA_GRAPH_NAME)?.payload;

  if (!dataRecord) return null;

  const dataRecordWithBackfill = {
    findingsCount: 0,
    failedExecutions: 0,
    executionsCount: 0,
    detectionRate: 0,
    ...dataRecord,
  } as IDetectionRateDay;

  const tooltipContentItems = [{
    text: 'pages.performance.graphs.detectionRate.tooltip.detectedFindings',
    value: dataRecordWithBackfill.findingsCount,
  }, {
    text: 'pages.performance.graphs.detectionRate.tooltip.failedExecutions',
    value: dataRecordWithBackfill.failedExecutions,
  }, {
    text: 'pages.performance.graphs.detectionRate.tooltip.totalExecutions',
    value: dataRecordWithBackfill.executionsCount,
  }];

  return (
    <div className={styles.tooltipWrapper}>
      <JitText muted size='xs' text={formatDate(dataRecordWithBackfill.date, false)} />

      <JitText bold size='xxl' text={`${dataRecordWithBackfill.detectionRate}%`} />

      {tooltipContentItems.map((item) => (

        <div key={item.text} className={styles.tooltipFindingsAmount}>

          <JitText size='s' text={item.text} />

          <JitText bold size='s' text={item.value} />

        </div>
      ))}
    </div>
  );
};
