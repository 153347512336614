import { NotificationError, NotificationSuccess } from 'assets';
import { SuccessToastBarContent } from 'components/CreateTicketButton/components/SuccessToastBarContent';
import { OpenTicket } from 'components/CreateTicketButton/VendorConfig';
import { JitText } from 'components/JitText/JitText';
import { useToastsContext } from 'context/ToastsContext/ToastsContext';
import { logError, logInfo } from 'services/logger/logger';
import colors from 'themes/colors.module.scss';
import { ToastType } from 'types/enums';
import { IFinding } from 'types/interfaces';
import { IntegrationProvider } from 'types/interfaces/Integrations/IIntegration';
import { TicketError, TicketResponse } from 'types/interfaces/TicketManager/Ticket';

const generateTitle = (findings: IFinding[]) => {
  if (findings.length === 1) {
    return findings[0].name;
  }
  return `Jit findings - ${new Date().toLocaleDateString()}`;
};

export const useCreateTicket = () => {
  const { showToast } = useToastsContext();
  // TODO: once the backend will be ready we should handle errors and success in some other way
  // maybe we should check the status code in the response for errors.

  const displayErrorToast = (error: string, findings: IFinding[]) => {
    logError(`Failed to create ticket. Error: ${error}. For findings ${findings.map((finding) => finding.id).join(', ')}`);
    showToast({
      type: ToastType.Error,
      overrideProps: {
        icon: NotificationError,
        title: 'tickets.failedToast.title',
        subtitle: 'tickets.failedToast.subtitle',
        content: <JitText color={colors.cards} text={error} />,
        secondsToClose: 30,
      },
    });
  };

  const createTicket = async (
    findings: IFinding[],
    integrationId: string,
    openTicketFunction: OpenTicket,
    provider: IntegrationProvider,
    title: string | null = null,
  ): Promise<string | undefined> => {
    const ticketTitle = title || generateTitle(findings);
    const response = await openTicketFunction(integrationId, ticketTitle, findings, provider).catch((error) => {
      const errorMessage = error?.response?.data?.error;
      return {
        status: 400,
        data: { message: errorMessage } as TicketError,
      };
    });

    if (response?.status === 400) {
      displayErrorToast((response?.data as TicketError).message, findings);
      return undefined;
    }
    const typedData = response?.data as TicketResponse;
    logInfo(`Ticket created successfully with id ${typedData.id}`);
    if (typedData.link) {
      showToast({
        type: ToastType.Success,
        overrideProps: {
          icon: NotificationSuccess,
          title: 'tickets.successToast.title',
          content: <SuccessToastBarContent link={typedData.link} />,
          secondsToClose: 30,
        },
      });
    }
    return typedData.link;
  };
  return { createStory: createTicket };
};
