import { FC, useCallback, useState } from 'react';

import styles from './LogsLink.module.scss';

import { JitText } from 'components/JitText/JitText';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { ExecutionLogDetails, LogDialog } from 'pages/PipelinesPage/components/LogDialog';
import { useExecutionsService } from 'services/ExecutionsService/useExecutionsService';
import colors from 'themes/colors.module.scss';
import { IGetExecutionLogResponse, IJobFieldsNew, IMouseEvent } from 'types/interfaces';
import { stopPropagation } from 'utils';

interface Props {
  job: IJobFieldsNew;
  jitEventId?: string;
}

export const LogsLink: FC<Props> = ({
  job,
  jitEventId,
}) => {
  const [shouldShowLogDialog, setShouldShowLogDialog] = useState<boolean>(false);
  const [areLogsLoading, setAreLogsLoading] = useState<boolean>(false);
  const [executionLogDetails, setExecutionLogDetails] = useState<ExecutionLogDetails>();
  const [hasError, setHasError] = useState<boolean>(false);
  const { getExecutionLog } = useExecutionsService();

  const updateStateBasedOnResponse = useCallback((response: IGetExecutionLogResponse | undefined) => {
    setAreLogsLoading(false);

    if (!response) { // Failed to get logs
      setHasError(true);
    } else {
      setExecutionLogDetails({
        text: response.content,
        isTruncated: response.truncated,
        downloadLink: response.presigned_url_read,
      });
    }
  }, []);

  const handleClickOnLogs = (e: IMouseEvent) => {
    stopPropagation(e); // TODO: Why is this necessary here?

    // We want to fetch the log only if it wasn't successfully fetched before
    if (!executionLogDetails) {
      setAreLogsLoading(true);
      setHasError(false);
      getExecutionLog(jitEventId || '', job.executionId || '')
        .then((response) => {
          if (response?.status && response.status <= 299) {
            updateStateBasedOnResponse(response?.data);
          } else updateStateBasedOnResponse(undefined);
        }).catch(() => updateStateBasedOnResponse(undefined)); // Avoid an infinite loading state
    }

    setShouldShowLogDialog(true);
  };

  return (
    <div data-testid='LogsLink'>
      <TooltipOnlyOnOverflow tooltipText='pages.pipelines.pipelinesTable.executionLogs.viewLog'>
        <JitText
          addUnderlineOnHover
          className={`${styles.overflowEllipsis} ${styles.showLogs}`}
          color={colors.iris}
          data-testid='logs-link-clickable-text'
          noWrap
          onClick={(e: IMouseEvent) => handleClickOnLogs(e)}
          overflowHiddenEllipsis
          size='s'
          text='pages.pipelines.pipelinesTable.executionLogs.viewLog'
        />
      </TooltipOnlyOnOverflow>

      <LogDialog
        executionLogDetails={executionLogDetails}
        hasError={hasError}
        isLoading={areLogsLoading}
        isOpen={shouldShowLogDialog}
        onClose={(e) => {
          stopPropagation(e as IMouseEvent);
          setShouldShowLogDialog(false);
        }}
      />
    </div>
  );
};
