import { FC } from 'react';

import { PageContent } from './components/PageContent/PageContent';
import styles from './TeamsPage.module.scss';

import { PageTitles } from 'components/PageTitles/PageTitles';

export const TeamsPage: FC = () => (
  <div className={styles.wrapper}>
    <PageTitles subtitle='pages.teams.subtitle' title='pages.teams.title' />

    <PageContent />
  </div>
);
