import { FC } from 'react';
import { TooltipProps } from 'recharts';

import styles from './FindingsOverTimeGraph.module.scss';

import { JitText } from 'components/JitText/JitText';
import { formatDate } from 'utils';

interface FindingsOverTimeTooltipProps extends TooltipProps<string, string> {
  closestLineName: string | null;
}

export const FindingsOverTimeTooltip: FC<FindingsOverTimeTooltipProps> = ({
  payload,
  label,
  closestLineName,
}) => {
  const dataRecord = payload?.[0]?.payload;

  if (!dataRecord) return null;

  const tooltipContentItems = [
    {
      text: 'pages.securityImpact.securityPosture.detectedFindings.detectedFindings',
      value: dataRecord.detectedFindings,
      lineName: 'detectedFindings',
    },
    {
      text: 'pages.securityImpact.securityPosture.detectedFindings.resolvedFindings',
      value: dataRecord.resolvedFindings,
      lineName: 'resolvedFindings',
    },
  ];

  const displayItem = tooltipContentItems.find((item) => item.lineName === closestLineName);

  if (!displayItem) return null;

  return (
    <div className={styles.tooltipWrapper}>
      <JitText muted size='xs' text={formatDate(label, false)} />

      <div>
        <JitText size='s' text={displayItem.text} />

        <JitText bold size='s' text={displayItem.value} />
      </div>
    </div>
  );
};
