import { t } from 'i18next';
import { FC } from 'react';

import { ConfirmDialog } from 'components/JitDialogs/ConfirmDialog';
import { ActionButtonVariant } from 'types/interfaces';

interface Props {
  plan: { title: string }
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}
export const RemoveGoalDialog: FC<Props> = ({ plan, isOpen, onConfirm, onClose }) => (
  <ConfirmDialog
    actionButtons={[{
      label: 'pages.securityPlans.removeGoalDialog.cancel',
      handleClick: onClose,
      variant: ActionButtonVariant.SECONDARY,
    },
    {
      label: 'pages.securityPlans.removeGoalDialog.remove',
      handleClick: onConfirm,
      variant: ActionButtonVariant.DANGER,
    }]}
    isOpen={isOpen}
    message='pages.securityPlans.removeGoalDialog.description'
    onClose={onClose}
    subMessage={`${t('pages.securityPlans.removeGoalDialog.the')} 
        <b>${plan.title}
         ${t('pages.securityPlans.removeGoalDialog.setBackAsGoal')}`}
    title='pages.securityPlans.removeGoalDialog.title'
  />
);
