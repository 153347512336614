import { FC, useMemo } from 'react';

import styles from './JitExternalLink.module.scss';

import { OpenInNewTab } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { IAnalyticsEvent } from 'types/interfaces';

interface Props {
  href: string;
  text: string;
  testId?: string
  size?: 's' | 'm' | 'l';
  className?: string;
  wrapperClassName?: string;
  analytics?: IAnalyticsEvent
  textColor?: string;
  onClick?: () => void;
  label?: string;
  showIcon?: boolean;
  withUnderline?: boolean;
}

export const JitExternalLink: FC<Props> = ({ href, text, size, className, wrapperClassName, analytics,
  textColor = colors.iris, onClick, showIcon = true, label, withUnderline, testId = 'jit-external-link' }) => {
  const linkContentClassName = useMemo(() => (className ? `${styles.linkContent} ${className}` : styles.linkContent), [className]);

  return (
    <div className={styles.root}>
      <JitLink
        analytics={analytics}
        className={wrapperClassName}
        data-testid={testId}
        href={href}
        onClick={onClick}
        rel='noreferrer'
        style={{
          textDecoration: withUnderline ? 'underline' : 'none',
          color: textColor,
        }}
        target='_blank'
      >
        <div className={linkContentClassName}>
          <JitText
            color={textColor}
            data-testid='jit-external-link-text'
            size={size}
            text={text}
          />

          {showIcon && <JitIcon color={textColor} data-testid='jit-external-link-icon' icon={OpenInNewTab} size={13} /> }

        </div>
      </JitLink>

      {label && <JitText color={colors.lightGray} size='s' text={label} />}
    </div>
  );
};
