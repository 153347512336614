import { FC } from 'react';

import styles from './AvgValueAndChangeRate.module.scss';

import { JitText } from 'components/JitText/JitText';
import { PercentageChip } from 'components/PercentageChip/PercentageChip';

interface Props {
  avgValue?: string | null;
  changeRate?: number | null
  positiveTrendIndicatorDirection?: 'up' | 'down';
}

export const AvgValueAndChangeRate: FC<Props> = ({ avgValue, changeRate, positiveTrendIndicatorDirection }) => {
  if (avgValue === null || avgValue === undefined) return null;
  return (
    <div className={styles.wrapper}>
      <JitText bold data-testid='avgValue' size='l' text={avgValue} />

      <PercentageChip percentage={changeRate} positiveTrendIndicatorDirection={positiveTrendIndicatorDirection!} />
    </div>
  );
};
