import { FC } from 'react';

import { ResourcePlanItemsStatusesTableData } from '../../ResourcePlanItemsStatusesPage';

import { HoverRowIcons } from './HoverRowIcons/HoverRowIcons';
import { useGetColumns } from './useGetColumns';

import { ElementToShowOnHoverProps } from 'components/JitTable/components/JitTableRow/JitTableRow';
import { useGetTableInstance } from 'components/JitTable/hooks/useGetTableInstance';
import { JitTable } from 'components/JitTable/JitTable';

interface Props {
  assetName: string;
  isLoading: boolean;
  tableData: ResourcePlanItemsStatusesTableData[];
  teamName?: string;
}

export const ResourcePlanItemsStatusesTable: FC<Props> = ({ assetName, isLoading, tableData, teamName }) => {
  const columns = useGetColumns(assetName, isLoading ? undefined : tableData.length);
  const tableInstance = useGetTableInstance(columns, tableData);

  const elementToShowOnRoweHover = ({ row }: ElementToShowOnHoverProps) => {
    const { slug } = row.original as ResourcePlanItemsStatusesTableData;

    return (
      <HoverRowIcons assetName={assetName} slug={slug} teamName={teamName} />
    );
  };

  return (
    <JitTable
      cellVerticalAlign='center'
      ElementToShowOnRowHover={elementToShowOnRoweHover}
      emptyTableText='pages.teams.resourcePlanItemsStatuses.planItemsTable.noData'
      isFetching={isLoading}
      isLoading={isLoading}
      jittyIcon={null}
      showPagination={false}
      tableInstance={tableInstance}
    />
  );
};
