import { FC, ReactNode } from 'react';

import styles from './DialogContentWrapper.module.scss';

import { JitFavicon } from 'assets';
import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { ISvg } from 'types/interfaces';
import { ActionButton } from 'types/interfaces/ActionButton/ActionButton';

interface Props {
  children?: ReactNode,
  actionButtons?: ActionButton[];
  leftButtonsElement?: JSX.Element;
  icon?: FC<ISvg>
  iconSize?: number;
  buttonDescription?: string;
}

export const DialogContentWrapper: FC<Props> = ({ actionButtons, buttonDescription, icon, iconSize, children, leftButtonsElement }) => (
  <div className={styles.wrapper}>
    <div className={styles.iconWrapper}>
      <JitIcon icon={icon || JitFavicon} size={iconSize || 37} />
    </div>

    <div className={styles.content}>
      {children}
    </div>

    {(actionButtons || leftButtonsElement) && (
    <div className={buttonDescription ? styles.buttonWithDescriptionWrapper : styles.buttonWrapper}>
      <JitText data-testid='buttonDescription' text={buttonDescription} />

      <div className={styles.actionButtonWrapper}>
        <div className={styles.actionButtons}>
          {actionButtons?.map((actionButton) => <JitActionButton key={`actionButton-${actionButton.label}`} actionButton={actionButton} />)}
        </div>

        {leftButtonsElement}
      </div>
    </div>
    )}
  </div>
);
