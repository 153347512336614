import { FC } from 'react';

import styles from './IntegrationLink.module.scss';

import { OpenInNewTab } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { Vendor } from 'types/enums';
import { getPermissionLinkForVendor } from 'utils/functions/matchVendorToIntegration';

interface Props {
  vendor: Vendor;
}

export const IntegrationLink: FC<Props> = ({ vendor }) => (
  <div>
    <JitText color={colors.gray} text='pages.plan.itemDetails.info.activateToConnect' />

    <JitLink
      data-testid='integration-link'
      href={getPermissionLinkForVendor(vendor)}
      style={{
        textDecoration: 'none',
        cursor: 'pointer',
      }}
      target='_blank'
    >
      <div className={styles.linkChildren}>
        <JitText color={colors.iris} text={`pages.plan.configurationDialog.${vendor}Integration.link`} />

        <JitIcon color={colors.iris} icon={OpenInNewTab} size={12} />
      </div>
    </JitLink>
  </div>
);
