import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { IFinding } from 'types/interfaces';

export const useSelectedFindings = (findings: IFinding[]) => {
  const [selectedFindingIds, setSelectedFindingIds] = useState<string[]>([]);

  const onChangeFindingsSelection = useCallback((findingId: string) => {
    let newSelectedFindings;
    if (selectedFindingIds.includes(findingId)) {
      newSelectedFindings = selectedFindingIds.filter((selectedFindingId) => selectedFindingId !== findingId);
    } else {
      newSelectedFindings = [...selectedFindingIds, findingId];
    }
    setSelectedFindingIds(newSelectedFindings);
  }, [selectedFindingIds]);

  const findingsWithSelectedProperty = useMemo(() => findings.map((finding) => ({
    ...finding,
    isSelectedDetails: {
      isSelected: selectedFindingIds.includes(finding.id),
      onChangeSelection: onChangeFindingsSelection,
      findingId: finding.id,
    },
  })), [findings, selectedFindingIds, onChangeFindingsSelection]);

  const setPageSelected = useCallback((isPageSelected: boolean) => {
    const updatedSelectedFindingIds = isPageSelected ? findings.map((finding) => finding.id) : [];
    setSelectedFindingIds(updatedSelectedFindingIds);
  }, [findings]);

  const isPageSelected = useMemo(() => !isEmpty(findingsWithSelectedProperty)
    && findingsWithSelectedProperty.every((finding) => finding.isSelectedDetails.isSelected), [findingsWithSelectedProperty]);

  const resetSelectedFindings = useCallback(() => setSelectedFindingIds([]), []);

  useEffect(() => {
    setSelectedFindingIds([]);
  }, [findings]);

  return {
    selectedFindingIds,
    findingsWithSelectedProperty,
    setPageSelected,
    isPageSelected,
    resetSelectedFindings,
  };
};
