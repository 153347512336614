import colors from 'themes/colors.module.scss';

export const markdownOptionsProps = {
  options: {
    overrides: {
      a: {
        props: {
          target: '_blank',
          style: {
            color: colors.iris,
          },
        },
      },
    },
    namedCodesToUnicode: {
      '#39': '\u0027',
    },
  },
};
