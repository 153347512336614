import { FC } from 'react';

import styles from './JitRectangleGrade.module.scss';

import { JitText, JitTextProps } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { getColorByGrade, getGradeByScore } from 'utils/functions/grades';

export type Size = 'l';

interface Props {
  score: number;
  size?: Size;
}

export const JitRectangleGrade: FC<Props> = ({ score, size = 'l' }) => {
  const grade = getGradeByScore(score);
  const color = getColorByGrade(grade);

  const sizeToStyleMap: {
    [key: string]: {
      wrapper: { padding: string, width: string, height: string },
      gradeText: JitTextProps,
      scoreText: JitTextProps,
    }
  } = {
    l: {
      wrapper: {
        padding: '8px 16px',
        width: '141px',
        height: '60px',
      },
      gradeText: {
        size: 'xxxxl',
      },
      scoreText: {
        size: 'l',
      },
    },
  };

  return (
    <div className={styles.root}>
      <div
        className={styles.gradeWrapper}
        data-testid='grade-wrapper'
        style={{
          backgroundColor: color,
          ...sizeToStyleMap[size].wrapper,
        }}
      >
        <JitText
          bold
          className={styles.grade}
          color={colors.cards}
          data-testid='grade'
          {...sizeToStyleMap[size].gradeText}
          text={grade}
        />

        <JitText
          bold
          color={colors.cards}
          data-testid='score'
          {...sizeToStyleMap[size].scoreText}
          text={`${score}%`}
        />
      </div>
    </div>
  );
};
