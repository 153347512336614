import { createContext, useContext, Dispatch, SetStateAction } from 'react';

import { IPaginatedState, initialPaginatedState } from 'types/interfaces/IPaginatedState';
import { ExpendableComponentGroup } from 'types/interfaces/Sbom/SbomComponentGroup';
import { SbomReportStatus } from 'types/interfaces/Sbom/SbomReportStatus';

interface ISbomContext {
  getSbomComponents: ({ resetPagination, searchValue, silent }: { resetPagination: boolean, searchValue: string, silent?: boolean }) => Promise<void>;
  sbomComponents: IPaginatedState<ExpendableComponentGroup>;
  setSbomComponents: Dispatch<SetStateAction<IPaginatedState<ExpendableComponentGroup>>>;
  toggleComponentExpended: (groupId: string) => void;
  reportStatus?: SbomReportStatus;
  isLoadingReportStatus: boolean;
  isPremiumFeatureEnabled: boolean;
  getReportStatus: () => void;
}

export const SbomContext = createContext<ISbomContext>({
  getSbomComponents: async () => {},
  sbomComponents: initialPaginatedState,
  setSbomComponents: () => {},
  toggleComponentExpended: () => {},
  isLoadingReportStatus: false,
  isPremiumFeatureEnabled: false,
  reportStatus: SbomReportStatus.ACTIVE,
  getReportStatus: () => {},
});

export const useSbomContext = () => useContext(SbomContext);
