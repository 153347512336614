import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import { useWebsocketSubscribe } from '../WebSocketContext/hooks/index';

import { useHandleTeamAssetsWebSocketNotification } from './hooks/useHandleTeamAssetsWebSocketNotification';
import { useHandleTeamMembersWebSocketNotification } from './hooks/useHandleTeamMembersWebSocketNotification';
import { useHandleTeamsWebSocketNotification } from './hooks/useHandleTeamsWebSocketNotification';

import { GetTeamsOptions, TeamsContext } from 'context/TeamsContext/TeamsContext';
import { constants } from 'globalConstants';
import { useAssetService } from 'services/AssetsService/useAssetService';
import { useTeamsService } from 'services/TeamsService/useTeamsService';
import { WebSocketNotificationTopics } from 'types/enums';
import { Queries } from 'types/enums/Queries';
import { IAsset } from 'types/interfaces/IAsset';
import { initialPaginatedState, IPaginatedState } from 'types/interfaces/IPaginatedState';
import { IGetTeamResponseItem, IMember, ITeam } from 'types/interfaces/Teams/ITeam';
import { TeamSortBy, TeamSortOrder } from 'types/interfaces/Teams/TeamSorting';

export interface ISpecificTeamState {
  team?: ITeam;
  isLoadingSpecificTeam: boolean;
  members: IPaginatedState<IMember>;
  childTeams: IPaginatedState<ITeam>;
  resources: IPaginatedState<IAsset>;
}

export const initialSpecificTeamState: ISpecificTeamState = {
  team: undefined,
  isLoadingSpecificTeam: false,
  members: initialPaginatedState,
  childTeams: initialPaginatedState,
  resources: initialPaginatedState,
};

export const TeamsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { time: { MINUTE } } = constants;
  const [specificTeam, setSpecificTeam] = useState<ISpecificTeamState>(initialSpecificTeamState);
  const [teams, setTeams] = useState<IPaginatedState<IGetTeamResponseItem>>(initialPaginatedState);
  const [areTeamsLoading, setAreTeamsLoading] = useState<boolean>(false);
  const [sortedBy, setSortedBy] = useState<TeamSortBy>(TeamSortBy.SCORE);
  const [sortOrder, setSortOrder] = useState<TeamSortOrder>(TeamSortOrder.DESC);
  const [isUploadingTeams, setIsUploadingTeams] = useState<boolean>(false);

  const {
    fetchTeams,
    fetchTeamById,
    fetchTeamChildren,
    fetchMembersById,
  } = useTeamsService();
  const queryClient = useQueryClient();
  const { getAllAssets } = useAssetService();

  const { websocketSubscribe } = useWebsocketSubscribe();
  const { handleTeamsWebSocketNotification } = useHandleTeamsWebSocketNotification({
    setTeams,
    setSpecificTeam,
    sortOrder,
    sortedBy,
    setIsUploadingTeams,
  });
  const { handleTeamAssetsWebSocketNotification } = useHandleTeamAssetsWebSocketNotification({ setSpecificTeam });
  const { handleTeamMembersWebSocketNotification } = useHandleTeamMembersWebSocketNotification({
    setTeams,
    setSpecificTeam,
  });

  const getSpecificTeamById = useCallback(async (teamId: string, includeChecks?: boolean, includePosition?: boolean) => {
    setSpecificTeam((prevTeam) => ({
      ...prevTeam,
      isLoadingSpecificTeam: true,
      members: {
        ...prevTeam.members,
        isLoading: true,
      },
      childTeams: {
        ...prevTeam.childTeams,
        isLoading: true,
      },
      resources: {
        ...prevTeam.resources,
        isLoading: true,
      },
    }));
    const resolvedRequests = await Promise.all([
      queryClient.fetchQuery([Queries.TeamById, {
        teamId,
        includeChecks,
        includePosition,
      }], fetchTeamById),
      fetchTeamChildren(teamId),
      fetchMembersById(teamId),
    ]);
    const [teamRes, childTeamsRes, membersRes] = resolvedRequests;
    setSpecificTeam((prevTeam) => ({
      ...prevTeam,
      team: teamRes,
      members: {
        ...prevTeam.members,
        isLoading: false,
        data: membersRes?.data || [],
        after: membersRes?.metadata?.after,
        hasReachedEnd: !membersRes?.metadata?.after,
      },
      childTeams: {
        ...prevTeam.childTeams,
        isLoading: false,
        data: childTeamsRes?.data || [],
        after: childTeamsRes?.metadata?.after,
        hasReachedEnd: !childTeamsRes?.metadata?.after,
      },
      resources: {
        ...prevTeam.resources,
        isLoading: true,
      },
    }
    ));
    if (teamRes) {
      const assets = await getAllAssets(teamRes.name);
      if (assets) {
        setSpecificTeam((prevTeam) => ({
          ...prevTeam,
          isLoadingSpecificTeam: false,
          resources: {
            ...prevTeam.resources,
            isLoading: false,
            data: assets,
            after: undefined,
            hasReachedEnd: true,
          },
        }));
      }
    } else {
      setSpecificTeam((prevTeam) => ({
        ...prevTeam,
        isLoadingSpecificTeam: false,
      }));
    }
  }, [fetchMembersById, fetchTeamById, fetchTeamChildren, getAllAssets, queryClient]);

  const getTeams = useCallback(async ({
    shouldResetState,
    searchValue,
    searchKey,
    memberSearch,
    sortBy,
    displayImage,
    displayMembers = true,
    userId,
    limit,
    page,
  }: GetTeamsOptions) => {
    setAreTeamsLoading(true);
    setTeams((prevTeam) => ({
      ...prevTeam,
      data: shouldResetState ? [] : prevTeam.data,
      isLoading: true,
    }));
    const after = shouldResetState ? undefined : teams.after;

    const fetchTeamOptions = {
      sortBy: sortBy || sortedBy,
      sortOrder,
      after,
      searchValue,
      searchKey,
      memberSearch,
      displayImage,
      displayMembers,
      userId,
      limit,
      page,
    };

    const response = await queryClient.fetchQuery(
      [Queries.Teams, fetchTeamOptions],
      fetchTeams,
    );

    let afterResponse = response?.metadata?.after;
    if (afterResponse === null) {
      afterResponse = undefined; // We don't want to send null to the API
    }
    const data = shouldResetState ? response?.data : [...teams.data, ...(response?.data || [])];
    setTeams((prevTeam) => ({
      ...prevTeam,
      isLoading: false,
      after: afterResponse,
      data: data || [],
      hasReachedEnd: !response?.metadata?.after,
    }));
    setAreTeamsLoading(false);
  }, [teams.after, teams.data, sortedBy, sortOrder, queryClient, fetchTeams]);

  const getNextChildTeams = useCallback(async () => {
    setSpecificTeam((prevTeam) => ({
      ...prevTeam,
      childTeams: {
        ...prevTeam.childTeams,
        isLoading: true,
      },
    }));
    const response = await fetchTeamChildren(specificTeam.team!.id, specificTeam.childTeams.after);
    setSpecificTeam((prevTeam) => ({
      ...prevTeam,
      childTeams: {
        ...prevTeam.childTeams,
        isLoading: false,
        after: response?.metadata?.after,
        data: [...prevTeam.childTeams.data, ...(response?.data || [])],
        hasReachedEnd: !response?.metadata?.after,
      },
    }));
  }, [fetchTeamChildren, specificTeam.childTeams.after, specificTeam.team]);

  const getNextMembers = useCallback(async () => {
    setSpecificTeam((prevTeam) => ({
      ...prevTeam,
      members: {
        ...prevTeam.members,
        isLoading: true,
      },
    }));
    const response = await fetchMembersById(specificTeam.team!.id, specificTeam.members.after);
    setSpecificTeam((prevTeam) => ({
      ...prevTeam,
      members: {
        ...prevTeam.members,
        isLoading: false,
        after: response?.metadata?.after,
        data: [...prevTeam.members.data, ...(response?.data || [])],
        hasReachedEnd: !response?.metadata?.after,
      },
    }));
  }, [fetchMembersById, specificTeam.members.after, specificTeam.team]);

  useEffect(() => {
    // set isUploadingTeams to false after 1 minute to make sure the user is not stuck in a loading state for infinite time
    // we might not get websocket notification for created teams
    if (isUploadingTeams) {
      const timeout = setTimeout(() => {
        setIsUploadingTeams(false);
      }, MINUTE);

      return () => clearTimeout(timeout);
    }

    return undefined;
  }, [MINUTE, isUploadingTeams]);

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.Team, handleTeamsWebSocketNotification);
  }, [handleTeamsWebSocketNotification, websocketSubscribe]);

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.Asset, handleTeamAssetsWebSocketNotification);
  }, [handleTeamAssetsWebSocketNotification, websocketSubscribe]);

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.TeamMembers, handleTeamMembersWebSocketNotification);
  }, [handleTeamMembersWebSocketNotification, websocketSubscribe]);

  const value = useMemo(() => ({
    specificTeam,
    teams,
    getTeams,
    getSpecificTeamById,
    getNextChildTeams,
    getNextMembers,
    isLoadingSpecificTeam: specificTeam.isLoadingSpecificTeam,
    areTeamsLoading,
    sortedBy,
    sortOrder,
    setSortedBy,
    setSortOrder,
    setTeams,
    isUploadingTeams,
    setIsUploadingTeams,
  }), [specificTeam,
    teams,
    getTeams,
    getSpecificTeamById,
    getNextChildTeams,
    getNextMembers,
    areTeamsLoading,
    sortedBy,
    sortOrder,
    isUploadingTeams]);

  return (
    <TeamsContext.Provider value={value}>
      {children}
    </TeamsContext.Provider>
  );
};
