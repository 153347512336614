import { useCallback, useMemo } from 'react';

import { BreadcrumbItem } from 'components/JitBreadcrumbs/JitBreadcrumbs';
import { renderLinkBreadCrumbItem, renderTextBreadCrumbItem } from 'components/JitBreadcrumbs/utils/textToBreadcrumbContent';
import { constants } from 'globalConstants';
import { GET_TOP_FINDINGS_PAGE_LIMIT } from 'services/TeamsService/constants';
import { FindingStatus, Severity } from 'types/enums';
import { FindingServiceViewMode } from 'types/enums/FindingServiceViewMode';
import { IAsset, IDictionary } from 'types/interfaces';
import { ITeam } from 'types/interfaces/Teams/ITeam';

interface Props {
  currentTeam: ITeam;
  relevantAsset?: IAsset;
  planItemSlug?: string;
  mode: FindingServiceViewMode;
}

type GetFilters = () => IDictionary<string | string[] | boolean[]>;
type PageElements = {
  getBreadcrumbItems: () => BreadcrumbItem[];
  getFilters: GetFilters;
  findingsLimit?: number;
};

export const useFindingsContent = ({ currentTeam, relevantAsset, planItemSlug, mode }: Props) => {
  const getPlanItemBreadcrumbs = useCallback(() => {
    const {
      teamsPortal: {
        BASE_ROUTE: BASE_TEAMS_PORTAL_ROUTE,
        PORTAL_TEAMS,
        PORTAL_RESOURCES,
      },
    } = constants.routes;

    const teamId = currentTeam?.id || '';
    const teamPageLink = `/${BASE_TEAMS_PORTAL_ROUTE}/${PORTAL_TEAMS}/${teamId}`;
    const resourcePageLink = `/${BASE_TEAMS_PORTAL_ROUTE}/${PORTAL_TEAMS}/${teamId}/${PORTAL_RESOURCES}/${relevantAsset?.asset_id}`;

    return [
      {
        content: renderLinkBreadCrumbItem(currentTeam?.name || '', teamPageLink, 'team-name'),
        itemKey: 'team-name',
      },
      {
        content: renderLinkBreadCrumbItem(relevantAsset?.asset_name || '', resourcePageLink, 'resource-name'),
        itemKey: 'resource-name',
      },
      {
        content: renderTextBreadCrumbItem(planItemSlug!, 'plan-item-slug'),
        itemKey: 'plan-item-slug',
      },
    ];
  }, [currentTeam, relevantAsset, planItemSlug]);

  const getTopFindingsBreadcrumbs = useCallback(() => {
    const {
      teamsPortal: {
        BASE_ROUTE: BASE_TEAMS_PORTAL_ROUTE,
        PORTAL_TEAMS,
      },
    } = constants.routes;

    const teamId = currentTeam?.id || '';
    const teamPageLink = `/${BASE_TEAMS_PORTAL_ROUTE}/${PORTAL_TEAMS}/${teamId}`;

    return [
      {
        content: renderLinkBreadCrumbItem(currentTeam?.name || '', teamPageLink, 'team-name'),
        itemKey: 'team-name',
      },
      {
        content: renderTextBreadCrumbItem('pages.teamsPortal.topFindingsPage.breadcrumbs.text', 'top-findings'),
        itemKey: 'top-findings',
      },
    ];
  }, [currentTeam]);

  const getPlanItemFilters = useCallback(() => ({
    asset_name: relevantAsset!.asset_name,
    resolution: FindingStatus.OPEN,
    ignored: ['False'],
    plan_item: planItemSlug!,
  }), [planItemSlug, relevantAsset]);

  const getTopFindingsFilters = useCallback(() => ({
    resolution: FindingStatus.OPEN,
    issue_severity: [Severity.High, Severity.Critical],
    ignored: ['False'],
    team: currentTeam.name,
  }), [currentTeam]);

  const ModeToPageElements: Record<FindingServiceViewMode, PageElements> = useMemo(() => ({
    [FindingServiceViewMode.PlanItemFindings]: {
      getBreadcrumbItems: getPlanItemBreadcrumbs,
      getFilters: getPlanItemFilters,
      findingsLimit: undefined,
    },
    [FindingServiceViewMode.TopFindings]: {
      getBreadcrumbItems: getTopFindingsBreadcrumbs,
      getFilters: getTopFindingsFilters,
      findingsLimit: GET_TOP_FINDINGS_PAGE_LIMIT,
    },
  }), [getPlanItemBreadcrumbs, getPlanItemFilters, getTopFindingsBreadcrumbs, getTopFindingsFilters]);

  return {
    findingsFilters: ModeToPageElements[mode].getFilters(),
    breadcrumbsItems: ModeToPageElements[mode].getBreadcrumbItems(),
    findingsLimit: ModeToPageElements[mode].findingsLimit,
  };
};

