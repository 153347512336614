import { TeamSortOrder } from 'types/interfaces/Teams/TeamSorting';

export const sortTeams = <T>(teams: T[], sortedBy: keyof T, sortOrder: TeamSortOrder): T[] => {
  const sortedTeams = teams.sort((a, b) => {
    const aValue = a[sortedBy];
    const bValue = b[sortedBy];

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return aValue.localeCompare(bValue);
    }
    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return aValue - bValue;
    }

    // Add more type checks if you have more types to sort by
    return 0;
  });

  if (sortOrder === TeamSortOrder.DESC) {
    return sortedTeams.reverse();
  }

  return sortedTeams;
};
