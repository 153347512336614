import { AxiosResponse } from 'axios';
import { useCallback } from 'react';

import { getApiUrls } from 'services/apiUrls';
import { logError } from 'services/logger/logger';
import { useClient } from 'services/useClient';
import { DevProductivityCalculationPatchRequest } from 'types/interfaces/Metrics/DevEfficiencyCalculation';
import { Snapshot } from 'types/interfaces/Metrics/Snapshots';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

export const useMetricService = () => {
  const { client } = useClient();

  const patchDevEfficiencyCalculation = useCallback(async (data: DevProductivityCalculationPatchRequest): Promise<void> => {
    const url = getApiUrls.metricService.patchDevEfficiencyCalculation();
    const response = await client.patch({ url,
      allowedStatuses: [200],
      requestConfig: { data } });
    if (response?.status !== 200) {
      logError('Failed to patch dev efficiency calculation');
    }
  }, [client]);

  const getSnapshot = useCallback(async (snapshotId: string): Promise<AxiosResponse<Snapshot> | undefined> => {
    const url = getApiUrls.metricService.getSnapshot(snapshotId);
    return client.get({ url,
      allowedStatuses: [200] });
  }, [client]);

  const getSnapshots = useCallback(async (): Promise<Snapshot[] > => {
    const url = getApiUrls.metricService.getSnapshots();
    const response = await client.get({ url,
      allowedStatuses: [200] });
    if (response?.status === 200) {
      return camelizeSnakeCaseKeys(response.data || []) as Snapshot[];
    }
    logError(`Get snapshots request failed with status ${response?.status}`);
    return [];
  }, [client]);

  const deleteSnapshot = useCallback(async (snapshotId: string): Promise<Snapshot | undefined> => {
    const url = getApiUrls.metricService.deleteSnapshot(snapshotId);
    const response = await client.delete({ url,
      allowedStatuses: [200] });
    if (response?.status === 200) {
      return camelizeSnakeCaseKeys(response.data) as Snapshot;
    }
    logError('Failed to delete snapshot - See logs in the server for more details', {
      url,
      snapshotId,
    });
    return undefined;
  }, [client]);

  return {
    patchDevEfficiencyCalculation,
    getSnapshot,
    getSnapshots,
    deleteSnapshot,
  };
};
