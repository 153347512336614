import { FC, useMemo, useState } from 'react';

import styles from './JitCarousel.module.scss';

import { BackArrow } from 'assets';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitIcon } from 'components/JitIcon/JitIcon';

interface Props {
  data: React.ReactNode[];
  onChange: (index: number) => void;
}

const ICON_SIZE = 13;

export const JitCarousel: FC<Props> = ({ data, onChange }) => {
  const [currentElementIndex, setCurrentElementIndex] = useState(0);

  const currentElement = useMemo(() => data[currentElementIndex], [currentElementIndex, data]);

  if (data.length <= 1) {
    return (
      <div className={styles.singleElementWrapper}>
        {currentElement}
      </div>
    );
  }

  const handleChange = (index: number) => {
    setCurrentElementIndex(index);
    onChange(index);
  };

  return (
    <div className={styles.content}>

      <JitGradientButton
        data-testid='previous-element-button'
        disabled={currentElementIndex === 0}
        height={35}
        onClick={() => handleChange(currentElementIndex - 1)}
        width={35}
      >
        <JitIcon icon={BackArrow} size={ICON_SIZE} />
      </JitGradientButton>

      {currentElement}

      <JitGradientButton
        data-testid='next-element-button'
        disabled={currentElementIndex === data.length - 1}
        height={35}
        onClick={() => handleChange(currentElementIndex + 1)}
        width={35}
      >
        <JitIcon icon={BackArrow} rotateDegrees={180} size={ICON_SIZE} />
      </JitGradientButton>
    </div>
  );
};
