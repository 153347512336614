import { cloneDeep } from 'lodash';
import { useCallback } from 'react';

import { DEPLOYMENT_TRIGGER } from '../constants';
import { generatePlanItemTriggerConfiguration } from '../utils/generatePlanItemTriggerConfiguration';
import { getPlanItemTriggerConfigurationName } from '../utils/getPlanItemTriggerConfigurationName';

import { ApplicationTypes, IConfigurations, IPlanItemDeployment, PlanItemsConfigurations } from 'types/interfaces';

export type GetUpdatedConfigurationAndPlanItemType = (baseConfigurations: IConfigurations | undefined, defaultApplicationName: string, planItemTriggerConfiguration: IPlanItemDeployment) =>
[IConfigurations | undefined, IPlanItemDeployment];

export const useEditConfigurations = (
  planItemSlug: string,
  setConfigurations: (config: IConfigurations) => void,
  applicationType: ApplicationTypes,
  getUpdatedConfigurationAndPlanItem?: GetUpdatedConfigurationAndPlanItemType,
) => {
  const getEditedPlanItemsTags = (baseConfigurations: IConfigurations | undefined, tags: string[]): PlanItemsConfigurations | undefined => {
    const planItems: PlanItemsConfigurations | undefined = baseConfigurations && cloneDeep(baseConfigurations.plan_items);
    if (planItems) {
      Object.keys(planItems).forEach((key) => {
        if (planItems[key]?.deployment?.tags) {
          planItems[key].deployment.tags = tags;
        }
      });
    }

    return planItems;
  };

  const setDeploymentPlanItemConfigurations = useCallback((baseConfigurations: IConfigurations | undefined) => {
    const defaultApplicationName = `${planItemSlug}_${applicationType}`;
    const planItemTriggerConfiguration: IPlanItemDeployment | null = (baseConfigurations && generatePlanItemTriggerConfiguration(baseConfigurations, defaultApplicationName, applicationType)) || null;
    const [tempConfigurations, planItemConfiguration]: [IConfigurations | undefined, IPlanItemDeployment | null] = getUpdatedConfigurationAndPlanItem && planItemTriggerConfiguration
      ? getUpdatedConfigurationAndPlanItem(baseConfigurations, defaultApplicationName, planItemTriggerConfiguration)
      : [baseConfigurations, planItemTriggerConfiguration];
    if (planItemConfiguration) {
      const planItems = getEditedPlanItemsTags(tempConfigurations, planItemConfiguration.tags);

      setConfigurations({
        ...tempConfigurations,
        plan_items:
                      {
                        ...planItems,
                        [planItemSlug]: { [DEPLOYMENT_TRIGGER]: planItemConfiguration },
                      },
      });
    }
  }, [applicationType, getUpdatedConfigurationAndPlanItem, planItemSlug, setConfigurations]);

  const setNonDeploymentPlanItemConfigurations = useCallback((baseConfigurations: IConfigurations | undefined) => {
    const planItemDeploymentTriggerConfigurationName = baseConfigurations && getPlanItemTriggerConfigurationName(baseConfigurations, planItemSlug, applicationType);
    if (planItemDeploymentTriggerConfigurationName) {
      const tempConfigurations = cloneDeep(baseConfigurations);
      delete tempConfigurations?.plan_items?.[planItemSlug]?.[planItemDeploymentTriggerConfigurationName];
      setConfigurations(tempConfigurations);
    }
  }, [applicationType, planItemSlug, setConfigurations]);

  const editConfigurations = (baseConfigurations: IConfigurations | undefined, isTriggerByDeployment: boolean) => {
    if (isTriggerByDeployment) {
      setDeploymentPlanItemConfigurations(baseConfigurations);
    } else {
      setNonDeploymentPlanItemConfigurations(baseConfigurations);
    }
  };

  return { editConfigurations };
};
