import { AxiosResponse } from 'axios';
import { useCallback } from 'react';

import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import { AssetType } from 'types/enums';
import { IArtifact, ArtifactType } from 'types/interfaces/Artifacts/IArtifact';
import { DownloadFileResponse } from 'types/interfaces/DownloadFileResponse';
import { IPaginatedResponse } from 'types/interfaces/IPaginatedResponse/IPaginatedResponse';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';
import { deleteFalseValuesFromObject } from 'utils/functions/deleteFalseValuesFromObject';

interface IGetArtifactsParams {
  artifactType?: ArtifactType,
  assetType?: AssetType,
  assetId?: string,
  limit?: number,
  after?: string,
}

export const useArtifactsService = () => {
  const { client } = useClient();

  const getArtifacts = useCallback(
    async ({
      artifactType,
      assetType,
      assetId,
      limit,
      after,
    }: IGetArtifactsParams):
    Promise<AxiosResponse<IPaginatedResponse<IArtifact>> | undefined> => {
      const url = getApiUrls.artifactsService.getArtifacts();
      const params = deleteFalseValuesFromObject({
        artifact_type: artifactType,
        asset_type: assetType,
        asset_id: assetId,
        limit,
        after,
      });
      const response = await client.get<IPaginatedResponse<IArtifact>>({
        url,
        allowedStatuses: [200],
        requestConfig: {
          params,
        },
      });
      if (response?.status === 200) {
        response.data = camelizeSnakeCaseKeys(response.data) as IPaginatedResponse<IArtifact>;
      }
      return response;
    },
    [client],
  );

  const downloadArtifact = useCallback(async (artifactId: string): Promise<AxiosResponse<DownloadFileResponse> | undefined> => {
    const url = getApiUrls.artifactsService.downloadArtifact(artifactId);
    const response = await client.get<DownloadFileResponse>({
      url,
      allowedStatuses: [200],
    });
    if (response?.status === 200) {
      response.data = camelizeSnakeCaseKeys(response.data) as DownloadFileResponse;
    }
    return response;
  }, [client]);

  return {
    getArtifacts,
    downloadArtifact,
  };
};
