import TextField from '@mui/material/TextField';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';

import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { i18n } from 'locale/i18n';
import { IChangeInputEvent } from 'types/interfaces';
import { iconsMap } from 'utils';

interface Props {
  isAllAssetsSelectedTabCovered: boolean;
  handleSearchAsset: (e: IChangeInputEvent) => void;
  handleToggleSelectAllAssets: (e: IChangeInputEvent, checked: boolean) => void;
  wholeSelectionCheckboxText: string;
}

export const TopBar: FC<Props> = ({
  handleSearchAsset,
  handleToggleSelectAllAssets,
  isAllAssetsSelectedTabCovered,
  wholeSelectionCheckboxText,
}) => {
  const { isControlledRepoActivation } = useFlags();
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginRight: '1rem',
        marginLeft: '1rem',
        paddingTop: '1rem',
        paddingBottom: '1rem',
      }}
    >
      <TextField
        autoFocus
        fullWidth
        id='search'
        InputProps={{
          inputProps: {
            style: {
              display: 'flex',
              alignItems: 'center',
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
            },
          },
          notched: false,
          style: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '12px',
            borderRadius: '0.5rem',
            width: '80%',
          },
          startAdornment: <JitIcon
            icon={iconsMap.search.icon}
            style={{
              marginRight: '0.5rem',
            }}
          />,
        }}
        label=' '
        onChange={handleSearchAsset}
        placeholder={i18n.t('dialogs.assetsManagement.search.placeholder')}
        type='search'
        variant='outlined'
      />

      <div style={{
        display: 'flex',
        alignItems: 'center',
      }}
      >
        <JitCheckbox
          checked={isAllAssetsSelectedTabCovered}
          data-testid='all-repos-checkbox'
          disabled={isControlledRepoActivation}
          onChange={handleToggleSelectAllAssets}
          style={{
            marginRight: '0.5rem',
          }}
        />

        <JitText noWrap text={wholeSelectionCheckboxText} />
      </div>
    </div>
  );
};
