import { getAllFindingsIdsMapForPipelineWorkFlows } from 'pages/PipelinesPage/PipelineDetailsPage/utils/pipelineFindings';
import { IPipelineNew } from 'types/interfaces';
import { InsightCategoryFindings } from 'types/interfaces/Insights';

export const getAllFindingsTypesOfDeployment = (deployment: IPipelineNew): InsightCategoryFindings => {
  const workflowsFindingsTypes = getAllFindingsIdsMapForPipelineWorkFlows(deployment.workflows);
  const newFindings: string[] = [];
  const existingFindings: string[] = [];
  const fixedFindings: string[] = [];

  workflowsFindingsTypes.forEach((workflowFindings) => {
    existingFindings.push(...workflowFindings.existingFindings);
    newFindings.push(...workflowFindings.newFindings);
    fixedFindings.push(...workflowFindings.fixedFindings);
  });

  return {
    existingFindings: existingFindings.length,
    fixedFindings: fixedFindings.length,
    newFindings: newFindings.length,
    ignoredFindings: 0, // for now we don't save ignored findings on pipeline
  };
};
