import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { CoverageCard } from './components/CoverageCard/CoverageCard';
import styles from './Coverage.module.scss';

import { JitText } from 'components/JitText/JitText';
import { LayersCoverage } from 'pages/InsightsPage/SubPages/SecurityImpact/components/LayersCoverage/LayersCoverage';
import { ResourcesCoverage } from 'pages/InsightsPage/SubPages/SecurityImpact/components/ResourcesCoverage/ResourcesCoverage';
import { SecurityImpactMetricCard } from 'pages/InsightsPage/SubPages/SecurityImpact/components/SecurityImpactWrapper/SecurityImpactWrapper';
import { INTEGRATIONS_ROUTE, PLAN_PAGE_ROUTE } from 'pages/OverviewPage/constants';
import colors from 'themes/colors.module.scss';
import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { IPlanStatus } from 'types/interfaces/Metrics/SecurityImpactMetrics/LayersCoverage';
import { IResourcesCoverage } from 'types/interfaces/Metrics/SecurityImpactMetrics/ResourcesCoverage';

interface Props {
  resourcesCoverageMetric: MetricWithLoadingIndication<IResourcesCoverage>;
  planStatusMetric: MetricWithLoadingIndication<IPlanStatus>;
}

export const Coverage: FC<Props> = ({ resourcesCoverageMetric, planStatusMetric }) => {
  const navigate = useNavigate();

  const cards = {
    LayersCoverage: {
      content: (<SecurityImpactMetricCard
        content={<LayersCoverage planStatusMetric={planStatusMetric} shouldShowItemDetails={false} />}
        increasedGap
        mainTitle='pages.overview.coverage.card.LayersCoverage.title'
      />),
      actionButton: {
        label: 'pages.overview.coverage.card.LayersCoverage.buttonText',
        handleClick: () => { navigate(PLAN_PAGE_ROUTE); },
      },
    },
    ResourcesCoverage: {
      content: (<SecurityImpactMetricCard
        content={<ResourcesCoverage resourcesCoverageMetric={resourcesCoverageMetric} />}
        increasedGap
        mainTitle='pages.overview.coverage.card.ResourcesCoverage.title'
      />),
      actionButton: {
        label: 'pages.overview.coverage.card.ResourcesCoverage.buttonText',
        handleClick: () => {
          navigate(INTEGRATIONS_ROUTE);
        },
      },
    },
  };
  return (
    <div className={styles.wrapper} data-testid='Coverage-wrapper'>
      <JitText className={styles.title} color={colors.lightGray} text='pages.overview.coverage.title' />

      <div className={styles.cards}>
        {Object.values(cards).map((card) => (
          <CoverageCard key={card.actionButton.label} actionButton={card.actionButton} content={card.content} />
        ))}

      </div>
    </div>
  );
};
