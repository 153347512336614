import { FC, useMemo, useCallback } from 'react';

import { NavButton, NavButtonGroup } from '..';

import { ITabItem, ITabGroup, Tab, TabItemTypes } from 'components/Navbar/hooks';
import styles from 'components/Navbar/Navbar.module.scss';

interface Props {
  isCollapsed: boolean,
  items: Tab[],
  onClick?: (tabItem: ITabItem) => void,
  selectedTabName?: string,
}

export const NavbarItems: FC<Props> = ({ isCollapsed, items, onClick, selectedTabName }) => {
  const scrollableContentGroupWidth = useMemo(() => (isCollapsed ? '' : '300px'), [isCollapsed]);

  const renderPageTabsItem = useCallback((tabItem: ITabItem) => (
    <NavButton
      key={tabItem.name}
      icon={tabItem.icon}
      isCollapsed={isCollapsed}
      isDisabled={tabItem.isDisabled}
      isNew={tabItem.isNew}
      isSelected={tabItem.name === selectedTabName}
      onClick={() => { onClick?.(tabItem); }}
      text={tabItem.name}
    />
  ), [isCollapsed, onClick, selectedTabName]);

  const renderTabGroup = useCallback((tabGroup: ITabGroup) => (
    <NavButtonGroup
      key={tabGroup.name}
      isCollapsed={isCollapsed}
      onTabItemClick={(tabItem) => { onClick?.(tabItem); }}
      selectedTabName={selectedTabName}
      tabGroup={tabGroup}
    />
  ), [isCollapsed, onClick, selectedTabName]);

  const renderTab = useCallback((tab: Tab) => {
    if (tab.type === TabItemTypes.item) return renderPageTabsItem(tab);
    if (tab.type === TabItemTypes.group) return renderTabGroup(tab);
    return null;
  }, [renderPageTabsItem, renderTabGroup]);

  return (
    <div className={styles.scrollableContentGroup} style={{ width: scrollableContentGroupWidth }}>
      {items.map((tab) => renderTab(tab))}
    </div>
  );
};
