import _ from 'lodash';

const isSnakeCase = (str: string) => str.includes('_');

export const camelizeSnakeCaseKeys = (object: object) => _.transform(object, (acc, value, key: number | string) => {
  const camelKey = (typeof key === 'string' && isSnakeCase(key) && _.camelCase(key)) || key;
  // @ts-ignore
  acc[camelKey] = _.isObject(value) ? camelizeSnakeCaseKeys(value) : value;
});

const isCamelCase = (str: string) => /^[a-z][a-zA-Z0-9]*$/.test(str);

export const snakeCaseCamelizeKeys = (object: object) => _.transform(object, (acc, value, key: number | string) => {
  const snakeKey = (typeof key === 'string' && isCamelCase(key) && _.snakeCase(key)) || key;
  // @ts-ignore
  acc[snakeKey] = _.isObject(value) ? snakeCaseCamelizeKeys(value) : value;
});
