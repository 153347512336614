import React, { useCallback, useState } from 'react';

import { SmilyRateBox } from '../SmilyRateBox/SmilyRateBox';

import styles from './FeedbackBox.module.scss';

import {
  FeedbackTerrible,
  FeedbackBad,
  FeedbackOkay,
  FeedbackGood,
  FeedbackGreat,
  Cross,
} from 'assets';
import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTextInput } from 'components/JitTextInput/JitTextInput';
import colors from 'themes/colors.module.scss';
import { ActionButtonVariant, ISvg } from 'types/interfaces';

interface FeedbackBoxProps {
  handleClose: () => void;
  handleSubmit: (feedback?: string, rating?: string) => Promise<void>;
  title: string;
  subtitle?: string;
  placeholderText?: string;
}

interface ISmilyRating {
  text: string;
  color: string;
  icon: React.FC<ISvg>;
}

const SmilyRatings: { [key: string]: ISmilyRating } = {
  1: {
    text: 'components.feedback.ratings.terrible',
    color: colors.terribleFeedback,
    icon: FeedbackTerrible,
  },
  2: {
    text: 'components.feedback.ratings.bad',
    color: colors.badFeedback,
    icon: FeedbackBad,
  },
  3: {
    text: 'components.feedback.ratings.neutral',
    color: colors.okayFeedback,
    icon: FeedbackOkay,
  },
  4: {
    text: 'components.feedback.ratings.good',
    color: colors.goodFeedback,
    icon: FeedbackGood,
  },
  5: {
    text: 'components.feedback.ratings.great',
    color: colors.greatFeedback,
    icon: FeedbackGreat,
  },
};

export const FeedbackBox: React.FC<FeedbackBoxProps> = ({ handleClose, handleSubmit, title, subtitle, placeholderText }) => {
  const [feedback, setFeedback] = useState('');
  const [selectedRating, setSelectedRating] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onChange = (value: string) => {
    setFeedback(value);
  };
  const onSubmit = useCallback((async () => {
    setIsSubmitting(true);
    await handleSubmit(feedback, selectedRating);
    setIsSubmitting(false);
    setFeedback('');
    handleClose();
  }), [feedback, selectedRating, handleSubmit, handleClose]);

  return (
    <div className={styles.feedbackBox}>
      <div className={styles.feedbackBoxHeader}>
        <JitText size='l' text={title} />

        <JitIcon data-testid='feedback-close-button' icon={Cross} onClick={handleClose} size={20} />
      </div>

      <JitText muted size='m' text={subtitle} />

      <div className={styles.ratingWrapper}>
        {Object.keys(SmilyRatings).map((key) => {
          const { text, color, icon } = SmilyRatings[key];
          return (
            <SmilyRateBox
              key={key}
              color={color}
              icon={icon}
              isSelected={selectedRating === key}
              onClick={() => {
                setSelectedRating(key);
              }}
              text={text}
              wrapperClassName={styles.smilyRating}
            />
          );
        })}
      </div>

      <div className={styles.feedbackBoxBody}>
        <JitTextInput
          autoFocus
          fullWidth
          multiline
          onChange={onChange}
          placeholder={placeholderText || 'components.feedback.placeholder'}
          rows={5}
          value={feedback}
        />
      </div>

      <div className={styles.feedbackBoxFooter}>
        <JitActionButton actionButton={{
          handleClick: onSubmit,
          label: 'components.feedback.submit',
          variant: ActionButtonVariant.PRIMARY,
          disabled: !selectedRating || isSubmitting,
          isLoading: isSubmitting,
        }}
        />
      </div>
    </div>
  );
};
