import Collapse from '@mui/material/Collapse';
import { Children, FC, PropsWithChildren, useMemo, useState } from 'react';

import styles from './CollapseList.module.scss';

import { ExtendFilters, VectorStroke } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface CollapseListProps {
  shouldCollapsedThreshold: number,
  headerText: string,
  headerParams: {}
}

export const CollapseList: FC<PropsWithChildren<CollapseListProps>> = ({ shouldCollapsedThreshold, headerText, headerParams, children }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const itemsCount = useMemo(() => Children.count(children), [children]);
  const shouldCollapsed = itemsCount > shouldCollapsedThreshold;

  const handleToggle = () => {
    setIsExpanded((isCurExpanded) => !isCurExpanded);
  };

  return (
    <div data-testid='collapseList'>
      {shouldCollapsed && (
        <div aria-hidden='true' className={styles.header} data-testid='collapseListHeader' onClick={handleToggle}>
          <JitIcon color={colors.iris} icon={isExpanded ? ExtendFilters : VectorStroke} size={10} />

          <JitText color={colors.iris} params={headerParams} text={headerText} />
        </div>
      )}

      <Collapse
        in={!shouldCollapsed || isExpanded}
        timeout='auto'
      >
        <div data-testid='collapseListContent'>
          {children}
        </div>
      </Collapse>
    </div>
  );
};

