import { config } from 'config';
import { useAnalyticsService } from 'services/AnalyticsService/useAnalyticsService';
import { IFronteggUser } from 'types/interfaces';

const REGISTER_USER_TO_HUBSPOT = 'didRegisterUserToHubspot';
const JIT_HUBSPOT_COOKIE_DOMAIN = 'jit.io';
const JIT_HUBSPOT_COOKIE_NAME = 'hubspotutk';

export const useIdentifyUser = () => {
  const { identifyUser: identifyUserAnalytics } = useAnalyticsService();
  const identifyUser = async (user: IFronteggUser, pageName: string, pathName: string) => {
    const didRegisterUser = localStorage.getItem(REGISTER_USER_TO_HUBSPOT);
    if (didRegisterUser === 'true') {
      return;
    }
    // @ts-ignore - 'cookieStore' is not recognized by TS still is not officially supported by all browsers
    // However, it is supported by all major browsers and is a W3C standard.
    // Anyway we're getting it in a safe way and wil manage the case when it's not supported.
    const cookies = await window?.cookieStore?.getAll(JIT_HUBSPOT_COOKIE_NAME) || [];
    const jitHubspotCookie = cookies.find((cookie: { domain: string, value: string }) => cookie.domain === JIT_HUBSPOT_COOKIE_DOMAIN);
    if (!jitHubspotCookie) {
      return;
    }

    await identifyUserAnalytics({
      email: user.email,
      firstname: user.name,
      lastname: '',
      tenant_id: user.tenantId,
      hubspot_cookie: jitHubspotCookie?.value,
      page_uri: config.platformBaseUrl + pathName,
      page_name: pageName,
    });
    localStorage.setItem(REGISTER_USER_TO_HUBSPOT, 'true');
  };

  return {
    identifyUser,
  };
};
