import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ISegmentsMapping } from '../../interfaces/ISegmentsMapping';
import { CoverageScale } from '../CoverageScale/CoverageScale';

import { theLoopColorTheme } from './constants';
import { IHoveredSegment, Side } from './interfaces/ISegment';
import {
  Test, Build, Code, Plan,
  Monitor, Operate, Deploy, Release,
} from './Segments';
import styles from './TheLoop.module.scss';

import colors from 'themes/colors.module.scss';

export interface ITheLoopProps {
  segments: ISegmentsMapping;
}

export const TheLoop: FC<ITheLoopProps> = ({ segments }) => {
  const [isTheLoopHovered, setIsTheLoopHovered] = useState(false);
  const [hoveredSegment, setHoveredSegment] = useState<IHoveredSegment | undefined>();

  const { t } = useTranslation();

  // The segments order is IMPORTANT as it represents the order the path is drawn inside the svg
  const segmentComponents = {
    Test,
    Build,
    Code,
    Plan,
    Monitor,
    Operate,
    Deploy,
    Release,
  };

  const getSideProperties = (side: Side) => {
    const oppositeSide = side === Side.Left ? Side.Right : Side.Left;
    const sideText = t(`pages.securityMapping.theLoop.labels.${side}Side`);
    const sideOpacity = isTheLoopHovered && hoveredSegment?.side === oppositeSide ? 0.2 : 1;

    if (hoveredSegment && hoveredSegment.side === side && hoveredSegment.activities.length > 0) {
      const segmentText = t('pages.securityMapping.theLoop.labels.template', { segmentName: hoveredSegment.id });
      return {
        text: segmentText,
        opacity: sideOpacity,
      };
    }

    return {
      text: sideText,
      opacity: sideOpacity,
    };
  };

  const onSegmentMouseEnter = (segment: IHoveredSegment) => {
    setIsTheLoopHovered(true);
    setHoveredSegment(segment);
  };

  const onSegmentMouseLeave = () => {
    setIsTheLoopHovered(false);
    setHoveredSegment(undefined);
  };

  const baseColors = theLoopColorTheme.map((color) => color.base);

  const { text: leftSideText, opacity: leftSideOpacity } = getSideProperties(Side.Left);
  const { text: rightSideText, opacity: rightSideOpacity } = getSideProperties(Side.Right);

  return (
    <div className={styles.container} data-testid='the-loop'>
      <svg className={styles.theLoop} viewBox='0 0 900 700'>
        <g transform='translate(140, 130) scale(0.9)'>
          {Object.entries(segmentComponents).map(([key, Component]) => {
            const segment = Object.keys(segments).includes(key.toLowerCase()) ? segments[key.toLowerCase()] : {
              activities: [],
              colors: theLoopColorTheme[0],
            };

            return (
              <Component
                key={key}
                activities={segment.activities}
                colors={segment.colors}
                isTheLoopHovered={isTheLoopHovered}
                onSegmentMouseEnter={onSegmentMouseEnter}
                onSegmentMouseLeave={onSegmentMouseLeave}
              />
            );
          })}

          <text
            className={styles.middleLabel}
            data-testid='the-loop-middle-left-label'
            style={{
              fill: colors.lightGray,
              opacity: leftSideOpacity,
            }}
            x='180'
            y='225'
          >
            {leftSideText}
          </text>

          <text
            className={styles.middleLabel}
            data-testid='the-loop-middle-right-label'
            style={{
              fill: colors.lightGray,
              opacity: rightSideOpacity,
            }}
            x='500'
            y='225'
          >
            {rightSideText}
          </text>
        </g>
      </svg>

      <CoverageScale inputColors={baseColors} />
    </div>
  );
};
