import { FC } from 'react';

import styles from './PolicyHeader.module.scss';

import { SourceControl } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  title: string;
  description: string;
  enabled: number;
}

export const PolicyHeader: FC<Props> = ({ title, description, enabled }) => {
  const enabledColor = enabled > 0 ? colors.successMain : colors.lightGray;

  return (
    <div className={styles.root}>
      <div className={styles.iconContainer}>
        <JitIcon data-testid='policy-icon' icon={SourceControl} />

        <div className={styles.textContainer}>
          <JitText text={title} />

          <JitText muted size='s' text={description} />
        </div>
      </div>

      <JitText bold color={enabledColor} params={{ count: enabled }} size='xs' text='pages.policies.policyItem.header.enabled' />
    </div>
  );
};
