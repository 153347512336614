import { FC } from 'react';

import styles from './SortableHeader.module.scss';

import { ArrowRight } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { TeamSortBy, TeamSortOrder } from 'types/interfaces/Teams/TeamSorting';

interface SortableHeaderProps {
  sortParameter: TeamSortBy;
  columnName: string;
  sortedBy: TeamSortBy;
  sortOrder: TeamSortOrder;
  setSortedBy: (value: TeamSortBy) => void;
  setSortOrder: (value: TeamSortOrder) => void;
}

export const SortableHeader: FC<SortableHeaderProps> = ({ columnName, sortParameter, sortedBy, sortOrder, setSortedBy, setSortOrder }) => {
  const isSortedByThisColumn = sortedBy === sortParameter;
  const toggleSortOrder = () => {
    if (isSortedByThisColumn) {
      setSortOrder(sortOrder === TeamSortOrder.ASC ? TeamSortOrder.DESC : TeamSortOrder.ASC);
    } else {
      setSortedBy(sortParameter);
      setSortOrder(TeamSortOrder.DESC); // Always set to ascending when changing columns
    }
  };

  let rotateDegrees = 90;

  if (isSortedByThisColumn) {
    rotateDegrees = sortOrder === TeamSortOrder.ASC ? -90 : 90;
  }
  const relevantIcon = (
    <JitIcon
      color={isSortedByThisColumn ? colors.iris : colors.lightGray}
      data-testid='cellIcon'
      icon={ArrowRight}
      rotateDegrees={rotateDegrees}
      size={25}
    />
  );

  const handleKeyDown = (e: React.KeyboardEvent) => {
    // This is done to comply with an ESLint rule that clickable divs need to also support
    // keyboard navigation. Appearantly this is a thing for accessibility.
    if (e.key === 'Enter' || e.key === ' ') {
      toggleSortOrder();
    }
  };

  return (
    <div
      className={styles.customHeader}
      data-testid={`customHeader-${columnName}`}
      onClick={toggleSortOrder}
      onKeyDown={handleKeyDown}
      role='button'
      tabIndex={0}
    >
      <JitText
        color={colors.white}
        muted
        size='m'
        text={columnName}
      />

      {relevantIcon}
    </div>
  );
};
