import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ResourcePlanItemsStatusesPage } from './ResourcePlanItemsStatusesPage';

import { JittyTheExplorer } from 'assets';
import { GeneralErrorPage } from 'components/GeneralErrorPage';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { useAssetsContext } from 'context/AssetsContext/AssetsContext';
import { TeamsProvider } from 'context/TeamsContext/TeamsProvider';

export const ResourcePlanItemsStatusesPageWrapper: FC = () => {
  const { assets, isLoadingAssets } = useAssetsContext();
  const { resourceId } = useParams<{ resourceId: string }>();
  const currentResource = useMemo(() => assets.find((asset) => resourceId === asset.asset_id), [resourceId, assets]);

  if (isLoadingAssets) {
    return <LoadingBar />;
  }

  if (!currentResource) {
    return (
      <GeneralErrorPage
        backgroundColor='transparent'
        description='pages.teams.resourcePlanItemsStatuses.notFound.description'
        icon={JittyTheExplorer}
        isFullPage={false}
        title='pages.teams.resourcePlanItemsStatuses.notFound.title'
      />
    );
  }

  return (
    <TeamsProvider>
      <ResourcePlanItemsStatusesPage resource={currentResource} />
    </TeamsProvider>
  );
};
