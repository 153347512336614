import Link, { LinkProps } from '@mui/material/Link';
import { FC } from 'react';

import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { IAnalyticsEvent } from 'types/interfaces';

interface Props extends LinkProps {
  analytics?: IAnalyticsEvent;
}

export const JitLink: FC<Props> = ({ children, onClick, analytics, ...props }) => {
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (analytics) {
      sendAnalyticsEvent(analytics);
    }
    onClick?.(e);
  };

  return (
    <Link onClick={handleClick} {...props}>
      {children}
    </Link>
  );
};
