import Collapse from '@mui/material/Collapse';
import { FC, useState } from 'react';

import { AssetManagementTable } from '../AssetManagementTable/AssetManagementTable';

import { HEADERS_LIST } from './constants';

import { ChevronClosed, ChevronOpened } from 'assets';
import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import styles from 'components/JitDialogs/AssetsManagementDialog/components/OwnerAccordion/OwnerAccordionCustomAws.module.scss';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { IAsset, IChangeEvent } from 'types/interfaces';
import { stopPropagation } from 'utils';

interface Props {
  assets: IAsset[];
  checkAsset: (assetId: string, checked: boolean) => void;
  checkMultipleAssetsByOwner: (assetId: string, checked: boolean) => void;
}

export const CustomCloudStandaloneAccounts: FC<Props> = ({
  assets,
  checkAsset,
  checkMultipleAssetsByOwner,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const isAllAssetsOfOwnerSelected = assets.every((asset) => asset.is_covered);

  const toggleExpanded = () => setIsExpanded((currentExpanded) => !currentExpanded);

  const handleSelectAllAssetsOfOwner = (e: IChangeEvent, checked: boolean) => {
    assets.forEach((asset) => checkMultipleAssetsByOwner(asset.owner, checked));
  };

  return (
    <div data-testid='Standalone Accounts'>
      <div
        className={styles.orgContainer}
        data-testid='standalone-accounts-heading-custom-cloud'
        onClick={toggleExpanded}
        role='button'
        tabIndex={0}
      >
        <JitCheckbox
          checked={isAllAssetsOfOwnerSelected}
          className={styles.ownerCheckBox}
          onChange={handleSelectAllAssetsOfOwner}
          onClick={stopPropagation}
        />

        <JitText className={styles.ownerText} text='dialogs.assetsManagement.awsStandaloneAccountsTopRow' />

        <JitIcon className={styles.chevron} color={colors.gray} icon={isExpanded ? ChevronOpened : ChevronClosed} />
      </div>

      <Collapse
        data-testid='standalone-accounts-details'
        in={isExpanded}
        style={{ paddingTop: 10,
          paddingBottom: 10 }}
      >
        <div className={styles.contentContainer}>
          <AssetManagementTable assets={assets} checkAsset={checkAsset} headers={HEADERS_LIST} />
        </div>
      </Collapse>
    </div>
  );
};
