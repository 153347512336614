import { FC } from 'react';

import styles from './JitGrade.module.scss';

import { QuestionShield } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText, JitTextProps } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import colors from 'themes/colors.module.scss';
import { getColorByGrade, getGradeByScore } from 'utils/functions/grades';

export type Size = 'xs' | 's' | 'l';

interface Props {
  score: number;
  size?: Size;
  tooltip?: string;
}

export const JitGrade: FC<Props> = ({ score, size = 'm', tooltip }) => {
  const sizeToStyleMap: { [key: string]: {
    wrapper: { height: string, width: string },
    text: JitTextProps,
  } } = {
    l: {
      wrapper: {
        height: '133px',
        width: '141px',
      },
      text: {
        size: 'xxxlhuge',
      },
    },
    m: {
      wrapper: {
        height: '50px',
        width: '56px',
      },
      text: {
        size: 'l',
      },
    },
    s: {
      wrapper: {
        height: '35px',
        width: '38px',
      },
      text: {
        size: 'l',
      },
    },
    xs: {
      wrapper: {
        height: '18px',
        width: '22px',
      },
      text: {
        size: 'xs',
      },
    },
  };

  const grade = getGradeByScore(score);
  const color = getColorByGrade(grade);

  return (
    <div className={styles.root}>
      <div
        className={styles.rankWrapper}
        data-testid='jit-grade'
        style={{ backgroundColor: color,
          ...sizeToStyleMap[size].wrapper }}
      >
        <JitText bold color={colors.teamRankText} data-testid='grade' {...sizeToStyleMap[size].text} text={grade} />

        {size === 'l' && (
        <JitTooltip followCursor={false} placement='top' title={tooltip || ''}>
          <div className={styles.score}>
            <JitIcon icon={QuestionShield} size={18} />

            <JitText bold color={colors.teamRankText} size='xl' text={`${score}%`} />
          </div>
        </JitTooltip>
        )}
      </div>

      {size !== 'l' && (
      <JitText bold color={color} {...sizeToStyleMap[size].text} text={`${score}%`} />
      )}
    </div>
  );
};
