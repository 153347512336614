import { LABELS_COLORS } from '../components/SecurityPlansBody/constants';

export const useGetLabelToColorMapping = (plans: { labels: string[] }[]) => {
  const getLabelToColorMapping = () => {
    const labelToColorMap: { [key: string]: string } = {};
    const labels = [...new Set(plans.flatMap((plan) => plan?.labels || []))].sort();

    labels.forEach((label, index) => {
      labelToColorMap[label as string] = LABELS_COLORS[index % LABELS_COLORS.length];
    });
    return labelToColorMap;
  };

  return {
    getLabelToColorMapping,
  };
};
