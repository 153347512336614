import { AxiosResponse } from 'axios';

import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import { ISlackServiceChannelResponse, ISlackUserResponse } from 'types/interfaces/Slack';

export const useSlackService = () => {
  const { client } = useClient();

  const getSlackUsers = async (): Promise<AxiosResponse<ISlackUserResponse[]> | undefined> => {
    const url = getApiUrls.slackService.getUsers();
    return client.get<ISlackUserResponse[]>({ url,
      allowedStatuses: [200] });
  };

  const getSlackChannels = async (): Promise<AxiosResponse<ISlackServiceChannelResponse[]> | undefined> => {
    const url = getApiUrls.slackService.getChannels();
    return client.get<ISlackServiceChannelResponse[]>({ url,
      allowedStatuses: [200] });
  };

  return {
    getSlackUsers,
    getSlackChannels,
  };
};
