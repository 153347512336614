import { ComponentProps, FC } from 'react';

import { AssetsManagementDialog } from 'components/JitDialogs/AssetsManagementDialog/AssetsManagementDialog';
import { IntegrationDialog } from 'components/JitDialogs/IntegrationDialog/IntegrationDialog';
import { Dialogs } from 'types/enums';

type DialogMapping = {
  [key: string]: {
    props?: Object;
    template: FC<ComponentProps<any>> // eslint-disable-line @typescript-eslint/no-explicit-any
  }
};

export const useDialogsMapping = (): DialogMapping => ({
  [Dialogs.AssetsManagement]: { template: AssetsManagementDialog },
  [Dialogs.Integrations]: { template: IntegrationDialog },
});
