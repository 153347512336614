import { FC } from 'react';

import styles from './AddTag.module.scss';

import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';

interface PopoverFooterProps {
  isDisabled: boolean;
  isLoading: boolean;
  addAssetTag: () => void;
}

export const PopoverFooter: FC<PopoverFooterProps> = ({
  isDisabled,
  isLoading,
  addAssetTag,
}) => (
  <div className={styles.buttonWrapper}>
    <JitGradientButton data-testid='saveTagButton' disabled={isDisabled} isLoading={isLoading} onClick={addAssetTag} width={50}>
      <JitText size='s' text='pages.risks.resources.resourcePage.resourceDetails.addTagPopover.save' />
    </JitGradientButton>
  </div>
);
