import Table from '@mui/material/Table';
import { FC } from 'react';

import { PlanItemDetailsSupportedLanguagesBody } from './components/PlanItemDetailsSupportedLanguagesBody/PlanItemDetailsSupportedLanguagesBody';
import {
  PlanItemDetailsSupportedLanguagesHeader,
} from './components/PlanItemDetailsSupportedLanguagesHeader/PlanItemDetailsSupportedLanguagesHeader';
import styles from './PlanItemDetailsSupportedLanguages.module.scss';
import { StyledTableContainer } from './PlanItemDetailsSupportedLanguages.styles';

import { JitSidePanelItem } from 'components/JitSidePanel/JitSidePanelItem/JitSidePanelItem';
import { usePlanItem } from 'context/PlansContext/hooks/usePlanItem';
import { IPlanItemDetails } from 'types/interfaces';

interface Props {
  planItem: IPlanItemDetails;
}

export const PlanItemDetailsSupportedLanguages: FC<Props> = ({ planItem }) => {
  const { getControls } = usePlanItem();

  const controls = getControls(planItem);

  const hasSupportedLanguages = controls.some((control) => control.supportedLanguages.length);
  const supportedLanguagesSectionTitle = hasSupportedLanguages ? 'supportedLanguages' : 'securityTools';

  return (
    <div>
      {!!planItem?.controls && (
        <JitSidePanelItem title={`pages.plan.itemDetails.${supportedLanguagesSectionTitle}`}>
          <StyledTableContainer>
            <Table
              className={styles.table}
              size='small'
              sx={{
                borderCollapse: 'separate',
                borderSpacing: '0 4px !important',
              }}
            >
              <PlanItemDetailsSupportedLanguagesHeader hasSupportedLanguages={hasSupportedLanguages} />

              <PlanItemDetailsSupportedLanguagesBody controls={controls} />
            </Table>
          </StyledTableContainer>
        </JitSidePanelItem>
      )}
    </div>
  );
};
