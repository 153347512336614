export const getUserVendorIdFromProfilePicture = (url: string): string => {
  const parsedUrl = new URL(url);
  const { pathname } = parsedUrl;

  // Split the pathname by '/' and get the part that contains the user ID
  const segments = pathname.split('/');
  // check if the URL has the expected structure
  if (segments.length < 3) {
    return '';
  }
  const userId = segments[2]; // Assuming the URL structure follows the pattern '/u/{userId}'
  return userId;
};
