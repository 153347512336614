import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import styles from './TeamDetailsPage.module.scss';

import { JittyTheExplorer } from 'assets';
import { GeneralErrorPage } from 'components/GeneralErrorPage';
import { JitDivider } from 'components/JitDivider/JitDivider';
import { JitText } from 'components/JitText/JitText';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { PageTitles } from 'components/PageTitles/PageTitles';
import { useTeamsContext } from 'context/TeamsContext/TeamsContext';
import { Actions } from 'pages/OverviewPage/components';
import { CollapseSection } from 'pages/OverviewPage/components/CollapseSection/CollapseSection';
import { StatsCards } from 'pages/TeamsPage/components/ResourcePlanItemsStatusesPage/components/StatsCards/StatsCards';
import { TeamsBreadcrumbs } from 'pages/TeamsPage/components/TeamsBreadcrumbs/TeamsBreadcrumbs';
import { ChildTeamsList } from 'pages/TeamsPage/SubPages/TeamDetailsPage/components/ChildTeamsList/ChildTeamsList';
import { MembersList } from 'pages/TeamsPage/SubPages/TeamDetailsPage/components/MembersList/MembersList';
import { ResourcesTable } from 'pages/TeamsPage/SubPages/TeamDetailsPage/components/ResourcesTable/ResourcesTable';
import { SetSlackNotification } from 'pages/TeamsPage/SubPages/TeamDetailsPage/components/SetSlackNotification/SetSlackNotification';
import { useFetchActions, useFetchActionsCount } from 'services/ActionService/useFetchActions';
import { fetchFindingsCount } from 'services/FindingsService/FindingsService';
import { IFilter, ServerFindingKeys } from 'types/interfaces';
import { ActionFiltersKeys } from 'types/interfaces/Actions/ActionFilters';
import { ICount } from 'types/interfaces/Counts/ICounts';

const ActionsHeader = (
  <JitText muted text='actions.title' />
);

export const TeamDetailsPage: FC = () => {
  const [findingsCount, setFindingsCount] = useState<number>();
  const {
    getSpecificTeamById,
    specificTeam,
  } = useTeamsContext();
  const {
    actions,
    isLoading: isLoadingActionsData,
    getActions,
  } = useFetchActions();
  const {
    actionsCount,
    isLoading: isLoadingActionsCount,
    getActionsCount,
  } = useFetchActionsCount();
  const { teamId } = useParams();

  const isLoadingActions = useMemo(() => isLoadingActionsData || isLoadingActionsCount, [isLoadingActionsData, isLoadingActionsCount]);

  useEffect(() => {
    if (teamId) {
      getSpecificTeamById(teamId);
    }
  }, [teamId]); // eslint-disable-line react-hooks/exhaustive-deps -- We want to run this only once
  useEffect(() => {
    if (!specificTeam.team) return;
    fetchFindingsCount({
      filters: {
        team: specificTeam.team.name,
        resolution: ['OPEN'],
        ignored: [false],
      },
    }).then((res) => {
      const response = res as ICount;
      setFindingsCount(response?.count);
    });
  }, [specificTeam]);

  useEffect(() => {
    if (specificTeam.team) {
      let actionFilters: IFilter[] = [];
      actionFilters = [{
        entityKey: ActionFiltersKeys.TEAMS,
        selectedValue: [
          {
            value: specificTeam.team.name,
            displayText: specificTeam.team.name,
          },
        ],
      } as IFilter];
      getActionsCount(actionFilters);
      getActions(actionFilters);
    }
  }, [specificTeam]); // eslint-disable-line react-hooks/exhaustive-deps -- We want to run this only once

  if (specificTeam.isLoadingSpecificTeam || isLoadingActions || isLoadingActionsCount) return <LoadingBar />;

  return (
    <div className={styles.wrapper}>
      <div className={styles.breadcrumbWrapper}>
        <TeamsBreadcrumbs teamId={specificTeam?.team?.id} teamName={specificTeam?.team?.name} />

        {specificTeam.team && <SetSlackNotification teamId={specificTeam.team.id} teamName={specificTeam.team.name} />}
      </div>

      {specificTeam.team ? (
        <>
          <div className={styles.pageTitleWrapper}>
            <PageTitles subtitle={specificTeam.team.description} title={specificTeam.team.name} />

            <StatsCards
              filterName={ServerFindingKeys.team}
              filterValue={specificTeam.team.name}
              navigateToFindings={false}
              score={specificTeam.team.score}
              totalFindingsCount={findingsCount}
            />
          </div>

          <>
            <div className={styles.separatorWrapper}>
              <JitDivider />
            </div>

            <CollapseSection defaultExpanded header={ActionsHeader} testId='Actions-wrapper'>
              <div className={styles.collapsibleSection}>
                <Actions actions={actions.data} count={actionsCount || 0} isLoading={isLoadingActions} teamName={specificTeam.team.name} />
              </div>
            </CollapseSection>
          </>

          <div className={styles.separatorWrapper}>
            <JitDivider />
          </div>

          <div className={styles.content}>

            <ResourcesTable teamAssets={specificTeam.resources.data} teamId={specificTeam.team.id} />

            <MembersList members={specificTeam.members} />

            <ChildTeamsList childTeams={specificTeam.childTeams} />
          </div>
        </>
      ) : (
        <GeneralErrorPage
          backgroundColor='transparent'
          description='pages.teams.teamDetails.notFound.description'
          icon={JittyTheExplorer}
          isFullPage={false}
          title='pages.teams.teamDetails.notFound.title'
        />
      )}

    </div>
  );
};
