import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import { JitButtonVariants } from 'components/JitButton/JitButton';
import colors from 'themes/colors.module.scss';

interface Props extends Omit<ButtonProps, 'variant'> {
  nohover?: string;
  width?: number | string;
  height?: number | string;
  variant?: JitButtonVariants
}

export const JitButtonStyles = styled(Button)<Props>(({ style, nohover, width, height, variant }) => ({
  borderRadius: 8,
  textTransform: 'none',
  display: 'flex',
  padding: '4px 12px',
  minWidth: 0,
  alignItems: 'center',
  justifyContent: 'center',
  width: width || 'auto',
  height: height || 'auto',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
  },
  ...(variant === 'contained' && {
    '&.Mui-disabled': {
      borderColor: colors.gray,
      color: colors.white,
      backgroundColor: colors.gray,
    },
  }),
  ...(variant === 'outlined' && {
    backgroundColor: 'inherit',
    borderColor: colors.blue02,
    color: colors.blue02,
    '&:hover': {
      borderColor: colors.white,
      color: colors.white,
    },
    '&.Mui-disabled': {
      borderColor: colors.gray,
      color: colors.gray,
      backgroundColor: 'inherit',
    },
  }),
  ...(variant === ('danger' as JitButtonVariants) && {
    backgroundColor: colors.error,
    borderColor: colors.error,
    '&:hover': {
      backgroundColor: colors.error,
      borderColor: colors.error,
    },
    '&.Mui-disabled': {
      backgroundColor: colors.blue02,
      opacity: 0.3,
    },
  }),
  ...(variant === ('jitContained' as JitButtonVariants) && {
    backgroundColor: colors.jitPink,
    width: 128,
    padding: 12,
    borderRadius: 24,
    '&:hover': {
      backgroundColor: colors.jitPink,
      color: colors.jitPink,
    },
    '&.Mui-disabled': {
      backgroundColor: colors.jitPink,
      opacity: 0.3,
    },
  }),
  ...(variant === 'text' && {
    backgroundColor: 'inherit',
    color: colors.blue02,
    padding: '0',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.white,
    },
    '&.Mui-disabled': {
      backgroundColor: 'inherit',
      opacity: 0.3,
      color: colors.gray,
    },
  }),
  ...style,
  ...(nohover && {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }),
}));
