import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';

export const useCalculateMaxVisibleElementsCount = (elements: React.ReactElement[], containerWidth: number): number => {
  const [visibleCount, setVisibleCount] = useState(0);

  useEffect(() => {
    const tempContainer = document.createElement('div');
    tempContainer.style.visibility = 'hidden';
    document.body.appendChild(tempContainer);

    const root = createRoot(tempContainer);

    const WrapperComponent = () => {
      useEffect(() => {
        let totalWidth = 0;
        let count = 0;

        Array.from(tempContainer.children).forEach((child: Element) => {
          const childWidth = child.clientWidth;
          if (totalWidth + childWidth > containerWidth) return;
          totalWidth += childWidth;
          count += 1;
        });

        setVisibleCount(count);

        // Cleanup
        root.unmount();
        document.body.removeChild(tempContainer);
      }, []);

      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{elements}</>;
    };

    root.render(<WrapperComponent />);

    return () => {
      if (tempContainer.parentNode) {
        root.unmount();
        document.body.removeChild(tempContainer);
      }
    };
  }, [elements, containerWidth]);

  return visibleCount;
};
