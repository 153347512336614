import { MenuItemsData } from '../BaseTaskDialog/BaseTaskDialog';

import { MenuItemType } from 'components/JitMenu/JitMenu';
import { useTeamsContext } from 'context/TeamsContext/TeamsContext';
import { MenuItemKeyType } from 'types/interfaces';

export const useGetReposMenuItems = (selectedItem: MenuItemKeyType | undefined): MenuItemsData => {
  const { specificTeam } = useTeamsContext();

  const menuItems: Partial<MenuItemType>[] = specificTeam?.resources?.data
    ?.map((resource) => (
      resource.asset_type === 'repo' ? {
        itemKey: resource.asset_name,
        isSelected: resource.asset_name === selectedItem,
      } : null
    ))
    .filter((item) => item !== null) // Filter out the nulls
    .sort((a, b) => a!.itemKey.localeCompare(b!.itemKey)) as Partial<MenuItemType>[];

  return ({
    menuItems,
    isLoading: false,
  });
};
