import { FC } from 'react';

import styles from '../ShareSnapshotDialog.module.scss';

import { JitText } from 'components/JitText/JitText';

interface Props {
  snapshotURL: string;
}

export const DialogContentSnapshotURL: FC<Props> = ({ snapshotURL }) => (
  <div className={styles.snapshotUrlWrapper}>
    <JitText muted text='pages.performance.shareSnapshot.snapshotUrl' />

    <div className={styles.urlBox} data-testid='snapShotUrlBox'>
      <JitText noWrap overflowHiddenEllipsis text={snapshotURL} />
    </div>
  </div>
);
