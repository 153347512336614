import { FC } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import styles from './HoverRowIcons.module.scss';

import { FrameIcon, UnionIcon } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { constants } from 'globalConstants';
import { IgnoreFilterKeys } from 'pages/FindingsPage/constants';
import { FindingStatus } from 'types/enums/FindingStatus';
import { ActionFiltersKeys } from 'types/interfaces/Actions/ActionFilters';
import { ServerFindingKeys } from 'types/interfaces/Findings/IFindingServer';

interface Props {
  assetName: string;
  slug: string;
  teamName?: string;
}

export const HoverRowIcons: FC<Props> = ({ assetName, slug, teamName }) => {
  const navigate = useNavigate();
  const { routes: { FINDINGS, ACTIONS } } = constants;

  const onActionIconClick = () => {
    const searchParams: { [key: string]: [string] } = {
      [ActionFiltersKeys.ASSET_NAMES]: [assetName],
      [ActionFiltersKeys.PLAN_ITEMS]: [slug],
    };
    if (teamName !== undefined) {
      searchParams[ActionFiltersKeys.TEAMS] = [teamName];
    }
    navigate({
      pathname: `/${ACTIONS}`,
      search: createSearchParams(searchParams).toString(),
    });
  };

  const onFindingsIconClick = () => {
    const searchParams: { [key: string]: string | FindingStatus | IgnoreFilterKeys } = {
      [ServerFindingKeys.asset_name]: assetName,
      [ServerFindingKeys.plan_item]: slug,
      [ServerFindingKeys.status]: FindingStatus.OPEN,
      [ServerFindingKeys.ignored]: IgnoreFilterKeys.NOT_IGNORED,
    };
    if (teamName !== undefined) {
      searchParams[ServerFindingKeys.team] = teamName;
    }

    navigate({
      pathname: `/${FINDINGS}`,
      search: createSearchParams(searchParams).toString(),
    });
  };

  return (
    <div className={styles.wrapper}>
      <JitTooltip followCursor={false} placement='top' title='pages.teams.resourcePlanItemsStatuses.rowHover.toolTips.actions'>
        <JitIcon
          className={styles.iconWrapper}
          data-testid='action-page-link-icon'
          icon={FrameIcon}
          onClick={onActionIconClick}
          size={20}
        />
      </JitTooltip>

      <JitTooltip followCursor={false} placement='top' title='pages.teams.resourcePlanItemsStatuses.rowHover.toolTips.findings'>
        <JitIcon
          className={styles.iconWrapper}
          data-testid='findings-page-link-icon'
          icon={UnionIcon}
          onClick={onFindingsIconClick}
          size={20}
        />
      </JitTooltip>
    </div>
  );
};

