import { FC } from 'react';
import { useQuery } from 'react-query';

import { IPolicyTemplate } from '../../types';
import { Policy } from '../Policy/Policy';

import styles from './PolicyContent.module.scss';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { usePoliciesService } from 'services/PoliciesService/usePoliciesService';
import { Queries } from 'types/enums/Queries';

interface Props {
  template: IPolicyTemplate;
}

export const PolicyContent: FC<Props> = ({ template }) => {
  const { getPolicyRules } = usePoliciesService();
  const { data = [], isLoading } = useQuery([Queries.policyRules, template.policySlug], () => getPolicyRules(template.policySlug));

  return (
    <div className={styles.root}>
      {isLoading ? (
        <CircularLoadingSpinner />
      ) : (
        <Policy policyTemplate={template} rulesData={data} />
      )}
    </div>
  );
};
