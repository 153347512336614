import { FC } from 'react';

import styles from './LogDialogLogLine.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

type Props = {
  text: string;
  rowLineNumber: number;
};

export const LogDialogLogLine: FC<Props> = ({
  text,
  rowLineNumber,
}) => (
  <div className={styles.logLineWrapper}>
    <div className={styles.logLineNumberWrapper}>
      <JitText
        align='center'
        color={colors.gray}
        data-testid='log-row-line-number'
        size='m'
        style={{ // className doesn't work here
          width: 45, // Give enough width so numbers with many digits won't cause the line number to jump or look weird
          marginBottom: 0,
          lineHeight: '22px',
        }}
        text={rowLineNumber.toString()}
      />
    </div>

    <div>
      <JitText
        data-testid='log-row-text'
        size='m'
        style={{
          // Can't apply the following styles from className because the component overrides it
          fontFamily: 'Source Code Pro',
          marginBottom: 0,
          lineHeight: '22px',
        }}
        text={text}
      />
    </div>
  </div>
);
