import { FC, useState } from 'react';

import { SnapshotHistory } from './SnapshotHistory/SnapshotHistory';

import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitCopyToClipboardButton } from 'components/JitCopyToClipboardButton/JitCopyToClipboardButton';
import { JitText } from 'components/JitText/JitText';
import {
  DialogContentInputs,
} from 'pages/InsightsPage/SubPages/PerformancePage/components/ShareSnapshotDialog/components/DialogContentInputs';
import {
  DialogContentSnapshotURL,
} from 'pages/InsightsPage/SubPages/PerformancePage/components/ShareSnapshotDialog/components/DialogContentSnapshotURL';
import styles
  from 'pages/InsightsPage/SubPages/PerformancePage/components/ShareSnapshotDialog/ShareSnapshotDialog.module.scss';
import { TimeAgo } from 'types/enums';
import { ActionButton, ActionButtonVariant } from 'types/interfaces';
import { Snapshot } from 'types/interfaces/Metrics/Snapshots';

interface Props {
  closeDialog: () => void;
  createSnapshot: (snapshotName: string, snapshotExpiration: TimeAgo) => void;
  isLoading: boolean;
  createdSnapshot?: Snapshot;
}

export const DialogContent: FC<Props> = ({ closeDialog, createSnapshot, isLoading, createdSnapshot }) => {
  const [snapshotName, setSnapshotName] = useState<string>('');
  const [snapshotExpiration, setSnapshotExpiration] = useState<TimeAgo>(TimeAgo.OneWeek);

  const actionButtons: ActionButton[] = [{
    label: 'pages.performance.shareSnapshot.cancelButtonText',
    variant: ActionButtonVariant.OUTLINED,
    handleClick: closeDialog,
  }, {
    label: 'pages.performance.shareSnapshot.createSnapshotButtonText',
    disabled: !snapshotName || isLoading,
    handleClick: () => createSnapshot(snapshotName, snapshotExpiration),
    isLoading,
    shouldShowChildrenWhileLoading: true,
  }];

  return (
    <div className={styles.contentWrapper}>
      <JitText muted text='pages.performance.shareSnapshot.dialogMessage' />

      {createdSnapshot?.link ? (
        <>
          <DialogContentSnapshotURL snapshotURL={createdSnapshot?.link} />

          <div className={styles.copyButtonWrapper}>
            <JitCopyToClipboardButton
              buttonText='pages.performance.shareSnapshot.copyButtonText'
              tooltipText='pages.performance.shareSnapshot.copiedButtonText'
              valueToCopy={createdSnapshot?.link}
            />

          </div>
        </>
      ) : (
        <>
          <DialogContentInputs
            setSnapshotExpiration={setSnapshotExpiration}
            setSnapshotName={setSnapshotName}
            snapshotExpiration={snapshotExpiration}
            snapshotName={snapshotName}
          />

          <div className={styles.actionButtons}>
            {actionButtons.map((actionButton) => <JitActionButton key={`actionButton-${actionButton.label}`} actionButton={actionButton} />)}
          </div>
        </>
      )}

      <SnapshotHistory />
    </div>
  );
};
