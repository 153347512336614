export enum JitEventName {
  Deployment = 'deployment', // TODO: remove this after we remove it from the backend
  ProductionDeployment = 'production_deployment',
  NonProductionDeployment = 'non_production_deployment',
  OpenFixPullRequest = 'open_fix_pull_request',
  itemActivated = 'item_activated',
  PullRequestCreated = 'pull_request_created',
  PullRequestUpdated = 'pull_request_updated',
  TriggerScheduledTask = 'trigger_scheduled_task',
  ManualExecution = 'manual_execution',
  MergeDefaultBranch = 'merge_default_branch',
  ResourceAdded = 'resource_added',
  JitBranchChanged = 'jit_branch_changed',
}
