import colors from 'themes/colors.module.scss';

// reference for customizing the plan card: https://docs.stigg.io/docs/pricing-table#custom-css
export const customCss = `
  .stigg-plan-offering-container {
    background-color: #1A1E26;
    border-radius: 8px;
    border: none;
  }
  .stigg-current-plan {
    background-color: ${colors.cardContent};
  }
  .stigg-plan-header {
    font-weight: 700;
  }
  .stigg-paywall-plan-button-text {
    color: ${colors.white};
    font-weight: 700;
  }
  .stigg-paywall-plan-button {
    background-color: ${colors.blue};
    border: none;
  }
  .stigg-paywall-plan-button:hover {
    background-color: ${colors.blue};
  }
  .stigg-paywall-plan-button:disabled {
    background-color: transparent;
    border: 1px solid ${colors.gray};
  }
  .stigg-paywall-plan-button:disabled:hover {
    background-color: transparent;
  }
  .stigg-plan-header-divider {
    // in order to make the divider invisible
    background-color: transparent;
  }

  // for making sure the plan cards are rendering in the correct order
  .stigg-plan-jit-free {
    order: 1;
  }
  .stigg-plan-jit-growth {
    order: 2;
  }
  .stigg-plan-jit-enterprise {
    order: 3;
  }
`;
