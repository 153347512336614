import { FC } from 'react';

import { JitText } from 'components/JitText/JitText';
import { FindingStatus } from 'types/enums';
import { InsightCategoryFindings } from 'types/interfaces/Insights';

interface FindingsTextsProps {
  findings: InsightCategoryFindings
  boldFindingStatus: FindingStatus
}

export const FindingsTexts: FC<FindingsTextsProps> = ({
  boldFindingStatus, findings,
}) => {
  const { newFindings, ignoredFindings, fixedFindings } = findings;
  const totalFindings = newFindings + ignoredFindings + fixedFindings;

  return (
    <>
      <JitText
        data-testid='findingsDisplayTextTitle'
        params={{ totalFindings }}
        text='pages.insights.listCardItem.findings.total'
      />

      <JitText
        bold={boldFindingStatus === FindingStatus.OPEN}
        data-testid='openFindingsDisplayText'
        params={{ newFindings }}
        text={newFindings ? 'pages.insights.listCardItem.findings.new' : ''}
      />

      <JitText
        bold={boldFindingStatus === FindingStatus.IGNORED}
        data-testid='ignoredFindingsDisplayText'
        params={{ ignoredFindings }}
        text={ignoredFindings ? 'pages.insights.listCardItem.findings.ignored' : ''}
      />

      <JitText
        bold={boldFindingStatus === FindingStatus.FIXED}
        data-testid='fixedFindingsDisplayText'
        params={{ fixedFindings }}
        text={fixedFindings ? 'pages.insights.listCardItem.findings.fixed' : ''}
      />
    </>
  );
};

