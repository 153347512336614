import { FC } from 'react';

import { CounterCard, InsightsCard } from '../../baseComponents/index';
import styles from '../../baseComponents/InsightsCard/InsightsCard.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { ITrendingDevProductivity } from 'types/interfaces/Metrics/SecurityImpactMetrics/DevProductivity';

interface Props {
  devProductivityMetric: ITrendingDevProductivity;
}

export const ProductionOpenFindingsComponent: FC<Props> = ({ devProductivityMetric }) => {
  const { totalFindingsChangeRate, totalFindings, totalFindingsPositiveTrendIndicatorDirection, previousPeriodTotalFindings } = devProductivityMetric;

  return (
    <InsightsCard
      content={(
        <CounterCard
          count={{ count: totalFindings,
            color: totalFindings === 0 ? colors.white : colors.failRed }}
          percentageChip={{ percentage: totalFindingsChangeRate,
            bgColor: colors.cards,
            positiveTrendIndicatorDirection: totalFindingsPositiveTrendIndicatorDirection }}
        />
)}
      footer={(
        <JitText
          className={styles.footerText}
          color={colors.gray}
          components={{ days: <JitText bold color={colors.lightGray} text={previousPeriodTotalFindings} /> }}
          text='pages.overview.insights.previousPeriod'
        />
)}
      header={{ title: 'pages.securityImpact.securityPosture.detectedFindingsTitle' }}
      size='s'
      variant='top'
    />
  );
};
