import { FC, useState } from 'react';

import { InfoPopperContent } from '../InfoPopperContent/InfoPopperContent';
import { RankPopperContent } from '../RankPopperContent/RankPopperContent';

import { Info } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitPopper } from 'components/JitPopper/JitPopper';
import styles from 'pages/InsightsPage/SubPages/PerformancePage/components/GraphHeaderComponents/MetricInfoIcon/MetricInfoIcon.module.scss';
import { IPerformanceRateOption, PerformanceRateRank } from 'types/interfaces/Metrics/PerformanceRateOption';

interface Props {
  popperTitle: string;
  avgValue?: string;
  performanceRateOptions?: IPerformanceRateOption[];
  rank?: PerformanceRateRank;
  popperDescription: string;
  shouldDisablePopper: boolean;
}

export const MetricInfoIcon: FC<Props> = ({ avgValue, rank, popperDescription, popperTitle, performanceRateOptions, shouldDisablePopper }) => {
  const [isHoverInfoIcon, setIsHoverInfoIcon] = useState(false);
  return (
    <JitPopper
      content={rank ? (
        <RankPopperContent
          avgValue={avgValue!}
          currentRank={rank!}
          description={popperDescription}
          metricName={popperTitle}
          performanceRateOptions={performanceRateOptions!}
        />
      ) : (
        <InfoPopperContent
          description={popperDescription}
          metricName={popperTitle}
        />
      )}
      isWithArrow={false}
      open={isHoverInfoIcon && !shouldDisablePopper}
      shadowVariant='dark'
    >
      <JitIcon
        className={styles.icon}
        color='transparent'
        data-testid='infoIcon'
        icon={Info}
        onMouseEnter={() => { setIsHoverInfoIcon(true); }}
        onMouseLeave={() => { setIsHoverInfoIcon(false); }}
        size={10}
      />
    </JitPopper>
  );
};
