import { FC } from 'react';

import styles from './Score.module.scss';

import { JitText } from 'components/JitText/JitText';
import { ScoreGauge } from 'pages/SecurityPlansPage/components/ScoreGauge/ScoreGauge';

interface Props {
  score: number;
}

export const Score: FC<Props> = ({ score }) => (
  <div className={styles.wrapper}>
    <div className={styles.textWrapper}>
      <JitText bold data-testid='score' text={`${score}%`} />
    </div>

    <ScoreGauge score={score / 100} showAnimation variant='xs' />
  </div>
);
