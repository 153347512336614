import { createContext, useContext } from 'react';

import { IFilter, IPlanItemTemplate, IPlanTemplate } from 'types/interfaces';

interface IPlanTemplatesContext {
  planItemsTemplates: IPlanItemTemplate[];
  isLoading: boolean;
  planTemplates: IPlanTemplate[];
  enabledPlanTemplates: IPlanTemplate[];
  planItemsFilters: IFilter[];
  fetchPlanItemTemplates: () => void;
  fetchControls: () => void;
  fetchPlanTemplates: () => void;
  getPlanItemTemplateBySlug: (slug: string) => IPlanItemTemplate | undefined;
  getPlanTemplateBySlug: (slug: string) => IPlanTemplate | undefined;
}

export const PlanTemplatesContext = createContext<IPlanTemplatesContext>({
  planItemsTemplates: [],
  isLoading: false,
  planTemplates: [],
  enabledPlanTemplates: [],
  planItemsFilters: [],
  fetchPlanItemTemplates: () => { },
  fetchControls: () => { },
  fetchPlanTemplates: () => { },
  getPlanItemTemplateBySlug: () => undefined,
  getPlanTemplateBySlug: () => undefined,
});

export const usePlanTemplatesContext = () => useContext(PlanTemplatesContext);
