import { FC } from 'react';

import styles from './PriorityFactorsBox.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Factor {
  key: string;
  displayName: string;
}

interface Props {
  factors: Factor[];
  action?: {
    onClick: (key: string) => void;
    selectedFactor?: string;
  }
  editable?: boolean;
  viewMode?: boolean;
}

export const PriorityFactorsBox: FC<Props> = ({
  factors,
  action,
  editable,
  viewMode,
}) => {
  const isSelectedFactor = (factor: string) => (viewMode || !action || (factor === action.selectedFactor));

  const getFactorColor = (factor: Factor) => {
    if (isSelectedFactor(factor.key)) return colors.criticalPriority;
    return editable ? colors.white : colors.gray;
  };

  const calcFactorStyle = (index: number, factor: string) => {
    const isSelected = isSelectedFactor(factor);
    const isPrevSelected = !!action && (factors?.[index - 1]?.key === action.selectedFactor);

    let borderRadius = '8px';
    let borderRightWidth = 1;
    let borderLeftWidth = 1;
    if (factors.length > 1) {
      if (index === 0) {
        borderRadius = '8px 0 0 8px';
      } else if (index === factors.length - 1) {
        borderRadius = '0 8px 8px 0';
      } else {
        borderRadius = '0';
      }

      if (!isSelected) borderRightWidth = index === factors.length - 1 ? 1 : 0;
      if (isPrevSelected) borderLeftWidth = 0;
    }

    return {
      cursor: editable ? 'pointer' : 'default',
      borderRadius,
      borderRightWidth,
      borderLeftWidth,
    };
  };

  const handleClick = (factor: string) => {
    if (editable && action) {
      action.onClick(factor);
    }
  };

  return (
    <div className={styles.factorsSet}>
      {factors.map((factor, index) => (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          key={factor.key}
          className={isSelectedFactor(factor.key) ? styles.factorBox : styles.factorBoxNotSelected}
          data-testid={`factor-${factor.key}`}
          onClick={() => handleClick(factor.key)}
          role={action ? 'button' : 'text'}
          style={calcFactorStyle(index, factor.key)}
        >
          <JitText
            bold
            color={getFactorColor(factor)}
            noWrap
            size='s'
            text={factor.displayName}
          />
        </div>

      ))}
    </div>
  );
};
