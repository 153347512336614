import { FC } from 'react';

import { SecurityImpactMetricCard } from '../../../SecurityImpactWrapper/SecurityImpactWrapper';
import { FindingsOverTime } from '../FindingsOverTime/FindingsOverTime';
import { OpenFindings } from '../OpenFindings/OpenFindings';

import styles from './SecurityPostureExpandedContent.module.scss';

import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { ITotalFindingsGraph } from 'types/interfaces/Metrics/SecurityImpactMetrics/TotalFindingsGraph';

interface Props {
  totalFindingsGraphMetric: MetricWithLoadingIndication<ITotalFindingsGraph>
}

export const SecurityPostureExpandedContent: FC<Props> = ({ totalFindingsGraphMetric }) => (
  <div className={styles.wrapper} data-testid='SecurityPostureExpandedContent'>
    <div className={styles.cardWrapper}>
      <SecurityImpactMetricCard
        content={<FindingsOverTime totalFindingsGraphMetric={totalFindingsGraphMetric} />}
        mainTitle='pages.securityImpact.securityPosture.findingsOverTimeTitle'
        subtitle='pages.securityImpact.securityPosture.findingsOverTimeDescription'
      />
    </div>

    <div className={styles.cardWrapper}>
      <SecurityImpactMetricCard
        content={<OpenFindings totalFindingsGraphMetric={totalFindingsGraphMetric} />}
        mainTitle='pages.securityImpact.securityPosture.openFindingsTitle'
        subtitle='pages.securityImpact.securityPosture.openFindingsDescription'
      />
    </div>

  </div>
);
