import { isEmpty } from 'lodash';
import { useCallback } from 'react';

import { useFormatAndExtendFilters, useSyncFiltersWithQueryParams } from '.';

import { useFindingsContext } from 'context/FindingsContext/FindingsContext';
import { buildGetFindingsRequestParams } from 'context/FindingsContext/utils/buildGetFindingsRequestParams';
import { fetchFilterOptions } from 'services/FindingsService';
import { IFindingFilterType } from 'types/interfaces';
import { useQueryParams } from 'utils';

export const useInitFilters = () => {
  const { filters, setFilters, currentActiveColumn, setHasFetchedFilters, getSavedFilters } = useFindingsContext();
  const { relevantQueryParams, getUpdateFiltersWithInitialQueryParams } = useSyncFiltersWithQueryParams(filters);
  const { queryParams } = useQueryParams();

  const { formatAndExtendFilters } = useFormatAndExtendFilters();

  const getFilterOptions = useCallback(async () => {
    const formattedFilterParams = buildGetFindingsRequestParams({ currentActiveColumn });

    const fetchedOptions = await fetchFilterOptions(formattedFilterParams);

    if (!fetchedOptions) {
      console.error('Error while getting filter options');
    }
    return fetchedOptions;
  }, [currentActiveColumn]);

  const initFilters = useCallback(async () => {
    const serverFilterOptions = await getFilterOptions();

    if (serverFilterOptions) {
      let updatedFilters = formatAndExtendFilters(filters, serverFilterOptions);

      if (!isEmpty(relevantQueryParams)) {
        updatedFilters = getUpdateFiltersWithInitialQueryParams(updatedFilters);
      }

      if (isEmpty(relevantQueryParams) && 'id' in queryParams) {
        updatedFilters = updatedFilters.map((filter) => ({
          ...filter,
          selectedValue: filter.type === IFindingFilterType.MULTI_SELECT ? [] : '',
          isVisible: false,
        }));
      }

      setFilters(updatedFilters);
    }

    setHasFetchedFilters(true);
    getSavedFilters(true);
  }, [getFilterOptions, queryParams, setHasFetchedFilters, getSavedFilters, formatAndExtendFilters, filters, relevantQueryParams, getUpdateFiltersWithInitialQueryParams, setFilters]);

  return { initFilters };
};
