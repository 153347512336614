import { IActionFinding } from 'types/interfaces';

export const generateBranchProtectionSubtitle = (finding: IActionFinding) => {
  const locationSplit = finding.location_text.split('/');
  return locationSplit[2];
};

export const generateBranchProtectionTitle = (finding: IActionFinding) => {
  const locationSplit = finding.location_text.split('/');
  return `${locationSplit[0]}/${locationSplit[1]}`;
};

// export const getFindingRepoLink = (finding: IActionFinding) => {
//   const locationSplit = finding.location_text.split('/');
//   const orgAndRepo = `${locationSplit[0]}/${locationSplit[1]}`;
//   return `https://www.github.com/${orgAndRepo}`;
// };
// - Currently unused but will be used in the coming iteration.
