import { FC } from 'react';

import styles from './StatusIndicationCellNew.module.scss';

import { DotClockRunning, DotQueued, Empty, ErrorDot, FailedDot, GreenDotCheck } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { STATUSES } from 'pages/PipelinesPage/constants';
import { PipelineStatus } from 'types/enums';

type Props = {
  status: PipelineStatus;
};

export const StatusIndicationBox: FC<Props> = ({ status }) => {
  const relevantData = {
    [STATUSES.SUCCESS]: {
      icon: GreenDotCheck || Empty,
      className: styles.successWrapper || '',
      text: 'pages.pipelines.statuses.displayNames.success',
    },
    [STATUSES.ERROR]: {
      icon: ErrorDot || Empty,
      className: styles.failedWrapper || '',
      text: 'pages.pipelines.statuses.displayNames.error',
    },
    [STATUSES.FAILED]: {
      icon: FailedDot || Empty,
      className: styles.failedWrapper || '',
      text: 'pages.pipelines.statuses.displayNames.failed',
    },
    [STATUSES.RUNNING]: {
      icon: DotClockRunning || Empty,
      className: styles.runningWrapper || '',
      text: 'pages.pipelines.statuses.displayNames.running',
    },
    [STATUSES.QUEUED]: {
      icon: DotQueued || Empty,
      className: styles.queuedWrapper || '',
      text: 'pages.pipelines.statuses.displayNames.queued',
    },
  };

  const data = relevantData?.[status];

  return (
    <div className={`${styles.cellElementsWrapper} ${data?.className}`}>
      {data?.icon
          && (
            <JitIcon
              centered={false}
              className={styles.iconStatus}
              data-testid='icon'
              icon={data?.icon}
              size={20}
            />
          )}

      <JitText
        bold
        data-testid='text'
        size='m'
        text={data?.text}
      />
    </div>
  );
};
