import { useAuthUser } from '@frontegg/react';
import Modal from '@mui/material/Modal';
import React, { useState, useEffect, useMemo } from 'react';

import styles from './SearchModal.module.scss';

import { ChatHeader, UserMessage, BotMessage, ChatFooter } from '.';

import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { useAskQuestion } from 'services/DocsService/docsService';
import { stopPropagation } from 'utils';

interface SearchModalProps {
  handleClose: () => void;
  open: boolean;
}

interface BotAnswer {
  [key: string]: string;
}

export const SearchModal: React.FC<SearchModalProps> = ({ handleClose, open }) => {
  const [questionInput, setQuestionInput] = useState<string>('');

  const [userQuestions, setUserQuestions] = useState<string[]>([]);
  const [botAnswers, setBotAnswers] = useState<BotAnswer>({});

  const frontEggUser = useAuthUser();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const { askQuestion, answer, isPolling } = useAskQuestion();

  const handleSend = async () => {
    const question = questionInput.trim();
    if (question) {
      await askQuestion(question, frontEggUser.tenantId, frontEggUser.id);
      setBotAnswers({
        ...botAnswers,
        [question]: '',
      });

      setUserQuestions([...userQuestions, question]);
      setQuestionInput('');
      sendAnalyticsEvent({
        action: 'ask-question',
        params: {
          question,
        },
      });
    }
  };

  const lastQuestion = useMemo(() => userQuestions[userQuestions.length - 1], [userQuestions]);

  useEffect(() => {
    if (lastQuestion && answer) {
      setBotAnswers((prev) => ({
        ...prev,
        [lastQuestion]: answer,
      }));
    }
  }, [answer, lastQuestion]);

  const handleSendKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.shiftKey) { // ignore shift enter
      return;
    }
    if (e.key === 'Enter') {
      e.preventDefault(); // prevent new line
      handleSend();
    }
  };

  const handleFeedback = (e: React.MouseEvent<HTMLElement>) => {
    const { action } = e.currentTarget.dataset;
    if (action) {
      sendAnalyticsEvent({
        action,
        params: {
          question: lastQuestion,
        },
      });
    }
  };

  const onResetChat = () => {
    setUserQuestions([]);
    setBotAnswers({});
  };

  return (
    <Modal data-testid='docsQaModal' onClick={stopPropagation} onClose={handleClose} open={open}>
      <div className={styles.modal}>

        <div className={styles.content}>
          <ChatHeader isPolling={isPolling} onReset={onResetChat} />

          {userQuestions.map((question, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={`${question}-${index}`}>
              <UserMessage message={question} profilePictureUrl={frontEggUser.profilePictureUrl || ''} />

              {botAnswers[question] && (
                <BotMessage message={botAnswers[question]} onFeedback={handleFeedback} />
              )}
            </React.Fragment>
          ))}
        </div>

        <ChatFooter
          isPolling={isPolling}
          onInputChange={(e) => setQuestionInput(e.target.value)}
          onInputKeyDown={handleSendKeyDown}
          onSend={handleSend}
          questionInput={questionInput}
        />

      </div>
    </Modal>
  );
};
