import React from 'react';

import styles from './FindingsOverTimeCheckboxes.module.scss';

import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface FindingsOverTimeCheckboxesProps {
  displayDetectedLine: boolean;
  setDisplayDetectedLine: (value: boolean) => void;
  displayResolvedLine: boolean;
  setDisplayResolvedLine: (value: boolean) => void;
}

export const FindingsOverTimeCheckboxes: React.FC<FindingsOverTimeCheckboxesProps> = ({
  displayDetectedLine,
  setDisplayDetectedLine,
  displayResolvedLine,
  setDisplayResolvedLine,
}) => {
  const disabledDetected = !displayResolvedLine && displayDetectedLine;
  const disabledResolved = !displayDetectedLine && displayResolvedLine;
  return (
    <div className={styles.checkboxesWrapper}>
      <div className={styles.checkboxWrapper}>
        <JitCheckbox
          checked={displayResolvedLine}
          data-testid='resolvedRateGraphHeaderCheckbox'
          disabled={disabledResolved}
          onChange={() => setDisplayResolvedLine(!displayResolvedLine)}
        />

        <JitText bold color={colors.performanceGreen} text='pages.securityImpact.resolvedFindings.resolvedButton' />
      </div>

      <div className={`${styles.checkboxWrapper}  ${styles.detectedCheckbox}`}>
        <JitCheckbox
          checked={displayDetectedLine}
          data-testid='detectedRateGraphHeaderCheckbox'
          disabled={disabledDetected}
          onChange={() => setDisplayDetectedLine(!displayDetectedLine)}
        />

        <JitText bold color={colors.failRed} text='pages.securityImpact.resolvedFindings.detectedButton' />
      </div>

    </div>
  );
};
