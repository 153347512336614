import { FC } from 'react';

import { PlanSelector } from './components/PlanSelector/PlanSelector';
import { SecurityPlanInfoCard } from './components/SecurityPlanInfoCard/SecurityPlanInfoCard';
import { SecurityPlanTopRowDetails } from './components/SecurityPlanTopRowDetails/SecurityPlanTopRowDetails';
import styles from './SecurityPlanTopRow.module.scss';

import { ExportButton } from 'components/ExportButton';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { useGetPlanReport } from 'context/PlansContext/hooks/useGetPlanReport';
import { IPlanDetails } from 'types/interfaces';

interface Props {
  plan: IPlanDetails;
}

export const SecurityPlanTopRow: FC<Props> = ({ plan }) => {
  const { getPlanReport } = useGetPlanReport();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const handleExportClick = async () => {
    sendAnalyticsEvent({
      action: 'export-plan-button-clicked',
      params: { 'export-plan-button-slug': plan.slug },
    });

    return getPlanReport(plan.slug);
  };
  return (
    <div>
      <div className={styles.topRow}>
        <PlanSelector plan={plan} />

        <ExportButton
          handleClick={handleExportClick}
          text='pages.plan.exportPlanResults'
        />
      </div>

      <div className={styles.wrapper}>
        <SecurityPlanInfoCard plan={plan} />

        <SecurityPlanTopRowDetails plan={plan} />
      </div>
    </div>
  );
};
