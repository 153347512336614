import { FC } from 'react';

import { IntegrationDetailsExistingAccount } from './IntegrationDetailsExistingAccount';
import { IntegrationDetailsExistingOrg } from './IntegrationDetailsExistingOrg';

import { AwsIntegrationAccount } from 'types/interfaces';

interface Props {
  account: AwsIntegrationAccount
}

export const IntegrationDetailsExisting: FC<Props> = ({ account }) => (
  account.type === 'org' ? <IntegrationDetailsExistingOrg account={account} /> : <IntegrationDetailsExistingAccount account={account} />
);
