import CircularProgress from '@mui/material/CircularProgress';
import saveAs from 'file-saver';

import styles from './exportFindingsToCsv.module.scss';

import { Download } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { useFindingsContext } from 'context/FindingsContext/FindingsContext';

export const ExportFindingsToCsv = () => {
  const { getCsvFile, isFetchingCSV, filters } = useFindingsContext();

  const handleClick = async () => {
    const csvDownloadLink = await getCsvFile(filters);
    if (csvDownloadLink) {
      saveAs(csvDownloadLink);
    }
  };

  const getDownloadIcon = () => {
    if (isFetchingCSV) {
      return <CircularProgress className={styles.downloadIcon} color='inherit' size={22} />;
    }
    return <JitIcon color='currentColor' icon={Download} iconClassName={styles.downloadIcon} />;
  };

  return (
    <JitButton
      className={styles.downloadButton}
      data-testid='export-findings-to-csv-button'
      disabled={isFetchingCSV}
      onClick={handleClick}
      variant='outlined'
    >
      {getDownloadIcon()}

      <JitText color='inherit' horizontalAlign='center' text='pages.findings.exportCsv' />
    </JitButton>
  );
};
