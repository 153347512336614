export enum AssetType {
  REPO = 'repo',
  AWS_ACCOUNT = 'aws_account',
  GCP_ACCOUNT = 'gcp_account',
  AZURE_ACCOUNT = 'azure_account',
  AWS_ORG = 'aws_org',
  ORG = 'org',
  API = 'api',
  WEB = 'web',
  APPLICATION = 'application',
}
