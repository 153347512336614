import { FC, FunctionComponent } from 'react';

import styles from './CoveredLayerCard.module.scss';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitStatusChip } from 'components/JitStatusChip/JitStatusChip';
import { JitText } from 'components/JitText/JitText';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import colors from 'themes/colors.module.scss';
import { ISvg } from 'types/interfaces';

interface Props {
  icon: FunctionComponent<ISvg>;
  title: string;
  activeItems: string[];
  inactiveItems: string[];
  shouldShowItemDetails: boolean;
}

export const CoveredLayerCard: FC<Props> = ({ icon, title, activeItems, inactiveItems, shouldShowItemDetails }) => (
  <div className={styles.wrapper}>
    <div className={styles.header}>
      <div className={styles.icon}>
        <JitIcon icon={icon} size={16} />
      </div>

      <div className={styles.headerText}>
        <TooltipOnlyOnOverflow bold overflowHiddenEllipsis text={title} />

        {!shouldShowItemDetails
        && (
        <div className={styles.coveredUncovered}>
          <JitText bold size='m' text={activeItems.length} />

          <JitText color={colors.gray} size='m' text='/' />

          <JitText color={colors.gray} size='m' text={`${activeItems.length + inactiveItems.length}`} />
        </div>
        )}
      </div>
    </div>

    {shouldShowItemDetails && (
    <div className={styles.items}>
      {activeItems.map((item) => (
        <JitStatusChip key={item} success text={item} textColor={colors.white} />
      ))}

      {inactiveItems.map((item) => (
        <JitStatusChip key={item} success={false} text={item} textColor={colors.white} />
      ))}
    </div>
    )}
  </div>
);
