import { CurveType } from 'recharts/types/shape/Curve';
import { AxisInterval } from 'recharts/types/util/types';

import { dateStringToNumber } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/utils';
import colors from 'themes/colors.module.scss';
import { TimeAgo } from 'types/enums';
import { formatDate } from 'utils';

export const xAxisDxToPeriod = {
  [TimeAgo.OneWeek]: -33,
  [TimeAgo.TwoWeeks]: -15,
  [TimeAgo.OneMonth]: -8,
  [TimeAgo.ThreeMonths]: -3,
};

export const commonLineChartProps = {
  height: 250,
  margin: {
    top: 35,
    right: 20,
    left: 0,
    bottom: 0,
  },
  style: { cursor: 'pointer' },
  width: 550,
};

export const commonAreaChartProps = {
  height: 250,
  margin: {
    top: 35,
    right: 20,
    left: 0,
    bottom: 0,
  },
  style: { cursor: 'pointer' },
  width: 550,
};
export const commonAreaProps = {
  activeDot: false,
  type: 'monotone' as CurveType,
  fillOpacity: 1,
};
export const commonLineProps = {
  activeDot: {
    stroke: 'black',
    strokeWidth: 3,
    r: 4,
  },
  dot: false,
  strokeWidth: 2,
  type: 'monotone' as CurveType,
  animationDuration: 500,
};

const YAXIS_TEXT_WITH_BUFFER = 40;
const YAXIS_TEXT_AVG_CHAR_WIDTH = 12;

export const commonYAxisProps = (maxTextLength: number) => ({
  axisLine: false,
  tickLine: false,
  width: maxTextLength * YAXIS_TEXT_AVG_CHAR_WIDTH + YAXIS_TEXT_WITH_BUFFER,
  style: {
    fontSize: '12px',
  },
});

export const commonXAxisProps = {
  type: 'number' as 'number',
  minTickGap: -1000,
  interval: 0 as AxisInterval,
  axisLine: false,
  style: { fontSize: '12px' },
  tickLine: false,
  height: 33,
  tickMargin: 15,
  tickFormatter: (date: number) => formatDate(new Date(date), false, false),
  dataKey: ({ date }: { date: string }) => dateStringToNumber(date),
};

export const commonTooltipProps = {
  cursor: false,
  wrapperStyle: { outline: 'none' },
};

export const commonGridProps = {
  stroke: '#1C1E23',
  vertical: false,
};

export const commonPieChartWrapperProps = {
  height: 300,
  width: 250,
};

export const commonPieChartProps = {
  cornerRadius: 8,
  cx: '45%',
  cy: '50%',
  dataKey: 'count',
  innerRadius: 80,
  outerRadius: 90,
  paddingAngle: 5,
};

export const commonPieLabelProps = {
  fill: colors.white,
};

export const commonPieCellProps = {
  stroke: 'none',
};
