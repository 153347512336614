import { FC } from 'react';

import styles from './FindingCountList.module.scss';

import { Dot } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import colors from 'themes/colors.module.scss';
import { FindingCountListItem } from 'types/interfaces/Metrics/PieChartCountList/PieChartCountList';

interface FindingCountListProps {
  items: FindingCountListItem[];
  setHoveredItem: (item: FindingCountListItem | undefined) => void;
  hoveredItem?: FindingCountListItem;
  onItemClick: (searchValue: string) => void;
}

export const FindingCountList: FC<FindingCountListProps> = ({ items, setHoveredItem, hoveredItem, onItemClick }) => (
  <div className={styles.list}>
    {items.map((listItem) => (
      <div
        key={listItem.displayName}
        className={styles.listItem}
        data-testid={`findingCountListItem-${listItem.displayName}`}
        onClick={() => onItemClick(listItem.key)}
        onMouseEnter={() => setHoveredItem(listItem)}
        onMouseLeave={() => setHoveredItem(undefined)}
        role='button'
        tabIndex={0}
      >
        <div className={styles.titleColorIndicator}>
          <JitIcon color={listItem.color} data-testid={`findingCountListItem-${listItem.displayName}-color-indicator`} icon={Dot} size={10} />

          {listItem.icon && <JitIcon color={listItem.color} data-testid={`findingCountListItem-${listItem.displayName}-icon`} icon={listItem.icon} size={20} />}

          <TooltipOnlyOnOverflow bold data-testid={`findingCountListItem-${listItem.displayName}-name`} noWrap text={listItem.displayName} />

        </div>

        <div className={styles.showMoreAndCountWrapper}>
          {hoveredItem?.displayName === listItem.displayName && (

          <JitText
            color={colors.iris}
            noWrap
            text='pages.performance.graphs.findingCountList.seeMore'
          />

          )}

          <JitText bold data-testid={`findingCountListItem-${listItem.displayName}-count`} text={listItem.count.toLocaleString()} />
        </div>

      </div>
    ))}
  </div>
);
