import { MenuItemsData } from '../BaseTaskDialog/BaseTaskDialog';

import { MenuItemType } from 'components/JitMenu/JitMenu';
import { useGetSlackChannels } from 'context/SlackContext/hooks/useGetSlackChannels';

export const useGetChannelsMenuItems = (): MenuItemsData => {
  const { slackChannels, isSlackChannelsLoading } = useGetSlackChannels();

  const menuItems: Partial<MenuItemType>[] = slackChannels?.map((channel) => ({
    itemKey: channel.name,
  })).sort((a, b) => a.itemKey.localeCompare(b.itemKey)) || [];

  return ({
    menuItems,
    isLoading: isSlackChannelsLoading,
  });
};
