
import { constants } from 'globalConstants';
import { IOnboardingState } from 'types/interfaces/index';

const setOnboardingState = (onboardingState: IOnboardingState) => {
  localStorage.setItem(constants.ONBOARDING_STATE_KEY, JSON.stringify(onboardingState));
};

const getOnboardingState = () => {
  const onboardingStateString = localStorage.getItem(constants.ONBOARDING_STATE_KEY);
  if (onboardingStateString) {
    return JSON.parse(onboardingStateString);
  }
  return undefined;
};

const deleteOnboardingState = () => {
  localStorage.removeItem(constants.ONBOARDING_STATE_KEY);
};

export const useOnboardingState = () => ({
  setOnboardingState,
  getOnboardingState,
  deleteOnboardingState,
});
