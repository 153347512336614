import { FC } from 'react';

import styles from './NoFindings.module.scss';

import { JittyNoFindings } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';

interface Props {
  title?: string;
  subtitle?: string;
}

export const NoFindings: FC<Props> = ({ title, subtitle }) => (
  <div data-testid='noFindings'>
    <JitIcon
      color='rgba(0, 0, 0, 0)'
      data-testid='NoFindingsIcon'
      icon={JittyNoFindings}
      iconClassName={styles.jittyNoFindingsIcon}
    />

    <JitText align='center' bold data-testid='wellDone' size='l' text={title || 'pages.findings.emptyFindingsTable.noFindings.wellDone'} />

    <JitText
      align='center'
      data-testid='noFindingsSoFar'
      marginTop='10px'
      text={subtitle || 'pages.findings.emptyFindingsTable.noFindings.noFindingsSoFar'}
    />

  </div>
);
