import { CSSProperties, FC, Fragment, useMemo } from 'react';

import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import { ItemContent } from 'components/JitMenu/ItemContent';
import { MenuItemType, MenuVariant } from 'components/JitMenu/JitMenu';
import { StyledMenuItem } from 'components/JitMenu/JitMenu.styles';
import brightVariantStyles from 'components/JitMenu/JitMenuBrightVariant.module.scss';
import defaultVariantStyles from 'components/JitMenu/JitMenuDefaultVariant.module.scss';
import lightVariantStyles from 'components/JitMenu/JitMenuLightVariant.module.scss';
import { JitRadioButton } from 'components/JitRadioButton/JitRadioButton';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { IMouseEvent, MenuItemKeyType } from 'types/interfaces';

export type SelectedDesignVariants = 'radioButtons' | 'fillRow';

interface Props {
  items: MenuItemType[],
  categoryKey?: string,
  isVisible?: boolean,
  onMenuItemClick?: ({ e, categoryKey, itemKey }: { e: IMouseEvent, categoryKey?: string, itemKey: MenuItemKeyType }) => void,
  withCheckbox?: boolean,
  withRadioButtons?: boolean,
  style?: CSSProperties,
  className?: string,
  menuItemStyle?: CSSProperties,
  selectedDesign?: SelectedDesignVariants;
  variant?: MenuVariant;
}

const variantToStyle: Record<MenuVariant, Record<string, string>> = {
  default: defaultVariantStyles,
  bright: brightVariantStyles,
  light: lightVariantStyles,
};

const renderTooltipIfNeeded = (
  content: JSX.Element,
  tooltip?: string | null,
  itemKey?: MenuItemKeyType,
): JSX.Element => (tooltip ? (
  <JitTooltip
    key={itemKey?.toString()}
    arrow
    enterDelay={100}
    followCursor={false}
    leaveDelay={0}
    placement='right'
    sx={{
      maxWidth: 'none',
      position: 'fixed',
    }}
    title={tooltip}
  >
    {content}
  </JitTooltip>
) : (
  content
));

export const MenuItems: FC<Props> = ({
  items, categoryKey, isVisible = true, onMenuItemClick, withCheckbox = false, withRadioButtons = false, style, className = '', menuItemStyle = {},
  selectedDesign = 'radioButtons', variant = 'default',
}) => {
  const styles = useMemo(() => variantToStyle[variant], [variant]);
  const selectedDesignMap = {
    radioButtons: styles.menuItem,
    fillRow: styles.menuItemSelected,
  };

  return (
    <div className={`${className} ${styles.menuItemsWrapper} ${isVisible ? '' : styles.hidden}`} style={style}>
      {items.map(({ itemKey, itemContent, itemWrapper, isSelected, disabled, tooltip }) => {
        const menuItem = (
          <StyledMenuItem
            key={itemKey.toString()}
            data-testid={`JitMenuItem-${itemKey}`}
            disabled={disabled}
            disableRipple
            onClick={(e) => {
              if (onMenuItemClick) {
                onMenuItemClick({
                  e,
                  categoryKey,
                  itemKey,
                });
              }
            }}
            style={style}
            variant={variant}
          >
            <div className={isSelected ? `${selectedDesignMap[selectedDesign]}` : ` ${styles.menuItem}`} style={menuItemStyle}>
              {withCheckbox && (
                <JitCheckbox
                  checked={isSelected}
                  sx={{ padding: 2 }}
                />
              )}

              {withRadioButtons && (
                <JitRadioButton
                  checked={isSelected}
                  sx={{ padding: 2 }}
                />
              )}

              <ItemContent itemContent={itemContent} />
            </div>
          </StyledMenuItem>
        );

        const wrappedMenuItem = itemWrapper ? (
          <Fragment key={`wrapper-${menuItem.key}`}>
            {itemWrapper(menuItem)}
          </Fragment>
        ) : menuItem;

        return renderTooltipIfNeeded(wrappedMenuItem, tooltip, itemKey);
      })}
    </div>
  );
};
