import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './PlanItemDetailsResultsStatusBreakdown.module.scss';

import { ErrorDot, FailedDot, GreenDotCheck } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { constants } from 'globalConstants';
import colors from 'themes/colors.module.scss';
import { IStatusProperties, IPlanItemStatusCount } from 'types/interfaces';

interface Props {
  statusProperties: IStatusProperties;
}

interface StatusData {
  statusCount: IPlanItemStatusCount;
  borderColor: string;
  icon: FC;
  text: string;
  showPipeline?: boolean;
}

export const PlanItemDetailsResultsStatusBreakdown: FC<Props> = ({ statusProperties }) => {
  const navigate = useNavigate();

  const navigateToPipeline = (pipelineId: string) => {
    navigate(`/${constants.routes.PIPELINES}/${pipelineId}`);
  };

  const statusData: { [key: string]: StatusData } = {
    error: {
      statusCount: statusProperties.error_count,
      borderColor: colors.failRedBackground,
      icon: ErrorDot,
      text: 'pages.plan.itemDetails.results.errorResources',
      showPipeline: true,
    },
    failure: {
      statusCount: statusProperties.failure_count,
      borderColor: colors.failRedBackground,
      icon: FailedDot,
      text: 'pages.plan.itemDetails.results.failedResources',
    },
    success: {
      statusCount: statusProperties.success_count,
      borderColor: colors.successGreenBackground,
      icon: GreenDotCheck,
      text: 'pages.plan.itemDetails.results.successResources',
    },
  };

  return (
    <div className={styles.statusBreakdownWrapper}>
      {Object.entries(statusData).map(([key, status]) => (
        status.statusCount.count > 0 && (
          <div key={key} className={styles.statusRowWrapper}>
            <div className={styles.statusBoxWrapper} style={{ borderColor: status.borderColor }}>
              <JitIcon
                centered
                color={colors.cards}
                data-testid={`breakdown-${key}-icon`}
                icon={status.icon}
                size={12}
              />

              <JitText
                params={{
                  count: status.statusCount.count,
                  plusSign: status.statusCount.has_more ? '+' : '',
                }}
                size='s'
                text={`${status.text}.${status.statusCount.count === 1 ? 'singular' : 'plural'}`}
              />
            </div>

            {status.showPipeline && status.statusCount.last_pipeline_id && (
              <JitText
                color={colors.iris}
                data-testid={`breakdown-${key}-pipeline-link`}
                onClick={() => navigateToPipeline(status.statusCount.last_pipeline_id as string)}
                text='pages.plan.itemDetails.results.latestPipeline'
              />
            )}
          </div>
        )
      ))}
    </div>
  );
};
