import DialogContent from '@mui/material/DialogContent';
import { FC, useMemo } from 'react';

import styles from './integrationLinkMessage.module.scss';
import { getAwsAccountIntegrationCloudFormationLink } from './utils/getAwsAccountIntegrationCloudFormationLink';

import { IntegrationSteps } from 'components/JitDialogs/IntegrationDialog/components/IntegrationSteps/IntegrationSteps';

interface Props {
  integrationType: string;
  onClose: () => void;
  externalId?: string;
  disabled: boolean;
  isLoading?: boolean;
}

export const IntegrationLinkMessage: FC<Props> = ({ disabled, externalId, integrationType, onClose, isLoading = false }) => {
  const linkUrl = useMemo(() => (externalId ? getAwsAccountIntegrationCloudFormationLink(externalId, integrationType) : ''), [externalId, integrationType]);

  const handleLinkClick = () => {
    window.open(linkUrl);
    onClose();
  };

  const getContent = () => {
    if (linkUrl) {
      return <IntegrationSteps disabled={disabled} handleLinkClick={handleLinkClick} integrationType={integrationType} isLoading={isLoading} />;
    }
    return <IntegrationSteps disabled={disabled || isLoading} handleLinkClick={handleLinkClick} integrationType={integrationType} isLoading={isLoading} />;
  };
  return (
    <DialogContent sx={{
      p: '6px 0',
      overflow: 'auto',
    }}
    >
      <div className={styles.container}>
        {getContent()}
      </div>

    </DialogContent>
  );
};
