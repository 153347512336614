import { constants } from 'globalConstants';

export const PLAN_PAGE_ROUTE = `/${constants.routes.PLANS}`;
export const PERFORMANCE_PAGE_ROUTE = `/${constants.routes.insights.BASE_ROUTE}/${constants.routes.insights.PERFORMANCE}`;
export const ACTIONS_ROUTE = `/${constants.routes.ACTIONS}`;
export const SPECIFIC_ACTION_ROUTE = `/${constants.routes.ACTIONS}?${constants.routes.pathParameters.ACTION_ID}=`;
export const INTEGRATIONS_ROUTE = `/${constants.routes.INTEGRATIONS}`;
export const PLANS_ROUTE = `/${constants.routes.PLANS}`;
export const SECURITY_PLANS_LEARN_MORE_LINK = 'https://docs.jit.io/docs/security-controls';
export const PLAN_ITEM_INTERNAL_WEBAPP_LINK = 'JIT_WEBAPP';
