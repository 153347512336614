import { FC } from 'react';

import styles from './GraphHeader.module.scss';

import { Description, TitleWithIcon } from 'pages/InsightsPage/SubPages/PerformancePage/components/GraphHeaderComponents';

interface Props {
  title: string;
  popperTitle: string;
  isLoading: boolean;
  description: string;
  popperDescription: string;
}

export const GraphHeader: FC<Props> = ({
  title,
  popperTitle,
  isLoading,
  description,
  popperDescription,
}) => (
  <div data-testid='exposureWindowHeader'>
    <div className={styles.headerWrapper}>
      <TitleWithIcon
        displayRankInfoIcon
        isLoading={isLoading}
        popperDescription={popperDescription}
        popperTitle={popperTitle}
        title={title}
      />
    </div>

    <Description description={description} />
  </div>
);
