import { FC } from 'react';

import {
  SeverityCritical,
  SeverityCriticalFull,
  SeverityCriticalNew,
  SeverityHigh,
  SeverityHighFull,
  SeverityHighNew,
  SeverityLow,
  SeverityLowFull,
  SeverityLowNew,
  SeverityMedium,
  SeverityMediumNew,
  SeverityMediumFull,
} from 'assets';
import { Severity } from 'types/enums';
import { ISvg } from 'types/interfaces';

export const getSeverityIcon = (severity: string, newIcons: boolean = false, fullWord: boolean = false): FC<ISvg> | undefined => {
  const severityUpperCase = severity.toUpperCase();
  switch (severityUpperCase) {
    case Severity.Critical:
      if (fullWord && !newIcons) {
        return SeverityCriticalFull;
      }
      return newIcons ? SeverityCriticalNew : SeverityCritical;
    case Severity.High:
      if (fullWord && !newIcons) {
        return SeverityHighFull;
      }
      return newIcons ? SeverityHighNew : SeverityHigh;
    case Severity.Medium:
      if (fullWord && !newIcons) {
        return SeverityMediumFull;
      }
      return newIcons ? SeverityMediumNew : SeverityMedium;
    case Severity.Low:
      if (fullWord && !newIcons) {
        return SeverityLowFull;
      }
      return newIcons ? SeverityLowNew : SeverityLow;
    default:
      return undefined;
  }
};

export const severityIconFullWidthMap = {
  [Severity.Critical]: 62,
  [Severity.High]: 50,
  [Severity.Medium]: 72,
  [Severity.Low]: 50,
};

export const getSeverityIconFullWidth = (severity: string): number | undefined => {
  const severityUpperCase = severity.toUpperCase();
  return severityIconFullWidthMap[severityUpperCase as keyof typeof severityIconFullWidthMap];
};
