/* eslint-disable react/no-array-index-key */
import React from 'react';

import styles from './Carousel.module.scss';

interface Props<T> {
  ChildComponent: React.ComponentType<T>;
  options: T[];
}

export const Carousel = <T extends {}>({ ChildComponent, options }: Props<T>) => (
  <div className={styles.wrapper}>
    <div className={styles.slidesContainer}>

      {options.map((option, index) => (
        <ChildComponent key={index} {...option} />
      ))}
    </div>

    <div className={styles.dots}>
      {options.map((_, index) => (
        <div key={index} className={styles.dot} />
      ))}
    </div>
  </div>
);
