import { FC } from 'react';

import { DisabledElement } from 'components/DisabledElement/DisabledElement';
import { JitText } from 'components/JitText/JitText';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { ITableCell } from 'types/interfaces';

export const NameCell: FC<ITableCell> = ({ cell: { value: { name, isActivated } } }) => (
  <DisabledElement isDisabled={!isActivated}>
    <TooltipOnlyOnOverflow bold size='l' tooltipText={name}>
      <JitText noWrap overflowHiddenEllipsis text={name} />
    </TooltipOnlyOnOverflow>
  </DisabledElement>
);
