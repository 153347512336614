import { FC, useCallback, useState } from 'react';

import { SlackIcon } from 'assets';
import { DialogCard } from 'components/JitDialogs/DialogCard/DialogCard';
import { SlackIntegrationDialog, SlackIntegrationDialogStage } from 'components/JitDialogs/SlackIntegrationDialog/SlackIntegrationDialog';
import { getSlackRedirectUri } from 'pages/IntegrationsPage/utils';
import { POST_INSTALL_QUERY_PARAM } from 'pages/TeamsPage/constants';
import { useSlackIntegrationStatus } from 'pages/TeamsPage/SubPages/TeamsPage/hooks/useSlackIntegrationStatus';
import { Vendor } from 'types/enums';

interface Props {
  description: string;
  configureText: string;
}

export const SlackIntegrationCard: FC<Props> = ({ description, configureText }) => {
  const [isConfigurationDialogOpen, setIsConfigurationDialogOpen] = useState(false);
  const { isConnected, isLoading, installationPath } = useSlackIntegrationStatus();

  const currentHrefWithoutQueryParams = window.location.href.split('?')[0];

  const onConnect = useCallback(() => {
    const href = getSlackRedirectUri(installationPath, { httpRedirect: `${currentHrefWithoutQueryParams}?${POST_INSTALL_QUERY_PARAM}=${Vendor.SLACK}` });
    if (href) window.open(href, '_self');
  }, [installationPath, currentHrefWithoutQueryParams]);

  return (
    <div data-testid='slack-integration-card'>
      <SlackIntegrationDialog
        isOpen={isConfigurationDialogOpen}
        onClose={() => setIsConfigurationDialogOpen(false)}
        stage={{ type: SlackIntegrationDialogStage.TENANT }}
      />

      <DialogCard
        configureText={configureText}
        description={description}
        icon={SlackIcon}
        isConnected={isConnected}
        isLoading={isLoading}
        onConfigure={() => { setIsConfigurationDialogOpen(true); }}
        onConnect={onConnect}
        vendor={Vendor.SLACK}
        width='100%'
      />
    </div>
  );
};
