import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { startCase } from 'lodash';
import { FC } from 'react';

import styles from './PlanItemDetailsSupportedLanguagesBody.module.scss';

import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { IControlElement } from 'types/interfaces';

interface Props {
  controls: IControlElement[];
}

export const PlanItemDetailsSupportedLanguagesBody: FC<Props> = ({ controls }) => (
  <TableBody>
    {Object.values(controls).map((control) => {
      const { supportedLanguages, securityTool, links } = control;
      const supportedLanguagesTextElements = supportedLanguages.map((text) => <JitText key={text} color={colors.lightGray} size='s' text={text} />);

      // has link if security tool link or website link exists
      const link = links.security_tool || links.website;

      const securityToolElement = (
        <JitText
          color={link ? colors.iris : colors.lightGray}
          fallbackText={startCase(securityTool)}
          muted
          size='s'
          text={`securityTools.${securityTool.toLowerCase()}`}
          withUnderline={!!link}
        />
      );

      return (
        <TableRow
          key={securityTool}
          className={styles.row}
          sx={{ verticalAlign: 'top' }}
        >

          <TableCell>
            {link ? (
              <JitLink
                className={styles.link}
                href={link}
                target='_blank'
              >
                {securityToolElement}
              </JitLink>
            ) : securityToolElement}
          </TableCell>

          {supportedLanguages && (
          <TableCell scope='row'>
            {supportedLanguagesTextElements}

          </TableCell>
          )}
        </TableRow>
      );
    })}
  </TableBody>
);
