import { FC, useState } from 'react';

import styles from './MttrInfoIcon.module.scss';

import { GrayInfo } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitPopper } from 'components/JitPopper/JitPopper';
import { JitText } from 'components/JitText/JitText';

interface Props {
  popperDescription: string;
  shouldDisablePopper: boolean;
}

export const MttrInfoIcon: FC<Props> = ({ popperDescription, shouldDisablePopper }) => {
  const [isHoverInfoIcon, setIsHoverInfoIcon] = useState(false);
  return (
    <JitPopper
      content={(
        <div className={styles.content}>
          <JitText className={styles.topText} size='s' text={popperDescription} />
        </div>
      )}
      isWithArrow={false}
      open={isHoverInfoIcon && !shouldDisablePopper}
      placement='top'
      shadowVariant='dark'
    >
      <JitIcon
        className={styles.icon}
        color='transparent'
        data-testid='infoIcon'
        icon={GrayInfo}
        onMouseEnter={() => { setIsHoverInfoIcon(true); }}
        onMouseLeave={() => { setIsHoverInfoIcon(false); }}
        size={10}
      />
    </JitPopper>
  );
};
