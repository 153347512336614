import { FC } from 'react';

import styles from './TrialEndBanner.module.scss';

import { JittyWeird } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import { config } from 'config';
import colors from 'themes/colors.module.scss';

export const TrialEndBanner: FC = () => (
  <div className={styles.wrapper} data-testid='trial-end-warning-banner'>
    <div className={styles.gradiantPart} />

    <div className={styles.infoWrapper}>
      <JitIcon className={styles.jittyIcon} icon={JittyWeird} size='80px' />

      <JitText bold className={styles.attentionText} color={colors.failRed} text='banners.trialEnd.attentionText' />

      <JitText
        components={{
          contactLink: <JitLink
            color={colors.blue02}
            href={config.contactUsUrl}
            rel='noreferrer'
            target='_blank'
            title='contact us'
            underline='hover'
          />,
        }}
        text='banners.trialEnd.text'
      />

    </div>
  </div>
);

