import { AnalyticsBrowser } from '@segment/analytics-next';
import freeEmailDomains from 'free-email-domains';
import { capitalize } from 'lodash';
import { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useHotjar from 'react-use-hotjar';

import { getPagesNames } from './utils/pageUtils';
import { useIdentifyUser } from './utils/useIdentifyUser';

import { config } from 'config';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks';
import { useAuthContext } from 'context/AuthContext/AuthContext';

export interface AnalyticsContextProps {
  analytics: AnalyticsBrowser | undefined
}

const defaultAnalyticsContext = {
  analytics: undefined,
};
export const AnalyticsContext = createContext<AnalyticsContextProps>(defaultAnalyticsContext);

export const useAnalyticsContext = () => {
  const result = useContext(AnalyticsContext);
  if (!result) {
    throw new Error('Context used outside of its Provider!');
  }
  return result;
};

export const splitName = (name: string) => {
  const space = name.indexOf(' ');
  return {
    firstName: name.substring(0, space),
    lastName: name.substring(space + 1),
  };
};

export const getCompanyName = (email: string, name: string) => {
  const domain = email.split('@')[1];
  if (freeEmailDomains.includes(domain)) {
    return `${name}'s Company`;
  }

  return capitalize(domain.split('.')[0]);
};

// eslint-disable-next-line max-statements
export const AnalyticsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { frontEggUser } = useAuthContext();
  const { sendAnalyticsAuthEvent } = useSendAnalyticsEvent();
  const { initHotjar, identifyHotjar } = useHotjar();
  const { pathname } = useLocation();
  const { identifyUser } = useIdentifyUser();

  const hotJarSiteId = parseInt(config.hotJarSiteId, 10);
  const WRITE_KEY: string = config.segmentKey!;
  const ANALYTICS_CDN: string = config.analyticsCdn!;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadAnalytics = useCallback(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const { distinctID, ...params } = Object.fromEntries(urlSearchParams.entries());
    const userId = frontEggUser.id;
    const utmParams = Object.fromEntries(Object.entries(params).filter((([key]) => (key.indexOf('utm') === 0))));
    const analyticsBrowser = AnalyticsBrowser.load({
      cdnURL: ANALYTICS_CDN,
      writeKey: WRITE_KEY,
    });
    const uniqueIdentifier = distinctID || userId;
    const { firstName, lastName } = splitName(frontEggUser.name);
    const userDetails = {
      email: frontEggUser.email,
      tenantId: frontEggUser.tenantId,
      tenant_id: frontEggUser.tenantId,
      firstName,
      lastName,
      company: {
        name: getCompanyName(frontEggUser.email, frontEggUser.name),
      },
      name: frontEggUser.name,
    };
    if (uniqueIdentifier) {
      analyticsBrowser?.identify(uniqueIdentifier, {
        ...userDetails,
        ...utmParams,
      });
    }

    return analyticsBrowser;
  }, [frontEggUser, WRITE_KEY, ANALYTICS_CDN]);

  const analytics = useMemo(loadAnalytics, [loadAnalytics]);
  const value = useMemo(() => ({ analytics }), [analytics]);

  useEffect(() => {
    initHotjar(hotJarSiteId, 6, false);
  }, [hotJarSiteId, initHotjar]);

  useEffect(() => {
    identifyHotjar(frontEggUser?.email, {
      TenantID: frontEggUser.tenantId,
      FrontEggUserId: frontEggUser.id,
      Name: frontEggUser.name,
    });
  }, [frontEggUser, hotJarSiteId, identifyHotjar]);

  useEffect(() => {
    sendAnalyticsAuthEvent(frontEggUser);
  }, [frontEggUser, sendAnalyticsAuthEvent]);

  useEffect(() => {
    const pageName = getPagesNames(pathname);
    identifyUser(frontEggUser, pageName, pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frontEggUser]);

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};
