import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import { IIdentifyUserRequest } from 'types/interfaces';

export const useAnalyticsService = () => {
  const { client } = useClient();

  const identifyUser = (identifyData: IIdentifyUserRequest) => {
    const url = getApiUrls.analyticsService.identifyUser;
    return client.post({
      url,
      allowedStatuses: [200],
      requestConfig: {
        data: identifyData,
      },
    });
  };

  return {
    identifyUser,
  };
};
