import { FC, useMemo } from 'react';

import { IconTextBox } from 'components/IconTextBox/IconTextBox';
import { findingStatusToTextColor } from 'pages/FindingsPage/constants';
import { FindingStatus } from 'types/enums';
import { ITableCell } from 'types/interfaces';

export const StatusCell: FC<ITableCell> = ({ cell: { value: { status, isIgnored } } }) => {
  const calculatedStatus: FindingStatus = isIgnored ? FindingStatus.IGNORED : status;
  const textColor = findingStatusToTextColor[calculatedStatus];

  const calculatedStatusText = useMemo(() => calculatedStatus.toLowerCase(), [calculatedStatus]);

  return (
    <IconTextBox borderOpacity={0.25} color={textColor} text={`pages.findings.status.${calculatedStatusText}`} />
  );
};

