import { isEmpty } from 'lodash';

import { deleteInstallations } from '../../../services/AppLifecycleService';
import { useSlackContext } from '../SlackContext';

import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import { Vendor } from 'types/enums';
import { IDeleteInstallationsRequest } from 'types/interfaces/AppLifecycle';

export const useDisconnectSlackIntegration = () => {
  const { installations } = useTenantContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const { setIsBeingUninstalled } = useSlackContext();
  const handleDisconnect = async () => {
    if (isEmpty(installations)) {
      return;
    }
    const deleteInstallationRequest: IDeleteInstallationsRequest = {
      installations: installations.filter((installation) => installation.vendor === Vendor.SLACK),
    };
    setIsBeingUninstalled(true);
    await deleteInstallations(deleteInstallationRequest);
    sendAnalyticsEvent({ action: 'slack-integration-removed' });
    setIsBeingUninstalled(false);
  };

  return {
    handleDisconnect,
  };
};
