import { FC, ReactElement } from 'react';

import styles from './DataOverview.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  title: string;
  description?: string;
  content: ReactElement;
  titleTestID?: string;
  descriptionTestID?: string;
  actionItemText?: string;
  actionItemOnClick?: () => void;
}

export const DataOverview: FC<Props> = ({ title, description, content, titleTestID = 'title', descriptionTestID = 'description', actionItemText, actionItemOnClick }) => (
  <div className={styles.wrapper}>
    <JitText bold data-testid={titleTestID} size='l' text={title} />

    <div className={styles.content}>
      {content}

      {description && (
      <JitText
        color={colors.gray}
        data-testid={descriptionTestID}
        text={description}
      />
      )}
    </div>

    { actionItemText
    && (
    <div
      className={styles.actionItem}
      data-testid='action-item-calculate'
      onClick={actionItemOnClick || (() => {})}
      role='button'
      tabIndex={0}
    >
      <JitText className={styles.actionItemText} color={colors.iris} size='m' text={actionItemText} />
    </div>
    )}
  </div>
);
