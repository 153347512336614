import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';

export const StyledTableContainer = styled(TableContainer)(() => ({
  '.MuiTableCell-root': {
    borderBottom: 'none',
  },
  '.MuiTable-root': {
    maxWidth: '400px',
  },
}));
