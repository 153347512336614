import { IPlanDetails } from 'types/interfaces';

export const getScore = (plan: IPlanDetails) => {
  if (plan.activeItemSlugs.size > 0) {
    const activationPercentage = plan.activeItemSlugs.size / plan.allItemSlugs.size;
    const successPercentage = plan.passedItemSlugs.size / plan.activeItemSlugs.size;
    return activationPercentage * successPercentage;
  }
  return 0;
};
