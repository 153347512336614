import { FC, ReactElement } from 'react';

import styles from './Background.module.scss';

interface BackgroundProps {
  variant?: 'gradient' | 'static';
}

export const Background: FC<BackgroundProps> = ({ variant = 'gradient' }) => {
  const renderBackground = (): ReactElement => {
    if (variant === 'gradient') {
      return (
        <>
          <div className={styles.firstGradient} data-testid='first-background-gradient' />

          <div className={styles.secondGradient} data-testid='second-background-gradient' />
        </>
      );
    }
    return <div className={styles.static} data-testid='static-background' />;
  };

  return renderBackground();
};
