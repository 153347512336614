import DialogActions from '@mui/material/DialogActions';
import { FC } from 'react';

import { FindingsFilterSaveFindingFilterDialogActionButtons } from './components/SaveFindingFilterDialogActionButtons/SaveFindingFilterDialogActionButtons';
import styles from './SaveFindingFilterDialogActions.module.scss';

import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { useCanUseSlackNotifications } from 'context/FindingsContext/hooks/useCanUseSlackNotifications';
import { stopPropagation } from 'utils';

interface Props {
  handleClose: () => void;
  handleSave: () => void;
  filterShouldNotify: boolean;
  setFilterShouldNotify: (filterShouldNotify: boolean) => void;
  isDisabled: boolean;
}

export const FindingsFilterSaveFindingDialogActions: FC<Props> = ({
  handleClose,
  handleSave,
  filterShouldNotify,
  setFilterShouldNotify,
  isDisabled,
}) => {
  const canChooseNotifications = useCanUseSlackNotifications();
  const tooltipTitle = canChooseNotifications ? '' : 'dialogs.planItemsCatalog.filter.savedFilters.notificationCheckBoxDisabledTooltip';
  return (
    <DialogActions>
      <div className={styles.dialogActionsContainer}>
        <JitTooltip title={tooltipTitle}>
          <div className={styles.jitCheckBoxContainer}>
            <JitCheckbox
              checked={filterShouldNotify}
              disabled={!canChooseNotifications}
              inputProps={{
                // @ts-ignore
                'data-testid': 'save-finding-filter-dialog-actions-check-box',
              }}
              onChange={() => setFilterShouldNotify(!filterShouldNotify)}
              onClick={stopPropagation}
              style={{ marginRight: '0.5rem' }}
            />

            <JitText bold muted={!canChooseNotifications} text='dialogs.planItemsCatalog.filter.savedFilters.notificationCheckBox' />
          </div>
        </JitTooltip>

        <FindingsFilterSaveFindingFilterDialogActionButtons handleClose={handleClose} handleSave={handleSave} isDisabled={isDisabled} />
      </div>
    </DialogActions>
  );
};
