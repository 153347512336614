import { FC, ReactElement, useCallback, useMemo, useState } from 'react';

import { ArrowRight } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import styles from 'components/Navbar/components/SelectTenantPopper/SelectTenantPopper.module.scss';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import colors from 'themes/colors.module.scss';

interface Props {
  isLast: boolean;
  endAdornment?: ReactElement;
  text: string;
  shouldShowArrow?: boolean;
  onSelect: () => void;
  icon?: ReactElement;
  bgColor?: string;
  shouldShowName?: boolean;
  shouldHandleHover?: boolean;
}

export const Row: FC<Props> = ({
  isLast, text, shouldShowArrow = true, onSelect, icon, endAdornment, bgColor, shouldShowName = true, shouldHandleHover = true,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const backgroundColor = useMemo(() => (isHovered && shouldHandleHover ? colors.navbarHovered : bgColor || colors.navbarFooterHovered), [bgColor, isHovered, shouldHandleHover]);

  const handleClick = useCallback(() => {
    if (shouldHandleHover) {
      onSelect();
    }
  }, [onSelect, shouldHandleHover]);

  const className = useMemo(() => (shouldHandleHover ? styles.hoverAbleRow : styles.row), [shouldHandleHover]);

  return (
    <div
      className={className}
      data-testid='row'
      onClick={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      role='button'
      style={{
        backgroundColor,
        borderRadius: isLast ? '0 0 5px 5px' : undefined,
      }}
      tabIndex={0}
    >
      <div className={styles.rowIcon}>
        {icon}
      </div>

      { shouldShowName && (
      <div className={styles.rowTextsWrapper}>
        <TooltipOnlyOnOverflow tooltipText={text}>
          <JitText noWrap overflowHiddenEllipsis size='m' text={text} />
        </TooltipOnlyOnOverflow>

        {endAdornment}
      </div>
      )}

      {isLast && shouldShowArrow && (
      <div className={styles.arrow}>
        <JitIcon color={bgColor} icon={ArrowRight} rotateDegrees={180} size={50} />
      </div>
      )}
    </div>
  );
};
