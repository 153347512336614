import { Dispatch, FC, SetStateAction, useMemo } from 'react';

import { JitButton, JitButtonVariants } from 'components/JitButton/JitButton';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { config } from 'config';
import { usePlanInstanceContext } from 'context/PlanInstanceContext/PlanInstanceContext';
import { useActivatePlanItem } from 'context/PlansContext/hooks/useActivatePlanItem';
import { useIsUserEntitledToPlanItem } from 'context/StiggContext/useIsUserEntitledToPlanItem';
import { isIntroItemSlug, ItemSlugOptions } from 'pages/PlanPage/components/PlanItemIntroDialog';
import { UseGetPlanItemDisabledTooltipText } from 'pages/PlanPage/hooks/UseGetPlanItemDisabledTooltipText';
import { IMouseEvent, IPlanItemDetails } from 'types/interfaces';
import { stopPropagation } from 'utils';

interface ActivatePlanItemButtonProps {
  planSlug: string;
  planItem: IPlanItemDetails;
  variant: JitButtonVariants;
  setIntroItem: Dispatch<SetStateAction<ItemSlugOptions | null>>
  longText?: boolean;
}

export const ActivatePlanItemButton: FC<ActivatePlanItemButtonProps> = ({
  planSlug, planItem, variant, setIntroItem, longText = false,
}) => {
  const { activatePlanItem } = useActivatePlanItem();
  const { planItemSlugsInCommit } = usePlanInstanceContext();
  const { disabledTooltipText } = UseGetPlanItemDisabledTooltipText(planItem);

  const isUserEntitled = useIsUserEntitledToPlanItem(planItem.slug);

  const buttonText = useMemo(() => {
    if (planItem.is_enabled === false) return 'pages.plan.planItem.config.comingSoon';
    if (!isUserEntitled) return 'pages.sbom.table.paywallGradiant.contactUs';
    return longText ? 'pages.plan.planItem.activateLong' : 'pages.plan.planItem.activate';
  }, [longText, planItem.is_enabled, isUserEntitled]);

  const onButtonClick = (e: IMouseEvent) => {
    if (!isUserEntitled) {
      window.open(config.contactUsUrl, '_blank');
    } else {
      activatePlanItem(planSlug, planItem);
      if (isIntroItemSlug(planItem.slug)) {
        setIntroItem(planItem.slug);
      }
    }
    stopPropagation(e);
  };

  const isLoading = planItemSlugsInCommit.filter((slug) => slug === planItem.slug).length > 0;
  return (
    <JitTooltip data-testid='setupButtonTooltip' placement='top' title={disabledTooltipText}>
      <JitButton
        data-testid='setupButton'
        disabled={!!disabledTooltipText}
        isLoading={isLoading}
        onClick={onButtonClick}
        variant={variant}
        width='fit-content'
      >
        <JitText color='inherit' data-testid='setupButtonText' noWrap text={buttonText} />
      </JitButton>
    </JitTooltip>
  );
};
