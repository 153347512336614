/* eslint-disable react/jsx-max-depth */
import Avatar from '@mui/material/Avatar';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FindingsTexts } from './components/FindingsTexts/FindingsTexts';

import { Stale, TimeAgoClock } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { constants } from 'globalConstants';
import styles from 'pages/InsightsPage/components/InsightListCardItem/InsightListCardItem.module.scss';
import colors from 'themes/colors.module.scss';
import { FindingStatus } from 'types/enums';
import { ISvg } from 'types/interfaces';
import { IInsightCategoryItem } from 'types/interfaces/Insights';
import { formatDuration } from 'utils';
import { getLocalDate } from 'utils/functions/getLocalDate';

interface InsightListCardItemProps {
  'data-testid'?: string;
  item: IInsightCategoryItem;
  shouldDisplayStaleIndication?: boolean;
  analyticsLinkType: string;
  boldFindingStatus: FindingStatus;
  icon: FC<ISvg>,
}

export const InsightListCardItem: FC<InsightListCardItemProps> = ({
  item, shouldDisplayStaleIndication, analyticsLinkType, boldFindingStatus, icon, ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const navigate = useNavigate();

  const handleLinkClick = () => {
    const action = `clicked-${analyticsLinkType}-link`;
    const params = {
      repo: `${item.repoOrg}/${item.repoName}`,
    };
    sendAnalyticsEvent({
      action,
      params,
    });
  };

  const { localDate } = getLocalDate(item.timestamp);
  let timeSinceOpen = new Date().getTime() - localDate!.getTime();
  const { MINUTE, HOUR, DAY } = constants.time;
  /*
  * Here we're keeping only the most significant time unit
  * For example, '8h 30m 3s' is converted to '8h'
  * */
  [MINUTE, HOUR, DAY].forEach((timeUnit) => {
    if (timeSinceOpen > timeUnit) {
      timeSinceOpen -= timeSinceOpen % timeUnit;
    }
  });

  const isThreeDaysPassed = () => {
    const itemDate = new Date(item.timestamp);
    const currentDate = new Date();
    return +currentDate > itemDate.setDate(itemDate.getDate() + 3);
  };

  const handleShowDetailsClick = () => navigate(item.showDetailsUrl!);

  const formattedTimeSinceOpened = `${formatDuration(timeSinceOpen)} ago`;
  return (
    <div
      className={styles.wrapper}
      data-testid={props['data-testid'] || 'item'}
      data-title={item.title}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={styles.leftSideWrapper}>
        <a
          className={`${styles.linkWrapper} ${styles.title}`}
          data-testid='itemUrl'
          href={item.url}
          onClick={() => handleLinkClick()}
          rel='noreferrer'
          target='_blank'
        >
          <JitIcon icon={icon} size={18} />

          <JitText addUnderlineOnHover bold text={item.title} />
        </a>

        <div className={styles.detailsWrapper}>
          {item.findings
            && <FindingsTexts boldFindingStatus={boldFindingStatus} findings={item.findings} />}

          {isHovered && item.showDetailsUrl && <JitText bold color={colors.iris} onClick={handleShowDetailsClick} text='pages.insights.showDetails' />}
        </div>
      </div>

      <div className={styles.rightSideWrapper}>
        {shouldDisplayStaleIndication && isThreeDaysPassed() && <JitIcon centered={false} icon={Stale} width={75} />}

        <div className={styles.timeAgoWrapper}>
          <JitIcon centered={false} className={styles.timeAgoClock} icon={TimeAgoClock} size={15} />

          <JitText color={colors.lightGray} data-testid='timeAgo' text={formattedTimeSinceOpened} />
        </div>

        <div className={styles.ownerWrapper}>
          <Avatar
            src={item.ownerAvatar}
            style={{ marginTop: 4 }}
            sx={{
              width: 15,
              height: 15,
            }}
          />

          <TooltipOnlyOnOverflow color={colors.lightGray} data-testid='itemOwner' text={item.owner} />
        </div>
      </div>
    </div>
  );
};

