import { useErrorToastProps, useInfoToastProps, useSuccessToastProps, useSystemErrorToastProps } from './toasts';
import { useSystemMessageToastProps } from './toasts/useSystemMessageToastProps';

import { ToastProps } from 'components/Toast/Toast';
import { ToastType } from 'types/enums';

interface IToastsMapping {
  [key: string]: {
    props: ToastProps,
    shouldBeRenderedTop: boolean,
  };
}

export const useToastsMapping = (): IToastsMapping => {
  const infoToastProps = useInfoToastProps();
  const successToastProps = useSuccessToastProps();
  const errorToastProps = useErrorToastProps();
  const systemErrorToastProps = useSystemErrorToastProps();
  const systemMessageToastProps = useSystemMessageToastProps();

  return {
    [ToastType.Info]: {
      props: infoToastProps,
      shouldBeRenderedTop: false,
    },
    [ToastType.Success]: {
      props: successToastProps,
      shouldBeRenderedTop: false,
    },
    [ToastType.Error]: {
      props: errorToastProps,
      shouldBeRenderedTop: false,
    },
    [ToastType.SystemError]: {
      props: systemErrorToastProps,
      shouldBeRenderedTop: true,
    },
    [ToastType.SystemMessage]: {
      props: systemMessageToastProps,
      shouldBeRenderedTop: true,
    },
  };
};
