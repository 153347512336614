
export interface SlackRedirectParams {
  httpRedirect?: string;
  [key: string]: string | undefined;
}

export const getSlackRedirectUri = (installationPath?: string, params?: SlackRedirectParams) => {
  if (!installationPath || !params) return installationPath;
  const queryParamsBase64 = btoa(JSON.stringify(params));
  const existingUrlParams = new URLSearchParams(installationPath);
  const existingParsedParams = Object.fromEntries(existingUrlParams.entries());
  const redirectUrl = existingParsedParams.redirect_url.replaceAll('oauth/oauth', 'oauth');
  return `${installationPath}&redirect_uri=${redirectUrl}?b64params=${queryParamsBase64}`;
};
