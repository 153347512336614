import { useCallback, useEffect, useState } from 'react';

import { useTenantService } from 'services/TenantService/useTenantService';
import { ITenantUser } from 'types/interfaces/IUserVendor';

export const useGetTenantUsers = () => {
  const [tenantUsers, setTenantUsers] = useState<ITenantUser[] | undefined>();
  const { getTenantUsers } = useTenantService();

  const initTenantUsers = useCallback(async () => {
    const users = await getTenantUsers();
    setTenantUsers(users?.data);
  }, [getTenantUsers]);

  useEffect(() => {
    initTenantUsers();
  }, [initTenantUsers]);

  return {
    tenantUsers,
  };
};
