import { useCallback, useEffect } from 'react';

import { useSlackContext } from 'context/SlackContext/SlackContext';
import { useSlackService } from 'services/SlackService/useSlackService';
import { sortAlphabetic } from 'utils/functions/sortAlphabetic';

export const useGetSlackUsers = () => {
  const { slackUsers, setSlackUsers } = useSlackContext();
  const { getSlackUsers } = useSlackService();

  const initSlackUsers = useCallback(async () => {
    const slackUsersResponse = await getSlackUsers();
    const users = slackUsersResponse?.data.filter((user) => !!user?.real_name || user.name);
    const sortedUsers = users?.sort((a, b) => sortAlphabetic(a?.real_name || a.name, b?.real_name || b.name));
    setSlackUsers(sortedUsers);
  }, [setSlackUsers]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!slackUsers?.length) {
      initSlackUsers();
    }
  }, [initSlackUsers, slackUsers?.length]);

  return {
    slackUsers,
  };
};
