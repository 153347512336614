import { useStiggContext } from '@stigg/react-sdk';
import { useCallback } from 'react';

export const useGetBooleanEntitlement = () => {
  const { stigg, isInitialized } = useStiggContext();

  return useCallback((featureId: string, defaultValue: boolean = false) => {
    if (!isInitialized || !stigg.isCustomerLoaded) {
      return defaultValue;
    }
    const entitlement = stigg.getBooleanEntitlement({
      featureId,
      options: {
        fallback: {
          hasAccess: defaultValue,
        },
      },
    });
    return entitlement.hasAccess;
  }, [isInitialized, stigg]);
};
