import { useMemo } from 'react';

import { config } from 'config';
import { usePlanInstanceContext } from 'context/PlanInstanceContext/PlanInstanceContext';
import { useIsUserEntitledToPlanItem } from 'context/StiggContext/useIsUserEntitledToPlanItem';
import { UseGetPlanItemDisabledTooltipText } from 'pages/PlanPage/hooks/UseGetPlanItemDisabledTooltipText';
import { ActionButton, ActionButtonVariant, IMouseEvent, IPlanItemDetails } from 'types/interfaces';

export const UseGetRelevantActivationButton = (
  planItem: IPlanItemDetails,
  openDeactivateConfirmDialog: () => void,
  onActivate: (e: IMouseEvent) => void,
  isPlanItemActive: boolean,
  onConfigure: () => void,
  isItemConfigurable: boolean,
  onRedirect: () => void,
  isItemRedirecting: boolean,
) => {
  const { planItemSlugsInCommit } = usePlanInstanceContext();
  const { disabledTooltipText } = UseGetPlanItemDisabledTooltipText(planItem);

  const isLoading = planItemSlugsInCommit.filter((slug) => slug === planItem.slug).length > 0;

  const isUserEntitled = useIsUserEntitledToPlanItem(planItem.slug);

  const handleActivate = useMemo(() => (!isUserEntitled
    ? () => window.open(config.contactUsUrl, '_blank')
    : onActivate), [isUserEntitled, onActivate]);

  const activateButtonText = useMemo(() => {
    if (planItem.is_enabled === false) return 'pages.plan.planItem.config.comingSoon';
    if (!isUserEntitled) return 'pages.sbom.table.paywallGradiant.contactUs';
    return 'pages.plan.itemDetails.info.activateButton';
  }, [planItem.is_enabled, isUserEntitled]);

  const deactivateButton: ActionButton = useMemo(() => ({
    label: 'pages.plan.itemDetails.info.deactivateButton',
    handleClick: openDeactivateConfirmDialog,
    isLoading,
    variant: ActionButtonVariant.DANGER,
  }), [isLoading, openDeactivateConfirmDialog]);

  const activateButton: ActionButton = useMemo(() => ({
    label: activateButtonText,
    handleClick: handleActivate,
    isLoading,
    tooltip: disabledTooltipText,
    disabled: !!disabledTooltipText,
  }), [disabledTooltipText, activateButtonText, isLoading, handleActivate]);

  const isConfiguredDisabled = planItem.is_enabled === false || planItemSlugsInCommit.some((slug) => slug === planItem.slug);
  const activateText = useMemo(() => {
    if (planItem.is_enabled === false) return 'pages.plan.planItem.config.comingSoon';
    if (!isUserEntitled) return 'pages.sbom.table.paywallGradiant.contactUs';
    return 'pages.plan.planItem.activate';
  }, [planItem.is_enabled, isUserEntitled]);

  const configureText = 'pages.plan.planItem.config.configureItemButton';

  const configureButton: ActionButton = useMemo(() => ({
    label: isPlanItemActive ? configureText : activateText,
    handleClick: onConfigure,
    isLoading,
    variant: isPlanItemActive ? ActionButtonVariant.OUTLINED : ActionButtonVariant.PRIMARY,
    tooltip: disabledTooltipText,
    disabled: isConfiguredDisabled || !!disabledTooltipText,
  }), [activateText, disabledTooltipText, isConfiguredDisabled, isLoading, isPlanItemActive, onConfigure]);

  const viewReportText = 'pages.plan.planItem.viewReport.viewReportButton';

  const viewReportButton: ActionButton = useMemo(() => ({
    label: viewReportText,
    handleClick: onRedirect,
    variant: isPlanItemActive ? ActionButtonVariant.OUTLINED : ActionButtonVariant.PRIMARY,
  }), [isPlanItemActive, onRedirect]);

  const actionButtons = useMemo(() => {
    if (isItemConfigurable) return isPlanItemActive ? [configureButton, deactivateButton] : [configureButton];
    if (isItemRedirecting) return isPlanItemActive ? [viewReportButton, deactivateButton] : [activateButton];
    if (isPlanItemActive) return [deactivateButton];
    return [activateButton];
  }, [activateButton, configureButton, deactivateButton, viewReportButton, isItemConfigurable, isPlanItemActive, isItemRedirecting]);

  return { actionButtons };
};
