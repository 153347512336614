import { createContext, useContext } from 'react';

import { IStiggFeatures } from 'types/interfaces/Entitlements/IStiggFeatures';

interface IStiggContext {
  showStiggPayWall: boolean;
  setShowStiggPayWall: (show: boolean) => void;
  stiggFeatures: IStiggFeatures[];
}

const defaultStiggContext = {
  showStiggPayWall: false,
  setShowStiggPayWall: () => {},
  stiggFeatures: [],
};

export const StiggExtendedContext = createContext<IStiggContext>(defaultStiggContext);

export const useStiggExtendedContext = () => useContext(StiggExtendedContext);
