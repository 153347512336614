import { FC } from 'react';

import {
  Applications,
  AwsIcon,
  GcpIcon,
  AzureIcon,
  ChevronClosed,
  ChevronOpened,
  Code,
  Commit,
  Configuration,
  ControlMatrix,
  Dashboard,
  Data,
  Dot,
  Find,
  GithubIcon,
  Info,
  Infrastructure,
  Operations,
  Pipeline,
  Runtime,
  Search,
  ThirdPartyApp,
  Trash,
  WebIcon,
  APIIcon,
  JitFavicon,
  OwaspIcon,
  GitlabIcon,
} from 'assets';
import colors from 'themes/colors.module.scss';
import { ISvg } from 'types/interfaces';

interface IIconsMap {
  [id: string]: {
    icon: FC<ISvg>
    color?: string;
  }
}

export const iconsMap: IIconsMap = {
  code: {
    icon: Code,
    color: colors.statusGreen,
  },
  data: {
    icon: Data,
    color: colors.statusGreen,
  },
  runtime: {
    icon: Runtime,
    color: colors.statusGreen,
  },
  thirdPartyApp: {
    icon: ThirdPartyApp,
    color: colors.statusGreen,
  },
  infrastructure: {
    icon: Infrastructure,
    color: colors.yellow,
  },
  info: {
    icon: Info,
    color: colors.darkGray,
  },
  application: {
    icon: Applications,
    color: colors.lightBlue2,
  },
  dashboard: {
    icon: Dashboard,
    color: colors.blue02,
  },
  chevronClosed: {
    icon: ChevronClosed,
    color: colors.blue02,
  },
  aws: {
    icon: AwsIcon,
    color: colors.blue02,
  },
  gcp: {
    icon: GcpIcon,
    color: colors.blue02,
  },
  azure: {
    icon: AzureIcon,
    color: colors.blue02,
  },
  github: {
    icon: GithubIcon,
    color: colors.lightGray,
  },
  gitlab: {
    icon: GitlabIcon,
    color: colors.blue02,
  },
  chevronOpen: {
    icon: ChevronOpened,
    color: colors.blue02,
  },
  commit: {
    icon: Commit,
    color: colors.blue02,
  },
  dot: {
    icon: Dot,
    color: colors.blue02,
  },
  find: {
    icon: Find,
    color: colors.yellow,
  },
  operations: {
    icon: Operations,
    color: colors.successGreen,
  },
  configuration: {
    icon: Configuration,
    color: colors.error,
  },
  pipeline: {
    icon: Pipeline,
    color: colors.statusRed,
  },
  search: {
    icon: Search,
    color: colors.lightGray,
  },
  'control-matrix': {
    icon: ControlMatrix,
    color: colors.lightGray,
  },
  removeItem: {
    icon: Trash,
    color: colors.statusRed,
  },
  api: {
    icon: APIIcon,
    color: colors.blue02,
  },
  web: {
    icon: WebIcon,
    color: colors.blue02,
  },
  jit: {
    icon: JitFavicon,
    color: colors.blue02,
  },
  owasp: {
    icon: OwaspIcon,
  },
};
