import { useState } from 'react';

import { ConfigurationDetailsStep } from 'components/Configurations/interfaces/configurations';
import { IConfigurations } from 'types/interfaces';

export const useValidation = (currentStepConfiguration: ConfigurationDetailsStep, configurations: IConfigurations, fetchedConfigurations?: IConfigurations) => {
  const [validationError, setValidationError] = useState<string | null>(null);
  const [isFailedValidation, setIsFailedValidation] = useState<boolean>(false);
  const [isValidationLoading, setIsValidationLoading] = useState<boolean>(false);

  const validate = async () => {
    if (!currentStepConfiguration.validation) {
      return true;
    }
    setValidationError(null);
    setIsValidationLoading(true);

    const { isValid, reason } = await currentStepConfiguration.validation(configurations || {}, fetchedConfigurations || {});
    setIsValidationLoading(false);
    setIsFailedValidation(!isValid);
    if (isValid) {
      return true;
    }
    setValidationError(reason);
    return false;
  };

  return {
    validationError,
    isFailedValidation,
    setIsFailedValidation,
    isValidationLoading,
    validate,
  };
};
