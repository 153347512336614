import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { StepGithubCard } from './components/StepGithubCard';
import { AnalyticsEvents } from './constants';
import styles from './CreateCentralizedRepo.module.scss';

import { GithubIcon } from 'assets';
import { DialogContentWrapper } from 'components/JitDialogs/DialogContentWrapper/DialogContentWrapper';
import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { JitText } from 'components/JitText/JitText';
import { config } from 'config';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { useTenantContext } from 'context/TenantContext';
import { useSetCentralizedRepo } from 'context/TenantContext/tenantHooks/useSetCentralizedRepo';
import { useValidateCentralizedRepo } from 'context/TenantContext/tenantHooks/useValidateCentralizedRepo';
import { CentralizedAssetValidationStatus } from 'types/enums/CentralizedAssetValidationStatus';
import { IAsset } from 'types/interfaces';

interface Props {
  initializedSelectedAsset?: IAsset;
  closeWizard: () => void;
}

export const CreateCentralizedRepo: FC<Props> = ({ initializedSelectedAsset, closeWizard }) => {
  const [selectedAsset, setSelectedAsset] = useState<IAsset | undefined>(initializedSelectedAsset);
  const [isLoading, setIsLoading] = useState(false);

  const { initialOnboardingCompleted, SCMIntegrationCompleted } = useTenantContext();
  const { handleSetCentralizedRepo, centralizedRepoResponseStatus } = useSetCentralizedRepo();
  const { uiShowSecurityChampionQuickGuide } = useFlags();
  const {
    handleValidateCentralizedRepo, validateCentralizedRepoValidationStatus, setValidateCentralizedRepoValidationStatus,
  } = useValidateCentralizedRepo();

  const createCentralizedRepoCompleted = useMemo(() => {
    if (!uiShowSecurityChampionQuickGuide) {
      return centralizedRepoResponseStatus === CentralizedAssetValidationStatus.VALID;
    }
    return SCMIntegrationCompleted && initialOnboardingCompleted && centralizedRepoResponseStatus === CentralizedAssetValidationStatus.VALID;
  }, [centralizedRepoResponseStatus, initialOnboardingCompleted, SCMIntegrationCompleted, uiShowSecurityChampionQuickGuide]);

  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const onContinueButtonClick = useCallback(async () => {
    if (selectedAsset) {
      setIsLoading(true);
      sendAnalyticsEvent({ action: AnalyticsEvents.CentralizedRepoSetRepoClicked });
      const status = await handleSetCentralizedRepo(selectedAsset);
      if (status === CentralizedAssetValidationStatus.VALID) {
        if (!uiShowSecurityChampionQuickGuide) {
          // This will be removed once the FF is open to all, and setCentralizedRepoSetCompleted will always be called.
          closeWizard();
        }
      }
    }
  }, [selectedAsset, sendAnalyticsEvent, handleSetCentralizedRepo, uiShowSecurityChampionQuickGuide, closeWizard]);

  useEffect(() => {
    if (createCentralizedRepoCompleted) {
      setIsLoading(false);
      closeWizard();
    }
  }, [closeWizard, createCentralizedRepoCompleted]);

  const validateSelectedAsset = useCallback(async (asset: IAsset) => {
    const status = await handleValidateCentralizedRepo(asset);
    if (status === CentralizedAssetValidationStatus.INVALID) {
      sendAnalyticsEvent({ action: AnalyticsEvents.CentralizedRepoValidationFailed });
    }
  }, [handleValidateCentralizedRepo, sendAnalyticsEvent]);

  const isPendingSetCentralizedRepo = centralizedRepoResponseStatus === CentralizedAssetValidationStatus.PENDING;
  const continueButtonLabel = 'GithubIntegrationWizard.createCentralizedRepo.next';

  return (
    <DialogContentWrapper
      actionButtons={[
        {
          label: continueButtonLabel,
          handleClick: onContinueButtonClick,
          disabled: !(validateCentralizedRepoValidationStatus === CentralizedAssetValidationStatus.VALID) || isPendingSetCentralizedRepo || isLoading,
          isLoading,
        },
      ]}
      icon={GithubIcon}
      leftButtonsElement={(
        <JitExternalLink href={config.docsGithubInstallation} text='GithubIntegrationWizard.createCentralizedRepo.learnMore' />
      )}
    >
      <div className={styles.content}>
        <JitText bold size='l' text='GithubIntegrationWizard.createCentralizedRepo.title' />

        <StepGithubCard
          selectedAsset={selectedAsset}
          setSelectedAsset={setSelectedAsset}
          setValidationStatus={setValidateCentralizedRepoValidationStatus}
          validateSelectedAsset={validateSelectedAsset}
          validationStatus={validateCentralizedRepoValidationStatus}
        />
      </div>
    </DialogContentWrapper>
  );
};
