import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { LayersCoverage } from '../../../LayersCoverage/LayersCoverage';
import { ResourcesCoverage } from '../../../ResourcesCoverage/ResourcesCoverage';
import { CoverageExpandedCard } from '../CoverageExpandedCard/CoverageExpandedCard';

import styles from './CoverageExpandedContent.module.scss';

import { useDialogsContext } from 'context/DialogsContext/DialogsContext';
import { constants } from 'globalConstants';
import { Dialogs } from 'types/enums';
import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { IPlanStatus } from 'types/interfaces/Metrics/SecurityImpactMetrics/LayersCoverage';
import { IResourcesCoverage } from 'types/interfaces/Metrics/SecurityImpactMetrics/ResourcesCoverage';

interface Props {
  resourcesCoverageMetric: MetricWithLoadingIndication<IResourcesCoverage>
  planStatusMetric: MetricWithLoadingIndication<IPlanStatus>
}

export const CoverageExpandedContent: FC<Props> = ({ resourcesCoverageMetric, planStatusMetric }) => {
  const { showDialog } = useDialogsContext();
  const navigate = useNavigate();
  const { PLAN } = constants.routes;
  return (
    <div className={styles.wrapper} data-testid='CoverageExpandedContent'>
      <CoverageExpandedCard
        actionItemOnClick={() => navigate(`/${PLAN}`)}
        actionItemText='pages.securityImpact.layers.actionText'
        actionItemTitle='pages.securityImpact.layers.actionTitle'
        content={<LayersCoverage planStatusMetric={planStatusMetric} shouldShowLayersIfNoCoverage={false} />}
        hasCoverage={!!planStatusMetric.metric?.activeItemsPercentage && planStatusMetric.metric.activeItemsPercentage > 0}
        title='pages.securityImpact.layers.title'
      />

      <CoverageExpandedCard
        actionItemOnClick={() => showDialog({ type: Dialogs.AssetsManagement })}
        actionItemText='pages.securityImpact.resources.actionText'
        actionItemTitle='pages.securityImpact.resources.actionTitle'
        content={<ResourcesCoverage resourcesCoverageMetric={resourcesCoverageMetric} />}
        hasCoverage={!!resourcesCoverageMetric.metric?.coveragePercentage && resourcesCoverageMetric.metric.coveragePercentage > 0}
        title='pages.securityImpact.resources.title'
      />
    </div>
  );
};
