/* eslint-disable no-bitwise */

/**
 * Calculate gradient color between two colors
 * @param startColor - start color in hex format (#000000)
 * @param endColor - end color in hex format (#000000)
 * @param position - position between 0 and 1
 */
export const calculateGradientColor = (startColor: string, endColor: string, position: number) => {
  const color1 = parseInt(startColor.substring(1), 16);
  const color2 = parseInt(endColor.substring(1), 16);

  const r1 = (color1 >> 16) & 255;
  const g1 = (color1 >> 8) & 255;
  const b1 = color1 & 255;

  const r2 = (color2 >> 16) & 255;
  const g2 = (color2 >> 8) & 255;
  const b2 = color2 & 255;

  const r = Math.round((r2 - r1) * position + r1);
  const g = Math.round((g2 - g1) * position + g1);
  const b = Math.round((b2 - b1) * position + b1);

  return `rgb(${r}, ${g}, ${b})`;
};
