import { FC, useState } from 'react';

import styles from './IconWithDialog.module.scss';

import { Trash } from 'assets/index';
import { JitButton } from 'components/JitButton/JitButton';
import { ConfirmDialog } from 'components/JitDialogs/ConfirmDialog/ConfirmDialog';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { ActionButtonVariant, IMouseEvent } from 'types/interfaces';
import { stopPropagation } from 'utils';

interface Props {
  title: string;
  message: string;
  subMessage: string;
  onConfirm: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  action: keyof typeof ACTIONS_MAP;
  tooltip: string;
}

export enum IconWithDialogAction {
  DELETE = 'delete',
}

const ACTIONS_MAP = {
  [IconWithDialogAction.DELETE]: {
    icon: Trash,
    confirmButton: {
      label: 'dialogs.iconWithDialog.remove',
      variant: ActionButtonVariant.DANGER,
    },
  },
};

export const IconWithDialog: FC<Props> = ({ title, message, subMessage, onConfirm, action, tooltip }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onOpen = (e: IMouseEvent) => {
    stopPropagation(e);
    setIsOpen(true);
  };

  const onClose = (e: IMouseEvent) => {
    stopPropagation(e);
    setIsOpen(false);
  };

  const handleConfirm = async (event: React.MouseEvent<HTMLButtonElement>) => {
    stopPropagation(event);
    setIsLoading(true);
    await onConfirm(event);
    setIsLoading(false);
    setIsOpen(false);
  };

  const { confirmButton, icon } = ACTIONS_MAP[action];

  return (
    <>
      <div className={styles.wrapper}>
        <JitButton data-testid='deleteButton' noHover onClick={onOpen}>
          <JitTooltip followCursor={false} placement='top' title={tooltip}>
            <JitIcon icon={icon} size={15} />
          </JitTooltip>
        </JitButton>
      </div>

      <ConfirmDialog
        actionButtons={[
          {
            label: 'dialogs.iconWithDialog.cancel',
            variant: ActionButtonVariant.SECONDARY,
            handleClick: onClose,
          },
          {
            label: confirmButton.label,
            variant: confirmButton.variant,
            handleClick: handleConfirm,
            isLoading,
            disabled: isLoading,
          },
        ]}
        isOpen={isOpen}
        message={message}
        onClose={onClose}
        subMessage={subMessage}
        title={title}
      />
    </>
  );
};
