import { FC, useMemo } from 'react';

import { PriorityBox } from 'pages/FindingsPage/components/PriorityBox/PriorityBox';
import { BaseCard } from 'pages/Resources/ResourcesPage/component/BaseCard/BaseCard';
import { IAggregatedAssetStatistics } from 'types/interfaces';

interface Props {
  resourcesStatistics: IAggregatedAssetStatistics | undefined;
}

export const ScoreCard: FC<Props> = ({ resourcesStatistics }) => {
  // When calling the API without agg key we get only one item in result.
  const score = useMemo(() => resourcesStatistics?.averagePriorityScore, [resourcesStatistics]);
  return (
    <BaseCard title='pages.risks.resources.resourcesPage.cards.score.description'>
      {score !== undefined ? (<PriorityBox priorityScore={score} size='s' withBorder={false} />) : null}
    </BaseCard>
  );
};
