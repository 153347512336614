import { groupBy, indexOf, sortBy, toPairs } from 'lodash';
import { useMemo } from 'react';

import { categoryListOrder } from './categoryListOrder';

import { IPlanDetails, IPlanItemDetails } from 'types/interfaces';

type IPlan = Pick<IPlanDetails, 'layers'>;

export const usePlanItemsGroupedByCategory = (planItems: IPlanItemDetails[], plan?: IPlan) => {
  const layerOrder = useMemo(() => {
    if (!plan?.layers || plan.layers.length === 0) return categoryListOrder;
    return plan.layers.map((layer) => layer.key);
  }, [plan]);

  const planItemTemplatesGroupedByCategory = useMemo(() => {
    const planItemsByLayer = Object.fromEntries(
      Object.entries(groupBy(planItems, 'layer')).map(([layer, layerItems]) => [layer, sortBy(layerItems, 'item_id')]),
    );
    if (!planItemsByLayer) return [];
    const planItemsByLayerPairs = toPairs(planItemsByLayer);
    return sortBy(planItemsByLayerPairs, (planItemsByLayerPair) => {
      const layer = planItemsByLayerPair?.[0];
      const index = indexOf(layerOrder, layer);
      return index === -1 ? layerOrder.length : index;
    });
  }, [layerOrder, planItems]);

  return { planItemTemplatesGroupedByCategory };
};
