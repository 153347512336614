import { startCase } from 'lodash';

import { i18n } from 'locale/i18n';

export const useGetFormatControlName = () => {
  const getFormattedControlName = (controlName: string): string => {
    const baseControlName = controlName.replace('control-', '');
    const formattedControlName = i18n.t([`securityTools.${controlName.toLowerCase()}`, startCase(baseControlName)]);
    return formattedControlName;
  };

  return { getFormattedControlName };
};
