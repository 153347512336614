import { FC, useMemo, useState } from 'react';

import styles from './ResourceTags.module.scss';
import { calcTagColor, TAG_NAMES_TO_HIDE } from './utils';

import { JitTag } from 'components/JitTag/JitTag';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { AddTag } from 'pages/Resources/ResourcePage/components/ResourceDetails/components/ResourceTags/components/AddTag/AddTag';
import colors from 'themes/colors.module.scss';
import { IAsset } from 'types/interfaces';
import { Tag } from 'types/interfaces/Tag';
import { useCalculateMaxTagsInContainer } from 'utils/hooks/useCalculateMaxTagsInContainer';

interface Props {
  maxWidth?: number | string;
  asset?: IAsset;
}

export const ResourceTags: FC<Props> = ({
  maxWidth = '100%',
  asset,
}) => {
  const [tagToEdit, setTagToEdit] = useState<Tag>();
  const tags = asset?.tags || [];
  const filteredTags = tags.filter(
    (tag) => !TAG_NAMES_TO_HIDE.includes(tag.name),
  );

  const tagStrings = useMemo(() => filteredTags.map((tag) => `${tag.name}: ${tag.value}`), [filteredTags]);

  const {
    containerRef,
    actualWidth,
    tagsToShow,
    hiddenTags,
  } = useCalculateMaxTagsInContainer(tagStrings, maxWidth);

  const TooltipContent = useMemo(() => (
    <div>
      {hiddenTags.map((tag) => (
        <JitText key={tag} size='s' text={tag} />
      ))}
    </div>
  ), [hiddenTags]);

  const onClickEditTag = (tagString: string) => {
    const [name, value] = tagString.split(': ');
    setTagToEdit({
      name,
      value,
    });
  };
  const onEditTagComplete = () => {
    setTagToEdit(undefined);
  };
  return (
    <div
      ref={containerRef}
      className={styles.wrapper}
      data-testid='resourceTags'
      style={{
        maxWidth,
        width: actualWidth,
      }}
    >
      {tagsToShow.map((tag) => (
        <JitTooltip key={tag} followCursor={false} placement='top' title='pages.risks.resources.resourcePage.resourceDetails.editTag'>
          <JitTag
            color={calcTagColor(tag)}
            dataTestId='ResourceTag'
            onClick={() => onClickEditTag(tag)}
            text={tag}
          />
        </JitTooltip>
      ))}

      {hiddenTags.length > 0 && (
        <JitTooltip
          followCursor={false}
          placement='top'
          title={TooltipContent}
        >
          <JitTag
            key='more'
            color={colors.iris}
            text={`+${hiddenTags.length}`}
            withBorder
          />
        </JitTooltip>
      )}

      <AddTag asset={asset} onEditTagComplete={onEditTagComplete} tagToEdit={tagToEdit} />
    </div>
  );
};
