import { FC } from 'react';

import styles from './DaysCountBadgeCard.module.scss';

import { JitText } from 'components/JitText/JitText';
import { RankBadgeWithPopover } from 'pages/InsightsPage/SubPages/PerformancePage/components/GraphHeaderComponents/RankBadgeWithPopover/RankBadgeWithPopover';
import { IPerformanceRateOption, PerformanceRateRank } from 'types/interfaces/Metrics/PerformanceRateOption';

interface Props {
  mttrAvg: string | null;
  rank: PerformanceRateRank | null;
  performanceRateOptions: IPerformanceRateOption[]
}

export const DaysCountBadgeCard: FC<Props> = ({ mttrAvg, rank, performanceRateOptions }) => (
  <div className={styles.wrapper}>
    <JitText
      bold
      className={styles.text}
      data-testid='DaysCountBadgeCard-text'
      size='huge'
      text={mttrAvg || 'NA'}
    />

    {rank ? (
      <RankBadgeWithPopover
        isLoading={false}
        performanceRateOptions={performanceRateOptions}
        popperDescription='MTTR measures the mean time from detection to remediation of security findings. Here you can view the industry standard to measure your teams performance.'
        popperTitle='pages.performance.graphs.mttr.widget.title'
        rank={rank}
      />
    ) : (
      <div className={styles.emptyRankStateText}>
        <JitText
          bold
          className={styles.text}
          color='black'
          data-testid='DaysCountBadgeCard-text'
          size='s'
          text='NA'
        />
      </div>
    )}

  </div>
);
