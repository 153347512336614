import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './ActionButtonCell.module.scss';

import { ChevronClosed } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { constants } from 'globalConstants';
import colors from 'themes/colors.module.scss';
import { ITableCell } from 'types/interfaces';
import { useIsUserMemberInTeam } from 'utils/hooks/useIsUserMemberInTeam';

export const ActionButtonCell: FC<ITableCell> = ({ cell: { value: { members, teamId, isActivated } } }) => {
  const { teamsPortal: { BASE_ROUTE: BASE_TEAMS_PORTAL_ROUTE, PORTAL_TEAMS } } = constants.routes;
  const { isMemberInTeam } = useIsUserMemberInTeam(members);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/${BASE_TEAMS_PORTAL_ROUTE}/${PORTAL_TEAMS}/${teamId}`);
  };

  if (!isMemberInTeam || !isActivated) return null;

  return (
    <JitButton
      data-testid='navigate-to-team-button'
      onClick={handleClick}
      sx={{
        backgroundColor: colors.leaderboardTableButton,
        borderRadius: '2px',
        border: '1px solid transparent',
        '&:hover': {
          border: `1px solid ${colors.white}`,
          backgroundColor: colors.leaderboardTableButton,
        },
      }}
    >
      <JitText
        className={styles.text}
        components={{
          icon: <JitIcon color={colors.white} icon={ChevronClosed} rotateDegrees={-90} />,
        }}
        text='pages.teamsPortal.leaderboardPage.table.cells.navigateToTeam'
      />
    </JitButton>
  );
};
