import { createContext, useContext } from 'react';

import { ISlackServiceChannelResponse, ISlackUserResponse } from 'types/interfaces/Slack';

interface ISlackContext {
  isBeingUninstalled: boolean;
  setIsBeingUninstalled: (isBeingUninstalled: boolean) => void;
  slackUsers?: ISlackUserResponse[];
  setSlackUsers: (slackUsers: ISlackUserResponse[] | undefined) => void;
  slackChannels?: ISlackServiceChannelResponse[];
  setSlackChannels: (slackChannels: ISlackServiceChannelResponse[] | undefined) => void;
  isSlackChannelsLoading: boolean;
  setIsSlackChannelsLoading: (isSlackChannelsLoading: boolean) => void;
}

export const SlackContext = createContext<ISlackContext>({
  isBeingUninstalled: false,
  setIsBeingUninstalled: () => {},
  slackUsers: [],
  setSlackUsers: () => {},
  slackChannels: [],
  setSlackChannels: () => {},
  isSlackChannelsLoading: false,
  setIsSlackChannelsLoading: () => {},
});

export const useSlackContext = () => useContext(SlackContext);
