// eslint-disable-next-line import/no-extraneous-dependencies
import { ITenantsResponse } from '@frontegg/rest-api/tenants/interfaces';
import { FC } from 'react';

import { JitTag } from 'components/JitTag/JitTag';
import { JitText } from 'components/JitText/JitText';
import styles from 'components/Navbar/components/SelectTenantPopper/SelectTenantPopper.module.scss';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import colors from 'themes/colors.module.scss';

interface Props {
  tenant?: ITenantsResponse;
}

export const UserDetails: FC<Props> = ({ tenant }) => (
  <div className={styles.userDetails}>
    <div className={styles.userTagWrapper}>
      <JitTag color={colors.white} padding='14px 20px' text={tenant?.name.slice(0, 1).toUpperCase() || ''} textSize='l' />
    </div>

    <div className={styles.textsWrapper}>

      <div className={styles.tooltipOverflowWrapper}>
        <TooltipOnlyOnOverflow tooltipText={tenant?.name}>
          <JitText color={colors.white} muted noWrap overflowHiddenEllipsis size='m' text={tenant?.name} />
        </TooltipOnlyOnOverflow>
      </div>

      <JitText muted size='m' text='navbar.menu.currentOrganization' />
    </div>

  </div>
);
