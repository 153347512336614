import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';

import styles from './JitTabsMenu.module.scss';

import { TabPanel } from 'components/JitDialogs/AssetsManagementDialog/components';
import colors from 'themes/colors.module.scss';

export interface TabItem {
  title: string;
  component: React.ReactNode;
}

interface JitTabsMenuProps {
  tabs: Record<string, TabItem>;
  initialTabKey?: string;
}

export const JitTabsMenu: React.FC<JitTabsMenuProps> = ({ tabs, initialTabKey }) => {
  const [selectedTab, setSelectedTab] = React.useState(initialTabKey || Object.keys(tabs)[0]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <Box>
      <Tabs
        onChange={handleTabChange}
        style={{
          boxShadow: `0px 1px 0px ${colors.darkGray02}`,
        }}
        TabIndicatorProps={{
          className: styles.tabIndicator,
          style: {
            backgroundColor: colors.lightBlue,
          },
        }}
        value={selectedTab}
      >
        {Object.entries(tabs).map(([key, { title }]) => (
          <Tab
            key={key}
            className={`${styles.tabLabel} ${selectedTab !== key && styles.nonSelectedTab}`}
            label={title}
            style={{
              color: colors.lightBlue,
              paddingLeft: 10,
              paddingRight: 10,
              paddingBottom: 5,
              textTransform: 'none',
            }}
            value={key}
          />
        ))}

      </Tabs>

      {Object.entries(tabs).map(([key, { component }]) => (
        <TabPanel key={key} index={key} value={selectedTab}>
          {component}
        </TabPanel>
      ))}
    </Box>
  );
};
