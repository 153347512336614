import { FC } from 'react';

import styles from './GraphLegend.module.scss';

import { JitText } from 'components/JitText/JitText';

interface Item {
  color: string;
  value: string;
}

interface Props {
  payload?: Item[];
}

export const GraphLegend: FC<Props> = ({ payload = [] }) => (
  <div className={styles.legend}>
    {payload.map(({ color, value }) => (
      <div key={value} className={styles.legendItem}>
        <div className={styles.box} data-testid={`legend-box-${value}`} style={{ backgroundColor: color }} />

        <JitText bold muted size='xs' text={value} />
      </div>
    ))}
  </div>
);
