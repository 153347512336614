import { FC } from 'react';

import { PlanStatusItemTable } from '../PlanStatusItemTable/PlanStatusItemTable';

import styles from './ResourcePageContent.module.scss';

import { Link } from 'assets';
import { JitBreadcrumbs } from 'components/JitBreadcrumbs/JitBreadcrumbs';
import { renderLinkBreadCrumbItem, renderTextBreadCrumbItem } from 'components/JitBreadcrumbs/utils/textToBreadcrumbContent';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitLink } from 'components/JitLink/JitLink';
import { JitRectangleGrade } from 'components/JitRectangleGrade/JitRectangleGrade';
import { JitText } from 'components/JitText/JitText';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { constants } from 'globalConstants';
import { buildResourceURL } from 'pages/TeamsPage/components/ResourcePlanItemsStatusesPage/utils/buildResourceURL';
import { useCurrentTeam } from 'pages/TeamsPortal/hooks/useCurrentTeam/useCurrentTeam';
import colors from 'themes/colors.module.scss';
import { IAsset } from 'types/interfaces';
import { useGetTableData } from 'utils/hooks/useGetResourcePlanStatusTableData';

interface ResourcePageContentProps {
  relevantAsset: IAsset;
}

export const ResourcePageContent: FC<ResourcePageContentProps> = ({ relevantAsset }) => {
  const {
    currentTeam,
    isLoadingSpecificTeam,
  } = useCurrentTeam();
  const {
    isLoading: isTableDataLoading,
    tableData,
    findingsCounts,
  } = useGetTableData(
    relevantAsset.asset_id,
    relevantAsset.asset_name,
    currentTeam?.name,
  );

  const {
    teamsPortal: {
      BASE_ROUTE: BASE_TEAMS_PORTAL_ROUTE,
      PORTAL_TEAMS,
    },
  } = constants.routes;
  const teamPageLink = `/${BASE_TEAMS_PORTAL_ROUTE}/${PORTAL_TEAMS}/${currentTeam?.id}`;
  const resourceURL = buildResourceURL(relevantAsset.asset_name, relevantAsset.owner, relevantAsset.vendor);

  return (
    <div className={styles.contentWrapper} data-testid='resource-page-content'>
      {isLoadingSpecificTeam ? (
        <LoadingBar />
      ) : (
        <>
          <div className={styles.header} data-testid='resource-page-loaded-content'>
            <JitBreadcrumbs
              items={[
                {
                  content: renderLinkBreadCrumbItem(currentTeam?.name || '', teamPageLink, 'team-name'),
                  itemKey: 'team-name',
                },
                {
                  content: renderTextBreadCrumbItem(relevantAsset.asset_name, 'resource-name'),
                  itemKey: 'resource-name',
                },
              ]}
            />

            {resourceURL && (
              <JitLink
                className={styles.githubLink}
                data-testid='asset-link'
                href={resourceURL}
                rel='noreferrer'
                target='_blank'
                title='View on GitHub'
                underline='none'
              >
                <JitText color={colors.blue02} text='pages.teamsPortal.resourcePage.viewOnGithub' />

                <JitIcon color={colors.blue02} data-testid='link-icon' icon={Link} size={17} />
              </JitLink>
            )}
          </div>

          <div className={styles.scoreWrapper}>
            <JitRectangleGrade score={relevantAsset.score || 0} size='l' />
          </div>

          <div className={styles.bodyWrapper} data-testid='resource-page-content-body'>
            <PlanStatusItemTable
              findingsCounts={findingsCounts}
              isLoading={isTableDataLoading}
              resourceId={relevantAsset.asset_id}
              resourceName={relevantAsset.asset_name}
              tableData={tableData}
              teamId={currentTeam?.id || ''}
            />
          </div>
        </>
      )}
    </div>
  );
};
