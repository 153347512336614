import { useCallback } from 'react';

import { useConfigurationsContext } from '../ConfigurationsContext';

import { IPlanItemDetails } from 'types/interfaces';

type PlanItem = Pick<IPlanItemDetails, 'slug'> & Partial<Pick<IPlanItemDetails, 'asset_types'>>;

export const useGetAreSettingsProperlySet = () => {
  const { isProperlyIntegrated, isProperlyConfigured } = useConfigurationsContext();

  const getAreSettingsProperlySet = useCallback(
    (planItem: PlanItem) => isProperlyConfigured(planItem.slug) && isProperlyIntegrated(planItem.asset_types || []),
    [isProperlyConfigured, isProperlyIntegrated],
  );

  return {
    getAreSettingsProperlySet,
  };
};
