import { FC } from 'react';

import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import colors from 'themes/colors.module.scss';

interface Props {
  owner: string;
}
export const SnapshotOwner: FC<Props> = ({ owner }) => (
  <div style={
    {
      width: '120px',
    }
  }
  >
    <TooltipOnlyOnOverflow color={colors.lightGray} data-testid={owner} size='m' text={owner} />

  </div>
);
