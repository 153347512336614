import { Dispatch, SetStateAction, useMemo } from 'react';

import actionsStyles from '../actionComponents.module.scss';

import { GithubIcon } from 'assets';
import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';
import { ActionButtonVariant } from 'types/interfaces';
import { ITeamCheckDetails, TaskKeyEnum } from 'types/interfaces/Teams/ITeam';

export const useGetExploreButton = (
  check: ITeamCheckDetails | undefined,
  setOpenedDialogKey: Dispatch<SetStateAction<string | null>>,
) => {
  const activateButton = (
    <JitGradientButton
      data-testid={`action-${TaskKeyEnum.exploreDeveloper}`}
      onClick={() => setOpenedDialogKey(TaskKeyEnum.exploreDeveloper)}
    >
      <div className={actionsStyles.gradientButton}>
        <JitText size='s' text={check?.options?.button?.label} />
      </div>
    </JitGradientButton>
  );

  const configureButton = useMemo(() => (
    <JitActionButton actionButton={{
      label: check?.value || '',
      handleClick: () => setOpenedDialogKey(TaskKeyEnum.exploreDeveloper),
      icon: GithubIcon,
      variant: ActionButtonVariant.SECONDARY,
    }}
    />
  ), [setOpenedDialogKey, check?.value]);

  const actionButton = check?.isCompleted ? (
    configureButton
  ) : (
    activateButton

  );

  return ({ actionButton });
};
