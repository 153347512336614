/* eslint-disable react/no-multi-comp */
import { useState, useEffect } from 'react';

import styles from './configurationComponentBySecret.module.scss';
import { SecretIntegration } from './Integrations/SecretIntegration/SecretIntegration';
import {
  ConfigurationsComponentDetails,
  IntegrationProps,
} from './interfaces/configurations';

import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import { JitText } from 'components/JitText/JitText';
import { logError } from 'services/logger/logger';

// Get title and subtitle associated with the vendorKey, e.g. npm will use Private Registry descriptions
const getTitleSubtitle = (key: string) => {
  switch (key) {
    case 'npm':
      return {
        title: 'configurations.optionalIntegrations.privateRegistries.title',
        subtitle: 'configurations.optionalIntegrations.privateRegistries.subtitle',
      };
    default:
      logError(`getTitleSubtitle: vendorKey ${key} not found.`);
      return {
        title: 'configurations.optionalIntegrations.defaultDescriptions.title',
        subtitle: 'configurations.optionalIntegrations.defaultDescriptions.subtitle',
      };
  }
};

const OptionalIntegration = (vendorKey: string, planItemName: string, title: string, subtitle: string): ConfigurationsComponentDetails | null => ({
  steps: [
    {
      ConfigurationComponent: ({ setIsDoneStep }: IntegrationProps) => {
        const [isChecked, setIsChecked] = useState(false);

        useEffect(() => {
          if (!isChecked) {
            setIsDoneStep(true);
          }
        }, [isChecked, setIsDoneStep]);

        return (
          <div className={styles.optionalIntegrationsSameLineContainer}>
            <JitCheckbox
              checked={isChecked}
              data-testid='configurationComponentBySecret-checkbox'
              onChange={(e) => setIsChecked(e.target.checked)}
              style={{ marginRight: '8px' }}
            />

            <div className={styles.optionalIntegrationsBody}>
              <JitText bold text={title} />

              <JitText
                style={{ marginBottom: '10px' }}
                text={subtitle}
              />

              {isChecked && (
              <SecretIntegration
                dialogCardWidth='100%'
                setIsIntegrationComplete={setIsDoneStep}
                vendorKeyForTemplate={vendorKey}
              />
              )}
            </div>
          </div>
        );
      },
      button: {
        text: 'dialogs.buttons.activate',
        disabledTooltip: 'pages.plan.configurationDialog.optionalSecretIntegration.disabledTooltip',
      },
      title: planItemName,
      subtitle: 'pages.plan.configurationDialog.optionalSecretIntegration.subtitle',
    },
  ],
});

// The vendorKey refers to the key of the integration template in integrations.json
export const configurationComponentBySecret = (vendorKey: string, planItemName: string, isOptional?: boolean): ConfigurationsComponentDetails | null => {
  if (isOptional) {
    const { title, subtitle } = getTitleSubtitle(vendorKey);
    return OptionalIntegration(vendorKey, planItemName, title, subtitle);
  }
  return {
    steps: [
      {
        ConfigurationComponent: ({ setIsDoneStep }: IntegrationProps) => (
          <SecretIntegration
            dialogCardWidth='100%'
            setIsIntegrationComplete={setIsDoneStep}
            vendorKeyForTemplate={vendorKey}
          />
        ),
        button: {
          text: 'dialogs.buttons.activate',
          disabledTooltip: 'pages.plan.configurationDialog.secretIntegration.disabledTooltip',
        },
        title: planItemName,
        subtitle: 'pages.plan.configurationDialog.secretIntegration.subtitle',
      }],
  };
};
