import { FC } from 'react';

import styles from './LicensesCell.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { ITableCell } from 'types/interfaces';

export const LicensesCell: FC<ITableCell> = ({ cell: { value } }) => (
  <div className={styles.wrapper}>
    <JitText alignSelf='center' color={colors.lightGray} text={(value.licenses || []).join(' OR ')} />
  </div>
);

