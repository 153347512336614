import { config } from 'config';
import { IFinding } from 'types/interfaces';

export const generateFindingLink = (finding: IFinding) => {
  if (finding.codeAttributes) {
    const [lineStart, lineEnd] = finding.codeAttributes.lineRange.split('-');
    const githubFilePathLink = config.getGithubFilePathLink({
      org: finding.organization,
      repo: finding.repository,
      branch: finding.codeAttributes.branch,
      path: finding.codeAttributes.filename,
      lineStart,
      lineEnd,
    });
    const lineRange = lineStart === lineEnd ? lineStart : `${lineStart}-${lineEnd}`;
    const linkText = `${finding.locationText}/${finding.codeAttributes.filename}@lines:${lineRange}`;
    return {
      linkText,
      linkUrl: githubFilePathLink,
    };
  }
  return {
    linkText: finding.locationText,
    linkUrl: null,
  };
};
