import json2md from 'json2md';

import { generateFindingLink } from './generateFindingLink';
import { groupFindings } from './groupFindings';

import { i18n } from 'locale/i18n';
import { IFinding } from 'types/interfaces';

const generateFindingTable = (findings: IFinding[]) => ({
  table: {
    headers: [i18n.t('pages.findings.copy.findingLocation')],
    rows: findings.map((finding) => {
      const { linkText, linkUrl } = generateFindingLink(finding);
      const location = linkUrl ? `[${linkText}](${linkUrl})` : linkText;
      return [finding.location || location];
    }),
  },
});

export const generateMarkdown = (findings: IFinding[]): string => {
  const groupedFindings = groupFindings(findings);
  json2md.converters.bold = (input: string) => `**${input}**`;
  const findingsMdJson: json2md.DataObject[] = [];
  groupedFindings.forEach((group) => {
    const findingJson: json2md.DataObject = [{ h1: `[${i18n.t('pages.findings.copy.securityFinding')}] ${group.name}` }];
    findingJson.push({ p: `${i18n.t('pages.findings.copy.severity')}: \`${group.severity}\`` });
    findingJson.push({ p: `${i18n.t('pages.findings.copy.vulnerabilityType')}: \`${group.vulnerabilityType}\`` });
    findingJson.push(generateFindingTable(group.findings));
    findingJson.push({ blockquote: i18n.t('pages.findings.copy.info', { control: group.controlName,
      date: group.firstDetectedDate }) });
    findingJson.push({ h2: `${i18n.t('pages.findings.copy.issueText')}` });
    findingJson.push({ blockquote: `${group.issueText}\n\n[${i18n.t('pages.findings.copy.learnMore')}](${group.referencesUrls})` });
    findingsMdJson.push(findingJson);
  });
  return json2md(findingsMdJson);
};
