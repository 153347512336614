import { FC } from 'react';

import styles from './ResourcesStatistics.module.scss';

import { JitText } from 'components/JitText/JitText';
import { PriorityBreakdown } from 'pages/Resources/components/PriorityBreakdown/PriorityBreakdown';
import colors from 'themes/colors.module.scss';
import { IAssetPriorityBuckets } from 'types/interfaces';

interface Props {
  resourcesCount: number;
  priorityBreakdown: Omit<IAssetPriorityBuckets, 'total'>
}

export const ResourcesStatistics: FC<Props> = ({ resourcesCount, priorityBreakdown }) => (

  <div className={styles.wrapper} data-testid='resources-statistics'>
    <div className={styles.sectionWrapper} style={{ alignItems: 'start' }}>
      <JitText bold color={colors.lightGray} size='xs' text='pages.risks.resources.resourcesDashboard.resourcesCount' />

      <JitText bold size='l' text={resourcesCount} />
    </div>

    <div className={styles.sectionWrapper} style={{ alignItems: 'end' }}>
      <JitText bold color={colors.lightGray} size='xs' text='pages.risks.resources.resourcesDashboard.findingsByCriticality' />

      <PriorityBreakdown critical={priorityBreakdown.critical} high={priorityBreakdown.high} medium={priorityBreakdown.medium + priorityBreakdown.low} />
    </div>
  </div>
);
