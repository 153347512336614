import { i18n } from 'locale/i18n';

export const getFormattedOptions = (
  currentKeyInput: string,
  keyOptions: string[],
) => {
  const sortedKeyOptions = keyOptions.sort();
  const isInputKeyNoInOptions = currentKeyInput && !keyOptions.includes(currentKeyInput);
  const options = isInputKeyNoInOptions ? [currentKeyInput, ...sortedKeyOptions] : sortedKeyOptions;
  return options.map((value) => ({
    key: value,
    label: value === currentKeyInput && !keyOptions.includes(value) ? i18n.t('pages.risks.resources.resourcePage.resourceDetails.addTagPopover.formattedNewTag', { tag: value }) : value,
  }));
};

export const PREDEFINED_TAGS = [
  'Application',
  'Service',
  'Microservice',
  'Business Unit',
  'Business Line',
  'Product',
];
