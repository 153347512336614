import uniqBy from 'lodash/uniqBy';
import { useCallback } from 'react';

import { usePlansContext } from '../PlansContext';

import { usePlanInstanceContext } from 'context/PlanInstanceContext/PlanInstanceContext';
import { buildPlanItemRecord } from 'context/PlanInstanceContext/planUtils';
import { PlanItemConclusion } from 'types/enums';
import { IPlanInstance, ITenantPlanItem, IPlanItemDetails } from 'types/interfaces';

export const useAddItemsToPlan = () => {
  const { plan, setPlan, setPlanItems } = usePlanInstanceContext();
  const { setPlans } = usePlansContext();

  const createPlaceholderItem = useCallback(
    (planItem: IPlanItemDetails): ITenantPlanItem => (
      {
        slug: planItem.slug,
        name: planItem.name,
        layer: planItem.layer,
        conclusion: PlanItemConclusion.SUCCESS,
        is_active: true,
        input: planItem.input,
      }
    ),
    [],
  );

  const addItemsToPlan = useCallback((planItems: IPlanItemDetails[]) => {
    const itemsToAdd = planItems.map((itemOption) => buildPlanItemRecord(itemOption.name, itemOption?.layer, itemOption.slug, itemOption.input));
    const items = (plan?.items || []).map((item) => {
      const updatedItem = itemsToAdd.find((itemToAdd) => itemToAdd.uses === item.uses);
      return updatedItem || item;
    });
    const newSlugs = new Set(planItems.map((item) => item.slug));

    const updatedUniqItems = uniqBy([
      ...(items),
      ...itemsToAdd,
    ], (item) => item.uses);

    const updatedPlan: IPlanInstance = {
      ...plan,
      items: updatedUniqItems,
    };
    setPlan(updatedPlan);
    setPlanItems((prevItems) => [
      ...(prevItems || []),
      ...planItems.map((item) => createPlaceholderItem(item)),
    ]);
    setPlans((prevPlans) => (
      Object.fromEntries(
        Object.entries(prevPlans).map(([, planDetails]) => {
          const itemsInPlan = new Set([...planDetails.allItemSlugs].filter((slug) => newSlugs.has(slug)));
          if (itemsInPlan.size === 0) {
            return [planDetails.slug, planDetails];
          }
          return [planDetails.slug, {
            ...planDetails,
            activeItemSlugs: new Set([...planDetails.activeItemSlugs, ...itemsInPlan]),
            items: Object.fromEntries(
              Object.entries(planDetails.items || {}).map(([key, item]) => {
                if (itemsInPlan.has(key)) {
                  return [key, {
                    ...item,
                    is_active: true,
                  }];
                }
                return [key, item];
              }),
            ),
          }];
        }),
      )
    ));
    return updatedPlan;
  }, [createPlaceholderItem, plan, setPlan, setPlanItems, setPlans]);

  return { addItemsToPlan };
};
