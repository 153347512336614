import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';

import styles from './OpenFindingsGraph.module.scss';
import { OpenFindingsTooltip } from './TooltipFormatter';

import {
  commonLineProps,
  commonGridProps,
  commonYAxisProps,
  commonLineChartProps,
  commonTooltipProps,
} from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/constants';
import colors from 'themes/colors.module.scss';
import { ITotalFindingsGraph } from 'types/interfaces/Metrics/SecurityImpactMetrics/TotalFindingsGraph';

interface OpenFindingsGraphProps {
  totalFindingsGraph: ITotalFindingsGraph;
}

export const OpenFindingsGraph: React.FC<OpenFindingsGraphProps> = ({
  totalFindingsGraph,
}) => {
  const monthTickFormatter = (dateString: string) => {
    const date = new Date(dateString);
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
    return month;
  };

  const YAxisTickFormatter = (value: number) => value.toLocaleString();

  const sortedDays = totalFindingsGraph.days.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA.getTime() - dateB.getTime();
  });

  const chartData = sortedDays.map((item) => ({
    date: item.date,
    openFindings: item.openFindings,
    resolvedFindings: item.resolvedFindings,
  }));

  const maxValue = Math.max(...chartData.map((item) => item.openFindings));
  const formattedLastValue = YAxisTickFormatter(maxValue);

  return (
    <div className={`${styles.graph}`} data-testid='openFindingsGraph'>
      <ResponsiveContainer height={297} width='100%'>
        <LineChart
          {...commonLineChartProps}
          data={chartData}
          margin={{
            left: -32,
            top: 10,
          }}
        >
          <CartesianGrid {...commonGridProps} stroke={colors.darkGray} />

          <XAxis dataKey='date' tickFormatter={monthTickFormatter} tickLine={false} tickMargin={10} />

          <YAxis {...commonYAxisProps(formattedLastValue.length)} allowDecimals={false} tickFormatter={YAxisTickFormatter} />

          <Line
            {...commonLineProps}
            activeDot={<circle fill={colors.failRed} r={4} stroke={colors.appBgColor} strokeWidth={2} />}
            dataKey='openFindings'
            stroke={colors.failRed}
            strokeWidth={3}
          />

          <Tooltip {...commonTooltipProps} content={<OpenFindingsTooltip />} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
