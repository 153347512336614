import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

import { TextCell } from 'components/JitTable/templates';
import { TagsCell } from 'components/JitTable/templates/TagsCell/TagsCell';
import { i18n } from 'locale/i18n';
import { GradeCell } from 'pages/TeamsPortal/components/GradeCell/GradeCell';
import { IAsset } from 'types/interfaces';
import { TableColumn } from 'types/types';

export const useGetColumns = (): TableColumn<object>[] => {
  const { t } = i18n;
  const { useKnowledgeGraph } = useFlags();
  // @ts-ignore The whole tables related logic uses object as type, this should be fixed
  const columns: TableColumn<object>[] = useMemo(() => [
    {
      Header: t('pages.teams.teamDetails.resourceTable.columns.name'),
      accessor: (asset: IAsset) => asset.asset_name,
      Cell: TextCell,
      width: useKnowledgeGraph ? '30%' : '80%',
    },
    ...useKnowledgeGraph ? [{
      Header: t('pages.teams.teamDetails.resourceTable.columns.tags'),
      accessor: (asset: IAsset) => asset.tags,
      Cell: TagsCell,
      width: '50%',
    }] : [],
    {
      Header: t('pages.teams.teamDetails.resourceTable.columns.score'),
      accessor: (asset: IAsset) => ({
        score: asset.score || 0,
        isActivated: true,
      }),
      Cell: GradeCell,
      width: '20%',
    },
  ], [t, useKnowledgeGraph]);

  return columns;
};
