import { addIgnoredFilterIfNeeded } from './utils/addIgnoreFilterIfNeeded';

import { useFindingsContext } from 'context/FindingsContext/FindingsContext';
import { IFindingFilter } from 'types/interfaces';

export const useAddFilterToEndOfList = () => {
  const { filters, setFilters } = useFindingsContext();

  const addFilterToEndOfList = (newFilter: IFindingFilter) => {
    let currentFilters = filters.filter((currentFilter) => currentFilter.key !== newFilter.key);
    currentFilters.push(newFilter);
    currentFilters = addIgnoredFilterIfNeeded(newFilter, currentFilters);

    setFilters(currentFilters);
  };
  return { addFilterToEndOfList };
};
