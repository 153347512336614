import { possibleYAxisTicks } from './constants';

import { constants } from 'globalConstants';
import { Environment, MTTRDay } from 'types/interfaces/Metrics/PerformanceMetrics/MTTR';
import { formatDuration } from 'utils';

export const graphNameWithData = (env: Environment) => `${env}_DAYS_WITH_DATA`;
export const graphNameWithoutData = (env: Environment) => `${env}_DAYS_WITHOUT_DATA`;

export const isEmptyDay = (day: MTTRDay, environment: Environment) => !day[environment];
export const getLineDataKey = (environment: Environment) => (day: MTTRDay) => day?.[environment]?.mttr?.value;

export const yAxisTicksFormatter = (mttrInHours: number) => (formatDuration(mttrInHours * constants.time.HOUR, 1));

export const getYAxisTicks = (days: MTTRDay[]) => {
  const getDayMttrs = (day: MTTRDay) => [getLineDataKey('production')(day), getLineDataKey('preProduction')(day)];
  const daysMttrs = days.map(getDayMttrs).flat(2).map((mttr) => mttr || 0);
  const maxMTTR = Math.max(...daysMttrs);
  return possibleYAxisTicks.filter((_, index) => possibleYAxisTicks[index - 1] <= maxMTTR);
};

export const getEmptyLineDataKey = (days: MTTRDay[], environment: Environment) => (currentDay: MTTRDay) => {
  if (isEmptyDay(currentDay, environment)) return 0;

  const dayIndex = days.indexOf(currentDay);
  const prevDay = days[dayIndex - 1];
  const nextDay = days[dayIndex + 1];
  if ((prevDay && isEmptyDay(prevDay, environment)) || (nextDay && isEmptyDay(nextDay, environment))) return getLineDataKey(environment)(currentDay);

  return null;
};
