import { IPipelineNew, IResponsedPipelineNew } from 'types/interfaces/Pipelines/IPipelineNew';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

export const ConvertSinglePipelineResponseIntoIPipeline = <T extends object, V>(pipeline: T): V => (
  camelizeSnakeCaseKeys(pipeline) as unknown as V
);

export const ConvertPipelineResponseIntoIPipelineNew = (pipelineList: IResponsedPipelineNew[]): IPipelineNew[] => (
  pipelineList?.map((pipeline) => ConvertSinglePipelineResponseIntoIPipeline(pipeline)) || []
);
