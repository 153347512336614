import { FC } from 'react';

import styles from './PipelineWorkflows.module.scss';

import { JitText } from 'components/JitText/JitText';
import { WorkflowNew } from 'pages/PipelinesPage/components/WorkflowsNew/WorkflowNew';
import { AssetsList } from 'pages/PipelinesPage/PipelineDetailsPage/components/PipelineWorkflows/Assets/AssetsList/AssetsList';
import { getPrLink, getJobRunner } from 'pages/PipelinesPage/utils/utilsNew';
import colors from 'themes/colors.module.scss';
import { JitEventName } from 'types/enums';
import { IPipelineNew } from 'types/interfaces/Pipelines';

interface Props {
  pipeline: IPipelineNew;
}

export const PipelineWorkflows: FC<Props> = ({ pipeline }) => (
  <div className={styles.workflowsContainer} data-testid='PipelineWorkflows'>
    <JitText bold color={colors.lightGray} data-testid='PipelineWorkflowsHeader' text='pages.pipelineDetails.workflows.header' />

    <div className={styles.workflowsList} data-testid='PipelineWorkflowsList'>

      {Object.entries(pipeline.workflows).map(([workflowSlug, workflow]) => (
        <div key={workflowSlug} data-testid='PipelineWorkflow'>
          <WorkflowNew
            key={workflowSlug}
            jobRunner={getJobRunner(pipeline.workflows)}
            prLink={getPrLink(pipeline)}
            workflow={workflow}
            workflowSlug={workflowSlug}
          >
            <AssetsList assets={workflow.assets} jitEventId={pipeline.jitEventId} jitEventName={pipeline.jitEventName as JitEventName} />
          </WorkflowNew>
        </div>
      ))}
    </div>
  </div>
);

