import Radio, { RadioProps } from '@mui/material/Radio';
import { styled } from '@mui/material/styles';

import colors from 'themes/colors.module.scss';

interface Props extends Omit<RadioProps, 'size'> {
  size?: number;
}

export const JitRadioButton = styled(Radio)<Props>(({ size = 20, disabled }) => ({
  height: size,
  width: size,
  color: `${disabled ? colors.darkGray : colors.blue02} !important`,
  svg: {
    height: size,
  },
}));
