import { isEmpty } from 'lodash';
import { FC } from 'react';

import styles from './FindingDetailsTickets.module.scss';

import { CreateTicketButton } from 'components/CreateTicketButton';
import { JitText } from 'components/JitText/JitText';
import { TicketsList } from 'components/TicketsList/TicketsList';
import colors from 'themes/colors.module.scss';
import { IFinding, TicketFinding } from 'types/interfaces';

interface Props {
  finding: IFinding;
  shouldDisplayCreateTicketButton?: boolean;
  handleBack: (ticket?: TicketFinding) => void;
}

export const FindingDetailsTickets: FC<Props> = ({ finding, shouldDisplayCreateTicketButton, handleBack }) => (
  <>
    {(!isEmpty(finding.tickets) || shouldDisplayCreateTicketButton) && (
      <JitText
        color={colors.lightGray}
        data-testid='ticket-section-header'
        size='s'
        text='pages.pipelineDetails.findingsDetails.ticketSectionHeader'
      />
    )}

    {finding.tickets && <TicketsList tickets={finding.tickets} />}

    { shouldDisplayCreateTicketButton
        && (
        <div className={styles.createTicketButton}>
          <CreateTicketButton
            callToActionText='tickets.noIntegrationTooltip'
            data-testid='create-ticket-button'
            findings={[finding]}
            onClose={handleBack}
          />
        </div>
        )}
  </>
);
