import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useState, useCallback } from 'react';
import { useQuery } from 'react-query';

import { EngineDisabledBanner } from './component/EngineDisabledBanner/EngineDisabledBanner';

import { PageTitles } from 'components/PageTitles/PageTitles';
import { RisksBreadcrumbs } from 'pages/Resources/components/RisksBreadcrumbs/RisksBreadcrumbs';
import { FindingsCountCard } from 'pages/Resources/ResourcesPage/component/FindingsCountCard/FindingsCountCard';
import { ResourcesTable } from 'pages/Resources/ResourcesPage/component/ResourcesTable/ResourcesTable';
import { ResourcesTableFilters } from 'pages/Resources/ResourcesPage/component/ResourcesTable/ResourcesTableFilters/ResourcesTableFilters';
import { ScoreCard } from 'pages/Resources/ResourcesPage/component/ScoreCard/ScoreCard';
import styles from 'pages/Resources/ResourcesPage/ResourcesPage.module.scss';
import { useAssetService } from 'services/AssetsService/useAssetService';
import { Queries } from 'types/enums/Queries';
import { IFilter } from 'types/interfaces';

export const ResourcesPage: FC = () => {
  const { useKnowledgeGraph } = useFlags();
  const { getAssetsStatistics } = useAssetService();

  const [filters, setFilters] = useState<IFilter[]>([]);

  const { data: statistics = [] } = useQuery(
    [Queries.AssetsStatistics, { filterParams: filters }],
    getAssetsStatistics,
    { keepPreviousData: true },
  );
  // Since we didn't pass any aggregation key, we expect that we would always have one item represents 'all'
  const resourcesStatistics = statistics?.[0];

  const clearFilters = useCallback(() => {
    setFilters((prevFilters) => prevFilters.map((filter) => ({
      ...filter,
      selectedValue: [],
    })));
  }, []);

  return (
    <div className={styles.wrapper}>
      <RisksBreadcrumbs />

      <div className={styles.titleWrapper}>
        <PageTitles title='pages.risks.resources.resourcesPage.title' />
      </div>

      <div className={styles.header}>
        <ResourcesTableFilters filters={filters} setFilters={setFilters} />

        {useKnowledgeGraph && (
          <div className={styles.cardsWrapper} data-testid='statistic-cards'>
            <ScoreCard resourcesStatistics={resourcesStatistics} />

            <FindingsCountCard filters={filters} />
          </div>
        )}
      </div>

      {!useKnowledgeGraph && <EngineDisabledBanner />}

      <div className={styles.body}>
        <ResourcesTable clearFilters={clearFilters} filters={filters} resourcesStatistics={resourcesStatistics} />
      </div>
    </div>
  );
};
