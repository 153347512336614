import ButtonGroup from '@mui/material/ButtonGroup';
import * as React from 'react';
import { FC } from 'react';

import { JitButton } from '../JitButton/JitButton';
import { JitIcon } from '../JitIcon/JitIcon';
import { JitMenu, MenuItemType } from '../JitMenu/JitMenu';
import { JitText } from '../JitText/JitText';

import { ExpandArrow } from 'assets';
import colors from 'themes/colors.module.scss';
import { IActionOption, IMouseEvent, MenuItemKeyType } from 'types/interfaces';

interface Props {
  options: IActionOption[]
  disableActions: boolean
  isLoading?: boolean
  'data-testid'?: string
}
export const JitMultiActionButton: FC<Props> = ({
  options,
  disableActions,
  isLoading = false,
  ...props
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const onMenuItemClick = ({ e, itemKey }: { e: IMouseEvent, itemKey: MenuItemKeyType }) => {
    const index = options.findIndex((option) => option.text === itemKey);
    setSelectedIndex(index);
    options[index].handleClick(e);
    setIsOpen(false);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleToggle = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };
  const menuItems: MenuItemType[] = options.map((option, index) => {
    const text = <JitText data-testid={option.text} size='m' text={option.text} />;
    return ({
      itemKey: option.text,
      itemContent: text,
      isSelected: index === selectedIndex,
      disabled: option.disabled,
      itemWrapper: option.wrapper,
    });
  });
  return (
    <div data-testid={props['data-testid'] || 'multiActionButton'}>

      <JitMenu
        alignment='right'
        data-testid='menu'
        menuItems={menuItems}
        menuStyle={{ alignItems: 'right',
          marginTop: 1 }}
        onMenuClose={handleClose}
        onMenuItemClick={onMenuItemClick}
        open={isOpen}
        paperProps={{ style: {
          border: `1px solid ${colors.gray}`,
        } }}
        shouldCloseOnItemClick
      >

        <ButtonGroup aria-label='split button' variant='contained'>
          <JitButton
            data-testid='selectedActionButton'
            disabled={disableActions}
            isLoading={isLoading}
            onClick={options[selectedIndex].handleClick}
          >
            <JitText data-testid='selectedActionText' size='m' text={options[selectedIndex].text} />
          </JitButton>

          <JitButton
            data-testid='expandButton'
            disabled={disableActions}
            height={36}
            onClick={handleToggle}
            size='small'
            sx={{
              borderRadius: 2 }}
          >
            <JitIcon height={6} icon={ExpandArrow} width={10} />
          </JitButton>
        </ButtonGroup>
      </JitMenu>
    </div>
  );
};
