import { client } from '../client';

import { parseFindings } from './utils/parseFindings';

import {
  CreateIgnoreRuleRequest,
  IBaseIgnoreRule,
  IFinding,
  IFindingServer,
  IFindingsS3CsvUrl,
  IFindingsStatistics,
  IServerFindingFilterOptions,
  ITableQueryParams,
  ITableServerResponse,
} from 'types/interfaces';
import { ICount, IGroupByCount } from 'types/interfaces/Counts/ICounts';
import { IGetFindingsResponse } from 'types/interfaces/Findings/IGetFindingsResponse';
import { ISavedFilter } from 'types/interfaces/Findings/ISavedFilter';
import { ISavedFilterResponse } from 'types/interfaces/Findings/ISavedFilterResponse';
import { parseObjectToQueryParams } from 'utils';

const serviceName = 'finding';
const newServiceName = 'findings';

export const createIgnoreRule = (ignoreRulesToCreate: CreateIgnoreRuleRequest): Promise<IBaseIgnoreRule | undefined> => {
  const url = `${serviceName}/ignore-rules/v2`;
  return client.post<IBaseIgnoreRule | undefined>(url, ignoreRulesToCreate);
};

export const deleteIgnoreRule = (ignoreRuleToDeleteId: string): Promise<undefined> => {
  const url = `${serviceName}/ignore-rules/v2/${ignoreRuleToDeleteId}`;
  return client.delete(url);
};

export const fetchFindingsCount = async (params: ITableQueryParams, backlog: boolean | null = true): Promise<ICount | IGroupByCount[] | undefined> => {
  const queryParams = parseObjectToQueryParams({
    ...params,
    filters: {
      ...params.filters,
      backlog,
    },
  });
  const url = `${newServiceName}/count?${queryParams}`;

  const response = await client.get<ICount | IGroupByCount[]>(url);
  return response;
};

export const fetchFindings = async (params: ITableQueryParams, nextPageKey?: string): Promise<ITableServerResponse<IFinding>> => {
  const queryParams = parseObjectToQueryParams({
    ...params,
    filters: {
      ...params.filters,
      backlog: true,
    },
    next_page_key: nextPageKey,
  });
  let url = `${newServiceName}/`;
  if (queryParams) url += `?${queryParams}`;
  const response = await client.get<IGetFindingsResponse>(url);
  const {
    findings,
    next_page_key: nextKey,
  } = response || {};
  return {
    data: parseFindings(findings || []),
    metadata: {
      count: findings?.length || 0,
      nextKey: nextKey || undefined,
    },
  };
};

const convertSavedFilterObjectToCamelCase = (savedFilter: ISavedFilterResponse): ISavedFilter => {
  const convertedSavedFilter: ISavedFilter = {
    ...savedFilter,
    isDefault: savedFilter.is_default,
    shouldNotify: savedFilter.should_notify,
  };
  return convertedSavedFilter;
};

export const postSavedFilter = async (savedFilter: ISavedFilter): Promise<ISavedFilter | undefined> => {
  const url = `${serviceName}/saved-filters`;
  const savedFilterRequest: ISavedFilterResponse = {
    ...savedFilter,
    is_default: savedFilter.isDefault,
    should_notify: savedFilter.shouldNotify,
  };
  const response = await client.post(url, savedFilterRequest);
  return response ? convertSavedFilterObjectToCamelCase(response) : undefined;
};

export const putSavedFilter = async (savedFilter: ISavedFilter): Promise<ISavedFilter | undefined> => {
  const url = `${serviceName}/saved-filters`;
  const response = await client.put(url, {
    ...savedFilter,
    is_default: savedFilter.isDefault,
    should_notify: savedFilter.shouldNotify,
  });
  return response ? convertSavedFilterObjectToCamelCase(response) : undefined;
};

export const fetchSavedFilters = async (): Promise<ISavedFilter[]> => {
  const url = `${serviceName}/saved-filters`;
  const response: ISavedFilterResponse[] | undefined = await client.get(url);
  return response ? response.map(convertSavedFilterObjectToCamelCase) : [];
};

export const deleteSavedFilter = async (id: string) => {
  const url = `${serviceName}/saved-filters/${id}`;
  await client.delete(url);
};

export const fetchFindingsCsvLink = async (params: ITableQueryParams): Promise<string> => {
  const queryParams = parseObjectToQueryParams({
    ...params,
    filters: {
      ...params.filters,
      backlog: true,
    },
  });
  const url = `${serviceName}?${queryParams}`;
  const response = await client.get<IFindingsS3CsvUrl>(url, { headers: { Accept: 'text/csv+link' } });
  return response?.s3_url || '';
};

export const fetchFindingsStatistics = (params: ITableQueryParams) => {
  const queryParams = parseObjectToQueryParams({
    ...params,
    filters: {
      ...params.filters,
      backlog: true,
    },
  });
  let url = `${serviceName}/stats`;
  if (queryParams) url += `?${queryParams}`;
  return client.get<IFindingsStatistics>(url);
};

export const fetchFilterOptions = (params: ITableQueryParams) => {
  const queryParams = parseObjectToQueryParams({
    ...params,
    filters: {
      ...params.filters,
      backlog: true,
    },
  });
  let url = `${serviceName}/filters-values`;
  if (queryParams) url += `?${queryParams}`;
  return client.get<IServerFindingFilterOptions>(url);
};

export const fetchFullFindingsByIds = async (findingIds: string[]): Promise<IFindingServer[] | undefined> => {
  // Get findings by batches
  const responses: Promise<IFindingServer[] | undefined>[] = [];
  const batchSize = 50;
  for (let i = 0; i < findingIds.length; i += batchSize) {
    const findingIdsBatch = findingIds.slice(i, i + batchSize);
    const url = `${serviceName}/findings-by-ids`;
    responses.push(client.post<IFindingServer[]>(url, findingIdsBatch));
  }
  const findingsResponses: (IFindingServer[] | undefined)[] = await Promise.all(responses);
  const findings = findingsResponses.filter((findingsResponse) => findingsResponse !== undefined) as IFindingServer[][];
  return findings.flat();
};

export const getPrFindings = async (assetId: string, branch: string, prNumber: string, lastEvaluatedKey?: string): Promise<IFindingServer[] | undefined> => {
  const url = `${serviceName}/pr-findings?asset_id=${assetId}&branch=${branch}&pr_number=${prNumber}&last_evaluated_key=${lastEvaluatedKey}`;
  return client.get(url);
};

export const openFixPR = async (findingId: string): Promise<undefined> => {
  const url = `${newServiceName}/${findingId}/fixes`;
  return client.post(url);
};
