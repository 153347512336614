import { FC } from 'react';

import styles from './CircularLoadingSpinner.module.scss';

import colors from 'themes/colors.module.scss';

type Variants = 'simple' | 'colorful';

interface Props {
  variant?: Variants
  size?: 'small' | 'medium' | 'large'
}

export const CircularLoadingSpinner: FC<Props> = ({ variant = 'simple', size = 'medium' }) => {
  const variantsMap = {
    simple: {
      color: colors.white,
    },
    colorful: {
      color: colors.blue02,
    },
  };
  const sizeMap = {
    small: {
      height: 16,
      width: 16,
    },
    medium: {
      height: 20,
      width: 20,
    },
    large: {
      height: 24,
      width: 24,
    },
  };
  const variantParams = variantsMap[variant];
  const sizeParams = sizeMap[size];
  return (
    <div
      className={styles.loadingSpinner}
      data-testid='loadingSpinner'
      style={{
        borderColor: variantParams.color,
        height: sizeParams.height,
        width: sizeParams.width,
      }}
    />
  );
};
