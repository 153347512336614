import { FC } from 'react';

import { FeedbackIcon } from 'assets';
import { FeedbackBox } from 'components/FeedbackBox/FeedbackBox';
import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitPopper } from 'components/JitPopper/JitPopper';
import { useGraphService } from 'services/GraphService/useGraphService';
import { ActionButtonVariant, IFinding } from 'types/interfaces';

interface FeedbackPopperProps {
  finding: IFinding;
  isFeedbackOpen: boolean;
  setIsFeedbackOpen: (isOpen: boolean) => void;
  setIsFeedbackSubmitted: (isSubmitted: boolean) => void;
}

export const FeedbackPopper: FC<FeedbackPopperProps> = ({ finding, isFeedbackOpen, setIsFeedbackOpen, setIsFeedbackSubmitted }) => {
  const { createFeedback } = useGraphService();

  const handleSubmit = async (feedback?: string, rating?: string) => {
    await createFeedback({
      feedback: `${rating}/5: ${feedback}`,
      findingId: finding.id,
      priorityFactor: 'General',
      assetId: finding.assetId,
      findingDetails: finding,
    });
    setIsFeedbackSubmitted(true);
  };

  const feedbackContent = (
    <FeedbackBox
      handleClose={() => setIsFeedbackOpen(false)}
      handleSubmit={handleSubmit}
      subtitle='pages.findings.findingDetails.priority.feedbackWindowSubtitle'
      title='pages.findings.findingDetails.priority.feedbackWindowTitle'
    />
  );
  return (
    <JitPopper
      content={feedbackContent}
      isWithArrow={false}
      keepMounted
      onClose={() => setIsFeedbackOpen(false)}
      open={isFeedbackOpen}
      placement='bottom-end'
      shadowVariant='none'
    >
      <div data-testid='FeedbackButton'>
        <JitActionButton
          actionButton={{
            icon: FeedbackIcon,
            iconSize: 16,
            handleClick: () => setIsFeedbackOpen(!isFeedbackOpen),
            variant: ActionButtonVariant.SECONDARY,
            noHover: true,
            textProps: {
              size: 's',
              bold: false,
              color: 'inherit',
            },
          }}
        />
      </div>
    </JitPopper>
  );
};
