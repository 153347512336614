import type { Location } from 'react-router-dom';

import { i18n } from 'locale/i18n';
import { IFilterOption, IFilterType, IFilter } from 'types/interfaces';
import { IAssetFilterValues, ValueOfIAssetFilterValues } from 'types/interfaces/IAssetFilterValues';
import { Tag } from 'types/interfaces/Tag';

type FilterMetadata = {
  entityKey: keyof IAssetFilterValues;
  displayText: string;
  translationPrefix?: string;
  keyTransformer?: (value: ValueOfIAssetFilterValues) => string;
  requiresContextEngine?: boolean
};

const filtersMetadata: FilterMetadata[] = [
  {
    entityKey: 'priority_factors',
    displayText: i18n.t('pages.risks.resources.resourcesPage.table.filters.priorityFactors'),
    requiresContextEngine: true,
  },
  {
    entityKey: 'asset_types',
    displayText: i18n.t('pages.risks.resources.resourcesPage.table.filters.type'),
    translationPrefix: 'assetType.',
  },
  {
    entityKey: 'teams',
    displayText: i18n.t('pages.risks.resources.resourcesPage.table.filters.teams'),
  },
  {
    entityKey: 'tags',
    displayText: i18n.t('pages.risks.resources.resourcesPage.table.filters.tags'),
    keyTransformer: ((tag: Tag) => `${tag.name}: ${tag.value}`) as FilterMetadata['keyTransformer'],
  },
];

const extractSelectedValuesFromUrl = (location: Location, entityKey: string, valueOptions: IFilterOption[]): IFilterOption[] => {
  const params = new URLSearchParams(location.search);
  const paramValue = params.get(entityKey);
  if (!paramValue) return [];
  const selectedValues = paramValue.split(',');
  return valueOptions.filter((option) => selectedValues.includes(option.value));
};

const convertToFilterOption = (value: ValueOfIAssetFilterValues, keyTransformer: FilterMetadata['keyTransformer'], translationPrefix: string = ''): IFilterOption => {
  const transformedValue = keyTransformer ? keyTransformer(value) : value;
  return ({
    value: transformedValue as string,
    displayText: i18n.t(translationPrefix + transformedValue),
  });
};

export const buildInitialFilters = (location: Location, assetFilterValues: IAssetFilterValues, includeContextFilters: boolean): IFilter[] => filtersMetadata.filter(
  ({ requiresContextEngine }) => includeContextFilters || !requiresContextEngine,
).map(({ entityKey, translationPrefix, keyTransformer, displayText }) => {
  const valueOptions = assetFilterValues[entityKey].map((value) => convertToFilterOption(value, keyTransformer, translationPrefix || ''));
  return ({
    entityKey,
    displayText,
    type: IFilterType.MULTI_SELECT,
    valueOptions,
    selectedValue: extractSelectedValuesFromUrl(location, entityKey, valueOptions),
  });
});
