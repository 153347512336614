import { isEmpty } from 'lodash';
import { FC } from 'react';

import { JitButton } from 'components/JitButton/JitButton';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { IFilter, IFilterType, IFilterOption } from 'types/interfaces/IFilter';

interface Props {
  clearFilters: () => void;
  filters: IFilter[];
}

export const FiltersClearButton: FC<Props> = ({
  clearFilters,
  filters,
}) => {
  const shouldEnable = !!filters.find((filter) => {
    if (filter.type === IFilterType.MULTI_SELECT) {
      return !isEmpty(filter.selectedValue);
    }

    return (filter?.selectedValue && !isEmpty((filter.selectedValue as IFilterOption).value));
  });

  return (
    <JitButton
      data-testid='clearButton'
      disabled={!shouldEnable}
      disableRipple
      noHover
      onClick={clearFilters}
      style={{
        height: 'auto',
        padding: '0',
      }}
    >
      <JitText
        color={shouldEnable ? colors.blue02 : colors.gray}
        data-testid='clearButtonText'
        size='l'
        style={{
          textDecorationColor: colors.darkGray,
        }}
        text='filters.clearFilters'
      />

    </JitButton>
  );
};
