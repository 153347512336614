import { branchProtectionConfigurationValidator } from './branchProtectionConfigurationValidator';
import { ConfigurationValidator, ZapApiValidator, ZapWebValidator } from './ZapValidators';

import { constants } from 'globalConstants';
import { Vendor } from 'types/enums';

export const validatorsByItemSlug: { [index: string]: ConfigurationValidator } = {
  [constants.PLAN_ITEMS_SLUG.ZAP_WEB]: ZapWebValidator,
  [constants.PLAN_ITEMS_SLUG.ZAP_API]: ZapApiValidator,
  [constants.PLAN_ITEMS_SLUG.GITHUB_BRANCH_PROTECTION]: branchProtectionConfigurationValidator,
  [constants.PLAN_ITEMS_SLUG.GITHUB_BRANCH_SET_CONFIG]: branchProtectionConfigurationValidator,
};

export const validatorsByVendor: { [index: string]: ConfigurationValidator } = {
  [Vendor.ZAP_WEB]: ZapWebValidator,
  [Vendor.ZAP_API]: ZapApiValidator,
};
