import { FC } from 'react';

import { RankBadge } from '../RankBadge/RankBadge';

import { JitText } from 'components/JitText/JitText';
import { RANK_BADGE_COLORS } from 'pages/InsightsPage/SubPages/PerformancePage/components/GraphHeaderComponents/RankBadge/constatnts';
import styles from 'pages/InsightsPage/SubPages/PerformancePage/components/GraphHeaderComponents/RankPopperContent/RankPopperContent.module.scss';
import { IPerformanceRateOption, PerformanceRateRank } from 'types/interfaces/Metrics/PerformanceRateOption';

interface Props {
  performanceRateOptions: IPerformanceRateOption[];
  avgValue: string;
  metricName: string;
  description: string;
  currentRank: PerformanceRateRank;
}

export const RankPopperContent: FC<Props> = ({ performanceRateOptions, avgValue, metricName, description, currentRank }) => (
  <div className={styles.wrapper}>
    <JitText text={metricName} />

    <JitText bold className={styles.avgValueText} size='xl' text={avgValue} />

    <JitText className={styles.description} muted text={description} />

    <div className={styles.optionsWrapper}>
      <JitText className={styles.performanceRate} text='pages.performance.graphs.performanceRateTitle' />

      {performanceRateOptions.map((option) => {
        const isCurrentRank = option.rank === currentRank;
        const borderColor = isCurrentRank ? RANK_BADGE_COLORS[currentRank] : 'transparent';
        return (
          <div key={option.rank} className={styles.option} style={{ borderColor }}>
            <RankBadge rank={option.rank} />

            <JitText text={option.displayValue} />
          </div>
        );
      })}
    </div>

  </div>
);
