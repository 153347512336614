import { FC } from 'react';

import styles from './AppliedFiltersHasNoData.module.scss';

import { JittyAppliedFiltersNoData } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  clearFilters: () => void;
  entityName: string;
}

export const AppliedFiltersHasNoData: FC<Props> = ({ clearFilters, entityName }) => (
  <div data-testid='appliedFilters'>
    <JitIcon
      color='rgba(0, 0, 0, 0)'
      data-testid='appliedFiltersIcon'
      icon={JittyAppliedFiltersNoData}
      iconClassName={styles.jittyAppliedFiltersNoDataIcon}
    />

    <JitText align='center' bold className={styles.titleText} data-testid='appliedFiltersNoData' params={{ entityName }} size='l' text='table.filtersAppliedDoesNotHaveData' />

    <div className={styles.offerTextWrapper}>

      <JitText data-testid='AppliedFiltersHasNoDataMessage' size='m' text='table.tryOtherFilters' />

      <JitButton
        data-testid='clearFiltersButton'
        disableRipple
        noHover
        onClick={clearFilters}
        style={{ padding: 0 }}
      >
        <JitText
          align='center'
          className={styles.clearFiltersButtonText}
          color={colors.blue02}
          data-testid='clearFiltersButtonText'
          size='m'
          text='table.clearFiltersEmptyTableMessage'
        />
      </JitButton>
    </div>
  </div>
);

