import { FC } from 'react';
import { CartesianGrid, Line, LineChart, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';

import { TooltipFormatter } from '..';
import {
  getEmptyLineDataKey,
  getLineDataKey,
  getYAxisTicks,
  graphNameWithData,
  graphNameWithoutData,
  yAxisTicksFormatter,
} from '../../utils';

import { useClosestLineName } from 'pages/InsightsPage/hooks/useClosestLineName';
import {
  commonGridProps,
  commonLineChartProps,
  commonLineProps,
  commonTooltipProps,
  commonXAxisProps,
  commonYAxisProps,
  xAxisDxToPeriod,
} from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/constants';
import { getGraphXAxisDomain } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/utils';
import { Legend } from 'pages/InsightsPage/SubPages/PerformancePage/components/Legend/Legend';
import colors from 'themes/colors.module.scss';
import { TimeAgo } from 'types/enums';
import { Environment, MTTRDay } from 'types/interfaces/Metrics/PerformanceMetrics/MTTR';

interface Props {
  days: MTTRDay[];
  xAxisTicks: number[];
  period: TimeAgo;
}

export const GraphBody: FC<Props> = ({ days, xAxisTicks, period }) => {
  const legendItems = [{
    color: colors.MTTRProdLineColor,
    text: 'pages.performance.graphs.mttr.environments.production',
  }, {
    color: colors.MTTRPreProdLineColor,
    text: 'pages.performance.graphs.mttr.environments.preProduction',
  }];

  const yAxisTicks = getYAxisTicks(days);
  const { closestLineName, onMouseMove } = useClosestLineName(yAxisTicks);

  const allValues = days.flatMap((day) => [day.production?.mttr.value || 0, day.preProduction?.mttr.value || 0]);
  const maxVal = Math.max(...allValues);
  const formattedMaxValue = yAxisTicksFormatter(maxVal);
  const renderEnvLines = (environment: Environment, strokeColor: string) => (
    <>
      <Line
        {...commonLineProps}
        dataKey={getLineDataKey(environment)}
        name={graphNameWithData(environment)}
        stroke={strokeColor}
        {...(closestLineName === graphNameWithData(environment) ? {} : { activeDot: false })}
      />

      <Line
        {...commonLineProps}
        dataKey={getEmptyLineDataKey(days, environment)}
        name={graphNameWithoutData(environment)}
        stroke={strokeColor}
        {...(closestLineName === graphNameWithoutData(environment) ? {} : { activeDot: false })}
      />
    </>
  );
  const getTooltipFormatter = (props: TooltipProps<string, string>) => <TooltipFormatter {...props} closestLineName={closestLineName} />;
  return (
    <div data-testid='mttrGraphBody'>
      <LineChart {...commonLineChartProps} data={days} onMouseMove={onMouseMove}>
        <CartesianGrid {...commonGridProps} />

        {renderEnvLines('production', colors.MTTRProdLineColor)}

        {renderEnvLines('preProduction', colors.MTTRPreProdLineColor)}

        <YAxis {...commonYAxisProps(formattedMaxValue.length)} tickFormatter={yAxisTicksFormatter} />

        <XAxis {...commonXAxisProps} domain={getGraphXAxisDomain(xAxisTicks)} dx={xAxisDxToPeriod[period]} ticks={xAxisTicks} />

        <Tooltip {...commonTooltipProps} content={getTooltipFormatter} />
      </LineChart>

      <Legend legendItems={legendItems} />
    </div>
  );
};
