import { FC } from 'react';

import styles from './SecurityPostureDescription.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  resolvedFindingsCount: number;
  openFindingsCountDisplay: string;
  openFindingsFromCurrentPeriodCountDisplay: string;
  averageMttrString: string;
}

export const SecurityPostureDescription: FC<Props> = ({ resolvedFindingsCount, openFindingsCountDisplay,
  openFindingsFromCurrentPeriodCountDisplay, averageMttrString }) => (
    <div className={styles.wrapper} data-testid='security-posture-description'>
      <JitText
        color={colors.lightGray}
        components={{
          resolvedFindingsCount: <JitText
            bold
            color={colors.performanceGreen}
            display='inline'
            params={{
              resolvedFindingsCount: resolvedFindingsCount.toLocaleString(),
            }}
            size='xxl'
            text='pages.securityImpact.securityPosture.resolvedFindingsCount'
          />,
          openFindingsCount: <JitText
            bold
            color={colors.failRed}
            display='inline'
            params={{ openFindingsCount: openFindingsCountDisplay }}
            size='xxl'
            text='pages.securityImpact.securityPosture.openFindingsCount'
          />,
          openFindingsFromCurrentPeriodCount: <JitText
            bold
            display='inline'
            params={{ openFindingsFromCurrentPeriodCount: openFindingsFromCurrentPeriodCountDisplay }}
            size='xxl'
            text='pages.securityImpact.securityPosture.openFindingsFromCurrentPeriodCount'
          />,
          averageMttrString: <JitText
            bold
            display='inline'
            size='xxl'
            text={averageMttrString}
          />,
          resolutionBold: <JitText
            bold
            color={colors.performanceGreen}
            display='inline'
            size='xxl'
            text='pages.securityImpact.securityPosture.resolutionBold'
          />,
        }}
        size='xxl'
        text='pages.securityImpact.securityPosture.description'
      />
    </div>
);
