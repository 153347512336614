import yaml from 'js-yaml';

import { logError } from 'services/logger/logger';

export const yamlLoad = <T = undefined>(content: string): T | undefined => {
  try {
    return yaml.load(content) as T;
  } catch (e: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
    logError('Error Loading Yaml', { error: e.toString() });
    return undefined;
  }
};

export const yamlDump = (obj: Object | undefined): string => {
  try {
    return yaml.dump(obj);
  } catch (e: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
    logError('Error Dumping Yaml', { error: e.toString() });
    return '';
  }
};
