import { useMemo } from 'react';

import { useSbomContext } from 'context/SbomContext/SbomContext';
import { ActivateSbom } from 'pages/SbomPage/components/SbomTable/ActivateSbom/ActivateSbom';
import { ScanError } from 'pages/SbomPage/components/SbomTable/ScanError/ScanError';
import { SbomReportStatus } from 'types/interfaces/Sbom/SbomReportStatus';

export const useGetEmptyTableProps = () => {
  const { reportStatus } = useSbomContext();
  const emptyTableProps = useMemo(() => {
    const statusToEmptyTableTextMap = {
      [SbomReportStatus.FAILED]: {
        EmptyTableView: ScanError,
      },
      [SbomReportStatus.INACTIVE]: {
        EmptyTableView: ActivateSbom,
      },
      [SbomReportStatus.SCANNING]: {
        emptyTableText: 'pages.sbom.table.scanning.title',
        emptyTableSubtext: 'pages.sbom.table.scanning.description',
      },
      [SbomReportStatus.NO_DATA]: {
        emptyTableText: 'pages.sbom.table.noData.title',
        emptyTableSubtext: 'pages.sbom.table.noData.description',
      },
      [SbomReportStatus.ACTIVE]: {
        emptyTableText: 'pages.sbom.table.noSearchData.title',
        emptyTableSubtext: 'pages.sbom.table.noSearchData.description',
      },
    };
    if (!reportStatus) return {};
    return statusToEmptyTableTextMap[reportStatus];
  }, [reportStatus]);

  return emptyTableProps;
};
