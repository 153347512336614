import styles from 'components/JitDropdown/JitDropdown.module.scss';
import colors from 'themes/colors.module.scss';

export const menuProps = {
  MenuListProps: {
    style: {
      backgroundColor: colors.cards,
      padding: 0,
      maxHeight: 500,
      overflow: 'auto',
    },
  },
  PopoverClasses: {
    paper: styles.paper,
  },
};
