import { ZapAnalyticsEvents } from 'components/Configurations/Zap/components/constants';
import { AnalyticsEvents } from 'components/GithubIntegrationWizard/StepComponents/CreateCentralizedRepo/constants';
import { FileInputAnalyticsEvents } from 'components/JitFileInput/constants';
import { IDictionary } from 'types/interfaces';

export const analyticsEvents: IDictionary<string> = {
  'clicked-navbar-getting-started': 'Navbar Getting Started Clicked',
  'clicked-closed-manage-repos': 'Manage Repos Closed',
  'clicked-save-manage-repos': 'Manage Repos Save Clicked',
  'clicked-commit-dialog-button': 'Commit Plan Clicked',
  'clicked-wizard-activate-plan': 'Wizard Activate Plan Clicked',
  'clicked-wizard-create-new-github-repo': 'Wizard Create Repo Clicked',
  'clicked-wizard-next-create-new-github-repo': 'Wizard Create Repo Next Clicked',
  'clicked-install-github-app': 'Wizard Install Github App Clicked',
  'clicked-error-install-github-app': 'Wizard Github App Error',
  'clicked-wizard-next-install-jit-app': 'Wizard Install Jit App Next Clicked',
  'clicked-sign-up-invitation-form': 'Signup Invitation Clicked',
  'clicked-manage-repos': 'Manage Repos Clicked',
  'clicked-commit-plan': 'Commit Plan Dialog Opened',
  'clicked-plan-matrix-add-to-my-plan': 'Matrix Add Plan',
  'clicked-repository-link': 'Link to GitHub repo Clicked',
  'clicked-pull-request-link': 'Link to GitHub PR Clicked',
  'plan-page-viewed': 'Plan Page Viewed',
  'pipelines-page-viewed': 'Pipelines Page Viewed',
  'findings-page-viewed': 'Findings Page Viewed',
  'matrix-page-viewed': 'Matrix Page Viewed',
  'test-log-page-viewed': 'Test Log Page Viewed',
  'pull-requests-page-viewed': 'Pull Requests Page Viewed',
  'plan-committed': 'Plan Committed',
  'add-items-clicked': 'Add Items Clicked',
  'requirement-activated': 'Requirement Activated',
  'requirement-deactivated': 'Requirement Deactivated',
  'requirement-clicked': 'Requirement Clicked',
  login: 'Login',
  logout: 'Logout',
  'finding-clicked': 'Finding Clicked',
  'items-added': 'Items Added',
  'items-removed': 'Items Removed',
  'wizard-welcome-viewed': 'Wizard Welcome Viewed',
  'wizard-completed-clicked': 'Wizard Completed Clicked',
  'plan-active-viewed': 'Plan Active Viewed',
  'plan-active-dismissed': 'Plan Active Dismissed',
  'introduce-secret-clicked': 'Introduce Secret Clicked',
  'testing-secret-viewed': 'Item Secret Detection',
  'testing-secret-view-github_clicked': 'Testing Secret View Github Clicked',
  'testing-secret-dismissed': 'Testing Secret Dismissed',
  'activation-demo-clicked': 'Activation Demo Clicked',
  'fix-pr-action-clicked': 'Fix PR Action Clicked',
  'branch-protection-action-clicked': 'Branch Protection Action Clicked',
  'jit-repo-detected': 'Jit Repo Detected',
  'ignore-action-clicked': 'Ignore Action Clicked',
  'guidelines-clicked': 'Guidelines Clicked',
  'slack-integration-removed': 'Slack Integration Removed',
  'slack-config-opened': 'Slack Config Opened',
  'slack-channel-selected': 'Slack Channel Selected',
  'slack-channel-disabled': 'Slack Channel Disabled',
  'slack-channel-enabled': 'Slack Channel Enabled',
  'slack-notification-team-preference-updated': 'Slack Notification Team Preference Updated',
  'slack-user-selected': 'Slack User Mapped',
  'action-page-redirection': 'Action Page Redirection',
  [AnalyticsEvents.CentralizedRepoSelected]: 'Centralized Repo Selected',
  [AnalyticsEvents.CentralizedRepoValidationFailed]: 'Centralized Repo Validation Failed',
  [AnalyticsEvents.CentralizedRepoSetRepoClicked]: 'Centralized Repo Set Repo Clicked',
  [AnalyticsEvents.CentralizedRepoReloadClicked]: 'Centralized Repo Reload Clicked',
  [AnalyticsEvents.CentralizedRepoCreateRepoClicked]: 'Centralized Repo Create Repo Clicked',
  [AnalyticsEvents.CentralizedRepoEditAccessClicked]: 'Centralized Repo Edit Access Clicked',
  [FileInputAnalyticsEvents.FileUploadClicked]: 'File Upload Clicked',
  [FileInputAnalyticsEvents.FileDropped]: 'File Dropped',
  [FileInputAnalyticsEvents.FileClearClicked]: 'File Clear Clicked',
  [ZapAnalyticsEvents.ApiFileUploaded]: 'Zap API File Uploaded',
  [ZapAnalyticsEvents.ApiFileInvalidContent]: 'Zap API File Invalid Content Detected',
  [ZapAnalyticsEvents.ApiFileInvalidType]: 'Zap API File Invalid Type Detected',
  'like-ai-bot-answer': 'Like AI Bot Answer',
  'dislike-ai-bot-answer': 'Dislike AI Bot Answer',
};
