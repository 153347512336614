import { FC } from 'react';
import { TooltipProps } from 'recharts';

import { LONG_TERM_CATEGORY_NAME, MID_TERM_CATEGORY_NAME, SHORT_TERM_CATEGORY_NAME } from './constants';
import styles from './ExposureWindowGraph.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { IDictionary } from 'types/interfaces';
import { IExposureWindowDay } from 'types/interfaces/Metrics/PerformanceMetrics/ExposureWindow';
import { formatDate } from 'utils';

interface Props extends TooltipProps<string, string> {
  categoriesDisplayNames: IDictionary<string>;
}

export const TooltipFormatter: FC<Props> = ({ payload, categoriesDisplayNames }) => {
  const hoveredPoint = payload?.[0]?.payload as IExposureWindowDay | undefined;
  if (!hoveredPoint) return null;
  return (
    <div className={styles.tooltipWrapper}>
      <JitText muted size='xs' text={formatDate(hoveredPoint.date, false)} />

      <div key={categoriesDisplayNames[SHORT_TERM_CATEGORY_NAME]} className={styles.tooltipFindingsAmount}>

        <JitText color={colors.exposureWindowShortTermAreaColor} size='s' text={categoriesDisplayNames[SHORT_TERM_CATEGORY_NAME]} />

        <JitText bold size='s' text={hoveredPoint.shortTermFindings} />

      </div>

      <div key={categoriesDisplayNames[MID_TERM_CATEGORY_NAME]} className={styles.tooltipFindingsAmount}>

        <JitText color={colors.exposureWindowMidTermAreaColor} size='s' text={categoriesDisplayNames[MID_TERM_CATEGORY_NAME]} />

        <JitText bold size='s' text={hoveredPoint.midTermFindings} />

      </div>

      <div key={categoriesDisplayNames[LONG_TERM_CATEGORY_NAME]} className={styles.tooltipFindingsAmount}>

        <JitText color={colors.exposureWindowLongTermAreaColor} size='s' text={categoriesDisplayNames[LONG_TERM_CATEGORY_NAME]} />

        <JitText bold size='s' text={hoveredPoint.longTermFindings} />

      </div>

      <div key='pages.performance.graphs.exposureWindow.tooltip.total' className={styles.tooltipFindingsAmount}>

        <JitText size='s' text='pages.performance.graphs.exposureWindow.tooltip.total' />

        <JitText bold size='s' text={hoveredPoint.longTermFindings + hoveredPoint.shortTermFindings + hoveredPoint.midTermFindings} />

      </div>
    </div>
  );
};
