import { isEmpty } from 'lodash';
import { FC } from 'react';

import styles from '../../Graphs.module.scss';
import { GraphEmpty } from '../GraphEmpty/GraphEmpty';
import { GraphLoading } from '../GraphLoading/GraphLoading';

import { GraphBody } from './components/GraphBody/GraphBody';
import { GraphHeader } from './components/GraphHeader/GraphHeader';

import { FindingCountListItem } from 'types/interfaces/Metrics/PieChartCountList/PieChartCountList';

interface Props {
  isLoading: boolean;
  items: FindingCountListItem[];
  title: string;
  description: string;
  onItemClick: (searchValue: string) => void;
  'data-testid'?: string;
}

export const PieChartCountList: FC<Props> = ({ items, isLoading, title, description, onItemClick, ...props }) => {
  const getContent = () => {
    if (isLoading) {
      return <GraphLoading />;
    }

    if (!isEmpty(items) && !isLoading) {
      return <GraphBody items={items} onItemClick={onItemClick} />;
    }

    return (
      <GraphEmpty />
    );
  };

  return (
    <div className={styles.metricWrapper} data-testid={props['data-testid'] || 'pieChartGraph'}>
      <GraphHeader description={description} title={title} />

      {getContent()}
    </div>
  );
};
