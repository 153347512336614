import { createContext, useContext } from 'react';

import { IPriorityFactorsGroup, IPriorityFactor } from 'types/interfaces/Graph/IPriorityFactor';

interface IGraphContext {
  priorityFactorsGroups: IPriorityFactorsGroup[];
  allPriorityFactors: (IPriorityFactor & { group: IPriorityFactorsGroup })[];
  isLoadingPriorityFactors: boolean;
  getPriorityFactors: () => void;
}

export const GraphContext = createContext<IGraphContext>({
  priorityFactorsGroups: [],
  allPriorityFactors: [],
  isLoadingPriorityFactors: false,
  getPriorityFactors: () => {},
});

export const useGraphContext = () => useContext(GraphContext);
