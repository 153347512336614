import { FC } from 'react';
import { PieChart, Pie, Cell, Label } from 'recharts';

import styles from './ScoreGauge.module.scss';

import colors from 'themes/colors.module.scss';
import { calculateGradientColor } from 'utils/functions/calculateGradientColor';

interface Props {
  score: number;
  variant?: 'small' | 'large' | 'xs';
  showAnimation?: boolean;
}

const styleProps = {
  large: {
    height: 200,
    width: 200,
    innerRadius: 70,
    outerRadius: 73,
    strokeWidth: 10,
    textSize: 16,
    verticalAlign: '50%',
    paddingRadius: 4.25,
  },
  small: {
    height: 72,
    width: 72,
    innerRadius: 30,
    outerRadius: 33,
    strokeWidth: 6,
    textSize: 12,
    verticalAlign: undefined,
    paddingRadius: 6.5,
  },
  xs: {
    height: 35,
    width: 35,
    innerRadius: 10,
    outerRadius: 12,
    strokeWidth: 1,
    textSize: 0,
    verticalAlign: '60%',
    paddingRadius: 0,
  },
};

export const ScoreGauge: FC<Props> = ({ score, variant = 'large', showAnimation = true }) => {
  const data = [
    {
      isData: true,
      value: score,
    },
    {
      isData: false,
      value: 1 - score,
    },
  ];

  const endAngle = -25;
  const startAngle = 205;

  const { innerRadius, outerRadius, width, strokeWidth, height, textSize, verticalAlign, paddingRadius } = styleProps[variant];

  const intermediateColor = calculateGradientColor(colors.planStatusGraphColorStart, colors.planStatusGraphColorEnd, score);
  const gradientId = `gradient-${score}}`;
  const chartColor = colors.cardsDivider;

  return (

    <PieChart className={styles.pieWrapper} height={height} width={width}>
      <defs>
        <linearGradient id={gradientId} x1='0' x2='1' y1='0' y2='0'>
          <stop offset='0%' stopColor='#EF5D5D' />

          <stop offset='100%' stopColor={intermediateColor} />
        </linearGradient>
      </defs>

      <Pie
        cy={verticalAlign}
        data={data}
        dataKey='value'
        endAngle={endAngle}
        innerRadius={innerRadius}
        isAnimationActive={showAnimation}
        outerRadius={outerRadius}
        paddingAngle={score === 0 ? 0 : paddingRadius}
        startAngle={startAngle}
      >
        {data.map(({ isData }) => (
          <Cell
            key={`cell-${isData}`}
            fill={isData ? `url(#${gradientId})` : chartColor}
            stroke={isData ? `url(#${gradientId})` : undefined}
            strokeLinejoin='round'
            strokeWidth={isData ? strokeWidth : 1}
          />
        ))}

        {variant === 'large' && (
          <Label
            dy={-15}
            position='center'
            style={{
              fontSize: textSize - 4,
              fill: colors.white,
            }}
            value='Score'
          />
        )}

        <Label
          dy={variant === 'large' ? 5 : 0}
          position='center'
          style={{
            fontSize: textSize,
            fontWeight: 'bold',
            fill: colors.white,
          }}
          value={`${(score * 100).toFixed(0)}%`}
        />
      </Pie>
    </PieChart>
  );
};
