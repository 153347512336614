import { Dispatch, SetStateAction } from 'react';

import { fetchFullFindingsByIds } from 'services/FindingsService';
import { FindingStatus } from 'types/enums';
import { IFindingServer, ServerFindingKeys } from 'types/interfaces';
import {
  IAssetFieldsNew,
  IJobFieldsNew,
  IWorkflowNew,
  IWorkflowFieldsNew,
  IPipelineNew,
} from 'types/interfaces/Pipelines/IPipelineNew';

export const getNewFindingsIdsForPipelineWorkflows = (workflow: IWorkflowNew) => {
  const pipelineFindingsIds = Object.values(workflow)
    .flatMap((workflowValues: IWorkflowFieldsNew) => Object.values(workflowValues.assets)
      .flatMap((asset: IAssetFieldsNew) => Object.values(asset.jobs)
        .flatMap((job: IJobFieldsNew) => job.newFindings)));

  return pipelineFindingsIds;
};

export const getAllFindingsIdsForPipelineWorkflows = (workflow: IWorkflowNew) => {
  const pipelineFindingsIds = Object.values(workflow)
    .flatMap((workflowValues: IWorkflowFieldsNew) => Object.values(workflowValues.assets)
      .flatMap((asset: IAssetFieldsNew) => Object.values(asset.jobs)
        .flatMap((job: IJobFieldsNew) => [...job.newFindings, ...job.existingFindings, ...job.fixedFindings])));

  return pipelineFindingsIds;
};

export const getAllFindingsIdsMapForPipelineWorkFlows = (workflows: IWorkflowNew) => {
  const pipelineFindingsIdsMap = Object.values(workflows)
    .flatMap((workflowValues: IWorkflowFieldsNew) => Object.values(workflowValues.assets)
      .flatMap((asset: IAssetFieldsNew) => Object.values(asset.jobs)
        .flatMap((job: IJobFieldsNew) => ({ newFindings: job.newFindings,
          existingFindings: job.existingFindings,
          fixedFindings: job.fixedFindings }))));

  return pipelineFindingsIdsMap;
};

export const getAllFindingsIdsMapToStatusForPipelineWorkflows = (workflow: IWorkflowNew | undefined) => {
  const res: { [x: string]: FindingStatus; } = {};

  if (!workflow) return res;

  Object.values(workflow)
    .flatMap((workflowValues: IWorkflowFieldsNew) => Object.values(workflowValues.assets)
      .flatMap((asset: IAssetFieldsNew) => Object.values(asset.jobs)
        .forEach((job: IJobFieldsNew) => {
          job.newFindings.forEach((findingId) => {
            res[findingId] = FindingStatus.OPEN;
          });
          job.existingFindings.forEach((findingId) => {
            res[findingId] = FindingStatus.OPEN;
          });
          job.fixedFindings.forEach((findingId) => {
            res[findingId] = FindingStatus.FIXED;
          });
        })));

  return res;
};

// eslint-disable-next-line max-len
export const setPipelineFindings = async (findingsIds: string[], pipeline: IPipelineNew | null, setFindings: Dispatch<SetStateAction<IFindingServer[]>>, setIsFetchingFindings: Dispatch<SetStateAction<boolean>>) => {
  setIsFetchingFindings(true);

  const findingsPromises = fetchFullFindingsByIds(findingsIds)
    .then(
      (findingsForAsset) => {
        if (findingsForAsset) {
          return findingsForAsset;
        }
        return [];
      },
    );

  const findingsLists = await findingsPromises;

  // for each finding set pipeline_resolution attribute according to its status on the pipeline's findings' snapshot
  const allFindingsIdsMapToStatus = getAllFindingsIdsMapToStatusForPipelineWorkflows(pipeline?.workflows);
  const findings = findingsLists.flat().map((finding) => {
    let pipelineResolution;
    if (finding.id in allFindingsIdsMapToStatus) {
      pipelineResolution = allFindingsIdsMapToStatus[finding.id];
    } else {
      pipelineResolution = finding[ServerFindingKeys.status] as FindingStatus;
    }

    return { ...finding,
      [ServerFindingKeys.pipeline_status]: pipelineResolution };
  });

  setFindings(findings);

  setIsFetchingFindings(false);
};

export const getRelevantFindingsIdsForPipelineWorkflows = (pipeline: IPipelineNew) => {
  const shouldExcludeExistingFindings = pipeline.excludeExistingFindings;
  const getRelevantFindingsIds = shouldExcludeExistingFindings ? getNewFindingsIdsForPipelineWorkflows : getAllFindingsIdsForPipelineWorkflows;
  return getRelevantFindingsIds(pipeline.workflows);
};
