import { FC } from 'react';

import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';

interface Props {
  name: string;
}
export const SnapshotName: FC<Props> = ({ name }) => (
  <div style={
    {
      width: '150px',
    }
  }
  >
    <TooltipOnlyOnOverflow bold data-testid={name} size='m' text={name} />
  </div>
);
