import { FC } from 'react';

import { OpenFindingsGraph } from './components/OpenFindingsGraph/OpenFindingsGraph';
import styles from './OpenFindings.module.scss';

import { GraphLoading, GraphEmpty } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/components';
import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { ITotalFindingsGraph } from 'types/interfaces/Metrics/SecurityImpactMetrics/TotalFindingsGraph';

export interface OpenFindingsProps {
  totalFindingsGraphMetric: MetricWithLoadingIndication<ITotalFindingsGraph>
}

export const OpenFindings: FC<OpenFindingsProps> = ({ totalFindingsGraphMetric }) => {
  const getContent = () => {
    if (totalFindingsGraphMetric.isLoading) {
      return <GraphLoading />;
    }
    const totalFindingsGraph = totalFindingsGraphMetric.metric;

    if (totalFindingsGraph) {
      return (
        <div className={styles.content}>

          <OpenFindingsGraph data-testid='openFindingsGraph' totalFindingsGraph={totalFindingsGraph} />
        </div>
      );
    }

    return <GraphEmpty />;
  };

  const content = getContent();
  return (
    <div className={styles.wrapper}>
      {content}
    </div>
  );
};
