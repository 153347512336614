import { FC, useEffect, useState, useCallback } from 'react';

import { TABLE_ROW_HEIGHT } from './constants';

import { JittyNotFound, Search } from 'assets';
import { useGetTableInstance } from 'components/JitTable/hooks/useGetTableInstance';
import { JitTable } from 'components/JitTable/JitTable';
import { JitTextInput } from 'components/JitTextInput/JitTextInput';
import { useSbomContext } from 'context/SbomContext/SbomContext';
import { PaywallGradiant } from 'pages/SbomPage/components/SbomTable/PaywallGradiant/PaywallGradiant';
import styles from 'pages/SbomPage/components/SbomTable/SbomTable.module.scss';
import { useGetColumns } from 'pages/SbomPage/components/SbomTable/useGetColumns';
import { useGetEmptyTableProps } from 'pages/SbomPage/components/SbomTable/useGetEmptyTableProps';
import { SbomReportStatus } from 'types/interfaces/Sbom/SbomReportStatus';

export const SbomTable: FC = () => {
  const {
    isPremiumFeatureEnabled, isLoadingReportStatus, reportStatus, getSbomComponents, sbomComponents: { hasReachedEnd, isLoading, data },
  } = useSbomContext();
  const [tableContainer, setTableContainer] = useState<HTMLDivElement | null>(null);
  const [hasFetchedSbomComponents, setHasFetchedSbomComponents] = useState<boolean>(false);
  const emptyTableProps = useGetEmptyTableProps();

  const [searchValue, setSearchValue] = useState('');
  const isSbomActive = reportStatus === SbomReportStatus.ACTIVE;

  const fetchDataOnSearchChange = useCallback(() => {
    const canDoSearch = isSbomActive && isPremiumFeatureEnabled && hasFetchedSbomComponents;
    if (!canDoSearch) return;

    if (tableContainer) tableContainer.scrollTop = 0; // Scroll table to top
    getSbomComponents({
      resetPagination: true,
      searchValue,
    });

    // This fetch should happen only when search value changes:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    fetchDataOnSearchChange();
  }, [fetchDataOnSearchChange]);

  useEffect(() => {
    // First fetch of sbom components
    if (isSbomActive && !hasFetchedSbomComponents) {
      getSbomComponents({
        resetPagination: true,
        searchValue: '',
      });
      setHasFetchedSbomComponents(true);
    }
  }, [reportStatus, getSbomComponents, hasFetchedSbomComponents, isSbomActive]);

  const handleReachScrollEnd = () => {
    if (isLoading || hasReachedEnd || !isPremiumFeatureEnabled) return;

    getSbomComponents({
      resetPagination: false,
      searchValue,
    });
  };

  const onScroll = (event: React.UIEvent<HTMLDivElement>) => {
    setTableContainer(event.currentTarget);
  };

  const columns = useGetColumns();
  const tableInstance = useGetTableInstance(columns, data);
  return (
    <div className={styles.wrapper}>
      <div style={{ width: 400 }}>
        <JitTextInput
          debounceDelay={250}
          defaultValue={searchValue}
          fullWidth
          icon={Search}
          onChange={setSearchValue}
          placeholder='pages.sbom.table.searchPlaceHolder'
        />
      </div>

      <div className={styles.tableWrapper}>

        <JitTable
          cellVerticalAlign='top'
          enableScrolling={isPremiumFeatureEnabled}
          handleReachScrollEnd={{
            threshold: TABLE_ROW_HEIGHT,
            callback: handleReachScrollEnd,
          }}
          handleScroll={onScroll}
          isFetching={isLoading || isLoadingReportStatus}
          isLoading={(isLoading && !data.length) || isLoadingReportStatus}
          jittyIcon={JittyNotFound}
          showPagination={false}
          tableInstance={tableInstance}
          {...emptyTableProps}
        />

        {!isPremiumFeatureEnabled && isSbomActive && (<PaywallGradiant />)}

      </div>
    </div>
  );
};
