import { FC } from 'react';

import { Description } from '../../../../../GraphHeaderComponents';
import { Title } from '../../../../../GraphHeaderComponents/Title/Title';

import styles from './GraphHeader.module.scss';

interface Props {
  title: string;
  description: string;
}

export const GraphHeader: FC<Props> = ({ title, description }) => (
  <div
    className={styles.wrapper}
    data-testid='PieChartGraphHeader'
  >
    <div>
      <Title title={title} />

      <Description description={description} />
    </div>
  </div>
);
