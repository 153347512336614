import { FC, ReactNode } from 'react';

import styles from './GitHubCard.module.scss';

import { GithubIcon } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';

interface Props {
  shouldShowIcon?: boolean;
  children: ReactNode;
}

export const GitHubCard: FC<Props> = ({ children, shouldShowIcon = true }) => (
  <div className={styles.card} data-testid='GitHubCard'>
    <div className={styles.cardContent}>
      {shouldShowIcon && <JitIcon centered={false} icon={GithubIcon} size={35} />}

      {children}

    </div>
  </div>
);

