import { isEmpty } from 'lodash';
import { ChangeEvent, FC, useCallback, useState } from 'react';

import { ActionSuggestedFixDialog } from '../ActionSuggestedFixDialog/ActionSuggestedFixDialog';

import styles from './ExtendedActionFindingsList.module.scss';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { JitButton } from 'components/JitButton/JitButton';
import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import { JitText } from 'components/JitText/JitText';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { TicketsList } from 'components/TicketsList/TicketsList';
import colors from 'themes/colors.module.scss';
import { IActionFinding, TicketFinding, TicketFindingServer } from 'types/interfaces';
import { stopPropagation } from 'utils';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

interface ExtendedFindingsListProps {
  findings: IActionFinding[]
  selectedFindings: string[]
  generateTitle: (finding: IActionFinding) => string,
  generateSubtitle?: (finding: IActionFinding) => string,
  handleCheckAllFindings: (event: ChangeEvent<HTMLInputElement>, checked: Boolean) => void
  handleCheckFinding: (event: ChangeEvent<HTMLInputElement>, checked: Boolean) => void
  handleScroll: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void
  isLoading: boolean
}

export const ExtendedActionFindingsList: FC<ExtendedFindingsListProps> = ({
  findings, selectedFindings, generateTitle, generateSubtitle, handleCheckAllFindings, handleCheckFinding, handleScroll, isLoading,
}) => {
  const isCheckBoxChecked = useCallback((id: string) => selectedFindings.includes(id), [selectedFindings]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedFinding, setSelectedFinding] = useState<IActionFinding | null>(null);

  const handleOpenDialog = (finding: IActionFinding) => {
    setSelectedFinding(finding);
    setIsDialogOpen(true);
  };

  if (isLoading && !findings.length) {
    return (
      <div className={styles.loadingSpinner}>
        <CircularLoadingSpinner />
      </div>
    );
  }

  const sortTickets = (tickets: TicketFindingServer[]) => {
    const parsedTickets = tickets.map((ticket) => camelizeSnakeCaseKeys(ticket)) as TicketFinding[];

    return parsedTickets.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  };

  return (
    <div className={styles.expandedCardContent} data-testid='actionCardFindingsList' onClick={stopPropagation} role='button' tabIndex={0}>
      <div className={styles.itemWrapper} style={{ marginLeft: '15px' }}>
        <JitCheckbox
          checked={selectedFindings.length === findings.length}
          data-testid='selectAllCheckBox'
          onChange={handleCheckAllFindings}
          onClick={stopPropagation}

        />

        <JitText
          color={colors.white}
          data-testid='selectAllText'
          params={{ selectedFindings: selectedFindings.length }}
          size='m'
          text='pages.actions.actionCard.selectedFindings'
        />
      </div>

      <div className={styles.findingsList} onScroll={handleScroll}>
        {findings.map((finding) => (
          <div key={finding.id} className={styles.findingWrapper}>
            <JitCheckbox
              checked={isCheckBoxChecked(finding.id)}
              data-testid={`finding-${finding.id}-checkbox`}
              id={finding.id}
              onChange={handleCheckFinding}
              onClick={stopPropagation}
              style={{ marginLeft: '15px' }}
            />

            <a className={styles.occurrenceLink} href={finding.location}>
              <JitText
                addUnderlineOnHover={!!finding.location}
                color={colors.white}
                data-testid={`finding-${finding.id}-path`}
                size='m'
                text={generateTitle(finding)}
              />
            </a>

            {generateSubtitle && <JitText color={colors.gray} data-testid={`finding-${finding.id}-lines`} size='m' text={generateSubtitle(finding)} />}

            {finding.pendingForPrUrl && (
              <div className={styles.linkWrapper} data-testid='creatingPRIndication'>
                <JitText className={styles.openingPR} color={colors.gray} size='m' text='pages.actions.actionCard.findingsList.openingPr' />

                <JitText className={styles.dotFlashing} color={colors.gray} size='m' text=' ' />
              </div>
            )}

            {!finding.pr_url && !isEmpty(finding.tickets) && !finding.pendingForPrUrl && (
              <div className={styles.linkWrapper}>
                <TicketsList tickets={sortTickets(finding.tickets!)} />
              </div>
            )}

            {finding.pr_url && !finding.pendingForPrUrl && (
              <a className={styles.linkWrapper} data-testid='prLink' href={finding.pr_url} onClick={() => {}} rel='noreferrer' target='_blank'>
                <JitText color={colors.iris} data-testid='prLinkText' size='m' text='pages.actions.actionCard.viewFixPR' />
              </a>
            )}

            {finding.fix_suggestion.code_fixes && (
              <div className={styles.linkWrapper}>
                <JitButton
                  data-testid='view-suggested-fix-button'
                  disableRipple
                  noHover
                  onClick={() => handleOpenDialog(finding)}
                >
                  <JitText color={colors.iris} data-testid='viewSuggestedFixText' size='m' text='View Suggested Fix' />
                </JitButton>
              </div>
            )}

          </div>
        ))}

        {isLoading && <LoadingBar sx={{ w: 1 }} />}
      </div>

      {selectedFinding && (
        <ActionSuggestedFixDialog
          fixSuggestion={selectedFinding.fix_suggestion}
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
        />
      )}
    </div>
  );
};
