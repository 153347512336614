import { IExecution, IExecutionServer } from 'types/interfaces';

const parseExecution = (execution: IExecutionServer): IExecution => {
  const { run_id: id } = execution;
  return ({
    id,
    ...execution,
  });
};

export const parseExecutions = (data: IExecutionServer[]): IExecution[] => data.map((execution) => parseExecution(execution));
