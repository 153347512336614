import { FC } from 'react';

import styles from './TooltipFormatter.module.scss';

import { JitGrade } from 'components/JitGrade/JitGrade';
import { JitText } from 'components/JitText/JitText';

type Props = {
  label?: string;
  value?: number;
};

export const ScoreTooltipFormatter: FC<Props> = ({ label, value }) => {
  if (!label || value === undefined || value === null) return null;

  return (
    <div className={styles.tooltipWrapper}>
      <JitText muted size='s' text={label} />

      <JitGrade score={value} size='s' />
    </div>
  );
};
