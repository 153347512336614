import { IActionFinding, IGithubBranchProtectionAdditionalData } from 'types/interfaces';

/* eslint-disable */
const scriptBase = `#!/bin/bash

set -e
echo "Checking for relevant executables"

# Perform sanity check for executables and provided params
if ! [ -x "$(command -v jq)" ]; then
  echo -e "Error: 'jq' is not installed. \nThis script requires the 'jq' and 'curl' commands to execute properly. Please make sure they are installed on your system and try again." >&2
  exit 1
fi

if ! [ -x "$(command -v curl)" ]; then
  echo -e "Error: 'curl' is not installed. \nThis script requires the 'jq' and 'curl' commands to execute properly. Please make sure they are installed on your system and try again." >&2
  exit 1
fi

if (( $# == 0 )); then
    echo "Github auth flag not provided. This script must run with the following param: 'GithubUser:GithubToken', with your Github username and a Github access token."
    exit 1
fi


# Ensure that the Github token is valid

if [[ $1 != *":"* ]];then
    echo "Provided Github auth parameter does not contain an access token. The structure should be as follows: 'GithubUser:GithubToken'"
    exit 1
fi

status_code=$(curl --silent --output /dev/null --write-out "%{http_code}" --user "$1" -H "Accept: application/vnd.github+json" https://api.github.com/repos/jonathanTest9876/test-github)
if [[ $status_code != 200 ]]; then
    echo -e "Could not fetch branch protection data with the provided Github user. Please make sure you provided the correct user and access token.\nThe structure should be as follows: GithubUser:AccessToken"
    exit 1
fi

provided_arg=$1
github_username=\${provided_arg%:*}
echo -e "GitHub requests will use the $github_username user with the provided access token.\\n"
echo "Start declaring branch protection configurations"

echo "Start declaring branch protection configurations"
$curls`;
/* eslint-enable */

const generateUpdatedPullRequestConfig = (updatedConfig: object, url: string): string => `
new_config='${JSON.stringify(updatedConfig)}'
current_config=$(curl --user "$1" -H "Accept: application/vnd.github+json" ${url})
# Put current config to request_json

request_json="{}"

current_pr_reviews_config=$( echo "$current_config" | jq '.required_pull_request_reviews')
current_status_checks_config=$( echo "$current_config" | jq '.required_status_checks')
request_json=$(echo "$request_json" "{\\"required_pull_request_reviews\\": $current_pr_reviews_config}" | jq -s add)
request_json=$(echo "$request_json" "{\\"required_status_checks\\": $current_status_checks_config}" | jq -s add)

current_bool_params="{
\\"required_linear_history\\": $(echo "$current_config" | jq '.required_linear_history.enabled'),
\\"allow_force_pushes\\": $(echo "$current_config" | jq '.allow_force_pushes.enabled'),
\\"allow_deletions\\": $(echo "$current_config" | jq '.allow_deletions.enabled'),
\\"block_creations\\": $(echo "$current_config" | jq '.block_creations.enabled'),
\\"required_conversation_resolution\\": $(echo "$current_config" | jq '.required_conversation_resolution.enabled'),
\\"restrictions\\": $(echo "$current_config" | jq '.restrictions'),
\\"enforce_admins\\": $(echo "$current_config" | jq '.enforce_admins.enabled')
}"

request_json=$(echo "$request_json" "$current_bool_params" | jq -s '.[0] * .[1]')


# Adding relevant updates
request_json=$(echo "$request_json" | jq 'del(.required_status_checks.checks)')
request_json=$(echo "$request_json" "$new_config" | jq -s '.[0] * .[1]')`;

export const generateFixScriptForAmountOfApprovers = (findings: IActionFinding[], selectedFindingsIds: string[]): string => {
  const commands: string[] = [];

  findings.forEach((finding) => {
    if (!selectedFindingsIds.includes(finding.id)) return;
    const fixSuggestion = finding.fix_suggestion;
    const additionalData = fixSuggestion?.additional_data as IGithubBranchProtectionAdditionalData;
    if (!additionalData) return;
    const repository = `${additionalData.organization}/${additionalData.repository}/branches/${additionalData.branch}`;
    const url = `https://api.github.com/repos/${repository}/protection`;
    commands.push('\n');
    commands.push(generateUpdatedPullRequestConfig({ required_pull_request_reviews: additionalData!.required_pull_request_reviews }, url));
    commands.push(`curl \\\n\t--user "$1" \\\n\t--header "Accept: application/vnd.github.v3+json" \\\n\t--request PUT \\\n\t${url} \\\n\t--data "\${request_json}"`);
    commands.push(`echo "Declared branch protection configurations on ${repository}"`);
  });
  return scriptBase.replace('$curls', commands.join('\n'));
};

export const generateFixScriptForRequiredStatusChecks = (findings: IActionFinding[], selectedFindingsIds: string[]): string => {
  const commands: string[] = [];

  findings.forEach((finding) => {
    if (!selectedFindingsIds.includes(finding.id)) return;
    const fixSuggestion = finding.fix_suggestion;
    const additionalData = fixSuggestion?.additional_data as IGithubBranchProtectionAdditionalData;
    if (!additionalData) return;
    const repository = `${additionalData.organization}/${additionalData.repository}/branches/${additionalData.branch}`;
    const url = `https://api.github.com/repos/${repository}/protection`;
    commands.push('\n');
    commands.push(generateUpdatedPullRequestConfig({ required_status_checks: additionalData!.required_status_checks }, url));
    commands.push(`curl \\\n\t--user "$1" \\\n\t--header "Accept: application/vnd.github.v3+json" \\\n\t--request PUT \\\n\t${url} \\\n\t--data "\${request_json}"`);
    commands.push(`echo "Declared branch protection configurations on ${repository}"`);
  });
  return scriptBase.replace('$curls', commands.join('\n'));
};

export const downloadScript = (scriptText: string, filename: string) => {
  const blob = new Blob([scriptText], { type: 'text/plain' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
};
