/* eslint-disable no-nested-ternary */
import { FC, useMemo } from 'react';

import styles from './Actions.module.scss';
import { ActionCard } from './components/ActionCard/ActionCard';
import { EmptyActionCard } from './components/EmptyActionCard/EmptyActionCard';

import { GraphLoading } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/components';
import { IAction, IConcealedAction } from 'types/interfaces';

interface Props {
  actions: (IAction | IConcealedAction)[];
  count: number;
  teamName?: string;
  isLoading: boolean;
}

export const Actions: FC<Props> = ({ actions, count, teamName, isLoading }) => {
  const notConcealedActions = useMemo(() => actions.filter(({ is_concealed }) => !is_concealed) as IAction[], [actions]);

  return (
    isLoading ? (
      <div className={styles.loaderWrapper}>
        <GraphLoading />
      </div>
    ) : !actions.length ? <EmptyActionCard /> : (
      <ActionCard
        action={notConcealedActions.reduce((prev, current) => ((prev.created_at > current.created_at) ? prev : current))}
        allActions={count}
        teamName={teamName}
      />
    )
  );
};
