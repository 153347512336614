import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC } from 'react';
import { HeaderGroup } from 'react-table';

import colors from 'themes/colors.module.scss';
import { hexColorToRGBA } from 'utils/functions/hexColorToRGBA';

interface Props {
  headerGroups: HeaderGroup<object>[];
  cellPadding?: string;
}

export const JitTableHeader: FC<Props> = ({ headerGroups, cellPadding = '16px' }) => {
  const styles = {
    backgroundColor: hexColorToRGBA(colors.cards),
    borderBottom: 0,
    topBorderRadius: 6,
    bottomBorderRadius: 0,
  };

  return (
    <TableHead>
      {headerGroups?.map((headerGroup) => {
        const headerGroupProps = headerGroup.getHeaderGroupProps();
        const { headers } = headerGroup;
        return (
          <TableRow
            {...headerGroupProps}
            key={headers?.toString()}
            sx={{
              th: {
                borderBottom: styles.borderBottom,
                backgroundColor: styles.backgroundColor,
                '&:first-of-type': {
                  borderTopLeftRadius: styles.topBorderRadius,
                  borderBottomLeftRadius: styles.bottomBorderRadius,
                },
                '&:last-of-type': {
                  borderTopRightRadius: styles.topBorderRadius,
                  borderBottomRightRadius: styles.bottomBorderRadius,
                },
              },
            }}
          >
            {headers?.map((column) => {
              const columnProps = column.getHeaderProps();
              return (
                <TableCell
                  {...columnProps}
                  key={column.id}
                  sx={{
                    padding: cellPadding,
                    minWidth: column.minWidth,
                    width: column.width,
                  }}
                >
                  {column.render('Header')}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableHead>
  );
};
