import { FC } from 'react';

import styles from './CounterCard.module.scss';

import { JitText } from 'components/JitText/JitText';
import { PercentageChip, PercentageChipProperty } from 'components/PercentageChip/PercentageChip';

interface Count {
  count: number;
  color: string;
}

interface Props {
  count: Count;
  percentageChip: PercentageChipProperty;
}

export const CounterCard: FC<Props> = ({ count, percentageChip }) => (
  <div className={styles.wrapper}>
    <JitText bold color={count.color} size='huge' text={count.count} />

    <PercentageChip bgColor={percentageChip.bgColor} percentage={percentageChip.percentage} positiveTrendIndicatorDirection={percentageChip.positiveTrendIndicatorDirection} />
  </div>
);
