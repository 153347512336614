import { FC, useState } from 'react';

import { NotificationError } from 'assets';
import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { constants } from 'globalConstants';
import { useGetRerunButton } from 'pages/PlanPage/components/PlanItemDetails/components/PlanItemDetailsBody/components/RerunButton/useGetRerunButton';
import { EmptyStateTemplate } from 'pages/SbomPage/components/SbomTable/EmptyStateTemplate/EmptyStateTemplate';
import colors from 'themes/colors.module.scss';
import { ActionButtonVariant } from 'types/interfaces';

export const ScanError: FC = () => {
  const [hasTriggerSent, setHasTriggerSent] = useState(false);
  const rerunButtonProps = useGetRerunButton({
    itemSlug: constants.PLAN_ITEMS_SLUG.SBOM,
    // Once the status will be updated the button will be disabled
    onTriggerSend: () => { setHasTriggerSent(true); },
    variant: ActionButtonVariant.PRIMARY,
    isLoadingOverride: hasTriggerSent,
  });
  return (
    <EmptyStateTemplate
      buttonComponent={(
        <JitActionButton actionButton={rerunButtonProps} />
    )}
      description='pages.sbom.table.scanError.description'
      title='pages.sbom.table.scanError.title'
      titleIcon={<JitIcon color={colors.white} icon={NotificationError} size={16} />}
    />
  );
};
