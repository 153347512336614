import { FC } from 'react';

import { JitText } from 'components/JitText/JitText';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { ITableCell } from 'types/interfaces';

export const FindingNameCell: FC<ITableCell> = ({ cell: { value } }) => (
  <TooltipOnlyOnOverflow tooltipText={value.name}>
    <JitText
      bold
      noWrap
      overflowHiddenEllipsis
      size='m'
      style={{ marginLeft: '30px' }}
      text={value.name}
    />
  </TooltipOnlyOnOverflow>
);
