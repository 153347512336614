import { FC } from 'react';

import styles from './PageTitles.module.scss';

import { JitText } from 'components/JitText/JitText';

interface Props {
  title: string,
  shortTitle?: string,
  subtitle?: string,
  description?: string,
}
export const PageTitles: FC<Props> = ({
  title,
  shortTitle,
  subtitle,
  description,
}) => (
  <div className={styles.wrapper}>
    <div className={styles.title}>
      <JitText bold data-testid='PageTitles-title' size='xxl' text={title} />

      <JitText data-testid='PageTitles-shortTitle' size='xxl' text={shortTitle} />
    </div>

    <JitText data-testid='PageTitles-subtitle' text={subtitle} />

    <JitText data-testid='PageTitles-description' muted text={description} />
  </div>
);
