import { CircleFailure, TriangleWarning, WhiteDotCheck } from 'assets';
import colors from 'themes/colors.module.scss';
import { IntegrationStatus } from 'types/interfaces';

export const AWS_ERROR_LEARN_MORE_LINK = 'https://docs.jit.io/docs/troubleshooting-aws-integration';
export const AWS_GENERAL_LEARN_MORE_LINK = 'https://docs.jit.io/docs/integrating-with-aws';
export const AWS_GENERAL_PERMISSION_LINK = `${AWS_GENERAL_LEARN_MORE_LINK}#permissions`;
export const GCP_GENERAL_LEARN_MORE_LINK = 'https://docs.jit.io/docs/integrating-with-gcp';
export const GCP_GENERAL_PERMISSION_LINK = `${GCP_GENERAL_LEARN_MORE_LINK}#permissions`;
export const AZURE_GENERAL_LEARN_MORE_LINK = 'https://docs.jit.io/docs/integrating-with-azure';
export const AZURE_GENERAL_PERMISSION_LINK = `${AZURE_GENERAL_LEARN_MORE_LINK}#permissions`;
export const GITHUB_GENERAL_LEARN_MORE_LINK = 'https://docs.jit.io/docs/integrating-with-github';
export const GITHUB_GENERAL_PERMISSION_LINK = `${GITHUB_GENERAL_LEARN_MORE_LINK}#requirements`;

export const StatusIndicationIconProps = {
  [IntegrationStatus.ERROR]: CircleFailure,
  [IntegrationStatus.WARNING]: TriangleWarning,
  [IntegrationStatus.CONNECTED]: WhiteDotCheck,
};

export const CardStatusToBorderColor = {
  [IntegrationStatus.ERROR]: colors.failRed,
  [IntegrationStatus.WARNING]: colors.alert,
};
