import { LABELS_COLORS } from 'pages/SecurityPlansPage/components/SecurityPlansBody/constants';

export const TAG_NAMES_TO_HIDE = ['team'];

export const calcTagColor = (tag: string) => {
  const optionsAmount = LABELS_COLORS.length;
  const tagChecksum = tag.split('')
    .reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return LABELS_COLORS[tagChecksum % optionsAmount];
};
