import { FC } from 'react';

import styles from './PeriodDropdown.module.scss';

import { JitDropdownNew } from 'components/JitDropdownNew/JitDropdownNew';
import { i18n } from 'locale/i18n';
import { TimeAgo } from 'types/enums';

interface Props {
  period: TimeAgo;
  handlePeriodChange: (period: TimeAgo) => void;
  snapshotPeriod?: string;
}

export const PeriodDropdown: FC<Props> = ({ period, handlePeriodChange, snapshotPeriod }) => {
  const periodOptions = [
    TimeAgo.OneWeek,
    TimeAgo.TwoWeeks,
    TimeAgo.OneMonth,
    TimeAgo.ThreeMonths,
  ].map((periodOption) => ({
    itemKey: periodOption,
    itemName: `pages.performance.periodsOptions.${periodOption}`,
    isSelected: !snapshotPeriod && (period === periodOption),
  }));

  return (
    <div className={styles.dropdownWrapper}>
      <JitDropdownNew
        displayText={snapshotPeriod || i18n.t(`pages.performance.periodsOptions.${period}`)}
        menuItems={periodOptions}
        onMenuItemClick={({ itemKey }) => handlePeriodChange(itemKey as TimeAgo)}
        width={snapshotPeriod ? 270 : 200}
      />
    </div>
  );
};
