
import { FC } from 'react';

import { WelcomePageHeader } from '../WelcomePageHeader/WelcomePageHeader';

import styles from './EmptyState.module.scss';

import { OpenInNewTab } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import { ShapesBackground } from 'components/ShapesBackground/ShapesBackground';
import { config } from 'config';
import colors from 'themes/colors.module.scss';

export const EmptyState: FC = () => (
  <div className={styles.emptyStateWrapper}>
    <div>
      <WelcomePageHeader isEmpty />
    </div>

    <ShapesBackground />

    <JitLink
      className={styles.link}
      data-testid='link'
      href={config.docsTeamsSync}
      style={{ textDecoration: 'none' }}
      target='_blank'
    >
      <div className={styles.linkContent}>
        <JitText color={colors.iris} data-testid='link-text' text='pages.teamsPortal.welcomePage.emptyState.linkText' />

        <JitIcon color={colors.iris} data-testid='link-icon' icon={OpenInNewTab} size={13} />
      </div>
    </JitLink>
  </div>
);
