import { startCase } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import styles from './FindingsFiltersBox.module.scss';

import { Cross } from 'assets';
import { AssetType } from 'components/AssetType/AssetType';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitMenu, MenuItemType } from 'components/JitMenu/JitMenu';
import { JitText } from 'components/JitText/JitText';
import { useFindingsContext } from 'context/FindingsContext/FindingsContext';
import { useUpdateSingleFilter } from 'context/FindingsContext/hooks/useUpdateSingleFilter';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { AssetType as AssetTypeEnum } from 'types/enums';
import { IFindingFilter, IFindingFilterType, MenuItemKeyType, ServerFindingKeys, TIME_AGO_FILTER_KEY } from 'types/interfaces';
import { useAssetTypeToVendor } from 'utils/hooks/useAssetTypeToVendor';
import { useGetFormatControlName } from 'utils/hooks/useGetFormatControlName';

interface Props {
  filter: IFindingFilter
  shouldInitiallyBeOpen: boolean;
  onMenuItemClickAction: () => void;
}

export const FindingsFiltersBox: FC<Props> = ({ filter, shouldInitiallyBeOpen, onMenuItemClickAction }) => {
  const { updateSingleFilter } = useUpdateSingleFilter();
  const { t } = i18n;
  const { getVendorByAssetType } = useAssetTypeToVendor();

  const { getFormattedControlName } = useGetFormatControlName();

  const { filtersVisibleInFirstLine } = useFindingsContext();

  const [shouldRunOnMenuAdd, setShouldRunOnMenuAdd] = useState(false);

  const isMultiSelect = useMemo(() => filter.type === IFindingFilterType.MULTI_SELECT, [filter]);
  const isSingleSelect = useMemo(() => filter.type === IFindingFilterType.SINGLE_SELECT, [filter]);

  const isMultiSelectFilterFullySelected = useMemo(() => Array.isArray(filter.selectedValue)
    && (filter.selectedValue.length === 0 || filter.selectedValue.length === filter.valueOptions?.length), [filter]);

  const isAllValuesSelected = (isMultiSelect && isMultiSelectFilterFullySelected) || (isSingleSelect && !filter.selectedValue);

  const formatFilterValue = useCallback((filterValue: string) => {
    if (filter.key === TIME_AGO_FILTER_KEY) {
      return t(`pages.findings.filtersSection.filterValues.date.${filterValue}`);
    }
    if (filter.key === ServerFindingKeys.asset_type) {
      return startCase(filterValue.toLowerCase().replace('aws_', ''));
    }
    if (filter.key === ServerFindingKeys.control_name) {
      return getFormattedControlName(filterValue);
    }
    if (filter.key === ServerFindingKeys.plan_layer) {
      return t(`layer.${filterValue}`);
    }
    if ([ServerFindingKeys.status,
      ServerFindingKeys.ignored,
      ServerFindingKeys.issue_severity,
      ServerFindingKeys.vulnerability_type].includes(filter.key as ServerFindingKeys)) {
      return startCase(filterValue?.toString().toLowerCase());
    }
    return filterValue;
  }, [t, getFormattedControlName, filter.key]);
  const formattedSelectedValue = useMemo(() => {
    // Don't display "All" if there is only one value selected
    if (Array.isArray(filter.selectedValue) && filter.selectedValue.length === 1) {
      return filter.selectedValue;
    }
    if (isAllValuesSelected) {
      return 'all';
    }
    if (isMultiSelect && Array.isArray(filter.selectedValue)) {
      // We need to check if the selected value is an array to prevent a white screen in case
      // isMultiSelect is true but the selectedValue is not an array.
      return (filter.selectedValue as string[]).map(formatFilterValue).join(', ');
    }
    return formatFilterValue(filter.selectedValue as string);
  }, [filter.selectedValue, isMultiSelect, isAllValuesSelected, formatFilterValue]);

  const getFilterValueOptionDisplay = (filterValue: string) => {
    if (filter.key === ServerFindingKeys.asset_type) {
      return <AssetType assetName={filterValue} vendor={getVendorByAssetType(filterValue as AssetTypeEnum)} />;
    }

    const displayText = formatFilterValue(filterValue);
    return (
      <JitText
        horizontalAlign='center'
        noWrap
        overflowHiddenEllipsis
        text={displayText}
      />
    );
  };

  useEffect(() => {
    const isFilterVisible = filtersVisibleInFirstLine.find((curFilter) => curFilter.key === filter.key);
    if (shouldRunOnMenuAdd && !isFilterVisible) {
      onMenuItemClickAction();
      setShouldRunOnMenuAdd(false);
    }
  }, [shouldRunOnMenuAdd, filtersVisibleInFirstLine, filter.key, onMenuItemClickAction]);

  const handleCheckboxClick = (filterValue: MenuItemKeyType) => {
    let updatedSelectedValue = filter.selectedValue;
    if (Array.isArray(filter.selectedValue) && filter.selectedValue.includes(filterValue)) {
      updatedSelectedValue = (updatedSelectedValue as string[]).filter((option) => option !== filterValue);
    } else {
      updatedSelectedValue = [...(updatedSelectedValue as string[]), filterValue];
    }
    const updatedFilter: IFindingFilter = {
      ...filter,
      selectedValue: updatedSelectedValue,
    };

    updateSingleFilter(updatedFilter);
  };

  const handleRadioButtonClick = (filterValue: MenuItemKeyType) => {
    const updatedFilter: IFindingFilter = {
      ...filter,
      selectedValue: filterValue,
    };
    updateSingleFilter(updatedFilter);
  };

  const filterKeysText = `${t(`pages.findings.filtersSection.filterKeys.${filter.key}`)}:`;

  const menuItems: MenuItemType[] = filter.valueOptions?.map((filterValueOption) => ({
    itemKey: filterValueOption,
    itemContent: getFilterValueOptionDisplay(filterValueOption.toString()),
    searchValue: filterValueOption.toString(),
    isSelected: isMultiSelect
      ? (Array.isArray(filter.selectedValue) && filter.selectedValue.includes(filterValueOption))
      : filter.selectedValue === filterValueOption,
  }));

  const removeFilterVisibility = () => {
    const updatedFilter: IFindingFilter = {
      ...filter,
      isVisible: false,
      selectedValue: filter.defaultValue,
    };
    updateSingleFilter(updatedFilter);
  };

  const onMenuItemClick = ({ itemKey: filterValue }: { itemKey: MenuItemKeyType }) => {
    if (isMultiSelect) {
      handleCheckboxClick(filterValue);
    } else if (filter.type === IFindingFilterType.SINGLE_SELECT) {
      handleRadioButtonClick(filterValue);
    }
    setShouldRunOnMenuAdd(true);
    /*
      We use setShouldRunOnMenuAdd(true) and run in in useEffect instead of simple calling onMenuItemClickAction() here.
      The reason we do it is that we want the filtersVisibleInFirstLine parameter to be updated, which only happens in the next
      render.

      We are aware that this is a hackey solution, but we don't see a better solution at the moment.
     */
  };

  return (
    <JitMenu
      childrenWrapperStyle={{
        width: '100%',
        height: '100%',
      }}
      inputColor='dimBlue'
      menuItems={menuItems}
      menuItemsWrapperClassName={styles.menuItemsWrapper}
      onMenuItemClick={onMenuItemClick}
      shouldBeInitiallyOpen={shouldInitiallyBeOpen}
      variant='light'
      withCheckbox={isMultiSelect}
      withRadioButtons={isSingleSelect}
      withSearchBox={{ shouldHideAllWhenEmptyFilter: filter.key === ServerFindingKeys.test_name }}
    >
      <div className={styles.boxContainer}>
        <JitText color={colors.lightGray} noWrap overflowHiddenEllipsis text={filterKeysText} />

        <JitText
          bold
          className={styles.valuesText}
          color={isAllValuesSelected ? colors.darkGray : colors.white}
          noWrap
          overflowHiddenEllipsis
          text={formattedSelectedValue as string}
        />

        <JitIcon className={styles.removeFilterIcon} color={colors.lightGray} icon={Cross} onClick={removeFilterVisibility} size={22} />

      </div>

    </JitMenu>
  );
};
