import CircularProgress from '@mui/material/CircularProgress';
import saveAs from 'file-saver';
import { FC, useState } from 'react';

import styles from './ExportButton.module.scss';

import { Download } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';

interface ExportButtonProps {
  handleClick: () => Promise<string | undefined>;
  text: string;
  disabled?: boolean;
}
export const ExportButton: FC<ExportButtonProps> = ({ handleClick, text, disabled }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownload = async () => {
    try {
      setIsLoading(true);
      const downloadLink = await handleClick();
      if (downloadLink) {
        saveAs(downloadLink);
      }
    } catch (error) {
      console.error('error downloading file');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <JitButton
      className={styles.downloadButton}
      data-testid='export-button'
      disabled={isLoading || disabled}
      onClick={handleDownload}
      variant='outlined'
    >
      {isLoading
        ? <CircularProgress className={styles.downloadIcon} color='inherit' data-testid='loading-icon' size={22} />
        : <JitIcon color='currentColor' data-testid='download-icon' icon={Download} iconClassName={styles.downloadIcon} />}

      <JitText color='inherit' data-testid='export-button-text' horizontalAlign='center' text={text} />
    </JitButton>
  );
};
