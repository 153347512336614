/* eslint-disable react/no-multi-comp */
/* eslint-disable react/no-unstable-nested-components */
import { FC, useMemo } from 'react';

import { CompletionGauge } from '../CompletionGauge/CompletionGauge';

import styles from './CompletionStatusRow.module.scss';

import { JitText } from 'components/JitText/JitText';
import { PanelLayout } from 'components/PanelLayout';

interface Props {
  title: string;
  description: string;
  completed: number;
  total: number;
}

export const CompletionStatusRow: FC<Props> = ({ title, description, completed, total }) => {
  const center = useMemo(() => ({
    component: () => (
      <div className={styles.center}>
        <div className={styles.text}>
          <JitText bold size='xxl' text={title} />

          <JitText muted text={description} />
        </div>
      </div>
    ),
    style: {
      display: 'flex',
      flex: 7,
    },
  }), [title, description]);

  const left = useMemo(() => ({
    component: () => <CompletionGauge completed={completed} total={total} />,
    style: {
      display: 'flex',
      justifyContent: 'center',
    },
  }), [completed, total]);

  const rowComponents = useMemo(() => [{
    left,
    center,
  }], [center, left]);

  return (
    <PanelLayout rowComponents={rowComponents} />
  );
};
