import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';

import styles from './OrgScoreWidget.module.scss';
import { useOrgScoreWidget } from './useOrgScoreWidget';
import { getTotalScore } from './utils';

import { ScoreTooltipFormatter } from 'components/graphs/components/TooltipFormatter/ScoreTooltipFormatter';
import { JitLinearGradientChart } from 'components/graphs/LinearGradientChart/JitLinearGradientChart';
import { JitText } from 'components/JitText/JitText';
import {
  GraphEmpty,
  GraphLoading,
} from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/components';
import { getScoreTrendMetric } from 'services/MetricService/MetricService';
import { Queries } from 'types/enums/Queries';
import { ScoreEntityType } from 'types/interfaces/Metrics/TeamsPortalMetrics/ScoreTrending';
import { getGradeByScore } from 'utils/functions/grades';

const type = ScoreEntityType.Org;

export const OrgScoreWidget: FC = () => {
  const { data, isLoading } = useQuery([Queries.OrgScoreTrending, type], () => getScoreTrendMetric({
    params: {
      entities_type: type,
    },
  }));

  const { monthlyScoreData, customXTickFormatter, gradientColors, sameScoreColor } = useOrgScoreWidget(data);

  const { orgTotalScore, orgGrade } = useMemo(() => {
    const scores = monthlyScoreData.map(({ value }) => value);
    const totalScore = getTotalScore(scores);
    const grade = getGradeByScore(totalScore);

    return {
      orgTotalScore: totalScore,
      orgGrade: grade,
    };
  }, [monthlyScoreData]);

  if (isLoading) return <GraphLoading />;
  if (!monthlyScoreData.length) return <GraphEmpty />;

  return (
    <div className={styles.orgScoreContainer}>
      <div>
        <div className={styles.score}>
          <JitText bold data-testid='org-score-grade' size='xhuge' text={orgGrade} />

          <JitText data-testid='org-score-percentage' size='xhuge' text={`${orgTotalScore}%`} />
        </div>

        <JitText
          muted
          size='s'
          text='pages.securityImpactV2.sections.securityProgress.widgets.orgScore.label'
        />
      </div>

      <div className={styles.chart}>
        <JitLinearGradientChart
          CustomTooltip={ScoreTooltipFormatter}
          customXTickFormatter={customXTickFormatter}
          data={monthlyScoreData}
          gradientColors={gradientColors}
          singleColor={sameScoreColor}
        />
      </div>
    </div>
  );
};
