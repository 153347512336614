/* eslint-disable react/no-multi-comp */
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

import { FindingsTableGeneralHeader, FindingsTableSelectHeader } from '../components';
import { PriorityFactors } from '../components/PriorityFactors/PriorityFactors';

import { IsSelectedCheckboxCell, SeverityCell, TextCell } from 'components/JitTable/templates';
import { LocationCell } from 'components/JitTable/templates/LocationCell/LocationCell';
import { StatusCell } from 'components/JitTable/templates/StatusCell/StatusCell';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { i18n } from 'locale/i18n';
import { IDictionary, IFinding, IFindingWithSelected, ISortColumn, ITableCell, ServerFindingKeys } from 'types/interfaces';
import { TableColumns } from 'types/types/TableColumns';

interface Props {
  isPageSelected: boolean;
  setPageSelected: (isPageSelected: boolean) => void;
  updateFindingIgnoredState: (findingToUpdate: IFinding, selectedIsIgnored: boolean) => void;
  isIgnoreRulesReadOnly: boolean;
  getColumn: (columnKey: string) => ISortColumn | undefined;
  toggleColumnSort: (columnKey: string) => void;
}

export const useGetTableColumns = ({
  isPageSelected, setPageSelected, getColumn, toggleColumnSort,
}: Props): TableColumns<IFindingWithSelected> => {
  const { t } = i18n;

  const { useKnowledgeGraph } = useFlags();

  const columnHeaders: IDictionary<string> = useMemo(() => t('pages.findings.findingsTable.columnHeaders', { returnObjects: true }), [t]);
  const columnsData = useMemo(() => {
    const columns = [
      {
        Header: <FindingsTableSelectHeader isPageSelected={isPageSelected} setPageSelected={setPageSelected} />,
        accessor: 'isSelectedDetails',
        width: '4%',
        Cell: IsSelectedCheckboxCell,
      },
      {
        Header: <FindingsTableGeneralHeader columnKey={ServerFindingKeys.location_text} columnName={columnHeaders.location} />,
        accessor: 'locationText',
        width: '20%',
        Cell: LocationCell,
      },
      ...(useKnowledgeGraph ? [{
        Header: <FindingsTableGeneralHeader
          columnKey={ServerFindingKeys.priority_score}
          columnName={columnHeaders.priorityScore}
          infoTooltipText='pages.findings.findingsTable.columnHeaders.priorityScoreTooltip'
          onChangeSortOrder={toggleColumnSort}
          sortDirection={getColumn(ServerFindingKeys.priority_score)!.direction}
        />,
        accessor: 'priorityScore',
        width: '9%',
        Cell: PriorityFactors,
      }] : []),
      {
        Header: <FindingsTableGeneralHeader columnKey={ServerFindingKeys.test_name} columnName={columnHeaders.name} />,
        accessor: 'name',
        width: '20%',
        Cell: TextCell,
      },
      {
        Header: <FindingsTableGeneralHeader
          columnKey={ServerFindingKeys.created_at}
          columnName={columnHeaders.firstDetected}
          onChangeSortOrder={toggleColumnSort}
          sortDirection={getColumn(ServerFindingKeys.created_at)!.direction}
        />,
        accessor: 'createdAt',
        width: '10%',
        Cell: ({ cell: { value } }: ITableCell<IFindingWithSelected>) => (
          <JitTooltip title={value}>
            <JitText text={value.split(' at')[0]} />
          </JitTooltip>
        ),
      },
      {
        id: 'status',
        Header: <FindingsTableGeneralHeader columnKey={ServerFindingKeys.status} columnName={columnHeaders.status} />,
        accessor: (finding: IFindingWithSelected) => ({
          status: finding.resolution,
          isIgnored: finding.ignored,
        }),
        width: '6%',
        Cell: StatusCell,
      },
      {
        Header: <FindingsTableGeneralHeader columnKey={ServerFindingKeys.vulnerability_type} columnName={columnHeaders.vulnerabilityType} />,
        accessor: 'vulnerabilityType',
        width: '12%',
        Cell: ({ cell: { value } }: ITableCell<IFindingWithSelected>) => {
          const newCell = { value: value.replace('Vulnerability', '') };
          // @ts-ignore
          return <TextCell cell={newCell} />;
        },
      },
      ...(!useKnowledgeGraph ? [{
        Header: <FindingsTableGeneralHeader columnKey={ServerFindingKeys.issue_severity} columnName={columnHeaders.severity} />,
        accessor: 'severity',
        width: '6%',
        Cell: SeverityCell,
      }] : []),
      // empty header for the last column to leave space for the actions column
      {
        Header: '',
        accessor: 'empty',
        width: '13%',
      },
    ];

    return { columns };
  }, [
    columnHeaders.firstDetected,
    columnHeaders.location,
    columnHeaders.name,
    columnHeaders.priorityScore,
    columnHeaders.severity,
    columnHeaders.status,
    columnHeaders.vulnerabilityType,
    getColumn,
    isPageSelected,
    setPageSelected,
    toggleColumnSort,
    useKnowledgeGraph,
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return columnsData as TableColumns<any>;
};
