import { toPairs } from 'lodash';

export const formatDuration = (ms: number, resolution: number = 2) => {
  const msAbs = Math.abs(ms);
  const time = {
    y: Math.floor(msAbs / (1000 * 60 * 60 * 24 * 365)),
    d: Math.floor(msAbs / 86400000) % 365,
    h: Math.floor(msAbs / 3600000) % 24,
    m: Math.floor(msAbs / 60000) % 60,
    s: Math.floor(msAbs / 1000) % 60,
  };

  return toPairs(time).filter(([, val]) => val !== 0).slice(0, resolution).map(([key, val]) => `${val}${key}`)
    .join(' ');
};
