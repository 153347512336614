import { HOURS_IN_DAY } from './constants';

interface ITimeFormat {
  value: number;
  unit: 'days' | 'hours';
}

export const hoursToShortenedDaysOrHoursString = (hours: number | undefined | null) => {
  if (hours == null) {
    return '--';
  }
  const days = Math.floor(hours / 24);
  if (days >= 1) {
    return `${days}d`;
  }
  return `${hours}h`;
};

export const secondsToShortenedDaysAndHoursString = (seconds: number | undefined | null) => {
  if (seconds == null) {
    return '--';
  }

  const days = Math.floor(seconds / 86400);
  const remainingSeconds = seconds % 86400;
  const hours = Math.floor(remainingSeconds / 3600);
  return `${days}d ${hours}h`;
};

export const convertHoursToTimeFormat = (hours: number): ITimeFormat => (hours >= HOURS_IN_DAY
  ? {
    value: Math.round(hours / HOURS_IN_DAY),
    unit: 'days',
  }
  : {
    value: hours,
    unit: 'hours',
  });

export const formatToWrittenDate = (date: Date): string => {
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return `${monthNames[monthIndex]} ${day}, ${year}`;
};
// Formats to a written month, such as: December 22, 2022
