import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useCallback, useEffect, useState } from 'react';

import { EXAMPLE_FILE_NAME } from './constants';
import styles from './ImportFileButton.module.scss';

import { UploadFileIcon } from 'assets';
import { ImportFileDialog } from 'components/ImportFileDialog/ImportFileDialog';
import { JitButton, JitButtonVariants } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { config } from 'config';
import { useTeamsContext } from 'context/TeamsContext/TeamsContext';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import { useTeamsService } from 'services/TeamsService/useTeamsService';
import colors from 'themes/colors.module.scss';
import { TeamsSyncType } from 'types/interfaces';
import { downloadFileFromJson } from 'utils/functions/downloadFileFromJson';
import { PERMISSIONS } from 'wrappers/RBAC/constants';
import { useHasPermissions } from 'wrappers/RBAC/hooks/useHasPermissions';

const UPLOAD_FILE_DOCS_PATH = 'jit-teams-sync#uploading-json-file-for-team-structure';

interface Props {
  variant?: JitButtonVariants;
}

export const ImportFileButton: FC<Props> = ({ variant = 'text' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { uiShowUploadTeamsJsonFile } = useFlags();
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const { preferences, isPreferencesLoading, initPreferences } = useTenantContext();
  const { teams, setIsUploadingTeams } = useTeamsContext();
  const { uploadTeamsStructure, getTeamsStructureExample } = useTeamsService();
  const { docsUrl } = config;

  useEffect(() => {
    if (!isPreferencesLoading && !preferences) {
      initPreferences();
    }
  }, [isPreferencesLoading, preferences, initPreferences]);

  const getAlertMessage = useCallback(() => {
    if (!preferences?.teams_sync?.sync_type && teams.data.length === 0) {
      return undefined;
    }

    if (preferences?.teams_sync?.sync_type === TeamsSyncType.MANUAL) {
      return 'pages.teams.teamsTable.importTeams.dialog.alertMessages.manual';
    }

    return 'pages.teams.teamsTable.importTeams.dialog.alertMessages.auto';
  }, [preferences?.teams_sync, teams.data.length]);

  const { hasPermission } = useHasPermissions();
  const shouldAllowAddingTeams = hasPermission(PERMISSIONS.WRITE_TEAMS);

  if (!uiShowUploadTeamsJsonFile) {
    return null;
  }

  const isFileContentValid = async (value: string) => {
    try {
      JSON.parse(value);
      return true;
    } catch (e) {
      return false;
    }
  };

  const downloadTemplate = async () => {
    setIsLoadingDownload(true);
    const response = await getTeamsStructureExample();
    if (!response) return;

    downloadFileFromJson(response.data.content, EXAMPLE_FILE_NAME);
    setIsLoadingDownload(false);
  };

  const onClick = () => setIsOpen(true);
  const isDisabled = isPreferencesLoading;
  const isReadOnly = !shouldAllowAddingTeams;

  const handleUpload = async (file: File) => {
    const response = await uploadTeamsStructure(file);
    if (response?.status === 202) {
      setIsUploadingTeams(true);
    }

    return response;
  };

  return (
    <>
      <JitButton data-testid='import-file-button' disabled={isDisabled} isReadOnly={isReadOnly} onClick={onClick} variant={variant}>
        <div className={styles.button}>

          {variant === 'text' && <JitIcon data-testid='upload-file-icon' icon={UploadFileIcon} size={16} />}

          <JitText color={variant === 'text' ? colors.iris : undefined} text='pages.teams.teamsTable.importTeams.button' />
        </div>
      </JitButton>

      <ImportFileDialog
        accept='application/json'
        alertMessage={getAlertMessage()}
        docsLink={`${docsUrl}/${UPLOAD_FILE_DOCS_PATH}`}
        downloadTemplate={downloadTemplate}
        fileInputTitle='pages.teams.teamsTable.importTeams.dialog.fileInputTitle'
        handleUpload={handleUpload}
        isFileContentValid={isFileContentValid}
        isLoadingDownload={isLoadingDownload}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title='pages.teams.teamsTable.importTeams.dialog.title'
      />
    </>
  );
};
