import { Paywall, Checkout, OnCheckoutCompletedParams, Plan } from '@stigg/react-sdk';
import { FC, useState } from 'react';

import { config } from 'config';
import { useStiggExtendedContext } from 'context/StiggContext';
import { logError } from 'services/logger/logger';

export enum StiggPlanId {
  PLAN_JIT_FREE = 'plan-jit-free',
  PLAN_JIT_GROWTH = 'plan-jit-growth',
  PLAN_JIT_ENTERPRISE = 'plan-jit-enterprise',
}

export const StiggPaywall: FC = () => {
  const { setShowStiggPayWall } = useStiggExtendedContext();
  const [selectedPlan, setSelectedPlan] = useState('');

  const handlePageSelection = ({ plan }: { plan: Plan }) => {
    switch (plan.id) {
      case StiggPlanId.PLAN_JIT_FREE:
        setShowStiggPayWall(false);
        break;
      case StiggPlanId.PLAN_JIT_GROWTH:
        setSelectedPlan(StiggPlanId.PLAN_JIT_GROWTH);
        break;
      case StiggPlanId.PLAN_JIT_ENTERPRISE:
        window.open(config.contactUsUrl, '_blank');
        break;
      default:
        console.error('Unknown plan', plan);
        break;
    }
  };

  const onCheckoutCompleted = async (params: OnCheckoutCompletedParams) => {
    if (params.success) {
      setShowStiggPayWall(false);
    } else {
      logError('Checkout failed');
    }
  };

  return selectedPlan ? (
    <Checkout
      onCheckoutCompleted={onCheckoutCompleted}
      planId={selectedPlan}
    />
  ) : (
    <Paywall onPlanSelected={handlePageSelection} />
  );
};
