import { DocNode } from '@atlaskit/adf-schema';
import { InlineLinkText } from '@atlaskit/adf-schema/dist/types/schema/nodes/types/inline-content';
import {
  doc,
  paragraph,
  heading,
  blockQuote,
  hardBreak,
  table,
  tableHeader,
  tableRow,
  tableCell,
} from '@atlaskit/adf-utils/builders';

import { generateFindingLink } from './generateFindingLink';
import { groupFindings, IGroupedFindings } from './groupFindings';

import { i18n } from 'locale/i18n';
import { IFinding } from 'types/interfaces';

const generateFindingTable = (findings: IFinding[]) => table(
  tableRow(
    [
      tableHeader()(paragraph(i18n.t('pages.findings.copy.findingLocation'))),
    ],
  ),
  ...findings.map((finding) => {
    const { linkText, linkUrl } = generateFindingLink(finding);
    return tableRow(
      [
        tableCell()(paragraph({
          type: 'text',
          text: finding.location || linkText,
          marks: [{
            type: 'link',
            attrs: { href: finding.location || linkUrl || linkText },
          }],
        })),
      ],
    );
  }),
);

const generateFindingsMetadata = (group: IGroupedFindings) => {
  const severity = paragraph(
    i18n.t('pages.findings.copy.severity'),
    ': ',
    { text: group.severity,
      type: 'text',
      marks: [{ type: 'code' }] },
  );
  const vulnerabilityType = paragraph(
    i18n.t('pages.findings.copy.vulnerabilityType'),
    ': ',
    { text: group.vulnerabilityType,
      type: 'text',
      marks: [{ type: 'code' }] },
  );
  return [severity, vulnerabilityType];
};

const generateIssueText = (group: IGroupedFindings) => {
  const issueTextHeader = heading({ level: 2 })(
    {
      text: i18n.t('pages.findings.copy.issueText').toString(),
      type: 'text',
    },
  );
  const learnMoreLink = group.referencesUrls.length > 0 && {
    text: i18n.t('pages.findings.copy.learnMore').toString(),
    type: 'text',
    marks: [
      {
        type: 'link',
        attrs: {
          href: group.referencesUrls[0],
        },
      },
    ],
  };
  const issueText = blockQuote(
    paragraph(
      group.issueText,
      ...(learnMoreLink ? [hardBreak(), learnMoreLink as InlineLinkText] : []),
    ),
  );
  return [issueTextHeader, issueText];
};

const getDetectedByQuote = (group: IGroupedFindings) => blockQuote(
  paragraph(
    i18n.t('pages.findings.copy.adf.detectedFirst'),
    {
      text: group.controlName,
      type: 'text',
      marks: [{ type: 'strong' }],
    },
    i18n.t('pages.findings.copy.adf.securityTool'),
    {
      text: group.firstDetectedDate,
      type: 'text',
      marks: [{ type: 'strong' }],
    },
  ),
);

const findingsToADF = (group: IGroupedFindings) => {
  const header = heading({ level: 1 })(
    {
      text: `[${i18n.t('pages.findings.copy.securityFinding')}] ${group.name}`,
      type: 'text',
    },
  );
  const findingsMetadata = generateFindingsMetadata(group);
  const findingTable = generateFindingTable(group.findings);
  const detectedBy = getDetectedByQuote(group);
  const [issueTextHeader, issueText] = generateIssueText(group);

  return [header, ...findingsMetadata, findingTable, detectedBy, issueTextHeader, issueText];
};

export const generateADF = (findings: IFinding[]): DocNode => {
  const groupedFindings = groupFindings(findings);
  return doc(...groupedFindings.flatMap(findingsToADF));
};
