import { FC } from 'react';

import styles from './PlanItemActiveLabel.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

export const PlanItemActiveLabel: FC = () => (
  <div className={styles.wrapper}>
    <JitText color={colors.successGreen02} data-testid='ActiveLabelText' text='pages.plan.planItem.activated' />
  </div>
);
