import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './StatsCards.module.scss';

import { constants } from 'globalConstants';
import { FindingsCountBanner } from 'pages/TeamsPage/components/FindingsCountBanner/FindingsCountBanner';
import { ScoreBanner } from 'pages/TeamsPage/SubPages/TeamDetailsPage/components/ScoreBanner/ScoreBanner';
import { IServerFindingFilterOptions } from 'types/interfaces';

interface Props {
  score: number;
  totalFindingsCount?: number;
  filterValue?: string;
  filterName?: keyof IServerFindingFilterOptions;
  navigateToFindings?: boolean;
}

export const StatsCards: FC<Props> = memo(({
  score,
  totalFindingsCount,
  filterName,
  filterValue,
  navigateToFindings = true,
}: Props) => {
  const navigate = useNavigate();
  const handleFindingBannerClick = () => {
    navigate(`/${constants.routes.FINDINGS}?${filterName}=${filterValue}&resolution=OPEN&ignored=false`);
  };
  return (
    <div className={styles.wrapper}>

      <ScoreBanner score={score} />

      <FindingsCountBanner findingsCount={totalFindingsCount} navigateToFindings={navigateToFindings} onClick={handleFindingBannerClick} />
    </div>
  );
});
