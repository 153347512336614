import { useCallback, useEffect, useState } from 'react';

import { LAST_VIEWED_TEAM_LOCAL_STORAGE_KEY } from './constants';

import { useAuthContext } from 'context/AuthContext/AuthContext';
import { useTeamsContext } from 'context/TeamsContext/TeamsContext';
import { GET_PORTAL_TEAMS_LIMIT } from 'services/TeamsService/constants';
import { IGetTeamResponseItem } from 'types/interfaces/Teams/ITeam';
import { TeamSortBy } from 'types/interfaces/Teams/TeamSorting';

interface Props {
  initialIndex?: number;
  sortBy?: TeamSortBy;
}

export const useCurrentTeam = ({ initialIndex = 0, sortBy = TeamSortBy.SCORE }: Props = {}) => {
  const {
    teams,
    getTeams,
    getSpecificTeamById,
    specificTeam,
    isLoadingSpecificTeam,
  } = useTeamsContext();
  const [isInitLoading, setIsInitLoading] = useState(false);
  const { frontEggUser, userHasAccessToManagementPortal } = useAuthContext();

  const [allTeamsFetched, setAllTeamsFetched] = useState(false);

  const fetchAllTeams = useCallback(async () => {
    if (allTeamsFetched) return;

    const shouldFetchMore = !teams.hasReachedEnd && !teams.isLoading;
    if (shouldFetchMore) {
      await getTeams({
        shouldResetState: teams.data.length === 0,
        userId: userHasAccessToManagementPortal ? undefined : frontEggUser.id, // If the user has access to management portal, they should see all teams.
        sortBy,
        displayImage: true,
        limit: GET_PORTAL_TEAMS_LIMIT,
        page: Math.floor(teams.data.length / GET_PORTAL_TEAMS_LIMIT) + 1,
      });
    }

    if (teams.hasReachedEnd) {
      setAllTeamsFetched(true);
    }
  }, [allTeamsFetched, teams.hasReachedEnd, teams.isLoading, teams.data.length, getTeams, userHasAccessToManagementPortal, frontEggUser.id, sortBy]);

  const initTeams = useCallback(async () => {
    setIsInitLoading(true);
    setAllTeamsFetched(false);
    await fetchAllTeams();
    setIsInitLoading(false);
  }, [fetchAllTeams]);

  const handleCurrentTeamChange = useCallback((currentTeam: IGetTeamResponseItem) => {
    localStorage.setItem(LAST_VIEWED_TEAM_LOCAL_STORAGE_KEY, currentTeam.id);
    getSpecificTeamById(currentTeam.id, true, true);
  }, [getSpecificTeamById]);

  useEffect(() => {
    if (teams.data.findIndex((team) => team.id === specificTeam?.team?.id)
            === teams.data.length
            - 2
            && !teams.isLoading
            && !teams.hasReachedEnd
    ) {
      fetchAllTeams();
    }
    if (!specificTeam?.team && teams.data.length > 0 && !isLoadingSpecificTeam) {
      const teamsIds = teams.data.map((team) => team.id);
      const storedTeamId = localStorage.getItem(LAST_VIEWED_TEAM_LOCAL_STORAGE_KEY) || '';
      const teamId = (teamsIds.includes(storedTeamId) && storedTeamId) || teams.data[initialIndex].id;
      getSpecificTeamById(teamId, true, true);
    }
  }, [specificTeam?.team, teams.data, teams.isLoading, teams.hasReachedEnd, fetchAllTeams, initialIndex, isLoadingSpecificTeam, getSpecificTeamById]);

  return {
    currentTeam: specificTeam?.team,
    initTeams,
    specificTeam,
    handleCurrentTeamChange,
    getSpecificTeamById,
    isInitLoading,
    teams,
    isLoadingSpecificTeam: specificTeam?.isLoadingSpecificTeam,
  };
};
