import { styled } from '@mui/material/styles';

import colors from 'themes/colors.module.scss';

interface Props {
  isSelected?: boolean;
}

const background = colors.cards;

export const StyledPlanCategoryCardItem = styled('div')<Props>(({ isSelected }) => ({
  backgroundColor: background,
  cursor: 'pointer',
  justifyContent: 'space-between',
  marginTop: '0.5rem',
  display: 'flex',
  alignItems: 'center',
  height: '70px',
  borderRadius: '0.5rem',
  gap: '24px',
  padding: '11px 12px',
  '& > div#main': {
    width: '100%',
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: 'auto',
    '& > div:first-of-type': {
      display: 'flex',
    },
  },
  ...(isSelected && {
    border: `1px solid ${colors.blue02}`,
  }),
  ' .MuiCheckbox-root.Mui-checked': {
    display: 'none',
  },
  '&:hover': {
    border: `1px solid ${colors.blue02}`,
    ' .MuiCheckbox-root.Mui-checked': {
      display: 'inherit',
    },
    p: {
      visibility: 'visible',
    },
  },
}));
