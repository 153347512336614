import { FC } from 'react';

import styles from './EngineDisabledBanner.module.scss';

import { WarningTrianglesMultiple } from 'assets';
import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { config } from 'config';
import colors from 'themes/colors.module.scss';

export const EngineDisabledBanner: FC = () => {
  const handleContactSalesClick = () => {
    window.open(config.contactUsUrl, '_blank');
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.banner} data-testid='engine-disabled-banner'>
        <div className={styles.icon}>
          <JitIcon height='90px' icon={WarningTrianglesMultiple} width='226.5px' />

          <div className={styles.text}>
            <JitText size='xxl' text='pages.risks.resources.resourcesPage.engineDisabled.prelude' />

            <JitText
              bold
              color={colors.jitPink}
              size={34}
              text='pages.risks.resources.resourcesPage.engineDisabled.contextEngine'
            />

          </div>
        </div>

        <div className={styles.buttons}>
          <div
            className={styles.contactSalesButton}
            data-testid='upgradeToPremium'
            onClick={handleContactSalesClick}
            role='button'
            tabIndex={0}
          >
            <JitText size='s' text='pages.risks.resources.resourcesPage.engineDisabled.contactSales' />
          </div>

          <JitExternalLink
            data-testid='learnMoreLink'
            href={config.docsContextEngine}
            showIcon
            size='s'
            text='pages.risks.resources.resourcesPage.engineDisabled.learnMore'
          />
        </div>
      </div>
    </div>
  );
};
