import { createContext, useContext } from 'react';

import { IPullRequestAnalysisPercentage, IPullRequestsAnalysis } from 'types/interfaces/PullRequests';

interface IPullRequestsContext {
  prPercentage?: IPullRequestAnalysisPercentage;
  prAnalysis?: IPullRequestsAnalysis;
  isLoading: boolean;
  getPullRequestsAnalysis: () => void;
}

export const PullRequestsContext = createContext<IPullRequestsContext>({
  prAnalysis: undefined,
  prPercentage: undefined,
  isLoading: true,
  getPullRequestsAnalysis: () => {},
});

export const usePullRequestsContext = () => useContext(PullRequestsContext);
