import { NoFindings } from './components';

import { AppliedFiltersHasNoData } from 'components/JitTable/components/AppliedFiltersHasNoData/AppliedFiltersHasNoData';
import { MissingScmIntegration } from 'components/JitTable/components/MissingScmIntegration/MissingScmIntegration';
import { useClearFilters } from 'context/FindingsContext/hooks/useClearFilters';
import { useHasVisibleFilters } from 'context/FindingsContext/hooks/useHasVisibleFiltersCheck';
import { useTenantContext } from 'context/TenantContext/TenantContext';

export const EmptyFindingsTable = () => {
  const hasVisibleFilters = useHasVisibleFilters();
  const clearFilters = useClearFilters();
  const { isAnyScmVendorIntegrated } = useTenantContext();

  if (!isAnyScmVendorIntegrated) {
    return (
      <MissingScmIntegration
        subtitleAction='pages.findings.emptyFindingsTable.missingScmIntegrationSubtitleAction'
      />
    );
  }
  if (hasVisibleFilters()) {
    return <AppliedFiltersHasNoData clearFilters={clearFilters} entityName='findings' />;
  }
  return <NoFindings />;
};
