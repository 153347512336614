import { FC, useMemo } from 'react';

import styles from './StepDetails.module.scss';

import { JitDivider } from 'components/JitDivider/JitDivider';
import { JitSidePanel } from 'components/JitSidePanel/JitSidePanel';
import { JitText } from 'components/JitText/JitText';
import { TriggerSection } from 'pages/WorkflowsPage/EditWorkflowPage/components/StepDetails/TriggerSection/TriggerSection';
import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext';
import colors from 'themes/colors.module.scss';
import { capitalizeFirstLetter } from 'utils';

interface Props {
  closePanel: () => void;
  selectedStepId: string;
  setSelectedStepId: (stepId: string) => void;
}

export const StepDetails: FC<Props> = ({
  closePanel,
  selectedStepId,
  setSelectedStepId,
}) => {
  const { workflow } = useEditWorkflowContext();

  const selectedStep = useMemo(() => {
    if (workflow.trigger?.id === selectedStepId) return workflow.trigger;
    return workflow.steps.find((step) => step.id === selectedStepId);
  }, [selectedStepId, workflow]);

  return (
    <JitSidePanel closePanel={closePanel} title='pages.workflows.editWorkflowPage.stepDetails.title'>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <JitText bold muted size='s' text='pages.workflows.editWorkflowPage.stepDetails.subtitle' />

          {selectedStep?.stepType && <JitText bold size='l' text={capitalizeFirstLetter(selectedStep?.stepType)} />}
        </div>

        <JitDivider color={colors.black} />

        {selectedStep?.stepType === 'trigger' && <TriggerSection setSelectedStepId={setSelectedStepId} />}
      </div>
    </JitSidePanel>
  );
};
