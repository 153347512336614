const MS_PER_DAY = 1000 * 60 * 60 * 24;

export interface FormatHoursOptions {
  hours: number;
  fullWord?: boolean;
  roundUp?: boolean;
  singleTimeUnit?: boolean;
}

export const dateDiffInDays = (a: Date, b: Date) => {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / MS_PER_DAY);
};

export const formatHoursToDisplayText = ({
  hours,
  fullWord = false,
  roundUp = false,
  singleTimeUnit = false,
}: FormatHoursOptions) => {
  const totalHours = roundUp ? Math.ceil(hours) : Math.floor(hours);

  const days = singleTimeUnit && roundUp ? Math.ceil(totalHours / 24) : Math.floor(totalHours / 24);
  const remainingHours = Math.floor(totalHours % 24);

  if (singleTimeUnit) {
    if (days > 0) {
      return fullWord ? `${days} days` : `${days}d`;
    }
    return fullWord ? `${Math.floor(totalHours)} hours` : `${Math.floor(totalHours)}h`;
  }

  if (days > 0 && remainingHours > 0) {
    return fullWord ? `${days} days and ${remainingHours} hours` : `${days}d ${remainingHours}h`;
  }
  if (days > 0) {
    return fullWord ? `${days} days` : `${days}d`;
  }
  return fullWord ? `${remainingHours} hours` : `${remainingHours}h`;
};
