import { FC, ReactNode } from 'react';

import { JitTooltip } from '../JitTooltip/JitTooltip';
import { TooltipOnlyOnOverflow } from '../TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';

import styles from './JitCard.module.scss';

import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitTag } from 'components/JitTag/JitTag';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { ISvg } from 'types/interfaces';
import { ActionButton } from 'types/interfaces/ActionButton/ActionButton';
import { Link } from 'types/interfaces/Link/Link';
import { ExtractProps } from 'utils/functions/extractProps';
import { useCalculateMaxVisibleElementsCount } from 'utils/hooks/useCalculateMaxVisibleElementsCount';

export interface Tag {
  color: string;
  text: string;
}

export interface HeaderIcon extends ExtractProps<typeof JitIcon> {
  icon?: FC<ISvg>;
  iconUrl?: string;
  tooltip?: string;
}

export type HorizontalAlign = 'left' | 'right';
const defaultHorizontalAlign = 'left';

export interface HeaderIcons {
  icons: HeaderIcon[];
  horizontalAlign?: HorizontalAlign;
}

const flexDirectionStyleMap = new Map<HorizontalAlign, string>([
  ['left', styles.headerIconsAlignLeft],
  ['right', styles.headerIconsAlignRight],
]);

interface Props {
  headerIcons?: HeaderIcons;
  title?: string;
  subTitle?: string;
  description?: string;
  actionButtons?: ActionButton[];
  tags?: Tag[];
  testid?: string;
  link?: Link;
  borderColor?: string;
  backgroundColor?: string;
  height?: string;
  width?: string;
  children?: ReactNode;
  cardClassName?: string;
}

const GENERAL_PADDING = 25;
const ICON_SIZE = 30;

export const JitCard: FC<Props> = ({
  borderColor, backgroundColor, headerIcons, title, subTitle, description, actionButtons, testid, tags, link, height, width = '285px', children,
}) => {
  const tagsElements = tags?.map((tag) => <JitTag key={`${title}-${tag.text}`} color={tag.color} text={tag.text} />);
  const parsedWidth = parseInt(width, 10);
  const totalWidth = parsedWidth - GENERAL_PADDING;
  const visibleCount = useCalculateMaxVisibleElementsCount(tagsElements || [], totalWidth);

  const getIconComponent = (icon: HeaderIcon, key: string, index: number) => {
    if (icon.icon) {
      return <JitIcon {...icon} key={key} data-testid={`card-icon-${icon['data-testid'] || index}`} size={ICON_SIZE} />;
    }
    if (icon.iconUrl) {
      return (
        <img
          key={key}
          alt={icon.tooltip}
          data-testid={`card-icon-${icon['data-testid'] || index}`}
          src={icon.iconUrl}
          style={{
            height: ICON_SIZE,
            width: ICON_SIZE,
          }}
        />
      );
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  };

  return (
    <div
      className={styles.generalCardContainer}
      data-testid={`jit-card-${testid}`}
      style={{
        border: borderColor ? `1px solid ${borderColor}` : undefined,
        height: height || undefined,
        maxWidth: width,
        minWidth: width,
        background: backgroundColor || undefined,
        padding: GENERAL_PADDING,
      }}
    >
      <div className={styles.cardContentStyle}>
        <div className={`${styles.headerIcons} ${headerIcons?.horizontalAlign ? flexDirectionStyleMap.get(headerIcons.horizontalAlign || defaultHorizontalAlign) : ''}`}>
          {headerIcons?.icons?.map((icon, index) => {
          // eslint-disable-next-line react/no-array-index-key
            const iconComponent = getIconComponent(icon, `jit-card-icon-${index}`, index);
            if (icon.tooltip) {
              return (
              // eslint-disable-next-line react/no-array-index-key
                <JitTooltip key={`jit-card-tooltip-${index}`} followCursor={false} placement='top' title={icon.tooltip}>
                  {iconComponent}
                </JitTooltip>
              );
            }
            return iconComponent;
          })}
        </div>

        <TooltipOnlyOnOverflow bold className={styles.title} data-testid='card-title' height='25px' noWrap overflowHiddenEllipsis size='l' text={title} />

        <JitText bold className={styles.subTitle} data-testid='card-subTitle' size='m' text={subTitle} />

        <div className={styles.tagsWrapper}>
          {tagsElements?.slice(0, visibleCount)}

          {tagsElements?.length && visibleCount < tagsElements.length && (
            <JitTooltip followCursor={false} placement='top' title={tagsElements.slice(visibleCount).map((tag) => tag.props.text).join(', ')}>
              <JitTag
                color={colors.lightGray}
                text={`+${tagsElements.length - visibleCount}`}
              />
            </JitTooltip>
          )}
        </div>

        <TooltipOnlyOnOverflow text={description}>
          <JitText
            className={styles.description}
            color={colors.lightGray}
            data-testid='card-description'
            overflowHiddenEllipsis
            text={description}
          />
        </TooltipOnlyOnOverflow>

        {link && (
        <div className={styles.link}>
          <JitExternalLink href={link.href} testId='card-link' text={link.text} />
        </div>
        )}

        {children}

      </div>

      {actionButtons && (
      <div className={styles.cardBottom}>
        <div className={styles.actionButtonsWrapper} data-testid='actionButtons'>
          {actionButtons.map((actionButton) => <JitActionButton key={`actionButton-${actionButton.label}`} actionButton={actionButton} />)}
        </div>
      </div>
      )}
    </div>
  );
};
