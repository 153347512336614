import { FC, PropsWithChildren } from 'react';

import styles from './SecurityPlanTopRowCard.module.scss';

interface Props {
  width?: string;
}

export const SecurityPlanTopRowCard: FC<PropsWithChildren<Props>> = ({ width, children }) => (
  <div className={styles.wrapper} style={{ width }}>

    {children}

  </div>
);
