// input: https://github.com/organization/repository/blob/main/test.py#L24-L24
// output: line 24
// input: https://github.com/organization/repository/blob/main/test.py#L24-L27

import { logError } from 'services/logger/logger';
import { IActionFinding } from 'types/interfaces';

export const generateCodeSubtitle = (finding: IActionFinding) => {
  // This funtion tries to fetch the lines from the location string
  // and return a string with the lines. If they cannot be fetched, it returns an empty string.
  if (!finding.location) {
    return '';
  }

  const splittedLocation = finding.location.split('#');
  if (splittedLocation.length < 2) {
    return '';
  }

  const linesString = splittedLocation[1];
  const lines = linesString.split('-');
  if (lines.length !== 2) {
    return '';
  }

  const startLine = lines[0].slice(1);
  const endLine = lines[1].slice(1);
  if (!startLine || !endLine) {
    return '';
  }

  return startLine === endLine ? ` line ${startLine}` : ` line ${startLine} - line ${endLine}`;
};

export const generateCodeTitle = (finding: IActionFinding) => {
  let path = `${finding.asset_domain}/${finding.asset_name}/`;
  try {
    if (finding.fix_suggestion?.filename) {
      return `${path}${finding.fix_suggestion?.filename}`;
    }
    const filePathNodes = finding.location?.split('/');
    if (filePathNodes) {
      const indexOfBlobString = filePathNodes?.indexOf('blob');
      if (indexOfBlobString) {
        const actualPath = filePathNodes?.slice(indexOfBlobString + 2, filePathNodes.length - 1);
        path = actualPath?.join('/') || path;
        const fileNameAndLines = filePathNodes[filePathNodes!.length - 1];
        const fileName = fileNameAndLines.split('#')[0];
        path += fileName;
      }
    }
  } catch (error) {
    logError('Error generating code title', { error });
  }
  return path;
};
