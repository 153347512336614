import Chip from '@mui/material/Chip';
import { FC } from 'react';

import { LESS, MORE, NEGATIVE_COLOR, NEUTRAL_COLOR, POSITIVE_COLOR } from './constants';

import { TriangleUp, TriangleDown } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { hexColorToRGBA } from 'utils';

export interface PercentageChipProperty {
  percentage?: number | null
  bgColor?: string;
  positiveTrendIndicatorDirection: 'up' | 'down'
  displayBorder?: boolean;
}

export const PercentageChip: FC<PercentageChipProperty> = ({
  percentage,
  bgColor = colors.cards,
  positiveTrendIndicatorDirection,
  displayBorder,
}) => {
  let direction = '';
  let title: string;
  let processedPercentage;
  let color = NEUTRAL_COLOR;
  let icon;
  if (percentage === undefined || percentage === null) { // percentage is undefined when we don't have data for tenant
    processedPercentage = '--';
    title = i18n.t('PercentageChip.noData');
  } else {
    processedPercentage = percentage.toString().replace('-', '');
    if (percentage > 0) {
      color = positiveTrendIndicatorDirection === 'up' ? POSITIVE_COLOR : NEGATIVE_COLOR;
      icon = TriangleUp;
      direction = MORE;
    } else if (percentage < 0) {
      color = positiveTrendIndicatorDirection === 'down' ? POSITIVE_COLOR : NEGATIVE_COLOR;
      icon = TriangleDown;
      direction = LESS;
    }
    title = i18n.t('PercentageChip.tooltip', {
      percentage: processedPercentage,
      direction,
    });
  }

  const iconObj = icon ? (<JitIcon color={color} icon={icon} size={5} />) : undefined;

  const borderColor = hexColorToRGBA(color, 0.3);
  const border = displayBorder ? `1px solid ${borderColor}` : 'none';

  return (
    <JitTooltip followCursor={false} placement='top' sx={{ padding: '3px' }} title={title}>
      <Chip
        icon={iconObj}
        label={`${processedPercentage}%`}
        size='small'
        sx={{
          color,
          backgroundColor: bgColor,
          borderRadius: '6px',
          fontSize: 12,
          minWidth: 37,
          fontWeight: 'bold',
          margin: '0px 11px',
          border,
        }}
      />
    </JitTooltip>
  );
};
