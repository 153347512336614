import { FC, useEffect, useState } from 'react';

import { useConfigurationsContext } from 'context/ConfigurationsContext/ConfigurationsContext';
import { useFetchConfigurations } from 'context/ConfigurationsContext/hooks';
import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { DeploymentsPage } from 'pages/InsightsPage/SubPages/DeploymentsPage/DeploymentsPage';
import {
  useHandleDeploymentsUpdatesWebSocketNotification,
} from 'pages/InsightsPage/SubPages/DeploymentsPage/hooks/useHandleDeploymentsWebSocketNotificationNew';
import { usePipelineService } from 'services/PipelinesService/usePipelineService';
import { WebSocketNotificationTopics } from 'types/enums';
import { IPipelineNew } from 'types/interfaces';

export const DeploymentsPageWrapper: FC = () => {
  useFetchConfigurations();
  const [deployments, setDeployments] = useState<IPipelineNew[]>();
  const { websocketSubscribe } = useWebsocketSubscribe();
  const [isLoadingDeployments, setIsLoadingDeployments] = useState<boolean>(false);
  const { isLoadingConfigurations, fetchedConfigurations } = useConfigurationsContext();
  const { getDeploymentsFromCreatedAfter } = usePipelineService();

  // always take the first one, we don't support multi envs yet
  const environment = fetchedConfigurations?.deployment?.environments?.[0] || '';
  const { handleDeploymentsUpdatesWebSocketNotification } = useHandleDeploymentsUpdatesWebSocketNotification({
    setDeployments,
    environment,
  });

  useEffect(() => {
    const fetchDeployments = async () => {
      if (environment) {
        setIsLoadingDeployments(true);
        const fetchedDeployments = await getDeploymentsFromCreatedAfter(environment);
        setDeployments(fetchedDeployments);
        setIsLoadingDeployments(false);
      }
    };

    fetchDeployments();
  }, [environment, getDeploymentsFromCreatedAfter]);

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.Deployment, handleDeploymentsUpdatesWebSocketNotification);
  }, [handleDeploymentsUpdatesWebSocketNotification, websocketSubscribe]);

  const isDeploymentConfigured = !!fetchedConfigurations?.deployment;

  const isLoading = isLoadingConfigurations || isLoadingDeployments;

  return (
    <DeploymentsPage deployments={deployments} environment={environment} isDeploymentConfigured={isDeploymentConfigured} isLoading={isLoading} />
  );
};
