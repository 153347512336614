export enum Status {
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export interface PlanItemAssetStatus {
  tenantId: string;
  itemSlug: string;
  assetId: string;
  status: Status
  updatedAt?: string;
  type: string;
}
