import { FC } from 'react';

import { DisabledElement } from 'components/DisabledElement/DisabledElement';
import { JitGrade } from 'components/JitGrade/JitGrade';
import { ITableCell } from 'types/interfaces';

export const GradeCell: FC<ITableCell> = ({ cell: { value: { score, isActivated } } }) => (
  <DisabledElement isDisabled={!isActivated}>
    <JitGrade score={score} size='s' />
  </DisabledElement>
);
