import { t } from 'i18next';
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';

import { InfoBanner } from './InfoBanner';
import { RepoSelectionDropdown } from './RepoSelectionDropdown';
import styles from './RepoSelectionDropdown.module.scss';

import { JitText } from 'components/JitText/JitText';
import { useAssetsContext } from 'context/AssetsContext/AssetsContext';
import { constants } from 'globalConstants';
import { CentralizedAssetValidationStatus } from 'types/enums/CentralizedAssetValidationStatus';
import { AssetType } from 'types/enums/index';
import { IAsset } from 'types/interfaces';

interface Props {
  validateSelectedAsset: (asset: IAsset) => void;
  selectedAsset?: IAsset;
  setSelectedAsset: Dispatch<SetStateAction<IAsset | undefined>>;
  validationStatus?: CentralizedAssetValidationStatus;
  setValidationStatus: Dispatch<SetStateAction<CentralizedAssetValidationStatus | undefined>>;
}

export const StepGithubCard: FC<Props> = ({
  validateSelectedAsset,
  selectedAsset,
  setSelectedAsset,
  validationStatus,
  setValidationStatus,
}) => {
  const { assets } = useAssetsContext();
  const [isLoading, setIsLoading] = useState(true);

  const repoTypeAssets = useMemo(() => assets.filter((asset) => asset.is_active
    && !asset.is_archived
    && asset.asset_type
    === AssetType.REPO), [assets]);
  useEffect(() => {
    if (!repoTypeAssets.length) {
      setSelectedAsset(undefined);
      setValidationStatus(undefined);
    }
  }, [repoTypeAssets.length, setSelectedAsset, setValidationStatus]);

  const loadingTimeout = useMemo(() => setTimeout(() => {
    setIsLoading(false);
  }, constants.LOADER_TIMEOUT), []);

  useEffect(() => {
    if (repoTypeAssets.length > 0) {
      setIsLoading(false);
      clearTimeout(loadingTimeout);
    }
  }, [loadingTimeout, repoTypeAssets.length]);

  const shouldShowNoReposLoader = useMemo(() => !repoTypeAssets.length && isLoading, [repoTypeAssets.length, isLoading]);

  const descriptionBullets: string[] = t('GithubIntegrationWizard.createCentralizedRepo.description.bullets', { returnObjects: true });

  return (
    <div className={styles.wrapper}>
      {descriptionBullets && (
        <ul className={styles.list}>
          {descriptionBullets.map((bullet) => (
            <li key={bullet} className={styles.listItem}>
              <JitText muted text={bullet} />
            </li>
          ))}
        </ul>
      )}

      <div className={styles.listContainer}>
        <RepoSelectionDropdown
          assets={repoTypeAssets}
          isLoading={shouldShowNoReposLoader}
          selectedAsset={selectedAsset}
          setSelectedAsset={setSelectedAsset}
          validateSelectedAsset={validateSelectedAsset}
          validationStatus={validationStatus}
        />

        <div className={styles.infoBanner}>
          <InfoBanner />
        </div>

      </div>
    </div>
  );
};

