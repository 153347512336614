import { IFinding } from 'types/interfaces';

export interface IGroupedFindings {
  name: string;
  issueText: string;
  referencesUrls: string[];
  controlName: string;
  firstDetectedDate: string;
  severity: string;
  vulnerabilityType: string;
  findings: IFinding[];
}

const isSameGroup = (group: IGroupedFindings, finding: IFinding) => (
  group.name === finding.name
    && group.issueText === finding.issueText
    && group.controlName === finding.controlName
    && group.severity === finding.severity
    && group.vulnerabilityType === finding.vulnerabilityType
);

export const groupFindings = (findings: IFinding[]): IGroupedFindings[] => findings.reduce((groupedFindings: IGroupedFindings[], finding: IFinding) => {
  const existingGroup = groupedFindings.find((group) => isSameGroup(group, finding));
  if (existingGroup) {
    existingGroup.findings.push(finding);
    existingGroup.referencesUrls = [...new Set([...existingGroup.referencesUrls, ...finding.referencesUrls])];
    existingGroup.firstDetectedDate = existingGroup.firstDetectedDate < finding.createdAt ? existingGroup.firstDetectedDate : finding.createdAt;
  } else {
    groupedFindings.push({
      name: finding.name,
      issueText: finding.issueText,
      referencesUrls: finding.referencesUrls,
      controlName: finding.controlName,
      firstDetectedDate: finding.createdAt,
      severity: finding.severity,
      vulnerabilityType: finding.vulnerabilityType,
      findings: [finding],
    });
  }
  return groupedFindings;
}, []);
