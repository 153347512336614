import { FC } from 'react';

import { Plan } from './Plan';
import styles from './PlanList.module.scss';

import { IPlan } from 'pages/SecurityMappingPage/interfaces/IPlan';

export interface IPlanListProps {
  securityPlans: IPlan[];
  handlePlanClick: (id: string) => void;
  handlePlanLinkClick: (id: string) => void;
}

export const PlanList: FC<IPlanListProps> = ({ handlePlanClick, handlePlanLinkClick, securityPlans }) => {
  const onPlanClicked = (e: React.MouseEvent) => {
    const { id } = e.currentTarget;
    handlePlanClick(id);
  };

  const onPlanLinkClicked = (e: React.MouseEvent) => {
    const { id } = e.currentTarget;
    handlePlanLinkClick(id);
  };

  return (
    <div
      className={styles.wrapper}
      data-testid='plan-list'
    >
      {securityPlans.map((plan) => (
        <Plan
          key={plan.key}
          onPlanClicked={onPlanClicked}
          onPlanLinkClicked={onPlanLinkClicked}
          plan={plan}
        />
      ))}
    </div>
  );
};

