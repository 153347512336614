import { useMemo, useRef, useState, useCallback, useEffect } from 'react';

export interface IManagePagination {
  canPreviousPage: boolean;
  canNextPage: boolean;
  getNextPage: () => void;
  getPreviousPage: () => void;
  pageIndex: number;
  setPageIndex: (updater: number | ((pageIndex: number) => number)) => void;
  paginationKeys: (string | undefined)[];
  setPaginationKeys: (updater: (string | undefined)[] | ((paginationKeys: (string | undefined)[]) => (string | undefined)[])) => void;
  resetPagination: () => void;
  isLoading: boolean;
  reFetchCurrentPage: () => Promise<void>;
}

interface Props {
  data: never[];
  fetchData: (nextPageKey?: string) => Promise<string | undefined>;
}

export const useManagePagination = ({ fetchData, data }: Props): IManagePagination => {
  const resetPaginationKeyRequestId = useRef(0);
  const [paginationKeys, setPaginationKeys] = useState<(string | undefined)[]>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [canNextPage, setCanNextPage] = useState(true);

  const canPreviousPage = useMemo(() => pageIndex > 0, [pageIndex]);

  const fetchDataByPaginationKey = useCallback(async (paginationKey: string | undefined, silent: boolean = false) => {
    if (!silent) setIsLoading(true);
    const nextPageKey = await fetchData(paginationKey);
    if (!silent) setIsLoading(false);

    setCanNextPage(!!nextPageKey);
    if (nextPageKey) {
      setPaginationKeys((currentPageKeys) => (currentPageKeys.includes(nextPageKey) ? currentPageKeys : [...currentPageKeys, nextPageKey]));
    }
  }, [fetchData]);

  const getNextPage = async () => {
    if (!canNextPage || isLoading) return;
    await fetchDataByPaginationKey(paginationKeys[pageIndex + 1]);
    setPageIndex(pageIndex + 1);
  };

  const getPreviousPage = useCallback(async () => {
    if (!canPreviousPage || isLoading) return;
    await fetchDataByPaginationKey(paginationKeys[pageIndex - 1]);
    setPageIndex(pageIndex - 1);
  }, [canPreviousPage, fetchDataByPaginationKey, isLoading, pageIndex, paginationKeys]);

  const resetPagination = useCallback(async () => {
    const requestId = Date.now();
    resetPaginationKeyRequestId.current = requestId;
    setPaginationKeys([undefined]);
    setPageIndex(0);
    setIsLoading(true);
    const nextPageKey = await fetchData();
    setIsLoading(false);
    if (requestId !== resetPaginationKeyRequestId.current) {
      return;
    }

    setCanNextPage(!!nextPageKey);
    if (nextPageKey) {
      setPaginationKeys((currentPageKeys) => (currentPageKeys.includes(nextPageKey) ? currentPageKeys : [...currentPageKeys, nextPageKey]));
    }
  }, [fetchData]);

  const reFetchCurrentPage = useCallback(async () => {
    // Remove all pagination keys after current page
    setPaginationKeys((currentPageKeys) => currentPageKeys.slice(0, pageIndex + 1));
    await fetchDataByPaginationKey(paginationKeys[pageIndex], true);
  }, [fetchDataByPaginationKey, pageIndex, paginationKeys]);

  useEffect(() => {
    if (!data.length && !isLoading && !canNextPage && canPreviousPage) getPreviousPage();
  }, [data, isLoading, canNextPage, canPreviousPage, getPreviousPage]);

  return {
    canPreviousPage,
    canNextPage,
    pageIndex,
    setPageIndex,
    getNextPage,
    getPreviousPage,
    paginationKeys,
    setPaginationKeys,
    resetPagination,
    isLoading,
    reFetchCurrentPage,
  };
};
