import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { getScore } from '../../../../context/PlansContext/utils/getPlanCoverage';
import { buildSetPlanAsGoalAnalyticsEvent, buildUnsetPlanAsGoalAnalyticsEvent } from '../../utils/buildSetUnsetPlanAsGoalEventParams';
import { ControlsSummary } from '../ControlsSummary/ControlsSummary';
import { RemoveGoalDialog } from '../RemoveGoalDialog/RemoveGoalDialog';
import { ScoreGauge } from '../ScoreGauge/ScoreGauge';
import { SetAsGoalDialog } from '../SetAsGoalDialog/SetAsGoalDialog';

import styles from './SecurityPlanCard.module.scss';

import { SetAsGoal, SetAsGoalOn } from 'assets';
import { HorizontalAlign, JitCard, Tag } from 'components/JitCard/JitCard';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { usePlanService } from 'services/PlanService/usePlanService';
import colors from 'themes/colors.module.scss';
import { ActionButton, ActionButtonVariant, IPlanDetails } from 'types/interfaces';
import { iconsMap } from 'utils';

const DEFAULT_AUTHOR = 'jit';

interface Props {
  plan: IPlanDetails;
  navigate: (slug: string) => void;
  labelColorsMap: { [key: string]: string }
}

export const SecurityPlanCard: FC<Props> = ({ plan, navigate, labelColorsMap }) => {
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const { partialUpdatePlan } = usePlanService();

  const [isSetAsGoal, setIsSetAsGoal] = useState(false);
  const [showSetAsGoalDialog, setShowSetAsGoalDialog] = useState(false);
  const [showRemoveGoalDialog, setShowRemoveGoalDialog] = useState(false);

  const [score, setScore] = useState(0);

  const calculatedScore = useMemo(() => getScore(plan), [plan]);

  useEffect(() => {
    setIsSetAsGoal(plan.is_goal);
  }, [setIsSetAsGoal, plan]);

  useEffect(() => {
    if (calculatedScore !== score) {
      setScore(calculatedScore);
    }
  }, [calculatedScore, score]);

  const setPlanAsGoal = useCallback((isGoal: boolean) => {
    setIsSetAsGoal(isGoal);
    partialUpdatePlan({
      slug: plan.slug,
      is_goal: isGoal,
    });
  }, [partialUpdatePlan, plan.slug]);

  const handleGoalClick = useCallback(() => {
    if (!isSetAsGoal) {
      setShowSetAsGoalDialog(true);
      setPlanAsGoal(true);
      sendAnalyticsEvent(buildSetPlanAsGoalAnalyticsEvent(plan.slug.toLowerCase(), 'SecurityPlanCard'));
    } else {
      setShowRemoveGoalDialog(true);
    }
  }, [isSetAsGoal, plan.slug, sendAnalyticsEvent, setPlanAsGoal]);

  const headerIcons = {
    icons: [{
      size: 26,
      icon: isSetAsGoal ? SetAsGoalOn : SetAsGoal,
      color: colors.iris,
      tooltip: `pages.securityPlans.planCard.setAsGoal.${isSetAsGoal ? 'unsetTooltip' : 'setTooltip'}`,
      onClick: handleGoalClick,
      withBackSquare: {
        height: 40,
        width: 40,
        backgroundColor: isSetAsGoal ? colors.cardContent : colors.appBgColor,
        borderColor: 'transparent',
        borderRadius: '50%',
      },
    }],
    horizontalAlign: 'right' as HorizontalAlign,
  };

  const onViewPlanClicked = useCallback(() => {
    sendAnalyticsEvent({
      action: 'view-plan',
      params: { slug: plan.slug.toLowerCase() },
    });
    navigate(plan.slug.toLowerCase());
  }, [navigate, plan.slug, sendAnalyticsEvent]);

  const actionButtons: ActionButton[] = [{
    label: 'pages.securityPlans.planCard.viewPlan.label',
    handleClick: onViewPlanClicked,
    variant: ActionButtonVariant.OUTLINED,
  }];

  const confirmRemoveGoal = useCallback(() => {
    setShowRemoveGoalDialog(false);
    setPlanAsGoal(false);
    sendAnalyticsEvent(buildUnsetPlanAsGoalAnalyticsEvent(plan.slug.toLowerCase(), 'SecurityPlanCard'));
  }, [plan.slug, sendAnalyticsEvent, setPlanAsGoal]);

  const tags: Tag[] | undefined = useMemo(() => plan.labels?.map((label) => ({
    color: labelColorsMap[label],
    text: label,
  })), [labelColorsMap, plan.labels]);

  return (
    <div className={styles.wrapper}>

      <div className={styles.gaugeWrapper} data-testid='gauge-wrapper'>
        <div className={styles.iconWrapper}>
          <JitIcon data-testid='author-icon' icon={(iconsMap[(plan.author?.toLowerCase() || '')] || iconsMap[DEFAULT_AUTHOR]).icon} size='30' />
        </div>

        <ScoreGauge score={score} showAnimation={score !== 0 || !plan.activeItemSlugs.size} />
      </div>

      <JitCard
        actionButtons={actionButtons}
        backgroundColor='transparent'
        description={plan.description}
        headerIcons={headerIcons}
        height='310px'
        tags={tags}
        testid={`security-plan-card-${plan.slug}`}
        title={plan.title || ''}
        width='325px'
      >
        <ControlsSummary
          active={plan.activeItemSlugs.size}
          passed={plan.passedItemSlugs.size}
          total={plan.allItemSlugs.size}
        />
      </JitCard>

      <SetAsGoalDialog
        isOpen={showSetAsGoalDialog}
        onClose={() => setShowSetAsGoalDialog(false)}
        onConfirm={onViewPlanClicked}
        plan={plan}
        variant='goToPlan'
      />

      <RemoveGoalDialog
        isOpen={showRemoveGoalDialog}
        onClose={() => setShowRemoveGoalDialog(false)}
        onConfirm={confirmRemoveGoal}
        plan={plan}
      />
    </div>
  );
};
