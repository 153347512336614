import { FC } from 'react';

import { JitText } from 'components/JitText/JitText';
import styles from 'pages/SbomPage/components/SbomTable/EmptyStateTemplate/EmptyStateTemplate.module.scss';

interface Props {
  title: string;
  description: string;
  buttonComponent: JSX.Element;
  titleIcon?: JSX.Element;
}
export const EmptyStateTemplate: FC<Props> = ({ title, description, buttonComponent, titleIcon }) => (
  <div className={styles.wrapper}>
    <div className={styles.titleWrapper}>
      { titleIcon }

      <JitText align='center' bold text={title} />
    </div>

    <div className={styles.descriptionWrapper}>
      <JitText align='center' text={description} />
    </div>

    <div className={styles.buttonWrapper}>
      {buttonComponent}
    </div>
  </div>
);
