export const HEADERS_LIST = [
  {
    label: 'dialogs.assetsManagement.accordion.aws',
    key: 'asset_name',
  },
  {
    label: 'dialogs.assetsManagement.accordion.account_id',
    key: 'aws_account_id',
  },
  {
    label: 'dialogs.assetsManagement.accordion.regions',
    key: 'aws_regions_to_scan',
  },
  {
    label: 'dialogs.assetsManagement.accordion.status',
    key: 'status',
  },
];
