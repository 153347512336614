import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, index, value, ...other } = props;

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role='tabpanel'
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: 3,
          paddingBottom: 3 }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
