/* eslint-disable react/jsx-max-depth */
import { t } from 'i18next';
import { FC } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import styles from './ActionCard.module.scss';

import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitText } from 'components/JitText/JitText';
import { ACTIONS_ROUTE, SPECIFIC_ACTION_ROUTE } from 'pages/OverviewPage/constants';
import colors from 'themes/colors.module.scss';
import { ActionButtonVariant, IAction } from 'types/interfaces';
import { ActionFiltersKeys } from 'types/interfaces/Actions/ActionFilters';

interface ActionCardProps {
  action: IAction;
  allActions: number;
  teamName?: string;
}

export const ActionCard: FC<ActionCardProps> = ({ action, allActions, teamName }) => {
  const navigate = useNavigate();
  const specificActionRoute = `${SPECIFIC_ACTION_ROUTE}${action.id}`;

  const label = teamName ? t('actions.card.viewAllActionsButton', { actionsNumber: allActions,
    teamName })
    : t('actions.card.viewAllActionsButtonNoTeam', { actionsNumber: allActions });

  const handleShowAllClick = () => {
    if (teamName) {
      navigate({
        pathname: `${ACTIONS_ROUTE}`,
        search: createSearchParams({ [ActionFiltersKeys.TEAMS]: [teamName] }).toString(),
      });
    } else navigate(ACTIONS_ROUTE);
  };
  return (
    <div
      className={styles.wrapper}
      data-testid='action-card'
      style={{ borderLeftColor: colors.severityRed }}
    >
      <div className={styles.cardContentWrapper}>

        <div className={styles.text}>
          <JitText params={{ actionTitle: action.title }} text='actions.card.title' />

          <JitText color={colors.lightGray} text={action.reason} />

          <div>
            <JitActionButton
              actionButton={{
                variant: ActionButtonVariant.SECONDARY,
                label,
                handleClick: handleShowAllClick,
              }}
            />
          </div>

        </div>

        <div className={styles.actionButton}>

          <JitActionButton actionButton={{
            variant: ActionButtonVariant.PRIMARY,
            label: 'actions.card.remediateButton',
            handleClick: () => { navigate(specificActionRoute); },
          }}
          />
        </div>
      </div>
    </div>

  );
};
