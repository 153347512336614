import { FC, useEffect } from 'react';

import { useSbomContext } from 'context/SbomContext/SbomContext';
import { SbomPage } from 'pages/SbomPage/SbomPage';

export const SbomPageWrapper: FC = () => {
  const { getReportStatus } = useSbomContext();

  useEffect(() => {
    getReportStatus();
  }, [getReportStatus]);
  return (
    <SbomPage />
  );
};
