import { config } from 'config';
import { GraphEntityType } from 'types/enums/ContextGraphEntityType';

const servicesNames = {
  jiraService: 'jira',
  linearService: 'linear',
  shortcutService: 'shortcut',
  secretsService: 'secrets',
  tenantService: 'tenant',
  planService: 'plans',
  docsService: 'docs',
  metricService: 'metric',
  assetService: 'asset',
  executionsService: 'execution',
  actionService: 'action',
  analyticsService: 'analytics',
  pipelineService: 'pipeline',
  sbomService: 'sbom',
  artifactsService: 'artifacts',
  notificationsService: 'notification',
  slackService: 'slack',
  teamService: 'teams',
  entitlementService: 'entitlements',
  integrationService: 'integration',
  findingService: 'findings',
  graphService: 'graphs',
  gitlabService: 'gitlab',
  policiesService: 'policies',
  workflowsService: 'workflows',
};

export const getApiUrls = {
  jiraService: {
    createTicket: () => `${servicesNames.jiraService}/ticket`,
  },
  linearService: {
    createTicket: () => `${servicesNames.linearService}/ticket`,
  },
  shortcutService: {
    createTicket: () => `${servicesNames.shortcutService}/ticket`,
  },
  docsService: {
    answer: () => config.docsQaUrl,
  },
  secretsService: {
    getTenantSecrets: () => `${servicesNames.secretsService}/`,
    saveTenantSecret: (secretName: string) => `${servicesNames.secretsService}/${secretName}`,
    deleteTenantSecret: (secretName: string) => `${servicesNames.secretsService}/${secretName}`,
  },
  tenantService: {
    getAllInstallations: () => `${servicesNames.tenantService}/installations`,
    fetchIntegrations: () => `${servicesNames.tenantService}/integrations`,
    patchPreferences: (preferenceType: string) => `${servicesNames.tenantService}/preferences/${preferenceType}`,
    fetchPreferences: () => `${servicesNames.tenantService}/preferences`,
    getTenantUsers: () => `${servicesNames.tenantService}/users`,
    createUserVendor: () => `${servicesNames.tenantService}/user/uservendor`,
    getUserVendorByVendor: (vendor: string) => `${servicesNames.tenantService}/user/uservendor/vendor/${vendor}`,
    updateUser: () => `${servicesNames.tenantService}/user/update`,
    signUp: () => `${servicesNames.tenantService}/sign-up`,
    setCentralizedRepo: (vendor: string, appId: string, installationId: string) => `${servicesNames.tenantService}/vendor/${vendor}/app_id/${appId}/installation/${installationId}/centralized-repo`,
    validateCentralizedRepo: (vendor: string, appId: string, installationId: string) => (
      `${servicesNames.tenantService}/vendor/${vendor}/app_id/${appId}/installation/${installationId}/centralized-repo/validate`
    ),
    partialUpdateOnboardingDetails: () => `${servicesNames.tenantService}/onboarding`,
    generateInvitationLink: () => `${servicesNames.tenantService}/invite`,
  },
  planService: {
    getPlans: () => `${servicesNames.planService}`,
    getPlansOld: () => `${servicesNames.planService}`,
    getPlanContent: () => `${servicesNames.planService}/jit-plan/content`,
    partialUpdatePlan: (slug: string) => `${servicesNames.planService}/${slug}`,
    getPlanDetails: (slug: string) => `${servicesNames.planService}/${slug}`,
    getPlanItems: (slug: string) => `${servicesNames.planService}/${slug}/items`,
    generatePlanReport: (slug: string) => `${servicesNames.planService}/${slug}/report`,
    rerunPlanItem: (planSlug: string, itemSlug: string) => `${servicesNames.planService}/${planSlug}/items/${itemSlug}/rerun`,
    updateConfigurations: () => `${servicesNames.planService}/configuration-file`,
    getAllFilesMetadata: () => `${servicesNames.planService}/files`,
    getIntegrationFile: () => `${servicesNames.planService}/integration-file`,
    uploadFileToCentralizedRepo: () => `${servicesNames.planService}/files`,
    getPlanStatusesForAsset: () => `${servicesNames.planService}/jit_plan/statuses`,
    getAllTemplateWorkflows: () => `${servicesNames.planService}/template/workflows`,
    getConfigurations: () => `${servicesNames.planService}/configuration-file`,
    getAllPlanTemplates: () => `${servicesNames.planService}/template/plans`,
    getAllPlanItemTemplates: () => `${servicesNames.planService}/template/items`,
    commitPlan: (vendor: string) => `${servicesNames.planService}/vendor/${vendor}/plan/jit-plan`,
    createAwsAccount: () => `${servicesNames.planService}/aws/create`, // Assuming no dynamic parameters in URL
    reloadAwsAccounts: () => `${servicesNames.planService}/aws/reload`, // Assuming no dynamic parameters in URL

  },
  metricService: {
    patchDevEfficiencyCalculation: () => `${servicesNames.metricService}/statistics/dev-efficiency-calculation`,
    getSnapshot: (snapshotId: string) => `${servicesNames.metricService}/snapshot/${snapshotId}`,
    getSnapshots: () => `${servicesNames.metricService}/snapshot`,
    deleteSnapshot: (snapshotId: string) => `${servicesNames.metricService}/snapshot/${snapshotId}`,
  },
  assetService: {
    getAssets: () => `${servicesNames.assetService}`,
    getAssetsFilterValues: () => `${servicesNames.assetService}/filters-values`,
    updateMultipleAssets: () => `${servicesNames.assetService}/assets`,
    updateAsset: (asset_id: string) => `${servicesNames.assetService}/asset/${asset_id}`,
    getAssetsStatistics: () => `${servicesNames.assetService}/statistics`,
    getInitialScanStats: () => `${servicesNames.assetService}/initial-slug-stats`,
    getAssetsCount: () => `${servicesNames.assetService}/count`,
  },
  executionsService: {
    getExecutionArtifacts: (jitEventId: string, executionId: string) => `${servicesNames.executionsService}/${jitEventId}/executions/${executionId}/zipball/artifacts`,
    fetchExecutions: () => `${servicesNames.executionsService}/`,
    getExecutionLog: (jitEventId: string, executionId: string) => `${servicesNames.executionsService}/${jitEventId}/${executionId}/log`,
  },
  actionService: {
    fetchActions: (queryParams: string) => {
      if (queryParams) {
        return `${servicesNames.actionService}/actions/v2?${queryParams}`;
      }
      return `${servicesNames.actionService}/actions/v2`;
    },
    fetchActionsFiltersDistinctValues: () => `${servicesNames.actionService}/filters-values`,
    fetchActionFindings: (actionId: string, queryParams: string) => {
      if (queryParams) {
        return `${servicesNames.actionService}/actions/${actionId}/findings?${queryParams}`;
      }
      return `${servicesNames.actionService}/actions/${actionId}/findings`;
    },
    openPRForActionFindings: () => `${servicesNames.actionService}/fix-prs`,
    createActionFindingsIgnoreRules: () => `${servicesNames.actionService}/ignore-findings`,
    fetchActionsCount: (incompletedStatuses: string, queryParams: string) => {
      if (queryParams) {
        return `${servicesNames.actionService}/count?${incompletedStatuses}&${queryParams}`;
      }
      return `${servicesNames.actionService}/count?${incompletedStatuses}`;
    },
  },
  analyticsService: {
    identifyUser: `${servicesNames.analyticsService}/contact`,
  },
  pipelineService: {
    getNewPipelines: () => `${servicesNames.pipelineService}/pipelines`,
    getPipelineById: (pipelineId: string) => `${servicesNames.pipelineService}/pipeline/${pipelineId}`,
    getDeploymentsFromCreatedAfter: (environment: string) => `${servicesNames.pipelineService}/pipelines/deployments/environment/${environment}`,
  },
  sbomService: {
    getSbomReportDownload: () => `${servicesNames.sbomService}/`,
    getSbomComponents: () => `${servicesNames.sbomService}/component-groups`,
    getSbomReportStatus: () => `${servicesNames.sbomService}/status`,
  },
  artifactsService: {
    getArtifacts: () => `${servicesNames.artifactsService}/`,
    downloadArtifact: (artifactId: string) => `${servicesNames.artifactsService}/${artifactId}/export`,
  },
  notificationsService: {
    invitation: () => `${servicesNames.notificationsService}/invitation`,
  },
  slackService: {
    getChannels: () => `${servicesNames.slackService}/channels`,
    getUsers: () => `${servicesNames.slackService}/users`,
  },
  teamsService: {
    fetchTeams: () => `${servicesNames.teamService}`,
    fetchTeamById: (teamId: string) => `${servicesNames.teamService}/${teamId}`,
    deleteTeamById: (teamId: string) => `${servicesNames.teamService}/${teamId}`,
    fetchMembersById: (teamId: string) => `${servicesNames.teamService}/${teamId}/members`,
    preferences: (teamId: string) => `${servicesNames.teamService}/${teamId}/preferences`,
    updateTeamChecks: (teamId: string) => `${servicesNames.teamService}/${teamId}/checks`,
    uploadTeamsStructure: () => `${servicesNames.teamService}/import`,
    getTeamsStructureExample: () => `${servicesNames.teamService}/import/example`,
  },
  entitlementService: {
    getFeatures: () => `${servicesNames.entitlementService}/features`,
  },
  integrationService: {
    getIntegrations: () => `${servicesNames.integrationService}`,
    getParagonEnv: () => `${servicesNames.integrationService}/paragon-env`,
    createTicket: () => `${servicesNames.integrationService}/ticket`,
    publishLifecycleNotification: () => `${servicesNames.integrationService}/lifecycle/notify`,
  },
  findingService: {
    createTicket: () => `${servicesNames.findingService}/ticket`,
    getFindingsCount: () => `${servicesNames.findingService}/count`,
  },
  graphService: {
    getPriorityFactors: () => `${servicesNames.graphService}/factors`,
    createFeedback: () => `${servicesNames.graphService}/feedback`,
    updateFindingPriorityContext: (findingId: string) => `${servicesNames.graphService}/factors/findings/${findingId}`,
    updateAssetPriorityContext: (assetId: string) => `${servicesNames.graphService}/factors/assets/${assetId}`,
    getEntityGraph: (entityType: GraphEntityType, entityId: string) => `${servicesNames.graphService}/entities/${entityType}/${entityId}`,
  },
  gitlabService: {
    createInstallation: () => `${servicesNames.gitlabService}/installations`,
    getGroups: () => `${servicesNames.gitlabService}/groups`,
    getProjects: (groupId: string) => `${servicesNames.gitlabService}/groups/${groupId}/projects`,
    updateInstallationProjects: (installationId: string) => `${servicesNames.gitlabService}/installations/${installationId}/projects`,
  },
  quickstartGuide: {
    getChecks: () => `${servicesNames.tenantService}/checks`,
    putCheck: (checkSlug: string) => `${servicesNames.tenantService}/checks/${checkSlug}`,
  },
  policiesService: {
    getPoliciesTemplate: () => `${servicesNames.policiesService}`,
    getPolicyRules: (slug: string) => `${servicesNames.policiesService}/${slug}/rules`,
  },
  workflowsService: {
    getWorkflows: () => `${servicesNames.workflowsService}`,
  },
};
