import { styled } from '@mui/material/styles';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import { CSSProperties } from 'react';

import { borderRadius, hoveredBorderColor, rowBackgroundColor, borderColor } from 'components/JitTable/components/JitTableRow/utils/constants';
import { CellVerticalAlign, DefaultCellVerticalAlign } from 'components/JitTable/constants';
import colors from 'themes/colors.module.scss';

interface RowProps extends TableRowProps {
  isselected?: string;
  style?: CSSProperties;
  hoverStyle?: CSSProperties;
  verticalalign?: CellVerticalAlign;
  isClickable: boolean;
}

export const StyledTableRow = styled(TableRow)<RowProps>(({
  isselected, style, verticalalign = DefaultCellVerticalAlign, hoverStyle, isClickable,
}) => ({
  backgroundColor: rowBackgroundColor,
  height: 70,
  borderColor,
  borderWidth: isClickable ? '1px 0' : '0px 0px',
  borderStyle: 'solid',
  position: 'relative',

  ...(isselected && {
    borderColor: colors.blue02,
  }),
  '&:hover': {
    borderColor: hoveredBorderColor,
    ...hoverStyle,
  },
  ...(style || {}),

  td: {
    borderWidth: 'inherit',
    borderColor: 'inherit',
    verticalAlign: verticalalign,
    borderStyle: 'solid',

    '&:first-of-type': {
      borderTopLeftRadius: borderRadius,
      borderBottomLeftRadius: borderRadius,
      borderRightWidth: 0,
      borderLeftWidth: isClickable && 1,
    },

    '&:last-of-type': {
      borderTopRightRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
      borderRightWidth: isClickable && 1,
      borderLeftWidth: 0,
    },
  },
}));
