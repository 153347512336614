import { uniqBy } from 'lodash';
import { createContext, FC, PropsWithChildren, useContext, useMemo, useState } from 'react';

import { IActiveDialog } from './interfaces';

import { Dialogs } from 'types/enums';

interface IDialogsContext {
  activeDialogs: IActiveDialog[];
  showDialog: (dialog: IActiveDialog) => void;
  hideDialog: (type: Dialogs) => void;
}

export const DialogsContext = createContext<IDialogsContext>({
  activeDialogs: [],
  showDialog: () => {},
  hideDialog: () => {},
});

export const useDialogsContext = () => useContext(DialogsContext);

export const DialogsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [activeDialogs, setActiveDialogs] = useState<IActiveDialog[]>([]);

  const showDialog = (dialog: IActiveDialog) => {
    setActiveDialogs((curActiveDialogs) => uniqBy([...curActiveDialogs, dialog], 'type'));
  };

  const hideDialog = (type: Dialogs) => {
    setActiveDialogs((curActiveDialogs) => curActiveDialogs.filter((curDialog) => curDialog.type !== type));
  };

  const value = useMemo(() => ({
    activeDialogs,
    showDialog,
    hideDialog,
  }), [activeDialogs]);

  return (
    <DialogsContext.Provider value={value}>
      {children}
    </DialogsContext.Provider>
  );
};
