import { FC, useMemo } from 'react';

import { SlackIntegrationCard } from '../InviteMembersDialog/SlackIntegrationCard';

import styles from './IntegrateSlackContent.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

export const IntegrateSlackContent: FC = () => {
  const content = useMemo(() => (
    <div className={styles.wrapper} data-testid='IntegrateSlackContent'>

      <div className={styles.textWrapper}>
        <JitText
          bold
          data-testid='integrate-slack-title'
          size='l'
          text='pages.teams.invite.dialog.integrateSlack.title'
        />

        <JitText
          color={colors.lightGray}
          data-testid='integrate-slack-subtitle'
          size='m'
          text='pages.teams.invite.dialog.integrateSlack.subtitle'
        />
      </div>

      <SlackIntegrationCard
        configureText='pages.teams.invite.dialog.integrateSlack.card.configure'
        description='pages.teams.invite.dialog.integrateSlack.card.description'
      />

    </div>
  ), []);

  return (
    content
  );
};
