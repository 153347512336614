import { useCallback } from 'react';

import { constants } from 'globalConstants';

const handleSetGoalPlan = (searchParams: URLSearchParams) => {
  /*
    * The purpose of this function is to save if the user have a goal plan that make him signup.
    * Logic:
    * 1. If the user gets to sign up or login with a goal plan save it on session storage.
    * */
  const goalPlan = searchParams.get(constants.GOAL_PLAN_KEY);
  if (goalPlan) {
    sessionStorage.setItem(constants.GOAL_PLAN_KEY, goalPlan);
  }
};

const handleSetShouldSignup = (pathname: string, search: string) => {
  /*
    * The purpose of this function is to save if we should call signup function to sessionStorage.
    * Logic:
    * 1. If the user gets to sign up or login set the should signup key to true.
    * */
  const currentPath = pathname + search;

  const signupPaths = [
    constants.SIGN_UP_PATH,
    constants.LOGIN_PATH,
    constants.ACCOUNT_SOCIAL_SUCCESS_PATH,
    constants.ACCOUNT_SAML_SUCCESS_PATH,
    constants.ACCOUNT_OIDC_SUCCESS_PATH,
  ];
  const shouldSignup = signupPaths.some((path) => currentPath.includes(path));
  if (shouldSignup) {
    sessionStorage.setItem(constants.SHOULD_SIGNUP_KEY, shouldSignup.toString());
    sessionStorage.setItem(constants.IS_SIGNUP_KEY, shouldSignup.toString());
  }
};

const handleSetInvitationToken = (searchParams: URLSearchParams) => {
  /*
  * The purpose of this function is to save the invitation token and optional state if they exist to sessionStorage so we can use it for sign up.
  * Logic:
  * 1. If the route has invite token save it.
  * 2. If the route has state save it.
  * */
  const invitationToken = searchParams.get(constants.INVITATION_TOKEN_KEY);
  if (invitationToken) {
    sessionStorage.setItem(constants.INVITATION_TOKEN_KEY, invitationToken);
  }
  const stateToken = searchParams.get(constants.STATE_TOKEN_KEY);
  if (stateToken) {
    sessionStorage.setItem(constants.STATE_TOKEN_KEY, stateToken);
  }
};

const handleSetRedirectPath = (pathname: string, search: string, searchParams: URLSearchParams) => {
  /*
    * The purpose of this function is to save the current url to sessionStorage so we can redirect to it after login.
    * Logic:
    * 1. If the route is auth related, skip.
    * 2. If search params contains 'invitationToken':
    *   2.1. Save the route in session storage, but without the invitationToken param.
    *   2.2. Redirect to the signup page with the invitationToken param.
    * 3. Else, Save the route in session storage.
    * */
  let currentPath = pathname + search;

  const invitationToken = searchParams.get(constants.INVITATION_TOKEN_KEY);
  const authRelatedRoutes = [constants.LOGIN_PATH, constants.LOGOUT_PATH, constants.ACCOUNT_SOCIAL_SUCCESS_PATH,
    constants.SIGN_UP_PATH, constants.routes.THIRD_PARTY_AUTHENTICATION, constants.GITHUB_INSTALLATION_REDIRECT_PATH,
    constants.ACCOUNT_SAML_SUCCESS_PATH, constants.ACCOUNT_OIDC_SUCCESS_PATH];
  const isAuthRelatedRoute = authRelatedRoutes.some((path) => currentPath.includes(path));
  if (isAuthRelatedRoute || pathname === '/') return;

  if (invitationToken) {
    searchParams.delete(constants.INVITATION_TOKEN_KEY);
    currentPath = `${pathname}?${searchParams.toString()}`;
    const signupPath = `${constants.SIGN_UP_PATH}?${constants.INVITATION_TOKEN_KEY}=${invitationToken}`;
    window.location.href = signupPath;
  }

  sessionStorage.setItem(constants.INITIAL_PATHNAME_KEY, currentPath);
};

export const useSetPathBasedItemsIntoSessionStorage = () => {
  /*
  * This function is called on every time the app mounts
  * The purpose of this function is to save to sessionStorage based url data.
  * */
  const setPathBasedItemsIntoSessionStorage = useCallback(() => {
    const { pathname = '', search = '' } = window.location;
    const searchParams = new URLSearchParams(search);
    if (!sessionStorage.getItem(constants.IS_REDIRECTED)) {
      handleSetRedirectPath(pathname, search, searchParams);
    }

    handleSetInvitationToken(searchParams);
    handleSetShouldSignup(pathname, search);
    handleSetGoalPlan(searchParams);
  }, []);

  return { setPathBasedItemsIntoSessionStorage };
};
