import { FC } from 'react';

import { useGetColumns } from './useGetColumns';
import styles from './WorkflowsTable.module.scss';

import { useGetTableInstance } from 'components/JitTable/hooks/useGetTableInstance';
import { JitTable } from 'components/JitTable/JitTable';
import { IWorkflow } from 'types/interfaces/Workflows/IWorkflow';

interface WorkflowsTableProps {
  workflows: IWorkflow[];
  isFetchingNextPage?: boolean;
  fetchNextPage: () => void;
}

export const WorkflowsTable: FC<WorkflowsTableProps> = ({ workflows, fetchNextPage, isFetchingNextPage }) => {
  const onSelectRow = ({ id }: IWorkflow) => {
    // not implemented yet
    // eslint-disable-next-line no-console
    console.log('Selected workflow id:', id);
  };

  const { columns } = useGetColumns();
  const tableInstance = useGetTableInstance(columns, workflows);
  return (
    <div className={styles.tableWrapper} data-testid='workflows-table'>
      <JitTable
        handleReachScrollEnd={{
          callback: fetchNextPage,
        }}
        isFetching={isFetchingNextPage}
        isLoading={false}
        onSelectRow={onSelectRow}
        showPagination={false}
        tableInstance={tableInstance}
      />
    </div>
  );
};
