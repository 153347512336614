import Chip from '@mui/material/Chip';
import { FC, useState } from 'react';

import { IPlan } from '../../interfaces/IPlan';

import styles from './Plan.module.scss';

import { ArrowLeft } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import colors from 'themes/colors.module.scss';

export interface IPlanProps {
  plan: IPlan;
  onPlanClicked: (e: React.MouseEvent) => void;
  onPlanLinkClicked: (e: React.MouseEvent) => void;
}

export const Plan: FC<IPlanProps> = ({ plan, onPlanClicked, onPlanLinkClicked }) => {
  const [isHovered, setIsHovered] = useState(false);

  const onMouseEnter = () => {
    setIsHovered(true);
  };

  const onMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className={styles.wrapper}>
      <Chip
        data-testid='plan-chip'
        id={plan.key}
        label={plan.name}
        onClick={onPlanClicked}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        sx={{
          borderColor: (plan.isSelected || isHovered) ? `${plan.color}` : `${plan.color}33`, // 20% opacity
          backgroundColor: plan.isSelected ? `${plan.color}1A` : 'transparent',
          maxWidth: '75%',
          marginRight: '10px',
        }}
        variant='outlined'
      />

      {plan.isSelected && (
        <JitTooltip
          followCursor={false}
          placement='top-start'
          title='pages.securityMapping.coverageOffering.linkToPlan.tooltip'
          withHighlightShadow
        >
          <JitButton
            className={styles.linkButton}
            data-testid='link-button'
            id={plan.key}
            onClick={onPlanLinkClicked}
          >
            <JitIcon
              color={colors.iris}
              data-testid='integration-vendor-icon'
              icon={ArrowLeft}
              size='14px'
            />
          </JitButton>
        </JitTooltip>
      )}
    </div>
  );
};
