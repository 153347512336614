import { useCallback, useState } from 'react';

import { theLoopColorTheme } from '../components/TheLoop/constants';
import { IPlan } from '../interfaces/IPlan';
import { ISegmentsMapping } from '../interfaces/ISegmentsMapping';

export const useLoopSegments = (securityPlans: IPlan[]) => {
  const [segments, setSegments] = useState<ISegmentsMapping>({});

  const getActivities = useCallback((segment: string, isSelected: boolean) => securityPlans
    .filter((plan) => plan.isSelected === isSelected)
    .flatMap((plan) => plan.stagesActivities[segment] || []), [securityPlans]);

  const getSelectedSegmentActivitiesRatio = useCallback((segment: string) => {
    const selectedActivities = [...new Set(getActivities(segment, true))];
    const unselectedActivities = [...new Set(getActivities(segment, false))];

    const totalActivitiesCount = selectedActivities.length + unselectedActivities.length;
    const selectedActivitiesRatio = selectedActivities.length / totalActivitiesCount;

    return selectedActivitiesRatio;
  }, [getActivities]);

  const getSegmentColor = useCallback((segment: string) => {
    const selectedActivitiesRatio = getSelectedSegmentActivitiesRatio(segment);

    const colorIndex = Math.min(Math.round(selectedActivitiesRatio * 4), theLoopColorTheme.length - 1);

    return theLoopColorTheme[colorIndex];
  }, [getSelectedSegmentActivitiesRatio]);

  const updateSegments = useCallback(() => {
    const newSelectedSegments: ISegmentsMapping = {};

    securityPlans.forEach((plan) => {
      if (plan.isSelected) {
        Object.entries(plan.stagesActivities).forEach(([stage, activities]) => {
          if (stage in newSelectedSegments) {
            newSelectedSegments[stage].activities = [...newSelectedSegments[stage].activities, ...activities];
          } else {
            newSelectedSegments[stage] = {
              activities,
              colors: {
                base: '',
                text: '',
              }, // Initialize with an empty string
            };
          }
        });
      }
    });

    // Ensure distinct activities and calculate color for each stage
    Object.keys(newSelectedSegments).forEach((stage) => {
      newSelectedSegments[stage].activities = [...new Set(newSelectedSegments[stage].activities)];
      newSelectedSegments[stage].colors = getSegmentColor(stage);
    });

    setSegments(newSelectedSegments);
  }, [securityPlans, getSegmentColor]);

  return {
    segments,
    updateSegments,
  };
};
