import { createContext, useContext } from 'react';

import { IWorkflow } from 'types/interfaces/Workflows/IWorkflow';

interface EditWorkflowContextType {
  workflow: IWorkflow;
  setWorkflow: React.Dispatch<React.SetStateAction<IWorkflow>>;
  onStepClick: (nodeId: string) => void;
}

export const EditWorkflowContext = createContext<EditWorkflowContextType | null>(null);

export const useEditWorkflowContext = () => {
  const context = useContext(EditWorkflowContext);
  if (!context) {
    throw new Error('useEditWorkflowContext must be used within an EditWorkflowContext.Provider');
  }
  return context;
};
