import { getTimezoneUTCFormat } from 'pages/PipelinesPage/utils';

export const formatDate = (date?: Date | string, withTime: boolean = true, withYear: boolean = true): string => {
  if (!date) return '';

  const localDate = typeof date === 'string' ? getTimezoneUTCFormat(date) : date;
  if (!localDate) return '';

  const [, month, day, year] = localDate.toDateString().split(' ');
  let formattedDate = `${month} ${parseInt(day, 10)}`;

  if (withYear) {
    formattedDate += `, ${year}`;
  }

  if (withTime) {
    const [hour, minute] = localDate.toTimeString().split(':');
    formattedDate += ` at ${hour}:${minute}`;
  }

  return formattedDate;
};

export const capitalizeFirstLetterOfEachWord = (str: string, shouldLowercaseFirst: boolean = false) => {
  let stringToFormat = str;
  if (shouldLowercaseFirst) {
    stringToFormat = str.toLowerCase();
  }
  const words = stringToFormat.split(' ');
  const newWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  return newWords.join(' ');
};

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
