import { FC } from 'react';

import { GraphEmpty, GraphLoading } from '../components';
import styles from '../Graphs.module.scss';

import { GraphHeader, GraphBody } from './components';
import { isEmptyDay, yAxisTicksFormatter } from './utils';

import { TimeAgo } from 'types/enums';
import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { IMTTRMetric } from 'types/interfaces/Metrics/PerformanceMetrics/MTTR';

interface Props {
  mttrMetric: MetricWithLoadingIndication<IMTTRMetric>;
  xAxisTicks: number[];
  period: TimeAgo;
}

export const MTTRGraph: FC<Props> = ({ mttrMetric, xAxisTicks, period }) => {
  const days = mttrMetric.metric?.days ?? [];
  const hasData = days.some((day) => !isEmptyDay(day, 'preProduction') || !isEmptyDay(day, 'production'));
  let formattedAvgValue;
  if (typeof mttrMetric.metric?.average === 'number') {
    if (mttrMetric.metric?.average === 0) {
      formattedAvgValue = '0h';
    } else {
      formattedAvgValue = yAxisTicksFormatter(mttrMetric.metric?.average);
    }
  }
  return (
    <div className={styles.metricWrapper} data-testid='mttrGraph'>
      <GraphHeader
        avgValue={formattedAvgValue}
        changeRate={mttrMetric.metric?.changeRate}
        description='pages.performance.graphs.mttr.description'
        isLoading={mttrMetric.isLoading}
        performanceRateOptions={mttrMetric.metric?.performanceRateOptions}
        popperDescription='pages.performance.graphs.mttr.widget.description'
        popperTitle='pages.performance.graphs.mttr.widget.title'
        positiveTrendIndicatorDirection={mttrMetric.metric?.positiveTrendIndicatorDirection}
        rank={mttrMetric.metric?.rank}
        title='pages.performance.graphs.mttr.title'
      />

      {mttrMetric.isLoading && <GraphLoading />}

      {!mttrMetric.isLoading && !hasData && (
        <GraphEmpty />
      )}

      {!mttrMetric.isLoading && hasData && (<GraphBody days={days} period={period} xAxisTicks={xAxisTicks} />)}
    </div>
  );
};
