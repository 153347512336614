import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { JitText } from 'components/JitText/JitText';
import { constants } from 'globalConstants';
import colors from 'themes/colors.module.scss';
import { FindingStatus } from 'types/enums';

interface Props {
  planItemSlug: string;
  findingsCount: number;
}

export const PlanItemSeeFindings: FC<Props> = ({ planItemSlug, findingsCount }) => {
  const navigate = useNavigate();
  const onSeeFindingsClick = () => {
    navigate(`/${constants.routes.FINDINGS}?plan_item=${planItemSlug}&resolution=${FindingStatus.OPEN}&ignored=${false}`);
  };

  const text = `pages.plan.planItem.findingsCount.${findingsCount === 1 ? 'singular' : 'plural'}`;

  return (
    <div data-testid='see-findings-container'>
      <JitText color={colors.iris} onClick={onSeeFindingsClick} params={{ count: findingsCount }} text={text} />
    </div>
  );
};
