export enum ActionFiltersKeys {
  ASSET_NAMES = 'asset_names',
  PLAN_ITEMS = 'plan_items',
  TEAMS = 'teams',
}

export interface IActionFilters {
  [ActionFiltersKeys.ASSET_NAMES]: string[];
  [ActionFiltersKeys.PLAN_ITEMS]: string[];
  [ActionFiltersKeys.TEAMS]: string[];
}
