import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import { IAction, IActionFinding, ICreateIgnoreRulesRequest, IFilter, IFilterOption } from 'types/interfaces';
import { ActionFiltersKeys, IActionFilters } from 'types/interfaces/Actions/ActionFilters';
import { IActionCountResponse } from 'types/interfaces/Actions/IActionCountResponse';
import { IFixAction } from 'types/interfaces/Actions/IFixAction';
import { IPaginatedResponse } from 'types/interfaces/IPaginatedResponse/IPaginatedResponse';
import { parseObjectToQueryParams } from 'utils';

type FetchActionsParams = {
  limit?: number;
  after?: string;
  [ActionFiltersKeys.ASSET_NAMES]?: string[];
  [ActionFiltersKeys.PLAN_ITEMS]?: string[];
  [ActionFiltersKeys.TEAMS]?: string[];
};

export const useActionService = () => {
  const { client } = useClient();

  const getActionsQueryParams = (after?: string, filters?: IFilter[]): string => {
    const queryParams: FetchActionsParams = {};

    filters?.forEach((filter) => {
      const entityKey = filter.entityKey as ActionFiltersKeys.ASSET_NAMES | ActionFiltersKeys.PLAN_ITEMS | ActionFiltersKeys.TEAMS;

      if (Array.isArray(filter.selectedValue) && filter.selectedValue.length > 0) {
        const selectedValues = filter.selectedValue.map((value: IFilterOption) => value.value);
        queryParams[entityKey] = selectedValues;
      }
    });

    if (after) {
      queryParams.after = after;
    }

    return parseObjectToQueryParams(queryParams);
  };

  const getIncompletedActionsQueryParams = (): string => {
    const incompletedStatuses = ['NEW', 'IN_PROGRESS'];
    return `statuses=${incompletedStatuses.join(',')}`;
  };

  const fetchActions = async (after?: string, filters?: IFilter[]): Promise<IPaginatedResponse<IAction> | undefined> => {
    const queryParams = getActionsQueryParams(after, filters);
    const url = getApiUrls.actionService.fetchActions(queryParams);

    const response = await client.get<IPaginatedResponse<IAction>>({
      url,
      allowedStatuses: [200],
    });

    return response?.data;
  };

  const fetchActionsFiltersDistinctValues = async (): Promise<IActionFilters | undefined> => {
    const url = getApiUrls.actionService.fetchActionsFiltersDistinctValues();
    const response = await client.get<IActionFilters>({
      url,
      allowedStatuses: [200],
    });

    return response?.data;
  };

  const fetchActionFindings = async (actionId: string, after?: string, filters?: IFilter[]): Promise<IPaginatedResponse<IActionFinding> | undefined> => {
    const params: FetchActionsParams = {};
    const queryParams = getActionsQueryParams(after, filters);
    const url = getApiUrls.actionService.fetchActionFindings(actionId, queryParams);

    const response = await client.get<IPaginatedResponse<IActionFinding> | undefined>({
      url,
      allowedStatuses: [200],
      requestConfig: {
        params,
      },
    });

    return response?.data;
  };

  const openPRForActionFindings = async (payload: IFixAction): Promise<undefined> => {
    const url = getApiUrls.actionService.openPRForActionFindings();
    const response = await client.post({
      url,
      allowedStatuses: [200],
      requestConfig: {
        data: payload,
      },
    });

    return response?.data;
  };

  const createActionFindingsIgnoreRules = async (payload: ICreateIgnoreRulesRequest): Promise<undefined> => {
    const url = getApiUrls.actionService.createActionFindingsIgnoreRules();
    const response = await client.post({
      url,
      allowedStatuses: [200],
      requestConfig: {
        data: payload,
      },
    });

    return response?.data;
  };

  const fetchActionsCount = async (filters?: IFilter[]): Promise<IActionCountResponse | undefined> => {
    const incompletedStatuses = getIncompletedActionsQueryParams();
    const queryParams = getActionsQueryParams(undefined, filters);
    const url = getApiUrls.actionService.fetchActionsCount(incompletedStatuses, queryParams);
    const response = await client.get<IActionCountResponse>({
      url,
      allowedStatuses: [200],
    });

    return response?.data;
  };

  return {
    fetchActions,
    fetchActionsFiltersDistinctValues,
    fetchActionFindings,
    openPRForActionFindings,
    createActionFindingsIgnoreRules,
    fetchActionsCount,
  };
};
