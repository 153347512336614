import { CustomNodeData } from '../types';

import { WizIcon } from 'assets';

export const isExternalVendorNode = (customNodeData: CustomNodeData) => customNodeData?.type === 'Wiz Issue';

export const getExternalLink = (customNodeData: CustomNodeData) => customNodeData?.metadata?.wiz?.issueUrl;

export const getVendorFactors = (customNodeData: CustomNodeData) => (customNodeData?.metadata?.wiz?.risks || []) as string[];

export const getVendorIcon = (customNodeData: CustomNodeData) => {
  if (customNodeData.metadata && 'wiz' in customNodeData.metadata) {
    return WizIcon;
  }
  return null;
};

export const getVendorFormattedFactorText = (factor: string) => factor.replaceAll('_', ' ');
