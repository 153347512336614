import { IPlanInstanceItem } from 'types/interfaces';

export const getPlanItemSlug = (planItem: IPlanInstanceItem): string => {
  // For example: planItem.uses = 'jitsecurity-controls/jit-plans/items/code/item-code-vulnerability.yml@latest';
  const slicedUses = planItem.uses.split('/');
  const rawSlug = slicedUses[slicedUses.length - 1]; // In this example: rawSlug = 'item-code-vulnerability.yml@latest'
  const slug = rawSlug.split('.yml')[0]; // In this example: slug = 'item-code-vulnerability'

  return slug;
};
