import { FC, useState } from 'react';

import { RankBadge } from '../RankBadge/RankBadge';
import { RankPopperContent } from '../RankPopperContent/RankPopperContent';

import { JitPopper } from 'components/JitPopper/JitPopper';
import { IPerformanceRateOption, PerformanceRateRank } from 'types/interfaces/Metrics/PerformanceRateOption';

interface Props {
  rank: PerformanceRateRank;
  avgValue?: string | null
  popperTitle?: string
  popperDescription?: string
  performanceRateOptions?: IPerformanceRateOption[]
  isLoading: boolean
}

export const RankBadgeWithPopover: FC<Props> = ({ rank, avgValue, popperTitle, popperDescription, performanceRateOptions, isLoading }) => {
  const [isHoverInfoIcon, setIsHoverInfoIcon] = useState(false);
  if (!performanceRateOptions) {
    return null;
  }

  return (
    <JitPopper
      content={(
        <RankPopperContent
          avgValue={avgValue!}
          currentRank={rank!}
          description={popperDescription!}
          metricName={popperTitle!}
          performanceRateOptions={performanceRateOptions}
        />
      )}
      isWithArrow={false}
      open={!isLoading && isHoverInfoIcon}
    >
      <div
        onMouseEnter={() => { setIsHoverInfoIcon(true); }}
        onMouseLeave={() => { setIsHoverInfoIcon(false); }}
      >
        <RankBadge rank={rank} />
      </div>
    </JitPopper>
  );
};
