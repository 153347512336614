import { FC, ReactElement } from 'react';

import styles from './CoverageCard.module.scss';

import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { ActionButton } from 'types/interfaces';

export type CoverageCardSize = 'm' | 'l';

interface Props {
  content: ReactElement;
  actionButton?: ActionButton;
  size?: CoverageCardSize;

}

const sizeToStyle = {
  m: { minWidth: '324px' },
  l: { minWidth: '708px' },
};

export const CoverageCard: FC<Props> = ({ content, actionButton, size = 'm' }) => (
  <div className={styles.card} style={sizeToStyle[size]}>
    <div className={styles.content}>
      {content}
    </div>

    { actionButton && (
    <div className={styles.buttonWrapper}>
      <JitActionButton actionButton={actionButton} />
    </div>
    )}
  </div>
);
