import { FC, useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { createSearchParams, useNavigate } from 'react-router-dom';

import styles from './MostDetectedWidget.module.scss';

import { JitText } from 'components/JitText/JitText';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { constants } from 'globalConstants';
import { GraphEmpty, GraphLoading } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/components';
import { useFindingsService } from 'services/FindingsService/useFindingService';
import colors from 'themes/colors.module.scss';
import { TimeAgo } from 'types/enums';
import { Queries } from 'types/enums/Queries';
import { ITableQueryParams } from 'types/interfaces';
import { IGroupByCount } from 'types/interfaces/Counts/ICounts';

type Props = {
  queryParams: ITableQueryParams;
  period: TimeAgo;
};

export const MostDetectedWidget: FC<Props> = ({ queryParams, period }) => {
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const { getFindingsCount } = useFindingsService();
  const navigate = useNavigate();

  const { data, isLoading, isError } = useQuery([Queries.FindingsCount, queryParams], () => getFindingsCount(queryParams, false));

  const handleHover = useCallback((index: number) => setHoveredItem(index), []);
  const clearHover = useCallback(() => setHoveredItem(null), []);

  const handleClick = (value: string) => {
    navigate({
      pathname: `/${constants.routes.FINDINGS}`,
      search: createSearchParams({
        [String(queryParams.group_by)]: value,
        time_ago: period,
      }).toString(),
    });
  };

  if (isLoading) return <GraphLoading />;

  const topFindings = (data?.data as IGroupByCount[])?.slice(0, 3);

  if (isError || !topFindings || !topFindings.length) return <GraphEmpty />;

  return (
    <div className={styles.wrapper} data-testid='mostDetectedWidget'>
      <div className={styles.row}>
        <JitText horizontalAlign='end' muted size='xs' text='pages.securityImpactV2.sections.performance.widgets.mostDetectedFindings.headers.count' />

        <JitText muted size='xs' text='pages.securityImpactV2.sections.performance.widgets.mostDetectedFindings.headers.key' />

      </div>

      {topFindings.map((item, index) => (
        <div
          key={item.key}
          className={styles.row}
          onBlur={clearHover}
          onClick={() => handleClick(item.key)}
          onFocus={() => handleHover(index)}
          onKeyDown={(e) => e.key === 'Enter' && handleClick(item.key)}
          onMouseEnter={() => handleHover(index)}
          onMouseLeave={clearHover}
          role='button'
          tabIndex={0}
        >
          <JitText text={item.count} />

          <div className={styles.rightColumn}>
            <TooltipOnlyOnOverflow noWrap text={item.key} />

            {hoveredItem === index && (
              <JitText
                color={colors.iris}
                minWidth={140}
                text='pages.securityImpactV2.sections.performance.widgets.mostDetectedFindings.navigate'
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
