import { FC } from 'react';

import styles from './SeeDetailsButton.module.scss';

import { JitText } from 'components/JitText/JitText';

interface Props {
  onClick: () => void;
  disabled?: boolean;
}

export const SeeDetailsButton: FC<Props> = ({ onClick, disabled }) => (
  <div
    className={disabled ? styles.disabledButton : styles.button}
    onClick={disabled ? undefined : onClick}
    role='button'
    tabIndex={0}
  >
    <JitText size='s' text='pages.risks.resources.resourcesDashboard.seeDetails' />
  </div>
);
