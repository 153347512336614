import { ISegmentColors } from 'pages/SecurityMappingPage/interfaces/ISegmentsMapping';

export enum SegmentId {
  Code = 'code',
  Build = 'build',
  Test = 'test',
  Release = 'release',
  Deploy = 'deploy',
  Operate = 'operate',
  Monitor = 'monitor',
  Plan = 'plan',
}

export enum Side {
  Left = 'left',
  Right = 'right',
}

export enum Placement {
  Left = 'left',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom',
}

export interface IGradientAttributes {
  coordinates: {
    x1: string;
    x2: string;
    y1: string;
    y2: string;
  }
}

export interface ITooltipAttributes {
  width: string;
  height: string;
  placement: Placement;
  position: {
    x: string;
    y: string;
  },
}

export interface ISegmentSettings {
  side: Side;
  gradient: IGradientAttributes;
  tooltip: ITooltipAttributes;
}

export interface IHoveredSegment {
  id: SegmentId;
  side: Side;
  activities: string[];
}

export interface ISegmentWrapper {
  colors: ISegmentColors;
  isTheLoopHovered: boolean;
  activities: string[];
  onSegmentClick?: (e: React.MouseEvent) => void;
  onSegmentMouseEnter: (hoveredSegment: IHoveredSegment) => void;
  onSegmentMouseLeave: (e: React.MouseEvent) => void;
}

export interface ISegment extends ISegmentWrapper {
  id: SegmentId;
  path: string;
  textPath: string;
  settings: ISegmentSettings;
}

