import { FC } from 'react';

import styles from './ScoreTrendingCell.module.scss';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { DisabledElement } from 'components/DisabledElement/DisabledElement';
import { ScoreTrendingGraph } from 'components/ScoreTrendingGraph/ScoreTrendingGraph';
import { ITableCell } from 'types/interfaces';

export const ScoreTrendingCell: FC<ITableCell> = ({ cell: { value: { scoreTrendingEntity, isActivated } } }) => (
  <div className={styles.wrapper}>
    {scoreTrendingEntity === null ? <CircularLoadingSpinner /> : (
      <DisabledElement isDisabled={!isActivated}>
        <ScoreTrendingGraph mode='compact' scoreTrendingEntity={scoreTrendingEntity} />
      </DisabledElement>
    )}
  </div>
);

