export const sizesMap = {
  xxs: 8,
  xs: 10,
  s: 12,
  m: 14,
  l: 16,
  xl: 18,
  xxl: 20,
  xxxl: 22,
  xxxxl: 24,
  huge: 26,
  xhuge: 30,
  xlhuge: 44,
  xxlhuge: 50,
  xxxlhuge: 60,
};
