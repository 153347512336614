import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './SecurityPlanCard.module.scss';

import { JitLogoIcon } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { getScore } from 'context/PlansContext/utils/getPlanCoverage';
import { PLANS_ROUTE } from 'pages/OverviewPage/constants';
import { ScoreGauge } from 'pages/SecurityPlansPage/components/ScoreGauge/ScoreGauge';
import colors from 'themes/colors.module.scss';
import { IPlanDetails } from 'types/interfaces';
import { iconsMap } from 'utils';

interface Props {
  plan: IPlanDetails;
  size?: 's' | 'm' | 'l';
}

const sizeToStyle = {
  s: { width: '264px' },
  m: { width: '294px' },
  l: { width: '339px' },
};

const DEFAULT_AUTHOR = 'jit';

export const SecurityPlanCard: FC<Props> = ({ plan, size = 's' }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const score = getScore(plan);

  return (
    <div
      className={styles.wrapper}
      data-testid='securityPlan-card'
      onClick={() => { navigate(`${PLANS_ROUTE}/${plan.slug}`); }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      role='button'
      tabIndex={0}
    >
      <div
        className={styles.card}
        style={{
          ...sizeToStyle[size],
        }}
      >
        <div className={styles.cardHeader}>
          <JitIcon
            centered={false}
            color={colors.white}
            icon={(iconsMap[(plan.author?.toLowerCase() || '')] || iconsMap[DEFAULT_AUTHOR]).icon || JitLogoIcon}
            size={18}
          />

          <div className={styles.headerText}>
            <TooltipOnlyOnOverflow bold text={plan.title} />
          </div>
        </div>

        <div className={styles.cardContentWrapper}>

          {isHovered ? <JitText color={colors.iris} data-testid='viewPlan' noWrap text='pages.overview.securityPlans.card.viewPlan' /> : (
            <>
              <JitText bold data-testid='score' text={`${(score * 100).toFixed(0)}%`} />

              <ScoreGauge score={score} showAnimation={false} variant='xs' />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
