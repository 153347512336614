import { uniqBy } from 'lodash';
import { FC } from 'react';

import { ChevronClosed } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { useSbomContext } from 'context/SbomContext/SbomContext';
import styles from 'pages/SbomPage/components/SbomTable/Cells/LibraryNameCell/LibraryNameCell.module.scss';
import colors from 'themes/colors.module.scss';
import { ITableCell } from 'types/interfaces';
import { IComponentGroupAssets } from 'types/interfaces/Sbom/SbomComponentGroup';

export const LibraryNameCell: FC<ITableCell> = ({ cell: { value: { assets, expanded, groupId, name, version } } }) => {
  const { toggleComponentExpended } = useSbomContext();
  const resources = assets as IComponentGroupAssets;

  const uniqueResources = uniqBy(Object.values(resources), (resource) => `${resource.orgAssetName}/${resource.repoAssetName}`);
  const shouldAllowExpand = uniqueResources.length > 1;

  const onClick = () => {
    toggleComponentExpended(groupId);
  };

  return (
    <div key={groupId} className={styles.wrapper}>
      {shouldAllowExpand
        ? <JitIcon color={colors.iris} icon={ChevronClosed} onClick={onClick} rotateDegrees={expanded ? 0 : 270} size={20} />
        : (
          <div className={styles.iconPlaceholder} />
        )}

      <TooltipOnlyOnOverflow align='center' alignSelf='center' bold text={name} />

      <JitText align='center' alignSelf='center' color={colors.lightGray} text={version} />

    </div>
  );
};
