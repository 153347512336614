import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import { constructRequestParams } from 'context/FindingsContext/utils/buildGetFindingsRequestParams';
import { fetchFindings, fetchFindingsCount } from 'services/FindingsService';
import { Queries } from 'types/enums/Queries';
import { IFinding } from 'types/interfaces';
import { ICount } from 'types/interfaces/Counts/ICounts';
import { IServerFindingFilterOptions } from 'types/interfaces/Findings/IFindingFilterOptions';
import { ISortColumn } from 'types/interfaces/ISortColumn';

interface IUseGetTableData {
  filters: Partial<IServerFindingFilterOptions>;
  activeColumn: ISortColumn;
  limit?: number;
  hasFetchedFilters?: boolean;
}

export const useGetTableData = ({ filters, hasFetchedFilters, activeColumn, limit }: IUseGetTableData) => {
  const [findings, setFindings] = useState<IFinding[]>([]);
  const [hasFetchedFindings, setHasFetchedFindings] = useState(false);

  const fetchData = useCallback(async (pageKey?: string) => {
    if (!hasFetchedFilters) return undefined;

    const formattedFilterParams = constructRequestParams({
      formattedFilters: filters,
      currentActiveColumn: activeColumn,
      nextPageKey: pageKey,
    });
    formattedFilterParams.page_limit = limit;

    if (!pageKey) setHasFetchedFindings(false);

    const { data, metadata: { nextKey } } = await fetchFindings(formattedFilterParams, pageKey);
    setHasFetchedFindings(true);
    setFindings(data);

    return nextKey;
  }, [activeColumn, filters, hasFetchedFilters, limit]);

  const getFindingsCount = useCallback(async () => {
    const formattedFilterParams = constructRequestParams({
      formattedFilters: filters,
      currentActiveColumn: activeColumn,
    });

    const response = await fetchFindingsCount(formattedFilterParams) || {};
    const { count } = response as ICount;
    return count;
  }, [activeColumn, filters]);

  const { data: totalFindingsAmount, refetch: refetchTotalFindingsAmount } = useQuery<number>(
    [Queries.FindingsCount, filters, activeColumn],
    getFindingsCount,
    {
      enabled: !!filters && !!activeColumn,
      // we want to fetch new data every time as it might change
      cacheTime: 0,
    },
  );

  const getTotalFindingsAmount = useCallback(async () => {
    await refetchTotalFindingsAmount();
  }, [refetchTotalFindingsAmount]);

  return {
    fetchData,
    findings,
    setFindings,
    totalFindingsAmount,
    getTotalFindingsAmount,
    hasFetchedFindings,
  };
};
