import { FC } from 'react';

import { ResourcesDashboardPage } from './ResourcesDashboardPage';
import { useRedirectToResourcePage } from './useRedirectToResourcePage';

import { GraphProvider } from 'context/GraphContext/GraphProvider';

export const ResourcesDashboardPageWrapper: FC = () => {
  useRedirectToResourcePage();

  return (
    <GraphProvider>
      <ResourcesDashboardPage />
    </GraphProvider>
  );
};
