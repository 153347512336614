import { FC, useEffect, useMemo } from 'react';

import { OpenPullRequest } from 'assets';
import { JitText } from 'components/JitText/JitText';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { PageTitles } from 'components/PageTitles/PageTitles';
import { usePullRequestsContext } from 'context/PullRequestsContext/PullRequestsContext';
import { i18n } from 'locale/i18n';
import { InsightBody } from 'pages/InsightsPage/components';
import { ANALYTICS_CLICKED_LINK_TYPES } from 'pages/InsightsPage/components/InsightListCardItem/constants';
import { PRsStatistics } from 'pages/InsightsPage/SubPages/PullRequestsPage/components/PRsStatistics/PRsStatistics';
import styles from 'pages/InsightsPage/SubPages/PullRequestsPage/PullRequestsPage.module.scss';
import { getPullRequestInsightCategories } from 'pages/InsightsPage/SubPages/PullRequestsPage/utils/getPullRequestCategories';
import { getPeriodDate } from 'pages/InsightsPage/utils/getPeriodDates';
import colors from 'themes/colors.module.scss';
import { TimeAgo } from 'types/enums';

export const PullRequestsPage: FC = () => {
  const { prAnalysis, isLoading, prPercentage, getPullRequestsAnalysis } = usePullRequestsContext();

  const { formattedStartDate, formattedEndDate } = getPeriodDate(TimeAgo.TwoWeeks);

  useEffect(() => {
    getPullRequestsAnalysis();
  }, [getPullRequestsAnalysis]);

  const pullRequestCategories = useMemo(() => getPullRequestInsightCategories(prAnalysis, prPercentage), [prAnalysis, prPercentage]);

  const description = i18n.t('pages.pullRequests.dataPeriod', {
    periodStartDate: formattedStartDate,
    periodEndDate: formattedEndDate,
  });

  return (
    <div className={styles.wrapper} data-testid='pullRequestsPage'>
      <div className={styles.header}>
        <PageTitles
          description={description}
          subtitle='pages.pullRequests.subtitle'
          title='pages.pullRequests.title'
        />

        <PRsStatistics prAnalysis={prAnalysis} prPercentage={prPercentage} />

      </div>

      <div className={styles.contentTitle}>
        <JitText bold size='l' text='pages.pullRequests.contentTitle' />

        <JitText color={colors.lightGray} size='l' text='pages.pullRequests.contentSubtitle' />
      </div>

      {isLoading
        ? <LoadingBar data-testid='pullRequestLoadingBar' />
        : (
          <div className={styles.content} data-testid='pullRequestStatisticsAndListCards'>

            <InsightBody
              analyticsLinkType={ANALYTICS_CLICKED_LINK_TYPES.PR}
              categories={pullRequestCategories}
              emptyDescription='pages.pullRequests.prsLists.emptyList.description'
              emptyTitle='pages.pullRequests.prsLists.emptyList.title'
              listItemIcon={OpenPullRequest}
            />
          </div>
        )}

    </div>
  );
};
