import { FC } from 'react';

import styles from './GraphLoading.module.scss';

import { CircularProgressBar } from 'components/CircularProgressBar/CircularProgressBar';

export const GraphLoading: FC = () => (
  <div className={styles.loadingBarWrapper} data-testid='graphLoading'>
    <CircularProgressBar className={styles.loadingBar} size='30px' />
  </div>
);

