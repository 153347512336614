export const getDisabledTooltip = (fallback: string, isValidationLoading: boolean, isFailedValidation: boolean, isCommiting: boolean, isWaitingForAsset: boolean) => {
  if (isCommiting) {
    return 'pages.plan.configurationsDialog.commitInProgressTooltip';
  }
  if (isWaitingForAsset) {
    return 'pages.plan.configurationsDialog.missingAssetTooltip';
  }
  if (isValidationLoading) {
    return 'pages.plan.configurationsDialog.validationInProgressTooltip';
  }
  if (isFailedValidation) {
    return 'pages.plan.configurationsDialog.validationErrorTooltip';
  }
  return fallback;
};
