import { t } from 'i18next';
import { FC } from 'react';

import styles from './InfoBanner.module.scss';

import { Info } from 'assets';
import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { JitInfoBanner } from 'components/JitInfoBanner/JitInfoBanner';
import { JitText } from 'components/JitText/JitText';
import { config } from 'config';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import colors from 'themes/colors.module.scss';
import { RepositorySelection } from 'types/interfaces';

export const InfoBanner: FC = () => {
  const { githubInstallation } = useTenantContext();
  const isInstallationSelectedType = githubInstallation?.vendor_attributes?.repository_selection === RepositorySelection.SELECTED;

  const createGitHubRepoUrl = (name: string, description: string, visibility: string) => {
    const params = new URLSearchParams();

    params.set('name', name);
    params.set('description', description);
    params.set('visibility', visibility);

    return `${config.createNewGithubRepo}?${params.toString()}`;
  };

  const createRepoLink = createGitHubRepoUrl(
    t('GithubIntegrationWizard.createCentralizedRepo.repoUrlParams.name'),
    t('GithubIntegrationWizard.createCentralizedRepo.repoUrlParams.description'),
    t('GithubIntegrationWizard.createCentralizedRepo.repoUrlParams.visibility'),
  );

  return (
    <JitInfoBanner bgColor={colors.cardContent} icon={Info} iconColor={colors.blue}>
      <div className={styles.infoBanner}>
        <JitText bold size='s' text='GithubIntegrationWizard.createCentralizedRepo.infoBanner.title' />

        <div className={styles.infoContent}>

          <JitExternalLink href={createRepoLink} size='s' text='GithubIntegrationWizard.createCentralizedRepo.infoBanner.createRepo' />

          {isInstallationSelectedType && (
            <>
              <JitText muted size='s' text='GithubIntegrationWizard.createCentralizedRepo.infoBanner.and' />

              <JitExternalLink
                href={githubInstallation?.vendor_attributes?.html_url
                  || config.getGithubAppPermissionsUrl(githubInstallation.owner, githubInstallation.installation_id)}
                size='s'
                text='GithubIntegrationWizard.createCentralizedRepo.infoBanner.editPermissions'
              />
            </>
          )}
        </div>
      </div>
    </JitInfoBanner>
  );
};

