import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import styles from './SecurityPlanInfoCard.module.scss';

import { SetAsGoal, SetAsGoalOn } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitTag } from 'components/JitTag/JitTag';
import { JitText } from 'components/JitText/JitText';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { usePlansContext } from 'context/PlansContext/PlansContext';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import { RemoveGoalDialog } from 'pages/SecurityPlansPage/components/RemoveGoalDialog/RemoveGoalDialog';
import { SetAsGoalDialog } from 'pages/SecurityPlansPage/components/SetAsGoalDialog/SetAsGoalDialog';
import { useGetLabelToColorMapping } from 'pages/SecurityPlansPage/hooks/plansTemplates';
import {
  buildSetPlanAsGoalAnalyticsEvent,
  buildUnsetPlanAsGoalAnalyticsEvent,
} from 'pages/SecurityPlansPage/utils/buildSetUnsetPlanAsGoalEventParams';
import { usePlanService } from 'services/PlanService/usePlanService';
import colors from 'themes/colors.module.scss';
import { IPlanDetails } from 'types/interfaces';

interface Props {
  plan: IPlanDetails
}

export const SecurityPlanInfoCard: FC<Props> = ({ plan }) => {
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const { preferences, isPreferencesLoading, initPreferences } = useTenantContext();
  const { plans } = usePlansContext();

  const { getLabelToColorMapping } = useGetLabelToColorMapping(Object.values(plans));
  const { partialUpdatePlan } = usePlanService();

  const [isSetAsGoal, setIsSetAsGoal] = useState(false);
  const [showRemoveGoalText, setShowRemoveGoalText] = useState(false);
  const [showRemoveGoalDialog, setShowRemoveGoalDialog] = useState(false);
  const [showSetAsGoalDialog, setShowSetAsGoalDialog] = useState(false);

  useEffect(() => {
    setIsSetAsGoal(plan.is_goal);
  }, [setIsSetAsGoal, plan]);

  useEffect(() => {
    if (!isPreferencesLoading && !preferences) {
      initPreferences();
    }
  }, [isPreferencesLoading, preferences, initPreferences]);

  const setPlanAsGoal = useCallback((isGoal: boolean) => {
    setIsSetAsGoal(isGoal);
    partialUpdatePlan({
      slug: plan.slug,
      is_goal: isGoal,
    });
  }, [partialUpdatePlan, plan.slug]);

  const handleGoalClick = useCallback(() => {
    if (isSetAsGoal) {
      setShowRemoveGoalDialog(true);
    } else {
      setPlanAsGoal(true);
      setShowSetAsGoalDialog(true);
      sendAnalyticsEvent(buildSetPlanAsGoalAnalyticsEvent(plan.slug.toLowerCase(), 'SecurityPlanInfoCard'));
    }
  }, [isSetAsGoal, setPlanAsGoal, plan, sendAnalyticsEvent]);

  const handleConfirmRemoveGoal = useCallback(() => {
    setShowRemoveGoalDialog(false);
    setPlanAsGoal(false);
    sendAnalyticsEvent(buildUnsetPlanAsGoalAnalyticsEvent(plan.slug.toLowerCase(), 'SecurityPlanInfoCard'));
  }, [setPlanAsGoal, plan, sendAnalyticsEvent]);

  const setAsGoalButton = useMemo(() => (
    <JitButton
      data-testid='set-goal-button'
      height={35}
      onClick={handleGoalClick}
      variant='contained'
      width='fit-content'
    >
      <JitIcon className={styles.goalIcon} color={colors.white} icon={SetAsGoal} />

      <JitText bold text='pages.securityPlans.planInfoCard.setAsGoal' />
    </JitButton>
  ), [handleGoalClick]);

  const addedAsGoalButton = useMemo(() => (
    <div
      className={styles.unsetGoalWrapper}
      data-testid='unsetGoalWrapper'
      onClick={handleGoalClick}
      onMouseLeave={() => setShowRemoveGoalText(false)}
      role='button'
      tabIndex={0}
    >
      <JitButton
        data-testid='added-as-goal-button'
        height={35}
        onMouseEnter={() => setShowRemoveGoalText(true)}
        variant='text'
        width={150}
      >
        <JitIcon className={styles.goalIcon} icon={SetAsGoalOn} />

        <JitText
          bold
          className={styles.addedAsGoalText}
          size='l'
          text='pages.securityPlans.planInfoCard.addedAsGoal'
        />

      </JitButton>

      {showRemoveGoalText && (
        <JitText color={colors.failRed} data-testid='removeGoalText' text='pages.securityPlans.planInfoCard.removeGoalText' />
      )}
    </div>
  ), [handleGoalClick, showRemoveGoalText]);

  const labelToColor = useMemo(() => getLabelToColorMapping(), [getLabelToColorMapping]);

  return (
    <div className={styles.securityPlanInfoCardWrapper}>
      <div className={styles.titleWrapper}>
        <TooltipOnlyOnOverflow bold noWrap overflowHiddenEllipsis size='xxl' text={plan.name} />

        {plan.labels?.map((label) => <JitTag key={label} color={labelToColor[label]} text={label} />)}
      </div>

      {plan.version && (
        <div className={styles.titleWrapper}>
          <JitText color={colors.lightGray} data-testid='PlanVersionKey' horizontalAlign='center' text='pages.securityPlans.planInfoCard.version' />

          <JitText bold color={colors.lightGray} data-testid='PlanVersionValue' horizontalAlign='center' text={`${plan.version}`} />
        </div>
      )}

      <div className={styles.descriptionWrapper}>
        <JitText
          components={{
            learnMore: <JitExternalLink
              href={plan.references?.[0]}
              text='pages.securityPlans.planInfoCard.learnMore'
              textColor={colors.iris}
              wrapperClassName={styles.learnMoreLink}
            />,
          }}
          data-testid='planDescription'
          muted
          text={`${plan.description} <learnMore/>`}
        />

      </div>

      <div className={styles.goalButtonWrapper}>
        {isSetAsGoal ? addedAsGoalButton : setAsGoalButton}
      </div>

      <RemoveGoalDialog
        isOpen={showRemoveGoalDialog}
        onClose={() => setShowRemoveGoalDialog(false)}
        onConfirm={handleConfirmRemoveGoal}
        plan={plan}
      />

      <SetAsGoalDialog
        isOpen={showSetAsGoalDialog}
        onClose={() => setShowSetAsGoalDialog(false)}
        onConfirm={() => setShowSetAsGoalDialog(false)}
        plan={plan}
        variant='insidePlan'
      />
    </div>
  );
};
