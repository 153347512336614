/* eslint-disable react/no-multi-comp */
import { FC } from 'react';

import styles from '../BaseTaskGuide.module.scss';

import { CompleteShield } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

type Props = {
  isDisabled?: boolean;
};

type GetCenterComponentParams = {
  title: string;
  isMainFullWidth: boolean;
  description?: string;
  MainComponent?: FC;
};

export const useBaseTaskGuide = ({ isDisabled }: Props) => {
  const disabledClass = isDisabled ? styles.disabled : '';
  const centerClass = `${styles.center} ${disabledClass}`;
  const rightClass = `${styles.right} ${disabledClass}`;

  const getCenterComponent = ({ title, description, isMainFullWidth, MainComponent }: GetCenterComponentParams) => () => (
    <div className={centerClass}>
      <div className={styles.text}>
        <JitText bold size='l' text={title} />

        <JitText color={colors.lightGray} size='m' text={description} />
      </div>

      <div>
        {!isMainFullWidth && MainComponent && <MainComponent />}
      </div>
    </div>
  );

  const getLeftComponent = (isCompleted: boolean) => () => (
    <JitIcon color='none' icon={CompleteShield} strokeColor={isCompleted ? colors.successGreen02 : colors.darkGray} />
  );

  const getRightComponent = (RightComponent?: FC) => () => (
    <div className={rightClass}>
      {RightComponent && <RightComponent />}
    </div>
  );

  return {
    getLeftComponent,
    getRightComponent,
    getCenterComponent,
  };
};
