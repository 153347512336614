import { FC, useState } from 'react';

import { AddAssetsDialog } from './components/AddAssetsDialog/AddAssetsDialog';
import styles from './EmptyAssetsTable.module.scss';

import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';
import { Vendor } from 'types/enums';

interface Props {
  teamName: string;
}

export const EmptyAssetsTable: FC<Props> = ({ teamName }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className={styles.wrapper} data-testid='empty-assets-table'>
        <div className={styles.textWrapper}>
          <JitText align='center' bold size='l' text='pages.teamsPortal.teamPage.table.emptyState.title' />

          <JitText align='center' size='m' text='pages.teamsPortal.teamPage.table.emptyState.description' />
        </div>

        <JitGradientButton
          data-testid='empty-assets-table-cta'
          onClick={() => setIsOpen(true)}
        >
          <div className={styles.button}>
            <JitText size='s' text='pages.teamsPortal.teamPage.table.emptyState.cta' />
          </div>
        </JitGradientButton>

      </div>

      <AddAssetsDialog isOpen={isOpen} onClose={() => setIsOpen(false)} teamName={teamName} vendor={Vendor.Github} />
    </>
  );
};
