import { useCallback, useEffect, useState } from 'react';

import { useTeamsService } from 'services/TeamsService/useTeamsService';
import { ITeamPreferences, TeamPreferencesType } from 'types/interfaces/Teams/ITeamPreferences';

export const useGetTeamPreferences = (teamId: string, teamName: string, type?: TeamPreferencesType) => {
  const [teamPreferences, setTeamPreferences] = useState<ITeamPreferences>();
  const { fetchPreferences } = useTeamsService();

  const initTeamPreferences = useCallback(async () => {
    const teamPreferencesResponse = await fetchPreferences(teamId, teamName, type);
    if (teamPreferencesResponse) {
      setTeamPreferences((existingTeamPrefs) => {
        if (type) {
          return {
            ...existingTeamPrefs,
            ...teamPreferencesResponse,
          };
        }
        return existingTeamPrefs;
      });
    }
  }, [teamId, setTeamPreferences]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!teamPreferences) {
      initTeamPreferences();
    }
  }, [initTeamPreferences, teamPreferences]);

  return {
    teamPreferences,
    setTeamPreferences,
  };
};
