import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import { FC, useState } from 'react';

import { SavedFiltersActionsMenu } from '../SavedFilterActionsMenu/SavedFilterActionsMenu';

import { SavedFilterActions } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { useFindingsContext } from 'context/FindingsContext/FindingsContext';
import colors from 'themes/colors.module.scss';
import { ISavedFilter } from 'types/interfaces/Findings/ISavedFilter';

interface Props {
  savedFilter: ISavedFilter;
  handleClose: () => void;
}

export const SavedFiltersMenuItem: FC<Props> = ({
  savedFilter,
  handleClose,
}) => {
  const [openPopover, setOpenPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { editSavedFilter, setSavedFilterAsDefault, removeSavedFilter, selectSavedFilter } = useFindingsContext();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenPopover(true);
  };

  const onClose = () => {
    setAnchorEl(null);
    setOpenPopover(false);
    handleClose();
  };

  const selectFilter = () => {
    selectSavedFilter(savedFilter);
    handleClose();
  };

  const filterDisplayName = () => (
    <div>
      <JitText size='m' text={savedFilter.name} />

      {savedFilter.isDefault && (
        <JitText
          color={colors.lightGray}
          size='s'
          text='Default'
        />
      )}
    </div>
  );

  return (
    <div style={{
      width: '100%',
      display: 'flex',
    }}
    >
      <MenuItem
        disableRipple
        onClick={() => selectFilter()}
        sx={{
          width: '100%',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          '&:hover': { backgroundColor: 'transparent' },
        }}
      >
        {filterDisplayName()}
      </MenuItem>

      <div style={{
        display: 'block',
        marginLeft: 'auto',
        marginRight: 0,
      }}
      >
        <JitButton
          data-testid='saved-filter-actions-button-open-modal'
          disableRipple
          onClick={(e) => handleClick(e)}
          sx={{
            backgroundColor: 'transparent',
            '&:hover': { backgroundColor: 'transparent' },
            borderRadius: 0,
          }}
        >
          <JitIcon icon={SavedFilterActions} />
        </JitButton>
      </div>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openPopover}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <SavedFiltersActionsMenu
          anchorEl={anchorEl}
          editSavedFilter={editSavedFilter}
          onClose={onClose}
          openPopover={openPopover}
          removeSavedFilter={removeSavedFilter}
          savedFilter={savedFilter}
          setSavedFilterAsDefault={setSavedFilterAsDefault}
        />
      </Popover>
    </div>
  );
};
