import { ChangeEvent, FC } from 'react';

import { ExtendedActionActions } from './components/ExtendedActionActions/ExtendedActionActions';
import { ExtendedActionFindingsList } from './components/ExtendedActionFindingsList/ExtendedActionFindingsList';

import { IAction, IActionFinding, IConcealedAction, IMouseEvent } from 'types/interfaces';

interface ExtendedActionProps {
  selectedFindingsIds: string[]
  openFindings: IActionFinding[]
  fixButtonText?: string
  generateTitle: (finding: IActionFinding) => string,
  generateSubtitle?: (finding: IActionFinding) => string,
  handleCheckAllFindings: (event: ChangeEvent<HTMLInputElement>, checked: Boolean) => void
  handleCheckFinding: (event: ChangeEvent<HTMLInputElement>, checked: Boolean) => void
  handleFixFindings: (event: IMouseEvent) => void
  handleCreateStory: (event: IMouseEvent) => void
  handleOpenIgnoreDialog: (event: IMouseEvent) => void
  isOpenPRLoading: boolean
  isCreateStoryLoading: boolean
  isCreateStoryDisabled: boolean
  action: IAction | IConcealedAction
  ignoreButtonText: string
  createTicketText: string
  isLoadingActionFindings: boolean
  handleScroll: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void
}

export const ExtendedAction: FC<ExtendedActionProps> = ({
  selectedFindingsIds,
  openFindings,
  generateTitle,
  generateSubtitle,
  handleCheckAllFindings,
  handleCheckFinding,
  action,
  handleFixFindings,
  handleCreateStory,
  handleOpenIgnoreDialog,
  isOpenPRLoading,
  isCreateStoryLoading,
  isCreateStoryDisabled,
  fixButtonText,
  ignoreButtonText,
  createTicketText,
  isLoadingActionFindings,
  handleScroll,
}) => (
  <div>
    <ExtendedActionFindingsList
      findings={openFindings}
      generateSubtitle={generateSubtitle}
      generateTitle={generateTitle}
      handleCheckAllFindings={handleCheckAllFindings}
      handleCheckFinding={handleCheckFinding}
      handleScroll={handleScroll}
      isLoading={isLoadingActionFindings}
      selectedFindings={selectedFindingsIds}
    />

    <ExtendedActionActions
      actionId={action.id}
      actionName={action.title}
      createTicketText={createTicketText}
      disableActions={selectedFindingsIds.length === 0}
      fixButtonText={fixButtonText}
      guidelines={(action as IAction).guidelines}
            // Concealed actions are not expandable by definition, so we can safely cast to IAction.
      handleCreateStory={handleCreateStory}
      handleOpenIgnoreDialog={handleOpenIgnoreDialog}
      ignoreButtonText={ignoreButtonText}
      isCreateStoryDisabled={isCreateStoryDisabled}
      isCreateStoryLoading={isCreateStoryLoading}
      isOpenPRLoading={isOpenPRLoading}
      remediateFindingsAction={handleFixFindings}
    />

  </div>
);
