import { FC, useState } from 'react';

import styles from './InfoIcon.module.scss';

import { LightGrayInfo } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitPopper } from 'components/JitPopper/JitPopper';
import { JitText } from 'components/JitText/JitText';

export const InfoIcon: FC = () => {
  const [isHoverInfoIcon, setIsHoverInfoIcon] = useState(false);
  return (
    <JitPopper
      content={(
        <div>
          <JitText size='s' text='pages.teams.teamDetails.scoreDescription' />
        </div>
      )}
      isWithArrow={false}
      open={isHoverInfoIcon}
      placement='top'
      shadowVariant='dark'
    >
      <JitIcon
        className={styles.icon}
        color='transparent'
        icon={LightGrayInfo}
        onMouseEnter={() => { setIsHoverInfoIcon(true); }}
        onMouseLeave={() => { setIsHoverInfoIcon(false); }}
        size={12}
      />
    </JitPopper>
  );
};
