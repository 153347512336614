import { ReactElement } from 'react';

import { getColorByScore } from 'utils/functions/grades';

export type CustomDotProps = {
  cy: number;
  cx: number;
  payload: { value: number }; };

type Props = CustomDotProps & {
  offset?: number;
  singleColor?: string | null;
};

const RADIUS = 4;

export const CustomDot = ({ cy, cx, payload, offset = 0, singleColor }: Props): ReactElement<SVGElement> => {
  const adjustedCx = cx + offset;

  return (
    <circle cx={adjustedCx} cy={cy} data-testid='custom-dot-circle' fill={singleColor || getColorByScore(payload.value)} r={RADIUS} />
  );
};
