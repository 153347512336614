import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './NoDeploymentConfiguration.module.scss';

import { JittyNotConfiguredDeployment } from 'assets';
import { JitEmpty } from 'components/JitEmpty/JitEmpty';
import { constants } from 'globalConstants';
import { ActionButtonVariant } from 'types/interfaces';

export const NoDeploymentConfiguration: FC = () => {
  const navigate = useNavigate();
  return (

    <div className={styles.wrapper} data-testid='noDeploymentConfiguration'>
      <JitEmpty
        actionButtons={[{
          label: 'pages.deployments.noDeploymentConfiguration.planPageRedirectButton',
          variant: ActionButtonVariant.PRIMARY,
          handleClick: () => navigate(`/${constants.routes.PLANS}`),
        }]}
        data-testid='noDeploymentsJitEmpty'
        description='pages.deployments.noDeploymentConfiguration.description'
        icon={JittyNotConfiguredDeployment}
        title='pages.deployments.noDeploymentConfiguration.title'
      />
    </div>
  );
};
