import { FC } from 'react';

import { Row } from '../Row/Row';

import styles from './TenantRow.module.scss';

import { JitText } from 'components/JitText/JitText';

interface Props {
  tenantName: string;
  shouldShowArrow?: boolean;
  isLast: boolean;
  onClick: () => void;
  backgroundColor?: string;
  shouldShowName?: boolean;
  endAdornment?: JSX.Element;
  shouldHandleHover?: boolean;
}

export const TenantRow: FC<Props> = ({
  tenantName, shouldShowArrow = true, isLast, onClick, backgroundColor, shouldShowName = true, endAdornment, shouldHandleHover,
}) => (
  <Row
    bgColor={backgroundColor}
    endAdornment={endAdornment}
    icon={(
      <div className={styles.wrapper}>
        <JitText align='center' horizontalAlign='center' text={tenantName.slice(0, 1).toUpperCase() || ''} />
      </div>
    )}
    isLast={isLast}
    onSelect={() => onClick()}
    shouldHandleHover={shouldHandleHover}
    shouldShowArrow={shouldShowArrow}
    shouldShowName={shouldShowName}
    text={tenantName}
  />
);
