import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './EmptySecurityPlansCard.module.scss';

import { SetAsGoal } from 'assets';
import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { PLANS_ROUTE, SECURITY_PLANS_LEARN_MORE_LINK } from 'pages/OverviewPage/constants';
import colors from 'themes/colors.module.scss';

export const EmptySecurityPlansCard: FC = () => {
  const navigate = useNavigate();
  return (
    <div
      className={styles.wrapper}
      data-testid='empty-securityPlans-card'
    >
      <div className={styles.cardTitle}>
        <JitIcon
          centered={false}
          color={colors.white}
          icon={SetAsGoal}
          size={26}
        />

        <div className={styles.titleText}>
          <JitText bold text='pages.overview.securityPlans.card.myGoals' />
        </div>
      </div>

      <div className={styles.cardContentWrapper}>
        <JitText text='pages.overview.securityPlans.card.emptyTitle' />

        <JitText
          className={styles.description}
          components={{
            learnMoreLink: <JitText
              onClick={() => window.open(SECURITY_PLANS_LEARN_MORE_LINK)}
              text='pages.overview.securityPlans.card.learnMoreText'
              withUnderline
            />,
          }}
          text='pages.overview.securityPlans.card.emptyDescription'
        />
      </div>

      <div className={styles.actionButton}>
        <JitActionButton actionButton={{ label: 'pages.overview.securityPlans.card.viewAllSecurityPlansButton',
          handleClick: () => { navigate(PLANS_ROUTE); } }}
        />
      </div>
    </div>

  );
};
