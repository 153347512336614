import { FC, useCallback } from 'react';

import commonStyles from '../common.module.scss';
import { GitLabCard } from '../components/GitLabCard/GitLabCard';
import gitlabCardStyles from '../components/GitLabCard/GitLabCard.module.scss';

import styles from './IntegrateGitlab.module.scss';

import { GitlabIcon } from 'assets';
import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { JitButton } from 'components/JitButton/JitButton';
import { DialogContentWrapper } from 'components/JitDialogs/DialogContentWrapper/DialogContentWrapper';
import { JitText } from 'components/JitText/JitText';
import { useParagonAuth } from 'context/IntegrationsContext/hooks/useParagonAuth';
import colors from 'themes/colors.module.scss';

interface Props {
  onContinueButtonClick: () => void;
  onIntegrateClick: () => void;
  isGitlabIntegrated: boolean;
}

export const IntegrateGitlab: FC<Props> = ({ onContinueButtonClick, onIntegrateClick, isGitlabIntegrated }) => {
  const { isLoading, isLoadingIntegrations } = useParagonAuth();

  const getCardContent = useCallback(() => {
    if (isGitlabIntegrated) return (<JitText bold color={colors.successGreen} text='GitlabIntegrationWizard.integrateGitlab.card.success' />);

    if (isLoadingIntegrations) {
      return (
        <div className={styles.loadingLabel}>
          <CircularLoadingSpinner />

          <JitText bold text='GitlabIntegrationWizard.integrateGitlab.card.loading' />
        </div>
      );
    }

    return (
      <JitButton
        analytics={{ action: 'clicked-integrate-gitlab-app' }}
        data-testid='integrate-gitlab-button'
        isLoading={isLoading}
        onClick={onIntegrateClick}
        variant='contained'
      >
        <JitText color='inherit' noWrap text='GitlabIntegrationWizard.integrateGitlab.card.buttonText' />
      </JitButton>
    );
  }, [isGitlabIntegrated, isLoading, onIntegrateClick, isLoadingIntegrations]);

  return (
    <DialogContentWrapper
      actionButtons={[{
        label: 'GitlabIntegrationWizard.integrateGitlab.buttonText',
        disabled: !isGitlabIntegrated,
        handleClick: onContinueButtonClick,
      }]}
      icon={GitlabIcon}
    >
      <div className={`${commonStyles.dotJitTextBox} ${styles.content}`}>

        <JitText bold size='l' text='GitlabIntegrationWizard.integrateGitlab.title' />

        <JitText muted text='GitlabIntegrationWizard.integrateGitlab.description' />

        <GitLabCard shouldShowIcon={false}>
          <div className={gitlabCardStyles.cardLeftSide}>
            <JitText bold text='GitlabIntegrationWizard.integrateGitlab.card.title' />

          </div>

          <div className={gitlabCardStyles.cardRightSide}>
            <div className={styles.cardContentWrapper}>
              {getCardContent()}

            </div>
          </div>
        </GitLabCard>
      </div>
    </DialogContentWrapper>
  );
};
