import { getCurrentInstallationSortedByStatus } from './getCurrentInstallationSortedByStatus';
import { groupInstallationsByVendor } from './groupInstallationsByVendor';

import { IInstallation, IntegrationStatus } from 'types/interfaces';

export const isVendorInstalled = (vendor: string, installations: IInstallation[]) => {
  const installationsByVendor = groupInstallationsByVendor(installations);
  const currentInstallation = getCurrentInstallationSortedByStatus(vendor, installationsByVendor);
  return currentInstallation?.status === IntegrationStatus.CONNECTED && currentInstallation.is_active;
};
