import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import { styled } from '@mui/material';
import { AxiosResponse } from 'axios';
import { FC, useMemo } from 'react';

import { JiraIconIris, LinearIconIris, ShortcutIconOutlined } from 'assets';
import { generateADF } from 'pages/FindingsPage/utils/generateADF';
import { generateMarkdown } from 'pages/FindingsPage/utils/generateMarkdown';
import { useFindingsService } from 'services/FindingsService/useFindingService';
import { useJiraService } from 'services/JiraService/useJiraService';
import { useLinearService } from 'services/LinearService/useLinearService';
import { useShortcutService } from 'services/ShortcutService/useShortcutService';
import { Vendor } from 'types/enums';
import { IFinding, ISvg } from 'types/interfaces';
import { IntegrationProvider } from 'types/interfaces/Integrations/IIntegration';
import { Ticket } from 'types/interfaces/TicketManager/Ticket';

const StyledConfirmationNumberOutlinedIcon = styled(ConfirmationNumberOutlinedIcon)(() => ({
  width: 20,
  height: 20,
}));

export type OpenTicket = (integrationId: string, title: string, findings: IFinding[], provider?: IntegrationProvider) => Promise<AxiosResponse<Ticket> | undefined>;

interface IVendorTicketConfig {
  icon: FC<ISvg>;
  openTicket: OpenTicket;
  ticketName: string;
}

const iconMap: { [key: string]: FC<ISvg> } = {
  [Vendor.Jira]: JiraIconIris,
  [Vendor.Shortcut]: ShortcutIconOutlined,
  [Vendor.LINEAR]: LinearIconIris,
};

const getVendorIcon = (vendor: Vendor) => {
  const vendorIcon = iconMap[vendor] || StyledConfirmationNumberOutlinedIcon;

  return vendorIcon;
};

export const useVendorTicketConfig = (): { vendorTicketConfig: { [key: string]: IVendorTicketConfig } } => {
  const { createTicket } = useFindingsService();
  const { createTicket: createJiraTicket } = useJiraService();
  const { createTicket: createLinearTicket } = useLinearService();
  const { createTicket: createShortcutTicket } = useShortcutService();

  const getFindingIds = (findings: IFinding[]): string[] => findings.map((finding) => finding.id);

  const vendorTicketConfig = useMemo(() => ({
    [Vendor.Jira]: {
      icon: getVendorIcon(Vendor.Jira),
      openTicket: (integrationId: string, title: string, findings: IFinding[], provider?: IntegrationProvider) => (
        // support backwards compatibility - If old provider, use as code, otherwise third party provider
        provider === IntegrationProvider.JIT ? createJiraTicket(integrationId, title, generateADF(findings), findings.map((finding) => finding.id))
          : createTicket({
            title,
            vendor: Vendor.Jira,
            findingIds: getFindingIds(findings),
          })
      ),
      ticketName: 'tickets.jira.ticketName',
    },
    [Vendor.Shortcut]: {
      icon: getVendorIcon(Vendor.Shortcut),
      openTicket: (integrationId: string, title: string, findings: IFinding[]) => createShortcutTicket(integrationId, title, generateMarkdown(findings), findings.map((finding) => finding.id)),
      ticketName: 'tickets.shortcut.ticketName',
    },
    [Vendor.LINEAR]: {
      icon: getVendorIcon(Vendor.LINEAR),
      openTicket: (integrationId: string, title: string, findings: IFinding[]) => createLinearTicket(integrationId, title, generateMarkdown(findings), findings.map((finding) => finding.id)),
      ticketName: 'tickets.linear.ticketName',
    },
    [Vendor.MONDAY]: {
      icon: getVendorIcon(Vendor.MONDAY),
      openTicket: (integrationId: string, title: string, findings: IFinding[]) => (
        createTicket({
          title,
          vendor: Vendor.MONDAY,
          findingIds: getFindingIds(findings),
        })
      ),
      ticketName: 'tickets.monday.ticketName',
    },
  }), [createJiraTicket, createLinearTicket, createShortcutTicket, createTicket]);

  return { vendorTicketConfig };
};
