import { FC, PropsWithChildren } from 'react';

import styles from './GeneralPage.module.scss';

import { JitFavicon } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import colors from 'themes/colors.module.scss';

interface Props {
  isFullPage?: boolean;
  backgroundColor?: string;
}

export const GeneralPage: FC<PropsWithChildren<Props>> = ({ isFullPage = true, children, backgroundColor }) => (
  <div className={styles.wrapper} style={{ backgroundColor: backgroundColor || colors.marketingSiteBG }}>
    {isFullPage && (
      <div className={styles.faviconWrapper}>
        <JitIcon icon={JitFavicon} size={32} />
      </div>
    )}

    {children}
  </div>
);
