export const parseMarkdown = (originalText: string): string => {
  /*
      The following regex removes all single linebreaks from a markdown string.
      This is done so that we don't get random linebreaks when they were put mainly to improve readability in GitHub. Double linebreaks are left in.

      More information about this regex can be found here: https://regex101.com/r/wA2rO6/2
  */
  const stringWithoutLineBreaks = originalText.replace(/(\S)[ \t]*(?:\r\n|\n)[ \t]*(\S)/g, '$1 $2');

  // add line breaks after dots, if the dot is followed by a space and a non-digit character or line break
  const stringWithoutLineBreaksAfterDots = stringWithoutLineBreaks.replace(/(\. )(?=[^\d\n])/g, '$1\n');

  // add markdown bold to <word> that ends with a ": "
  const stringWithoutLineBreaksAfterDotsAndBold = stringWithoutLineBreaksAfterDots.replace(/(\w+)(: )/g, '**$1**$2');
  /*
      The following regex removes whitespaces from between markdown link elements.

      More information about this regex can be found here: https://regex101.com/r/Tgw9ko/2
  */
  return stringWithoutLineBreaksAfterDotsAndBold.replace(/(\[.*?]) (\(.*?\))/g, '$1$2');
};
