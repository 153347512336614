import { IPlanInstance, IPlanInstanceItem } from 'types/interfaces';

interface IPlansDiff {
  addedItems: IPlanInstanceItem[];
  removedItems: IPlanInstanceItem[];
}

export const getPlansDiff = (oldPlan: IPlanInstance, newPlan: IPlanInstance): IPlansDiff => {
  const newPlanItemsUses = newPlan.items.map(({ uses }) => (uses));
  const oldPlanItemsUses = oldPlan.items.map(({ uses }) => (uses));
  const addedItems = newPlan.items.filter((item) => !oldPlanItemsUses.includes(item.uses));
  const removedItems = oldPlan.items.filter((item) => !newPlanItemsUses.includes(item.uses));
  return {
    addedItems,
    removedItems,
  };
};
