import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import { FC } from 'react';

import styles from './LoadingBar.module.scss';

import { StyledLinearProgress } from 'components/AppLoadingBar/AppLoadingBar.styles';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  'data-testid'?: string,
  sx?: SxProps,
  text?: string,
}

export const LoadingBar: FC<Props> = (props: Props) => {
  const { sx, text } = props;
  return (
    <Box
      data-testid='loadingBar'
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '300px',
        transform: 'translateX(-50%)',
        ...sx,
      }}
      {...props}
    >
      <StyledLinearProgress value={50} />

      { text && <JitText align='center' className={styles.text} color={colors.lightGray} data-testid='loading-text' size='s' text={text} />}
    </Box>
  );
};
