import colors from 'themes/colors.module.scss';

export const calcPriorityScoreColor = (score: number): string => {
  const CRITICAL_PRIORITY_SCORE = 80;
  const HIGH_PRIORITY_SCORE = 40;

  let color = '';
  if (score >= CRITICAL_PRIORITY_SCORE) color = colors.criticalPriority;
  else if (score >= HIGH_PRIORITY_SCORE && score < CRITICAL_PRIORITY_SCORE) color = colors.highPriority;
  else if (score < HIGH_PRIORITY_SCORE && score >= 0) color = colors.mediumPriority;

  return color;
};
