import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';

import { TrialEndBanner } from 'components/TrialEndBanner/TrialEndBanner';
import { IActiveToast } from 'context/ToastsContext/interfaces';
import { ToastType } from 'types/enums';

interface Props {
  showToast: (toast: IActiveToast) => void;
}

export const useEndTrialWarningBanner = ({ showToast }: Props) => {
  const { showEndTrialBanner } = useFlags();

  const showTrialEndBannerIfNeeded = useCallback(() => {
    if (showEndTrialBanner) {
      const trialEndToast: IActiveToast = {
        toastId: 'trial-end-warning-banner',
        overrideProps: {
          content: <TrialEndBanner />,
        },
        type: ToastType.SystemMessage,
      };
      showToast(trialEndToast);
    }
  }, [showEndTrialBanner, showToast]);
  return { showTrialEndBannerIfNeeded };
};
