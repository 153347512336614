import { useRef, useState, useEffect, useMemo } from 'react';

import { getTextWidth } from 'components/PriorityFactorsGraph/utils/visualGraphUtils';
import { calculateElementRelativeWidth } from 'utils/functions/calculateElementRelativeWidth';

export const useCalculateMaxTagsInContainer = (tags: string[], maxWidth: number | string = '100%') => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [maxTagsInContainer, setMaxTagsInContainer] = useState(3);
  const [actualWidth, setActualWidth] = useState<number | string>(maxWidth);

  const sortedTags = useMemo(
    () => [...tags].sort((a, b) => a.length - b.length),
    [tags],
  );

  useEffect(() => {
    const calculateMaxTags = () => {
      if (containerRef.current) {
        const gapWidth = 35;
        const reservedWidthForPlus = 60;
        let totalWidth = 0;
        let calculatedMaxTags = 0;

        const maxAllowedWidth = calculateElementRelativeWidth(
          maxWidth,
          containerRef.current,
        );

        for (let i = 0; i < sortedTags.length; i += 1) {
          const tagWidth = getTextWidth(sortedTags[i], 14) + gapWidth;
          if (totalWidth + tagWidth + reservedWidthForPlus > maxAllowedWidth) {
            break;
          }

          totalWidth += tagWidth;
          calculatedMaxTags += 1;
        }
        if (calculatedMaxTags < sortedTags.length) totalWidth += reservedWidthForPlus;

        setActualWidth(totalWidth);
        setMaxTagsInContainer(
          calculatedMaxTags > 0 ? calculatedMaxTags : 1,
        );
      }
    };

    const resizeObserver = new ResizeObserver(calculateMaxTags);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Recalculate on window resize to handle 'vw' units
    window.addEventListener('resize', calculateMaxTags);

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current); // eslint-disable-line
      }
      window.removeEventListener('resize', calculateMaxTags);
    };
  }, [sortedTags, maxWidth]);

  const tagsToShow = sortedTags.slice(0, maxTagsInContainer);
  const hiddenTags = sortedTags.slice(maxTagsInContainer);

  return {
    containerRef,
    actualWidth,
    maxTagsInContainer,
    tagsToShow,
    hiddenTags,
  };
};
