import { mergeWith } from 'lodash';
import { useCallback } from 'react';

import { useGetDefaultFindingFilters } from 'context/FindingsContext/hooks/useGetDefaultFindingFilters';
import { IFindingFilter, IServerFindingFilterOptions } from 'types/interfaces';

export const useFormatAndExtendFilters = () => {
  const { getDefaultFilters } = useGetDefaultFindingFilters();

  const formatAndExtendFilters = useCallback((currentFilters: IFindingFilter[], fetchedServerFilterOptions: IServerFindingFilterOptions): IFindingFilter[] => {
    const defaultFilters = getDefaultFilters(fetchedServerFilterOptions);

    const updatedFilters = mergeWith(defaultFilters, currentFilters, (defaultFilter, currentFilter) => ({
      ...defaultFilter,
      selectedValue: currentFilter?.selectedValue ?? defaultFilter.selectedValue,
      isVisible: currentFilter?.isVisible ?? defaultFilter.isVisible,
    }));

    return updatedFilters;
  }, [getDefaultFilters]);

  return { formatAndExtendFilters };
};
