export enum Queries {
  ScoreTrending = 'scoreTrending',
  Teams = 'teams',
  TeamById = 'teamById',
  ParagonEnv = 'paragonEnv',
  ParagonUser = 'paragonUser',
  TeamsLeaderboard = 'teamsLeaderboard',
  ResourceFactors = 'resourceFactors',
  PlanItemsFindingsCount = 'planItemsFindingsCount',
  FindingsCount = 'findingsCount',
  Findings = 'findings',
  UserVendors = 'userVendors',
  GitlabGroups = 'gitlabGroups',
  GitlabProjects = 'gitlabProjects',
  QuickstartChecks = 'quickstartChecks',
  AssetsStatistics = 'assetsStatistics',
  AssetFilterValues = 'assetFilterValues',
  Assets = 'assets',
  AssetsCount = 'assetsCount',
  InitialScanStats = 'initialScanStats',
  IgnoredIssuesMetric = 'ignoredIssuesMetric',
  Mttr = 'mttr',
  OrgScoreTrending = 'orgScoreTrending',
  PrsStats = 'prsStats',
  policiesTemplate = 'policiesTemplate',
  policyRules = 'policyRules',
  Workflows = 'workflows',
}
