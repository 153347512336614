import { useCallback, useMemo } from 'react';

import { useAuthContext } from 'context/AuthContext/AuthContext';

export const useHasPermissions = () => {
  const { frontEggUser } = useAuthContext();
  const permissionKeys = useMemo(() => frontEggUser.permissions.map((p) => p.key), [frontEggUser.permissions]);

  const hasPermission = useCallback((permissionKey: string | null) => {
    // if permissionKey is null then the route is public
    const isRoutePublic = !permissionKey;
    return isRoutePublic || permissionKeys.includes(permissionKey);
  }, [permissionKeys]);

  return { hasPermission };
};

