import { t } from 'i18next';
import { FC, useEffect, useMemo, useState } from 'react';

import commonStyles from '../common.module.scss';
import { GitHubCard } from '../components/GitHubCard/GitHubCard';
import githubCardStyles from '../components/GitHubCard/GitHubCard.module.scss';

import styles from './InstallJitApp.module.scss';

import { GithubIcon, Link, Loading } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { DialogContentWrapper } from 'components/JitDialogs/DialogContentWrapper/DialogContentWrapper';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { config } from 'config';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import { constants } from 'globalConstants';
import colors from 'themes/colors.module.scss';

interface Props {
  onContinueButtonClick: () => void;
  isRedirectedBackFromGHInstallation: boolean;
  onInstallClick: () => void;
}

export const InstallJitApp: FC<Props> = ({ onContinueButtonClick, isRedirectedBackFromGHInstallation, onInstallClick }) => {
  const { githubInstallation, isLoadingInstallation } = useTenantContext();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isRedirectedBackFromGHInstallation) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, constants.LOADER_TIMEOUT);
    }
  }, [isRedirectedBackFromGHInstallation]);

  const onInstallButtonClick = async () => {
    setIsLoading(true);
    await onInstallClick();
  };

  const shouldShowLoader = useMemo(() => (isLoadingInstallation || isRedirectedBackFromGHInstallation) && isLoading, [isLoadingInstallation,
    isRedirectedBackFromGHInstallation,
    isLoading]);

  const installButton = (
    <JitButton
      analytics={{ action: 'clicked-install-github-app' }}
      data-testid='install-jit-github-app-button'
      isLoading={isLoading}
      onClick={onInstallButtonClick}
      variant='contained'
    >
      <JitText color='inherit' noWrap text='GithubIntegrationWizard.installJitApp.card.buttonText' />
    </JitButton>
  );

  const loadingLabel = (
    <div className={styles.loadingLabel}>
      <div className={styles.loadingIcon}>
        <JitIcon icon={Loading} size={12} />
      </div>

      <JitText bold text='GithubIntegrationWizard.installJitApp.card.loading' />
    </div>
  );

  const installSuccessLabel = (
    <JitText bold color={colors.successGreen} text='GithubIntegrationWizard.installJitApp.card.success' />
  );

  const getCardContent = () => {
    if (githubInstallation) return installSuccessLabel;
    if (shouldShowLoader) return loadingLabel;
    return installButton;
  };

  const descriptionBullets: string[] = t('GithubIntegrationWizard.installJitApp.description.bullets', { returnObjects: true });

  return (
    <DialogContentWrapper
      actionButtons={[{
        label: 'GithubIntegrationWizard.installJitApp.buttonText',
        disabled: !githubInstallation,
        handleClick: onContinueButtonClick,
      }]}
      icon={GithubIcon}
      leftButtonsElement={(
        <div
          className={styles.leftButtonsTextContent}
          onClick={() => { window.open(config.docsWhyJitNeedsPermissionLink); }}
          role='button'
          tabIndex={0}
        >
          <JitText color={colors.iris} text='GithubIntegrationWizard.installJitApp.learnMore' />

          <JitIcon color={colors.iris} icon={Link} size={15} />
        </div>
      )}
    >
      <div className={`${commonStyles.dotJitTextBox} ${styles.content}`}>

        <JitText bold size='l' text='GithubIntegrationWizard.installJitApp.title' />

        {descriptionBullets && (
          <ul className={styles.list}>
            {descriptionBullets.map((bullet) => (
              <li key={bullet} className={styles.listItem}>
                <JitText muted text={bullet} />
              </li>
            ))}
          </ul>
        )}

        <GitHubCard shouldShowIcon={false}>
          <div className={githubCardStyles.cardLeftSide}>
            <JitText bold text='GithubIntegrationWizard.installJitApp.card.title' />

            <JitText color={colors.lightGray} text='GithubIntegrationWizard.installJitApp.card.description' />
          </div>

          <div className={githubCardStyles.cardRightSide}>
            <div className={styles.cardContentWrapper}>
              {getCardContent()}
            </div>
          </div>
        </GitHubCard>
      </div>
    </DialogContentWrapper>
  );
};
