import { IFixSuggestionAdditionalData } from './IFixSuggestionAdditionalData';

export enum FixSuggestionSource {
  manual = 'manual',
  ai = 'ai',
  control = 'control',
}

export interface IFixSuggestion {
  title: string,
  reason: string,
  guidelines: string
  filename?: string
  additional_data?: IFixSuggestionAdditionalData
  code_fixes?: Record<string, string>
  source: FixSuggestionSource
}
