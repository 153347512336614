import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';

import { AwsIcon, OpenInNewTab } from 'assets';
import { INSTALLING_INTEGRATION_STATUSES } from 'components/Configurations/Integrations/constants';
import { TABS } from 'components/JitDialogs/AssetsManagementDialog/constants';
import { AwsConnectDialog } from 'components/JitDialogs/AwsConnectDialog/AwsConnectDialog';
import { DialogCard } from 'components/JitDialogs/DialogCard/DialogCard';
import { useConfigurationsContext } from 'context/ConfigurationsContext/ConfigurationsContext';
import { useDialogsContext } from 'context/DialogsContext/DialogsContext';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import { AWS_GENERAL_PERMISSION_LINK } from 'pages/IntegrationsPage/components/integrationCards/AWSIntegrationCard/constants';
import { getCurrentInstallationSortedByStatus } from 'pages/IntegrationsPage/utils/getCurrentInstallationSortedByStatus';
import { groupInstallationsByVendor } from 'pages/IntegrationsPage/utils/groupInstallationsByVendor';
import { Dialogs, Vendor } from 'types/enums';
import { IntegrationStatus } from 'types/interfaces';
import { Link } from 'types/interfaces/Link/Link';

interface Props {
  dialogCardWidth?: string;
  dialogCardMinHeight?: string;
  dialogCardMaxHeight?: string;
  setIsDoneStep: Dispatch<SetStateAction<boolean>>;
  setShouldHideStepsDialog: Dispatch<SetStateAction<boolean>>

}

export const AwsIntegration: FC<Props> = ({ dialogCardWidth, dialogCardMinHeight, dialogCardMaxHeight, setIsDoneStep, setShouldHideStepsDialog }) => {
  const { isCommittingConfigurations } = useConfigurationsContext();
  const { installations, isLoadingInstallation, hasTriedFetchInstallations, getIntegrations } = useTenantContext();
  const { showDialog } = useDialogsContext();

  const [showAwsConnectDialog, setShowAwsConnectDialog] = useState(false);
  const installationsByVendor = useMemo(() => groupInstallationsByVendor(installations), [installations]);
  const integrations = getIntegrations();

  const awsIntegration = useMemo(() => integrations.find((integration) => integration.vendor === Vendor.AWS), [integrations]);

  const awsInstallation = useMemo(() => getCurrentInstallationSortedByStatus(Vendor.AWS, installationsByVendor), [installationsByVendor]);

  useEffect(() => {
    setIsDoneStep(awsInstallation?.status === IntegrationStatus.CONNECTED);
  }, [awsInstallation, setIsDoneStep]);

  const onConnect = () => {
    setShouldHideStepsDialog(true);
    setShowAwsConnectDialog(true);
  };

  const onConfigure = () => {
    showDialog({
      type: Dialogs.AssetsManagement,
      props: { defaultTab: TABS.AWS },
    });
  };

  const onClose = () => {
    setShowAwsConnectDialog(false);
    setShouldHideStepsDialog(false);
  };

  const isLoading = useMemo(
    () => !hasTriedFetchInstallations || isLoadingInstallation || isCommittingConfigurations
      || INSTALLING_INTEGRATION_STATUSES.includes(awsInstallation?.status),
    [awsInstallation?.status, hasTriedFetchInstallations, isCommittingConfigurations, isLoadingInstallation],
  );

  const awsGeneralLink: Link = {
    text: 'pages.plan.configurationDialog.awsIntegration.link',
    href: AWS_GENERAL_PERMISSION_LINK,
    icon: OpenInNewTab,
  };

  return (
    <div data-testid='AwsIntegration'>

      {!showAwsConnectDialog ? (
        <DialogCard
          configureText='configurations.integrations.configure'
          description='configurations.integrations.aws.description'
          icon={AwsIcon}
          installation={awsInstallation}
          isLoading={isLoading}
          link={awsGeneralLink}
          maxHeight={dialogCardMaxHeight}
          minHeight={dialogCardMinHeight}
          onConfigure={onConfigure}
          onConnect={onConnect}
          vendor={Vendor.AWS}
          width={dialogCardWidth}
        />
      ) : (
        <AwsConnectDialog
          integration={awsIntegration}
          onClose={onClose}
          vendor={Vendor.AWS}
        />
      )}
    </div>
  );
};
