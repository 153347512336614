import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/*
* A simple custom hook that returns current URL query params as an object.
* E.g:
*   For the URL: '.../matrix?plan_id="mvs"&plan_item_id="item-logging"'
*   we will get {
*     plan_id: "mvs",
*     plan_item_id: "item-logging"
* }
* */
export const useQueryParams = () => {
  const { search } = useLocation();
  const queryParams = useMemo(() => Object.fromEntries(new URLSearchParams(search)), [search]);

  return { queryParams };
};
