import findKey from 'lodash/findKey';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import startCase from 'lodash/startCase';

import { DEFAULT_ROUTE, FORMATTED_ROUTES, SECURITY_PLANS, UNKNOWN_ROUTE } from './constants';

import { constants } from 'globalConstants';
import { IDictionary } from 'types/interfaces';

type RoutesMap = IDictionary<string | IDictionary<string>>;

const findMatchingRouteKey = (firstPart: string, routesObj: RoutesMap): string | undefined => findKey(routesObj, (value) => value === firstPart || (isObject(value) && value.BASE_ROUTE === firstPart));

const getPageNameFromRoutes = (pathnameParts: string[], routesObj: RoutesMap = constants.routes): string | null => {
  if (pathnameParts.length === 0) return null;

  const [firstSegment, ...remainingSegments] = pathnameParts;

  const routeKey = findMatchingRouteKey(firstSegment, routesObj);

  if (routeKey && isString(routesObj[routeKey]) && routesObj[routeKey] === firstSegment) {
    return FORMATTED_ROUTES[routeKey] || startCase(firstSegment);
  }

  const nestedRouteObj = routeKey && isObject(routesObj[routeKey]) ? routesObj[routeKey] as RoutesMap : null;

  if (nestedRouteObj && nestedRouteObj.BASE_ROUTE === firstSegment) {
    return getPageNameFromRoutes(remainingSegments, nestedRouteObj) || startCase(firstSegment);
  }

  return null;
};

export const getPagesNames = (pathname: string): string => {
  if (pathname === '/') return DEFAULT_ROUTE;

  const pathnameParts = pathname.split('/').filter(Boolean);
  const pageName = getPageNameFromRoutes(pathnameParts);

  return pageName || UNKNOWN_ROUTE;
};

export const getPlanName = (pathname: string): string | null => {
  if (SECURITY_PLANS === getPagesNames(pathname)) {
    const pathnameParts = pathname.split('/');
    const planNameIndex = pathnameParts.indexOf(constants.routes.PLANS) + 1;
    if (planNameIndex < pathnameParts.length) {
      return pathnameParts[planNameIndex];
    }
  }
  return null;
};

export const collectParentTestIds = (element: HTMLElement) => {
  let currElement: HTMLElement | null = element;
  const parentsTestIds = [];
  // Traverse up to collect test IDs
  while (currElement && currElement !== document.body) {
    const dataTestId = currElement.getAttribute('data-testid') || '';
    if (dataTestId) {
      // Collects the data-testid if present
      parentsTestIds.unshift(dataTestId); // Add at the beginning of the array
    }
    currElement = currElement.parentElement;
  }
  return parentsTestIds;
};
