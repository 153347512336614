import styles from './ReviewCard.module.scss';

import { Star } from 'assets';
import { JitText } from 'components/JitText/JitText';

type Props = {
  content: string;
  reviewer: {
    fullName: string;
    title: string;
  };
  rating: number;
};

const textSize = '0.7vw';

export const ReviewCard = ({ content, reviewer, rating }: Props) => (
  <div className={styles.wrapper}>

    <JitText bold size={textSize} text={content} />

    <div className={styles.stars}>
      {Array.from({ length: rating }, (_, index) => (
        <Star key={index} />
      ))}
    </div>

    <div>
      <JitText bold size={textSize} text={reviewer.fullName} />

      <JitText muted size={textSize} text={reviewer.title} />
    </div>

  </div>
);
