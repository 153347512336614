import { IntegrationTemplate } from 'context/IntegrationsContext/templates/interfaces';
import { useSecretsContext } from 'context/SecretsContext/SecretsContext';

export const useValidateSecretByIntegration = () => {
  const { tenantSecrets } = useSecretsContext();

  const isSecretValidForIntegration = (integration: IntegrationTemplate): boolean => {
    const secretName = integration?.options?.fields?.name?.value;
    const secretExists = !!secretName && !!tenantSecrets?.find((secret) => secret.name === secretName);
    return secretExists;
  };

  return isSecretValidForIntegration;
};
