import { FC } from 'react';

import styles from './ItemTypeBadge.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

export type ItemType = 'auto' | 'manual';

interface Props {
  itemType: ItemType;
}

const itemTypeToTextMap = {
  auto: 'pages.plan.itemDetails.info.auto',
  manual: 'pages.plan.itemDetails.info.manual',
};

export const ItemTypeBadge: FC<Props> = ({ itemType }) => (
  <div className={styles.wrapper}>
    <JitText bold color={colors.lightGray} size='s' text={itemTypeToTextMap[itemType]} />
  </div>
);
