import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';

import { RisksBreadcrumbs } from '../components/RisksBreadcrumbs/RisksBreadcrumbs';

import { ResourceBreakdownGrid } from './components/ResourceBreakdownGrid/ResourceBreakdownGrid';
import { ResourcesDashboardTitle } from './components/Title/ResourcesDashboardTitle';

import { JitText } from 'components/JitText/JitText';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { useGraphContext } from 'context/GraphContext/GraphContext';
import { calcTagColor } from 'pages/Resources/ResourcePage/components/ResourceDetails/components/ResourceTags/utils';
import styles from 'pages/Resources/ResourcesDashboardPage/ResourcesDashboardPage.module.scss';
import { useAssetService } from 'services/AssetsService/useAssetService';
import colors from 'themes/colors.module.scss';
import { Queries } from 'types/enums/Queries';

export enum AssetAggregationKey {
  AssetType = 'asset_type',
  PriorityFactors = 'priority_factors',
  Tags = 'tags',
}

const DISPLAYED_FACTORS = ['Production', 'Database Integration', 'Externally Accessible', 'Business Critical'];

export const ResourcesDashboardPage: FC = () => {
  const { getAssetsStatistics } = useAssetService();
  const {
    allPriorityFactors,
    isLoadingPriorityFactors,
  } = useGraphContext();
  const { data: totalAssetsStatistics = [] } = useQuery(
    [Queries.AssetsStatistics, { filterParams: undefined }],
    getAssetsStatistics,
    { keepPreviousData: true },
  );

  const {
    data: priorityFactorsStatistics = [],
    isLoading: isLoadingPriorityFactorsStatistics,
    isError: isErrorPriorityFactors,
  } = useQuery(
    [Queries.AssetsStatistics, { aggregationKey: AssetAggregationKey.PriorityFactors }],
    getAssetsStatistics,
  );
  const {
    data: tagsStatistics = [],
    isLoading: isLoadingTagsStatistics,
    isError: isErrorTags,
  } = useQuery(
    [Queries.AssetsStatistics, { aggregationKey: AssetAggregationKey.Tags }],
    getAssetsStatistics,
  );
  const tagsStatisticsWithoutTeams = useMemo(() => tagsStatistics.filter((tag) => !tag.aggregationKey.includes('team:')), [tagsStatistics]);

  if (isLoadingTagsStatistics || isLoadingPriorityFactorsStatistics || isLoadingPriorityFactors) {
    return <LoadingBar />;
  }

  if (isErrorPriorityFactors || isErrorTags) {
    // this is temporary error message, should be replaced with proper error component
    const message = 'Failed to fetch assets statistics';
    return <div className={styles.errorMessage}>{message}</div>;
  }

  const totalCount = totalAssetsStatistics?.[0]?.count || 0;

  const priorityFactorsKeysToDisplay = DISPLAYED_FACTORS.map(
    (key) => ({
      key,
      description: allPriorityFactors.find((factor) => factor.key === key)?.description || key,
    }),
  );

  return (
    <div className={styles.wrapper}>
      <RisksBreadcrumbs />

      <ResourcesDashboardTitle resourcesCount={totalCount} />

      <div className={styles.section}>
        <JitText data-testid='priorityFactors-section' size='m' text='pages.risks.resources.resourcesDashboard.sections.priorityFactors' />

        <ResourceBreakdownGrid
          aggregationKey={AssetAggregationKey.PriorityFactors}
          getTagColor={() => colors.criticalPriority}
          keysToDisplay={priorityFactorsKeysToDisplay}
          resourcesStatistic={priorityFactorsStatistics}
        />
      </div>

      <div className={styles.section}>
        <JitText data-testid='tags-section' size='m' text='pages.risks.resources.resourcesDashboard.sections.tags' />

        <ResourceBreakdownGrid aggregationKey={AssetAggregationKey.Tags} getTagColor={calcTagColor} resourcesStatistic={tagsStatisticsWithoutTeams} />
      </div>
    </div>
  );
};
