import { FC } from 'react';

import { Resize } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import colors from 'themes/colors.module.scss';

interface Props {
  setIsFullScreen: (isFullScreen: boolean) => void;

}

export const FullScreenButton: FC<Props> = ({ setIsFullScreen }) => (
  <JitIcon
    color={colors.iris}
    icon={Resize}
    onClick={() => { setIsFullScreen(true); }}
    size={16}
  />
);

