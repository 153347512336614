import { FC } from 'react';

import styles from './MembersCell.module.scss';

import { DisabledElement } from 'components/DisabledElement/DisabledElement';
import { JitAvatarGroup } from 'components/JitAvatarGroup/JitAvatarGroup';
import { ITableCell } from 'types/interfaces';

const MAX_MEMBERS_TO_DISPLAY = 5;

export const MembersCell: FC<ITableCell> = ({ cell: { value } }) => (
  <DisabledElement isDisabled={!(value.isActivated ?? true)}>
    <div className={styles.wrapper}>
      <JitAvatarGroup maxMembersToDisplay={MAX_MEMBERS_TO_DISPLAY} members={value.members} />
    </div>
  </DisabledElement>
);
