import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';

import { GROUP_BY, METRIC_NAME, SeveritiesToDisplay } from './constants';
import styles from './MTTR.module.scss';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { getSeverityIcon } from 'components/JitTable/templates/SeverityCell/utils/getSeverityIcon';
import { JitText } from 'components/JitText/JitText';
import { PercentageChip } from 'components/PercentageChip/PercentageChip';
import { GraphEmpty, GraphLoading } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/components';
import { fetchFindingsMetric } from 'services/MetricService/MetricService';
import { Severity, TimeAgo } from 'types/enums';
import { Queries } from 'types/enums/Queries';
import { MTTRMetric } from 'types/interfaces/Metrics/FindingsMetrics/FindingsMetrics';
import { formatHoursToDisplayText, timeAgoToDays } from 'utils';

interface Props {
  period: TimeAgo;
}

export const MTTR: FC<Props> = ({ period }) => {
  const { data, isLoading } = useQuery([Queries.Mttr, period, GROUP_BY], () => fetchFindingsMetric<MTTRMetric>({
    metricName: METRIC_NAME,
    period,
    params: { group_by: GROUP_BY },
  }));

  const mttrData = useMemo(() => {
    if (!data?.data) return [];

    const filteredData = Object.entries(data.data).filter(([key]) => SeveritiesToDisplay.includes(key.toUpperCase() as Severity));

    const items = filteredData.map(([key, { changeRate, currentMttr, previousMttr }]) => ({
      key,
      changeRate,
      currentMttr: formatHoursToDisplayText({
        hours: currentMttr / 60,
        roundUp: true,
        singleTimeUnit: true,
        fullWord: true,
      }),
      previousMttr: formatHoursToDisplayText({
        hours: previousMttr / 60,
        roundUp: true,
        singleTimeUnit: true,
        fullWord: true,
      }),
    }));

    return items;
  }, [data]);

  const periodDays = timeAgoToDays[period];

  if (isLoading) return <GraphLoading />;

  const hasNoData = mttrData.length === 0;
  if (hasNoData) return <GraphEmpty />;

  return (
    <div className={styles.mttrContainer}>
      {mttrData.map(({ key, changeRate, currentMttr, previousMttr }) => (
        <div key={key} className={styles.mttrItem}>
          <div className={styles.mttrInfo}>
            <JitText bold size='xhuge' text={currentMttr} />

            <PercentageChip bgColor='transparent' displayBorder percentage={changeRate} positiveTrendIndicatorDirection='down' />
          </div>

          <JitText
            muted
            params={{
              previousMttr,
              days: periodDays,
            }}
            size='s'
            text='pages.securityImpactV2.sections.performance.widgets.mttr.previousPeriodMttr'
          />

          <JitIcon className={styles.mttrIcon} data-testid={`mttr-${key}`} icon={getSeverityIcon(key, false, true)} size={40} />
        </div>
      ))}
    </div>
  );
};
