import { isEmpty } from 'lodash';
import { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { defaultReferrals } from './utils/defaultReferrals';
import { getCurrentReferrals } from './utils/getCurrentReferrals';

import { ISupportedReferrals } from 'types/interfaces';
import { useQueryParams } from 'utils';

interface IReferralContext {
  referral: ISupportedReferrals;
}

export const ReferralContext = createContext<IReferralContext>({ referral: {} });

export const useReferralContext = () => useContext(ReferralContext);

export const ReferralProvider: FC<PropsWithChildren> = ({ children }) => {
  const { queryParams } = useQueryParams();
  const [referral, setReferral] = useState<ISupportedReferrals>(defaultReferrals);

  const initReferral = useCallback(() => {
    if (isEmpty(queryParams)) return;
    const newReferrals = getCurrentReferrals(queryParams);
    if (!isEmpty(newReferrals)) setReferral(newReferrals);
  }, [queryParams]);

  useEffect(() => {
    initReferral();
  }, [initReferral]);

  const value = useMemo(() => ({ referral }), [referral]);

  return (
    <ReferralContext.Provider value={value}>
      {children}
    </ReferralContext.Provider>
  );
};

