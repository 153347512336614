
import { ZapApplicationNonAuth, ZapAuthenticationConfigType, ZapAuthMode } from 'types/interfaces';

export const webApplicationCommonInitialState = {
  type: undefined,
  authentication_mode: '',
  application_name: '',
  target_url: '',
  api_domain: '',
  exclude_paths: [],
};

const formBasedAuthExtraFieldsInitialState = {
  login_page_url: '',
  username: '',
  username_css_selector: '',
  authentication_key: '',
  authentication_value: '',
  password_css_selector: '',
};

const headerAuthExtraFieldsInitialState = {
  authentication_key: '',
  authentication_value: '',
};

const localStorageAuthExtraFieldsInitialState = {
  authentication_key: '',
  authentication_value: '',
};

const customCookieAuthExtraFieldsInitialState = {
  authentication_key: '',
  authentication_value: '',
};

const nonAuthExtraFieldsInitialState = {};

export const webApplicationFormBaseAuthInitialState = (zapType: ZapAuthenticationConfigType): ZapApplicationNonAuth => ({
  ...webApplicationCommonInitialState,
  type: zapType,
  authentication_mode: ZapAuthMode.NonAuthenticated,
});

export const authModeToExtraFieldsInitialState = {
  [ZapAuthMode.FormBased]: formBasedAuthExtraFieldsInitialState,
  [ZapAuthMode.BearerTokenHeader]: headerAuthExtraFieldsInitialState,
  [ZapAuthMode.CookieHeader]: headerAuthExtraFieldsInitialState,
  [ZapAuthMode.CustomHeader]: headerAuthExtraFieldsInitialState,
  [ZapAuthMode.NonAuthenticated]: nonAuthExtraFieldsInitialState,
  [ZapAuthMode.LocalStorage]: localStorageAuthExtraFieldsInitialState,
  [ZapAuthMode.CustomCookie]: customCookieAuthExtraFieldsInitialState,
};

export const staticAuthModeOptions = [
  ZapAuthMode.FormBased,
  ZapAuthMode.LocalStorage,
  ZapAuthMode.CustomCookie,
  ZapAuthMode.BearerTokenHeader,
  ZapAuthMode.CustomHeader,
  ZapAuthMode.CookieHeader,
].map((authMode) => ({
  itemKey: authMode,
  itemName: `configurations.zap.web.authModes.${authMode}`,
}));
