import { useCallback } from 'react';

import { useClient } from 'services/useClient';
import {
  IPullRequest,
  IPullRequestsAnalysis,
  IPullRequestsAnalysisResponse,
  IPullRequestServer,
  IPullRequestAnalysisPercentage,
} from 'types/interfaces/PullRequests';

const serviceName = 'pr';

const parsePullRequest = (pullRequestServer: IPullRequestServer): IPullRequest => ({
  tenantId: pullRequestServer.tenant_id,
  assetId: pullRequestServer.asset_id,
  vendor: pullRequestServer.vendor,
  owner: pullRequestServer.owner,
  author: pullRequestServer.author,
  authorId: pullRequestServer.author_id,
  authorAvatar: pullRequestServer.author_avatar,
  repository: pullRequestServer.repository,
  branch: pullRequestServer.branch,
  pullRequestNumber: pullRequestServer.pull_request_number,
  title: pullRequestServer.title,
  url: pullRequestServer.url,
  headSha: pullRequestServer.head_sha,
  baseSha: pullRequestServer.base_sha,
  conclusion: pullRequestServer.conclusion,
  status: pullRequestServer.status,
  createdAt: pullRequestServer.created_at,
  updatedAt: pullRequestServer.updated_at,
  analysis: pullRequestServer.analysis?.resolutions_count && { resolutionsCount: pullRequestServer.analysis?.resolutions_count },
  pipelineId: pullRequestServer.pipeline_id,
});

const parsePullRequests = (data: IPullRequestServer[]): IPullRequest[] => {
  if (!data) {
    return [];
  }
  return data.map((pullRequest: IPullRequestServer) => parsePullRequest(pullRequest));
};

const sortByCreatedAt = (a: IPullRequest, b: IPullRequest): number => (new Date(b.createdAt)).getTime() - (new Date(a.createdAt)).getTime();
const sortByUpdatedAt = (a: IPullRequest, b: IPullRequest): number => (new Date(b.updatedAt)).getTime() - (new Date(a.updatedAt)).getTime();

const convertPullRequestsAnalysisResponseObjectToCamelCase = (pullRequestsAnalysisResponse: IPullRequestsAnalysisResponse): IPullRequestsAnalysis => ({
  allPrs: parsePullRequests(pullRequestsAnalysisResponse.all_prs),
  openPrsWithFailedChecks: parsePullRequests(pullRequestsAnalysisResponse.open_prs_with_failed_checks),
  mergedPrsWithFixedChecks: parsePullRequests(pullRequestsAnalysisResponse.merged_prs_with_fixed_checks),
  stalePrs: parsePullRequests(pullRequestsAnalysisResponse.stale_prs).sort(sortByCreatedAt).reverse(),
  mergedPrsWithFailed: parsePullRequests(pullRequestsAnalysisResponse.merged_prs_with_failed).sort(sortByUpdatedAt),
  mergedPrsWithFailedIgnored: parsePullRequests(pullRequestsAnalysisResponse.merged_with_failed_ignored).sort(sortByUpdatedAt),
});

export const usePRService = () => {
  const { client } = useClient();

  const fetchPullRequestsAnalysis = useCallback(async (): Promise<IPullRequestsAnalysis | undefined> => {
    const url = `${serviceName}/analysis`;
    const response = await client.get<IPullRequestsAnalysisResponse>({
      url,
      allowedStatuses: [200],
    });
    if (response?.status !== 200) {
      return undefined;
    }
    return convertPullRequestsAnalysisResponseObjectToCamelCase(response.data);
  }, [client]);

  const fetchPullRequestPercentageAnalysis = useCallback(async (): Promise<IPullRequestAnalysisPercentage | undefined> => {
    const url = `${serviceName}/prs-categories-comparison-percentages`;
    const response = await client.get<IPullRequestAnalysisPercentage>({
      url,
      allowedStatuses: [200, 204],
    });
    if (response?.status === 200) {
      return response.data;
    }
    return undefined;
  }, [client]);

  return {
    fetchPullRequestsAnalysis,
    fetchPullRequestPercentageAnalysis,
  };
};
