import { FC, useState } from 'react';

import styles from './IgnoreFindingsDialogMenu.module.scss';

import { ExpandArrow } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitMenu, MenuItemType } from 'components/JitMenu/JitMenu';
import { JitText } from 'components/JitText/JitText';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import colors from 'themes/colors.module.scss';
import { IgnoreReason } from 'types/enums/IgnoreReason';

interface IPRItemIgnoreDialogMenuProps {
  selectedReason: string;
  setSelectedReason: (reason: IgnoreReason) => void;
  'data-testid'?: string
}

const reasonsForIgnoreOptions = [
  {
    value: IgnoreReason.AcceptRisk,
    displayText: `dialogs.ignore.${IgnoreReason.AcceptRisk}`,
  },
  {
    value: IgnoreReason.FalsePositive,
    displayText: `dialogs.ignore.${IgnoreReason.FalsePositive}`,
  },
];

export const IgnoreFindingsDialogMenu: FC<IPRItemIgnoreDialogMenuProps> = ({ selectedReason, setSelectedReason, ...props }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuItems: MenuItemType[] = reasonsForIgnoreOptions?.map((reasonsForIgnoreOption) => {
    const isSelected = reasonsForIgnoreOption.value === selectedReason;
    return ({
      itemKey: reasonsForIgnoreOption.value,
      itemContent: <TooltipOnlyOnOverflow text={reasonsForIgnoreOption.displayText} />,
      searchValue: reasonsForIgnoreOption.displayText,
      isSelected,
    });
  });
  const getBoxContainer = () => (
    <div
      className={`${styles.boxContainer} ${isMenuOpen ? styles.openedBoxContainer : ''}`}
    >
      {selectedReason
        ? (<JitText color={colors.white} text={`dialogs.ignore.${selectedReason}`} />)
        : (<JitText color={colors.gray} text='pages.actions.actionCard.ignoreDialog.reasonPlaceholder' />)}

      <JitIcon className={styles.expandIcon} color={colors.iris} data-testid='filterExpandIcon' icon={ExpandArrow} size={10} />

    </div>
  );
  return (
    <div data-testid={props['data-testid'] || 'IgnoreFindingsDialogMenu'}>

      <JitMenu
        menuItems={menuItems}
        menuSelectedDesign='fillRow'
        onMenuChangeOpenState={(isOpen: boolean) => { setIsMenuOpen(isOpen); }}
        onMenuItemClick={(item) => setSelectedReason(item.itemKey as IgnoreReason)}
        paperProps={{ style: { width: 510 } }}
        shouldCloseOnItemClick
        withCheckbox={false}
      >
        {getBoxContainer()}

      </JitMenu>
    </div>
  );
};

