import { FC } from 'react';

import styles from './GraphHeader.module.scss';
import { GraphHeaderCheckboxes } from './GraphHeaderCheckboxes/GraphHeaderCheckboxes';

import { Description, TitleWithIcon } from 'pages/InsightsPage/SubPages/PerformancePage/components/GraphHeaderComponents';

interface Props {
  title: string;
  popperTitle: string;
  isLoading: boolean;
  description: string;
  popperDescription: string;
  displayDetectedLine: boolean;
  setDisplayDetectedLine: (value: boolean) => void;
  displayResolvedLine: boolean;
  setDisplayResolvedLine: (value: boolean) => void;
  emptyDays: boolean;
}

export const GraphHeader: FC<Props> = ({
  title,
  popperTitle,
  isLoading,
  description,
  popperDescription,
  displayDetectedLine,
  setDisplayDetectedLine,
  displayResolvedLine,
  setDisplayResolvedLine,
  emptyDays,
}) => (
  <div data-testid='FindingsOverTimeGraphHeader'>
    <div className={styles.headerWrapper}>
      <TitleWithIcon
        displayRankInfoIcon
        isLoading={isLoading}
        popperDescription={popperDescription}
        popperTitle={popperTitle}
        title={title}
      />

      {!emptyDays && (
      <GraphHeaderCheckboxes
        displayDetectedLine={displayDetectedLine}
        displayResolvedLine={displayResolvedLine}
        setDisplayDetectedLine={setDisplayDetectedLine}
        setDisplayResolvedLine={setDisplayResolvedLine}
      />
      )}
    </div>

    <Description description={description} />
  </div>
);
