import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { CoverageCard } from '../Coverage/components/CoverageCard/CoverageCard';

import styles from './SecurityPosture.module.scss';

import { JittyGrayWink } from 'assets';
import { JitEmpty } from 'components/JitEmpty/JitEmpty';
import { JitText } from 'components/JitText/JitText';
import { PlanItemFindings } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/PlanItemFindings/PlanItemFindings';
import { PERFORMANCE_PAGE_ROUTE } from 'pages/OverviewPage/constants';
import colors from 'themes/colors.module.scss';
import { ActionButtonVariant } from 'types/interfaces';
import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { IPlanItemFindingsMetric } from 'types/interfaces/Metrics/PerformanceMetrics/PlanItemFindings';

interface Props {
  planItemFindingsMetric: MetricWithLoadingIndication<IPlanItemFindingsMetric>;
}

export const SecurityPosture: FC<Props> = ({ planItemFindingsMetric }) => {
  const navigate = useNavigate();
  const shouldShowEmptyState = !planItemFindingsMetric.isLoading && !planItemFindingsMetric.metric?.planItemFindingCounts.length;

  const emptyContent = (
    <div className={styles.emptyStateWrapper} data-testid='SecurityPosture-empty-state-wrapper'>
      <div className={styles.emptyStateContentWrapper}>
        <JitEmpty
          actionButtons={[{
            label: 'pages.overview.securityPosture.card.planItemFindings.buttonText',
            variant: ActionButtonVariant.SECONDARY,
            handleClick: () => { navigate(PERFORMANCE_PAGE_ROUTE); },
          }]}
          description='pages.overview.securityPosture.card.planItemFindings.emptyState.description'
          descriptionColor={colors.lightGray}
          icon={JittyGrayWink}
          title='pages.overview.securityPosture.card.planItemFindings.emptyState.title'
        />
      </div>
    </div>
  );
  const content = shouldShowEmptyState ? emptyContent : <PlanItemFindings planItemFindingsMetric={planItemFindingsMetric} />;
  return (
    <div className={styles.wrapper} data-testid='SecurityPosture-wrapper'>
      <JitText className={styles.title} color={colors.lightGray} text='pages.overview.securityPosture.title' />

      <CoverageCard
        actionButton={shouldShowEmptyState ? undefined : {
          label: 'pages.overview.securityPosture.card.planItemFindings.buttonText',
          handleClick: () => { navigate(PERFORMANCE_PAGE_ROUTE); },
        }}
        content={content}
      />
    </div>
  );
};
