import { FC } from 'react';

import styles from './NoCoverage.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  onClick: () => void;
  text: string;
  title: string;
}

export const NoCoverage: FC<Props> = ({ onClick, text, title }) => (
  <div className={styles.wrapper}>
    <JitText bold color={colors.lightGray} size='l' text={title} />

    <div
      className={styles.actionItem}
      data-testid={`action-item-${text}`}
      onClick={onClick}
      role='button'
      tabIndex={0}
    >
      <JitText className={styles.actionItemText} color={colors.iris} size='m' text={text} />
    </div>
  </div>
);
