
import { FC, useState } from 'react';

import { AddAssetsDialog } from '../AddAssetsDialog/AddAssetsDialog';

import styles from './AddAssetsButton.module.scss';

import { Plus } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { Vendor } from 'types/enums';
import { IMouseEvent } from 'types/interfaces';
import { stopPropagation } from 'utils';

interface Props {
  teamName: string;
  vendor: Vendor;
}

export const AddAssetsButton: FC<Props> = ({ teamName, vendor }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = (e: IMouseEvent) => {
    stopPropagation(e);
    setIsOpen(true);
  };

  const onClose = (e: IMouseEvent) => {
    stopPropagation(e);
    setIsOpen(false);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <JitButton className={styles.button} data-testid='AddAssetsButton' noHover onClick={onOpen}>
          <JitIcon
            color={colors.iris}
            icon={Plus}
            size={10}
          />

          <JitText bold color={colors.iris} text={`pages.teamsPortal.teamPage.table.add.${vendor}.cta`} />
        </JitButton>
      </div>

      <AddAssetsDialog isOpen={isOpen} onClose={onClose} teamName={teamName} vendor={vendor} />
    </>
  );
};

