import { isEmpty } from 'lodash';
import { Dispatch, SetStateAction, useCallback } from 'react';

import { IWebsocketNotification } from 'types/interfaces';
import { IConfigurableItem } from 'types/interfaces/Configurations/IConfigurableItem';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

interface Props {
  setTenantSecrets: Dispatch<SetStateAction<IConfigurableItem[] | undefined>>,
}

export const useHandleSecretsWebSocketNotification = ({ setTenantSecrets }: Props) => {
  const modifySecrets = (currentSecrets: IConfigurableItem[] | undefined, created: IConfigurableItem[], deleted: IConfigurableItem[]) => {
    if (!currentSecrets) {
      return isEmpty(created) ? undefined : created;
    }
    const secretsByName = currentSecrets.reduce((acc, secret) => {
      acc[secret.name] = secret;
      return acc;
    }, {} as Record<string, IConfigurableItem>);

    if (deleted) {
      deleted.forEach((secret) => {
        delete secretsByName[secret.name];
      });
    }

    if (created) {
      created.forEach((secret) => {
        secretsByName[secret.name] = secret;
      });
    }
    return camelizeSnakeCaseKeys(Object.values(secretsByName)) as IConfigurableItem[];
  };

  const handleSecretsWebSocketNotification = useCallback((notification: IWebsocketNotification<IConfigurableItem>) => {
    const { message: { created, deleted } } = notification;
    setTenantSecrets((currentSecrets) => modifySecrets(currentSecrets, created, deleted));
  }, [setTenantSecrets]);
  return { handleSecretsWebSocketNotification };
};
