import { IConfigurations } from 'types/interfaces';

const isUniqueApplicationName = (applicationName: string, applicationNames?: string[]): boolean => (
  !applicationNames || !applicationNames.includes(applicationName));

export const ApplicationNameValidator = (applicationName: string, configurations?: IConfigurations): boolean => {
  const applicationNames: string[] | undefined = configurations?.applications?.map((application) => application.application_name);

  return isUniqueApplicationName(applicationName, applicationNames);
};
