import { startCase, uniq, groupBy } from 'lodash';
import { useCallback } from 'react';

import { i18n } from 'locale/i18n';
import { IControlElement, IControlElementLinks, IControlDetails, IPlanItemDetails } from 'types/interfaces';

export const usePlanItem = () => {
  const { t } = i18n;

  const getSecurityToolDisplayText = useCallback((securityTool: string) => t([`securityTools.${securityTool.toLowerCase()}`, startCase(securityTool)]), [t]);

  const getSupportedLanguagesDisplayText = useCallback((language: string) => t([`supportedLanguages.${language.toLowerCase()}`, startCase(language)]), [t]);

  const getSupportedLanguages = useCallback((controls: IControlDetails[]): string[] => {
    const supportedLanguages = controls.map((control) => control.supported_languages).flat();

    // this is a temporary solution until we have a better "as code" definition for plans / items / controls
    const securityToolLanguageMap: Record<string, string> = {
      gitleaks: 'text',
      prowler: 'AWS, GCP, Azure',
    };

    const securityTools = controls.map((control) => control.control_name.toLowerCase()).flat();
    // loop security tools and add language if it exists
    securityTools.forEach((securityTool) => {
      if (securityToolLanguageMap[securityTool]) {
        supportedLanguages.push(securityToolLanguageMap[securityTool]);
      }
    });

    return uniq(supportedLanguages.map((language) => getSupportedLanguagesDisplayText(language).split(', ')).flat());
  }, [getSupportedLanguagesDisplayText]);

  const getSecurityTools = useCallback((controls: IControlDetails[]) => {
    const securityTools = controls.filter((control) => !control.is_hidden).map((control) => control.control_name).flat();
    return uniq(securityTools.map((securityTool) => getSecurityToolDisplayText(securityTool)));
  }, [getSecurityToolDisplayText]);

  const getDistinctControls = useCallback((planItem: IPlanItemDetails) => (planItem?.controls ? groupBy(planItem.controls, 'control_name') : {}), []);

  const getLinks = useCallback((controls: IControlDetails[]): IControlElementLinks => {
    // get all links from controls and remove duplicates
    const distinctLinks: IControlElementLinks = {};
    controls.forEach((control) => {
      Object.entries(control.links).forEach(([key, value]) => {
        if (!distinctLinks[key]) {
          distinctLinks[key] = value;
        }
      });
    });
    return distinctLinks;
  }, []);

  const getControls = useCallback((planItem: IPlanItemDetails): IControlElement[] => {
    const distinctControls = getDistinctControls(planItem);

    const controls = Object.entries(distinctControls).map(([securityTool, securityControls]): IControlElement | null => {
      const filteredControls = securityControls.filter((control) => !control.is_hidden);
      if (filteredControls.length > 0) {
        return {
          securityTool: getSecurityToolDisplayText(securityTool),
          supportedLanguages: getSupportedLanguages(filteredControls),
          links: getLinks(filteredControls),
        };
      }
      return null;
    });

    return controls.filter((control) => control !== null) as IControlElement[];
  }, [getDistinctControls, getSupportedLanguages, getSecurityToolDisplayText, getLinks]);

  return {
    getSupportedLanguages,
    getSecurityTools,
    getControls,
  };
};
