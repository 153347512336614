import { HeaderGroup, Row, TableBodyPropGetter, TableBodyProps, TablePropGetter, TableProps, usePagination, useTable } from 'react-table';

import { ITableRow } from 'types/interfaces';
import { TableColumn } from 'types/types';

export interface ITableInstance {
  getTableProps: (propGetter?: TablePropGetter<object> | undefined) => TableProps;
  getTableBodyProps: (propGetter?: TableBodyPropGetter<object> | undefined) => TableBodyProps;
  headerGroups: HeaderGroup<object>[];
  prepareRow: (row: Row<object>) => void;
  page: Row<ITableRow>[];
  canPreviousPage: boolean;
  canNextPage: boolean;
  getNextPage: () => void;
  getPreviousPage: () => void;
  pageIndex: number;
}

interface Props {
  columns: TableColumn<object>[];
  data: object[];

  canPreviousPage: boolean;
  canNextPage: boolean;
  getNextPage: () => void;
  getPreviousPage: () => void;
  pageIndex: number;
}

export const useManageTable = ({
  columns,
  data,
  canPreviousPage,
  canNextPage,
  getNextPage,
  getPreviousPage,
  pageIndex,
}: Props): ITableInstance => {
  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
    },
    usePagination,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
  } = tableInstance;

  return {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page: page as Row<ITableRow>[],
    getNextPage,
    getPreviousPage,
    canPreviousPage,
    canNextPage,
    pageIndex,
  };
};
