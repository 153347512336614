import { getAllFindingsTypesOfDeployment } from './getAllFindingsTypesOfDeployment';

import { constants } from 'globalConstants';
import { FindingStatus } from 'types/enums';
import { IPipelineNew } from 'types/interfaces';
import { IInsightCategory, IInsightCategoryItem } from 'types/interfaces/Insights';

const parseDeploymentItemToInsightCategoryItem = (deployments: IPipelineNew[] = []): IInsightCategoryItem[] => {
  const parsedDeployments = deployments.map((deployment) => ({
    repoOrg: deployment.sourceAssetOwner || '',
    repoName: deployment.sourceAssetName || '',
    url: deployment.additionalAttributes?.url || '',
    showDetailsUrl: `/${constants.routes.PIPELINES}/${deployment.pipelineId}`,
    timestamp: deployment.createdAt || '',
    title: `${deployment.sourceAssetOwner}/${deployment.sourceAssetName}`,
    owner: deployment.additionalAttributes?.sender?.login || '',
    ownerAvatar: deployment.additionalAttributes?.sender?.avatarUrl || '',
    findings: getAllFindingsTypesOfDeployment(deployment),
  }));

  return parsedDeployments;
};

export const getDeploymentsInsightCategories = (deployments: IPipelineNew[] | undefined): IInsightCategory[] => {
  const parsedDeployments = parseDeploymentItemToInsightCategoryItem(deployments);
  const deploymentWithNewFindings = parsedDeployments.filter((deployment) => !!deployment.findings?.newFindings);
  // const deploymentWithExistingFindings = parsedDeployments.filter((deployment) => !!deployment.findings?.existingFindings.length);
  const deploymentWithFixedFindings = parsedDeployments.filter((deployment) => !!deployment.findings?.fixedFindings);

  const categories = [{
    key: 'deploymentsWithFailedFindings',
    title: 'pages.deployments.deploymentsLists.types.deploymentsWithFailedFindings.title',
    subTitle: 'pages.deployments.deploymentsLists.types.deploymentsWithFailedFindings.subTitle',
    items: deploymentWithNewFindings,
    oppositeColor: true,
    boldFindingStatus: FindingStatus.OPEN,
  },
  // {
  //   key: 'mergedPrsWithFailedIgnored',
  //   title: 'pages.deployments.deploymentsLists.types.deploymentsWithIgnoredFindings.title',
  //   subTitle: 'pages.deployments.deploymentsLists.types.deploymentsWithIgnoredFindings.subTitle',
  //   items: deploymentWithExistingFindings,
  //   changePercentage: 0,
  //   oppositeColor: true,
  // },
  {
    key: 'deploymentsWithFixedFindings',
    title: 'pages.deployments.deploymentsLists.types.deploymentsWithFixedFindings.title',
    subTitle: 'pages.deployments.deploymentsLists.types.deploymentsWithFixedFindings.subTitle',
    items: deploymentWithFixedFindings,
    oppositeColor: true,
    boldFindingStatus: FindingStatus.FIXED,
  }];

  return categories;
};
