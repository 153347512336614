import { FC } from 'react';

import { InfoIcon } from './components/InfoIcon/InfoIcon';
import styles from './ScoreBanner.module.scss';

import { JitText } from 'components/JitText/JitText';
import { Score } from 'components/Score/Score';
import colors from 'themes/colors.module.scss';

interface ScoreBannerProps {
  score: number;
}

export const ScoreBanner: FC<ScoreBannerProps> = ({ score }) => (
  <div className={styles.wrapper} data-testid='scoreBanner'>
    <div className={styles.textWrapper}>
      <JitText color={colors.lightGray} text='pages.teams.teamDetails.score' />

      <div className={styles.icon}>
        <InfoIcon />
      </div>
    </div>

    <Score score={score} />
  </div>
);
