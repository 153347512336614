import {
  IntegrateSlackButton,
  CloudIntegrationStatus,
  GoToIntegrationsButton,
  ManageTeamsButton,
  SCMIntegrationTask,
  SecurityPlansProgress,
  ManageTeamsStatus,
  SlackStatus,
  SecurityPlanStatus,
} from '../components';

export const mainComponentKeyToComponentMap = {
  scmIntegrationMain: SCMIntegrationTask,
  integrateSlackMain: IntegrateSlackButton,
  configureCloudIntegrationMain: GoToIntegrationsButton,
  configureTeamsMain: ManageTeamsButton,
  securityPlansProgressMain: SecurityPlansProgress,
};

export const statusComponentKeyToComponentMap = {
  exampleCheck1Status: CloudIntegrationStatus,
  manageTeamsStatus: ManageTeamsStatus,
  configureCloudIntegrationStatus: CloudIntegrationStatus,
  configureSlackStatus: SlackStatus,
  planProgress: SecurityPlanStatus,
};
