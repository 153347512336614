import { useMemo } from 'react';

import { IScoreTrendingEntity } from 'components/ScoreTrendingWidget/hooks/useGetScoreTrendingByWeek';
import colors from 'themes/colors.module.scss';
import { getColorByScore } from 'utils/functions/grades';

interface DayData {
  name: string;
  value: number | null;
  week: string;
  weeklyAvg: number | null;
}

const DAYS_IN_WEEK = 7;
const GAP_SIZE = 1;

// The gradient colors to be used in the graph
// the offset is the percentage of the score range, the color is the color to be used according to the score (offset)
// score > 90 => gradeA
// 90 > score > 80 => gradeB
// 80 > score > 70 => gradeC
// 70 > score > 60 => gradeD
const stopColors = [{
  offset: 10,
  color: colors.gradeA,
}, {
  offset: 20,
  color: colors.gradeB,
}, {
  offset: 30,
  color: colors.gradeC,
}, {
  offset: 40,
  color: colors.gradeD,
}];

export const useGetGraphData = (scoreTrendingEntity: IScoreTrendingEntity) => {
  const daysData: DayData[] = scoreTrendingEntity.weeks.flatMap((week) => week.days.map((day) => ({
    name: day.date,
    value: day.score,
    week: week.week,
    weeklyAvg: week.avgScore,
  })));

  const weekAreas = scoreTrendingEntity.weeks.map((week, index) => ({
    x1: index * DAYS_IN_WEEK,
    x2: (index + 1) * DAYS_IN_WEEK - GAP_SIZE,
    avgScore: week.avgScore,
    key: week.week,
  }));

  const sameScoreColor = useMemo(() => {
    const daysWithScore = daysData.filter((day) => day.value !== null);
    if (daysWithScore.length === 0) return null;
    const isAllDatesHaveSameScore = daysWithScore.every((day, index, array) => day.value === array[0].value);

    return isAllDatesHaveSameScore ? getColorByScore(daysWithScore[0].value!) : null;
  }, [daysData]);

  const calculateGradientStops = (daysWithScores: number[]) => {
    const maxScore = Math.max(...daysWithScores);
    const minScore = Math.min(...daysWithScores);

    const gradientValues: { color: string; offset: string }[] = [];
    const currentGap = maxScore - minScore;
    if (currentGap === 0) {
      return [
        { offset: '0%',
          color: getColorByScore(maxScore) },
      ];
    }

    stopColors.forEach((stopColor) => {
      const adjustedPercentage = stopColor.offset / (100 / currentGap);
      const newColor = {
        color: stopColor.color,
        offset: `${adjustedPercentage}%`,
      };
      gradientValues.push(newColor);
    });

    return gradientValues;
  };

  const sliceGradientArray = (daysWithScores: number[], gradientValues: { color: string; offset: string }[]) => {
    const maxScore = Math.max(...daysWithScores);
    const minScore = Math.min(...daysWithScores);
    const maxColor = getColorByScore(maxScore);
    const minColor = getColorByScore(minScore);

    const maxColorIndex = gradientValues.findIndex((color) => color.color === maxColor);
    const minColorIndex = gradientValues.findIndex((color) => color.color === minColor);

    return gradientValues.slice(maxColorIndex, minColorIndex + 1);
  };

  const gradientColors = useMemo(() => {
    const daysWithScores = daysData.filter((day) => day.value !== null).map((day) => day.value!);
    if (daysWithScores.length === 0) return [];

    const gradientValues = calculateGradientStops(daysWithScores);
    return sliceGradientArray(daysWithScores, gradientValues);
  }, [daysData]);

  return { daysData,
    weekAreas,
    sameScoreColor,
    gradientColors };
};
