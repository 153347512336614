import { createContext, useContext } from 'react';

import { IFileMetadata } from 'types/interfaces/Files/IFileMetadata';

interface IFilesContext {
  isLoadingFilesMetadata: boolean;
  fetchFilesMetadata: () => Promise<void>;
  filesMetadata?: IFileMetadata[];
  integrationFile?: IFileMetadata;
}

export const FilesContext = createContext<IFilesContext>({
  filesMetadata: undefined,
  isLoadingFilesMetadata: false,
  integrationFile: undefined,
  fetchFilesMetadata: () => Promise.resolve(),
});

export const useFilesContext = () => useContext(FilesContext);
