import { Dispatch, SetStateAction, useCallback } from 'react';

import { useConfigurationsContext } from 'context/ConfigurationsContext/ConfigurationsContext';
import { Application, IConfigurations } from 'types/interfaces';

export const upsertApp = (currentApplications: Application[], updatedApplication: Application): Application[] => {
  const currentApp = currentApplications?.find((app) => app.type === updatedApplication.type);
  if (currentApp) {
    return currentApplications?.map((item: Application) => {
      if (item.type === updatedApplication.type) {
        return updatedApplication;
      }
      return item;
    });
  }

  return [...currentApplications, updatedApplication];
};

export const useConfigurationStateManager = (type: string, onChangeConfiguration?: Dispatch<SetStateAction<IConfigurations>>) => {
  const { setConfigurations, configurations } = useConfigurationsContext();

  const setConfigurationWithCallback: typeof setConfigurations = useCallback((newConfigurations) => {
    setConfigurations(newConfigurations);
    if (onChangeConfiguration) {
      onChangeConfiguration(newConfigurations);
    }
  }, [setConfigurations, onChangeConfiguration]);

  const getCurrentConfigurations = useCallback(
    (currentConfigurations = configurations) => {
      const configuration = currentConfigurations.applications?.filter(
        (app: Application) => app.type === type,
      );
      return configuration && configuration.length ? configuration : [];
    },
    [configurations, type],
  );

  const handleDeleteConfiguration = useCallback(
    (keyName: keyof Application, value: string) => {
      setConfigurationWithCallback((prevConfigurations: IConfigurations) => ({
        ...prevConfigurations,
        applications: prevConfigurations.applications?.filter((app) => app[keyName] !== value),
      }));
    },
    [setConfigurationWithCallback],
  );

  return {
    getCurrentConfigurations,
    handleDeleteConfiguration,
  };
};
