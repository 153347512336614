export enum FileInputAnalyticsEvents {
  FileClearClicked = 'file-input-clear-clicked',
  FileUploadClicked = 'file-input-upload-clicked',
  FileDropped = 'file-input-file-dropped',
}

export enum UploadStatus {
  LOADING = 'Loading',
  COMPLETE = 'Complete',
  INVALID = 'Invalid',
  FAIL = 'Failed',
}
