import startCase from 'lodash/startCase';
import { FC } from 'react';

import { JitText } from 'components/JitText/JitText';
import { RANK_BADGE_COLORS } from 'pages/InsightsPage/SubPages/PerformancePage/components/GraphHeaderComponents/RankBadge/constatnts';
import styles from 'pages/InsightsPage/SubPages/PerformancePage/components/GraphHeaderComponents/RankBadge/RankBadge.module.scss';
import colors from 'themes/colors.module.scss';
import { PerformanceRateRank } from 'types/interfaces/Metrics/PerformanceRateOption';

interface Props {
  rank: PerformanceRateRank;
}

export const RankBadge: FC<Props> = ({ rank }) => (
  <div
    className={styles.wrapper}
    style={{ backgroundColor: RANK_BADGE_COLORS[rank] }}
  >
    <JitText bold color={colors.cards} text={startCase(rank)} />
  </div>
);
