export const buildSetPlanAsGoalAnalyticsEvent = (slug: string, source: string) => ({
  action: 'set-plan-as-goal',
  params: {
    slug,
    source,
  },
});

export const buildUnsetPlanAsGoalAnalyticsEvent = (slug: string, source: string) => ({
  action: 'unset-plan-as-goal',
  params: {
    slug,
    source,
  },
});
