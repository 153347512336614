import { FC } from 'react';

import styles from './Legend.module.scss';

import { JitText } from 'components/JitText/JitText';

interface Props {
  legendItems: {
    color: string;
    text: string;
  }[];
}

export const Legend: FC<Props> = ({ legendItems }) => (
  <div className={styles.wrapper}>
    {legendItems.map((item) => (
      <div key={item.text} className={styles.legendItem}>
        <div className={styles.dot} style={{ backgroundColor: item.color }} />

        <JitText color={item.color} size='s' text={item.text} />
      </div>
    ))}
  </div>
);
