export const parseObjectToQueryParams = (params: object): string => {
  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value === undefined) return;

    let stringValue;
    if (Array.isArray(value)) stringValue = value.join(',');
    else if (typeof value === 'object') stringValue = JSON.stringify(value);
    else stringValue = value.toString();
    searchParams.set(key, stringValue);
  });
  const commaAsciiSymbol = '%2C';
  // by default, 'URLSearchParams' parse symbols to their ascii code, we want to ignore this in the case of the comma symbol for better appearance.
  return searchParams.toString().replaceAll(commaAsciiSymbol, ',');
};
