import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import React, { FC, ReactElement } from 'react';

import styles from './CustomDialog.module.scss';

import { Cross } from 'assets';
import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitDivider } from 'components/JitDivider/JitDivider';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import commonStyles from 'themes/common.module.scss';
import { IMouseEvent } from 'types/interfaces';
import { ActionButton } from 'types/interfaces/ActionButton/ActionButton';

export type DialogWidthOptions = 's' | 'm' | 'l' | 'xl' | 'xxl' | 'fullScreen';
export type DialogHeightOptions = 's' | 'm' | 'l' | 'xl' | 'fullScreen' | 'auto';

interface Props {
  content: ReactElement;
  isOpen: boolean;
  isHidden?: boolean;
  onClick?: (e: IMouseEvent) => void;
  onClose?: (event: object | React.MouseEvent<HTMLButtonElement>, reason?: string) => void;
  title?: string | ReactElement;
  withDivider?: boolean;
  width?: DialogWidthOptions;
  height?: DialogHeightOptions;
  actionButtons?: ActionButton[];
  titleActionButtons?: ActionButton[];
  dataTestId?: string;
  contentPadding?: number;
  actionButtonsPadding?: string;
  backgroundColor?: string;
  shouldShowEdgesBackground?: boolean;
  borderColor?: string;
}

const widthOptions = {
  s: 450,
  m: 560,
  l: 720,
  xl: 800,
  xxl: 1080,
  fullScreen: '100%',
};

const heightOptions = {
  s: 450,
  m: 580,
  l: 720,
  xl: 800,
  fullScreen: '100%',
  auto: 'auto',
};

const paperStyle = {
  backgroundColor: colors.cards,
  boxShadow: 'none',
  borderRadius: 8,
  backgroundImage: 'none',
  overflow: 'auto',
  padding: 0,
};

export const CustomDialog: FC<Props> = ({
  content,
  isOpen,
  isHidden,
  onClick,
  onClose,
  title,
  withDivider,
  width = 's',
  height = 'auto',
  actionButtons,
  titleActionButtons,
  dataTestId = 'customDialog',
  contentPadding = 0,
  actionButtonsPadding,
  backgroundColor = paperStyle.backgroundColor,
  shouldShowEdgesBackground = true,
  borderColor,
}) => {
  if (!isOpen) return null;

  // onClick is not one of the props of Dialog component, so it needs to be added conditionally in order to avoid issues with the default component
  const conditionalProps = onClick ? { onClick } : {};
  const titleElement = typeof title === 'string' ? <JitText size='l' text={title} /> : title;

  return (
    <Dialog
      {...conditionalProps}
      aria-describedby='alert-dialog-description'
      aria-labelledby='alert-dialog-title'
      BackdropProps={{
        style: {
          backgroundColor: colors.popoverBackdrop,
        },
      }}
      data-testid={dataTestId}
      maxWidth='xl'
      onClose={(e) => onClose && onClose(e)}
      open={isOpen}
      PaperProps={{
        style: {
          ...paperStyle,
          background: backgroundColor,
          width: widthOptions[width],
          height: heightOptions[height],
          display: isHidden ? 'none' : 'inherit',
          overflowX: 'hidden',
          ...(borderColor && { border: `1px solid ${borderColor}` }),

        },
      }}
    >
      <DialogTitle
        className={styles.title}
        id='alert-dialog-title'
      >
        <div data-testid='TitleWrapper'>
          {!!titleElement && (titleElement)}
        </div>

        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
        >

          {titleActionButtons && (
            <div className={styles.buttonsWrapper}>
              <div className={styles.actionButtons}>
                {titleActionButtons.map((actionButton) => <JitActionButton key={`actionButton-${actionButton.label}`} actionButton={actionButton} />)}
              </div>
            </div>
          )}

          {!!onClose && (
            <IconButton className={styles.close} data-testid='close-dialog-button' onClick={onClose}>
              <JitIcon icon={Cross} size={25} />
            </IconButton>
          )}
        </div>

      </DialogTitle>

      {withDivider && <JitDivider />}

      <DialogContent
        className={shouldShowEdgesBackground ? commonStyles.scrollInnerShadow : undefined}
        sx={{ padding: contentPadding }}
      >
        {content}
      </DialogContent>

      {actionButtons && (
        <div className={styles.buttonsWrapper} style={{ padding: actionButtonsPadding }}>
          <div className={styles.actionButtons}>
            {actionButtons.map((actionButton) => <JitActionButton key={`actionButton-${actionButton.label}`} actionButton={actionButton} />)}
          </div>
        </div>
      )}
    </Dialog>
  );
};
