import { createContext, useContext } from 'react';

import { WebSocketNotificationTopics } from 'types/enums';
import { WebsocketNotificationCallback } from 'types/types';

interface IWebSocketContext {
  subscribeTopic: (notificationTopic: WebSocketNotificationTopics, handler: WebsocketNotificationCallback<object>) => { handlerId: string },
  unsubscribeTopic: (handlerId: string) => void,
  setTopicHandler: (notificationTopic: WebSocketNotificationTopics, handler: WebsocketNotificationCallback<object>, handlerId: string) => void,
}

export const WebSocketContext = createContext<IWebSocketContext>({
  subscribeTopic: () => ({ handlerId: '' }),
  unsubscribeTopic: () => {},
  setTopicHandler: () => {},
});

export const useWebSocketContext = () => useContext(WebSocketContext);

