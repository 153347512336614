import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitText } from 'components/JitText/JitText';
import { constants } from 'globalConstants';
import { InviteMembersDialog } from 'pages/TeamsPage/SubPages/TeamsPage/components/InviteMembersDialog/InviteMembersDialog';
import { useCurrentTeam } from 'pages/TeamsPortal/hooks/useCurrentTeam/useCurrentTeam';
import colors from 'themes/colors.module.scss';
import { ActionButtonVariant, ITableCell } from 'types/interfaces';
import { useIsUserMemberInTeam } from 'utils/hooks/useIsUserMemberInTeam';
import { PERMISSIONS } from 'wrappers/RBAC/constants';
import { useHasPermissions } from 'wrappers/RBAC/hooks/useHasPermissions';

export const ActivatedCell: FC<ITableCell> = ({ cell: { value: team } }) => {
  const {
    members,
    isActivated,
  } = team;
  const navigate = useNavigate();
  const [isInviteMembersDialogOpen, setIsInviteMembersDialogOpen] = useState<boolean>(false);
  const { isMemberInTeam } = useIsUserMemberInTeam(members);
  const { handleCurrentTeamChange } = useCurrentTeam();
  const {
    teamsPortal: {
      BASE_ROUTE: BASE_TEAMS_PORTAL_ROUTE,
      GUIDE,
    },
  } = constants.routes;
  const { hasPermission } = useHasPermissions();

  const doesHaveInvitePermissions = hasPermission(PERMISSIONS.INVITE);
  const doesHaveActivatePermissions = hasPermission(PERMISSIONS.WRITE_TEAMS);
  const shouldAllowInvite = !isMemberInTeam && doesHaveInvitePermissions;

  const handleActivateTeam = () => {
    handleCurrentTeamChange(team);
    navigate(`/${BASE_TEAMS_PORTAL_ROUTE}/${GUIDE}`);
  };

  if (isActivated) {
    return <JitText color={colors.successGreen02} text='Activated' />;
  }

  if (shouldAllowInvite) {
    return (
      <>
        <JitActionButton actionButton={{
          label: 'pages.teamsPortal.leaderboardPage.table.cells.inviteEngineeringManager',
          handleClick: () => setIsInviteMembersDialogOpen(true),
          variant: ActionButtonVariant.SECONDARY,
        }}
        />

        <InviteMembersDialog isOpen={isInviteMembersDialogOpen} onClose={() => setIsInviteMembersDialogOpen(false)} />
      </>
    );
  }

  return (
    <JitActionButton actionButton={{
      label: 'pages.teamsPortal.leaderboardPage.table.cells.activateTeam',
      handleClick: handleActivateTeam,
      variant: ActionButtonVariant.SECONDARY,
      disabled: !doesHaveActivatePermissions,
    }}
    />
  );
};

