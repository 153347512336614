import { FC } from 'react';

import { GeneralErrorPage } from 'components/GeneralErrorPage/GeneralErrorPage';

export const ErrorBoundaryFallback: FC = () => (
  <GeneralErrorPage
    actionButtons={[{ label: 'pages.generalError.refresh',
      handleClick: () => { window.location.reload(); } }]}
    description='pages.generalError.description'
    title='pages.generalError.title'
  />
);
