import { FC, useEffect, UIEvent, useCallback } from 'react';

import { PageContent } from './components/PageContent/PageContent';

import { JitFilters } from 'components/JitFilters/JitFilters';
import { calcShouldFetchMore } from 'components/JitTable/utils';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { PageTitles } from 'components/PageTitles/PageTitles';
import { useActionsContext } from 'context/ActionsContext/ActionsContext';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import { i18n } from 'locale/i18n';
import styles from 'pages/ActionsPage/ActionsPage.module.scss';
import { useFetchActionsCount } from 'services/ActionService/useFetchActions';
import { IFilter } from 'types/interfaces';
import { initialPaginatedState } from 'types/interfaces/IPaginatedState';

export const ActionsPage: FC = () => {
  const {
    actions, isLoading, setActionFilters, getActions, isFetchActionFiltersLoading, applyFilterToQueryParam, actionFilters, getActionDistinctFilters,
    setActions,
  } = useActionsContext();
  const { isLoading: isLoadingActionsCount, actionsCount, getActionsCount } = useFetchActionsCount();
  const { isAnyScmVendorIntegrated } = useTenantContext();

  const { t } = i18n;

  useEffect(() => {
    getActionDistinctFilters();

    // eslint-disable-next-line react-hooks/exhaustive-deps -- getActions is a function that is not supposed to change
  }, []);

  useEffect(() => {
    if (actionFilters.length > 0) {
      getActions(actionFilters, true);
      getActionsCount(actionFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionFilters]); //  We only want to run this when actionFilters change

  const updateFilter = (updatedFilter: IFilter) => {
    const updatedFilters = actionFilters.map((filter) => (filter.entityKey === updatedFilter.entityKey ? updatedFilter : filter));
    setActions(initialPaginatedState);
    setActionFilters(updatedFilters);
    applyFilterToQueryParam(updatedFilter);
  };

  const handleScroll = useCallback((event: UIEvent<HTMLDivElement>) => {
    if (isLoading || actions.hasReachedEnd) return;

    const shouldFetchMore = calcShouldFetchMore(event, 100);
    if (shouldFetchMore) {
      getActions(actionFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions.hasReachedEnd, getActions, isLoading]); // We do not want to run this when actionFilters change,
  // As we already handle it in the useEffect above (If the filters change - we do a clean fetch of actions.)

  let subtitle = '';
  if (!isLoadingActionsCount || actionsCount) {
    subtitle = actionsCount !== 1
      ? t('pages.actions.manySubtitle', { numberOfActions: actionsCount })
      : 'pages.actions.singleSubtitle';
  }

  const getPageContent = () => {
    if (isAnyScmVendorIntegrated && isLoading && actions.data.length === 0) return <LoadingBar data-testid='actionsLoadingBar' />;

    return (
      <>
        <PageContent actions={actions.data} handleScroll={handleScroll} />

        {isAnyScmVendorIntegrated && isLoading && <LoadingBar sx={{ w: 1 }} />}
      </>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titlesWrapper}>
        <PageTitles subtitle={subtitle} title='pages.actions.title' />
      </div>

      <div className={styles.filtersWrapper}>
        {!isFetchActionFiltersLoading && <JitFilters filters={actionFilters} shouldCloseOnItemClick={false} updateFilter={updateFilter} />}
      </div>

      {getPageContent()}
    </div>
  );
};
