import { FC, useEffect, useMemo, useState } from 'react';

import { NavButton } from '..';

import styles from './NavButtonGroup.module.scss';

import { ExpandArrow, MinimizeArrow } from 'assets';
import { ITabGroup, ITabItem } from 'components/Navbar/hooks';

interface Props {
  tabGroup: ITabGroup,
  isCollapsed: boolean,
  selectedTabName?: string,
  onTabItemClick: (tabItem: ITabItem) => void,
}

export const NavButtonGroup: FC<Props> = ({ tabGroup, isCollapsed, selectedTabName, onTabItemClick }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isSelected = useMemo(() => tabGroup.items.some((tabItem) => tabItem.name === selectedTabName), [tabGroup, selectedTabName]);

  useEffect(() => {
    if (isCollapsed && !isSelected) setIsExpanded(false);
  }, [isCollapsed, isSelected]);

  return (
    <div>
      <NavButton
        icon={tabGroup.icon}
        isCollapsed={isCollapsed}
        isDisabled={tabGroup.isDisabled}
        isSelected={isSelected && isCollapsed}
        onClick={() => setIsExpanded(!isExpanded)}
        rightSideIcon={isExpanded ? ExpandArrow : MinimizeArrow}
        text={tabGroup.name}
      />

      {!isCollapsed && isExpanded && tabGroup.items.map((tabItem) => (
        <div key={tabItem.name} className={styles.groupButton}>
          <NavButton
            icon={tabItem.icon}
            isCollapsed={isCollapsed}
            isDisabled={tabItem.isDisabled}
            isSelected={tabItem.name === selectedTabName}
            onClick={() => onTabItemClick(tabItem)}
            text={tabItem.name}
          />
        </div>
      ))}
    </div>
  );
};
