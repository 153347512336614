import { FC } from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath } from 'reactflow';

import styles from './CustomEdge.module.scss';
import { CustomEdgeData } from './types';

import { JitTooltip } from 'components/JitTooltip/JitTooltip';

export const CustomEdge: FC<EdgeProps<CustomEdgeData>> = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  source,
  target,
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const description = data?.description;
  return (
    <>
      <BaseEdge path={edgePath} style={style} />

      {description && (
      <EdgeLabelRenderer>
        <div
          className='nodrag nopan'
          data-testid={`CustomEdge-${source}-${target}`}
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            pointerEvents: 'all',
          }}
        >
          <JitTooltip followCursor={false} placement='top' title={description}>
            <div className={styles.descriptionBox}>
              {description}
            </div>
          </JitTooltip>
        </div>
      </EdgeLabelRenderer>
      )}
    </>
  );
};

