export enum TimeAgo {
  OneWeek = 'ONE_WEEK',
  TwoWeeks = 'TWO_WEEKS',
  OneMonth = 'ONE_MONTH',
  ThreeMonths = 'THREE_MONTHS',
}

export enum NonStandardTimeAgo {
  SixWeeks = 'SIX_WEEKS',
}
