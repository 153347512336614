/* eslint-disable max-statements */
import { AxiosResponse } from 'axios';
import { ChangeEvent, FC, SyntheticEvent, useCallback, useMemo, useRef, useState } from 'react';

import { UploadStatus } from './constants';
import styles from './JitFileInput.module.scss';
import { JitFileInputEmptyState } from './JitFileInputEmptyState';
import { JitFileInputNonEmptyState } from './JitFileInputNonEmptyState';

import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { UploadFileErrorResponse } from 'types/interfaces/Files/IUploadFile';

interface Props {
  accept?: string;
  handleChange: (value: string) => void;
  isFileContentValid: (value: string) => Promise<boolean>;
  handleUpload: (file: File) => Promise<AxiosResponse | AxiosResponse<UploadFileErrorResponse> | undefined>;
  analyticsTitle: string;
  inputValue: string;
  'data-testid'?: string,
}

interface FileState {
  file?: File;
  message: string;
  uploadStatus?: UploadStatus;
}

const defaultFileUploadState: FileState = {
  file: undefined,
  message: '',
  uploadStatus: undefined,
};

export const JitFileInput: FC<Props> = ({
  accept, handleChange, isFileContentValid, handleUpload, analyticsTitle, inputValue, ...props
}) => {
  const [fileUploadState, setFileUploadState] = useState<FileState>(defaultFileUploadState);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const analytics = useMemo(() => ({
    invalidType: `${analyticsTitle}-file-invalid-type`,
    invalidContent: `${analyticsTitle}-file-invalid-content`,
    success: `${analyticsTitle}-file-uploaded`,
  }), [analyticsTitle]);

  const isEmpty = useMemo(() => !fileUploadState.file && !inputValue, [fileUploadState.file, inputValue]);
  const isFailed = useMemo(
    () => fileUploadState.uploadStatus && [UploadStatus.FAIL, UploadStatus.INVALID].includes(fileUploadState.uploadStatus),
    [fileUploadState.uploadStatus],
  );

  const upload = async (fileToUpload: File) => {
    if (!fileToUpload) {
      return;
    }

    setFileUploadState((prevState) => ({
      ...prevState,
      uploadStatus: UploadStatus.LOADING,
    }));

    // validate the content of the file
    const fileContent = await fileToUpload.text();
    const isValid = await isFileContentValid(fileContent);
    if (!isValid) {
      setFileUploadState((prevState) => ({
        ...prevState,
        uploadStatus: UploadStatus.INVALID,
      }));

      sendAnalyticsEvent({
        action: analytics.invalidContent,
        params: {
          file_name: fileToUpload.name,
        },
      });

      return;
    }

    const response = await handleUpload(fileToUpload);
    if (response?.status && response.status >= 200 && response.status <= 299) {
      sendAnalyticsEvent({
        action: analytics.success,
        params: {
          file_name: fileToUpload.name,
        },
      });

      setFileUploadState((prevState) => ({
        ...prevState,
        uploadStatus: UploadStatus.COMPLETE,
      }));
      handleChange(fileToUpload.name);
    } else {
      let { message } = fileUploadState;
      if (response?.data) {
        if ('message' in response.data) {
          message = response.data.message;
        }
      }
      setFileUploadState((prevState) => ({
        ...prevState,
        uploadStatus: UploadStatus.FAIL,
        message,
      }));
    }
  };

  const handleFileUpload = async (files: FileList | null, e: SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!files || !files.length) {
      return;
    }

    const isTypeNotSupported = accept && files[0]?.type && !accept.includes(files[0].type);
    const fileEx = files[0]?.name.split('.').pop();
    const isExNotSupported = accept && fileEx && !accept.includes(fileEx);

    if (isTypeNotSupported || isExNotSupported) {
      sendAnalyticsEvent({
        action: analytics.invalidType,
        params: {
          file_name: files[0].name,
          file_extension: fileEx || '',
        },
      });

      // non supported file type in case of drag & drop
      return;
    }

    setFileUploadState((prevState) => ({
      ...prevState,
      file: files[0],
    }));
    await upload(files[0]);
  };

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    await handleFileUpload(e.target.files, e);
  };

  const handleFileClear = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = ''; // Reset the input value
    }
    setFileUploadState(defaultFileUploadState);
    handleChange('');
  }, [handleChange]);

  return (
    <div
      className={`${styles.fileInputWrapper} ${isEmpty && styles.emptyState} ${isFailed && styles.failed}`}
      data-testid={props['data-testid'] || 'jit-file-input-container'}
    >
      <input ref={inputRef} accept={accept} data-testid='jit-file-input' onChange={onFileChange} style={{ display: 'none' }} type='file' />

      {isEmpty
        ? (
          <JitFileInputEmptyState
            handleFileUpload={handleFileUpload}
            inputRef={inputRef}
          />
        )
        : (
          <JitFileInputNonEmptyState
            file={fileUploadState.file}
            handleFileClear={handleFileClear}
            inputValue={inputValue}
            message={fileUploadState.message}
            uploadStatus={fileUploadState.uploadStatus}
          />
        )}
    </div>
  );
};
