import { lowerCase } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { useAssetsContext } from 'context/AssetsContext/AssetsContext';
import { AssetType, Vendor } from 'types/enums/index';
import { TagNames } from 'types/enums/Tags';
import { IAsset } from 'types/interfaces/index';

export const useGetPotentialVendorAssetsToAdd = (teamName: string, vendor: Vendor) => {
  const { assets, isLoadingAssets } = useAssetsContext();
  const [vendorAssets, setVendorAssets] = useState<IAsset[] | null>(null);

  const filterAssetByVendor: Partial<{ [K in Vendor]: (asset: IAsset) => boolean }> = useMemo(() => ({
    [Vendor.Github]: (asset: IAsset) => asset.asset_type === AssetType.REPO,
  }), []);

  const filterAsset: (asset: IAsset) => boolean = (asset: IAsset) => asset.is_active && asset.is_covered && !asset.is_archived;

  useEffect(() => {
    if (!vendorAssets) {
      setVendorAssets(assets.filter((asset) => {
        const isVendorAsset = lowerCase(asset.vendor) === lowerCase(vendor);
        const isFilteredAsset = filterAsset(asset) && (filterAssetByVendor[vendor]?.(asset) ?? true);
        const isTeamAsset = asset.tags.some((tag) => tag.name === TagNames.Teams && tag.value === teamName);
        return isVendorAsset && isFilteredAsset && !isTeamAsset;
      }));
    }
  }, [teamName, vendor, assets, vendorAssets, filterAssetByVendor]);

  return {
    assets: vendorAssets,
    isLoadingAssets,
  };
};
