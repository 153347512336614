/* eslint-disable react/no-multi-comp */
/* eslint-disable react/no-unstable-nested-components */
import { FC, useMemo } from 'react';

import { useBaseTaskGuide } from './hooks/useBaseTaskGuide';

import { PanelLayout } from 'components/PanelLayout';

interface Props {
  title: string;
  description?: string;
  isCompleted: boolean;
  MainComponent?: FC;
  RightComponent?: FC;
  isDisabled?: boolean;
  isMainFullWidth?: boolean;
  styles?: {
    centerStyle?: React.CSSProperties;
    leftStyle?: React.CSSProperties;
    rightStyle?: React.CSSProperties;
  };
}

const defaultStyles = {
  centerStyle: {
    display: 'flex',
  },
  leftStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'flex-start',
  },
  rightStyle: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
  },
};

export const BaseTaskGuide: FC<Props> = ({
  title,
  description,
  isCompleted,
  MainComponent,
  RightComponent,
  isDisabled,
  isMainFullWidth = true,
  styles,
}) => {
  const { getLeftComponent, getRightComponent, getCenterComponent } = useBaseTaskGuide({ isDisabled });

  const customStyles = useMemo(() => ({
    center: styles?.centerStyle || defaultStyles.centerStyle,
    left: styles?.leftStyle || defaultStyles.leftStyle,
    right: styles?.rightStyle || defaultStyles.rightStyle,
  }), [styles]);

  const centerComponent = useMemo(() => ({
    component: getCenterComponent({
      title,
      description,
      isMainFullWidth,
      MainComponent,
    }),
    style: customStyles.center,
  }), [getCenterComponent, title, description, MainComponent, isMainFullWidth, customStyles.center]);

  const leftComponent = useMemo(() => ({
    component: getLeftComponent(isCompleted),
    style: customStyles.left,
  }), [getLeftComponent, isCompleted, customStyles]);

  const rightComponent = useMemo(() => ({
    component: getRightComponent(RightComponent),
    style: customStyles.right,
  }), [RightComponent, getRightComponent, customStyles]);

  const rowComponents = useMemo(() => [
    {
      left: leftComponent,
      center: centerComponent,
      right: rightComponent,
    },
    ...(isMainFullWidth && MainComponent ? [{
      left: {
        component: () => <div />,
      },
      center: {
        component: () => <MainComponent />,
        style: { flex: 7 },
      },
    }] : []),
  ], [MainComponent, centerComponent, isMainFullWidth, leftComponent, rightComponent]);

  return (
    <PanelLayout
      rowComponents={rowComponents}
    />
  );
};
