import { useFlags } from 'launchdarkly-react-client-sdk';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { staticAuthModeOptions } from '../constants';

import { ConfigurationsTargetUrlField } from './ConfigurationsTargetUrlField';
import { ConfigurationsZapExtraFields } from './ConfigurationsZapExtraFields';
import { ConfigurationsZapField } from './ConfigurationsZapField';
import styles from './ConfigurationsZapForm.module.scss';
import { HandleChangeType } from './types';

import { ConfigurationsCheckboxField } from '.';

import { useZapValidator } from 'components/Configurations/utils/useZapValidator';
import { JitDropdownNew } from 'components/JitDropdownNew/JitDropdownNew';
import { useConfigurationsContext } from 'context/ConfigurationsContext/ConfigurationsContext';
import { ApplicationNameValidator } from 'context/ConfigurationsContext/validators/ApplicationNameValidator';
import { ZapApiValidator, ZapDomainValidator, ZapUrlValidator, ZapWebValidator } from 'context/ConfigurationsContext/validators/ZapValidators';
import { ValidationBanner } from 'pages/PlanPage/components/ConfigurePlanItemDialog/ValidationBanner';
import { ConfigurePlanItemDialogButtons } from 'pages/PlanPage/components/ConfigurePlanItemDialogButtons/ConfigurePlanItemDialogButtons';
import { AssetType } from 'types/enums/AssetType';
import { MenuItemKeyType, ZapApplication, ZapAuthenticationConfigType, ZapAuthMode } from 'types/interfaces';

interface Props {
  type: ZapAuthenticationConfigType;
  onSave: () => void;
  onCancel: () => void;
  selectedApplication: ZapApplication;
  setSelectedApplication: Dispatch<SetStateAction<ZapApplication | undefined>>;
  applicationNameBeforeEditing: string;
}

export const ConfigurationsZapForm: FC<Props> = ({
  type,
  onSave,
  onCancel,
  selectedApplication,
  setSelectedApplication,
  applicationNameBeforeEditing,
}) => {
  const { uiShowZapAuthenticationMode } = useFlags();
  const { configurations } = useConfigurationsContext();
  const [isValidConfig, setIsValidConfig] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  const { error, isValidating, validateZap, validationResult } = useZapValidator();

  const isUrlValid = useMemo(() => ZapUrlValidator(false, selectedApplication.target_url), [selectedApplication.target_url]);
  const isDomainValid = useMemo(() => ZapDomainValidator(selectedApplication.api_domain), [selectedApplication.api_domain]);
  const isAuthChecked = useMemo(
    () => selectedApplication?.authentication_mode !== ZapAuthMode.NonAuthenticated,
    [selectedApplication?.authentication_mode],
  );

  useEffect(() => {
    if (validationResult?.isValid && !isValidating && !error && !hasSaved) {
      setHasSaved(true);
      onSave();
    }
  }, [validationResult?.isValid, isValidating, error, hasSaved, onSave]);

  const handleChange: HandleChangeType = useCallback(
    (fieldName, fieldValue) => {
      if (!selectedApplication) return;
      setSelectedApplication((prev) => (prev ? {
        ...prev,
        [fieldName]: fieldValue,
      } : undefined));
    },
    [selectedApplication, setSelectedApplication],
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      handleChange(name as keyof ZapApplication, value);
    },
    [handleChange],
  );

  const onChangeExcludedPaths = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const excludePathsArray = value
      ? value.split(',')
        .map((path) => path.trim())
      : [];
    handleChange('exclude_paths', excludePathsArray);
  }, [handleChange]);

  const onChangeAuthenticationMode = useCallback((newAuthMode: ZapAuthMode) => {
    handleChange('authentication_mode', newAuthMode);
  }, [handleChange]);

  const onChangeAuthenticationModeCheckbox = useCallback((isChecked: boolean) => {
    if (uiShowZapAuthenticationMode) {
      const updatedAuthMode = isChecked ? ZapAuthMode.FormBased : ZapAuthMode.NonAuthenticated;
      onChangeAuthenticationMode(updatedAuthMode);
    }
  }, [uiShowZapAuthenticationMode, onChangeAuthenticationMode]);

  const handleAuthModeChange = useCallback(
    ({ itemKey }: { itemKey: MenuItemKeyType }) => {
      onChangeAuthenticationMode(itemKey as ZapAuthMode);
    },
    [onChangeAuthenticationMode],
  );

  const handleSaveApplication = useCallback(async () => {
    await validateZap(type, selectedApplication, configurations);
  }, [validateZap, type, selectedApplication, configurations]);

  const authModeDropdownOptions = useMemo(
    () => staticAuthModeOptions.map((option) => ({
      ...option,
      isSelected: selectedApplication?.authentication_mode === option.itemKey,
    })),
    [selectedApplication],
  );

  const isApplicationNameValid = useMemo(
    () => selectedApplication.application_name === applicationNameBeforeEditing
      || ApplicationNameValidator(selectedApplication.application_name, configurations),
    [selectedApplication.application_name, applicationNameBeforeEditing, configurations],
  );

  useEffect(() => {
    if (selectedApplication) {
      const isConfigValid = type === AssetType.WEB
        ? ZapWebValidator({ applications: [selectedApplication] })
        : ZapApiValidator({ applications: [selectedApplication] });

      setIsValidConfig(isConfigValid && isApplicationNameValid);
    }
  }, [selectedApplication, type, isApplicationNameValid]);

  const isSaveButtonDisabled = useMemo(() => !isValidConfig || isValidating, [isValidConfig, isValidating]);
  const isSaveButtonDisabledTooltip = isValidating ? 'configurations.zap.validation.validatingUrls' : 'pages.plan.configurationsDialog.missingField';
  const excludePathsValue = selectedApplication.exclude_paths?.join(', ') || '';

  const handleChangeAppName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    // Remove colons and spaces from the application name
    const formattedValue = event.target.value.replace(/[^a-z0-9-_]/g, '');
    handleInputChange({
      ...event,
      target: {
        ...event.target,
        name: event.target.name,
        value: formattedValue,
      },
    });
  }, [handleInputChange]);

  return (
    <div className={styles.container}>
      <div className={styles.scrollableContent}>
        <ConfigurationsZapField
          dataTestid='configurations-zap-field-application-name'
          disabled={!!applicationNameBeforeEditing}
          handleChangeInput={handleChangeAppName}
          inputField='application_name'
          inputValue={selectedApplication.application_name}
          isValid={isApplicationNameValid}
          type={type}
        />

        {type === AssetType.API && (
          <ConfigurationsTargetUrlField
            dataTestid='configurations-zap-api-field-target-url'
            handleChange={handleChange}
            handleChangeInput={handleInputChange}
            inputField='target_url'
            inputValue={selectedApplication.target_url}
            type={type}
          />
        )}

        {type === AssetType.WEB && (
          <ConfigurationsZapField
            dataTestid='configurations-zap-web-field-target-url'
            handleChangeInput={handleInputChange}
            inputField='target_url'
            inputValue={selectedApplication.target_url}
            isValid={isUrlValid}
            type={type}
          />
        )}

        <ConfigurationsZapField
          dataTestid='configurations-zap-field-exclude-urls'
          handleChangeInput={onChangeExcludedPaths}
          inputField='exclude_paths'
          inputValue={excludePathsValue}
          isMultiLine
          type={type}
        />

        <ConfigurationsZapField
          handleChangeInput={handleInputChange}
          inputField='api_domain'
          inputValue={selectedApplication.api_domain || ''}
          isValid={isDomainValid}
          type={type}
        />

        <ConfigurationsCheckboxField
          disabled={!uiShowZapAuthenticationMode}
          isChecked={isAuthChecked && uiShowZapAuthenticationMode}
          label='configurations.zap.enableAuthentication.label'
          onChange={onChangeAuthenticationModeCheckbox}
          testid='zap-authentication'
          tooltipText={uiShowZapAuthenticationMode ? 'configurations.zap.enableAuthentication.info_enabled' : 'configurations.zap.enableAuthentication.info_disabled'}
        />

        {isAuthChecked && uiShowZapAuthenticationMode && (
          <>
            <div className={styles.dropdownWrapper}>
              <JitDropdownNew
                displayText={`configurations.zap.web.authModes.${selectedApplication.authentication_mode}`}
                menuItems={authModeDropdownOptions}
                onMenuItemClick={handleAuthModeChange}
                width={240}
              />
            </div>

            <div className={styles.extraFieldsWrapper}>
              <ConfigurationsZapExtraFields
                configurations={selectedApplication}
                handleChange={handleChange}
                handleChangeInput={handleInputChange}
                type={type}
              />
            </div>
          </>
        )}
      </div>

      <div className={styles.footer}>
        {!validationResult?.isValid && (
          <ValidationBanner
            isValidationLoading={isValidating}
            screenshot={validationResult?.screenshot}
            validationError={validationResult!?.reason}
          />
        )}

        <ConfigurePlanItemDialogButtons
          className={styles.buttonsWrapper}
          customCancelText='configurations.zap.backButton'
          disabledFinishButtonTooltip={isSaveButtonDisabledTooltip}
          finishText='configurations.zap.saveApplication'
          isFinishButtonDisabled={isSaveButtonDisabled}
          onCancelClicked={onCancel}
          onFinish={handleSaveApplication}
          shouldShowCircularProgress={isValidating}
        />
      </div>
    </div>
  );
};
