import styles from './VulnerabilityClassBadge.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { IFinding } from 'types/interfaces';

interface IVulnerabilityClassBadgeProps {
  finding: IFinding;
}

const getVulnerabilityClass = (finding: IFinding) => {
  // CVE and CWE are mutually exclusive, and there should only be one in either list.
  if (finding.cwes?.length) {
    return finding.cwes[0];
  }
  if (finding.cves?.length) {
    return finding.cves[0];
  }
  return null;
};

export const VulnerabilityClassBadge = ({ finding }: IVulnerabilityClassBadgeProps) => {
  const vulnerabilityClass = getVulnerabilityClass(finding);
  if (!vulnerabilityClass) {
    return null;
  }
  return (
    <div className={styles.badge} data-testid='vulnerability-class-badge'>
      <JitText bold color={colors.lightGray} size='xs' text={vulnerabilityClass} />
    </div>
  );
};
