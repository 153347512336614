import Menu from '@mui/material/Menu';
import { PaperProps } from '@mui/material/Paper';
import { CSSProperties, FC, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import brightVariantStyles from './JitMenuBrightVariant.module.scss';
import defaultVariantStyles from './JitMenuDefaultVariant.module.scss';
import lightVariantStyles from './JitMenuLightVariant.module.scss';

import { Search } from 'assets';
import { CategoriesAndItems } from 'components/JitMenu/CategoriesAndItems';
import { MenuItems, SelectedDesignVariants } from 'components/JitMenu/MenuItems';
import { calcShouldFetchMore } from 'components/JitTable/utils';
import { JitTextInput, JitTextInputVariants } from 'components/JitTextInput/JitTextInput';
import { JitReadOnlyTooltip } from 'components/JitTooltip/JitReadOnlyTooltip/JitReadOnlyTooltip';
import { logError } from 'services/logger/logger';
import { IMouseEvent, MenuItemKeyType } from 'types/interfaces';
import { stopPropagation, useUpdateEffect } from 'utils';

export type MenuItemType = {
  itemKey: MenuItemKeyType,
  itemName?: string,
  itemIcon?: ReactElement,
  itemContent: string | ReactElement,
  itemWrapper?: (wrapped: ReactElement) => ReactElement,
  isSelected?: boolean,
  searchValue?: string,
  disabled?: boolean
  tooltip?: string | null,
};

export type MenuItemSearchBox = {
  onChange?: (searchValue: string) => void;
  initialValue?: string;
  shouldHideAllWhenEmptyFilter?: boolean;
  placeholder?: string;
};
export type MenuItemCategories = {
  categoryKey: string,
  categoryContent: string | ReactElement,
  items: MenuItemType[],
  withCheckbox?: boolean,
  withRadioButtons?: boolean
};
export type MenuVariant = 'default' | 'bright' | 'light';

interface Props {
  menuItems?: MenuItemType[];
  menuItemsWithCategories?: MenuItemCategories[];
  onMenuItemClick?: ({ e, categoryKey, itemKey }: { e: IMouseEvent, categoryKey?: string, itemKey: MenuItemKeyType }) => void;
  children: ReactElement;
  style?: CSSProperties;
  menuItemsWrapperStyle?: CSSProperties;
  className?: string;
  menuItemsWrapperClassName?: string;
  withCheckbox?: boolean;
  withRadioButtons?: boolean;
  open?: boolean | undefined;
  withSearchBox?: MenuItemSearchBox;
  shouldBeInitiallyOpen?: boolean;
  shouldCloseOnItemClick?: boolean;
  isEnabled?: boolean;
  onMenuClose?: () => void;
  disabled?: boolean;
  childrenWrapperStyle?: CSSProperties;
  menuStyle?: CSSProperties;
  paperProps?: PaperProps;
  menuItemStyle?: CSSProperties;
  menuSelectedDesign?: SelectedDesignVariants;
  onMenuChangeOpenState?: (isOpen: boolean) => void;
  searchBoxPlaceHolder?: string
  inputColor?: JitTextInputVariants
  alignment?: 'left' | 'right';
  'data-testid'?: string;
  variant?: MenuVariant;
  isReadOnly?: boolean;
  handleFetchMore?: () => void;
}

const defaultMenuStyle = {
  marginTop: 1,
  p: 0,
  paddingTop: 5,
};

const variantToStyle: Record<MenuVariant, Record<string, string>> = {
  default: defaultVariantStyles,
  bright: brightVariantStyles,
  light: lightVariantStyles,
};

export const JitMenu: FC<Props> = ({
  menuItems, menuItemsWithCategories, onMenuItemClick, isEnabled = true, withSearchBox, onMenuClose, disabled = false,
  children, style = {}, className = '', withCheckbox = false, shouldBeInitiallyOpen = false,
  withRadioButtons = false, menuItemsWrapperStyle, menuItemsWrapperClassName, shouldCloseOnItemClick = false, childrenWrapperStyle, menuStyle,
  paperProps, open, handleFetchMore,
  menuItemStyle, menuSelectedDesign, onMenuChangeOpenState = () => { }, searchBoxPlaceHolder, inputColor, alignment = 'left', variant = 'default',
  isReadOnly, ...props
}) => {
  const styles = useMemo(() => variantToStyle[variant], [variant]);
  const validateProps = () => {
    if (!menuItems && !menuItemsWithCategories) {
      logError('JitMenu Error: One of the keys "menuItems" or "menuItemsWithCategories" must be defined!');
    }
    if (withCheckbox && withRadioButtons) {
      logError('JitMenu Error: Only one of the keys "withCheckbox" or "withRadioButtons" can be defined!');
    }
    const doAllMenuItemsHasSearchValue = (items: MenuItemType[] | undefined) => items?.every((menuItem) => menuItem.searchValue);
    const isMenuItemsSearchable = doAllMenuItemsHasSearchValue(menuItems);
    const isMenuItemsWithCategoriesSearchable = menuItemsWithCategories?.every((category) => doAllMenuItemsHasSearchValue(category.items));

    if (withSearchBox && !(isMenuItemsSearchable || isMenuItemsWithCategoriesSearchable)) {
      logError('JitMenu Error: When setting "withSearchBox" to be `true`, the menu items must have "searchValue".');
    }
  };
  validateProps();

  const anchorElementRef = useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(withSearchBox?.initialValue || '');

  const onMenuClick = (e: IMouseEvent) => {
    if (!(disabled || isMenuOpen || isReadOnly)) {
      setIsMenuOpen(true);
    }
    stopPropagation(e);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    setSearchValue('');
    if (onMenuClose) onMenuClose();
  };

  useEffect(() => {
    onMenuChangeOpenState(isMenuOpen);
  }, [isMenuOpen, onMenuChangeOpenState]);

  useEffect(() => {
    if (shouldBeInitiallyOpen) {
      setIsMenuOpen(true);
    }
  }, [shouldBeInitiallyOpen]);

  useUpdateEffect(() => {
    if (withSearchBox?.initialValue && searchValue !== withSearchBox?.initialValue) {
      setSearchValue(withSearchBox?.initialValue);
    }
  }, [withSearchBox?.initialValue]);

  const filterMenuItemsBySearch = useCallback((items: MenuItemType[]) => items.filter((item: MenuItemType) => item.searchValue?.toLowerCase()
    .includes(searchValue.toLowerCase())), [searchValue]);

  const menuItemsAfterSearchFilter = useMemo(() => {
    if (!searchValue || !menuItems) {
      return withSearchBox?.shouldHideAllWhenEmptyFilter ? [] : menuItems;
    }
    return filterMenuItemsBySearch(menuItems);
  }, [searchValue, menuItems, filterMenuItemsBySearch, withSearchBox]);

  const menuItemsWithCategoriesAfterFilter = useMemo(() => {
    if (!searchValue || !menuItemsWithCategories) {
      return menuItemsWithCategories;
    }
    return menuItemsWithCategories.map((category: MenuItemCategories) => ({
      ...category,
      items: filterMenuItemsBySearch(category.items),
    })).filter((category: MenuItemCategories) => category.items.length > 0);
  }, [searchValue, menuItemsWithCategories, filterMenuItemsBySearch]);

  const onItemClick = ({ e, categoryKey, itemKey }: { e: IMouseEvent, categoryKey?: string, itemKey: MenuItemKeyType }) => {
    if (onMenuItemClick) {
      onMenuItemClick({
        e,
        categoryKey,
        itemKey,
      });
    }
    if (shouldCloseOnItemClick) {
      closeMenu();
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const shouldFetchMore = calcShouldFetchMore(event, 20);
    if (shouldFetchMore && handleFetchMore) {
      handleFetchMore();
    }
  };

  useUpdateEffect(() => {
    if (withSearchBox?.onChange) {
      withSearchBox?.onChange(searchValue);
    }
  }, [searchValue]);

  const menuComponent = (
    <div
      className={className}
      data-testid={props['data-testid'] || 'JitMenuClick'}
      onClick={isEnabled ? onMenuClick : () => { }}
      role='button'
      style={style}
      tabIndex={0}
    >
      <div
        ref={anchorElementRef}
        style={childrenWrapperStyle || { height: '100%' }}
      >
        {children}
      </div>

      <Menu
        anchorEl={anchorElementRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: alignment,
        }}
        autoFocus={false}
        MenuListProps={{ disablePadding: true }}
        onClose={closeMenu}
        open={open !== undefined ? open : isMenuOpen}
        PaperProps={paperProps}
        sx={menuStyle || defaultMenuStyle}
        transformOrigin={{
          vertical: 'top',
          horizontal: alignment,
        }}
      >

        {withSearchBox && (
          <div className={styles.searchInputWrapper}>
            <JitTextInput defaultValue={searchValue} icon={Search} onChange={setSearchValue} placeholder={searchBoxPlaceHolder} variant={inputColor} />
          </div>
        )}

        <div className={`${styles.menuWrapper} ${menuItemsWrapperClassName}`} data-testid='jit-menu-items-wrapper' onScroll={handleScroll}>

          {menuItemsAfterSearchFilter && (
            <MenuItems
              items={menuItemsAfterSearchFilter}
              menuItemStyle={menuItemStyle}
              onMenuItemClick={onItemClick}
              selectedDesign={menuSelectedDesign}
              style={menuItemsWrapperStyle}
              variant={variant}
              withCheckbox={withCheckbox}
              withRadioButtons={withRadioButtons}
            />
          )}

          {menuItemsWithCategoriesAfterFilter && (
            <CategoriesAndItems
              categories={menuItemsWithCategoriesAfterFilter}
              menuItemStyle={menuItemStyle}
              menuItemsWrapperStyle={menuItemsWrapperStyle}
              onMenuItemClick={onItemClick}
              selectedDesign={menuSelectedDesign}
            />
          )}
        </div>
      </Menu>

    </div>
  );

  return isReadOnly ? (
    <JitReadOnlyTooltip>
      {menuComponent}
    </JitReadOnlyTooltip>
  ) : menuComponent;
};
