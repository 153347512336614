import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useEffect, useMemo, useCallback } from 'react';

import { NoTeamsEmptyState } from './components/NoTeamsEmptyState/NoTeamsEmptyState';
import { TeamsSyncGradient } from './components/TeamsSyncGradient/TeamsSyncGradient';

import { JittyNotFoundDouble, JittyTheExplorer } from 'assets';
import { IconWithDialog, IconWithDialogAction } from 'components/IconWithDialog/IconWithDialog';
import { JitEmpty } from 'components/JitEmpty/JitEmpty';
import { ElementToShowOnHoverProps } from 'components/JitTable/components';
import { MissingScmIntegration } from 'components/JitTable/components/MissingScmIntegration/MissingScmIntegration';
import { useGetTableInstance } from 'components/JitTable/hooks/useGetTableInstance';
import { JitTable } from 'components/JitTable/JitTable';
import { useAssetsContext } from 'context/AssetsContext/AssetsContext';
import { useTeamsContext } from 'context/TeamsContext/TeamsContext';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import { useToastsContext } from 'context/ToastsContext/ToastsContext';
import { constants } from 'globalConstants';
import { i18n } from 'locale/i18n';
import { TEAM_ROW_APPROXIMATE_HEIGHT } from 'pages/TeamsPage/constants';
import styles from 'pages/TeamsPage/SubPages/TeamsPage/components/TeamsTable/TeamsTable.module.scss';
import { useGetColumns } from 'pages/TeamsPage/SubPages/TeamsPage/components/TeamsTable/useGetColumns';
import { useTeamsService } from 'services/TeamsService/useTeamsService';
import colors from 'themes/colors.module.scss';
import { TagNames } from 'types/enums/Tags';
import { ToastType } from 'types/enums/ToastType';
import { IGetTeamResponseItem, ITeam } from 'types/interfaces/Teams/ITeam';

const MANUAL = 'manual';

interface TeamsTableProps {
  teams: IGetTeamResponseItem[];
  searchValue?: string;
  shouldDisplayColumns: boolean;
}

type TeamWithAssetsCount = IGetTeamResponseItem & { assetsCount: number };

export const TeamsTable: FC<TeamsTableProps> = ({ teams, searchValue, shouldDisplayColumns }) => {
  const { getTeams, teams: { hasReachedEnd, isLoading }, setTeams, isUploadingTeams } = useTeamsContext();
  const { preferences, isPreferencesLoading, initPreferences } = useTenantContext();
  const { uiShowUploadTeamsJsonFile } = useFlags();
  const { isAnyScmVendorIntegrated } = useTenantContext();
  const { deleteTeamById } = useTeamsService();
  const { showToast } = useToastsContext();

  useEffect(() => {
    if (!isPreferencesLoading && !preferences) {
      initPreferences();
    }
  }, [isPreferencesLoading, preferences, initPreferences]);

  const { coveredAssets: assets } = useAssetsContext();

  const handleReachScrollEnd = () => {
    if (isLoading || hasReachedEnd) return;

    getTeams({
      shouldResetState: false,
      searchValue,
    });
  };

  const onSelectRow = ({ id }: ITeam) => {
    const { teamsPortal } = constants.routes;

    window.open(`/${teamsPortal.BASE_ROUTE}/${teamsPortal.PORTAL_TEAMS}/${id}`, '_blank');
  };

  const teamsWithAssetsCount: TeamWithAssetsCount[] = useMemo(() => {
    if (!isAnyScmVendorIntegrated) return [];
    return teams.map(
      (team) => ({
        ...team,
        assetsCount: assets.filter((asset) => {
          const teamTagsValues = asset.tags.filter((tag) => tag.name === TagNames.Teams).map((tag) => tag.value);
          return teamTagsValues.includes(team.name);
        }).length,
      }),
    );
  }, [isAnyScmVendorIntegrated, assets, teams]);

  const emptyTableViewAtt = useMemo(() => (searchValue ? {
    description: 'pages.teams.teamsTable.noSearchResults.description',
    title: 'pages.teams.teamsTable.noSearchResults.title',
    icon: JittyTheExplorer,
  } : {
    description: 'pages.teams.teamsTable.noData.description',
    title: 'pages.teams.teamsTable.noData.title',
    icon: JittyNotFoundDouble,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [teams]);

  const EmptyTableViewComp = useCallback(() => {
    if (!isAnyScmVendorIntegrated) {
      return (
        <MissingScmIntegration
          subtitleAction='pages.teams.teamsTable.missingScmIntegrationSubtitleAction'
        />
      );
    }

    if (uiShowUploadTeamsJsonFile && teams.length === 0 && !searchValue && !isLoading) return <NoTeamsEmptyState />;

    return (
      <JitEmpty
        descriptionColor={colors.lightGray}
        titleColor={colors.white}
        {...emptyTableViewAtt}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnyScmVendorIntegrated, isLoading, teams.length, uiShowUploadTeamsJsonFile]);

  const teamsSource = teams?.[0]?.source;
  const shouldDisplayTeamsSyncGradient = useMemo(() => (
    teams.length > 0 && !preferences?.teams_sync?.sync_type && uiShowUploadTeamsJsonFile && teamsSource && !isPreferencesLoading
  ), [teams.length, preferences?.teams_sync?.sync_type, uiShowUploadTeamsJsonFile, teamsSource, isPreferencesLoading]);

  const handleRemoveTeam = async (teamId: string): Promise<void> => {
    const response = await deleteTeamById(teamId);

    if (response !== undefined) {
      setTeams((prevTeams) => ({
        ...prevTeams,
        data: prevTeams.data.filter((team) => team.id !== teamId),
      }));
      showToast({
        type: ToastType.Success,
        overrideProps: {
          title: 'pages.teams.teamsTable.removeTeam.successToast',
          secondsToClose: 3,
        },
      });
    }
  };

  const elementToShowOnRoweHover = ({ row }: ElementToShowOnHoverProps) => {
    const team = row.original as TeamWithAssetsCount;

    return (
      team.source === MANUAL && uiShowUploadTeamsJsonFile ? (

        <IconWithDialog
          action={IconWithDialogAction.DELETE}
          message={i18n.t('pages.teams.teamsTable.removeTeam.message', { teamName: team.name })}
          onConfirm={() => handleRemoveTeam(team.id)}
          subMessage='pages.teams.teamsTable.removeTeam.subMessage'
          title='pages.teams.teamsTable.removeTeam.title'
          tooltip='pages.teams.teamsTable.removeTeam.tooltip'
        />
      ) : null
    );
  };

  const loadingText = useMemo(() => {
    if (isUploadingTeams) {
      return 'pages.teams.teamsTable.importTeams.loadingText';
    }
    return undefined;
  }, [isUploadingTeams]);

  const columns = useGetColumns(shouldDisplayColumns);
  const tableInstance = useGetTableInstance(columns, teamsWithAssetsCount);
  return (
    <div className={styles.tableWrapper}>
      <JitTable
        cellVerticalAlign='center'
        ElementToShowOnRowHover={elementToShowOnRoweHover}
        EmptyTableView={EmptyTableViewComp}
        handleReachScrollEnd={{
          callback: handleReachScrollEnd,
          threshold: TEAM_ROW_APPROXIMATE_HEIGHT,
        }}
        isFetching={isAnyScmVendorIntegrated && isLoading && !!teams.length}
        isLoading={(isAnyScmVendorIntegrated && isLoading && !teams.length) || isUploadingTeams}
        loadingText={loadingText}
        onSelectRow={onSelectRow}
        showPagination={false}
        tableInstance={tableInstance}
        {...emptyTableViewAtt}
      />

      {shouldDisplayTeamsSyncGradient && <TeamsSyncGradient teamsSource={teamsSource!} />}

    </div>
  );
};
