import { startCase } from 'lodash';
import { FC } from 'react';

import styles from './ActionStatusLabel.module.scss';

import { JitText } from 'components/JitText/JitText';
import { actionStatusToColors } from 'pages/ActionsPage/components/ActionCard/components/ActionCardContent/Components/ActionStatusLabel/constants';
import { ActionStatus } from 'types/interfaces';

interface ActionStatusLabelProps {
  status: ActionStatus;
}

export const ActionStatusLabel: FC<ActionStatusLabelProps> = ({ status }) => {
  const { backgroundColor, textColor } = actionStatusToColors(status);
  const formattedStatus = startCase(status.toLowerCase());
  return (
    <div className={styles.wrapper} data-testid='actionCardStatusLabel' style={{ backgroundColor }}>

      <JitText align='center' color={textColor} data-testid='actionCardStatusText' size='m' text={formattedStatus} />

    </div>
  );
};
