import { isEmpty } from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getActivePlanItemsText } from '../Coverage/utils';

import { CoveredLayerCard } from './components/CoveredLayerCard/CoveredLayerCard';
import styles from './LayersCoverage.module.scss';

import { PlanCategoryIcon } from 'assets';
import { PercentageLineProgress } from 'components/PercentageLineProgress/PercentageLineProgress';
import { GraphLoading, GraphEmpty } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/components';
import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { IPlanStatus } from 'types/interfaces/Metrics/SecurityImpactMetrics/LayersCoverage';
import { iconsMap } from 'utils';

export interface LayersCoverageProps {
  planStatusMetric: MetricWithLoadingIndication<IPlanStatus>;
  shouldShowItemDetails?: boolean;
  shouldShowLayersIfNoCoverage?: boolean;
}

export const LayersCoverage: FC<LayersCoverageProps> = ({ planStatusMetric, shouldShowItemDetails = true, shouldShowLayersIfNoCoverage = true }) => {
  const { t } = useTranslation();
  const getContent = () => {
    if (planStatusMetric.isLoading) {
      return <GraphLoading />;
    }
    const planDescriptionText = t('pages.securityImpact.coverage.securityControls.description');
    const activeItemsDescriptionText = planStatusMetric.metric && getActivePlanItemsText(planStatusMetric.metric);

    const planStatus = planStatusMetric.metric;
    const percentage = planStatus?.activeItemsPercentage || 0;
    if (!isEmpty(planStatus) && planStatus) {
      return (
        <div className={styles.content}>
          <PercentageLineProgress
            percentage={percentage}
            subtitle={activeItemsDescriptionText ? `${planDescriptionText} (${activeItemsDescriptionText}).` : 'pages.securityImpact.layers.percentageDescription'}
          />

          {(shouldShowLayersIfNoCoverage || percentage > 0) && (
            <div data-testid='covered-layers-cards'>
              {Object.keys(planStatus.planItems).map((key) => {
                if (planStatus.planItems[key].activeItems.length !== 0 || planStatus.planItems[key].inactiveItems.length !== 0) {
                  return (
                    <CoveredLayerCard
                      key={key}
                      activeItems={planStatus.planItems[key].activeItems}
                      icon={iconsMap[key].icon || PlanCategoryIcon}
                      inactiveItems={planStatus.planItems[key].inactiveItems}
                      shouldShowItemDetails={shouldShowItemDetails}
                      title={`layer.${key}`}
                    />
                  );
                }
                return null;
              })}
            </div>
          )}
        </div>
      );
    }

    return (
      <GraphEmpty />
    );
  };

  const content = getContent();
  return (
    <div className={styles.wrapper} data-testid='layers-coverage'>
      {content}
    </div>
  );
};
