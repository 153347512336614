import { FC, useMemo } from 'react';

import styles from './WelcomePageHeader.module.scss';

import { JitTeams } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import colors from 'themes/colors.module.scss';

interface Props {
  isEmpty?: boolean
}

export const WelcomePageHeader: FC<Props> = ({ isEmpty }) => {
  const { frontEggUser } = useAuthContext();

  const cleanUserName = useMemo(() => {
    const [name] = frontEggUser.name.split('@');

    return name;
  }, [frontEggUser.name]);

  return (
    <div className={styles.root}>
      <JitText
        className={styles.header}
        components={{
          icon: <JitIcon icon={JitTeams} width={80} />,
        }}
        data-testid='welcome-page-title'
        params={{
          userName: cleanUserName || '',
        }}
        size='xl'
        text='pages.teamsPortal.welcomePage.title'
      />

      <JitText
        align='center'
        color={colors.lightGray}
        data-testid='welcome-page-subtitle'
        text={!isEmpty ? 'pages.teamsPortal.welcomePage.subtitle' : 'pages.teamsPortal.welcomePage.emptySubtitle'}
      />
    </div>
  );
};
