import { Tag } from 'components/JitCard/JitCard';
import { IntegrationVendorType } from 'context/IntegrationsContext/templates/interfaces';
import { LABELS_COLORS } from 'pages/SecurityPlansPage/components/SecurityPlansBody/constants';

const variantTags: Partial<Record<IntegrationVendorType, Tag>> = {
  tms: {
    color: LABELS_COLORS[0],
    text: 'cards.tags.ticketManagement',
  },
  cloud: {
    color: LABELS_COLORS[1],
    text: 'cards.tags.cloudServices',
  },
  sourceCodeManagement: {
    color: LABELS_COLORS[2],
    text: 'cards.tags.sourceCodeManagement',
  },
  notificationPlatform: {
    color: LABELS_COLORS[3],
    text: 'cards.tags.notificationPlatform',
  },
  commercial: {
    color: LABELS_COLORS[11],
    text: 'cards.tags.commercialTools',
  },
  openSource: {
    color: LABELS_COLORS[12],
    text: 'cards.tags.openSource',
  },
  registry: {
    color: LABELS_COLORS[8],
    text: 'cards.tags.registry',
  },
};

export const getVariantTagArray = (vendorType: IntegrationVendorType): Tag[] => {
  const tag = variantTags[vendorType];
  return tag ? [tag] : [];
};
