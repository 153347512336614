import { SvgIconComponent } from '@mui/icons-material';
import { FC } from 'react';

import styles from './ResourceTypeCard.module.scss';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { ISvg } from 'types/interfaces';

interface Props {
  icon?: FC<ISvg> | SvgIconComponent;
  title: string;
  coveredAssets: number;
  uncoveredAssets: number;
}

export const ResourceTypeCard: FC<Props> = ({ icon, title, coveredAssets, uncoveredAssets }) => (
  <div className={styles.wrapper}>
    <div className={styles.header}>
      <div className={styles.icon}>
        {icon && <JitIcon icon={icon} size={16} />}
      </div>

      <JitText bold text={title} />
    </div>

    <div className={styles.coveredUncovered}>
      <JitText bold size='m' text={`${coveredAssets}`} />

      <div className={styles.separator}>
        <JitText color={colors.gray} size='m' text='/' />
      </div>

      <JitText color={colors.gray} size='m' text={`${uncoveredAssets + coveredAssets}`} />
    </div>
  </div>
);
