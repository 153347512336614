import { FC } from 'react';

import { MetricInfoIcon, Title } from 'pages/InsightsPage/SubPages/PerformancePage/components/GraphHeaderComponents/index';
import styles from 'pages/InsightsPage/SubPages/PerformancePage/components/GraphHeaderComponents/TitleWithIcon/TitleWithIcon.module.scss';
import { IPerformanceRateOption, PerformanceRateRank } from 'types/interfaces/Metrics/PerformanceRateOption';

interface Props {
  title: string;
  popperTitle: string;
  avgValue?: string | null;
  displayRankInfoIcon: boolean;
  performanceRateOptions?: IPerformanceRateOption[];
  rank?: PerformanceRateRank | null;
  isLoading: boolean;
  popperDescription: string;
}

export const TitleWithIcon: FC<Props> = ({ title, popperTitle, avgValue, performanceRateOptions, popperDescription, rank, isLoading, displayRankInfoIcon }) => (
  <div className={styles.wrapper}>
    <Title title={title} />

    {displayRankInfoIcon && (
    <MetricInfoIcon
      avgValue={avgValue!}
      performanceRateOptions={performanceRateOptions!}
      popperDescription={popperDescription}
      popperTitle={popperTitle}
      rank={rank!}
      shouldDisablePopper={isLoading}
    />
    )}
  </div>
);
