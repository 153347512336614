import { t } from 'i18next';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { useGetPotentialVendorAssetsToAdd } from '../../hooks/useGetPotentialVendorAssetsToAdd';

import styles from './AddAssetsDialogContent.module.scss';

import { CircleQuestion } from 'assets';
import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitAutoCompleteForm } from 'components/JitAutoCompleteForm/components/JitAutocompleteForm';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import colors from 'themes/colors.module.scss';
import { Vendor } from 'types/enums/index';
import { ActionButton, ActionButtonVariant, IAsset } from 'types/interfaces';

interface Props {
  vendor: Vendor;
  teamName: string;
  isLoading: boolean;
  onConfirm: (event: React.MouseEvent<HTMLButtonElement>, selectedAssets: IAsset[]) => void;
}

const MAX_SELECTIONS = 10;

export const AddAssetsDialogContent: FC<Props> = ({ vendor, teamName, isLoading, onConfirm }) => {
  const [selectedAssets, setSelectedAssets] = useState<IAsset[]>([]);
  const { assets, isLoadingAssets } = useGetPotentialVendorAssetsToAdd(teamName, vendor);

  const handleChange = useCallback((value: IAsset[]) => {
    if (value.length <= MAX_SELECTIONS) {
      setSelectedAssets(value);
    }
  }, [setSelectedAssets]);

  const actionButton: ActionButton = useMemo(() => ({
    label: 'pages.teamsPortal.teamPage.table.add.confirm',
    handleClick: (e) => onConfirm(e, selectedAssets),
    variant: ActionButtonVariant.PRIMARY,
    disabled: selectedAssets.length === 0 || isLoading,
    isLoading,
    textProps: {
      noWrap: true,
    },
  }), [selectedAssets, isLoading, onConfirm]);

  const content = useMemo(() => (
    <div className={styles.wrapper} data-testid='AddAssetsDialogContent'>

      <div className={styles.header}>
        <JitText color='lightGray' size='l' text={`pages.teamsPortal.teamPage.table.add.${vendor}.title`} />

        <JitTooltip
          childrenWrapperClassName={styles.infoIcon}
          followCursor={false}
          offset={[0, 8]}
          placement='top'
          title={`pages.teamsPortal.teamPage.table.add.${vendor}.tooltip`}
          withHighlightShadow
        >
          <JitIcon color={colors.white} icon={CircleQuestion} size={15} />
        </JitTooltip>
      </div>

      <div className={styles.autoComplete}>
        <JitAutoCompleteForm
          disableCloseOnSelect
          disabled={isLoadingAssets || assets?.length === 0}
          freeSolo
          getOptionKey={(option) => option.asset_id}
          getOptionLabel={(option) => option.asset_name}
          isLoading={isLoadingAssets}
          options={assets || []}
          placeHolder={assets?.length ? t(`pages.teamsPortal.teamPage.table.add.${vendor}.placeholder`) : t(`pages.teamsPortal.teamPage.table.add.${vendor}.emptyState`)}
          selectedValue={selectedAssets}
          setSelectedValue={handleChange}
        />

        <JitActionButton actionButton={actionButton} />

      </div>
    </div>
  ), [assets, isLoadingAssets, selectedAssets, handleChange, vendor, actionButton]);

  return (
    content
  );
};
