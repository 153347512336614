import { useState, useMemo } from 'react';

import styles from './ValidationBanner.module.scss';

import { Alert, Info } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import { JitInfoBanner } from 'components/JitInfoBanner/JitInfoBanner';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface ValidationBannerProps {
  isValidationLoading: boolean;
  validationError: string | null;
  screenshot?: string; // Base64 encoded image string
}

export const ValidationBanner = ({ isValidationLoading, validationError, screenshot }: ValidationBannerProps) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleOpenFullScreen = () => setIsFullScreen(true);
  const handleCloseFullScreen = () => setIsFullScreen(false);

  const validationBannerContent = useMemo(() => {
    if (isValidationLoading) {
      return (
        <JitInfoBanner
          bgColor={colors.cardContent}
          data-testid='info-banner'
          icon={Info}
          iconColor={colors.blue}
        >
          <JitText data-testid='info-banner-text' size='m' text='pages.plan.configurationsDialog.validating' />
        </JitInfoBanner>
      );
    }

    if (validationError) {
      return (
        <JitInfoBanner
          bgColor={colors.findingStatusOpenBG}
          className={styles.infoBanner}
          data-testid='error-banner'
          icon={Alert}
          iconColor={colors.error}
        >
          <div className={styles.errorBanner}>
            <JitText data-testid='error-banner-text' size='m' text={validationError} />

            <JitText size='m' text='pages.plan.configurationsDialog.recheck' />
          </div>

          <div className={styles.screenshotPlaceholder}>
            {screenshot && (
            <div className={styles.screenshotWrapper}>
              <JitButton className={styles.fullScreenButton} onClick={handleOpenFullScreen}>
                <JitText size='s' text='View Screenshot' />
              </JitButton>

              <img
                alt='Validation error screenshot'
                className={styles.screenshot}
                src={`data:image/png;base64,${screenshot}`}
              />
            </div>
            )}
          </div>
        </JitInfoBanner>
      );
    }

    return null;
  }, [isValidationLoading, validationError, screenshot]);

  return (
    <>
      {validationBannerContent && <div className={styles.bannerWrapper}>{validationBannerContent}</div>}

      {screenshot && (
        <CustomDialog
          content={(
            <div className={styles.dialogImageWrapper}>
              <img
                alt='Full-screen Validation Screenshot'
                className={styles.dialogImage}
                src={`data:image/png;base64,${screenshot}`}
              />
            </div>
          )}
          height='fullScreen'
          isOpen={isFullScreen}
          onClose={handleCloseFullScreen}
          title='Validation Screenshot'
          width='fullScreen'
          withDivider
        />
      )}
    </>
  );
};
