import Box from '@mui/material/Box';
import React from 'react';

import { JitTag } from 'components/JitTag/JitTag';
import colors from 'themes/colors.module.scss';
import { ITableCell } from 'types/interfaces';

const RISK_FACTOR_TAGS = ['exposure', 'has_findings', 'environment'];

export const TagsCell: React.FC<ITableCell> = ({ cell: { value } }) => {
  const renderTags = (item: { name: string; value: string }) => {
    if (!RISK_FACTOR_TAGS.includes(item.name)) {
      return null;
    }

    return (
      <Box data-testid={`tag-${item.name}`} display='inline-block' pr={1}>
        <JitTag color={colors.riskFactorTag} text={item.value} />
      </Box>
    );
  };

  return <div>{value.map(renderTags)}</div>;
};
