import { FC } from 'react';

import styles from './ConfigurationsZapInputFields.module.scss';

import { TextAndLink } from 'components/Configurations/ConfigurationTrigger/components/TextAndLink/TextAndLink';
import { LabelAndTooltip } from 'components/Configurations/Zap/components/LabelAndTooltip';
import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import colors from 'themes/colors.module.scss';
import { Link } from 'types/interfaces/Link/Link';

interface Subtitle {
  link: Link;
  text: string;
}

interface Props {
  isChecked: boolean;
  onChange?: (isChecked: boolean) => void;
  label: string;
  subtitle?: Subtitle;
  tooltipText?: string;
  tooltipCheckBox?: string;
  disabled?: boolean;
  testid?: string;
}
export const ConfigurationsCheckboxField: FC<Props> = ({ isChecked, onChange, label, subtitle, tooltipText, tooltipCheckBox, disabled, testid }) => (
  <div className={styles.checkboxContainer} onClick={() => onChange && onChange(!isChecked)} role='button' tabIndex={0}>
    <JitTooltip data-testid={`${testid}-checkbox-tooltip `} placement='top' title={tooltipCheckBox || ''}>
      <JitCheckbox checked={isChecked} data-testid={`${testid}-checkbox `} disabled={disabled} />
    </JitTooltip>

    <div>
      <LabelAndTooltip label={label} tooltipPlacement='right' tooltipText={tooltipText} withSpaceBetween={false} />

      {subtitle && (
      <TextAndLink
        data-testid={`${testid}-checkbox-subtitle`}
        link={subtitle.link}
        text={{
          text: subtitle.text,
          color: colors.gray,
        }}
      />
      )}
    </div>
  </div>
);
