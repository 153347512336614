import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

import colors from 'themes/colors.module.scss';

export const StyledCircularProgress = styled(CircularProgress)(() => ({
  display: 'flex',
  alignItems: 'center',
  color: colors.statusGreen,
}));
