import { FC } from 'react';

import styles from './AIGeneratedLabel.module.scss';

import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import colors from 'themes/colors.module.scss';

export const AIGeneratedLabel: FC = () => (
  <div className={styles.wrapper}>
    <JitTooltip followCursor={false} offset={[0, 9]} placement='top' title='pages.actions.actionCard.generatedByAITooltipText'>
      <JitText color={colors.yellowAIMark} size='m' text='pages.actions.actionCard.generatedByAIText' />
    </JitTooltip>
  </div>
);
