import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { useWebsocketSubscribe } from '../WebSocketContext/hooks';

import { useHandlePlanItemWebsocketNotification } from './hooks/useHandlePlanItemWebsocketNotification';
import { useHandlePlansWebSocketNotification } from './hooks/useHandlePlansWebSocketNotification';

import { PlansContext } from 'context/PlansContext/PlansContext';
import { usePlanService } from 'services/PlanService/usePlanService';
import { WebSocketNotificationTopics } from 'types/enums';
import { IPlanDetailsMap, PlanItemAggregatedStatus } from 'types/interfaces';

export const PlansProvider: FC<PropsWithChildren> = ({ children }) => {
  const [plans, setPlans] = useState<IPlanDetailsMap>({});
  const [isFetchingPlans, setIsFetchingPlans] = useState(false);
  const [hasFetchedPlans, setHasFetchedPlans] = useState(false);

  const { websocketSubscribe } = useWebsocketSubscribe();
  const { handlePlansWebSocketNotification } = useHandlePlansWebSocketNotification({ setPlans });
  const { handlePlanItemWebSocketNotification } = useHandlePlanItemWebsocketNotification({ setPlans });
  const { getPlans, getPlanDetails } = usePlanService();

  const fetchPlans = useCallback(async () => {
    setIsFetchingPlans(true);
    const response = await getPlans();
    if (response?.data) {
      setHasFetchedPlans(true);
      setPlans((curPlans) => ({
        ...Object.fromEntries(response.data.map((plan) => (
          [plan.slug,
            {
              ...curPlans[plan.slug],
              ...plan,
              allItemSlugs: new Set(plan.all_item_slugs),
              activeItemSlugs: new Set(plan.active_item_slugs),
              passedItemSlugs: new Set(plan.passed_item_slugs),
              failedItemSlugs: new Set(plan.failed_item_slugs),
            }]))),
      }));
    }
    setIsFetchingPlans(false);
  }, [getPlans]);

  const fetchPlanDetails = useCallback(async (slug: string) => {
    const response = await getPlanDetails(slug);
    if (response?.status === 404) {
      return false;
    }
    if (response?.data) {
      setPlans((curPlans) => ({
        ...curPlans,
        ...{
          [slug]:
            {
              ...curPlans[slug],
              ...response.data,
              items: Object.fromEntries(response.data.items?.map((item) => ([item.slug, item])) || []),
              hasFetchedItems: true,
              allItemSlugs: new Set(response.data.items?.map((item) => item.slug)),
              activeItemSlugs: new Set(response.data.items?.filter((item) => item.is_active).map((item) => item.slug)),
              passedItemSlugs: new Set(response.data.items?.filter((item) => item.is_active && item.status === PlanItemAggregatedStatus.SUCCESS)
                .map((item) => item.slug)),
              failedItemSlugs: new Set(response.data.items?.filter((item) => item.is_active && item.status === PlanItemAggregatedStatus.FAILURE)
                .map((item) => item.slug)),
            },
        },
      }));
    }
    return true;
  }, [getPlanDetails]);

  const getPlan = useCallback((slug: string) => plans[slug], [plans]);

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.Plan, handlePlansWebSocketNotification);
  }, [handlePlansWebSocketNotification, websocketSubscribe]);

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.PlanItem, handlePlanItemWebSocketNotification);
  }, [handlePlanItemWebSocketNotification, websocketSubscribe]);

  const value = useMemo(() => ({
    plans,
    setPlans,
    hasFetchedPlans,
    fetchPlans,
    fetchPlanDetails,
    getPlan,
    isFetchingPlans,
  }), [plans, hasFetchedPlans, fetchPlans, fetchPlanDetails, getPlan, isFetchingPlans]);

  return (
    <PlansContext.Provider value={value}>
      {children}
    </PlansContext.Provider>
  );
};
