import Input from '@mui/material/Input';
import { FC } from 'react';

import styles from '../../ConfigurationTrigger.module.scss';

import { Alert, EditPen } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitInfoBanner } from 'components/JitInfoBanner/JitInfoBanner';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  handleApplyToAllClick: () => void;
  isInputDisabled: boolean;
  shouldShowApplyButton: boolean;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputText: string;
  shouldShowEditIcon: boolean;
  handleEditClick: () => void;
}

export const TriggetConfigurationInput: FC<Props> = ({
  handleApplyToAllClick, isInputDisabled, shouldShowApplyButton, handleInputChange,
  inputText, shouldShowEditIcon, handleEditClick,
}) => {
  const applyToAllButton = (
    <JitText
      className={styles.endAdornment}
      color={colors.iris}
      data-testid='applyToAll'
      noWrap
      onClick={handleApplyToAllClick}
      size='l'
      text='pages.plan.configurationDialog.trigger.fields.applyToAll'
    />
  );

  return (
    <div className={styles.scanOnDeploymentConfiguration}>
      <div className={styles.inputWrapper}>

        <Input
          className={styles.inputFieldBox}
          data-testid='input'
          disabled={isInputDisabled}
          disableUnderline
          endAdornment={shouldShowApplyButton && applyToAllButton}
          fullWidth
          onChange={handleInputChange}
          sx={{
            marginRight: '0.5rem',
            padding: '0 5px 0',
            border: `1px solid ${isInputDisabled ? colors.gray : colors.iris}`,
            borderRadius: '4px',
            '.Mui-disabled': {
              WebkitTextFillColor: colors.gray,
            },
          }}
          value={inputText}
        />

        {shouldShowApplyButton && (
          <JitInfoBanner bgColor={colors.findingStatusOpenBG} icon={Alert} iconColor={colors.error}>
            <JitText size='s' text='pages.plan.configurationDialog.trigger.environmentEditDescription' />
          </JitInfoBanner>
        )}
      </div>

      {shouldShowEditIcon && (
        <JitIcon
          centered={false}
          className={styles.editIcon}
          color={isInputDisabled ? colors.iris : colors.gray}
          data-testid='editIcon'
          icon={EditPen}
          onClick={handleEditClick}
        />
      )}
    </div>
  );
};
