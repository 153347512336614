import { FC } from 'react';

import styles from './PriorityFactorsList.module.scss';

import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { PriorityFactorsBox } from 'components/PriorityFactorsBox/PriorityFactorsBox';
import { useCalculateMaxTagsInContainer } from 'utils/hooks/useCalculateMaxTagsInContainer';

interface Props {
  factors: string[];
  maxWidth?: number | string;
}

export const PriorityFactorsList: FC<Props> = ({
  factors,
  maxWidth = '100%',
}) => {
  const {
    containerRef,
    actualWidth,
    tagsToShow: factorsToShow,
    hiddenTags: hiddenFactors,
  } = useCalculateMaxTagsInContainer(factors, maxWidth);

  return (
    <div
      ref={containerRef}
      className={styles.wrapper}
      style={{
        maxWidth,
        width: actualWidth,
      }}
    >
      {factorsToShow.map((factor) => (
        <PriorityFactorsBox
          key={factor}
          factors={[
            {
              key: factor,
              displayName: factor,
            },
          ]}
        />
      ))}

      {hiddenFactors.length > 0 && (
        <JitTooltip
          followCursor={false}
          placement='top'
          title={hiddenFactors.join(', ')}
        >
          <PriorityFactorsBox
            factors={[
              {
                key: 'more',
                displayName: `+${hiddenFactors.length}`,
              },
            ]}
          />
        </JitTooltip>
      )}
    </div>
  );
};
