import { FC, useMemo } from 'react';

import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import { LogDialogContent } from 'pages/PipelinesPage/components/LogDialog';
import { IMouseEvent } from 'types/interfaces';
import { ActionButton, ActionButtonVariant } from 'types/interfaces/ActionButton/ActionButton';

export type ExecutionLogDetails = {
  text: string;
  isTruncated: boolean;
  downloadLink: string;
};

type Props = {
  isOpen: boolean; // Controls if the dialog will be rendered or not
  executionLogDetails?: ExecutionLogDetails;
  isLoading: boolean;
  hasError: boolean;
  onClose?: (e: IMouseEvent) => void;
};

export const LogDialog: FC<Props> = ({
  isOpen,
  onClose,
  executionLogDetails,
  isLoading,
  hasError,
}) => {
  const dialogContentChildren = useMemo(() => (
    <LogDialogContent
      executionLogDetails={executionLogDetails}
      hasError={hasError}
      isLoading={isLoading}
    />
  ), [executionLogDetails, isLoading, hasError]);

  const actionButtons: ActionButton[] = [
    {
      variant: ActionButtonVariant.SECONDARY,
      label: 'dialogs.executionLog.buttonClose',
      handleClick: (e: IMouseEvent) => onClose && onClose(e),
    },
  ];
  if (executionLogDetails?.text) { // We want to show the Download button only if there is a log to download
    actionButtons.push(
      {
        variant: ActionButtonVariant.PRIMARY,
        label: 'dialogs.executionLog.downloadLog',
        handleClick: () => window.open(executionLogDetails.downloadLink, '_blank'),
      },
    );
  }

  return (
    <CustomDialog
      actionButtons={isLoading ? undefined : actionButtons}
      content={dialogContentChildren}
      dataTestId='log-dialog'
      isOpen={isOpen}
      onClose={(e) => onClose && onClose(e as IMouseEvent)}
      title='dialogs.executionLog.title'
      width='fullScreen'
      withDivider
    />
  );
};
