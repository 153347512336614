import { FronteggProvider } from '@frontegg/react';
import { FC, ReactElement, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { authOptions, localizations } from './constants';
import { useSetPathBasedItemsIntoSessionStorage } from './utils/useSetPathBasedItemsIntoSessionStorage/useSetPathBasedItemsIntoSessionStorage';

import { config } from 'config';
import { AuthProvider } from 'context/AuthContext/AuthProvider';
import { fronteggLoginThemeOptions } from 'pages/LoginPage/LoginPageFrontegg';

interface Props {
  children: ReactElement;
}

export const AuthProviderWrapper: FC<Props> = ({ children }) => {
  const [isFrontEggLoading, setIsFrontEggLoading] = useState(false);
  const { setPathBasedItemsIntoSessionStorage } = useSetPathBasedItemsIntoSessionStorage();

  useEffect(() => {
    setPathBasedItemsIntoSessionStorage();
  }, [setPathBasedItemsIntoSessionStorage]);

  if (config.isTest) {
    return (
      <BrowserRouter>
        {children}
      </BrowserRouter>
    );
  }

  return (
    <FronteggProvider
      authOptions={authOptions}
      contextOptions={{ baseUrl: config.frontEggBaseUrl }}
      customLoader={setIsFrontEggLoading}
      entitlementsOptions={{ enabled: true }}
      localizations={localizations}
      themeOptions={fronteggLoginThemeOptions}
    >
      <AuthProvider isFrontEggLoading={isFrontEggLoading}>
        {children}
      </AuthProvider>
    </FronteggProvider>
  );
};
