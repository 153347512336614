import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './ResourcesDashboardTitle.module.scss';

import { JitText } from 'components/JitText/JitText';
import { constants } from 'globalConstants';
import colors from 'themes/colors.module.scss';

interface Props {
  resourcesCount: number;
}

export const ResourcesDashboardTitle: FC<Props> = ({ resourcesCount }) => {
  const navigate = useNavigate();
  const { BASE_ROUTE, RESOURCES, RESOURCES_LIST } = constants.routes.risks;

  const onClick = () => {
    navigate(`/${BASE_ROUTE}/${RESOURCES}/${RESOURCES_LIST}`);
  };

  return (
    <div className={styles.titleWrapper}>
      <JitText data-testid='PageTitles-title' size='xxxxl' text='pages.risks.resources.resourcesDashboard.title' />

      <JitText
        color={colors.lightBlue}
        data-testid='resources-count'
        onClick={onClick}
        params={{ resourcesCount }}
        size='l'
        text='pages.risks.resources.resourcesDashboard.resourcesCountTitle'
      />
    </div>
  );
};
