import { PERMISSIONS } from '../constants';

import { constants } from 'globalConstants';

export enum UiLayoutMode {
  ManagementPlatform = 'ManagementPlatform',
  TeamPortal = 'TeamPortal',
}

interface GetUiLayoutModeParams {
  currentRoute: string;
  userPermissions: string[];
}

const REQUIRED_PERMISSIONS = {
  [UiLayoutMode.TeamPortal]: PERMISSIONS.TEAMS_PORTAL,
  [UiLayoutMode.ManagementPlatform]: PERMISSIONS.MANAGEMENT_PLATFORM,
};

const URL_PREFIXES = {
  [UiLayoutMode.TeamPortal]: `/${constants.routes.teamsPortal.BASE_ROUTE}`,
};

export const getUserPermittedLayouts = (userPermissions: string[]): UiLayoutMode[] => Object.entries(REQUIRED_PERMISSIONS)
  .filter(([, permission]) => userPermissions.includes(permission))
  .map(([layout]) => layout as UiLayoutMode);

const isValidLayoutMode = (layout: UiLayoutMode): layout is keyof typeof URL_PREFIXES => layout in URL_PREFIXES;

const findLayoutModeFromUrl = (currentRoute: string, layout: UiLayoutMode): boolean => {
  if (isValidLayoutMode(layout)) {
    const prefix = URL_PREFIXES[layout];
    return prefix ? currentRoute.startsWith(prefix) : false;
  }
  return false;
};

const getLayoutModeFromUrl = (currentRoute: string, permittedLayouts: UiLayoutMode[]): UiLayoutMode | null => {
  const matchedLayout = permittedLayouts.find((layout) => findLayoutModeFromUrl(currentRoute, layout));
  return matchedLayout || null;
};

// Return one of the permitted layouts, but default to the ManagementPlatform layout if it's one of them.
const getDefaultLayoutMode = (permittedLayouts: UiLayoutMode[]): UiLayoutMode => (permittedLayouts.includes(UiLayoutMode.ManagementPlatform)
  ? UiLayoutMode.ManagementPlatform
  : permittedLayouts[0] || UiLayoutMode.ManagementPlatform);

export const getUiLayoutMode = ({ currentRoute, userPermissions }: GetUiLayoutModeParams): UiLayoutMode => {
  const permittedLayouts = getUserPermittedLayouts(userPermissions);
  // If the user has only one layout permission, return it
  if (permittedLayouts.length === 1) {
    return permittedLayouts[0];
  }

  // If the user has multiple layout permissions, check the URL to determine the layout
  const urlBasedLayoutMode = getLayoutModeFromUrl(currentRoute, permittedLayouts);

  return urlBasedLayoutMode || getDefaultLayoutMode(permittedLayouts);
};
