import { FC, ReactNode } from 'react';

import { SecurityImpactMetricCard } from '../../../SecurityImpactWrapper/SecurityImpactWrapper';
import { NoCoverage } from '../NoCoverage/NoCoverage';

import styles from './CoverageExpandedCard.module.scss';

interface Props {
  actionItemOnClick: () => void;
  actionItemText: string;
  actionItemTitle: string;
  content: ReactNode;
  hasCoverage: boolean;
  title: string;
}

export const CoverageExpandedCard: FC<Props> = ({ actionItemOnClick, actionItemText, actionItemTitle, content, hasCoverage, title }) => (
  <div className={styles.cardWrapper}>
    <SecurityImpactMetricCard
      actionItemOnClick={hasCoverage ? actionItemOnClick : undefined}
      actionItemText={hasCoverage ? actionItemText : undefined}
      content={(
        <div>
          {content}

          {!hasCoverage && <NoCoverage onClick={actionItemOnClick} text={actionItemText} title={actionItemTitle} />}
        </div>
)}
      increasedGap
      mainTitle={title}
    />
  </div>
);
