export const initialPlan = {
  owners: {
    default: null,
  },
  name: 'My plan',
  author: 'Jit',
  items: [],
  level: 'beginner',
  version: 0.1,
  references: [
    'https://www.jit.io',
  ],
  tags: [],
};
