import { Dispatch, SetStateAction } from 'react';

import { usePipelineService } from 'services/PipelinesService/usePipelineService';
import { IPipelineNew } from 'types/interfaces';

interface Props {
  setDeployments: Dispatch<SetStateAction<IPipelineNew[] | undefined>>
  environment: string
}

export const useHandleDeploymentsUpdatesWebSocketNotification = ({ setDeployments, environment }: Props) => {
  const { getDeploymentsFromCreatedAfter } = usePipelineService();
  const handleDeploymentsUpdatesWebSocketNotification = async () => {
    const deployments = await getDeploymentsFromCreatedAfter(environment);

    setDeployments(deployments);
  };

  return { handleDeploymentsUpdatesWebSocketNotification };
};
