/* eslint-disable react/jsx-max-depth */
import DialogContent from '@mui/material/DialogContent';
import Input from '@mui/material/Input';
import { FC } from 'react';

import styles from './integrationFieldsBody.module.scss';

import { CircleInfo } from 'assets';
import { JitDropdown } from 'components/JitDropdown/JitDropdown';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { JitDropdownVariant } from 'types/enums';
import { IChangeInputEvent, IDictionary } from 'types/interfaces';

interface Props {
  handleChange: (e: IChangeInputEvent) => void,
  integrationFields: {
    account_id: string;
    account_name: string;
    deploy_region: string;
    regions: string[];
    type?: string;
  },
  vendor: string,
  handleSelectChange: (regions: string[]) => void,
  handleSelectOnToggle?: (isOpen: boolean) => void,
  handleStackRegionSelectChange: (deploy_region: string) => void,
  handleNextClick: () => void,
}

const inputDefaultProps = {
  disableUnderline: true,
  fullWidth: true,
  required: true,
  sx: {
    color: 'gray',
    border: `2px solid ${colors.darkGray}`,
    paddingRight: '0.5rem',
    paddingLeft: '0.5rem',
    fontSize: '14px',
    borderRadius: '0.5rem',
  },
};

export const IntegrationFieldsBody: FC<Props> = ({
  vendor, integrationFields, handleSelectChange, handleSelectOnToggle,
}) => {
  const vendorFields: IDictionary<IDictionary<string>> = i18n.t(`dialogs.integration.${vendor}.fields`, { returnObjects: true });

  return (
    <DialogContent sx={{
      p: '6px 0',
      overflow: 'auto',
    }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginRight: '1.5rem',
          marginLeft: '1.5rem',
        }}
      >

        <div style={{ paddingBottom: '1rem' }}>
          <JitTooltip
            childrenWrapperClassName={styles.tooltipChildren}
            followCursor={false}
            placement='top'
            title={vendorFields.regions.tooltip}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '0.25rem 0',

              }}
            >

              <JitText style={{ margin: '0 0.25rem' }} text={vendorFields.regions.label} />

              <JitIcon data-testid='tooltipCircleIcon' icon={CircleInfo} iconClassName={styles.infoTooltipIcon} size={15} />
            </div>
          </JitTooltip>

          <JitDropdown
            checkedFilters={integrationFields.regions}
            filterOptions={vendorFields.regions.options as any} // eslint-disable-line @typescript-eslint/no-explicit-any
            formControlProps={{
              style: {
                width: '100%',
              },
            }}
            maxHeight={200}
            onClose={handleSelectOnToggle ? () => handleSelectOnToggle(false) : undefined}
            onOpen={handleSelectOnToggle ? () => handleSelectOnToggle(true) : undefined}
            placeholder={vendorFields.regions.placeholder}
            selectProps={{
              input: (
                <Input {...inputDefaultProps} />
              ),
            }}
            setCheckedFilters={handleSelectChange}
            variant={JitDropdownVariant.checkbox}
          />
        </div>
      </div>
    </DialogContent>
  );
};
