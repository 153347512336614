import { FC } from 'react';

import styles from './AddRemovePopover.module.scss';

import { JitText } from 'components/JitText/JitText';
import { useGraphContext } from 'context/GraphContext/GraphContext';
import { FactorsRow } from 'pages/FindingsPage/components/FindingDetails/components/Priority/children/AddRemoveFactor/FactorsRow';
import { GraphEntityType } from 'types/enums/ContextGraphEntityType';
import { IPriorityFactor } from 'types/interfaces/Graph/IPriorityFactor';

interface Props {
  toggleFactor: (factor: string) => void;
  selectedFactors: string[];
  scope: GraphEntityType;
}

export const PopoverEditMode: FC<Props> = ({ toggleFactor, selectedFactors, scope }) => {
  const { priorityFactorsGroups } = useGraphContext();
  const currentScopeGroups = priorityFactorsGroups.filter((group) => group.scopes.includes(scope));
  const isFactorSelected = (factor: IPriorityFactor) => selectedFactors.includes(factor.key);
  return (
    <div className={styles.editGroupsList}>
      {currentScopeGroups.map((group) => (
        <div key={group.displayName} className={styles.factorGroupAndTitle}>
          <JitText key={group.displayName} muted size='s' text={group.displayName} />

          {group.multiSelect ? (group.factors.map((factor) => (
            <FactorsRow
              key={factor.key}
              editable={group.isEditable}
              factors={[factor]}
              selectedFactor={isFactorSelected(factor) ? factor.key : undefined}
              showQuestionDescription
              toggleFactor={toggleFactor}
            />
          ))) : (
            <FactorsRow
              key={group.displayName}
              editable={group.isEditable}
              factors={group.factors}
              selectedFactor={group.factors.find((factor) => isFactorSelected(factor))?.key || undefined}
              showQuestionDescription
              toggleFactor={toggleFactor}
            />
          )}
        </div>
      ))}
    </div>
  );
};
