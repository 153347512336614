import { FC } from 'react';

import styles from './SecurityImpactCard.module.scss';

import { GraphEmpty } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/components';

export const SecurityImpactCardEmpty: FC = () => (
  <div className={styles.emptyWrapper} data-testid='securityImpactCardEmpty'>
    <div>
      <GraphEmpty />
    </div>
  </div>
);
