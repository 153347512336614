/* eslint-disable react/no-multi-comp */
import { FC, PropsWithChildren, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './BaseCard.module.scss';

import { JitText } from 'components/JitText/JitText';

interface Props {
  title: string;
  link?: string;
}

export const BaseCard: FC<PropsWithChildren<Props>> = ({ title, link, children }) => {
  const navigate = useNavigate();

  const additionalProps = useMemo(() => {
    if (link) {
      return {
        onClick: () => navigate(link),
        style: { cursor: 'pointer' },
        role: 'button',
        tabIndex: 0,
      };
    }
    return {};
  }, [link, navigate]);

  return (
    <div className={styles.wrapper} {...additionalProps}>
      <JitText align='center' muted text={title} />

      {children}
    </div>
  );
};
