/* eslint-disable react/no-unstable-nested-components */
import { FC, useMemo, useState } from 'react';
import { Line, XAxis, ResponsiveContainer, ReferenceArea, YAxis, ReferenceLine, Tooltip, LineChart } from 'recharts';

import { TooltipFormatter } from './components/TooltipFormatter/TooltipFormatter';
import { useGetGraphData } from './hooks/useGetGraphData';

import { CustomDot } from 'components/graphs/components/CustomDot/CustomDot';
import { IScoreTrendingEntity } from 'components/ScoreTrendingWidget/hooks/useGetScoreTrendingByWeek';
import { commonTooltipProps } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/constants';
import colors from 'themes/colors.module.scss';

interface Props {
  scoreTrendingEntity: IScoreTrendingEntity
  threshold?: number;
  mode?: 'full' | 'compact';
}

const MAX_Y_AXIS = 130;

const stylesByMode = {
  full: {
    height: '100%',
    width: '99%',
    margin: { bottom: -5 },
  },
  compact: {
    height: '80%',
    width: '99%',
    margin: { top: 10 },
  },
};

const OFFSET = 7;

export const ScoreTrendingGraph: FC<Props> = ({ scoreTrendingEntity, threshold = 80, mode = 'full' }) => {
  const [hoveredWeek, setHoveredWeek] = useState<string | null>(null);
  const LINEAR_GRADIENT_ID = `colorUv-${scoreTrendingEntity.entityId}`;

  const { daysData, weekAreas, sameScoreColor, gradientColors } = useGetGraphData(scoreTrendingEntity);
  const numberOfNotNullScores = daysData.filter((day) => day.value !== null).length;

  // @ts-ignore - currently recharts does not export the correct type
  const handleActiveDot = (activeDot) => {
    if (!activeDot) return;
    if (activeDot.payload.weeklyAvg === null) {
      setHoveredWeek(null);
      return;
    }
    setHoveredWeek(activeDot.payload.week);
  };
  const hoveredWeekAvgScore = useMemo(() => weekAreas.find((weekArea) => weekArea.key === hoveredWeek)?.avgScore, [hoveredWeek, weekAreas]);
  // set different tooltip position and offset for hovered week that has average score
  const tooltipPosition = hoveredWeekAvgScore !== undefined ? { y: -100 } : { y: -20 };
  const tooltipOffset = hoveredWeekAvgScore !== undefined ? -70 : -50;

  const strokeColor = sameScoreColor || `url(#${LINEAR_GRADIENT_ID})`;

  return (
    <ResponsiveContainer height={stylesByMode[mode].height} width={stylesByMode[mode].width}>
      <LineChart
        data={daysData}
        margin={stylesByMode[mode].margin}
        onMouseLeave={() => setHoveredWeek(null)}
      >
        <XAxis
          axisLine={false}
          dataKey='week'
          dx={20}
          hide={mode === 'compact'}
          interval={6}
          minTickGap={0}
          onMouseEnter={() => setHoveredWeek(null)}
          tickLine={false}
        />

        {mode === 'full' && (
          <Tooltip
            {...commonTooltipProps}
            content={<TooltipFormatter />}
            filterNull={false}
            offset={tooltipOffset}
            position={tooltipPosition}
          />
        )}

        <YAxis domain={[-5, MAX_Y_AXIS]} hide />

        {weekAreas.map((weekArea) => (
        // background areas for each week
          <ReferenceArea
            key={weekArea.key}
            cursor={mode === 'full' ? 'pointer' : 'default'}
            fill={colors.scoreTrendingReferenceAreaColor}
            fillOpacity={(hoveredWeek === null || hoveredWeek === weekArea.key) && weekArea.avgScore !== null ? 1 : 0.2}
            x1={weekArea.x1}
            x2={weekArea.x2}
            y1={0}
            y2={MAX_Y_AXIS}
          />
        ))}

        {mode === 'full' && <ReferenceLine stroke={colors.iconBlue} strokeDasharray='6' y={threshold} />}

        <defs>
          <linearGradient id={LINEAR_GRADIENT_ID} x1='0' x2='0' y1='0' y2='1'>
            {gradientColors.map((stopColor) => (
            // gradient colors painted on the line
              <stop key={stopColor.offset} offset={stopColor.offset} stopColor={stopColor.color} />
            ))}
          </linearGradient>
        </defs>

        <Line
        // @ts-ignore - currently recharts does not export the correct type
          activeDot={handleActiveDot}
          connectNulls
          cursor={mode === 'full' ? 'pointer' : 'default'}
          dataKey='value'
          dot={numberOfNotNullScores === 1 ? (props) => <CustomDot {...props} offset={OFFSET} /> : false}
          // this is workaround for recharts bug, when there is only one score, the line is not displayed
          isAnimationActive={numberOfNotNullScores > 1}
          stroke={numberOfNotNullScores > 1 ? strokeColor : 'none'}
          strokeWidth={3}
          type='basis'
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
