import { camelCase } from 'lodash';
import { FC } from 'react';

import {
  SlackChannelNotificationsItem,
} from 'components/JitDialogs/SlackIntegrationDialog/components/SlackChannelNotificationsItem/SlackChannelNotificationsItem';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { useGetSlackChannels } from 'context/SlackContext/hooks/useGetSlackChannels';
import { useGetTeamPreferences } from 'context/TeamsContext/hooks/useGetTeamPreferences';
import { useTeamsService } from 'services/TeamsService/useTeamsService';
import { SpecificTeamsNotificationPreference } from 'types/interfaces';
import { TeamNotificationPreference } from 'types/interfaces/Teams/ITeamPreferences';

export const NOTIFICATIONS_PREFERENCES: Record<string, keyof SpecificTeamsNotificationPreference> = {
  score_decrease: 'score_decrease',
};

interface Props {
  teamName: string;
  teamId: string;
}

export const TeamSlackNotifications: FC<Props> = ({ teamName, teamId }) => {
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const { slackChannels } = useGetSlackChannels();
  const { patchPreferences: patchTeamPreferences } = useTeamsService();
  const { teamPreferences, setTeamPreferences } = useGetTeamPreferences(teamId, teamName, 'notifications');

  const updateTeamNotificationPreferences = async (preferenceKey: string, enabled: boolean, channel?: string) => {
    const notificationsUpdate: TeamNotificationPreference = {
      [preferenceKey]: {
        enabled,
        channel,
      },
    };

    const response = await patchTeamPreferences(teamId, teamName, {
      notifications: notificationsUpdate,
    });

    if (response) {
      setTeamPreferences(response);
    }

    sendAnalyticsEvent({
      action: 'slack-notification-team-preference-updated',
      params: {
        'preference-key': preferenceKey,
        teamName,
        enabled: (enabled || false).toString(),
        channel: (channel || ''),
      },
    });
  };

  return (
    <div data-testid='slackChannelSection'>
      {Object.values(NOTIFICATIONS_PREFERENCES).map((preferenceKey) => (
        <SlackChannelNotificationsItem
          key={preferenceKey}
          preferenceKey={preferenceKey}
          selected={teamPreferences?.notifications?.[camelCase(preferenceKey)]}
          slackChannels={slackChannels || []}
          updateNotificationPreferences={updateTeamNotificationPreferences}
        />
      ))}
    </div>
  );
};

