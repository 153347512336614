import { ButtonProps } from '@mui/material/Button';
import { forwardRef, MouseEvent, RefCallback, RefObject } from 'react';

import { JitButtonStyles } from './JitButton.styles';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { JitReadOnlyTooltip } from 'components/JitTooltip/JitReadOnlyTooltip/JitReadOnlyTooltip';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { collectParentTestIds } from 'context/AnalyticsContext/utils/pageUtils';
import { IAnalyticsEvent } from 'types/interfaces';

export type JitButtonVariants = 'text' | 'contained' | 'outlined' | 'danger' | 'jitContained';

export interface Props extends Omit<ButtonProps, 'variant'> {
  analytics?: IAnalyticsEvent;
  noHover?: boolean;
  width?: number | string;
  height?: number | string;
  variant?: JitButtonVariants;
  isLoading?: boolean;
  isReadOnly?: boolean;
  target?: string;
  showChildrenWhileLoading?: boolean;
}

export const JitButton = forwardRef((
  {
    children,
    onClick,
    analytics,
    noHover,
    variant,
    width,
    height,
    isLoading,
    isReadOnly,
    showChildrenWhileLoading,
    ...props
  }: Props,
  ref?: RefCallback<HTMLButtonElement> | RefObject<HTMLButtonElement> | null,
) => {
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (isLoading) return;

    const buttonAttributes: IAnalyticsEvent['params'] = {
      text: e.currentTarget.textContent || '',
      'data-testid': e.currentTarget.getAttribute('data-testid') || '',
      parentsPaths: collectParentTestIds(e.currentTarget),
    };

    const analyticAction = analytics?.action || e.currentTarget.getAttribute('data-testid') || 'clicked-button';

    const analyticData: IAnalyticsEvent = {
      action: analyticAction,
      params: buttonAttributes,
    };
    sendAnalyticsEvent(analyticData);
    onClick?.(e);
  };

  const modifiedSx = props.sx;

  const loadingSpinnerVariant = variant === 'contained' || variant === 'danger' ? 'simple' : 'colorful';

  const button = (
    <JitButtonStyles
      ref={ref}
      height={height}
      nohover={noHover ? 'true' : undefined}
      onClick={handleClick}
      // @ts-ignore  TODO: fix the TS interface that it'll recognize our custom variants type (or remove the usage of MUI here)*
      variant={variant}
      width={width}
      {...props}
      disabled={props.disabled || isReadOnly}
      sx={modifiedSx}
    >
      {isLoading ? (
        <>
          {showChildrenWhileLoading && children}

          <div style={{
            margin: showChildrenWhileLoading ? '0px 3px' : 0,
          }}
          >
            <CircularLoadingSpinner size={showChildrenWhileLoading ? 'small' : 'medium'} variant={loadingSpinnerVariant} />
          </div>
        </>

      ) : children}
    </JitButtonStyles>
  );

  return (isReadOnly ? (
    <JitReadOnlyTooltip>
      {button}
    </JitReadOnlyTooltip>
  ) : (
    button
  ));
});
