import * as Sentry from '@sentry/react';

enum LogLevel {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

const log = (message: string, extra: Record<string, unknown>, level: LogLevel) => {
  Sentry.captureMessage(message, {
    level,
    extra,
  });
};

export const logInfo = (message: string, extra: Record<string, unknown> = {}) => {
  log(message, extra, LogLevel.INFO);
};

export const logWarning = (message: string, extra: Record<string, unknown> = {}) => {
  log(message, extra, LogLevel.WARNING);
};

export const logError = (message: string, extra: Record<string, unknown> = {}) => {
  log(message, extra, LogLevel.ERROR);
};
