import { constants } from 'globalConstants';

export const getTimezoneUTCFormat = (date: string) => {
  const formattedDate = new Date(`${date}${!date.endsWith('Z') ? 'Z' : ''}`);

  return formattedDate;
};

const hour = 60 * 60;
const day = hour * 24;
const week = day * 7;
const month = day * 30;
const year = month * 12;

const getFormattedTimeString = (seconds: number) => {
  if (seconds / year > 1) {
    return `${Math.floor(seconds / year)}y`;
  }
  if (seconds / month > 1) {
    return `${Math.floor(seconds / month)}mo`;
  }
  if (seconds / week > 1) {
    return `${Math.floor(seconds / week)}w`;
  }
  if (seconds / day > 1) {
    return `${Math.floor(seconds / day)}d`;
  }
  if (seconds / hour > 1) {
    return `${Math.floor(seconds / hour)}h`;
  }
  if (seconds / 60 > 1) {
    return `${Math.floor(seconds / 60)}m`;
  }

  return `${seconds}s`;
};

export const getFormattedTimeSince = (dateString?: string) => {
  if (!dateString) return '';

  const date = getTimezoneUTCFormat(dateString);

  if (Number.isNaN(date) || date.toString() === constants.INVALID_DATE) {
    return '';
  }

  const now = new Date();
  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  return `${getFormattedTimeString(seconds)} ago`;
};

export const getFormattedDuration = (timeInSeconds?: string) => {
  if (!timeInSeconds) return '';

  const seconds = parseInt(timeInSeconds, 10);

  return seconds ? getFormattedTimeString(seconds) : '';
};

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const isoFormatToDateFormat = (timeInIsoFormat?: string) => {
  if (!timeInIsoFormat) return '';

  const date = getTimezoneUTCFormat(timeInIsoFormat);

  if (Number.isNaN(date.getTime()) || date.toString() === constants.INVALID_DATE) {
    return '';
  }

  const Year = date.getFullYear();
  const Month = monthNames[date.getMonth()];
  const Day = date.getDate();
  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${Month} ${Day}, ${Year} at ${hours}:${minutes}`;
};
