import { FC } from 'react';

import styles from './CoverageDescription.module.scss';

import { JitText } from 'components/JitText/JitText';
import { getPercentageColor } from 'components/PercentageLineProgress/utils';
import colors from 'themes/colors.module.scss';

interface Props {
  coveragePercentage: number;
  activeItemsPercentage: number;
}

export const CoverageDescription: FC<Props> = ({ coveragePercentage, activeItemsPercentage }) => (
  <div className={styles.wrapper} data-testid='coverage-description'>
    <JitText
      color={colors.lightGray}
      components={{
        activeItemsPercentage: <JitText
          bold
          color={getPercentageColor(activeItemsPercentage)}
          data-testid='coverage-description-percentage'
          display='inline'
          size='xxxl'
          text={`${activeItemsPercentage}%`}
        />,
        coveragePercentage: <JitText
          bold
          color={getPercentageColor(coveragePercentage)}
          display='inline'
          size='xxxl'
          text={`${coveragePercentage}%`}
        />,
      }}
      size='xxl'
      text='pages.securityImpact.coverage.description'
    />
  </div>
);
