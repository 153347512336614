import { FC } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';

import { GraphEmpty, GraphLoading } from '../components';
import {
  commonGridProps,
  commonLineChartProps,
  commonLineProps,
  commonTooltipProps,
  commonXAxisProps,
  commonYAxisProps,
  xAxisDxToPeriod,
} from '../constants';
import styles from '../Graphs.module.scss';

import { DAYS_WITH_DATA_GRAPH_NAME, DAYS_WITHOUT_DATA_GRAPH_NAME } from './constants';
import { GraphHeader } from './GraphHeader/GraphHeader';
import { TooltipFormatter } from './TooltipFormatter';

import { getGraphXAxisDomain } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/utils';
import colors from 'themes/colors.module.scss';
import { TimeAgo } from 'types/enums';
import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { IDetectionRateDay, IDetectionRateMetric, IEmptyDetectionRateDay } from 'types/interfaces/Metrics/PerformanceMetrics/DetectionRate';

interface Props {
  detectionRateMetric: MetricWithLoadingIndication<IDetectionRateMetric>;
  xAxisTicks: number[];
  period: TimeAgo;
}

export const DetectionRateGraph: FC<Props> = ({ detectionRateMetric, xAxisTicks, period }) => {
  const days = detectionRateMetric.metric?.days ?? [];
  const isEmptyDay = (day: IDetectionRateDay | IEmptyDetectionRateDay) => !('detectionRate' in day);
  const daysWithData = (days.filter((day) => !isEmptyDay(day)) ?? []) as IDetectionRateDay[];
  const yAxisTicksFormatter = (detectionRate: number) => (detectionRate === 0 ? '0' : `${detectionRate}%`);

  const getEmptyLineDataKey = (day: IDetectionRateDay | IEmptyDetectionRateDay) => {
    if (isEmptyDay(day)) return 0;

    const dayIndex = days.indexOf(day);
    const prevDay = days[dayIndex - 1];
    const nextDay = days[dayIndex + 1];
    if ((prevDay && isEmptyDay(prevDay)) || (nextDay && isEmptyDay(nextDay))) return (day as IDetectionRateDay).detectionRate;

    return null;
  };

  const maxValue = Math.max(...daysWithData.map((day) => Math.round(day.detectionRate)));
  const formattedMaxValue = yAxisTicksFormatter(maxValue);

  const graph = (
    <div data-testid='graphBody'>
      <LineChart {...commonLineChartProps} data={days}>
        <CartesianGrid {...commonGridProps} />

        <Line {...commonLineProps} dataKey={getEmptyLineDataKey} name={DAYS_WITHOUT_DATA_GRAPH_NAME} stroke={colors.detectionRateLineColor} />

        <Line {...commonLineProps} dataKey='detectionRate' name={DAYS_WITH_DATA_GRAPH_NAME} stroke={colors.detectionRateLineColor} />

        <YAxis {...commonYAxisProps(formattedMaxValue.length)} tickFormatter={yAxisTicksFormatter} />

        <XAxis {...commonXAxisProps} domain={getGraphXAxisDomain(xAxisTicks)} dx={xAxisDxToPeriod[period]} ticks={xAxisTicks} />

        <Tooltip {...commonTooltipProps} content={TooltipFormatter} />
      </LineChart>
    </div>
  );

  const getContent = () => {
    if (detectionRateMetric.isLoading) {
      return <GraphLoading />;
    }

    if (daysWithData.length > 0) {
      return graph;
    }

    return (
      <GraphEmpty />
    );
  };

  return (
    <div className={styles.metricWrapper} data-testid='detectionRateGraph'>
      <GraphHeader
        avgValue={detectionRateMetric.metric?.avgDetectionRate}
        changeRate={detectionRateMetric.metric?.changeRate}
        description='pages.performance.graphs.detectionRate.description'
        isLoading={detectionRateMetric.isLoading}
        performanceRateOptions={detectionRateMetric.metric?.performanceRateOptions}
        popperDescription='pages.performance.graphs.detectionRate.widget.description'
        popperTitle='pages.performance.graphs.detectionRate.widget.title'
        positiveTrendIndicatorDirection={detectionRateMetric.metric?.positiveTrendIndicatorDirection}
        rank={detectionRateMetric.metric?.rank}
        title='pages.performance.graphs.detectionRate.title'
      />

      {getContent()}
    </div>
  );
};
