import { FC, useCallback } from 'react';

import styles from './RowActions.module.scss';

import { CreateTicketButton } from 'components/CreateTicketButton/CreateTicketButton';
import { useFindingTableContext } from 'components/FindingsTable/FindingsTableContext/FindingsTableContext';
import { JitCopyToClipboardBox } from 'components/JitCopyToClipboardBox/JitCopyToClipboardBox';
import { ElementToShowOnHoverProps } from 'components/JitTable/components';
import { HandleFixSuggestionButton } from 'pages/FindingsPage/components/HandleFixSuggestionButton/HandleFixSuggestionButton';
import { ToggleIgnoreFinding } from 'pages/FindingsPage/components/ToggleIgnoreFinding/ToggleIgnoreFinding';
import { generateMarkdown } from 'pages/FindingsPage/utils/generateMarkdown';
import { IFinding, TicketFinding } from 'types/interfaces/Findings/IFinding';

export const RowActions: FC<ElementToShowOnHoverProps> = ({ row }) => {
  const finding = row.original as IFinding;
  const { onSetIgnoredToSingleRow, setTicketUrlOnFindings } = useFindingTableContext();
  const onSetIsIgnored = useCallback((ignored: boolean) => {
    onSetIgnoredToSingleRow(finding, ignored);
  }, [finding, onSetIgnoredToSingleRow]);

  const handleCreateTicket = useCallback((ticket?: TicketFinding) => {
    if (ticket) {
      setTicketUrlOnFindings([finding], ticket);
    }
  }, [finding, setTicketUrlOnFindings]);

  return (
    <div className={styles.root} data-testid='row-actions'>
      <ToggleIgnoreFinding
        fullSize={false}
        isIgnored={finding.ignored}
        onSetIsIgnored={onSetIsIgnored}
      />

      <CreateTicketButton findings={[finding]} fullSize={false} onClose={handleCreateTicket} />

      <HandleFixSuggestionButton finding={finding} fullSize={false} />

      <JitCopyToClipboardBox displayText='' fullSize={false} text={generateMarkdown([finding])} />
    </div>
  );
};
