import { FC, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import styles from './RiskPagesDropdown.module.scss';

import { ExpandArrow } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitMenu } from 'components/JitMenu/JitMenu';
import { JitText } from 'components/JitText/JitText';
import {
  DASHBOARD_ROUTE,
  BACKLOG_ROUTE,
  RESOURCES_ROUTE_BASE,
} from 'pages/Resources/components/RisksBreadcrumbs/RisksBreadcrumbs';
import colors from 'themes/colors.module.scss';

interface Props {
  useDashboardPage: boolean;
}

export const RiskPagesDropdown: FC<Props> = ({ useDashboardPage }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isInBacklogPage = pathname.includes(BACKLOG_ROUTE);
  const menuItems = useMemo(() => [
    useDashboardPage ? {
      itemKey: DASHBOARD_ROUTE,
      displayText: 'pages.risks.breadcrumbs.resourcesDashboard',
      itemContent: <JitText text='pages.risks.breadcrumbs.resourcesDashboard' />,
      isSelected: !isInBacklogPage,
    } : {
      itemKey: RESOURCES_ROUTE_BASE,
      displayText: 'pages.risks.breadcrumbs.resources',
      itemContent: <JitText text='pages.risks.breadcrumbs.resources' />,
      isSelected: !isInBacklogPage,
    },
    {
      itemKey: BACKLOG_ROUTE,
      displayText: 'pages.risks.breadcrumbs.backlog',
      itemContent: <JitText text='pages.risks.breadcrumbs.backlog' />,
      isSelected: isInBacklogPage,
    },
  ], [isInBacklogPage, useDashboardPage]);

  const selectedItem = menuItems.find((item) => item.isSelected);

  return (
    <JitMenu
      menuItems={menuItems}
      menuSelectedDesign='fillRow'
      onMenuItemClick={(item) => navigate(item.itemKey as string)}
      paperProps={{ style: { width: 180 } }}
      shouldCloseOnItemClick
    >
      <div className={styles.menuDisplay}>
        <JitText
          bold
          color={colors.lightBlue}
          size='l'
          text={selectedItem?.displayText}
        />

        <JitIcon color={colors.lightBlue} icon={ExpandArrow} size={20} />
      </div>

    </JitMenu>
  );
};
