import _ from 'lodash';
import { ReactElement } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { InputOption, RuleTemplateInput, IRuleTemplate, CallbackKey, RuleSettings, Condition, DataType } from './types';

import { JitAutoCompleteForm } from 'components/JitAutoCompleteForm/components/JitAutocompleteForm';
import { JitText } from 'components/JitText/JitText';
import { JitTextInput } from 'components/JitTextInput/JitTextInput';
import { policyRuleOptionsCallbackMap } from 'pages/PoliciesPage/constants';

type GetterParams = {
  inputTemplate?: RuleTemplateInput;
  inputValue?: DataType;
};

const findOption = ({ inputTemplate, inputValue }: GetterParams) => inputValue && inputTemplate?.options?.find((option) => option.value === inputValue);

const getSelectedOptionValues = ({ inputTemplate, inputValue }: GetterParams) => {
  const selectedValue = !inputTemplate?.multi
    ? findOption({ inputTemplate,
      inputValue })
    : (inputValue as string[] || [])?.map((value) => findOption({ inputTemplate,
      inputValue: value }));

  const hasSelectedValue = !!((!inputTemplate?.multi && (selectedValue || selectedValue === 0))
  || (inputTemplate?.multi && Array.isArray(selectedValue) && selectedValue.length));

  return inputTemplate?.multi ? hasSelectedValue ? selectedValue : inputTemplate.defaultValue || [] : typeof selectedValue === 'number' ? selectedValue : selectedValue || inputTemplate?.defaultValue;
};

type GetRuleInputParams = {
  isEditMode: boolean;
  onFieldChange: (field: string, value: DataType, index?: number) => void;
  index?: number;
};

const getRuleInput = ({ inputTemplate, inputValue, isEditMode, onFieldChange, index, inputKey }: GetRuleInputParams & GetterParams & { inputKey: string }): ReactElement | null => {
  switch (inputTemplate?.inputType) {
    case 'number':
      return ({
        key: inputKey,
        type: (props) => <input type='number' {...props} />,
        props: {
          placeholder: inputTemplate.placeholder,
          value: inputValue,
        },
      });

    case 'text':
      return ({
        key: inputKey,
        type: JitTextInput,
        props: {
          placeholder: inputTemplate.placeholder,
          value: inputValue,
          onChange: () => {},
          ...inputTemplate?.validation,
        },
      });

    case 'select': {
      const selectedValue = getSelectedOptionValues({ inputTemplate,
        inputValue });

      return ({
        key: inputKey,
        type: JitAutoCompleteForm,
        props: {
          options: inputTemplate?.options || [],
          getOptionLabel: (option: InputOption) => option?.label,
          getOptionKey: (option: InputOption) => option?.value,
          placeHolder: inputTemplate.placeholder,
          selectedValue,
          isSingleValue: !inputTemplate.multi,
          disabled: !isEditMode,
          setSelectedValue: (newValue: InputOption | InputOption[]) => {
            const fieldValue = Array.isArray(newValue) ? newValue.map((v) => v.value) : newValue?.value;
            onFieldChange(inputKey, fieldValue, index);
          },
          disableCloseOnSelect: inputTemplate.multi,
        },
      });
    }

    default:
      return null;
  }
};

const getOptions = (callback?: CallbackKey): InputOption[] => (callback ? policyRuleOptionsCallbackMap[callback]?.() : []);

const getDynamicConfig = (
  inputTemplate: RuleTemplateInput,
  data: RuleSettings | Condition,
  inputKey: string,
) => {
  const entityKey = _.camelCase(data.conditionEntity as string);
  const attributeKey = _.camelCase(data.conditionAttribute as string);

  if (attributeKey && (inputKey === 'conditionValue' || inputKey === 'conditionOperator')) {
    return inputTemplate.dynamicConfig?.[entityKey]?.[attributeKey as keyof RuleTemplateInput['dynamicConfig']];
  }

  if (inputKey === 'conditionAttribute' || inputKey === 'conditionValue' || inputKey === 'conditionOperator') {
    return inputTemplate.dynamicConfig?.[entityKey];
  }

  const dependsOnValue = data[_.camelCase(inputTemplate.dependsOn) as keyof RuleTemplateInput['dynamicConfig']];
  return inputTemplate.dynamicConfig?.[dependsOnValue];
};

type GetConditionSentenceParams = GetRuleInputParams & {
  conditionSentence: string;
  inputTemplates: IRuleTemplate['inputs'];
  data: RuleSettings | Condition;
};

export const getConditionSentence = ({ conditionSentence, inputTemplates, data, isEditMode, onFieldChange, index }: GetConditionSentenceParams) => {
  const conditionFormats: ReactElement[] = [];
  const conditionSentenceSplit = conditionSentence.split(/(\{[^}]+\})/).map((w) => w.trim()).filter(Boolean);

  // eslint-disable-next-line max-statements
  conditionSentenceSplit.forEach((word) => {
    if (word.startsWith('{')) {
      const inputKey = _.camelCase(word.slice(1, -1)) as keyof (RuleSettings | Condition);
      let inputTemplate = inputTemplates[inputKey];

      if (inputTemplate && inputTemplate.dependsOn && inputTemplate.dynamicConfig) {
        const selectedInput = getDynamicConfig(inputTemplate, data, inputKey);

        inputTemplate = {
          ...inputTemplate,
          ...selectedInput,
        };

        if (inputTemplate.optionsCallback) {
          const options = getOptions(inputTemplate.optionsCallback);

          inputTemplate = {
            ...inputTemplate,
            options,
          };
        }
      }

      if (!(inputKey === 'conditionOperator' && (!inputTemplate?.options || inputTemplate?.options?.length === 1))) {
        const inputValue = data[inputKey] as DataType;

        const input = getRuleInput({
          inputTemplate,
          inputValue,
          isEditMode,
          onFieldChange,
          index,
          inputKey,
        });

        if (input) conditionFormats.push(input);
      }
    } else {
      conditionFormats.push({
        key: uuidv4(),
        type: JitText,
        props: {
          text: word,
        },
      });
    }
  });

  return conditionFormats;
};
