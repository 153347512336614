import { createContext, useContext } from 'react';

import { ActionStatus, IAction, IConcealedAction, IFilter } from 'types/interfaces';
import { IPaginatedState, initialPaginatedState } from 'types/interfaces/IPaginatedState';

interface IActionsContext {
  isLoading: boolean;
  actions: IPaginatedState<IAction | IConcealedAction>;
  setActionStatus: (actionId: string, status: ActionStatus) => void;
  getActions: (filters?: IFilter[], cleanState?: boolean) => void;
  isFetchActionFiltersLoading: boolean;
  getActionDistinctFilters: () => void;
  setActions: (actions: IPaginatedState<IAction | IConcealedAction>) => void;
  actionFilters: IFilter[];
  applyFilterToQueryParam: (filters: IFilter) => void;
  setActionFilters: (filters: IFilter[]) => void;

}

export const ActionsContext = createContext<IActionsContext>({
  isLoading: true,
  setActionStatus: () => {},
  getActions: () => {},
  actions: initialPaginatedState,
  isFetchActionFiltersLoading: true,
  setActions: () => {},
  getActionDistinctFilters: () => {},
  actionFilters: [],
  applyFilterToQueryParam: () => {},
  setActionFilters: () => {},

});

export const useActionsContext = () => useContext(ActionsContext);
