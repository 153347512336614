import { usePipelineContextNew } from 'context/PipelinesContext/PipelinesContextNew';
import { IFilter } from 'types/interfaces/IFilter';

export const useUpdateFilterNew = () => {
  const { filters, setFilters } = usePipelineContextNew();

  const updateFilter = (updatedFilter: IFilter) => {
    const updatedFilters = filters.map((currentFilter) => (currentFilter.entityKey === updatedFilter.entityKey ? updatedFilter : currentFilter));
    setFilters(updatedFilters);
  };
  return { updateFilter };
};
