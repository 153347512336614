import { UIEvent, ChangeEvent, FC, RefCallback, useCallback, useEffect, useMemo, useState } from 'react';

import styles from './ActionCard.module.scss';
import { downloadScript, generateFixScriptForAmountOfApprovers, generateFixScriptForRequiredStatusChecks } from './branchProtectionUtils';
import { ActionCardContent } from './components/ActionCardContent/ActionCardContent';
import { ExtendedAction } from './components/ExtendedAction/ExtendedAction';
import { generateBranchProtectionSubtitle, generateBranchProtectionTitle } from './components/utils/branchProtectionActions';
import { generateCodeSubtitle, generateCodeTitle } from './components/utils/codeAction';

import { useCreateTicket } from 'components/CreateTicketButton/hooks/useCreateTicket';
import { useVendorTicketConfig } from 'components/CreateTicketButton/VendorConfig';
import { IgnoreFindingsDialog } from 'components/IgnoreFindingsDialog/IgnoreFindingsDialog';
import { calcShouldFetchMore } from 'components/JitTable/utils';
import { useActionsContext } from 'context/ActionsContext/ActionsContext';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { useGetActiveIntegration } from 'context/IntegrationsContext/hooks/useGetActiveIntegration';
import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks/useWebsocketSubscribe';
import { i18n } from 'locale/i18n';
import {
  BRANCH_PROTECTION_CONTROL_NAME,
  MIN_APPROVALS_FILE_NAME,
  NUMBER_OF_APPROVALS_IS_LESS_THAN_REQUIRED_TEST_ID,
  REQUIRED_STATUS_CHECKS_FILE_NAME,
  SOME_MANDATORY_CHECKS_ARE_NOT_REQUIRED_TEST_ID,
} from 'pages/ActionsPage/constants';
import { useActionService } from 'services/ActionService/useActionService';
import { useFetchActionFindings } from 'services/ActionService/useFetchActions';
import { fetchFullFindingsByIds } from 'services/FindingsService';
import { parseFinding } from 'services/FindingsService/utils/parseFindings';
import { logError } from 'services/logger/logger';
import { FindingStatus, WebSocketNotificationTopics } from 'types/enums';
import { IgnoreReason } from 'types/enums/IgnoreReason';
import {
  ActionStatus,
  IAction,
  IActionFinding,
  IConcealedAction,
  IDictionary,
  IFinding,
  IMouseEvent,
  IWebsocketNotification,
  TicketFindingServer,
} from 'types/interfaces';
import { initialPaginatedState } from 'types/interfaces/IPaginatedState';
import { stopPropagation } from 'utils';

interface ActionCardProps {
  action: IAction | IConcealedAction;
  occurrences: number;
  actionIndex: number;
  isExpanded: boolean;
  isConcealed: boolean;
  isHovered: boolean;
  handleActionExpanded?: (actionId: string) => void;
  innerRef: RefCallback<HTMLDivElement>
}

export const ActionCard: FC<ActionCardProps> = ({
  action, occurrences, actionIndex, isExpanded, isConcealed, isHovered, handleActionExpanded, innerRef,
}) => {
  const [isOpenPRLoading, setIsOpenPRLoading] = useState(false);
  const [isIgnoreDialogOpen, setIsIgnoreDialogOpen] = useState(false);
  const [isCreateStoryLoading, setIsCreateStoryLoading] = useState(false);
  const [selectedFindingsIds, setSelectedFindingsIds] = useState<string[]>([]);
  const [isIgnoredLoading, setIsIgnoredLoading] = useState(false);
  const { vendorTicketConfig } = useVendorTicketConfig();
  const { isLoading: isIntegrationsLoading, activeIntegration } = useGetActiveIntegration((integration) => integration.vendor in vendorTicketConfig);
  const { getActionFindings, isLoading: isLoadingActionFindings, actionFindings, setActionFindings } = useFetchActionFindings();
  const { frontEggUser } = useAuthContext();

  const { setActionStatus } = useActionsContext();
  const { websocketSubscribe } = useWebsocketSubscribe();
  const { createStory } = useCreateTicket();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const { createActionFindingsIgnoreRules, openPRForActionFindings } = useActionService();

  const handleActionFindingUpdatesWebSocketNotification = (notification: IWebsocketNotification<IActionFinding>) => {
    const { message: { updated } } = notification;
    setActionFindings((prevFindings) => ({
      ...prevFindings,
      data: prevFindings.data.map((finding) => {
        const updatedFinding = updated?.find((actionFinding) => actionFinding.id === finding.id);
        return updatedFinding || finding;
      }),
    }));
  };

  useEffect(() => {
    if (isExpanded) {
      getActionFindings(action.id);
      websocketSubscribe(WebSocketNotificationTopics.ActionFinding, handleActionFindingUpdatesWebSocketNotification);
    } else {
      setSelectedFindingsIds([]);
      setActionFindings(initialPaginatedState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- getActionFindings is a callback that should be called only when isExpanded changes
  }, [isExpanded]);

  const vendorConfig = activeIntegration && vendorTicketConfig[activeIntegration.vendor as keyof typeof useVendorTicketConfig];

  const handleCheckFinding = (event: ChangeEvent<HTMLInputElement>, checked: Boolean) => {
    event.stopPropagation();
    const { id } = event.target;
    setSelectedFindingsIds((prev) => (checked ? [...prev, id] : prev.filter((finding) => finding !== id)));
  };

  const openFindings = useMemo(() => actionFindings.data.filter((finding) => finding.resolution === FindingStatus.OPEN), [actionFindings]);

  const handleCheckAllFindings = (event: ChangeEvent<HTMLInputElement>, checked: Boolean) => {
    if (checked) {
      const allFindingsID = openFindings.map(({ id }) => id);
      setSelectedFindingsIds([...allFindingsID]);
    } else {
      setSelectedFindingsIds([]);
    }
  };

  const buildAnalyticsEventParams = () => ({
    actionId: action.id,
    actionName: action.title,
    selectedFindingsIds,
    totalActionFindingsAmount: occurrences.toString(),
  });

  const handleCloseIgnoreDialog = () => setIsIgnoreDialogOpen(false);

  const handleSendIgnoreFindings = async (reason: IgnoreReason, comment: string) => {
    setIsIgnoredLoading(true);
    setSelectedFindingsIds([]);
    await createActionFindingsIgnoreRules({
      action_id: action.id,
      findings_ids: selectedFindingsIds,
      type: 'fingerprint',
      reason,
      comment,
    });
    setIsIgnoredLoading(false);
    handleCloseIgnoreDialog();
    sendAnalyticsEvent({
      action: 'ignore-action-clicked',
      params: buildAnalyticsEventParams(),
    });
  };

  const handleOpenIgnoreDialog = (event: IMouseEvent) => {
    event.stopPropagation();
    setIsIgnoreDialogOpen(true);
  };

  const handleBranchProtectionFix = () => {
    sendAnalyticsEvent({
      action: 'branch-protection-action-clicked',
      params: buildAnalyticsEventParams(),
    });

    if (action.test_id === NUMBER_OF_APPROVALS_IS_LESS_THAN_REQUIRED_TEST_ID) {
      const script = generateFixScriptForAmountOfApprovers(actionFindings.data, selectedFindingsIds);
      downloadScript(script, MIN_APPROVALS_FILE_NAME);
    } else if (action.test_id === SOME_MANDATORY_CHECKS_ARE_NOT_REQUIRED_TEST_ID) {
      const script = generateFixScriptForRequiredStatusChecks(actionFindings.data, selectedFindingsIds);
      downloadScript(script, REQUIRED_STATUS_CHECKS_FILE_NAME);
    }
  };

  const setFindingsPendingStatus = (findingIds: string[]) => (
    setActionFindings((prevFindings) => ({
      ...prevFindings,
      data: prevFindings.data.map((finding) => (findingIds.includes(finding.id) ? {
        ...finding,
        pendingForPrUrl: true,
      } : finding)),
    }))
  );

  const handleOpenPrFix = async () => {
    setIsOpenPRLoading(true);

    sendAnalyticsEvent({
      action: 'fix-pr-action-clicked',
      params: buildAnalyticsEventParams(),
    });

    const response = await openPRForActionFindings({
      action_id: action.id,
      findings_ids: selectedFindingsIds,
    });

    if (!response) console.error('Error in open pr');
    setFindingsPendingStatus(selectedFindingsIds);
    setActionStatus(action.id, ActionStatus.InProgress);
    setIsOpenPRLoading(false);
  };

  const isBranchProtection = () => action.control_name === BRANCH_PROTECTION_CONTROL_NAME;

  const handleFixFindings = async (event: IMouseEvent) => {
    event.stopPropagation();
    if (isBranchProtection()) {
      handleBranchProtectionFix();
    } else {
      await handleOpenPrFix();
    }
    setSelectedFindingsIds([]);
  };

  const actionsTexts: IDictionary<string> = i18n.t('pages.actions.actionCard.actions', { returnObjects: true });
  const generateSubtitle = () => (isBranchProtection() ? generateBranchProtectionSubtitle : generateCodeSubtitle);
  const generateTitle = () => (isBranchProtection() ? generateBranchProtectionTitle : generateCodeTitle);
  const getFixButtonText = () => {
    if (isBranchProtection()) {
      return actionsTexts.downloadScriptButton;
    }
    if (actionFindings.data.length > 0 && actionFindings.data[0].fix_suggestion.code_fixes) {
      // If the fix suggestion contains code fixes, we'll want to display them in a dialog and not provide an option to open fix
      return undefined;
    }
    return selectedFindingsIds.length > 1 ? actionsTexts.openPrsButton : actionsTexts.openPrButton;
  };
  const getIgnoreButtonText = () => (selectedFindingsIds.length > 1 ? actionsTexts.ignoreFindingsButton : actionsTexts.ignoreFindingButton);
  const getOpenTicketText = () => {
    if (!vendorConfig) {
      return i18n.t('tickets.noIntegration');
    }
    const baseText = selectedFindingsIds.length > 1 ? 'tickets.baseUnifiedText' : 'tickets.baseText';
    return (
      i18n.t(baseText, { ticketName: i18n.t(vendorConfig.ticketName).toString() })
    );
  };
  const relevantExtendedActionProps = {
    generateSubtitle: generateSubtitle(),
    generateTitle: generateTitle(),
    fixButtonText: getFixButtonText(),
    ignoreButtonText: getIgnoreButtonText(),
  };

  const addTicketUrlToFindings = (ticketUrl: string) => {
    const ticket: TicketFindingServer = {
      ticket_url: ticketUrl,
      vendor: activeIntegration!.vendor,
      created_at: new Date().toISOString(),
      user_id: frontEggUser?.id,
    };
    setActionFindings((prevFindings) => ({
      ...prevFindings,
      data: prevFindings.data.map((finding) => (selectedFindingsIds.includes(finding.id) ? {
        ...finding,
        tickets: [...(finding.tickets || []), ticket],
      } : finding)),
    }));
  };

  const fetchFindings = async () => {
    const serverFindings = await fetchFullFindingsByIds(selectedFindingsIds);
    // TODO : raise error toast if serverFindings is undefined
    if (!serverFindings) {
      logError('Error in fetchFindings');
      return [];
    }
    return serverFindings?.map((finding) => parseFinding(finding));
  };
  const handleCreateStory = async (event: IMouseEvent) => {
    if (!activeIntegration || !vendorConfig) return;
    setIsCreateStoryLoading(true);
    event.stopPropagation();
    const findings: IFinding[] = await fetchFindings();
    const ticket = await createStory(findings, activeIntegration.integrationId, vendorConfig.openTicket, activeIntegration.provider, action.title);
    if (ticket) addTicketUrlToFindings(ticket);
    setIsCreateStoryLoading(false);
  };
  const openTicketText = getOpenTicketText();
  const relevantStyle = isConcealed ? styles.wrapperConcealedAction : styles.wrapperFullAction;

  const handleScroll = useCallback((event: UIEvent<HTMLDivElement>) => {
    if (isLoadingActionFindings || actionFindings.hasReachedEnd) return;

    const shouldFetchMore = calcShouldFetchMore(event, 20);
    if (shouldFetchMore) {
      getActionFindings(action.id);
    }
  }, [action.id, actionFindings.hasReachedEnd, getActionFindings, isLoadingActionFindings]);

  return (
    <div
      ref={innerRef}
      className={`${relevantStyle} ${isExpanded && styles.borderedWrapper}`}
      data-testid={`action-card-${actionIndex}`}
      onClick={handleActionExpanded ? () => handleActionExpanded(action.id) : undefined}
      role='button'
      tabIndex={0}
    >
      <div className={`${isConcealed ? styles.concealedContent : ''} ${styles.cardContentWrapper}`}>
        <ActionCardContent
          action={action}
          actionIndex={actionIndex}
          isConcealed={!!action.is_concealed}
          isExpanded={isExpanded}
          isHovered={isHovered}
          notIgnoredFindings={openFindings}
        />

        {isExpanded && (
          <ExtendedAction
            {...relevantExtendedActionProps}
            action={action}
            createTicketText={openTicketText}
            handleCheckAllFindings={handleCheckAllFindings}
            handleCheckFinding={handleCheckFinding}
            handleCreateStory={handleCreateStory}
            handleFixFindings={handleFixFindings}
            handleOpenIgnoreDialog={handleOpenIgnoreDialog}
            handleScroll={handleScroll}
            isCreateStoryDisabled={isIntegrationsLoading || !activeIntegration || isCreateStoryLoading}
            isCreateStoryLoading={isCreateStoryLoading}
            isLoadingActionFindings={isLoadingActionFindings}
            isOpenPRLoading={isOpenPRLoading}
            openFindings={openFindings}
            selectedFindingsIds={selectedFindingsIds}
          />
        )}
      </div>

      <div onClick={stopPropagation} role='button' tabIndex={0}>
        <IgnoreFindingsDialog
          ignoreFunction={handleSendIgnoreFindings}
          isIgnoredLoading={isIgnoredLoading}
          onClose={() => handleCloseIgnoreDialog()}
          open={isIgnoreDialogOpen}
        />
      </div>

    </div>

  );
};
