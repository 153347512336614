import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import actionsStyles from '../actionComponents.module.scss';

import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';
import { constants } from 'globalConstants';
import { useTeamsService } from 'services/TeamsService/useTeamsService';
import { ITaskButtonModel, ITeamCheckDetails, TaskKeyEnum } from 'types/interfaces/Teams/ITeam';

export const useGetNavigateButton = (
  teamId: string,
  buttonTemplate: ITaskButtonModel | undefined,
  updateCheck: (check: ITeamCheckDetails, value?: string) => void,
) => {
  const { updateTeamChecks } = useTeamsService();
  const {
    teamsPortal: { BASE_ROUTE: BASE_TEAMS_PORTAL_ROUTE, PORTAL_TEAMS },
  } = constants.routes;
  const navigate = useNavigate();
  const handleClick = useCallback(async () => {
    updateTeamChecks(teamId, [{
      check_slug: TaskKeyEnum.resources,
      is_completed: true,
    }]);
    // This call is not awaited on purpose, since we don't want to wait for
    // the response before navigating to the next page.
    updateCheck({ checkSlug: TaskKeyEnum.resources } as ITeamCheckDetails, 'true');

    navigate(`/${BASE_TEAMS_PORTAL_ROUTE}/${PORTAL_TEAMS}/${teamId}`);
  }, [BASE_TEAMS_PORTAL_ROUTE, PORTAL_TEAMS, navigate, teamId, updateCheck, updateTeamChecks]);

  const gradientButton = (
    <JitGradientButton
      data-testid={`action-navigate-${TaskKeyEnum.resources}`}
      onClick={handleClick}
    >
      <div className={actionsStyles.gradientButton}>
        <JitText size='s' text={buttonTemplate?.label} />
      </div>
    </JitGradientButton>
  );

  const actionButton = gradientButton;

  return ({ actionButton });
};
