import { isEmpty } from 'lodash';

import {
  authModeToExtraFieldsInitialState,
  webApplicationCommonInitialState,
} from 'components/Configurations/Zap/constants';
import { AssetType } from 'types/enums';
import { IConfigurations, ZapApplication } from 'types/interfaces';

export type ConfigurationValidator = (configurations: IConfigurations) => boolean;

const getZapRequiredFields = (application: ZapApplication): (keyof ZapApplication)[] => {
  const extraFields = Object.keys(authModeToExtraFieldsInitialState[application.authentication_mode]);

  const notRequiredFields = ['exclude_paths', 'username_css_selector', 'password_css_selector'];
  // @ts-ignore
  return [...Object.keys(webApplicationCommonInitialState), ...extraFields].filter((key) => !notRequiredFields.includes(key));
};

const isValidUrl = (string: string): boolean => {
  try {
    // eslint-disable-next-line no-new
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
};

const isApiFileUrl = (url: string): boolean => {
  // url can be direct file path (<url>.json) or api with format in query (<url>?format=json)
  const formats = ['json', 'yml', 'yaml'];
  return formats.some((format) => url.endsWith(`.${format}`) || url.endsWith(`=${format}`) || url.includes(`.${format}`));
};

export const ZapUrlValidator = (isFile: boolean, targetUrl?: string) => {
  if (!targetUrl) return true;
  if (isFile) {
    return isValidUrl(targetUrl) && isApiFileUrl(targetUrl);
  }
  return isValidUrl(targetUrl) && !isApiFileUrl(targetUrl);
};

const isValidDomain = (domain: string): boolean => {
  const regex = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/;
  return regex.test(domain);
};

export const ZapDomainValidator = (domain?: string): boolean => !domain || isValidDomain(domain);

const ZapTargetUrlValidator = (isFile: boolean, targetUrl?: string) => {
  if (!targetUrl) return true;
  const urlValid = ZapUrlValidator(isFile, targetUrl);
  const fileValid = (isFile && isApiFileUrl(targetUrl));
  return urlValid || fileValid;
};

const ZapValidator = (applications?: ZapApplication[]) => {
  if (isEmpty(applications)) return false;

  return applications!.every((application) => {
    if (!application.type || !application.authentication_mode) return false; // This is just in case - shouldn't really happen.
    if (!ZapTargetUrlValidator(application.type === AssetType.API, application.target_url)) return false;
    if (!ZapDomainValidator(application.api_domain)) return false;

    const requiredFields = getZapRequiredFields(application);
    const missingFields = requiredFields.filter((field) => isEmpty(application[field]));
    return missingFields.length === 0;
  });
};

export const ZapWebValidator: ConfigurationValidator = (configurations: IConfigurations) => {
  const zapWebApplications = configurations?.applications?.filter((application) => application.type === AssetType.WEB) as ZapApplication[] | undefined;
  return ZapValidator(zapWebApplications);
};

export const ZapApiValidator: ConfigurationValidator = (configurations: IConfigurations) => {
  const zapAPIApplications = configurations?.applications?.filter((application) => application.type === AssetType.API) as ZapApplication[] | undefined;
  return ZapValidator(zapAPIApplications);
};
