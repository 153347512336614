import { FC } from 'react';

import styles from './SuccessToastBarContent.module.scss';

import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  link: string
}

export const SuccessToastBarContent: FC<Props> = ({ link }) => (
  <div className={styles.root}>
    <JitText color={colors.cards} text='tickets.successToast.subtitle' />

    <JitExternalLink href={link} text='tickets.successToast.link' textColor={colors.blue} />
  </div>
);
