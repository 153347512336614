import { FC, useState } from 'react';

import { FeedbackPopper } from 'pages/FindingsPage/components/FindingDetails/components/Priority/children/Feedback/FeedbackPopper/FeedbackPopper';
import {
  FeedbackSubmittedText,
} from 'pages/FindingsPage/components/FindingDetails/components/Priority/children/Feedback/FeedbackSubmittedText/FeedbackSubmittedText';
import { IFinding } from 'types/interfaces';

interface FeedbackButtonProps {
  finding: IFinding;
}

export const FeedbackButton: FC<FeedbackButtonProps> = ({ finding }) => {
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);

  const feedbackPopper = (
    <FeedbackPopper
      finding={finding}
      isFeedbackOpen={isFeedbackOpen}
      setIsFeedbackOpen={setIsFeedbackOpen}
      setIsFeedbackSubmitted={setIsFeedbackSubmitted}
    />
  );

  const feedbackSubmittedText = <FeedbackSubmittedText />;

  return isFeedbackSubmitted ? feedbackSubmittedText : feedbackPopper;
};
