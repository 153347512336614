import { Switch } from '@mui/material';
import _ from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';

import { DataType, IPolicyRule, IRuleTemplate } from '../../types';
import { RuleBuilder } from '../PolicyBuilder/RuleBuilder';

import styles from './PolicyRule.module.scss';

import { EditPen, Trash } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';

interface Props {
  ruleData: IPolicyRule;
  index: number;
  ruleTemplate: IRuleTemplate[];
}

export const PolicyRule: FC<Props> = ({ ruleData, ruleTemplate, index }) => {
  const [editRuleData, setEditRuleData] = useState<IPolicyRule | null>(null);

  const handleToggleEnableRule = useCallback(() => {
    // TODO: Implement
  }, []);

  const handleDelete = useCallback(() => {
    // TODO: Implement
  }, []);

  const handleEdit = useCallback(() => {
    setEditRuleData(JSON.parse(JSON.stringify(ruleData)));
  }, [ruleData]);

  const handleSave = useCallback(() => {
    // TODO: Implement
  }, []);

  const handleCancel = () => {
    setEditRuleData(null);
  };

  const dirtyForm = useMemo(() => JSON.stringify(editRuleData) !== JSON.stringify(ruleData), [editRuleData, ruleData]);

  const validForm = useMemo(() => {
    const isAllRequiredBaseConditionFilled = editRuleData?.settings.conditions.every((condition) => condition.conditionValue);
    const isAllRequiredFilterConditionsFilled = editRuleData?.settings.conditions.every((condition) => condition.conditionValue);

    return isAllRequiredBaseConditionFilled && isAllRequiredFilterConditionsFilled;
  }, [editRuleData]);

  const getDependentFields = useCallback((field: string, inputs: IRuleTemplate['inputs']): string[] => {
    const directDependents = Object.keys(inputs).filter(
      (key) => _.camelCase(inputs[key].dependsOn) === field,
    );

    const nestedDependents = directDependents
      .flatMap((dependentField) => getDependentFields(dependentField, inputs));

    return [...directDependents, ...nestedDependents];
  }, []);

  const resetDependentFields = useCallback((
    settings: IPolicyRule['settings'],
    changedField: string,
    conditionIndex?: number,
  ) => {
    const updatedSettings = { ...settings };
    const { inputs } = ruleTemplate[0];

    const fieldsToReset = getDependentFields(changedField, inputs);

    if (conditionIndex === undefined) {
      fieldsToReset.forEach((field) => {
        if (field in updatedSettings) {
          delete updatedSettings[field];
        }
      });
    } else if (updatedSettings.conditions?.[conditionIndex]) {
      const updatedCondition = { ...updatedSettings.conditions[conditionIndex] };
      fieldsToReset.forEach((field) => {
        if (field in updatedCondition) {
          delete updatedCondition[field as keyof typeof updatedCondition];
        }
      });
      updatedSettings.conditions = [
        ...updatedSettings.conditions.slice(0, conditionIndex),
        updatedCondition,
        ...updatedSettings.conditions.slice(conditionIndex + 1),
      ];
    }

    return updatedSettings;
  }, [ruleTemplate, getDependentFields]);

  const onFieldChange = useCallback((field: string, value: DataType, conditionIndex?: number) => {
    setEditRuleData((prev) => {
      if (!prev) return prev;

      let updatedSettings = { ...prev.settings };

      if (conditionIndex !== undefined) {
        const updatedConditions = [...(updatedSettings.conditions || [])];
        updatedConditions[conditionIndex] = {
          ...updatedConditions[conditionIndex],
          [field]: value,
        };
        updatedSettings = {
          ...updatedSettings,
          conditions: updatedConditions,
        };
      } else {
        updatedSettings = {
          ...updatedSettings,
          [field]: value,
        };
      }

      const finalSettings = resetDependentFields(updatedSettings, field, conditionIndex);

      return {
        ...prev,
        settings: finalSettings,
      };
    });
  }, [resetDependentFields]);

  const isSaving = false; // TODO: Implement

  const updatedByText = useMemo(
    () => {
      if (ruleData.updatedBy) {
        return ruleData.updatedAt
          ? `${i18n.t('pages.policies.rule.modify.updatedBy', { by: ruleData.updatedBy })}${ruleData.updatedAt && i18n.t('pages.policies.rule.modify.updatedAt', { at: ruleData.updatedAt })}`
          : `${i18n.t('pages.policies.rule.modify.createdBy', { by: ruleData.updatedBy })}${ruleData.createdAt && i18n.t('pages.policies.rule.modify.updatedAt', { at: ruleData.createdAt })}`;
      }

      return null;
    },
    [ruleData],
  );

  return (
    <>
      <JitText muted text={index + 1} />

      <div className={styles.ruleBody}>
        <RuleBuilder
          isEditMode={!!editRuleData}
          onFieldChange={onFieldChange}
          ruleTemplate={ruleTemplate}
          settings={editRuleData?.settings || ruleData.settings}
        />

        {updatedByText && (
          <div className={styles.editInfoBox}>
            <JitText
              text={updatedByText}
            />
          </div>
        )}
      </div>

      {editRuleData ? (
        <div className={styles.formButtons}>
          <JitGradientButton
            data-testid='saveRuleButton'
            disabled={!dirtyForm || !validForm}
            isLoading={isSaving}
            onClick={handleSave}
          >
            <div className={styles.saveButton}>
              <JitText bold fontFamily='Inter, serif' size='s' text='save' />
            </div>
          </JitGradientButton>

          <JitText data-testid='cancelRuleEditButton' onClick={handleCancel} text='Cancel' />
        </div>
      ) : (
        <div className={styles.buttonsWrapper}>
          <div className={styles.controlButtons}>
            <JitButton data-testid='editRuleButton' disabled={!!editRuleData} onClick={handleEdit}>
              <JitTooltip followCursor={false} placement='top' title='pages.policies.rule.buttons.edit.tooltip'>
                <JitIcon color={colors.iris} icon={EditPen} size={14} />
              </JitTooltip>
            </JitButton>

            <JitTooltip followCursor={false} placement='top' title='pages.policies.rule.buttons.delete.tooltip'>
              <JitButton data-testid='deleteRuleButton' onClick={handleDelete}>
                <JitIcon icon={Trash} size={14} />
              </JitButton>
            </JitTooltip>
          </div>

          <Switch onChange={handleToggleEnableRule} />
        </div>
      )}
    </>
  );
};
