import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { useWebsocketSubscribe } from '../WebSocketContext/hooks';

import { useHandleSecretsWebSocketNotification } from './hooks/useHandleSecretsWebSocketNotification/useHandleSecretsWebSocketNotification';

import { SecretsContext } from 'context/SecretsContext';
import { logError, logInfo } from 'services/logger/logger';
import { useSecretService } from 'services/SecretsService/useSecretService';
import { WebSocketNotificationTopics } from 'types/enums';
import { IConfigurableItem } from 'types/interfaces/Configurations/IConfigurableItem';

export const SecretsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showSecretsManagement, setShowSecretsManagement] = useState(false);
  const [tenantSecrets, setTenantSecrets] = useState<undefined | IConfigurableItem[]>(undefined);
  const { websocketSubscribe } = useWebsocketSubscribe();
  const { handleSecretsWebSocketNotification } = useHandleSecretsWebSocketNotification({ setTenantSecrets });
  const { getTenantSecrets } = useSecretService();

  const fetchTenantSecrets = useCallback(async () => {
    setIsLoading(true);

    const secretsResponse = await getTenantSecrets();
    if (secretsResponse?.status && secretsResponse.status <= 299) {
      setTenantSecrets(secretsResponse.data);
    } else if (secretsResponse?.status && secretsResponse.status === 404) {
      logInfo('No secrets file found');
    } else {
      logError('Error while fetching secrets');
    }
    setIsLoading(false);
  }, [getTenantSecrets]);

  useEffect(() => {
    if (tenantSecrets !== undefined) {
      websocketSubscribe(WebSocketNotificationTopics.Secret, handleSecretsWebSocketNotification);
    }
  }, [handleSecretsWebSocketNotification, tenantSecrets, websocketSubscribe]);

  const value = useMemo(() => ({
    tenantSecrets,
    setTenantSecrets,
    isLoading,
    fetchTenantSecrets,
    showSecretsManagement,
    setShowSecretsManagement,
  }), [tenantSecrets, isLoading, fetchTenantSecrets, showSecretsManagement]);

  return (
    <SecretsContext.Provider value={value}>
      {children}
    </SecretsContext.Provider>
  );
};
