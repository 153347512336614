import { FC, useState, useEffect } from 'react';

import styles from './AddTag.module.scss';
import { PopoverContent } from './PopoverContent';

import { Plus } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitPopper } from 'components/JitPopper/JitPopper';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { IAsset } from 'types/interfaces';
import { Tag } from 'types/interfaces/Tag';

interface Props {
  asset?: IAsset;
  onEditTagComplete: () => void;
  tagToEdit?: Tag;
}

export const AddTag: FC<Props> = ({ asset, tagToEdit, onEditTagComplete }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleClick = () => {
    setIsDialogOpen(true);
  };
  const onClose = () => {
    setIsDialogOpen(false);
    onEditTagComplete();
  };

  useEffect(() => {
    if (tagToEdit) {
      setIsDialogOpen(true);
    }
  }, [tagToEdit]);

  const buttonColor = isDialogOpen ? colors.gray : colors.iris;

  return (
    <JitPopper
      content={<PopoverContent asset={asset} onClose={onClose} tagToEdit={tagToEdit} />}
      isWithArrow={false}
      onClose={onClose}
      open={isDialogOpen}
      placement='bottom'
      shadowVariant='none'
      withBackdrop
    >
      <div
        className={styles.addTagIcon}
        data-testid='addTagButton'
        onClick={handleClick}
        role='button'
        tabIndex={0}
      >
        <JitIcon
          color={buttonColor}
          icon={Plus}
          size={10}
          style={{ alignSelf: 'center' }}
        />

        <JitText color={buttonColor} noWrap text='pages.risks.resources.resourcePage.resourceDetails.addTag' />
      </div>
    </JitPopper>
  );
};
