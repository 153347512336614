export enum GithubStatus {
  Outage = 'outage',
  Operational = 'operational',
}

export interface IGithubStatusAlert {
  status: GithubStatus;
  created_at: string;
  id: string;
}
