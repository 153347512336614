import { FC } from 'react';

import { JitText } from 'components/JitText/JitText';
import styles from 'pages/TeamsPage/SubPages/TeamsPage/components/TeamsTable/Cells/NameCell/NameCell.module.scss';
import { ITableCell } from 'types/interfaces';

export const NameCell: FC<ITableCell> = ({ cell: { value } }) => (
  <div className={styles.wrapper}>
    <JitText
      bold
      text={value.name}
    />

    <JitText
      muted
      noWrap
      overflowHiddenEllipsis
      size='s'
      text={value.description}
    />
  </div>
);
