import { Dispatch, SetStateAction, useCallback } from 'react';

import { ISpecificTeamState } from '../TeamsProvider';

import { IWebsocketNotification } from 'types/interfaces';
import { IPaginatedState } from 'types/interfaces/IPaginatedState';
import { IGetTeamResponseItem, IMember } from 'types/interfaces/Teams/ITeam';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

interface Props {
  setTeams: Dispatch<SetStateAction<IPaginatedState<IGetTeamResponseItem>>>,
  setSpecificTeam: Dispatch<SetStateAction<ISpecificTeamState>>,
}

export const useHandleTeamMembersWebSocketNotification = ({ setTeams, setSpecificTeam }: Props) => {
  const modifySpecificTeamMembers = (
    specificTeam: ISpecificTeamState,
    createdMembers: IMember[],
  ) => {
    if (!specificTeam.team || !specificTeam.members.data || !createdMembers) {
      return specificTeam;
    }

    const specificTeamCreatedMembers = createdMembers.filter((member) => member.teamId === specificTeam.team?.id);

    return {
      ...specificTeam,
      members: {
        ...specificTeam.members,
        data: [...specificTeam.members.data, ...specificTeamCreatedMembers],
      },
    };
  };

  const getTeamsAfterAddedCreatedMembers = useCallback((teams: IPaginatedState<IGetTeamResponseItem>, createdMembers: IMember[]) => {
    if (!teams.data || !createdMembers) {
      return teams;
    }

    return {
      ...teams,
      data: teams.data.map((team) => {
        const teamMembers = createdMembers.filter((member) => member.teamId === team.id);
        return {
          ...team,
          members: [...team.members, ...teamMembers],
        };
      }),
    };
  }, []);

  const handleTeamMembersWebSocketNotification = useCallback((notification: IWebsocketNotification<IMember>) => {
    const { message: { created } } = notification;
    const createdMembers = camelizeSnakeCaseKeys(created || []) as IMember[];

    setTeams((currentTeams) => getTeamsAfterAddedCreatedMembers(currentTeams, createdMembers));

    setSpecificTeam((currentSpecificTeam) => modifySpecificTeamMembers(
      currentSpecificTeam,
      createdMembers,
    ));
  }, [getTeamsAfterAddedCreatedMembers, setSpecificTeam, setTeams]);
  return { handleTeamMembersWebSocketNotification };
};
