import { isEmpty } from 'lodash';
import { FC } from 'react';

import styles from './RunnerCell.module.scss';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { i18n } from 'locale/i18n';
import { JOB_RUNNER_ICONS } from 'pages/PipelinesPage/constants';
import { ITableCell } from 'types/interfaces';

export const RunnerCell: FC<ITableCell> = ({ cell: { value } }) => {
  const { jobRunner, shouldNotExpectJobRunner } = value;
  const runnerIcon = JOB_RUNNER_ICONS.get(jobRunner);

  if (isEmpty(jobRunner)) {
    if (shouldNotExpectJobRunner) {
      return (
        <JitText
          bold
          data-testid='no-runner-text'
          size='m'
          style={{ marginTop: 20 }}
          text='pages.pipelines.pipelinesTable.noRunner'
        />
      );
    }
    return (
      <div className={styles.loadingWrapper}>
        <JitText bold data-testid='loading-runner-text' size='m' text='pages.pipelines.pipelinesTable.loading' />

        <JitText bold className={styles.dotFlashing} size='m' text=' ' />
      </div>
    );
  }

  return (

    <div className={styles.runnerCellContainer}>
      {runnerIcon && (
        <JitTooltip
          componentsProps={{ tooltip: { style: { marginTop: 5 } } }}
          data-testid='runner-tooltip'
          followCursor={false}
          placement='bottom'
          title={`Runner: ${i18n.t(`pages.pipelineDetails.metadata.runners.${jobRunner}`)}`}
        >
          <JitIcon
            centered={false}
            className={styles.iconRunner}
            data-testid='runner-icon'
            icon={runnerIcon}
            size={20}
          />
        </JitTooltip>
      )}
    </div>

  );
};
