import { IDictionary, ISupportedReferrals } from 'types/interfaces';

export const getCurrentReferrals = (queryParams: IDictionary<string>): ISupportedReferrals => {
  const referrals: ISupportedReferrals = {};

  if ('items' in queryParams && queryParams.items !== '') {
    const items = queryParams.items.split(',');
    referrals.items = items;
  }

  return referrals;
};
