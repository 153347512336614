import { FC } from 'react';

import styles from './IntegrationDetailsExistingAccount.module.scss';
import { IntegrationDetailsExistingBox } from './IntegrationDetailsExistingBox';

import { AwsIntegrationAccount } from 'types/interfaces';

interface Props {
  account: AwsIntegrationAccount
}

export const IntegrationDetailsExistingAccount: FC<Props> = ({ account }) => {
  const accountEntries = Object.entries(account).filter(([key]) => key !== 'type');

  return (
    <div key={account?.account_id} className={styles.accountDetails}>
      {accountEntries.map(([key, value]) => (
        <IntegrationDetailsExistingBox
          key={account.account_name}
          keyText={key}
          valueText={value}
        />
      ))}
    </div>
  );
};

