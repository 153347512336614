import { AssetType } from 'types/enums';

export enum ZapAuthMode {
  FormBased = 'selenium',
  BearerTokenHeader = 'header-bearer',
  CustomCookie = 'custom-cookie',
  LocalStorage = 'local-storage',
  CookieHeader = 'header-cookie',
  CustomHeader = 'header-custom',
  NonAuthenticated = 'non-authenticated',
}

export interface BaseApplication {
  type: string,
  application_name: string,
}

export enum ApplicationTypes {
  ZAP_WEB = 'web',
  ZAP_API = 'api',
  AWS = 'aws_configuration',
}

export type ZapAuthenticationConfigType = AssetType.WEB | AssetType.API;

export interface ZapApplicationBase extends BaseApplication {
  type: ZapAuthenticationConfigType,
  application_name: string,
  target_url: string,
  exclude_paths: string[],
  authentication_mode: ZapAuthMode,
  api_domain?: string, // if non auth, this is not required
}

export interface ZapApplicationNonAuth extends ZapApplicationBase {
  authentication_mode: ZapAuthMode.NonAuthenticated
}

export interface ZapApplicationFormBasedAuth extends ZapApplicationBase {
  authentication_mode: ZapAuthMode.FormBased
  api_domain: string,
  login_page_url: string,
  username: string,
  authentication_key: string,
  authentication_value: string,
  username_css_selector: string,
  password_css_selector: string,
}

export interface ZapApplicationHeaderAuth extends ZapApplicationBase {
  authentication_mode: ZapAuthMode.CustomHeader | ZapAuthMode.BearerTokenHeader | ZapAuthMode.CookieHeader | ZapAuthMode.LocalStorage | ZapAuthMode.CustomCookie,
  authentication_key: string,
  authentication_value: string,
  api_domain: string,
}

export interface ZapApplicationLocalStorage extends ZapApplicationBase {
  authentication_mode: ZapAuthMode.LocalStorage,
  authentication_key: string,
  authentication_value: string,
}

export interface ZapApplicationCustomCookie extends ZapApplicationBase {
  authentication_mode: ZapAuthMode.CustomCookie,
  authentication_key: string,
  authentication_value: string,
}

export interface AWSApplication extends BaseApplication {
  account_ids: string[],
}

// TODO: the schema is not really correct, we technically get whatevent from the server since it set in the yml file.
// When fetching, we need to senitize the data to make sure it's valid.
export type ZapApplication =
  ZapApplicationFormBasedAuth
  | ZapApplicationHeaderAuth
  | ZapApplicationNonAuth;

export type Application = BaseApplication;

export interface IApplications {
  applications?: Application[];
}
