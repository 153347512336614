import { FC } from 'react';

import styles from './JitGradientButton.module.scss';

import { Props as ButtonProps, JitButton } from 'components/JitButton/JitButton';

export const JitGradientButton: FC<ButtonProps> = ({ children, ...props }) => (
  <JitButton
    className={styles.button}
    disableRipple
    variant='text'
    {...props}
  >
    <div className={styles.content}>
      {children}
    </div>
  </JitButton>
);
