import queryString from 'query-string';
import { FC } from 'react';

import styles from './ThirdPartyAuthentication.module.scss';

import { JitFavicon } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { constants } from 'globalConstants';
import colors from 'themes/colors.module.scss';

const { THIRD_PARTY_AUTHENTICATION } = constants;

const iconStyle = {
  withBackSquare: {
    borderRadius: '50%',
    height: '25vh',
    width: '25vh',
    backgroundColor: colors.cards,
  },
  style: {
    margin: '0vh 5vh',
  },
};

export const ThirdPartyAuthentication: FC = () => {
  const values = queryString.parse(window.location.search);
  const origin = values.origin || '';

  const thirdParty = origin && THIRD_PARTY_AUTHENTICATION[origin as keyof typeof THIRD_PARTY_AUTHENTICATION];

  if (!thirdParty) {
    window.location.href = '/';
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.iconsContainer}>
        <JitIcon
          icon={JitFavicon}
          {...iconStyle}
          size='12vh'
        />

        <JitIcon
          icon={thirdParty.icon}
          {...iconStyle}
          size='15vh'
        />
      </div>

      <JitText
        size='xlhuge'
        text={thirdParty.redirect_message.title}
      />

      <JitText
        gutterBottom
        muted
        size='huge'
        text={thirdParty.redirect_message.subtitle}
      />

    </div>
  );
};
