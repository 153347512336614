import { FC } from 'react';

import { ConfirmDialog } from '../ConfirmDialog';

import { useDisconnectSlackIntegration } from 'context/SlackContext/hooks/useDisconnectSlackIntegration';
import { ActionButtonVariant } from 'types/interfaces/ActionButton/ActionButton';

interface Props {
  isOpen: boolean,
  onClose: () => void,
}

export const SlackDisconnectDialog: FC<Props> = ({ isOpen, onClose }) => {
  const { handleDisconnect } = useDisconnectSlackIntegration();

  const handleDisconnectClick = async () => {
    onClose();
    await handleDisconnect();
  };

  const buttons = [
    { label: 'dialogs.disconnectIntegration.slack.cancel',
      variant: ActionButtonVariant.SECONDARY,
      handleClick: onClose },
    { label: 'dialogs.disconnectIntegration.slack.disconnect',
      variant: ActionButtonVariant.DANGER,
      handleClick: handleDisconnectClick },
  ];

  return (
    <ConfirmDialog
      actionButtons={buttons}
      isOpen={isOpen}
      message='dialogs.disconnectIntegration.slack.confirmationTitle'
      onClose={onClose}
      subMessage='dialogs.disconnectIntegration.slack.confirmationMessage'
      title='dialogs.disconnectIntegration.slack.title'
    />
  );
};

