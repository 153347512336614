import { FC, useState } from 'react';

import styles from './SetSlackNotification.module.scss';

import { SlackIconOneColor } from 'assets';
import { SlackIntegrationDialog, SlackIntegrationDialogStage } from 'components/JitDialogs/SlackIntegrationDialog/SlackIntegrationDialog';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { useSlackIntegrationStatus } from 'pages/TeamsPage/SubPages/TeamsPage/hooks/useSlackIntegrationStatus';
import colors from 'themes/colors.module.scss';

interface Props {
  teamName: string;
  teamId: string
}

export const SetSlackNotification: FC<Props> = ({ teamName, teamId }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { isConnected: isSlackConnected } = useSlackIntegrationStatus();

  const onButtonClick = () => {
    if (isSlackConnected) setIsDialogOpen(true);
  };
  const buttonColor = isSlackConnected ? colors.iris : colors.gray;
  return (
    <>
      <JitTooltip
        data-testid='SecurityPlanScoreCard-tooltip'
        followCursor={false}
        placement='top'
        title={!isSlackConnected && 'pages.teams.teamDetails.setupSlack.disabledTooltipText'}
      >
        <div className={styles.buttonWrapper} onClick={onButtonClick} role='button' tabIndex={0}>
          <JitIcon color={buttonColor} icon={SlackIconOneColor} size={12} />

          <JitText alignSelf='center' color={buttonColor} text='pages.teams.teamDetails.setupSlack.buttonText' />
        </div>
      </JitTooltip>

      <SlackIntegrationDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        stage={{
          type: SlackIntegrationDialogStage.TEAM,
          teamName,
          teamId,
        }}
      />
    </>
  );
};
