import { JitGrade } from 'components/JitGrade/JitGrade';

export const useGetRankDataComponent = (score: number) => {
  const gradeComponent = (
    <JitGrade score={score} />
  );

  const dataComponent = gradeComponent;

  return ({ dataComponent });
};
