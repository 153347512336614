import { IWorkflow } from 'types/interfaces/Workflows/IWorkflow';

export const buildInitialWorkflow = (): IWorkflow => {
  const now = new Date().toISOString();
  return {
    id: 'new-workflow', // will be set by the backend
    name: 'New Workflow',
    description: '',
    tenantId: '', // will be set by the backend
    createdAt: now,
    updatedAt: now,
    creatorUserId: '', // will be set by the backend
    creatorUserName: '', // will be set by the backend
    isEnabled: true,
    trigger: {
      id: 'trigger-not-selected',
      stepType: 'trigger',
      type: '',
      name: 'Select a trigger',
      next: undefined,
    },
    steps: [],
    version: 1,
    isLastVersion: true,
    lastRunAt: new Date().toISOString(),
    isDeleted: false,
  };
};
