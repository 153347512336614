import Breadcrumbs from '@mui/material/Breadcrumbs';
import { InputProps } from '@mui/material/Input';
import { FC, useMemo } from 'react';

import styles from './JitBreadcrumbs.module.scss';

import { JitText } from 'components/JitText/JitText';

export interface BreadcrumbItem {
  content: JSX.Element
  itemKey: string;
}

interface Props extends Omit<InputProps, 'onChange'> {
  items: BreadcrumbItem[];
}

export const JitBreadcrumbs: FC<Props> = ({ items }) => {
  const seperator = useMemo(() => (
    <div className={styles.seperatorWrapper}>
      <JitText size='xxl' text='/' />
    </div>
  ), []);
  return (
    <div data-testid='JitBreadcrumbs'>
      <Breadcrumbs
        aria-label='breadcrumb'
        separator={seperator}
      >
        {items.map((item: BreadcrumbItem) => (
          <div key={item.itemKey}>
            {item.content}
          </div>
        ))}
      </Breadcrumbs>
    </div>
  );
};
