import { IWorkflow } from 'types/interfaces/Workflows/IWorkflow';

export const mockWorkflow: IWorkflow = {
  id: 'workflow-1',
  name: 'Mock Workflow',
  description: 'This is a mock workflow based on the provided graph structure.',
  tenantId: 'tenant-1',
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  creatorUserId: 'user-1',
  creatorUserName: 'Admin User',
  isEnabled: true,
  trigger: {
    id: 'trigger',
    stepType: 'trigger',
    name: 'Finding detection',
    next: 'condition',
    type: 'FindingCreated', // Example trigger type
  },
  steps: [
    {
      stepType: 'condition',
      id: 'condition',
      name: 'Severity and Asset',
      next: ['action-1', 'action-2'],
      type: 'finding-condition',
      config: {
        severity: 'high',
        assetType: 'server',
      },
    },
    {
      stepType: 'action',
      id: 'action-1',
      name: 'Create a ticket',
      next: [],
      type: 'finding-slack-message',
      config: {
        vendor: 'slack',
      },
    },
    {
      stepType: 'action',
      id: 'action-2',
      name: 'Notify team',
      next: [],
      type: 'finding-jira-ticket',
      config: {
        vendor: 'jira',
        channel: 'team-notifications',
      },
    },
  ],
  version: 1,
  isLastVersion: true,
  lastRunAt: new Date().toISOString(),
  isDeleted: false,
};
