import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { JitButton } from 'components/JitButton/JitButton';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { constants } from 'globalConstants';
import { InsightsCard } from 'pages/OverviewPage/components/Insights/baseComponents';
import styles from 'pages/TeamsPortal/TeamPage/components/Insights/TopFindingsWidget/TopFindingsWidget.module.scss';
import colors from 'themes/colors.module.scss';

interface Props {
  teamId: string;
  disabled?: boolean;
}

export const TopFindingsWidget: FC<Props> = ({
  teamId, disabled,
}) => {
  const {
    teamsPortal: {
      BASE_ROUTE: BASE_TEAMS_PORTAL_ROUTE,
      PORTAL_TEAMS,
      TOP_FINDINGS,
    },
  } = constants.routes;
  const navigate = useNavigate();
  const handleClick = useCallback(async () => {
    navigate(`/${BASE_TEAMS_PORTAL_ROUTE}/${PORTAL_TEAMS}/${teamId}/${TOP_FINDINGS}`);
  }, [BASE_TEAMS_PORTAL_ROUTE, PORTAL_TEAMS, TOP_FINDINGS, navigate, teamId]);

  return (
    <InsightsCard
      content={(
        <div className={styles.TopFindingsWidgetContent}>
          <JitText
            color={colors.lightGray}
            data-testid='topFindingsDescription'
            size='s'
            text='pages.teamsPortal.teamPage.topFindingsCallToAction.description'
          />

          <JitTooltip
            followCursor={false}
            offset={[0, 9]}
            placement='top'
            title={disabled ? 'pages.teamsPortal.teamPage.topFindingsCallToAction.disabledTooltip' : ''}
          >

            <JitButton className={styles.actionButton} data-testId='topFindingsButton' disabled={disabled} height={36} onClick={handleClick} variant='contained'>
              <JitText bold size='m' text='pages.teamsPortal.teamPage.topFindingsCallToAction.button' />
            </JitButton>

          </JitTooltip>

        </div>

            )}
      header={{
        title: 'pages.teamsPortal.teamPage.topFindingsCallToAction.title',
      }}
      size='s'
      variant='light'
    />
  );
};

