import { FC, useEffect } from 'react';

import styles from './ManageTeamsStatus.module.scss';

import { JitText } from 'components/JitText/JitText';
import { useTeamsContext } from 'context/TeamsContext/TeamsContext';
import { GET_TEAMS_ONBOARDING_LIMIT } from 'services/TeamsService/constants';

export const ManageTeamsStatus: FC = () => {
  const { teams, getTeams } = useTeamsContext();

  useEffect(() => {
    getTeams({
      shouldResetState: true,
      limit: GET_TEAMS_ONBOARDING_LIMIT,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const teamsCount = teams.data.length;
  const displayCount = teamsCount === GET_TEAMS_ONBOARDING_LIMIT ? `${teamsCount}+` : teamsCount;

  return (
    <div className={styles.wrapper}>
      <JitText muted size='l' text={displayCount} />

      <JitText fontFamily='Inter, serif' muted text='Teams' />
    </div>
  );
};
