import { FC } from 'react';

import styles from './JitTablePagination.module.scss';

import { ChevronClosed, ChevronOpened } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  pageIndex: number;
  totalRecordsAmount: number | undefined;
  canNextPage: boolean;
  canPreviousPage: boolean;
  getNextPage: () => void;
  getPreviousPage: () => void;
  currentPageLength: number;
  pageMaxLength: number;
}

export const JitTablePagination: FC<Props> = ({
  pageIndex, totalRecordsAmount, canNextPage, currentPageLength,
  canPreviousPage, getNextPage, getPreviousPage, pageMaxLength,
}) => {
  const firstRecordIndex = pageIndex * pageMaxLength;
  let pageText = `${firstRecordIndex + 1} - ${firstRecordIndex + currentPageLength}`;
  if (totalRecordsAmount !== undefined) pageText += ` of ${totalRecordsAmount}`;

  return (
    <div className={styles.pagination}>
      <div className={styles.container}>
        <button className={styles.paginationButton} disabled={!canPreviousPage} onClick={getPreviousPage} type='button'>
          <JitIcon
            color={canPreviousPage ? colors.iris : colors.gray}
            icon={ChevronOpened}
            rotateDegrees={-90}
          />
        </button>

        <div className={styles.paginationIndex}>
          <JitText color={colors.lightGray} text={pageText} />
        </div>

        <button
          className={styles.paginationButton}
          data-testid='pagination-next-button'
          disabled={!canNextPage}
          onClick={getNextPage}
          style={{ cursor: canNextPage ? 'pointer' : 'inherit' }}
          type='button'
        >
          <JitIcon
            color={canNextPage ? colors.iris : colors.gray}
            icon={ChevronClosed}
            rotateDegrees={-90}
          />
        </button>
      </div>
    </div>
  );
};
