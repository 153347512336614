import { useMemo, useState } from 'react';

import { IMockPipelineNew } from 'types/interfaces/Pipelines/IPipelineNew';

export const useActivationFlowStateNew = () => {
  const [mockPipeline, setMockPipeline] = useState<IMockPipelineNew | null>(null);
  const [secondaryMockPipeline, setSecondaryMockPipeline] = useState<IMockPipelineNew | null>(null);
  const [showActivationPopper, setShowActivationPopper] = useState<boolean>(false);

  return useMemo(() => ({
    mockPipeline,
    setMockPipeline,
    secondaryMockPipeline,
    setSecondaryMockPipeline,
    showActivationPopper,
    setShowActivationPopper,
  }), [mockPipeline, setMockPipeline, secondaryMockPipeline, setSecondaryMockPipeline, showActivationPopper, setShowActivationPopper]);
};
