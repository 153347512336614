import { FC } from 'react';
import { TooltipProps } from 'recharts';

import { RESOLVED_FINDINGS_GRAPH_NAME } from '../../constants';

import styles from './TooltipFormatter.module.scss';

import { JitText } from 'components/JitText/JitText';
import { IFindingsOverTimeDay } from 'types/interfaces/Metrics/PerformanceMetrics/FindingsOvertime';
import { formatDate } from 'utils';

interface Props extends TooltipProps<string, string> {
  closestLineName: string | null;
}

export const TooltipFormatter: FC<Props> = ({ payload, closestLineName }) => {
  const hoveredPoint = payload?.find((point) => point.name === closestLineName)?.payload as IFindingsOverTimeDay | undefined;
  if (!hoveredPoint) return null;

  const toolTipContentItem = closestLineName === RESOLVED_FINDINGS_GRAPH_NAME ? {
    text: 'pages.performance.graphs.findingsOverTime.tooltip.resolvedFindings',
    value: hoveredPoint.resolvedFindings,
  } : {
    text: 'pages.performance.graphs.findingsOverTime.tooltip.detectedFindings',
    value: hoveredPoint.detectedFindings,
  };

  return (
    <div className={styles.tooltipWrapper}>
      <JitText muted size='xs' text={formatDate(hoveredPoint.date, false)} />

      <div key={toolTipContentItem.text} className={styles.tooltipFindingsAmount}>

        <JitText size='s' text={toolTipContentItem.text} />

        <JitText bold size='s' text={toolTipContentItem.value} />

      </div>
    </div>
  );
};
