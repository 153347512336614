import { FC, useMemo } from 'react';

import { InsightListCardItem } from '../InsightListCardItem';

import styles from './InsightListCard.module.scss';

import { JitEmpty } from 'components/JitEmpty/JitEmpty';
import { FindingStatus } from 'types/enums';
import { ISvg } from 'types/interfaces';
import { IInsightCategoryItem } from 'types/interfaces/Insights';

interface InsightListCardProps {
  items?: IInsightCategoryItem[];
  shouldDisplayStaleIndication: boolean;
  analyticsLinkType: string;
  emptyTitle: string;
  emptyDescription: string;
  boldFindingStatus: FindingStatus;
  listItemIcon: FC<ISvg>;
}

export const InsightListCard: FC<InsightListCardProps> = ({ items = [], shouldDisplayStaleIndication, analyticsLinkType, emptyTitle, emptyDescription, boldFindingStatus, listItemIcon }) => {
  const sortedItems = useMemo(() => items.sort((item1, item2) => Number(new Date(item1.timestamp)) - Number(new Date(item2.timestamp))), [items]);

  return (
    sortedItems.length ? (
      <div className={styles.insightsWrapper} data-testid='insightCategoryItems'>
        {sortedItems.map((item) => (
          <InsightListCardItem
            key={item.timestamp}
            analyticsLinkType={analyticsLinkType}
            boldFindingStatus={boldFindingStatus}
            data-testid={`insightListCardItem-${item.timestamp}`}
            icon={listItemIcon}
            item={item}
            shouldDisplayStaleIndication={shouldDisplayStaleIndication}
          />
        ))}
      </div>
    ) : (
      <div className={styles.jitEmptyWrapper}>
        <JitEmpty
          data-testid='insightCardJitEmpty'
          description={emptyDescription}
          title={emptyTitle}
        />
      </div>
    )
  );
};

