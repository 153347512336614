import { FC } from 'react';

import styles from './AssetsList.module.scss';

import { Asset } from 'pages/PipelinesPage/PipelineDetailsPage/components/PipelineWorkflows/Assets/Asset/Asset';
import { CollapseList } from 'pages/PipelinesPage/PipelineDetailsPage/components/PipelineWorkflows/CollapseList';
import { JitEventName } from 'types/enums';
import { IAssetNew } from 'types/interfaces/Pipelines/IPipelineNew';

interface AssetsListProps {
  assets: IAssetNew
  jitEventId: string;
  jitEventName: JitEventName
}

export const AssetsList: FC<AssetsListProps> = ({ assets, jitEventId, jitEventName }) => {
  const assetsCount = Object.keys(assets).length;

  const assetsElements = (
    Object.entries(assets).map(([assetSlug, asset]) => (
      <div key={assetSlug} className={styles.wrapper}>
        <Asset asset={asset} assetSlug={assetSlug} jitEventId={jitEventId} jitEventName={jitEventName} />
      </div>
    ))
  );

  return (
    <div data-testid='assets'>
      <CollapseList headerParams={{ assetsCount }} headerText='pages.pipelineDetails.workflows.resources' shouldCollapsedThreshold={1}>
        {assetsElements}
      </CollapseList>
    </div>
  );
};

