import {
  AWS_CONSOLE_CREATE_STACK_BASE_URL,
  AWS_ORG_STACK_NAME_PREFIX,
  AWS_ORG_STACK_TEMPLATE_NAME,
  AWS_STACK_NAME_PREFIX,
  AWS_STACK_TEMPLATE_NAME,
} from '../constants';

import { config } from 'config';
import { AwsIntegrationType } from 'types/enums';

export const getAwsAccountIntegrationCloudFormationLink = (externalId: string, integrationType: AwsIntegrationType | string): string => {
  const stackTemplateName = integrationType === AwsIntegrationType.Account ? AWS_STACK_TEMPLATE_NAME : AWS_ORG_STACK_TEMPLATE_NAME;
  const stackNamePrefix = integrationType === AwsIntegrationType.Account ? AWS_STACK_NAME_PREFIX : AWS_ORG_STACK_NAME_PREFIX;
  const envName = config.environmentName;
  const stackName = `${stackNamePrefix}${envName}`;
  const templateUrl = `https://jit-aws-${envName}.s3.amazonaws.com/${stackTemplateName}`;
  return `${AWS_CONSOLE_CREATE_STACK_BASE_URL}?stackName=${stackName}&templateURL=${templateUrl}&param_ExternalId=${externalId}`;
};
