import { useMemo } from 'react';

import { TextCell } from 'components/JitTable/templates';
import { i18n } from 'locale/i18n';
import { NameCell } from 'pages/TeamsPage/SubPages/TeamsPage/components/TeamsTable/Cells/NameCell/NameCell';
import { IWorkflow } from 'types/interfaces/Workflows/IWorkflow';
import { TableColumn } from 'types/types';

interface IColumnHeaders {
  name: string;
  description: string;
  runs: string;
  lastRun: string;
  createdBy: string;
  createdAt: string;
  lastModified: string;
  status: string;
  enabled: string;
}

export const useGetColumns = () => {
  const { t } = i18n;
  const columnHeaders: IColumnHeaders = useMemo(() => t('pages.workflows.workflowsListPage.table.columns', { returnObjects: true }), [t]);

  const columnsData = useMemo(() => {
    const columns = [
      {
        Header: columnHeaders.name,
        accessor: (workflow: IWorkflow) => ({
          name: workflow.name,
          description: workflow.description,
        }),
        Cell: NameCell,
        width: '30%',
      },
      {
        Header: columnHeaders.createdAt,
        accessor: 'createdAt',
        Cell: TextCell,
        width: '10%',
      },
      {
        Header: columnHeaders.createdBy,
        accessor: 'creatorUserName',
        Cell: TextCell,
        width: '10%',
      },
      {
        Header: columnHeaders.lastRun,
        accessor: 'lastRunAt',
        Cell: TextCell,
        width: '15%',
      },
      {
        Header: columnHeaders.status,
        accessor: 'status',
        Cell: TextCell,
        width: '10%',
      },
      {
        Header: columnHeaders.enabled,
        accessor: (workflow: IWorkflow) => (workflow.isEnabled ? 'Yes' : 'No'),
        Cell: TextCell,
        width: '10%',
      },
    ];
    return columns;
  }, [columnHeaders]);

  return {
    columns: columnsData as TableColumn<object>[],
  };
};
