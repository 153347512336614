import { FC, useState } from 'react';

import { Header } from './children/Header/Header';
import styles from './Priority.module.scss';

import { useJitSlidingPanelContext } from 'components/JitSlidingPanel/JitSlidingPanel';
import { PriorityFactorsGraph } from 'components/PriorityFactorsGraph/PriorityFactorsGraph';
import { IFinding } from 'types/interfaces';

interface Props {
  finding: IFinding;
}

export const Priority: FC<Props> = ({ finding }) => {
  const { isOpening: isInPanelOpenAnimation } = useJitSlidingPanelContext();

  const [isFullScreen, setIsFullScreen] = useState(false);

  return (
    <div className={styles.wrapper}>
      <Header finding={finding} setIsFullScreen={setIsFullScreen} />

      <div className={styles.graphWrapper}>
        <PriorityFactorsGraph entity={finding} isFullScreen={isFullScreen} isLoading={isInPanelOpenAnimation} setIsFullScreen={setIsFullScreen} />
      </div>
    </div>
  );
};
