
import { FC } from 'react';

import { MetadataItem } from './MetadataItem/MetadataItem';
import styles from './PipelineMetadata.module.scss';

import { CircleInfo } from 'assets';
import { i18n } from 'locale/i18n';
import {
  JOB_RUNNER_ICONS,
  EVENT_TO_PIPELINE_TYPE,
  SCOPES_DATA,
} from 'pages/PipelinesPage/constants';
import { getFormattedDuration, getFormattedTimeSince, isoFormatToDateFormat } from 'pages/PipelinesPage/utils';
import { getEnvironment, getJobRunner, getPrLink, getScope } from 'pages/PipelinesPage/utils/utilsNew';
import { PipelineTypes } from 'types/enums';
import { IPipelineDetailsMetadataItem, IPipelineNew } from 'types/interfaces/Pipelines';

interface Props {
  pipeline: IPipelineNew;
}

export const PipelineMetadata: FC<Props> = ({ pipeline }) => {
  const runnerData = getJobRunner(pipeline.workflows);
  const runnerItem: IPipelineDetailsMetadataItem = runnerData && {
    header: 'pages.pipelineDetails.metadata.runner',
    startIcon: JOB_RUNNER_ICONS.get(runnerData),
    tooltip: `Runner: ${i18n.t(`pages.pipelineDetails.metadata.runners.${runnerData}`)}`,
    dataTestId: 'runnerMetadataItem',
  };

  const scopeData = SCOPES_DATA?.[getScope(pipeline)];
  const scopeItem = {
    header: 'pages.pipelineDetails.metadata.scope',
    text: scopeData.text,
    endIcon: CircleInfo,
    iconSize: '10px',
    tooltip: scopeData.tooltip,
    dataTestId: 'scopeMetadataItem',
  };

  const prData = pipeline.additionalAttributes?.pullRequestTitle;
  const prItem = prData && {
    header: 'pages.pipelineDetails.metadata.pr',
    text: prData,
    link: getPrLink(pipeline),
    dataTestId: 'prMetadataItem',
  };

  const branchData = pipeline.additionalAttributes?.branch || pipeline.additionalAttributes?.branchName;
  const branchItem = branchData && {
    header: 'pages.pipelineDetails.metadata.branch',
    text: branchData,
    dataTestId: 'branchMetadataItem',
  };

  const startData = getFormattedTimeSince(pipeline.registeredAt);
  const startItem = startData && {
    header: 'pages.pipelineDetails.metadata.start',
    text: startData,
    tooltip: isoFormatToDateFormat(pipeline.registeredAt),
    dataTestId: 'startMetadataItem',
  };

  const durationData = getFormattedDuration(pipeline.duration);
  const durationItem = startData && durationData && {
    header: 'pages.pipelineDetails.metadata.duration',
    text: durationData,
    dataTestId: 'durationMetadataItem',
  };

  const initiatorData = pipeline.additionalAttributes?.userVendorId;
  const initiatorItem = initiatorData && {
    header: 'pages.pipelineDetails.metadata.initiator',
    startImgSrc: `https://avatars.githubusercontent.com/u/${initiatorData}`,
    text: pipeline.additionalAttributes?.userVendorName,
    dataTestId: 'initiatorMetadataItem',
  };

  const environmentData = pipeline.additionalAttributes?.environment || getEnvironment(pipeline.workflows);
  const environmentItem = environmentData && {
    header: 'pages.pipelineDetails.metadata.environment',
    text: environmentData,
    dataTestId: 'environmentMetadataItem',
    textStyle: { textTransform: 'capitalize' },
  };

  const deploymentLinkData = pipeline.additionalAttributes?.url;
  const deploymentLinkItem = deploymentLinkData && {
    header: 'pages.pipelineDetails.metadata.deployment',
    text: 'pages.pipelineDetails.metadata.deploymentAction',
    link: deploymentLinkData,
    dataTestId: 'deploymentMetadataItem',
  };

  const metadataItemsData = {
    [PipelineTypes.PR]: [
      prItem, branchItem, startItem, durationItem, scopeItem, runnerItem, initiatorItem,
    ],
    [PipelineTypes.DEPLOYMENT]: [
      environmentItem, deploymentLinkItem, branchItem, startItem, durationItem, scopeItem, runnerItem, initiatorItem,
    ],
    [PipelineTypes.SCHEDULER]: [
      startItem, durationItem, scopeItem, runnerItem,
    ],
  };

  const pipelineType = EVENT_TO_PIPELINE_TYPE?.[pipeline.jitEventName] || PipelineTypes.PR;
  const metadataItems = metadataItemsData?.[pipelineType].filter((e) => e);

  return (
    <div className={styles.metadataContainer} data-testid='PipelineMetadata'>

      {metadataItems.map((item) => item && <MetadataItem key={item.header} item={item} />)}

    </div>
  );
};

