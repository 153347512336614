import { FC } from 'react';

import styles from './PercentageLineProgress.module.scss';
import { getPercentageColor } from './utils';

import { JitText } from 'components/JitText/JitText';
import { LineProgress } from 'components/LineProgress/LineProgress';
import colors from 'themes/colors.module.scss';

interface Props {
  percentage: number;
  subtitle?: string;
  gap?: number;
}

export const PercentageLineProgress: FC<Props> = ({ percentage, subtitle, gap }) => {
  const gapSize = gap || 0;

  return (
    <div className={styles.wrapper} style={{ gap: gapSize }}>
      <JitText
        bold
        color={getPercentageColor(percentage)}
        size='huge'
        text={`${percentage}%`}
      />

      <div className={styles.bottomSection}>
        <LineProgress color={getPercentageColor(percentage)} percentage={percentage} />

        {subtitle && (
        <JitText
          color={colors.gray}
          size='s'
          text={subtitle}
        />
        )}
      </div>
    </div>
  );
};
