export interface IPaginatedState<D> {
  data: D[];
  isLoading: boolean;
  after?: string;
  hasReachedEnd: boolean;
}

export const initialPaginatedState: IPaginatedState<never> = {
  data: [],
  isLoading: false,
  hasReachedEnd: false,
};
