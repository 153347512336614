import { FC } from 'react';

import styles from './PipelineFindingDetails.module.scss';

import { JitCollapsibleText } from 'components/JitCollapsibleText/JitCollapsibleText';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { IFindingServer, ServerFindingKeys } from 'types/interfaces';
import { getCweIdText, getSolutionDisplayText, getTagsDisplayText } from 'utils/functions/findingsUtils';

interface Props {
  finding: IFindingServer;
}

export const AppFindingDetails: FC<Props> = ({ finding }) => {
  const tagsDisplay = getTagsDisplayText(finding[ServerFindingKeys.app_attributes]?.tags);
  const cweidDisplay = getCweIdText(finding[ServerFindingKeys.app_attributes]?.cweid);
  const solutionDisplay = getSolutionDisplayText(finding[ServerFindingKeys.app_attributes]?.solution);

  return (
    <>
      <JitText
        bold
        color={colors.lightGray}
        size='s'
        style={{ marginTop: '28px' }}
        text='pages.pipelineDetails.findingsDetails.findingContext'
      />

      <div className={styles.keyValueTextsContainer}>
        <JitText
          className={styles.headerTextWidth}
          color={colors.lightGray}
          size='s'
          text='pages.pipelineDetails.zapSpecificDetails.applicationName'
        />

        <JitText size='m' text={finding[ServerFindingKeys.asset_name]} />
      </div>

      <div className={styles.keyValueTextsContainer}>
        <JitText
          className={styles.headerTextWidth}
          color={colors.lightGray}
          size='s'
          text='pages.pipelineDetails.zapSpecificDetails.url'
        />

        <JitText size='m' text={finding[ServerFindingKeys.app_attributes]?.target_url} />
      </div>

      <div className={styles.keyValueTextsContainer}>
        <JitText
          className={styles.headerTextWidth}
          color={colors.lightGray}
          size='s'
          text='pages.pipelineDetails.zapSpecificDetails.paramUsedForAttack'
        />

        <JitText size='m' text={finding[ServerFindingKeys.app_attributes]?.param} />
      </div>

      <div className={styles.keyValueTextsContainer}>
        <JitText
          className={styles.headerTextWidth}
          color={colors.lightGray}
          size='s'
          text='pages.pipelineDetails.zapSpecificDetails.path'
        />

        <JitText size='m' text={finding[ServerFindingKeys.app_attributes]?.path} />
      </div>

      <div className={styles.keyValueTextsContainer}>
        <JitText
          className={styles.headerTextWidth}
          color={colors.lightGray}
          size='s'
          text='pages.pipelineDetails.zapSpecificDetails.method'
        />

        <JitText size='m' text={finding[ServerFindingKeys.app_attributes]?.method} />
      </div>

      <div className={styles.keyValueTextsContainer}>
        <JitText
          className={styles.headerTextWidth}
          color={colors.lightGray}
          size='s'
          text='pages.pipelineDetails.zapSpecificDetails.cwe'
        />

        <JitText size='m' text={cweidDisplay} />
      </div>

      {tagsDisplay && (
      <div className={styles.keyValueTextsContainer}>
        <JitText
          className={styles.headerTextWidth}
          color={colors.lightGray}
          size='s'
          text='pages.pipelineDetails.zapSpecificDetails.tags'
        />

        <JitText size='m' text={tagsDisplay} />
      </div>
      )}

      {finding[ServerFindingKeys.app_attributes]?.request_header && (
      <div style={{ marginTop: 30 }}>
        <JitText
          className={styles.headerTextWidth}
          color={colors.lightGray}
          size='s'
          text='pages.pipelineDetails.zapSpecificDetails.requestHeader'
        />

        <JitCollapsibleText key={`${finding.id}-request-header-collapse`} text={finding[ServerFindingKeys.app_attributes]?.request_header || ''} />
      </div>

      )}

      {finding[ServerFindingKeys.app_attributes]?.response_header && (
      <div style={{ marginTop: 30 }}>
        <JitText
          className={styles.headerTextWidth}
          color={colors.lightGray}
          size='s'
          text='pages.pipelineDetails.zapSpecificDetails.responseHeader'
        />

        <JitCollapsibleText key={`${finding.id}-response-header-collapse`} text={finding[ServerFindingKeys.app_attributes]?.response_header || ''} />
      </div>
      )}

      {finding[ServerFindingKeys.app_attributes]?.solution && (
      <div style={{ marginTop: 30 }}>
        <JitText
          className={styles.headerTextWidth}
          color={colors.lightGray}
          size='s'
          text='pages.pipelineDetails.zapSpecificDetails.solution'
        />

        <JitCollapsibleText key={`${finding.id}-solution`} text={solutionDisplay} />
      </div>
      )}
    </>
  );
};
