import { IConfigurations } from 'types/interfaces';

export const branchProtectionConfigurationValidator = (configurations: IConfigurations) => {
  if (!configurations.github_branch_protection) {
    return false;
  }

  const orgLevelConfig = configurations.github_branch_protection.organization;

  return (!!orgLevelConfig && (orgLevelConfig.amount_of_required_reviews > 0 || orgLevelConfig.required_status_checks.length > 0));
};
