/* eslint-disable react/no-multi-comp */
import { useMemo } from 'react';

import styles from '../components/QuickGuideSection/QuickGuideSection.module.scss';

import { ArrowRight } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

export const rightStyle = {
  justifyContent: 'start',
  marginLeft: 30,
};

const leftStyle = {
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'flex-start',
  display: 'flex',
};

export const useQuickGuideSectionComponents = (
  isExpanded: boolean,
  sectionColor: string,
  title: string,
  isAllCompleted: boolean,
) => {
  const leftComponent = useMemo(() => ({
    component: () => (
      <JitIcon
        color={sectionColor}
        data-testid='quick-guide-section-arrow'
        icon={ArrowRight}
        rotateDegrees={isExpanded ? 90 : 0}
      />
    ),
    style: leftStyle,
  }), [sectionColor, isExpanded]);

  const centerComponent = useMemo(() => ({
    component: () => (
      <JitText
        bold
        color={sectionColor}
        data-testid='quick-guide-section-title'
        text={title}
      />
    ),
  }), [sectionColor, title]);

  const rightComponent = useMemo(() => ({
    component: () => (isAllCompleted && !isExpanded ? (
      <div className={styles.completedText}>
        <JitText
          bold
          color={colors.successGreen02}
          data-testid='quick-guide-section-completed'
          text='pages.quickStart.section.completed'
        />
      </div>
    ) : null),
    style: rightStyle,
  }), [isAllCompleted, isExpanded]);

  const rowComponents = useMemo(() => [{
    left: leftComponent,
    center: centerComponent,
    right: rightComponent,
  }], [leftComponent, centerComponent, rightComponent]);

  return { rowComponents,
    rightStyle };
};
