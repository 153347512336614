import { createContext, useContext } from 'react';

import { GithubStatus } from 'types/enums/GithubStatus';

interface IGithubContext {
  githubStatus?: GithubStatus;
  isLoadingGithubStatus: boolean;
}

export const GithubContext = createContext<IGithubContext>({
  isLoadingGithubStatus: false,
});

export const useGithubContext = () => useContext(GithubContext);
