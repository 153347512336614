import { FC, PropsWithChildren } from 'react';

import styles from './TableGradient.module.scss';

export const TableGradient: FC<PropsWithChildren> = ({ children }) => (
  <div className={styles.root} data-testid='table-gradient'>
    <div className={styles.gradient}>
      {children}
    </div>
  </div>
);
