import { FC, ReactElement, useCallback, useMemo } from 'react';

import { DataOverview } from '../DevProductivity/components/DataOverview/DataOverview';

import styles from './SecurityImpactCard.module.scss';

import { ArrowRight } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

export interface DataOverviewItem {
  title: string;
  description?: string;
  content: ReactElement;
  titleTestID?: string;
  descriptionTestID?: string;
  actionItemText?: string;
  actionItemOnClick?: () => void;
}

interface Props {
  title: string;
  description: ReactElement;
  dataOverviewItems: DataOverviewItem[];
  expandedContent?: ReactElement;
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>
}

export const SecurityImpactCard: FC<Props> = ({ title, description, dataOverviewItems, expandedContent, isExpanded, setIsExpanded }) => {
  const isExpandable = useMemo(() => !!expandedContent, [expandedContent]);

  const toggleExpand = useCallback(() => {
    if (isExpandable) {
      setIsExpanded((prev) => !prev);
    }
  }, [isExpandable, setIsExpanded]);

  const titleContent = useMemo(() => {
    const titleProps = isExpandable ? {
      role: 'button',
      tabIndex: 0,
      onClick: toggleExpand,
    } : {};

    return (
      <div className={isExpandable ? styles.expandedTitle : ''}>

        {isExpandable && <JitIcon color={colors.iris} icon={ArrowRight} onClick={toggleExpand} rotateDegrees={!isExpanded ? 0 : 90} />}

        <div {...titleProps}>
          <JitText bold color={isExpandable ? colors.iris : colors.white} data-testid={`security-impact-card-title-${title}`} size='xxl' text={title} />
        </div>
      </div>
    );
  }, [toggleExpand, isExpandable, isExpanded, title]);

  return (
    <div className={isExpanded ? styles.wrapper : styles.notExpandedWrapper} data-testid={`SecurityImpactCard-${title}`}>
      <div className={styles.cardContent}>

        <div className={styles.titleDescriptionWrapper}>
          {titleContent}

          {description}
        </div>

        <div className={styles.dataOverviewItemsWrapper}>
          <div className={styles.dataOverviewItemsPadding}>
            {dataOverviewItems.map((item) => (
              <div key={item.title} className={styles.dataSection}>
                <DataOverview
                  actionItemOnClick={item.actionItemOnClick}
                  actionItemText={item.actionItemText}
                  content={item.content}
                  description={item.description}
                  descriptionTestID={item.descriptionTestID}
                  title={item.title}
                  titleTestID={item.titleTestID}
                />
              </div>
            ))}
          </div>
        </div>

      </div>

      {isExpanded && expandedContent}
    </div>
  );
};
