import { Collapse } from '@mui/material';
import { FC, ReactElement, useState } from 'react';

import styles from './CollapseSection.module.scss';

import { ExpandArrow } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import colors from 'themes/colors.module.scss';

interface Props {
  header: ReactElement;
  children: ReactElement;
  testId?: string;
  withBackground?: boolean;
  defaultExpanded?: boolean;
}

export const CollapseSection: FC<Props> = ({ header, children, testId, withBackground, defaultExpanded }) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const toggleExpand = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const headerClasses = withBackground
    ? `${styles.header} ${styles.withBackground} ${isExpanded ? styles.expanded : ''}`
    : styles.header;

  return (
    <div data-testid={testId}>
      <div className={headerClasses} onClick={toggleExpand} role='button' tabIndex={0}>
        <JitIcon color={colors.iris} icon={ExpandArrow} iconClassName={styles.expandIcon} rotateDegrees={isExpanded ? 0 : -90} size={10} />

        {header}
      </div>

      <Collapse in={isExpanded}>
        <div className={styles.collapse}>
          {isExpanded && children}
        </div>
      </Collapse>
    </div>
  );
};
