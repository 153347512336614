import { FC } from 'react';

import { JitButton } from 'components/JitButton/JitButton';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { IFindingFilter } from 'types/interfaces';

interface Props {
  clearFilters: () => void;
  visibleFilters: IFindingFilter[];
}

export const FindingsFiltersClearButton: FC<Props> = ({
  clearFilters,
  visibleFilters,
}) => (
  <JitButton
    data-testid='clear-filters-button'
    disableRipple
    noHover
    onClick={clearFilters}
  >
    <JitText
      color={visibleFilters.length > 0 ? colors.blue02 : colors.gray}
      size='l'
      style={{
        textDecorationColor: colors.darkGray,
      }}
      text='pages.findings.filtersSection.clearFilters'
    />

  </JitButton>
);
