import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';

import { ConfigurationsZapField } from './ConfigurationsZapField';
import styles from './FormBasedAuthConfigurations.module.scss';

import { ConfigurationsZapWebPassword } from 'components/Configurations/Zap/components/ConfigurationsZapWebPassword';
import {
  AUTH_KEY,
  AUTH_VALUE,
  AUTHORIZATION_HEADER,
  BEARER,
  BEARER_AUTH_INPUT_FIELD,
  COOKIE_HEADER,
  COOKIE_HEADER_INPUT_FIELD,
  DEFAULT_AUTH_INPUT_FIELD,
} from 'components/Configurations/Zap/components/constants';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { ZapApplicationHeaderAuth, ZapAuthenticationConfigType } from 'types/interfaces/Configurations/IApplicationConfiguration';

export enum HeaderType {
  Custom = 'custom',
  Bearer = 'bearer',
  Cookie = 'cookie',
}

interface Props {
  configurations: ZapApplicationHeaderAuth;
  handleChange: (key: string, value: string) => void;
  headerType: HeaderType;
  type: ZapAuthenticationConfigType;
}

export const CustomHeaderAuthConfigurations: FC<Props> = ({ configurations, handleChange, headerType, type }) => {
  const {
    authentication_key: authenticationKey,
    authentication_value: authenticationValue,
  } = configurations;
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (headerType === HeaderType.Cookie && authenticationKey !== COOKIE_HEADER) {
      handleChange(AUTH_KEY, COOKIE_HEADER);
    } else if (headerType === HeaderType.Bearer && authenticationKey !== AUTHORIZATION_HEADER) {
      handleChange(AUTH_KEY, AUTHORIZATION_HEADER);
    }
  }, [authenticationKey, handleChange, headerType]);

  const handleHeaderValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleChange(AUTH_VALUE, event.target.value);
  };

  const buildHeaderPreviewText = () => {
    const passwordPreview = (!showPassword && !!authenticationValue) ? '********' : authenticationValue;

    // Prepend 'Bearer ' in the preview to clarify that the JWT should not include the 'Bearer ' prefix in the input's value.
    const formattedPasswordPreview = (headerType === HeaderType.Bearer && passwordPreview !== '********')
      ? `${BEARER} ${passwordPreview}`
      : passwordPreview;

    return `"${authenticationKey}": ${formattedPasswordPreview}`;
  };

  const onShowPasswordChange = (newShowPassword: boolean) => {
    setShowPassword(newShowPassword);
  };

  const authHeaderInputField = useMemo(() => {
    if (headerType === HeaderType.Bearer) {
      return BEARER_AUTH_INPUT_FIELD;
    }
    if (headerType === HeaderType.Cookie) {
      return COOKIE_HEADER_INPUT_FIELD;
    }
    return DEFAULT_AUTH_INPUT_FIELD;
  }, [headerType]);

  const handleChangeHeaderName = (event: ChangeEvent<HTMLInputElement>) => {
    handleChange(AUTH_KEY, event.target.value);
  };

  const computedPassword = useMemo(
    () => (headerType === HeaderType.Bearer
      ? authenticationValue?.replace('Bearer ', '') || ''
      : authenticationValue),
    [headerType, authenticationValue],
  );

  return (
    <>
      <div className={styles.credentialsWrapper}>

        {(headerType === HeaderType.Custom) && (
          <ConfigurationsZapField
            handleChangeInput={handleChangeHeaderName}
            inputField='auth_header_name'
            inputValue={authenticationKey}
            type={type}
          />
        )}

        {/* Change to password field */}

        <ConfigurationsZapWebPassword
          handleChange={(_, value) => handleChange(AUTH_VALUE, value)}
          handleChangeInput={handleHeaderValueChange}
          inputField={authHeaderInputField}
          isMultiLine={headerType !== HeaderType.Custom}
          onShowPasswordChange={onShowPasswordChange}
          password={computedPassword}
          type={type}
        />
      </div>

      <div className={styles.previewHeader}>
        <JitText bold text='configurations.zap.headerAuthConfigurations.headerPreviewTitle' />

        {!authenticationValue ? (
          <JitText color={colors.gray} text='configurations.zap.headerAuthConfigurations.headerPreviewPlaceholder' />
        ) : (
          <div>
            <JitText color={colors.gray} text='{' />

            <JitText className={styles.headerPreviewText} color={colors.gray} text={buildHeaderPreviewText()} />

            <JitText color={colors.gray} text='}' />
          </div>
        )}
      </div>

    </>
  );
};
