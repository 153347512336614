import { FC } from 'react';

import styles from './GeneralErrorPage.module.scss';

import { JittyEmpty } from 'assets';
import { GeneralPage } from 'components/GeneralPage';
import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { ISvg } from 'types/interfaces';
import { ActionButton } from 'types/interfaces/ActionButton/ActionButton';

interface Props {
  icon?: FC<ISvg>;
  title?: string;
  description?: string;
  isFullPage?: boolean;
  backgroundColor?: string;
  actionButtons?: ActionButton[];
}

export const GeneralErrorPage: FC<Props> = ({ backgroundColor, actionButtons, icon = JittyEmpty, title, description, isFullPage = true }) => (
  <GeneralPage backgroundColor={backgroundColor} isFullPage={isFullPage}>

    <JitIcon className={styles.jittyIcon} data-testid='GeneralErrorPage-icon' icon={icon} size={100} />

    <div className={styles.texts}>
      <JitText
        align='center'
        bold
        className={styles.text}
        data-testid='GeneralErrorPage-title'
        size='l'
        text={title}
      />

      <JitText
        align='center'
        className={styles.text}
        data-testid='GeneralErrorPage-description'
        size='m'
        style={{ marginTop: 6 }}
        text={description}
      />
    </div>

    <div className={styles.buttons}>
      {actionButtons?.map((actionButton: ActionButton) => <JitActionButton key={`actionButton-${actionButton.label}`} actionButton={actionButton} />)}
    </div>
  </GeneralPage>
);
