import React from 'react';

import styles from './FindingsOverTimeProgress.module.scss';

import { JitText } from 'components/JitText/JitText';
import { LineProgress } from 'components/LineProgress/LineProgress';
import colors from 'themes/colors.module.scss';
import { ITotalFindingsDay } from 'types/interfaces/Metrics/SecurityImpactMetrics/TotalFindingsGraph';

interface FindingsOverTimeProgressProps {
  currentDay: ITotalFindingsDay;
}

export const FindingsOverTimeProgress: React.FC<FindingsOverTimeProgressProps> = ({ currentDay }) => {
  const percentage = (currentDay.resolvedFindings / currentDay.detectedFindings) * 100;

  return (
    <div>
      <div className={styles.totalFindingsNumber}>
        <JitText bold color={colors.performanceGreen} size='l' text={`${currentDay.resolvedFindingsDisplay}`} />

        <JitText color={colors.gray} size='l' text='/' />

        <JitText color={colors.gray} size='l' text={`${currentDay.detectedFindingsDisplay}`} />
      </div>

      <LineProgress color={colors.performanceGreen} emptySectionColor={colors.failRed} percentage={percentage} width='100%' />
    </div>
  );
};
