import { FC, useState } from 'react';
import { NodeProps, Handle, Position } from 'reactflow';

import styles from './CustomNode.module.scss';
import { CustomNodeData } from './types';
import { getExternalLink, getVendorFactors, getVendorFormattedFactorText, getVendorIcon, isExternalVendorNode } from './utils/vendorUtils';
import { EXTRA_HEIGHT_NODE_WITH_FACTOR, NODE_WIDTH, NODE_HEIGHT, getTextWidth } from './utils/visualGraphUtils';

import { Cross } from 'assets';
import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { useGraphContext } from 'context/GraphContext/GraphContext';
import { useAddRemoveFactors } from 'context/GraphContext/hooks/useAddRemoveFactors';
import colors from 'themes/colors.module.scss';
import { IPriorityFactor } from 'types/interfaces/Graph/IPriorityFactor';
import { hexColorToRGBA } from 'utils';

const EXTERNAL_VENDOR_COLOR = '#0079f7';

export const CustomNode: FC<NodeProps<CustomNodeData>> = ({ data }) => {
  const {
    icon,
    type,
    name,
    factors,
    entity,
    priorityFields,
  } = data;
  const { removeFactors } = useAddRemoveFactors();
  const { allPriorityFactors } = useGraphContext();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const entityFactors = priorityFields?.priorityFactors || [];

  const entityFactorsAfterEdits = (factors?.filter((factor) => entityFactors.includes(factor))
    || []).map((factor) => allPriorityFactors.find((f) => f.key === factor))
    .filter((factor): factor is NonNullable<typeof factor> => factor
      !== undefined);

  const hasFactors = entityFactorsAfterEdits.length > 0;

  const handleRemoveFactor = async (factor: IPriorityFactor) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    await removeFactors(priorityFields, entity, [factor]);
    setIsSubmitting(false);
  };

  const externalLink = getExternalLink(data);
  const vendorFactors = getVendorFactors(data);

  const vendorIcon = getVendorIcon(data);

  let borderColor: string;
  if (isExternalVendorNode(data)) {
    borderColor = EXTERNAL_VENDOR_COLOR;
  } else if (hasFactors) {
    borderColor = colors.criticalPriority;
  } else {
    borderColor = colors.priorityFactorGraphDefaultNodeBorder;
  }

  let nodeBackgroundColor;
  if (isExternalVendorNode(data)) {
    nodeBackgroundColor = hexColorToRGBA(EXTERNAL_VENDOR_COLOR, 0.3);
  } else {
    nodeBackgroundColor = colors.priorityFactorGraphDefaultNodeBg;
  }

  const getFactorStyle = (index: number, factorText: string, isEditable: boolean) => {
    // Width = text width + padding + remove icon width
    const textWidth = getTextWidth(factorText, 14);
    const nodePaddingWidth = 20;
    const removeIconWidth = isEditable ? 20 : 0;

    const evaluatedWidth = textWidth + nodePaddingWidth + removeIconWidth;
    const minimalWidth = NODE_WIDTH * 0.9;
    const factorWidth = Math.max(evaluatedWidth, minimalWidth);

    const factorWithPaddingHeight = EXTRA_HEIGHT_NODE_WITH_FACTOR + 6;
    const paddingFromParentNode = 8;
    const bottom = -(factorWithPaddingHeight) * (index + 1) + paddingFromParentNode;
    return {
      width: factorWidth,
      left: (NODE_WIDTH - factorWidth) / 2,
      borderColor,
      bottom,
    };
  };
  return (
    <div
      className={styles.customNode}
      data-testid={`customGraphNode-${type}-${name}`}
      style={{
        width: NODE_WIDTH,
        height: NODE_HEIGHT,
        borderColor,
        background: nodeBackgroundColor,
      }}
    >
      <div className={styles.iconContainer} style={{ backgroundColor: icon.bgColor }}>
        <JitIcon color={colors.white} icon={icon.icon} size={16} strokeColor={colors.white} />
      </div>

      <div className={styles.textContainer} style={{ maxWidth: NODE_WIDTH }}>
        <TooltipOnlyOnOverflow bold noWrap overflowHiddenEllipsis size='s' text={type} />

        <TooltipOnlyOnOverflow className={styles.subText} muted noWrap overflowHiddenEllipsis size='xs' text={name} />
      </div>

      {entityFactorsAfterEdits.map((factor, index) => (
        <div
          key={factor.displayName}
          className={`${styles.factorsBox} ${styles.factorTextIconWrapper}`}
          style={getFactorStyle(index, factor.displayName, factor.group.isEditable)}
        >
          <JitText align='center' muted noWrap size='s' text={factor.displayName} />

          {factor.group.isEditable && (
            <JitTooltip followCursor={false} placement='top' title='pages.findings.findingDetails.priority.addRemoveFactors.removeIconTooltip'>
              <JitIcon
                color={colors.cards}
                icon={Cross}
                onClick={() => handleRemoveFactor(factor)}
                size={10}
                withBackSquare={{
                  backgroundColor: colors.iris,
                  borderRadius: '50%',
                  height: 14,
                  width: 14,
                  borderColor: 'transparent',
                }}
              />
            </JitTooltip>
          )}
        </div>
      ))}

      {vendorFactors.map((factor, index) => (
        <div
          key={factor}
          className={`${styles.factorsBox} ${styles.vendorFactorsWrapper}`}
          style={getFactorStyle(index + entityFactorsAfterEdits.length, factor, false)}
        >

          {vendorIcon && <JitIcon className={styles.vendorIcon} icon={vendorIcon} size={16} />}

          <JitText muted noWrap size='s' text={getVendorFormattedFactorText(factor)} />

        </div>
      ))}

      {externalLink && (
        <div className={styles.externalLink}>
          <JitExternalLink
            analytics={{
              action: `open-external-link-${externalLink.split('?')[0]}`,
              params: { url: externalLink },
            }}
            className={styles.externalLink}
            href={externalLink}
            size='s'
            text=''
          />
        </div>
      )}

      <Handle position={Position.Left} style={{ visibility: 'hidden' }} type='target' />

      <Handle position={Position.Right} style={{ visibility: 'hidden' }} type='source' />
    </div>
  );
};
