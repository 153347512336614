export const AWS_INTEGRATION_VERSION = '2';
export const AWS_VERSION_ERROR_TOAST_ID = 'aws-version-error-toast';
export const UPGRADE_PROWLER_DOCS = 'https://docs.jit.io/docs/upgrading-from-prowler-2-to-prowler-3';
export const INITIAL_ONBOARDING_CHECK_SLUG = 'initial_onboarding_completed';
export const SCM_INTEGRATION_CHECK_SLUG = 'integrate_scm';
export const CONFIGURE_CLOUD_INTEGRATION_CHECK_SLUG = 'configure_cloud_integration';
export const CONFIGURE_TEAMS_CHECK_SLUG = 'configure_teams';
export const INTEGRATE_SLACK_CHECK_SLUG = 'integrate_slack';
export const EXPLORE_APPSEC_PLAN_CHECK_SLUG = 'explore_appsec_plan';
// TODO: using the check data instead of constants. https://app.shortcut.com/jit/story/27042/tech-debt-webapp-send-checks-into-main-components?vc_group_by=day&ct_workflow=all&cf_workflow=500021836
