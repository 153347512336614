import { t } from 'i18next';
import { useMemo } from 'react';

import { useGetPlanItemConfigurationsStatus, useHasConfigurationsChanged } from 'context/ConfigurationsContext/hooks';
import { useTenantContext } from 'context/TenantContext';
import { IPlanItemDetails } from 'types/interfaces';

export const UseGetPlanItemDisabledTooltipText = (planItem: IPlanItemDetails) => {
  const { isAnyScmVendorIntegrated } = useTenantContext();
  const { isIntegrated, isConfigurationMissing, getMissingIntegrations, isConfigurable } = useGetPlanItemConfigurationsStatus();
  const { hasConfigurationsChanged } = useHasConfigurationsChanged();

  const disabledTooltipText = useMemo(() => {
    if (!isAnyScmVendorIntegrated) return t('pages.plan.planItem.config.scmIntegrationRequired');
    if (isConfigurationMissing(planItem)) {
      if (!isIntegrated(planItem) && !isConfigurable(planItem)) {
        const missingIntegrations = getMissingIntegrations(planItem);
        return t('pages.plan.planItem.config.integrationRequired', { missingIntegrations });
      }
      if (hasConfigurationsChanged(planItem.slug)) return 'pages.plan.planItem.config.applyChangesFirst';

      return '';
    }
    return '';
  }, [isAnyScmVendorIntegrated, planItem, isConfigurationMissing, isIntegrated, isConfigurable, getMissingIntegrations, hasConfigurationsChanged]);

  return { disabledTooltipText };
};
