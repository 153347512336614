import { Dispatch, SetStateAction, useMemo } from 'react';

import actionsStyles from '../actionComponents.module.scss';

import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';
import { ITeamCheckDetails, TaskKeyEnum } from 'types/interfaces/Teams/ITeam';

export const useGetActivateJitAction = (
  setOpenedDialogKey: Dispatch<SetStateAction<string | null>>,
  check?: ITeamCheckDetails,
) => {
  const activateButton = useMemo(() => (
    <JitGradientButton
      data-testid={`action-${TaskKeyEnum.prCheckEnablement}`}
      onClick={() => setOpenedDialogKey(TaskKeyEnum.prCheckEnablement)}
    >
      <div className={actionsStyles.gradientButton}>
        <JitText size='s' text={check?.options?.button?.label} />
      </div>
    </JitGradientButton>
  ), [check?.options?.button?.label, setOpenedDialogKey]);

  const actionButton = useMemo(() => (check?.isCompleted ? undefined : activateButton), [check?.isCompleted, activateButton]);

  return ({ actionButton });
};
