import { FC, useState } from 'react';

import { Activities } from './Activities/Activities';
import { ISegment, SegmentId } from './interfaces/ISegment';

export const Segment: FC<ISegment> = ({
  id,
  isTheLoopHovered,
  activities,
  colors: segmentColors,
  settings,
  path,
  textPath,
  onSegmentClick,
  onSegmentMouseEnter,
  onSegmentMouseLeave,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { tooltip, gradient, side } = settings;

  const handleMouseEnter = (e: React.MouseEvent) => {
    setIsHovered(true);

    onSegmentMouseEnter({
      id: e.currentTarget.id as SegmentId,
      activities,
      side,
    });
  };

  const handleMouseLeave = (e: React.MouseEvent) => {
    setIsHovered(false);
    onSegmentMouseLeave(e);
  };

  const stops = [
    {
      offset: '0',
      stopColor: segmentColors.base,
      stopOpacity: isTheLoopHovered ? '0.1' : '0.2',
    },
    {
      offset: '1',
      stopColor: segmentColors.base,
      stopOpacity: isTheLoopHovered ? '0.3' : '1',
    },
  ];

  const isActive = isHovered && activities.length > 0;

  const textColor = isTheLoopHovered && !isHovered ? `${segmentColors.text}4D` : segmentColors.text;

  return (
    <>
      <g
        data-testid='segment'
        id={id}
        onClick={onSegmentClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <path
          clipRule='evenodd'
          d={path}
          data-testid='segment-path'
          fill={isHovered ? segmentColors.base : `url(#${id}_segment_gradient)`}
          fillRule='evenodd'
        />

        <path
          d={textPath}
          data-testid='segment-text-path'
          fill={textColor}
        />
      </g>

      {isActive ? (
        <foreignObject
          data-testid='activities'
          height={tooltip.height}
          width={tooltip.width}
          x={tooltip.position.x}
          y={tooltip.position.y}
        >
          <Activities
            activities={activities}
            data-testid='activities'
            placement={tooltip.placement}
          />
        </foreignObject>
      ) : null}

      <defs>
        <linearGradient
          data-testid='segment-gradient'
          gradientUnits='userSpaceOnUse'
          id={`${id}_segment_gradient`}
          x1={gradient.coordinates.x1}
          x2={gradient.coordinates.x2}
          y1={gradient.coordinates.y1}
          y2={gradient.coordinates.y2}
        >
          {stops.map((stop) => (
            <stop
              key={stop.offset}
              offset={stop.offset}
              stopColor={stop.stopColor}
              stopOpacity={stop.stopOpacity}
            />
          ))}
        </linearGradient>
      </defs>
    </>
  );
};
