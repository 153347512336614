import { FC } from 'react';

import { SelectionListAccordion } from './SelectionListAccordion';

import { IAsset } from 'types/interfaces';

interface Props {
  assets: IAsset[];
  checkAsset: (assetId: string, checked: boolean) => void;
  checkMultipleAssetsByOwner: (owner: string, checked: boolean) => void;
  vendor: string;
}

export const SelectionListCustomSCM: FC<Props> = ({
  assets,
  checkAsset,
  checkMultipleAssetsByOwner,
  vendor,
}) => (
  <SelectionListAccordion
    accordionOwnerTypeTitle={`dialogs.assetsManagement.topRow.${vendor}`}
    accordionTableAssetColumnHeader={`dialogs.assetsManagement.accordion.${vendor}`}
    assets={assets}
    checkAsset={checkAsset}
    checkMultipleAssetsByOwner={checkMultipleAssetsByOwner}
  />
);
