import { FC } from 'react';

import styles from './IntegrationDetailsExistingBox.module.scss';

import { JitText } from 'components/JitText/JitText';

interface Props {
  keyText: string;
  valueText: string | string[];
}

export const IntegrationDetailsExistingBox: FC<Props> = ({ keyText, valueText }) => {
  const valueTextSplitted = String(valueText).split(',').join(', ');
  const showBullet = keyText === 'account_id';

  return (
    <div
      key={keyText}
      className={styles.wrapper}
    >
      <JitText
        bold
        {...(showBullet && {
          style: {
            display: 'list-item',
            alignSelf: 'start',
          },
        })}
        text={`dialogs.integration.aws.fields.${keyText}.label`}
      />

      <JitText
        text={`: ${valueTextSplitted}`}
      />
    </div>
  );
};
