import { FC } from 'react';

import styles from '../GraphHeader.module.scss';

import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  displayDetectedLine: boolean;
  setDisplayDetectedLine: (value: boolean) => void;
  displayResolvedLine: boolean;
  setDisplayResolvedLine: (value: boolean) => void;
}

export const GraphHeaderCheckboxes: FC<Props> = ({
  displayDetectedLine,
  setDisplayDetectedLine,
  displayResolvedLine,
  setDisplayResolvedLine,
}) => (
  <div className={styles.checkboxesWrapper}>
    <div className={styles.checkboxWrapper}>
      <JitCheckbox
        checked={displayDetectedLine}
        data-testid='detectedRateGraphHeaderCheckbox'
        onChange={() => setDisplayDetectedLine(!displayDetectedLine)}
      />

      <JitText bold color={colors.findingsOverTimeDetectedLineColor} text='pages.performance.graphs.findingsOverTime.checkboxes.detected' />
    </div>

    <div className={styles.checkboxWrapper}>
      <JitCheckbox
        checked={displayResolvedLine}
        data-testid='resolvedRateGraphHeaderCheckbox'
        onChange={() => setDisplayResolvedLine(!displayResolvedLine)}
      />

      <JitText bold color={colors.findingsOverTimeResolvedLineColor} text='pages.performance.graphs.findingsOverTime.checkboxes.resolved' />
    </div>
  </div>
);
