import { FC } from 'react';

import styles from './DevEfficiencyCalculationWidget.module.scss';

import { CircularPercentage } from 'components/CircularPercentage/CircularPercentage';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { formatMoneyToDisplayText } from 'utils/functions/formatMoney';

interface Props {
  maxMoneySaved: number;
  moneySaved: number;
  timeSaved: string;
}

export const DevEfficiencyCalculationWidget: FC<Props> = ({ maxMoneySaved, moneySaved, timeSaved }) => {
  const displayMoneySaved = formatMoneyToDisplayText(moneySaved);
  const percentageSaved = (moneySaved / maxMoneySaved) * 100;
  const content = (
    <div className={styles.widgetContent}>
      <JitText bold className={styles.topText} size='xl' text={timeSaved} />

      <div className={styles.bottomTextWrapper}>
        <JitText bold color={colors.performanceGreen} size='xxxl' text={`= $${displayMoneySaved}`} />
      </div>
    </div>
  );

  return (
    <div className={styles.devEfficiencyCalculationWidget}>
      <CircularPercentage
        color={moneySaved !== 0 ? colors.performanceGreen : colors.white}
        content={content}
        filledSectionThickness={3}
        size={165}
        value={percentageSaved}
      />
    </div>
  );
};
