import { FC } from 'react';

import styles from './SelectedFindings.module.scss';

import { Minus } from 'assets';
import { CreateTicketButton } from 'components/CreateTicketButton';
import { useFindingTableContext } from 'components/FindingsTable/FindingsTableContext/FindingsTableContext';
import { JitButton } from 'components/JitButton/JitButton';
import { JitCopyToClipboardBox } from 'components/JitCopyToClipboardBox/JitCopyToClipboardBox';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { ToggleIgnoreFinding } from 'pages/FindingsPage/components/ToggleIgnoreFinding/ToggleIgnoreFinding';
import { generateMarkdown } from 'pages/FindingsPage/utils/generateMarkdown';
import colors from 'themes/colors.module.scss';
import { TicketFinding } from 'types/interfaces';

interface Props {
}

export const FindingsSelectedFindings: FC<Props> = () => {
  const {
    findings,
    resetSelectedFindings,
    onSetIsIgnoredToSelectedRows,
    selectedRow,
    setTicketUrlOnFindings,
    selectedFindingIds,
  } = useFindingTableContext();
  const isRowOpen = !!selectedRow;
  const onSetIsIgnored = onSetIsIgnoredToSelectedRows;
  const selectedFindingsAmount = selectedFindingIds.length;
  const selectedFindings = findings.filter((finding) => selectedFindingIds.includes(finding.id));
  const ignoredValues = selectedFindings.map((finding) => finding.ignored);
  const initialStatus = ignoredValues?.[0];

  const handleTicketCreated = (ticket?: TicketFinding) => {
    if (ticket) {
      setTicketUrlOnFindings(selectedFindings, ticket);
    }
    resetSelectedFindings();
  };

  return (
    <div
      className={styles.wrapper}
      style={{ display: isRowOpen ? 'none' : 'flex' }}
    >
      <div className={styles.amountSelected}>
        <JitButton
          data-testid='findings-reset-selected-button'
          onClick={resetSelectedFindings}
          style={{ backgroundColor: colors.blue }}
        >
          <JitIcon icon={Minus} size={16} />

        </JitButton>

        <JitText bold size='xl' style={{ alignSelf: 'center' }} text={selectedFindingsAmount} />

        <JitText bold size='l' style={{ alignSelf: 'center' }} text='pages.findings.selectedFindings.findingSelected' />

      </div>

      <div className={styles.rowActions}>
        <CreateTicketButton findings={selectedFindings} onClose={handleTicketCreated} />

        <ToggleIgnoreFinding isIgnored={initialStatus} onSetIsIgnored={onSetIsIgnored} />

        <JitCopyToClipboardBox displayText='' iconSize={14} text={generateMarkdown(selectedFindings)} variant='outlined' />
      </div>
    </div>
  );
};
