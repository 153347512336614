import { constants } from 'globalConstants';
import { NonStandardTimeAgo, TimeAgo } from 'types/enums';
import { formatDate } from 'utils';

export const timeAgoToTime = { // In milliseconds
  [TimeAgo.OneWeek]: constants.time.WEEK,
  [TimeAgo.TwoWeeks]: constants.time.WEEK * 2,
  [NonStandardTimeAgo.SixWeeks]: constants.time.WEEK * 6 - (1000 * 60 * 60 * 24),
  [TimeAgo.OneMonth]: constants.time.MONTH,
  [TimeAgo.ThreeMonths]: constants.time.MONTH * 3,
};

export const getPeriodDate = (period: TimeAgo) => {
  const endDate = new Date();
  // We need to subtract 1 day from the current date to get the correct date range
  const startDate = new Date((new Date()).getTime() - timeAgoToTime[period]);
  const formattedEndDate = formatDate(endDate, false);
  const formattedStartDate = formatDate(startDate, false);

  return {
    startDate,
    endDate,
    formattedEndDate,
    formattedStartDate,
  };
};
