import { t } from 'i18next';
import { FC } from 'react';

import { ConfirmDialog } from 'components/JitDialogs/ConfirmDialog';
import { ActionButtonVariant } from 'types/interfaces';
import { IConfigurableItem } from 'types/interfaces/Configurations/IConfigurableItem';

interface Props {
  onClose: () => void;
  onDelete: () => void;
  isOpen: boolean;
  isLoading: boolean;
  selectedSecret: IConfigurableItem;
}

export const DeleteSecretDialogContent: FC<Props> = ({ onClose, onDelete, isOpen, isLoading, selectedSecret }) => (
  <ConfirmDialog
    actionButtons={[
      {
        label: 'dialogs.secretsManagement.deleteSecret.cancelButton',
        variant: ActionButtonVariant.OUTLINED,
        handleClick: onClose,
      },
      {
        label: 'dialogs.secretsManagement.deleteSecret.deleteButton',
        variant: ActionButtonVariant.DANGER,
        handleClick: onDelete,
        isLoading,
        disabled: isLoading,
      },
    ]}
    isOpen={isOpen}
    message={`${t('dialogs.secretsManagement.deleteSecret.subtitle')} ${selectedSecret.name}?`}
    onClose={onClose}
    subMessage='dialogs.secretsManagement.deleteSecret.title'
    title='dialogs.secretsManagement.deleteSecret.header'
  />

);
