import { AwsIntegrationAccount, IAsset } from 'types/interfaces';

interface IGetAwsAccountsResponse {
  account_id: string,
  account_name: string,
  deploy_region: string,
  regions: string[]
}

export const getAwsAccounts = (account: AwsIntegrationAccount, awsAccountAssets: IAsset[]): IGetAwsAccountsResponse[] => {
  const accountId = account.account_id;

  const accountsUnderRoot = awsAccountAssets.filter((el: IAsset) => el.owner === accountId);
  const res: IGetAwsAccountsResponse[] = [];

  accountsUnderRoot.forEach((element) => {
    const data = {
      account_id: element.aws_account_id || '',
      account_name: element.asset_name,
      deploy_region: account.deploy_region,
      regions: account.regions,
    };
    res.push(data);
  });

  return res.length === 0 ? [account] : res;
};
