import styles from './useGetReposDataComponent.module.scss';

import { GithubIcon } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { useCurrentTeam } from 'pages/TeamsPortal/hooks/useCurrentTeam/useCurrentTeam';
import colors from 'themes/colors.module.scss';

export const useGetReposDataComponent = () => {
  const { specificTeam } = useCurrentTeam();
  const assets = specificTeam?.resources.data;

  const dataComponent = (
    <div className={styles.wrapper}>
      <JitText bold color={colors.lightGray} text={assets?.length || 0} />

      <div className={styles.repos}>
        <JitIcon icon={GithubIcon} size={15} />

        <JitText color={colors.lightGray} text='Repos' />
      </div>
    </div>
  );

  return ({ dataComponent });
};

