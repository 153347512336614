import { FC } from 'react';

import styles from './AddTag.module.scss';

import { JitAutoCompleteForm } from 'components/JitAutoCompleteForm/components/JitAutocompleteForm';

interface PopoverBodyProps {
  currentKeyInput: string;
  setCurrentKeyInput: (value: string) => void;
  selectedKey: string;
  setSelectedKey: (value: string) => void;
  currentValueInput: string;
  setCurrentValueInput: (value: string) => void;
  selectedValue: string;
  setSelectedValue: (value: string) => void;
  formattedKeyOptions: { key: string; label: string }[];
  formattedValueOptions: { key: string; label: string }[];
}

export const PopoverBody: FC<PopoverBodyProps> = ({
  currentKeyInput,
  setCurrentKeyInput,
  setSelectedKey,
  formattedKeyOptions,
  selectedKey,
  currentValueInput,
  setCurrentValueInput,
  selectedValue,
  setSelectedValue,
  formattedValueOptions,
}) => (
  <div className={styles.popoverContent}>
    <div className={styles.popOverContentColumn}>
      <JitAutoCompleteForm
        getOptionKey={(option) => option.key}
        getOptionLabel={(option) => option.label}
        isSingleValue
        onInputChange={(value) => {
          setCurrentKeyInput(value);
          setSelectedKey('');
        }}
        options={formattedKeyOptions}
        placeHolder='pages.risks.resources.resourcePage.resourceDetails.addTagPopover.placeHolder'
        selectedValue={{
          key: selectedKey || currentKeyInput,
          label: selectedKey || currentKeyInput,
        }}
        setSelectedValue={(res) => {
          setSelectedKey(res.key);
        }}
      />
    </div>

    <div className={styles.popOverContentColumn}>
      <JitAutoCompleteForm
        autoFocus={false}
        getOptionKey={(option) => option.key}
        getOptionLabel={(option) => option.label}
        isSingleValue
        onInputChange={(value) => {
          setCurrentValueInput(value);
          setSelectedValue('');
        }}
        options={formattedValueOptions}
        selectedValue={{
          key: selectedValue || currentValueInput,
          label: selectedValue || currentValueInput,
        }}
        setSelectedValue={(res) => {
          setSelectedValue(res.key);
        }}
      />
    </div>
  </div>
);
