
import { FC } from 'react';

import styles from './TriggerSection.module.scss';

import { JitButton } from 'components/JitButton/JitButton';
import { JitMenu } from 'components/JitMenu/JitMenu';
import { JitText } from 'components/JitText/JitText';
import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext';
import colors from 'themes/colors.module.scss';

const MOCK_TRIGGER_OPTIONS = ['Finding detected', 'Finding ignored', 'Finding resolved']; // TODO: This should come from the BE - Next PR

interface Props {
  setSelectedStepId: (stepId: string) => void;
}

export const TriggerSection: FC<Props> = ({ setSelectedStepId }) => {
  const {
    workflow,
    setWorkflow,
  } = useEditWorkflowContext();

  const menuItems = MOCK_TRIGGER_OPTIONS.map((option) => ({
    itemKey: option,
    displayText: option,
    itemContent: <JitText text={option} />,
    isSelected: workflow.trigger?.type === option,
  }));

  const onSelectTriggerOptions = (triggerOption: string) => {
    const stepId = `${triggerOption}-trigger`;
    setWorkflow((prevWorkflow) => ({
      ...prevWorkflow,
      trigger: {
        ...(prevWorkflow.trigger || {}),
        id: `${triggerOption}-trigger`,
        stepType: 'trigger',
        type: triggerOption,
        next: undefined,
        name: triggerOption, // TODO: Change it later on based on BE Options - next PR
      },
    }));
    setSelectedStepId(stepId);
  };

  return (
    <div className={styles.triggerSection}>
      <JitText bold muted size='s' text='pages.workflows.editWorkflowPage.stepDetails.trigger.ctaQuestion' />

      <JitMenu
        menuItems={menuItems}
        menuSelectedDesign='fillRow'
        onMenuItemClick={(item) => onSelectTriggerOptions(item.itemKey as string)}
        paperProps={{ style: { width: 180 } }}
        shouldCloseOnItemClick
      >
        <JitButton noHover variant='outlined' width={150}>
          <JitText color={colors.iris} text={workflow.trigger?.name || 'pages.workflows.editWorkflowPage.stepDetails.trigger.addTrigger'} />
        </JitButton>
      </JitMenu>
    </div>
  );
};
