import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Tab } from 'components/Navbar/hooks';

// Setting the selected tab name according to the current route.
export const useGetSelectedTabName = (pagesTabs: Tab[]) => {
  const { pathname } = useLocation();

  const selectedTabName = useMemo(() => {
    const tabItems = pagesTabs.flatMap((tab) => {
      if (tab.type === 'group') {
        return tab.items;
      }
      return tab;
    });

    let relatedTab = tabItems.find((tab) => tab.route && pathname.startsWith(`/${tab.route}`));

    // If no tab is found, the default tab is selected.
    if (!relatedTab) relatedTab = tabItems.find((tab) => tab.isDefault);

    return relatedTab!.name;
  }, [pagesTabs, pathname]);

  return { selectedTabName };
};
