import { FC } from 'react';

import { ImportFileButton } from '../../../ImportFileButton/ImportFileButton';

import styles from './NoTeamsEmptyState.module.scss';

import { JitTeams } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { ShapesBackground } from 'components/ShapesBackground/ShapesBackground';
import colors from 'themes/colors.module.scss';

export const NoTeamsEmptyState: FC = () => (
  <div className={styles.root} data-testid='no-teams-empty-state'>

    <ShapesBackground fullSize={false} />

    <div className={styles.content}>
      <JitText align='center' bold text='pages.teams.teamsTable.noTeams.title' />

      <JitText align='center' text='pages.teams.teamsTable.noTeams.description' />

      <ImportFileButton variant='contained' />
    </div>

    <JitIcon color={colors.gray} data-testid='jit-teams-icon' icon={JitTeams} width={70} />
  </div>
);
