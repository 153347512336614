import { FC } from 'react';

import styles from './PlanItemTitleWithTooltip.module.scss';

import { CircleQuestion } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import colors from 'themes/colors.module.scss';

interface PlanItemTitleWithTooltipProps {
  columnName: string;
}

export const PlanItemTitleWithTooltip: FC<PlanItemTitleWithTooltipProps> = ({ columnName }) => (
  <div className={styles.container}>
    <JitText horizontalAlign='center' size='m' text={columnName} />

    <JitTooltip childrenWrapperClassName={styles.infoIcon} followCursor={false} placement='top' title='pages.teamsPortal.teamPage.table.columns.planItemsTooltip'>
      <JitIcon color={colors.white} icon={CircleQuestion} size={15} />
    </JitTooltip>
  </div>
);
