import { FC } from 'react';

import { WorkflowTrigger, WorkflowCondition, DefaultAction } from 'assets';
import { getIconByVendor } from 'components/AssetType/utils/getIconByVendor';
import { WorkflowNode, WorkflowEdge } from 'pages/WorkflowsPage/EditWorkflowPage/components/WorkflowGraph/types';
import colors from 'themes/colors.module.scss';
import { IWorkflow, ITriggerStep, IConditionStep, IActionStep } from 'types/interfaces/Workflows/IWorkflow';

const buildEdge = (source: string, target: string): WorkflowEdge => ({
  id: `${source}-${target}`,
  source,
  target,
  type: 'customEdge',
  style: {
    stroke: colors.edgeColor,
    strokeWidth: 2,
  },
});

const buildNode = (step: ITriggerStep | IConditionStep | IActionStep, icon: FC): WorkflowNode => ({
  id: step.id,
  draggable: false,
  data: {
    icon,
    step,
  },
  position: {
    x: 0,
    y: 0,
  },
  type: 'customNode',
});

export const translateWorkflowToGraph = (workflow: IWorkflow): { nodes: WorkflowNode[]; edges: WorkflowEdge[] } => {
  const nodes: WorkflowNode[] = [];
  const edges: WorkflowEdge[] = [];

  nodes.push(buildNode(workflow.trigger, WorkflowTrigger));

  if (workflow?.trigger?.next) {
    edges.push(buildEdge(workflow.trigger.id, workflow?.trigger?.next));
  }

  // Add steps as nodes
  workflow.steps.forEach((step) => {
    let icon: FC;
    if (step.stepType === 'condition') {
      icon = WorkflowCondition;
    } else {
      icon = getIconByVendor(step.config.vendor, undefined, true) || DefaultAction;
    }

    nodes.push(buildNode(step, icon));

    // Create edges from trigger or other steps
    step.next.forEach((nextStepId) => {
      edges.push(buildEdge(step.id, nextStepId));
    });
  });

  return {
    nodes,
    edges,
  };
};
