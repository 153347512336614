import { useEffect, useState } from 'react';

import { useIntegrationsContext } from '../IntegrationsContext';

import { IIntegration, IntegrationStatus } from 'types/interfaces/Integrations/IIntegration';

interface IUseGetActiveIntegration {
  activeIntegration: IIntegration | undefined;
  isLoading: boolean;
}

export const useGetActiveIntegration = (predicate?: (integration: IIntegration) => boolean): IUseGetActiveIntegration => {
  const { isLoading, integrations, fetchIntegrations } = useIntegrationsContext();

  const [activeIntegration, setActiveIntegration] = useState<IIntegration>();

  useEffect(() => {
    if (!integrations) {
      fetchIntegrations();
    } else {
      const foundIntegration = integrations?.find((integration) => integration.status === IntegrationStatus.SUCCESS && (!predicate || predicate(integration)));
      setActiveIntegration(foundIntegration);
    }
  }, [integrations, fetchIntegrations, predicate]);

  return {
    activeIntegration,
    isLoading,
  };
};
