import { Interval } from 'types/types';

export const setIntervalImmediately = (func: Function, ms: number, maxRuns?: number): Interval => {
  let counter = 1;
  let interval: Interval;

  const intervalFunc = () => {
    if (maxRuns && counter >= maxRuns) {
      clearInterval(interval);
    } else {
      counter += 1;
      func();
    }
  };

  func();
  interval = setInterval(intervalFunc, ms);

  return interval;
};

