import { cloneDeep, isEqual } from 'lodash';
import uniqBy from 'lodash/uniqBy';
import { Dispatch, SetStateAction, useCallback } from 'react';

import { buildPlanItemRecord } from 'context/PlanInstanceContext/planUtils';
import { useToastsContext } from 'context/ToastsContext/ToastsContext';
import { i18n } from 'locale/i18n';
import { ToastType } from 'types/enums';
import { IPlanInstance, ITenantPlanItem, IWebsocketMessageEntityUpdateMessage, IWebsocketNotification } from 'types/interfaces';
import { formatDate } from 'utils';
import { getPlanItemSlug } from 'utils/functions/getPlanItemSlug';

interface Props {
  plan: IPlanInstance,
  setPlan: Dispatch<SetStateAction<IPlanInstance>>,
  fetchedPlan: IPlanInstance,
  setFetchedPlan: Dispatch<SetStateAction<IPlanInstance>>,
  setPlanItems: Dispatch<SetStateAction<ITenantPlanItem[] | undefined>>,
}

export const useHandlePlanItemWebsocketNotification = ({ plan, setPlan, fetchedPlan, setFetchedPlan, setPlanItems }: Props) => {
  const { showToast } = useToastsContext();
  const { t } = i18n;

  const showPlanChangedToast = useCallback((message: IWebsocketMessageEntityUpdateMessage<ITenantPlanItem>, timestamp: string) => {
    let changeText = '';

    if (message.created?.length) {
      const addedItems = message.created?.map((item) => `"${item.name}"`).join(', ');
      changeText += `\n${t('toasts.planChanged.subtitle.changeText.addedItems', { addedItems })}`;
    }

    if (message.deleted?.length) {
      const deletedItems = message.deleted?.map((item) => `"${item.name}"`).join(', ');
      changeText += `\n${t('toasts.planChanged.subtitle.changeText.removedItems', { deletedItems })}`;
    }

    const introText = i18n.t('toasts.planChanged.subtitle.intro', { timestamp: formatDate(timestamp, true) });

    const toastText = `${introText}${changeText}`;

    return showToast({
      type: ToastType.Info,
      overrideProps: { subtitle: toastText },
    });
  }, [showToast, t]);

  const appendCreatedPlanItems = useCallback((currentPlan: IPlanInstance, updatedItems: ITenantPlanItem[]) => {
    const parsedPlanItems = updatedItems.map((item) => buildPlanItemRecord(item.name, item.layer, item.slug, item.input));
    return {
      ...currentPlan,
      items: uniqBy([...currentPlan.items, ...parsedPlanItems], 'uses'),
    };
  }, []);

  const removeDeletedPlanItems = useCallback((currentPlan: IPlanInstance, deletedItems: ITenantPlanItem[]) => {
    const ItemsAfterDeletion = currentPlan.items.filter(
      (item) => !deletedItems.some((deletedItem) => getPlanItemSlug(item) === deletedItem.slug),
    );
    return {
      ...currentPlan,
      items: [...ItemsAfterDeletion],
    };
  }, []);

  const replaceUpdatedPlanItems = useCallback((currentPlan: IPlanInstance, updatedItems: ITenantPlanItem[]) => {
    const updatedPlanItems = currentPlan.items.map((item) => {
      const updatedItem = updatedItems.find((updatedPlanItem) => getPlanItemSlug(item) === updatedPlanItem.slug);
      if (updatedItem) {
        return {
          ...item,
          ...buildPlanItemRecord(updatedItem.name, updatedItem.layer, updatedItem.slug, updatedItem.input),
        };
      }
      return item;
    });
    return {
      ...currentPlan,
      items: updatedPlanItems,
    };
  }, []);

  const handleItemUpdateInPlan = useCallback((
    planBeforeUpdate: IPlanInstance,
    deletedItems?: ITenantPlanItem[],
    addedItems?: ITenantPlanItem[],
    updatedItems?: ITenantPlanItem[],
  ): IPlanInstance => {
    let updatedPlan = cloneDeep(planBeforeUpdate);

    if (deletedItems?.length) updatedPlan = removeDeletedPlanItems(updatedPlan, deletedItems);
    if (addedItems?.length) updatedPlan = appendCreatedPlanItems(updatedPlan, addedItems);
    if (updatedItems?.length) updatedPlan = replaceUpdatedPlanItems(updatedPlan, updatedItems);

    return updatedPlan;
  }, [appendCreatedPlanItems, removeDeletedPlanItems, replaceUpdatedPlanItems]);

  const updatePlanItemsList = useCallback((created: ITenantPlanItem[], deleted: ITenantPlanItem[], updated: ITenantPlanItem[]) => setPlanItems((currentPlanItems) => {
    const updatedPlanItems = (currentPlanItems || []).filter((item) => !deleted.some((deletedItem) => deletedItem.slug === item.slug));
    const updatedPlanItemsWithUpdatedItems = updatedPlanItems.map((item) => {
      const updatedItem = updated.find((updatedPlanItem) => updatedPlanItem.slug === item.slug);
      if (updatedItem) {
        return {
          ...item,
          ...updatedItem,
        };
      }
      return item;
    });
    return uniqBy([...created, ...updatedPlanItemsWithUpdatedItems], 'slug');
  }), [setPlanItems]);

  const handlePlanItemWebSocketNotification = useCallback((notification: IWebsocketNotification<ITenantPlanItem>) => {
    const { message } = notification;
    const { deleted: deletedItems = [], created: addedItems = [], updated: updatedItems = [] } = message;

    // Will happen when a plan item has been deleted or created
    const updatedFetchedPlan = handleItemUpdateInPlan(fetchedPlan, deletedItems, addedItems, updatedItems);
    setFetchedPlan(updatedFetchedPlan);

    const updatedPlan = handleItemUpdateInPlan(plan, deletedItems, addedItems, updatedItems);
    setPlan(updatedPlan);
    // In some cases We'll get a notification about a change that is already in the plan, in this case we don't want to call the callback.
    const hasPlanChanged = !isEqual(plan, updatedPlan) || addedItems?.length > 0 || deletedItems?.length > 0;
    if (hasPlanChanged) showPlanChangedToast(message, notification.timestamp);
    updatePlanItemsList(addedItems || [], deletedItems || [], updatedItems || []);
  }, [handleItemUpdateInPlan, fetchedPlan, setFetchedPlan, plan, setPlan, showPlanChangedToast, updatePlanItemsList]);

  return { handlePlanItemWebSocketNotification };
};
