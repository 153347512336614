import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { JitBreadcrumbs } from 'components/JitBreadcrumbs/JitBreadcrumbs';
import { renderLinkBreadCrumbItem } from 'components/JitBreadcrumbs/utils/textToBreadcrumbContent';
import { JitDropdownNew } from 'components/JitDropdownNew/JitDropdownNew';
import { MenuItemType } from 'components/JitMenu/JitMenu';
import { usePlansContext } from 'context/PlansContext/PlansContext';
import { constants } from 'globalConstants';
import colors from 'themes/colors.module.scss';
import { IPlanDetails, MenuItemKeyType } from 'types/interfaces';

interface Props {
  plan: IPlanDetails;
}

export const PlanSelector: FC<Props> = ({ plan }) => {
  const navigate = useNavigate();
  const { plans } = usePlansContext();
  const onMenuItemClick = useCallback(({ itemKey }: { itemKey: MenuItemKeyType }) => {
    navigate(`/${constants.routes.PLANS}/${itemKey.toString().toLowerCase()}`);
  }, [navigate]);

  const planMenuItems: Partial<MenuItemType>[] = [...Object.values(plans)].sort(
    (a, b) => Number(b.slug === plan.slug) - Number(a.slug === plan.slug),
  ).map((template) => ({
    itemKey: template.slug,
    itemName: template.title,
    isSelected: template.slug === plan.slug,
    searchValue: template.title,
  }));

  const planTemplatesMenu = useMemo(() => (
    <JitDropdownNew
      displayText={plan.title}
      enabledColor={colors.lightBlue}
      menuItems={planMenuItems}
      menuWidth={300}
      noBackground
      noBorder
      noGap
      onMenuItemClick={onMenuItemClick}
      withSearchBox={planMenuItems.length > 7 ? { placeholder: 'pages.securityPlans.planSelector.placeholder' } : undefined}
    />

  ), [onMenuItemClick, plan.title, planMenuItems]);

  return (
    <JitBreadcrumbs
      items={[
        {
          content: renderLinkBreadCrumbItem(
            'pages.securityPlans.planSelector.mainPage',
            `/${constants.routes.PLANS}`,
            'allSecurityPlanBreadcrumb',
          ),
          itemKey: 'allSecurityPlanBreadcrumb',
        },
        {
          content: planTemplatesMenu,
          itemKey: 'selectedSecurityPlanBreadcrumb',
        },
      ]}
    />
  );
};
