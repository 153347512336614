import { isEmpty } from 'lodash';
import { FC, useMemo } from 'react';

import { AWS_ERROR_LEARN_MORE_LINK, AWS_GENERAL_LEARN_MORE_LINK, CardStatusToBorderColor, StatusIndicationIconProps } from './constants';

import { JitCard } from 'components/JitCard/JitCard';
import { TABS } from 'components/JitDialogs/AssetsManagementDialog/constants';
import { useAssetsContext } from 'context/AssetsContext/AssetsContext';
import { useConfigurationsContext } from 'context/ConfigurationsContext/ConfigurationsContext';
import { useDialogsContext } from 'context/DialogsContext/DialogsContext';
import { CustomIntegrationCardDefaultProps } from 'context/IntegrationsContext/templates/interfaces';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import { i18n } from 'locale/i18n';
import { getCurrentInstallationSortedByStatus, groupInstallationsByVendor } from 'pages/IntegrationsPage/utils';
import { AssetStatus, Dialogs, Vendor } from 'types/enums';
import { IntegrationStatus } from 'types/interfaces';
import { ActionButton, ActionButtonVariant } from 'types/interfaces/ActionButton/ActionButton';
import { Link } from 'types/interfaces/Link/Link';

export const AWSIntegrationCard: FC<CustomIntegrationCardDefaultProps> = ({ title, icon, tags }) => {
  const { t } = i18n;
  const { assets } = useAssetsContext();
  const { showDialog } = useDialogsContext();
  const { configurations } = useConfigurationsContext();
  const { isAnyScmVendorIntegrated, installations } = useTenantContext();
  const showAssetsManagementDialog = (tab?: TABS) => {
    showDialog({
      type: Dialogs.AssetsManagement,
      props: { defaultTab: tab },
    });
  };
  const showAWSDialog = () => {
    showDialog({
      type: Dialogs.Integrations,
      props: {
        vendor: Vendor.AWS,
        integration: configurations[Vendor.AWS.toString()],
      },
    });
  };
  const installationsByVendor = groupInstallationsByVendor(installations);
  const awsInstallation = getCurrentInstallationSortedByStatus(Vendor.AWS, installationsByVendor);

  const awsStatus = useMemo(() => {
    const awsAssets = assets ? assets.filter((asset) => asset.vendor === Vendor.AWS && asset.is_active) : [];
    if (!awsAssets.length) {
      return IntegrationStatus.DISCONNECTED;
    }
    const failedAssets = awsAssets.filter((asset) => asset.status === AssetStatus.FAILED && asset.is_covered);
    if (failedAssets.length > 0) {
      if (failedAssets.length < awsAssets.length) {
        return IntegrationStatus.WARNING;
      }
      return IntegrationStatus.ERROR;
    }
    return IntegrationStatus.CONNECTED;
  }, [assets]);

  const description = i18n.t(`cards.aws.descriptions.${awsInstallation?.status}`, {
    defaultValue: t('cards.aws.descriptions.primary'),
  });

  const connectButton: ActionButton = {
    label: 'cards.buttons.connect',
    handleClick: showAWSDialog,
    disabled: !isAnyScmVendorIntegrated,
    tooltip: !isAnyScmVendorIntegrated ? 'cards.tooltip.requiresSCMIntegration' : undefined,
  };

  const disconnectingButton: ActionButton = {
    label: 'cards.buttons.disconnecting',
    handleClick: showAWSDialog,
    isLoading: true,
    disabled: true,
    shouldShowChildrenWhileLoading: true,
  };

  const connectingButton: ActionButton = {
    label: 'cards.buttons.connecting',
    handleClick: showAWSDialog,
    isLoading: true,
    disabled: true,
    shouldShowChildrenWhileLoading: true,
  };

  const ConfigureButton: ActionButton = {
    label: 'cards.buttons.configure',
    handleClick: () => {
      showAssetsManagementDialog(TABS.AWS);
    },
    disabled: !isAnyScmVendorIntegrated,
    tooltip: !isAnyScmVendorIntegrated ? 'cards.tooltip.requiresSCMIntegration' : undefined,
    variant: ActionButtonVariant.OUTLINED,
  };

  const ConfigureButtonDisabled: ActionButton = {
    label: 'cards.buttons.configure',
    handleClick: () => {
      showAssetsManagementDialog(TABS.AWS);
    },
    variant: ActionButtonVariant.OUTLINED,
    disabled: true,
  };

  const statusToActionButtons = {
    [IntegrationStatus.DISCONNECTED]: [connectButton],
    [IntegrationStatus.DISCONNECTING]: [disconnectingButton],
    [IntegrationStatus.CONNECTING]: [connectingButton],
    [IntegrationStatus.CONNECTED]: [ConfigureButton],
    [IntegrationStatus.ERROR]: [ConfigureButtonDisabled],
    [IntegrationStatus.WARNING]: [ConfigureButton],
    [IntegrationStatus.PENDING]: [ConfigureButton],
  };

  const subtitle = i18n.t(`cards.aws.subtitle.${awsInstallation?.status}`, {
    defaultValue: '',
  });

  const shouldShowTag = isEmpty(subtitle);

  const getLink = (): Link | undefined => {
    const shouldShowLink = awsStatus !== IntegrationStatus.CONNECTED;
    if (!shouldShowLink) {
      return undefined;
    }

    const href = awsStatus === IntegrationStatus.ERROR ? AWS_ERROR_LEARN_MORE_LINK : AWS_GENERAL_LEARN_MORE_LINK;

    return {
      text: 'cards.buttons.learnMore',
      href,
    };
  };

  const statusIcon = awsInstallation?.status && StatusIndicationIconProps?.[awsStatus as keyof typeof StatusIndicationIconProps];
  const actionButtons: ActionButton[] = (awsInstallation?.status && statusToActionButtons[awsStatus as keyof typeof StatusIndicationIconProps])
    || [connectButton];
  const borderColor = CardStatusToBorderColor?.[awsInstallation?.status as keyof typeof CardStatusToBorderColor];
  const headerIcons = { icons: statusIcon ? [{ icon }, { icon: statusIcon }] : [{ icon }] };
  return (
    <JitCard
      actionButtons={actionButtons}
      borderColor={borderColor}
      description={description}
      headerIcons={headerIcons}
      link={getLink()}
      subTitle={subtitle}
      tags={shouldShowTag ? tags : undefined}
      testid='aws'
      title={title || 'vendor.aws'}
    />
  );
};
