import { FC, useEffect, useState, useMemo, useCallback } from 'react';

import styles from './FindingsTableWrapper.module.scss';

import { FindingsTable } from 'components/FindingsTable/FindingsTable';
import { JitText } from 'components/JitText/JitText';
import { i18n } from 'locale/i18n';
import { useFindingsService } from 'services/FindingsService/useFindingService';
import { FindingStatus } from 'types/enums';
import { ServerFindingKeys, IAsset } from 'types/interfaces';
import { ICount } from 'types/interfaces/Counts/ICounts';

interface Props {
  asset?: IAsset;
}

export const FindingsTableWrapper: FC<Props> = ({ asset }) => {
  const { getFindingsCount } = useFindingsService();

  const [findingsCount, setFindingsCount] = useState<number>();

  const filters = useMemo(() => ({
    [ServerFindingKeys.asset_name]: [asset?.asset_name || ''],
    [ServerFindingKeys.status]: [FindingStatus.OPEN],
    [ServerFindingKeys.ignored]: [false],
  }), [asset]);

  useEffect(() => {
    if (!asset) return;
    getFindingsCount({ filters }).then((response) => {
      const data = response?.data as ICount;
      setFindingsCount(data?.count);
    });
  }, [asset, filters, getFindingsCount]);
  let findingGraphTitle = '';
  if (findingsCount) {
    findingGraphTitle = i18n.t('pages.risks.resources.resourcePage.findingsCount', { count: findingsCount });
  } else if (findingsCount === 0) {
    findingGraphTitle = 'pages.risks.resources.resourcePage.noFindings';
  }
  const EmptyTableView = useCallback(() => (
    <div className={styles.emptyTableWrapper}>
      <JitText align='center' bold size='l' text='pages.risks.resources.resourcePage.emptyTable.title' />

      <JitText
        align='center'
        data-testid='noFindingsSoFar'
        text='pages.risks.resources.resourcePage.emptyTable.subtitle'
      />
    </div>
  ), []);
  return (

    <>
      <JitText size='m' text={findingGraphTitle} />

      <FindingsTable
        EmptyTableView={EmptyTableView}
        filters={filters}
        isLoading={!asset}
      />
    </>
  );
};
