import { useCallback } from 'react';

import { useClient } from 'services/useClient';
import { IGithubStatusAlert } from 'types/enums/GithubStatus';

const serviceName = 'github';

export const useGithubService = () => {
  const { client } = useClient();

  const fetchGithubStatus = useCallback(async () => {
    const url = `${serviceName}/github-status`;
    return client.get<IGithubStatusAlert>({
      url,
      allowedStatuses: [200],
    });
  }, [client]);

  return {
    fetchGithubStatus,
  };
};
