import { useQuery } from 'react-query';

import { PolicyContent } from './components/PolicyContent/PolicyContent';
import { PolicyHeader } from './components/PolicyHeader/PolicyHeader';
import styles from './PoliciesPage.module.scss';
import { IPolicyTemplate } from './types';

import { JitText } from 'components/JitText/JitText';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { PageTitles } from 'components/PageTitles/PageTitles';
import { CollapseSection } from 'pages/OverviewPage/components/CollapseSection/CollapseSection';
import { usePoliciesService } from 'services/PoliciesService/usePoliciesService';
import { Queries } from 'types/enums/Queries';

export const PoliciesPage = () => {
  const { getPoliciesTemplate } = usePoliciesService();
  const { data, isLoading } = useQuery(Queries.policiesTemplate, getPoliciesTemplate);

  if (isLoading) {
    return <LoadingBar />;
  }

  return (
    <div className={styles.root}>
      <PageTitles title='pages.policies.title' />

      <div className={styles.content}>
        <JitText muted size='s' text='pages.policies.subtitle' />

        <div className={styles.policies}>
          {data?.map((template: IPolicyTemplate) => (
            <CollapseSection
              key={template.policySlug}
              header={<PolicyHeader description={template.display.description} enabled={template.dynamicData.enabledRulesCount} title={template.display.displayName} />}
              withBackground
            >
              <PolicyContent template={template} />
            </CollapseSection>
          ))}
        </div>
      </div>
    </div>
  );
};
