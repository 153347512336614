import { useCallback } from 'react';

import { usePlanInstanceContext } from 'context/PlanInstanceContext/PlanInstanceContext';
import { usePlansContext } from 'context/PlansContext/PlansContext';
import { IPlanInstance } from 'types/interfaces';
import { getPlanItemSlug } from 'utils/functions/getPlanItemSlug';

export const useRemoveItemFromPlan = (planSlug: string) => {
  const { plan, setPlan, setPlanItems } = usePlanInstanceContext();
  const { setPlans } = usePlansContext();
  const removeItemFromPlan = useCallback((planItemSlug: string) => {
    const updatedPlan: IPlanInstance = {
      ...plan,
      items: plan.items?.filter((item) => getPlanItemSlug(item) !== planItemSlug),
    };
    setPlan(updatedPlan);
    setPlanItems((prevPlanItems) => (prevPlanItems || []).filter((item) => item.slug !== planItemSlug));
    setPlans((prevPlans) => ({
      ...prevPlans,
      [planSlug]: {
        ...prevPlans[planSlug],
        activeItemSlugs: new Set([...prevPlans[planSlug].activeItemSlugs].filter((slug) => slug !== planItemSlug)),
        passedItemSlugs: new Set([...prevPlans[planSlug].passedItemSlugs].filter((slug) => slug !== planItemSlug)),
        failedItemSlugs: new Set([...prevPlans[planSlug].failedItemSlugs].filter((slug) => slug !== planItemSlug)),
        items: Object.fromEntries(
          Object.entries(prevPlans[planSlug].items || {}).map(([key, item]) => {
            if (key === planItemSlug) {
              return [key, {
                ...item,
                status_properties: undefined,
                is_active: false,
              }];
            }
            return [key, item];
          }),
        ),
      },
    }));
    return updatedPlan;
  }, [plan, planSlug, setPlan, setPlanItems, setPlans]);

  return { removeItemFromPlan };
};
