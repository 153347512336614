export enum ArtifactType {
  SBOM = 'SBOM',
}

export interface IArtifact {
  artifactId: string;
  entitled: boolean;
  assetId: string;
  version: number;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
}
