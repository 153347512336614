import { createContext, Dispatch, SetStateAction, useContext } from 'react';

import { IPlanDetails, IPlanDetailsMap } from 'types/interfaces';

interface IPlansContext {
  plans: IPlanDetailsMap;
  setPlans: Dispatch<SetStateAction<IPlanDetailsMap>>;
  hasFetchedPlans: boolean;
  fetchPlans: () => void;
  fetchPlanDetails: (slug: string) => Promise<boolean>;
  getPlan: (slug: string) => IPlanDetails | undefined;
  isFetchingPlans: boolean;
}

export const PlansContext = createContext<IPlansContext>({
  plans: {},
  setPlans: () => undefined,
  hasFetchedPlans: false,
  fetchPlans: () => undefined,
  fetchPlanDetails: () => Promise.resolve(true),
  getPlan: () => undefined,
  isFetchingPlans: false,
});

export const usePlansContext = () => useContext(PlansContext);
