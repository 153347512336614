import { FC, useEffect, useMemo, useState } from 'react';

import { ImportFileButton } from '../ImportFileButton/ImportFileButton';

import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { useTeamsContext } from 'context/TeamsContext/TeamsContext';
import { InviteMembersDialog } from 'pages/TeamsPage/SubPages/TeamsPage/components/InviteMembersDialog/InviteMembersDialog';
import styles from 'pages/TeamsPage/SubPages/TeamsPage/components/PageContent/PageContent.module.scss';
import { TeamsSearch } from 'pages/TeamsPage/SubPages/TeamsPage/components/TeamsSearch/TeamsSearch';
import { TeamsTable } from 'pages/TeamsPage/SubPages/TeamsPage/components/TeamsTable/TeamsTable';
import { useSlackIntegrationStatus } from 'pages/TeamsPage/SubPages/TeamsPage/hooks/useSlackIntegrationStatus';
import { ActionButtonVariant } from 'types/interfaces';
import { useDebounce } from 'utils/hooks/useDebounce';
import { PERMISSIONS } from 'wrappers/RBAC/constants';
import { useHasPermissions } from 'wrappers/RBAC/hooks/useHasPermissions';

export const PageContent: FC = () => {
  // this state indicates that the tenant has teams even if the teams array is empty while searching
  const [tenantHasTeams, setTenantHasTeams] = useState(false);
  const {
    teams,
    getTeams,
    sortedBy,
    sortOrder,
  } = useTeamsContext();
  const [searchValue, setSearchValue] = useState('');
  const [isInviteMembersDialogOpen, setIsInviteMembersDialogOpen] = useState(false);
  const { isPostInstallation } = useSlackIntegrationStatus();
  const { hasPermission } = useHasPermissions();

  const shouldAllowInvite = hasPermission(PERMISSIONS.INVITE);

  const { debounceValue } = useDebounce(searchValue);
  useEffect(() => {
    getTeams({
      shouldResetState: true,
      searchValue,
    });
  }, [sortOrder, sortedBy, debounceValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (teams.data.length > 0 && !tenantHasTeams) {
      setTenantHasTeams(true);
    }
  }, [tenantHasTeams, teams.data.length]);

  useEffect(() => {
    setIsInviteMembersDialogOpen(isPostInstallation);
  }, [isPostInstallation]);

  const header = useMemo(() => (
    <>
      <TeamsSearch onChange={setSearchValue} searchValue={searchValue} />

      <div className={styles.actionButtons}>
        <ImportFileButton />

        {shouldAllowInvite && (
          <JitActionButton
            actionButton={{
              label: 'pages.teams.invite.button',
              handleClick: () => setIsInviteMembersDialogOpen(true),
              variant: ActionButtonVariant.OUTLINED,
            }}
          />
        )}
      </div>

      {shouldAllowInvite && (
        <InviteMembersDialog
          isOpen={isInviteMembersDialogOpen}
          onClose={() => setIsInviteMembersDialogOpen(false)}
        />
      )}

    </>
  ), [searchValue, shouldAllowInvite, isInviteMembersDialogOpen]);

  const shouldDisplayHeader = useMemo(() => (
    debounceValue !== '' || tenantHasTeams
  ), [debounceValue, tenantHasTeams]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        {shouldDisplayHeader && header}
      </div>

      <TeamsTable searchValue={searchValue} shouldDisplayColumns={tenantHasTeams} teams={teams.data} />

    </div>
  );
};
