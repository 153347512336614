import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';

import styles from './IgnoredIssues.module.scss';

import { JitPieChart } from 'components/graphs/JitPieChart/JitPieChart';
import { GraphEmpty, GraphLoading } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/components';
import { fetchFindingsMetric } from 'services/MetricService/MetricService';
import colors from 'themes/colors.module.scss';
import { TimeAgo } from 'types/enums';
import { Queries } from 'types/enums/Queries';
import { IgnoredIssuesMetric } from 'types/interfaces/Metrics/FindingsMetrics/FindingsMetrics';

interface Props {
  period: TimeAgo;
  breakdown: string;
}

export const IgnoredIssues: FC<Props> = ({ period, breakdown }) => {
  const { data: metricData, isLoading } = useQuery([Queries.IgnoredIssuesMetric, period, breakdown], () => fetchFindingsMetric<IgnoredIssuesMetric>({
    period,
    params: { breakdown },
  }));

  const data = useMemo(() => {
    if (!metricData || !metricData.data) return [];
    const { acceptRisk, falsePositive, resolved } = metricData.data;

    return [{
      name: 'pages.securityImpactV2.sections.performance.widgets.ignoredIssues.categories.resolved',
      value: resolved,
      color: colors.successDark,
    }, {
      name: 'pages.securityImpactV2.sections.performance.widgets.ignoredIssues.categories.ignoredAcceptRisk',
      value: acceptRisk,
      color: colors.warningLight,
    }, {
      name: 'pages.securityImpactV2.sections.performance.widgets.ignoredIssues.categories.ignoredFalsePositive',
      value: falsePositive,
      color: colors.warningDark,
    }];
  }, [metricData]);

  if (isLoading) return <GraphLoading />;

  const hasNoData = data.every((item) => item.value === 0);
  if (hasNoData) return <GraphEmpty />;

  return (
    <div className={styles.root}>
      <JitPieChart data={data} />
    </div>
  );
};
