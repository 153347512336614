import { FC } from 'react';

import styles from './StatisticsRow.module.scss';

import { JitText } from 'components/JitText/JitText';

interface StatisticsRowProps {
  text: string;
  number?: number | string;
  bulletColor: string;
  testId?: string;
}

export const StatisticsRow: FC<StatisticsRowProps> = ({ text, number, bulletColor, testId }) => (
  <div className={styles.row} data-testid={testId}>
    <div className={styles.textBulletPair}>
      <div className={styles.bullet} style={{ backgroundColor: bulletColor }} />

      <JitText size='m' text={text} />
    </div>

    <JitText bold size='m' text={number ?? '--'} />
  </div>
);
