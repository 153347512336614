import { FC } from 'react';

import { LogsLink } from '../../LogsLink/LogsLink';

import styles from './Asset.module.scss';

import { DotClockRunning, DotQueued, Empty, FailedDot, GreenDotCheck } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { config } from 'config';
import { useAssetsContext } from 'context/AssetsContext/AssetsContext';
import { STATUSES } from 'pages/PipelinesPage/constants';
import { getStatusFromJobs } from 'pages/PipelinesPage/utils/utilsNew';
import { isCiRunner, Vendor } from 'types/enums';
import { IMouseEvent } from 'types/interfaces';
import { IAssetFieldsNew } from 'types/interfaces/Pipelines/IPipelineNew';
import { stopPropagation } from 'utils';

export enum AssetStyle {
  BORDER = 'border',
  DEFAULT = 'default',
}

export enum LogsLinkLocation {
  OUT = 'out',
  IN = 'in',
}

interface Props {
  asset: IAssetFieldsNew;
  jitEventId?: string;
  assetStyle?: AssetStyle;
  logsLinkLocation?: LogsLinkLocation;
  shouldShowShortAssetName?: boolean;
}

export const Asset: FC<Props> = ({ asset, jitEventId, assetStyle, logsLinkLocation, shouldShowShortAssetName }) => {
  const assetName = (asset.vendor === Vendor.Github) && !shouldShowShortAssetName ? (`${asset.owner}/${asset.assetName}`) : (asset.assetName);
  const firstJob = Object.values(asset.jobs)[0];
  const shouldBeClickable = !!(isCiRunner(firstJob.runner) && firstJob.centralizedRepoExternalJobId);
  const { centralizedRepo } = useAssetsContext();

  const handleClickOnControl = (e: IMouseEvent) => {
    if (shouldBeClickable && centralizedRepo) {
      window.open(config.githubExternalJobId(asset.owner, centralizedRepo.asset_name, firstJob.centralizedRepoExternalJobId));
    }
    stopPropagation(e);
  };

  const statusToIconProps = {
    [STATUSES.COMPLETED]: {
      icon: GreenDotCheck || Empty,
      className: {
        [AssetStyle.BORDER]: styles.successWrapperWithBorder || '',
        [AssetStyle.DEFAULT]: styles.successWrapper || '',
      },
    },
    [STATUSES.FAILED]: {
      icon: FailedDot || Empty,
      className: {
        [AssetStyle.BORDER]: styles.failedWrapperWithBorder || '',
        [AssetStyle.DEFAULT]: styles.failedWrapper || '',
      },
    },
    [STATUSES.ERROR]: {
      icon: FailedDot || Empty,
      className: {
        [AssetStyle.BORDER]: styles.failedWrapperWithBorder || '',
        [AssetStyle.DEFAULT]: styles.failedWrapper || '',
      },
    },
    [STATUSES.RUNNING]: {
      icon: DotClockRunning || Empty,
      className: {
        [AssetStyle.BORDER]: styles.runningWrapperWithBorder || '',
        [AssetStyle.DEFAULT]: styles.runningWrapper || '',
      },
    },
    [STATUSES.PENDING]: {
      icon: DotQueued || Empty,
      className: {
        [AssetStyle.BORDER]: styles.queuedWrapperWithBorder || '',
        [AssetStyle.DEFAULT]: styles.queuedWrapper || '',
      },
    },
  };

  const getAssetStatus = () => {
    const jobs = Object.values(asset.jobs);
    return getStatusFromJobs(jobs);
  };

  const assetStatusProps = statusToIconProps[getAssetStatus()];

  return (
    <div className={`${styles.wrapper}`}>
      <div className={`${assetStatusProps?.className[assetStyle || AssetStyle.DEFAULT]} ${styles.assetWrapper}`}>
        <JitIcon
          className={styles.icon}
          icon={assetStatusProps?.icon}
          size={12}
        />

        <TooltipOnlyOnOverflow tooltipText={assetName}>
          <JitText
            addUnderlineOnHover={shouldBeClickable}
            className={shouldBeClickable ? (styles.overflowEllipsis) : (`${styles.overflowEllipsis} ${styles.notClickable}`)}
            data-testid={assetName}
            noWrap
            onClick={(e: IMouseEvent) => handleClickOnControl(e)}
            overflowHiddenEllipsis
            size='s'
            text={assetName}
          />
        </TooltipOnlyOnOverflow>

        {logsLinkLocation === LogsLinkLocation.IN && (
          <LogsLink jitEventId={jitEventId} job={firstJob} />
        )}
      </div>

      {logsLinkLocation !== LogsLinkLocation.IN && (
        <LogsLink jitEventId={jitEventId} job={firstJob} />
      )}
    </div>
  );
};
