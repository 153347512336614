import { FC } from 'react';

import { FiltersBoxes } from './FiltersBoxes/FiltersBoxes';
import { FiltersClearButton } from './FiltersClearButton/FiltersClearButton';
import styles from './JitFilters.module.scss';

import { Filters } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import colors from 'themes/colors.module.scss';
import { IFilter } from 'types/interfaces/IFilter';

interface Props {
  filters: Array<IFilter>;
  updateFilter: (updatedFilter: IFilter) => void;
  resetFilters?: () => void;
  showFilterIcon?: boolean;
  shouldCloseOnItemClick?: boolean;
}

export const JitFilters: FC<Props> = ({ filters, updateFilter, resetFilters, showFilterIcon = true, shouldCloseOnItemClick = true }) => (
  <div className={styles.container} data-testid='jit-filters'>
    {showFilterIcon && <JitIcon centered color={colors.lightGray} icon={Filters} />}

    <FiltersBoxes filters={filters} shouldCloseOnItemClick={shouldCloseOnItemClick} updateFilter={updateFilter} />

    {resetFilters && (
    <>
      <div className={styles.separatedLine} />

      <FiltersClearButton clearFilters={resetFilters} filters={filters} />
    </>
    )}
  </div>
);
