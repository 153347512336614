import { createTheme } from '@mui/material';

import { baseTheme } from 'themes/baseTheme';

// @ts-ignore
// Todo: find a way to remove ts-ignore: https://mui.com/customization/theming/#custom-variables
export const lightTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: 'light',
  },
});
