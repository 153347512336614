import { FC } from 'react';

import { JitCopyToClipboardBox } from 'components/JitCopyToClipboardBox/JitCopyToClipboardBox';
import colors from 'themes/colors.module.scss';

interface Props {
  link: string;
}
export const SnapshotCopyLink: FC<Props> = ({ link }) => (
  <JitCopyToClipboardBox
    displayText='pages.performance.shareSnapshot.history.CopyLink'
    iconSize={12}
    text={link}
    textColor={colors.iris}
    variant='minimal'
  />
);
