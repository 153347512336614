import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { constants } from 'globalConstants';

const { risks: { BASE_ROUTE, RESOURCES, RESOURCES_LIST } } = constants.routes;

const RESOURCES_ROUTE = `/${BASE_ROUTE}/${RESOURCES}/${RESOURCES_LIST}`;

export const useRedirectToResourcePage = () => {
  // In case the context FF is off, the dashboard page should redirect to the resources page
  const navigate = useNavigate();
  const { useKnowledgeGraph } = useFlags();

  useEffect(() => {
    if (!useKnowledgeGraph) {
      navigate({
        pathname: RESOURCES_ROUTE,
      });
    }
  }, [useKnowledgeGraph, navigate]);
};
