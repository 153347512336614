export enum IFilterType {
  SINGLE_SELECT = 'single_select',
  MULTI_SELECT = 'multi_select',
}

export interface IFilterOption {
  value: string;
  displayText: string;
}

export interface IFilterOptionsWithCategories {
  category: string;
  valueOptions: IFilterOption[];
}

export interface IFilter {
  entityKey: string;
  displayText: string;
  type: IFilterType;
  valueOptions?: IFilterOption[];
  valueOptionsWithCategories?: IFilterOptionsWithCategories[];
  selectedValue?: IFilterOption | IFilterOption[];
  withSearchBox?: boolean;
  width?: string;
}
