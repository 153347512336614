import { ChangeEvent, useCallback, useMemo } from 'react';

import { useConfigurationsContext } from 'context/ConfigurationsContext/ConfigurationsContext';
import { BranchProtectionConfiguration, GithubBranchProtection } from 'types/interfaces';

export const useConfigurationStateManager = (initialState: GithubBranchProtection) => {
  const { setConfigurations, configurations } = useConfigurationsContext();

  const currentConfiguration = useMemo(() => configurations.github_branch_protection || initialState, [configurations.github_branch_protection,
    initialState]);

  const handleChange = useCallback((name: string, value: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    setConfigurations({
      ...configurations,
      github_branch_protection: {
        ...currentConfiguration,
        organization: {
          ...currentConfiguration?.organization,
          [name]: value,
        } as BranchProtectionConfiguration,
      },
    });
  }, [configurations, currentConfiguration, setConfigurations]);

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.name, event.target.value);
  };

  return {
    currentConfiguration,
    handleChangeInput,
    handleChange,
  };
};
