import { FC, PropsWithChildren } from 'react';
import { TooltipProps } from 'recharts';
import { NameType, Payload, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import styles from './GraphTooltip.module.scss';

interface GraphTooltipProps extends TooltipProps<ValueType, NameType> {
  payload?: Payload<ValueType, NameType>[];
  hide?: boolean;
}

export const GraphTooltip: FC<PropsWithChildren<GraphTooltipProps>> = ({ active, payload, hide, children }) => (
  active && payload && payload.length ? (
    <div className={styles.root} data-testid='graph-tooltip' style={{ ...(hide ? { display: 'none' } : {}) }}>
      {children}
    </div>
  ) : null
);
