import { FC } from 'react';

import styles from './AddRemovePopover.module.scss';

import { EditPen, Cross } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { PriorityBox } from 'pages/FindingsPage/components/PriorityBox/PriorityBox';
import colors from 'themes/colors.module.scss';

interface Props {
  priorityScore: number;
  onClose: () => void;
  isInEditMode: boolean;
  setIsInEditMode: (isInEditMode: boolean) => void;
}

export const PopoverHeader: FC<Props> = ({ priorityScore, onClose, isInEditMode, setIsInEditMode }) => (
  <div className={styles.header}>
    <div className={styles.closeIcon}>
      <JitIcon icon={Cross} onClick={onClose} size={14} />
    </div>

    <div className={styles.scoreAndTexts}>
      <PriorityBox priorityScore={priorityScore} />

      <div className={styles.headerTexts}>

        <JitText bold size='l' text='pages.findings.findingDetails.priority.addRemoveFactors.title' />

        <JitText muted size='m' text='pages.findings.findingDetails.priority.addRemoveFactors.subtitle' />
      </div>
    </div>

    <div data-testid='EditModeButton'>
      <JitTooltip
        followCursor={false}
        offset={[0, 16]}
        placement='top'
        title={isInEditMode
          ? 'pages.findings.findingDetails.priority.addRemoveFactors.editIconTooltip.goBack'
          : 'pages.findings.findingDetails.priority.addRemoveFactors.editIconTooltip.edit'}
      >
        <JitIcon
          color={isInEditMode ? colors.grey11 : colors.blue02}
          icon={EditPen}
          onClick={() => setIsInEditMode(!isInEditMode)}
          size={12}
          withBackSquare={{
            height: 36,
            width: 36,
            backgroundColor: isInEditMode ? colors.blue02 : colors.darkBlue04,
            borderRadius: '50%',
            borderColor: 'transparent',
          }}
        />
      </JitTooltip>
    </div>
  </div>

);
