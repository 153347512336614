import { FC } from 'react';

import styles from './RemainingCard.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Count {
  count: number;
  color: string;
}

interface Props {
  count: Count;
  total: number;
}

export const RemainingCard: FC<Props> = ({ count, total }) => (

  <div className={styles.wrapper} data-testid='remainingCard'>
    <JitText bold color={count.color} size='huge' text={count.count} />

    <JitText bold color={colors.gray} size='l' text='/' />

    <JitText bold color={colors.gray} size='l' text={total} />
  </div>
);
