import { FC } from 'react';

import { JitDropdownNew } from 'components/JitDropdownNew/JitDropdownNew';
import { JitText } from 'components/JitText/JitText';
import { JitTextInput } from 'components/JitTextInput/JitTextInput';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import styles from 'pages/InsightsPage/SubPages/PerformancePage/components/ShareSnapshotDialog/ShareSnapshotDialog.module.scss';
import { TimeAgo } from 'types/enums';

interface Props {
  snapshotName: string,
  setSnapshotName: (snapshotName: string) => void,
  snapshotExpiration: TimeAgo,
  setSnapshotExpiration: (snapshotExpiration: TimeAgo) => void,
}

export const DialogContentInputs: FC<Props> = ({ snapshotName, setSnapshotName, snapshotExpiration, setSnapshotExpiration }) => {
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const expirationOptions = [TimeAgo.OneWeek, TimeAgo.TwoWeeks, TimeAgo.OneMonth].map((expirationOption) => ({
    itemKey: expirationOption,
    itemName: `pages.performance.shareSnapshot.expirationOptions.${expirationOption}`,
    isSelected: snapshotExpiration === expirationOption,
  }));
  const onExpirationOptionClick = (itemKey: TimeAgo) => {
    setSnapshotExpiration(itemKey);
    sendAnalyticsEvent({
      action: 'share-snapshot-dialog-expiration-option-clicked',
      params: {
        selectedValue: itemKey,
      },
    });
  };

  return (
    <div className={styles.inputsWrapper}>
      <div className={styles.inputWrapper}>
        <JitTextInput
          autoFocus
          data-testid='snapshot-name-input'
          label='pages.performance.shareSnapshot.snapshotNameInputLabel'
          onChange={(newSnapshotName) => setSnapshotName(newSnapshotName)}
          placeholder='pages.performance.shareSnapshot.snapshotNameInputPlaceholder'
          value={snapshotName}
        />
      </div>

      <div className={styles.inputWrapper}>
        <div className={styles.expireInputLabel}>
          <JitText data-testid='snapshot-expiration-input-label' muted text='pages.performance.shareSnapshot.expireInputLabel' />
        </div>

        <JitDropdownNew
          displayText={`pages.performance.shareSnapshot.expirationOptions.${snapshotExpiration}`}
          height={32}
          menuDataTestId='snapshot-expiration-input'
          menuItems={expirationOptions}
          onMenuItemClick={({ itemKey }) => { onExpirationOptionClick(itemKey as TimeAgo); }}
        />
      </div>
    </div>
  );
};
