import { t } from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import debounce from 'lodash/debounce';
import { FC, UIEvent, useCallback, useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import commonStyles from '../common.module.scss';

import styles from './ChooseGitlabGroup.module.scss';

import { GitlabIcon, Info } from 'assets';
import { JitAutoCompleteForm } from 'components/JitAutoCompleteForm/components/JitAutocompleteForm';
import { DialogContentWrapper } from 'components/JitDialogs/DialogContentWrapper/DialogContentWrapper';
import { JitInfoBanner } from 'components/JitInfoBanner/JitInfoBanner';
import { calcShouldFetchMore } from 'components/JitTable/utils';
import { JitText } from 'components/JitText/JitText';
import { useTenantContext } from 'context/TenantContext';
import { IGroup, useGitlabService } from 'services/GitlabService/useGitlabService';
import colors from 'themes/colors.module.scss';
import { Queries } from 'types/enums/Queries';

interface Props {
  onContinueButtonClick: () => void;
  isGroupSelected: boolean;
  setSelectedGroup: (group: IGroup) => void;
  selectedGroup?: IGroup;
  isInstallationLoading: boolean;
}

const PAGE_LIMIT = 100;
const SEARCH_BOX_PLACEHOLDER = t('GitlabIntegrationWizard.chooseGitlabGroup.dropdown.placeholder');
// https://docs.gitlab.com/ee/api/members.html#roles - when useGitlabOwnerRole FF is open (default), use access level 50
const OWNER_ACCESS_LEVEL = 50;
const MAINTAINER_ACCESS_LEVEL = 40;

export const ChooseGitlabGroup: FC<Props> = ({
  onContinueButtonClick, isGroupSelected, setSelectedGroup, selectedGroup, isInstallationLoading,
}) => {
  const { useGitlabOwnerRole } = useFlags();
  const minAccessLevel = useGitlabOwnerRole ? OWNER_ACCESS_LEVEL : MAINTAINER_ACCESS_LEVEL;

  const [searchTerm, setSearchTerm] = useState<string>();
  const { fetchGroups } = useGitlabService();
  const { isGitlabIntegrated, gitlabInstallation } = useTenantContext();

  const isGitLabInstallation = useMemo(() => !!gitlabInstallation, [gitlabInstallation]);

  const {
    data,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isLoading: isGroupsLoading,
    remove,
  } = useInfiniteQuery(
    [Queries.GitlabGroups, searchTerm],
    ({ pageParam = 1 }) => fetchGroups({
      per_page: PAGE_LIMIT,
      page: pageParam,
      search: searchTerm,
      min_access_level: minAccessLevel,
    }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage || lastPage.length < PAGE_LIMIT) return undefined;
        return pages.length + 1;
      },
      enabled: !isGitLabInstallation,
    },
  );

  const gitlabGroups = data?.pages.reduce((acc, currPage) => acc?.concat(currPage || []), []) || [];

  const onScroll = useCallback((e: UIEvent<HTMLUListElement>) => {
    if (hasNextPage && !isFetchingNextPage && calcShouldFetchMore(e, 20)) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  const debouncedInputChange = debounce((value: string) => {
    if (selectedGroup?.name !== value) {
      setSearchTerm(value);
      remove();
    }
  }, 300);

  return (
    <DialogContentWrapper
      actionButtons={[{
        label: 'GitlabIntegrationWizard.chooseGitlabGroup.buttonText',
        disabled: !isGitlabIntegrated && !isGroupSelected,
        handleClick: onContinueButtonClick,
        isLoading: isInstallationLoading,
      }]}
      icon={GitlabIcon}
    >
      <div className={`${commonStyles.dotJitTextBox} ${styles.content}`}>

        <JitText bold size='l' text='GitlabIntegrationWizard.chooseGitlabGroup.title' />

        <JitAutoCompleteForm
          clearOnBlur={false}
          disableCloseOnSelect
          disabled={isGitLabInstallation}
          getOptionKey={(group) => group.id}
          getOptionLabel={(group) => group.name}
          isLoading={isGroupsLoading || isFetchingNextPage}
          isSingleValue
          onInputChange={debouncedInputChange}
          onScroll={onScroll}
          options={gitlabGroups}
          placeHolder={SEARCH_BOX_PLACEHOLDER}
          selectedValue={selectedGroup}
          setSelectedValue={setSelectedGroup}
        />

        <JitInfoBanner bgColor={colors.cardContent} data-testid='alert-message' icon={Info} iconColor={colors.blue}>
          <JitText text='GitlabIntegrationWizard.chooseGitlabGroup.info' />
        </JitInfoBanner>
      </div>
    </DialogContentWrapper>
  );
};
