import { useMemo } from 'react';

import { GradeCell } from '../../../components/GradeCell/GradeCell';

import { ActionButtonCell } from './Cells/ActionButtonCell/ActionButtonCell';
import { ActivatedCell } from './Cells/ActivatedCell/ActivatedCell';
import { RankCell } from './Cells/RankCell/RankCell';
import { ScoreTrendingCell } from './Cells/ScoreTrendingCell/ScoreTrendingCell';

import { TextCell } from 'components/JitTable/templates';
import { MembersCell } from 'components/JitTable/templates/MembersCell/MembersCell';
import { IScoreTrendingEntity } from 'components/ScoreTrendingWidget/hooks/useGetScoreTrendingByWeek';
import { i18n } from 'locale/i18n';
import { IGetTeamResponseItem } from 'types/interfaces/Teams/ITeam';
import { TableColumn } from 'types/types';

interface TableColumnsNames {
  rank: string;
  team: string;
  members: string;
  score: string;
  weeklyTrend: string;
  activated: string;
  actionButton: string;
}

export const useGetColumns = (): TableColumn<object>[] => {
  const { t } = i18n;
  const columnHeaders: TableColumnsNames = useMemo(() => t('pages.teamsPortal.leaderboardPage.table.columns', { returnObjects: true }), [t]);

  // @ts-ignore The whole tables related logic uses object as type, this should be fixed
  const columns: TableColumn<object>[] = useMemo(() => [
    {
      id: columnHeaders.rank,
      Header: columnHeaders.rank,
      accessor: (team: IGetTeamResponseItem & { rank: number }) => ({
        imageDetails: team.imageDetails,
        rank: team.rank,
        isActivated: team.isActivated,
      }),
      Cell: RankCell,
      width: '10%',
    },
    {
      id: columnHeaders.team,
      Header: columnHeaders.team,
      accessor: (team: IGetTeamResponseItem) => team.name,
      Cell: TextCell,
      width: '15%',
    },
    {
      id: columnHeaders.members,
      Header: columnHeaders.members,
      accessor: (team: IGetTeamResponseItem) => ({
        members: team.members,
        count: team.membersCount,
        isActivated: team.isActivated,
      }),
      Cell: MembersCell,
      width: '10%',
    },
    {
      id: columnHeaders.score,
      Header: columnHeaders.score,
      accessor: (team: IGetTeamResponseItem) => ({
        score: team.score,
        isActivated: team.isActivated,
      }),
      Cell: GradeCell,
      width: '10%',
    },
    {
      id: columnHeaders.weeklyTrend,
      Header: columnHeaders.weeklyTrend,
      accessor: (team: IGetTeamResponseItem & { scoreTrending: IScoreTrendingEntity }) => ({
        scoreTrendingEntity: team.scoreTrending,
        isActivated: team.isActivated,
      }),
      Cell: ScoreTrendingCell,
      width: '25%',
    },
    {
      id: columnHeaders.activated,
      Header: '',
      accessor: (team: IGetTeamResponseItem) => team,
      Cell: ActivatedCell,
      width: '15%',
    },
    {
      id: columnHeaders.actionButton,
      Header: '',
      accessor: (team: IGetTeamResponseItem) => ({
        members: team.members,
        teamId: team.id,
        isActivated: team.isActivated,
      }),
      Cell: ActionButtonCell,
      width: '15%',
    },
  ], [columnHeaders.actionButton, columnHeaders.activated, columnHeaders.members, columnHeaders.rank, columnHeaders.score, columnHeaders.team, columnHeaders.weeklyTrend]);

  return columns;
};
