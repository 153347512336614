import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { TeamCard } from '../TeamCard/TeamCard';

import styles from './TeamCarousel.module.scss';

import { JitCarousel } from 'components/JitCarousel/JitCarousel';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { useGetTeamsSymbolImage } from 'context/TeamsContext/hooks/useGetTeamsSymbolImage';
import { IPaginatedState } from 'types/interfaces/IPaginatedState';
import { IGetTeamResponseItem, ITeam } from 'types/interfaces/Teams/ITeam';

interface Props {
  currentTeam?: ITeam | undefined;
  setCurrentTeam: (team: IGetTeamResponseItem) => void;
  teams: IPaginatedState<IGetTeamResponseItem>;
  handleSelectTeam: () => void;
}

export const TeamCarousel: FC<Props> = ({ currentTeam, setCurrentTeam, teams, handleSelectTeam }) => {
  const [teamRankMap, setTeamRankMap] = useState<Record<string, number>>({});
  const [currentTeamIndex, setCurrentTeamIndex] = useState(0);
  const { symbolImages } = useGetTeamsSymbolImage(teams.data);

  const handleChange = useCallback((index: number) => {
    setCurrentTeam(teams.data[index]);
    setCurrentTeamIndex(index);
  }, [setCurrentTeam, teams.data]);

  useEffect(() => {
    if (currentTeam) {
      setTeamRankMap((prev) => ({ ...prev,
        [currentTeam.id]: currentTeam.rank! }));
    }
  }, [currentTeam]);

  const teamWithRanks = useMemo(() => teams.data.map((team) => (
    {
      ...team,
      rank: currentTeam?.id === team.id ? currentTeam?.rank : undefined,
    })), [currentTeam?.id, currentTeam?.rank, teams.data]);

  const data = useMemo(() => teamWithRanks.map((team) => (
    <TeamCard
      key={team.id}
      // if the rank of the team is already exists in the teamWithRanks array, we don't need to show the loading bar
      isLoadingRank={teamRankMap[team.id] === undefined}
      rank={team.rank || teamRankMap[team.id]}
      team={team}
    />

  )), [teamRankMap, teamWithRanks]);

  return (
    <>
      <div className={styles.container}>

        <div className={styles.image}>
          <img alt='symbol' src={symbolImages[currentTeamIndex]?.src} />
        </div>

        <div className={styles.teamCardsCarousel}>
          <JitCarousel data={data} onChange={handleChange} />
        </div>
      </div>

      {teams.data.length > 0 && (
      <JitGradientButton
        data-testid='start-with-team-button'
        height={50}
        onClick={() => handleSelectTeam()}
        width={260}
      >
        <TooltipOnlyOnOverflow
          bold
          params={{
            teamName: (teams.data[currentTeamIndex].name || '').toUpperCase(),
          }}
          size='s'
          text='pages.teamsPortal.welcomePage.startWithTeamButton'
        />
      </JitGradientButton>
      )}
    </>

  );
};
