import { client } from '../client';

import { IDeleteInstallationsRequest } from 'types/interfaces/AppLifecycle';

const serviceName = 'applifecycle';

export const deleteInstallations = (request: IDeleteInstallationsRequest) => {
  const url = `${serviceName}/installations`;
  return client.delete(url, { data: request });
};
