import { createContext, useContext } from 'react';

import { IIntegration } from 'types/interfaces/Integrations/IIntegration';

interface IIntegrationsContext {
  integrations?: IIntegration[];
  fileSha?: string;
  isLoading: boolean;
  fetchIntegrations: (refetch?: boolean) => void;
}

export const IntegrationsContext = createContext<IIntegrationsContext>({
  integrations: undefined,
  fileSha: undefined,
  isLoading: false,
  fetchIntegrations: () => {},
});

export const useIntegrationsContext = () => useContext(IntegrationsContext);
