import { FC } from 'react';

import styles from './InfoPopperContent.module.scss';

import { JitText } from 'components/JitText/JitText';

interface Props {
  metricName: string;
  description: string;
}

export const InfoPopperContent: FC<Props> = ({ metricName, description }) => (
  <div className={styles.wrapper}>
    <JitText bold className={styles.avgValueText} size='xl' text={metricName} />

    <div className={styles.description}>
      <JitText
        muted
        style={{
          whiteSpace: 'pre-line',
          lineHeight: 1.2,
        }}
        text={description}
      />
    </div>
  </div>
);
