import { useMemo } from 'react';

import { useStiggExtendedContext } from './StiggExtendedContext';
import { useGetBooleanEntitlement } from './useGetBooleanEntitlement';

export const useIsUserEntitledToPlanItem = (planItemSlug: string) => {
  const { stiggFeatures } = useStiggExtendedContext();
  const getBooleanEntitlement = useGetBooleanEntitlement();
  const stiggPlanItemFeature = `feature-${planItemSlug}`;

  const isPlanItemPremiumFeature = useMemo(
    () => stiggFeatures.some((feature) => feature.featureId === stiggPlanItemFeature),
    [stiggFeatures, stiggPlanItemFeature],
  );

  const isUserEntitled = useMemo(
    () => (isPlanItemPremiumFeature
      ? getBooleanEntitlement(stiggPlanItemFeature, false)
      : true),
    [stiggPlanItemFeature, isPlanItemPremiumFeature, getBooleanEntitlement],
  );

  return isUserEntitled;
};
